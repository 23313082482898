<syslink-select label="IsBillable" [store]="taskBillingTypesService.store"
    [(model)]="element.TaskBillingTypeId" (modelChange)="taskBillingTypeChange.emit()"></syslink-select>


<syslink-input-number *ngIf="element.TaskBillingTypeId.Code == 'FixedPrice'" label="Fixed price"
    [(model)]="element.FixedPrice" [min]="0"></syslink-input-number>

<div *ngIf="element.TaskBillingTypeId && element.TaskBillingTypeId.Code != 'NotBillable'">
    <syslink-progress-bar #billedTimeProgressBar name="Billed time"
        [max]="element.EstimatedTime || element.BillableTime" [value]="element.BilledTime"
        [fillColor]="element.BilledTimeState.Color"></syslink-progress-bar>
</div>