import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { DocumentRelationData } from '../document-relations-block/document-relations-block.component';
import { ModalComponent, TabData } from 'projects/libraries/syslink-components/src/public-api';
import { SaleQuoteGridComponent } from 'projects/erp-app/src/app/sales/sale-quotes/sale-quote-grid/sale-quote-grid.component';
import { WorkTimeGridComponent } from 'projects/erp-app/src/app/time-management/work-times/work-times/work-time-grid/work-time-grid.component';
import { TaskGridComponent } from 'projects/erp-app/src/app/time-management/tasks/tasks/task-grid/task-grid.component';
import { StockMoveIncomingGridComponent } from 'projects/erp-app/src/app/stocks/stock-move-incomings/stock-move-incoming-grid/stock-move-incoming-grid.component';
import { SaleOrderGridComponent } from 'projects/erp-app/src/app/sales/sale-orders/sale-order-grid/sale-order-grid.component';
import { SaleInvoiceGridComponent } from 'projects/erp-app/src/app/sales/sale-invoices/sale-invoice-grid/sale-invoice-grid.component';
import { SaleCreditNoteGridComponent } from 'projects/erp-app/src/app/sales/sale-credit-notes/sale-credit-note-grid/sale-credit-note-grid.component';
import { SaleContractGridComponent } from 'projects/erp-app/src/app/sales/sale-contracts/sale-contract-grid/sale-contract-grid.component';
import { PurchaseOrderGridComponent } from 'projects/erp-app/src/app/purchases/purchase-orders/purchase-order-grid/purchase-order-grid.component';
import { PurchaseInvoiceGridComponent } from 'projects/erp-app/src/app/purchases/purchase-invoices/purchase-invoice-grid/purchase-invoice-grid.component';
import { PurchaseCreditNoteGridComponent } from 'projects/erp-app/src/app/purchases/purchase-credit-notes/purchase-credit-note-grid/purchase-credit-note-grid.component';
import { StockMoveOutgoingGridComponent } from 'projects/erp-app/src/app/stocks/stock-move-outgoings/stock-move-outgoing-grid/stock-move-outgoing-grid.component';
import { ViewComponent } from 'projects/libraries/syslink-components/src/lib/helpers/view/view.component';

@Component({
  selector: 'app-document-relations-v2',
  templateUrl: './document-relations-v2.component.html',
  styleUrls: ['./document-relations-v2.component.scss']
})
export class DocumentRelationsV2Component extends ViewComponent {
  public _elements: DocumentRelationData[] = [];
  @Input() set elements(value: DocumentRelationData[]) {
    this._elements = value;
    this.loadFilter();
  }
  get elements() {
    return this._elements;
  }

  @Output() public onRefreshClicked: EventEmitter<{ type: string }> = new EventEmitter<{ type: string }>();
  @Output() public onAddClicked: EventEmitter<{ type: string }> = new EventEmitter<{ type: string }>();
  @Output() public onDoubleClicked: EventEmitter<any> = new EventEmitter<any>();
  @Output() public onDeleteClicked: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild('modalSaleQuote') modalSaleQuote?: ModalComponent;
  @ViewChild('modalSaleOrder') modalSaleOrder?: ModalComponent;
  @ViewChild('modalSaleInvoice') modalSaleInvoice?: ModalComponent;
  @ViewChild('modalSaleCreditNote') modalSaleCreditNote?: ModalComponent;
  @ViewChild('modalSaleContract') modalSaleContract?: ModalComponent;
  @ViewChild('modalPurchaseOrder') modalPurchaseOrder?: ModalComponent;
  @ViewChild('modalPurchaseInvoice') modalPurchaseInvoice?: ModalComponent;
  @ViewChild('modalPurchaseCreditNote') modalPurchaseCreditNote?: ModalComponent;
  @ViewChild('modalStockMoveCustomerOutgoing') modalStockMoveCustomerOutgoing?: ModalComponent;
  @ViewChild('modalStockMoveCustomerIncoming') modalStockMoveCustomerIncoming?: ModalComponent;
  @ViewChild('modalStockMoveSupplierOutgoing') modalStockMoveSupplierOutgoing?: ModalComponent;
  @ViewChild('modalStockMoveSupplierIncoming') modalStockMoveSupplierIncoming?: ModalComponent;
  @ViewChild('modalTimeManagementTask') modalTimeManagementTask?: ModalComponent;
  @ViewChild('modalTimeManagementWorkTime') modalTimeManagementWorkTime?: ModalComponent;

  @ViewChild('saleQuoteGridComponent') saleQuoteGridComponent?: SaleQuoteGridComponent;
  @ViewChild('saleOrderGridComponent') saleOrderGridComponent?: SaleOrderGridComponent;
  @ViewChild('saleInvoiceGridComponent') saleInvoiceGridComponent?: SaleInvoiceGridComponent;
  @ViewChild('saleCreditNoteGridComponent') saleCreditNoteGridComponent?: SaleCreditNoteGridComponent;
  @ViewChild('saleContractGridComponent') saleContractGridComponent?: SaleContractGridComponent;
  @ViewChild('purchaseOrderGridComponent') purchaseOrderGridComponent?: PurchaseOrderGridComponent;
  @ViewChild('purchaseInvoiceGridComponent') purchaseInvoiceGridComponent?: PurchaseInvoiceGridComponent;
  @ViewChild('purchaseCreditNoteGridComponent') purchaseCreditNoteGridComponent?: PurchaseCreditNoteGridComponent;
  @ViewChild('stockMoveIncomingGridComponent') stockMoveIncomingGridComponent?: StockMoveIncomingGridComponent;
  @ViewChild('stockMoveOutgoingGridComponent') stockMoveOutgoingGridComponent?: StockMoveOutgoingGridComponent;
  @ViewChild('taskGridComponent') taskGridComponent?: TaskGridComponent;
  @ViewChild('workTimeGridComponent') workTimeGridComponent?: WorkTimeGridComponent;


  public gridFilter: string | Array<string> = "";

  public modalData: TabData[] = [
    { id: 0, key: "SaleQuote", label: "Sale quotes", loaded: false, data: {} },
    { id: 1, key: "SaleOrder", label: "Sale orders", loaded: false, data: {} },
    { id: 2, key: "SaleInvoice", label: "Sale invoices", loaded: false, data: {} },
    { id: 3, key: "SaleCreditNote", label: "Sale credit notes", loaded: false, data: {} },
    { id: 4, key: "SaleContract", label: "Sale contracts", loaded: false, data: {} },
    { id: 5, key: "PurchaseOrder", label: "Purchase orders", loaded: false, data: {} },
    { id: 6, key: "PurchaseInvoice", label: "Purchase invoices", loaded: false, data: {} },
    { id: 7, key: "PurchaseCreditNote", label: "Purchase credit notes", loaded: false, data: {} },
    { id: 8, key: "StockMoveCustomerOutgoing", label: "Stock Move Customer Outgoings", loaded: false, data: {} },
    { id: 9, key: "StockMoveCustomerIncoming", label: "Stock Move Customer Incomings", loaded: false, data: {} },
    { id: 10, key: "StockMoveSupplierOutgoing", label: "Stock Move Supplier Outgoings", loaded: false, data: {} },
    { id: 11, key: "StockMoveSupplierIncoming", label: "Stock Move Supplier Incomings", loaded: false, data: {} },
    { id: 12, key: "TimeManagementTask", label: "Time management task", loaded: false, data: {} },
    { id: 13, key: "TimeManagementWorkTime", label: "Time management work time", loaded: false, data: {} },
  ]

  public onItemClick(e: string) {
    var index = this.modalData.findIndex(s => s.key == e);
    if (this.modalData[index].loaded == true) {
      this.openModal(e);
    }
    this.modalData[index].loaded = true;
    this.modalData[index].data.filter = this.elements?.find(s => s.key == e)?.gridFilter;
    this.modalData[index].data.columns = this.elements?.find(s => s.key == e)?.columns;
    this.modalData[index].label = this.elements?.find(s => s.key == e)?.title ?? this.modalData[index].label;
  }

  private openModal(e: string) {
    switch (e) {
      case "SaleQuote":
        this.modalSaleQuote?.open();
        break;
      case "SaleOrder":
        this.modalSaleOrder?.open();
        break;
      case "SaleInvoice":
        this.modalSaleInvoice?.open();
        break;
      case "SaleCreditNote":
        this.modalSaleCreditNote?.open();
        break;
      case "SaleContract":
        this.modalSaleContract?.open();
        break;
      case "PurchaseOrder":
        this.modalPurchaseOrder?.open();
        break;
      case "PurchaseInvoice":
        this.modalPurchaseInvoice?.open();
        break;
      case "PurchaseCreditNote":
        this.modalPurchaseCreditNote?.open();
        break;
      case "StockMoveCustomerOutgoing":
        this.modalStockMoveCustomerOutgoing?.open();
        break;
      case "StockMoveCustomerIncoming":
        this.modalStockMoveCustomerIncoming?.open();
        break;
      case "StockMoveSupplierIncoming":
        this.modalStockMoveSupplierIncoming?.open();
        break;
      case "StockMoveSupplierOutgoing":
        this.modalStockMoveSupplierOutgoing?.open();
        break;
      case "TimeManagementTask":
        this.modalTimeManagementTask?.open();
        break;
      case "TimeManagementWorkTime":
        this.modalTimeManagementWorkTime?.open();
        break;
    }
  }

  private loadFilter() {
    for (let i = 0; i < this.modalData.length; i++) {
      this.modalData[i].data.filter = this.elements?.find(s => s.id == i)?.gridFilter;
    }
    this.saleQuoteGridComponent?.grid?.grid?.instance.filter(this.modalData[0].data.filter);
    this.saleQuoteGridComponent?.grid?.grid?.instance.refresh();
    this.saleOrderGridComponent?.grid?.grid?.instance.filter(this.modalData[1].data.filter);
    this.saleOrderGridComponent?.grid?.grid?.instance.refresh();
    this.saleInvoiceGridComponent?.grid?.grid?.instance.filter(this.modalData[2].data.filter);
    this.saleInvoiceGridComponent?.grid?.grid?.instance.refresh();
    this.saleCreditNoteGridComponent?.grid?.grid?.instance.filter(this.modalData[3].data.filter);
    this.saleCreditNoteGridComponent?.grid?.grid?.instance.refresh();
    this.saleContractGridComponent?.grid?.grid?.instance.filter(this.modalData[4].data.filter);
    this.saleContractGridComponent?.grid?.grid?.instance.refresh();

    this.purchaseOrderGridComponent?.grid?.grid?.instance.filter(this.modalData[5].data.filter);
    this.purchaseOrderGridComponent?.grid?.grid?.instance.refresh();
    this.purchaseInvoiceGridComponent?.grid?.grid?.instance.filter(this.modalData[6].data.filter);
    this.purchaseInvoiceGridComponent?.grid?.grid?.instance.refresh();
    this.purchaseCreditNoteGridComponent?.grid?.grid?.instance.filter(this.modalData[7].data.filter);
    this.purchaseCreditNoteGridComponent?.grid?.grid?.instance.refresh();


    this.stockMoveIncomingGridComponent?.grid?.grid?.instance.filter([...this.modalData[9].data.filter, ...this.modalData[11].data.filter]);
    this.stockMoveIncomingGridComponent?.grid?.grid?.instance.refresh();
    this.stockMoveOutgoingGridComponent?.grid?.grid?.instance.filter([...this.modalData[8].data.filter, ...this.modalData[10].data.filter]);
    this.stockMoveOutgoingGridComponent?.grid?.grid?.instance.refresh();

    this.taskGridComponent?.grid?.grid?.instance.filter(this.modalData[12].data.filter);
    this.taskGridComponent?.grid?.grid?.instance.refresh();
    this.workTimeGridComponent?.grid?.grid?.instance.filter(this.modalData[13].data.filter);
    this.workTimeGridComponent?.grid?.grid?.instance.refresh();
  }

  getGroups(): string[] {
    // Obtenir une liste unique de groupes
    const groups = this.elements.map(item => item.group);
    return Array.from(new Set(groups));
  }

  getItemsByGroup(group: string): any[] {
    return this.elements.filter(item => item.group === group && item.visible === true);
  }
}