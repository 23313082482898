<syslink-block [title]="service.url">
    <app-basic-grid
        [permissionKey]="'list'"
        [service]="service"
        [columns]="columns"
        [expand]="expand"
        [canUpdate]="true"
        storageKey="basic-{{ this.service.url }}"
        [languages]="languagesService.languages"
        (onPrepareRow)="onPrepareRow($event)"
        (beforeAdd)="onBeforeInsert($event)"
        (beforeUpdate)="onBeforeUpdate($event)"
    ></app-basic-grid>
</syslink-block>