import { Component, Input, ViewChild } from '@angular/core';
import { BaseGridComponent } from 'projects/libraries/syslink-components/src/lib/grid/base-grid/base-grid.component';
import { SequenceConfiguration } from '../sequence-configuration.model';
import { GridComponent } from 'projects/libraries/syslink-components/src/public-api';
import { SyslinkColumn } from 'projects/libraries/syslink-components/src/lib/helpers/SyslinkColumn';
import { SequenceConfigurationsService } from '../sequence-configurations.service';

@Component({
  selector: 'app-sequence-configuration-grid',
  templateUrl: './sequence-configuration-grid.component.html',
  styleUrls: ['./sequence-configuration-grid.component.scss']
})
export class SequenceConfigurationGridComponent extends BaseGridComponent<SequenceConfiguration> {
  @Input() storageKey: string = 'grid-sequences-configuration';

  @ViewChild('gridComponent') public gridComponent?: GridComponent;

  public columns: SyslinkColumn[] = [
    new SyslinkColumn({ field: 'Id', type: 'number', editable:false }),
    new SyslinkColumn({ field: 'Type' }),
    new SyslinkColumn({ field: 'Model' }),
    new SyslinkColumn({ field: 'Prefix' }),
    new SyslinkColumn({ field: 'Suffix' }),
    new SyslinkColumn({ field: 'DigitCount', type: 'number' }),
    new SyslinkColumn({ field: 'IsDependantOnYear', type: 'boolean' }),
  ];

  constructor(
    public sequenceConfigurationsService: SequenceConfigurationsService,
  ) {
    super();
  }

}
