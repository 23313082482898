<app-supplier-catalog-grid
#supplierCatalogGrid
    [permissionKey]="'purchase.supplierCatalogs.list'"
    [supplierCatalogs]="element.SupplierCatalogs"
    (add)="onCreateBtnClicked()"
    (beforeUpdate)="onBeforeUpdate($event)"
    (beforeDelete)="onDeleteBtnClicked($event)"
    ></app-supplier-catalog-grid>

<syslink-modal
    #editModal
    title="Supplier catalog"
    width="400px"
    (onValidate)="editModal.data.newItem==false ?undefined : onCreate(supplierCatalog)"
    [validateText]="(supplierCatalog.Id ? 'Save' : 'Create')"
    cancelText="Cancel"
>
    <app-product-supplier-catalog-form [(element)]="supplierCatalog"></app-product-supplier-catalog-form>
</syslink-modal>