<div class="row">
  <div class="col-3">
    <syslink-input-text
      name="SupplierReference"
      label="Supplier reference"
      [(model)]="element.ManufacturerReference"
    ></syslink-input-text>
  </div>
</div>

<syslink-block
  [title]="'Supplier catalog'"
  [titleBgColor]="'gray'"
  [titleTextColor]="'white'"
  [bordered]="true"
  borderColor="$clr-gray"
  [titlePadding]="2"
>
  <app-product-supplier-catalogs [(element)]="element"></app-product-supplier-catalogs>
</syslink-block>