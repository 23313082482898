import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Payment } from '../payment.model';
import { PaymentMethodsService } from '../payment-methods/payment-methods.service';
import { GridComponent, ModalComponent, NotificationsService, SelectComponent } from 'projects/libraries/syslink-components/src/public-api';
import { SaleDocument } from '../../../sales/sale-documents/sale-documents/sale-document.model';
import { PaymentMethod } from '../payment-methods/payment-method';
import { MollieTerminal } from '../../../connectors/mollie/mollie-terminal/mollie-terminal.model';
import { MollieService } from '../../../connectors/mollie/mollie.service';
import { jsonToOdataFormat } from 'projects/libraries/syslink-components/src/lib/helpers/tools';
import { SyslinkColumn } from 'projects/libraries/syslink-components/src/lib/helpers/SyslinkColumn';
import { SaleInvoicesService } from '../../../sales/sale-invoices/sale-invoices/sale-invoices.service';
import { SaleCreditNotesService } from '../../../sales/sale-credit-notes/sale-credit-notes/sale-credit-notes.service';
import { Third } from '../../../thirds/thirds/third.model';
import { PaymentTypesService } from '../payment-types/payment-types.service';
import { PaymentsService } from '../payments.service';
import { MolliePayment } from '../../../connectors/mollie/mollie-payment/mollie-payment.model';
import { PurchaseInvoicesService } from '../../../purchases/purchase-invoices/purchase-invoices/purchase-invoices.service';
import { PurchaseCreditNotesService } from '../../../purchases/purchase-credit-notes/purchase-credit-notes/purchase-credit-notes.service';
import { PurchaseDocument } from '../../../purchases/purchase-documents/purchase-documents/purchase-document.model';
import { PaymentStatesService } from '../payment-states/payment-states.service';

@Component({
  selector: 'app-payment-details',
  templateUrl: './payment-details.component.html',
  styleUrls: ['./payment-details.component.scss']
})
export class PaymentDetailsComponent implements OnInit {
  private readonly allowedDocumentType: string[] = [
    "SaleInvoiceHeader",
    "SaleCreditNoteHeader",
    "SaleOrderHeader",
    "PurchaseInvoiceHeader",
    "PurchaseCreditNoteHeader",
    "PurchaseOrderHeader",
  ]

  @Input() public payment: Payment = new Payment();
  @Input() public documentType: string = '';
  @Input() public third?: Third;
  @Input() public amountRemaining?: number = 0;
  @Input() public documentNameFormatted?: string;
  @Input() public refundablePayments: Payment[] = [];
  @Input() public filterPaymentType: string | string[] = '';
  @Output() public paymentChange: EventEmitter<Payment> = new EventEmitter<Payment>();
  //@Output() public onUpdatePayment: EventEmitter<Payment> = new EventEmitter<Payment>();

  @ViewChild('modal') modal: ModalComponent = new ModalComponent();
  @ViewChild('paymentMethodSelect') public paymentMethodSelect?: SelectComponent;
  @ViewChild('gridRefundablePayment') public gridRefundablePayment?: GridComponent;

  public filterPaymentMethod: string | string[] = "";
  public filterLinked: string | string[] = "";
  public AmountAvailable?: number | undefined;
  public RemainingAmount: number = 0;

  // Mollie
  // ------
  public mollieTerminals: MollieTerminal[] = [];
  public columnsMollie: SyslinkColumn[] = [
    new SyslinkColumn({ field: "Amount", label: "Amount", cellTemplate: 'currency-cell', editable: false, data: { key: 'TotalPrecision', shouldHideData() { return false } } }),
    new SyslinkColumn({ field: "Date", label: "Date", type: 'datetime', editable: true })
  ];

  constructor(
    private paymentsService: PaymentsService,
    public paymentMethodsService: PaymentMethodsService,
    public saleInvoicesService: SaleInvoicesService,
    public saleCreditNotesService: SaleCreditNotesService,
    public purchaseInvoicesService: PurchaseInvoicesService,
    public purchaseCreditNotesService: PurchaseCreditNotesService,
    public paymentStatesService: PaymentStatesService,
    private mollieService: MollieService,
    public paymentTypesService: PaymentTypesService
  ) { }

  async ngOnInit(): Promise<void> {
    this.setDocumentTypeOrFail();
    if (this.payment.PaymentTypeId == undefined) {
      await this.loadDefaultPaymentType();
    }
    this.loadFilter();
    if (this.third && this.third.Id) {
      this.filterLinked = ["ThirdId.Id eq " + this.third.Id];
    }
  }

  public loadFilter() {
    switch (this.documentType) {
      case "SaleInvoice":
      case "PurchaseInvoice":
        this.filterPaymentMethod = this.payment.PaymentTypeId?.Code != 'Refund' ? ["Code ne 'Invoice'"] : ["Code ne 'CreditNote'"];
        break;
      case "SaleCreditNote":
      case "PurchaseCreditNote":
        this.filterPaymentMethod = this.payment.PaymentTypeId?.Code != 'Refund' ? ["Code ne 'CreditNote'"] : ["Code ne 'Invoice'"];
        break;
    }

    this.paymentMethodSelect?.selectComponent?.instance.getDataSource().filter(this.filterPaymentMethod);
    this.paymentMethodSelect?.selectComponent?.instance.getDataSource().reload();
    this.payment.LinkedDocument = undefined;
  }

  public async loadDefaultPaymentType() {
    switch (this.documentType) {
      // case "SaleOrder":
      // case "PurchaseOrder":
      //   this.payment.PaymentTypeId = (await this.paymentTypesService.load({ filter: ["Code eq 'Advance'"] }))[0];
      //   break;
      default:
        this.payment.PaymentTypeId = (await this.paymentTypesService.load({ filter: ["Code eq 'Payment'"] }))[0];
        break;
    }
  }

  // Change payment method
  // ---------------------
  public async onChangePaymentMethod(e: PaymentMethod) {
    if (e.Code == 'Mollie-Terminal') {
      await this.loadMollieTerminal();
    }
  }

  public onChangeDocumentLinked(type: string) {
    this.AmountAvailable = this.payment.LinkedDocument?.AmountRemaining;
  }

  // Change Amount
  // -------------
  public onAmountChange(e: number) {
    this.RemainingAmount = (this.amountRemaining != undefined ? this.amountRemaining : 0) - this.payment.Amount;
    if (this.RemainingAmount < 0) {
      this.RemainingAmount = 0;
    }
    // Apply Round
    // -----------
    // if (this.payment.PaymentMethodPrimary.ApplyRound == true) {
    this.payment.RoundedAmount = Math.round(this.payment.Amount / 0.05) * 0.05;
    // }
    // Apply Return
    // ------------
    if (this.payment.PaymentMethodPrimary.ApplyReturn == true) {
      this.payment.AmountToReturn = (this.payment.RoundedAmount - this.RemainingAmount) > 0 ? Math.round((this.payment.RoundedAmount - this.RemainingAmount) / 0.05) * 0.05 : 0;
    }
  }

  // Mollie terminal
  // ---------------
  public async loadMollieTerminal() {
    this.mollieTerminals = []
    this.mollieTerminals = (await this.mollieService.getMollieListOfTerminal()).map(e => jsonToOdataFormat(e)) ?? [];
    if (this.mollieTerminals.length > 0) {
      this.payment.SelectedMollieTerminal = this.mollieTerminals[0];
    }
    this.payment.MollieDocumentNameFormatted = this.documentNameFormatted;
    this.payment.DocumentType = this.documentType + "Header";
  }

  // // Validate payment
  // // --------------
  // public async onValidatePayment() {
  //   if (!this.payment.PaymentMethodPrimary || !this.payment.PaymentMethodPrimary.Id) {
  //     NotificationsService.sendErrorMessage("Payment method cannot be empty");
  //     return;
  //   }
  //   if (!this.payment.PaymentTypeId || !this.payment.PaymentTypeId.Id) {
  //     NotificationsService.sendErrorMessage("Payment type cannot be empty");
  //     return;
  //   }

  //   if (this.payment.PaymentTypeId.Code == "Refund") {
  //     this.payment.Amount = - this.payment.Amount;
  //     this.payment.RoundedAmount = - this.payment.RoundedAmount;
  //   }

  //   if (this.payment.Id) {
  //     await this.onUpdatePayment(this.payment);
  //     return;
  //   }
  //   this.modal.close();
  //   // Refund with mollie
  //   // -------------------------
  //   if (this.payment.PaymentTypeId.Code == "Refund" && this.payment.PaymentMethodPrimary.Code == "Mollie-Terminal") {
  //     await this.addRefundPaymentWithMollieTerminal();
  //     return;
  //   }

  //   // Paid with invoices or creditNotes
  //   // ---------------------------------
  //   if (this.payment.PaymentMethodPrimary.Code == "Invoice" || this.payment.PaymentMethodPrimary.Code == "CreditNote") {
  //     await this.addPaymentWithOtherDocument();
  //     return;
  //   }
  //   const payment = await this.paymentsService.insert(this.paymentsService.format(this.payment));

  //   // Paid with mollie terminal
  //   // -------------------------
  //   if (this.payment.PaymentTypeId.Code != "Refund" && this.payment.PaymentMethodPrimary.Code == "Mollie-Terminal" && this.selectedMollieTerminal && this.selectedMollieTerminal.Id) {
  //     await this.addPaymentWithMollieTerminal(payment);
  //   }

  //   this.paymentChange.emit(payment);
  // }

  // // Update Payment 
  // // --------------
  // public async onUpdatePayment(payment: Payment) {
  //   if (!payment.Id) return;
  //   await this.paymentsService.update(payment.Id, this.paymentsService.format(payment));
  //   NotificationsService.sendSuccess("Payment is updated");
  //   this.paymentChange.emit(payment);
  // }

  // // add payment with Mollie terminal
  // // --------------------------------
  // private async addRefundPaymentWithMollieTerminal() {
  //   if (!this.gridRefundablePayment) return;
  //   // Check if salectedKey is empty
  //   // -----------------------------
  //   if (this.gridRefundablePayment?.selectedKeys.length == 0) {
  //     NotificationsService.sendErrorMessage("Payment to refund is empty");
  //   }
  //   await this.mollieService.postRefundPayment(this.gridRefundablePayment.selectedKeys, this.payment.Note ?? "Refund");
  //   this.paymentChange.emit();
  // }

  // // add payment with invoices or creditNotes
  // // ----------------------------------------
  // private async addPaymentWithOtherDocument() {
  //   if (this.documentType.includes("Sale")) {
  //     this.payment.Reference = this.payment.PaymentMethodPrimary.Code == "Invoice" ? this.saleInvoiceLinked?.NameFormatted : this.saleCreditNoteLinked?.NameFormatted;
  //     const firstPayment = await this.paymentsService.insert(this.paymentsService.format(this.payment));
  //     this.payment.ParentId = firstPayment;
  //     this.payment.Reference = this.documentNameFormatted;
  //     this.payment.DocumentId = this.payment.PaymentMethodPrimary.Code == "Invoice" ? this.saleInvoiceLinked?.Id : this.saleCreditNoteLinked?.Id;
  //     this.payment.DocumentType = this.payment.DocumentType?.includes("Invoice") ? this.payment.DocumentType.replace("Invoice", "CreditNote") : this.payment.DocumentType?.replace("CreditNote", "Invoice");
  //     this.payment.PaymentMethodPrimary = this.payment.PaymentMethodPrimary.Code == "Invoice" ? (await this.paymentMethodsService.load({ filter: ["Code eq 'CreditNote'"] }))[0] : (await this.paymentMethodsService.load({ filter: ["Code eq 'Invoice'"] }))[0];
  //     await this.paymentsService.insert(this.paymentsService.format(this.payment));
  //     this.paymentChange.emit(firstPayment);
  //   }
  //   if (this.documentType.includes("Purchase")) {
  //     this.payment.Reference = this.payment.PaymentMethodPrimary.Code == "Invoice" ? this.purchaseInvoiceLinked?.NameFormatted : this.purchaseCreditNoteLinked?.NameFormatted;
  //     const firstPayment = await this.paymentsService.insert(this.paymentsService.format(this.payment));
  //     this.payment.ParentId = firstPayment;
  //     this.payment.Reference = this.documentNameFormatted;
  //     this.payment.DocumentId = this.payment.PaymentMethodPrimary.Code == "Invoice" ? this.purchaseInvoiceLinked?.Id : this.purchaseCreditNoteLinked?.Id;
  //     this.payment.DocumentType = this.payment.DocumentType?.includes("Invoice") ? this.payment.DocumentType.replace("Invoice", "CreditNote") : this.payment.DocumentType?.replace("CreditNote", "Invoice");
  //     this.payment.PaymentMethodPrimary = this.payment.PaymentMethodPrimary.Code == "Invoice" ? (await this.paymentMethodsService.load({ filter: ["Code eq 'CreditNote'"] }))[0] : (await this.paymentMethodsService.load({ filter: ["Code eq 'Invoice'"] }))[0];
  //     await this.paymentsService.insert(this.paymentsService.format(this.payment));
  //     this.paymentChange.emit(firstPayment);
  //   }

  // }

  // // add payment with Mollie terminal
  // // --------------------------------
  // private async addPaymentWithMollieTerminal(payment: Payment) {
  //   await this.mollieService.postPaymentCheckout(new MolliePayment({
  //     Amount: this.payment.Amount,
  //     IdMethod: "pointofsale",
  //     TerminalId: this.selectedMollieTerminal?.Id,
  //     Description: this.documentNameFormatted,
  //     PaymentId: payment.Id,
  //   }));
  // }

  private setDocumentTypeOrFail() {
    let documentType = this.documentType + "Header";
    if (!this.allowedDocumentType.includes(documentType)) {
      NotificationsService.sendErrorMessage("Can't create payment with this document type.");
      this.modal.close();
    } else {
      this.payment.DocumentType = documentType;
    }
  }
  public onChangeGridRefundablePaymentSelectedKeys(e:any){
    console.log(e);
    this.payment.SelectedMolliePayment = this.gridRefundablePayment?.selectedKeys;
    
  }

}
