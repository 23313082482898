import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Task } from '../task.model';
import { TaskBillingTypesService } from '../../task-billing-types/task-billing-types.service';

@Component({
  selector: 'app-task-billings',
  templateUrl: './task-billings.component.html',
  styleUrls: ['./task-billings.component.scss']
})
export class TaskBillingsComponent {
  @Input() public element: Task = new Task();
  @Output() public elementChange: EventEmitter<Task> = new EventEmitter<Task>();
  
  @Output() public taskBillingTypeChange: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    public taskBillingTypesService:TaskBillingTypesService
  ){}
}
