<syslink-block [title]="'Bank reconciliation'">
<app-bank-reconciliation-grid  
    #Grid
    [permissionKey]="'list'"
    (add)="addNewReconciliation()"
    (rowDoubleClicked)="onOpenBankReconciliation($event)"
></app-bank-reconciliation-grid>
</syslink-block>

<syslink-modal
    #modal
    title="Bank reconciliation"
    width="450px"
    (onValidate)="onValidateModalButtonClicked()"
    validateText="Validate"
    cancelText="Cancel"
>
    <syslink-file-uploader 
        *ngIf="modalData.CodaFile"
        #fileUploader 
        buttonText="Coda file"
        [(files)]="modalData.CodaFile"
        [allowedFileExtensions]="['.COD']"
        accept=".COD"
    ></syslink-file-uploader>

    <syslink-input-text
        name="reference"
        label="Reference"
        [(model)]="modalData.Reference"
    ></syslink-input-text>
</syslink-modal>

