<syslink-block [title]="'Sale quotes'">

  <ng-container *ngIf="globalResult">
    <div class="row gap-2">
      <div class="offset-4 col-1 rounded text-center border border-dark">
        <div class="h-100 d-flex flex-column justify-content-between">
          <p class="h6 fw-bold my-2"> {{'Total amount' | translate}}</p>
          <div>
            <p class="my-1">{{globalResult.ExTaxTotal | syslinkCurrency:"TotalPrecision"}} H.T.V.A</p>
            <p class="my-1">{{globalResult.InTaxTotal | syslinkCurrency:"TotalPrecision"}} T.T.C</p>
          </div>
        </div>
      </div>
      <div class="col-1 rounded bg-gray text-center border border-secondary">
        <div class="h-100 d-flex flex-column justify-content-between">
          <p class="h6 fw-bold my-2"> {{'Draft amount' | translate}}</p>
          <div>
            <p class="my-1">{{globalResult.DraftExTaxTotal | syslinkCurrency:"TotalPrecision"}} H.T.V.A</p>
            <p class="my-1">{{globalResult.DraftInTaxTotal | syslinkCurrency:"TotalPrecision"}} T.T.C</p>
          </div>
        </div>
      </div>
      <div class="col-1 rounded bg-accent text-center border border-secondary">
        <div class="h-100 d-flex flex-column justify-content-between">
          <p class="h6 fw-bold my-2"> {{'Transmitted amount' | translate}}</p>
          <div>
            <p class="my-1">{{globalResult.TransmittedExTaxTotal | syslinkCurrency:"TotalPrecision"}} H.T.V.A</p>
            <p class="my-1">{{globalResult.TransmittedInTaxTotal | syslinkCurrency:"TotalPrecision"}} T.T.C</p>
          </div>
        </div>
      </div>
      <div class="col-1 rounded text-center border border-secondary bg-success">
        <div class="h-100 d-flex flex-column justify-content-between">
          <p class="h6 fw-bold my-2"> {{'Confirmed amount' | translate}}</p>
          <div>
            <p class="my-1">{{globalResult.ConfirmedExTaxTotal | syslinkCurrency:"TotalPrecision"}} H.T.V.A</p>
            <p class="my-1">{{globalResult.ConfirmedInTaxTotal | syslinkCurrency:"TotalPrecision"}} T.T.C</p>
          </div>
        </div>
      </div>
    </div>
  </ng-container>

  <app-sale-quote-grid
  #grid
    [permissionKey]="'list'"
    [canUpdate]="false"
    (add)="onAddButtonClicked()"
    [items]="items" 
    [contextMenuItems]="contextMenuItems"
    [canDelete]="false"
    (onFilterValueChange)="onFilterValueChange($event)"
  >
  </app-sale-quote-grid>
 </syslink-block>

<app-document-create-modal
  #createModal
  title="Create Quote"
  [thirdFilter]="customerFilter"
  detailsUrl="/thirds/customers/"
  (validate)="onCreateValidated($event)"
></app-document-create-modal>

<syslink-confirm-modal
  #confirmModal
  (onValidate)="onConfirmModalValidated($event)"
></syslink-confirm-modal>
<app-sale-quote-status-select-modal
  #statusModal
  (validate)="onConfirmModalValidated($event)"
></app-sale-quote-status-select-modal>

<app-document-copy-modal
  #copyModal
  (validate)="onCopyButtonClicked($event)"
></app-document-copy-modal>