<div class="row">
  <div class="col-sm-3 col-xs-12 ">
    <div>
      <i
        class="text-primary text-center w-100 h5 py-1 m-0 fa-solid fa-user bg-light"
        style="font-size: 5rem"
      ></i>
    </div>
    <div *ngIf="user.Oid && authService.user?.Oid?.toString()!=user.Oid.toString()">
      <syslink-switch
        [(model)]="user.IsActive"
        type="box"
        [falseText]="'Blocked' | translate"
        [trueText]="'Active' | translate"
      ></syslink-switch>
    </div>
  </div>
  <div class="col-sm-9">
    <syslink-input-text
      label="Username"
      [(model)]="user.UserName"
      [usedModificationDetect]="false"
    ></syslink-input-text>

    <syslink-input-text
      label="Email"
      type="email"
      [(model)]="user.Email"
      [usedModificationDetect]="false"
    ></syslink-input-text>
  </div>
</div>