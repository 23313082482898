import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Task } from '../task.model';
import { WorkTypesService } from '../../../works/work-types/work-types.service';
import { ThirdsService } from 'projects/erp-app/src/app/thirds/thirds/thirds.service';
import { UserGroupsService } from 'projects/erp-app/src/app/core/auth/user-groups/user-groups.service';
import { ModalComponent, NotificationsService, TagboxComponent } from 'projects/libraries/syslink-components/src/public-api';
import { Router } from '@angular/router';
import { TasksService } from '../tasks.service';
import { UsersService } from 'projects/erp-app/src/app/core/auth/users/users.service';
import { TranslateService } from '@ngx-translate/core';
import { ViewComponent } from 'projects/libraries/syslink-components/src/lib/helpers/view/view.component';
import { WorkTimesService } from '../../../work-times/work-times/work-times.service';
import { WorkTimeModalComponent } from '../../../work-times/work-times/work-time-modal/work-time-modal.component';
import { WorkTime } from '../../../work-times/work-times/work-time';
import { TaskBillingTypesService } from '../../task-billing-types/task-billing-types.service';
import { User } from 'projects/erp-app/src/app/core/auth/users/user.model';
import { UserGroup } from 'projects/erp-app/src/app/core/auth/user-groups/user-group.model';
import { TaskParticipant } from '../../task-participants/task-participant.model';
import { TaskStatusesService } from '../../task-statuses/task-statuses.service';

@Component({
  selector: 'app-task-modal',
  templateUrl: './task-modal.component.html',
  styleUrls: ['./task-modal.component.scss']
})
export class TaskModalComponent extends ViewComponent implements OnInit {
  @Input() title: string = "Create new Task";
  @Output() public validate: EventEmitter<any> = new EventEmitter<any>();
  @Output() public onCancel: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild("modal") modal?: ModalComponent;
  @ViewChild("usersTagbox") usersTagbox?: TagboxComponent;
  @ViewChild("userGroupsTagbox") userGroupsTagbox?: TagboxComponent;
  @ViewChild('worktimeModal') worktimeModal?: WorkTimeModalComponent;

  public userAndUserGroupItems?: any;

  constructor(
    public WorkTypesService: WorkTypesService,
    public tasksService: TasksService,
    public thirdsService: ThirdsService,
    public userGroupsService: UserGroupsService,
    public translateService: TranslateService,
    public usersService: UsersService,
    protected override router: Router,
    private workTimesService: WorkTimesService,
    public taskBillingTypesService: TaskBillingTypesService,
    public taskStatusesService: TaskStatusesService
  ) {
    super();
  }

  override async ngOnInit(): Promise<void> {
    await this.loadUserAndUserGroup();
  }

  private async loadUserAndUserGroup() {
    this.userAndUserGroupItems = [];
    var users = await this.usersService.load({ filter: ["IsActive eq true"], select: ['Oid', 'Name', 'UserName'], expand: [] });
    var userGroups = await this.userGroupsService.load({ filter: ["IsActive eq true"], expand: ['NameTranslationId.Translations.LanguageId'], select: ['Id', 'Name'] });

    users.forEach((u: User) => {
      this.userAndUserGroupItems?.push({ Id: this.userAndUserGroupItems.length + 1, 'FormattedName': u.Name, user: u, isUser: true });
    })
    userGroups.forEach((u: UserGroup) => {
      this.userAndUserGroupItems?.push({ Id: this.userAndUserGroupItems.length + 1, 'FormattedName': u.Name, user: u, isUser: false });
    })
  }

  public open(task: Task) {
    this.modal?.open(task);
    if (!this.modal) return;
    this.modal.data.tagBoxTaskParticipants = [];
    this.modal.data.TaskParticipants.forEach((participant: TaskParticipant) => {
      this.modal?.data.tagBoxTaskParticipants.push(this.userAndUserGroupItems.find((u: any) => {
        if (u.isUser == true && participant.IsUser == true && u.user.Oid?.toString() == participant.UserId?.Oid?.toString()) {
          return u;
        }
        if (u.isUser == false && participant.IsUser == false && u.user.Id == participant.UserGroupId?.Id) {
          return u;
        }
      }));
    });
  }
  public close() {
    this.onCancel.emit();
    this.modal?.close();
  }


  async onValidate(e: any) {
    if (!this.authService.hasPermission('time-management.tasks.modals.update')) {
      NotificationsService.sendErrorMessage("You do not have the required permission!");
      return;
    }

    // UpdateTaskParticipant
    var participants = [...this.modal?.data.TaskParticipants];
    if (participants && this.modal) {
      this.modal.data.TaskParticipants = [];
      this.modal?.data.tagBoxTaskParticipants.forEach((p: any) => {
        if (!this.modal) return;
        var existingElement = participants.find((u: any) => {
          if (p.isUser == true && u.IsUser == true && p.user.Oid?.toString() == u.UserId?.Oid?.toString()) {
            return u;
          }
          if (p.isUser == false && u.IsUser == false && p.user.Id == u.UserGroupId?.Id) {
            return u;
          }
        })
        if (existingElement) {
          this.modal.data.TaskParticipants.push(existingElement)
        }
        else {
          this.modal.data.TaskParticipants.push(new TaskParticipant({
            IsUser: p.isUser,
            FormattedName: p.FormattedName,
            BackgroundColor: p.user.BackgroundColor,
            ForegroundColor: p.user.ForegroundColor,
            UserId: p.isUser ? p.user : undefined,
            UserGroupId: !p.isUser ? p.user : undefined,
            TaskId: this.modal.data
          }));
        }
      });
    }

    if (this.tasksService.validateElementInformations(this.modal?.data) == false) return;
    delete this.modal?.data.tagBoxTaskParticipants;

    if (this.validate.observed) {
      this.validate.emit(e);
    }
  }

  onViewMoreClicked() {
    if (this.modal?.data.Id) {
      this.router.navigateByUrl('/time-management/tasks/' + this.modal?.data.Id);
    }
    else {
      this.tasksService.saveInstance(this.modal?.data);
      this.router.navigateByUrl('/time-management/tasks/new');
    }
  }

  // WorkTime
  // --------
  public async onAddWorkTimeButtonClicked() {
    if (!this.modal) return;
    this.worktimeModal?.open(await this.workTimesService.getInstance({
      ThirdId: this.modal.data.ThirdId,
      TaskId: this.modal.data,
    }));
  }

  public async onValidateWorkTimeCreation(e: WorkTime) {
    try {
      await this.tasksService.createOrUpdate(this.tasksService.format(this.modal?.data));
      await this.tasksService.updateRelations(this.modal?.data);

      if (!e.Id) {
        await this.workTimesService.insert(await this.workTimesService.format(e));
        NotificationsService.sendSuccess("Record created");
      }
      else {
        await this.workTimesService.update(e.Id, await this.workTimesService.format(e));
        NotificationsService.sendSuccess("Record created");
      }

      this.worktimeModal?.close();
    } catch (_error) { }
  }
}
