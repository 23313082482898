import { Component, Input, ViewChild } from '@angular/core';
import { BaseGridComponent } from 'projects/libraries/syslink-components/src/lib/grid/base-grid/base-grid.component';
import { AccountMoveLine } from '../account-move-line.model';
import { GridComponent } from 'projects/libraries/syslink-components/src/public-api';
import { SyslinkColumn } from 'projects/libraries/syslink-components/src/lib/helpers/SyslinkColumn';
import { AccountMoveLinesService } from '../account-move-line.service';

@Component({
  selector: 'app-account-move-line-grid',
  templateUrl: './account-move-line-grid.component.html',
  styleUrls: ['./account-move-line-grid.component.scss']
})
export class AccountMoveLineGridComponent extends BaseGridComponent<AccountMoveLine> {

  @Input() storageKey: string = 'grid-account-move-lines';
  @Input() public override expand?: string | string[] = ['AccountMoveId', 'AccountId', 'ThirdId', 'SaleDocumentId', 'TaxId'];

  @ViewChild('gridComponent') public gridComponent?: GridComponent;

  public columns: SyslinkColumn[] = [
    new SyslinkColumn({ field: 'LineNo', sortable: true, sortIndex: 0, sortDirection: 'desc' }),
    new SyslinkColumn({ field: 'AccountMoveId.No', label: 'Account move' }),
    new SyslinkColumn({ field: 'Date', label: 'Date', type: 'date' }),
    new SyslinkColumn({ field: 'Debit', type: 'number' }),
    new SyslinkColumn({ field: 'Credit', type: 'number' }),
    new SyslinkColumn({ field: 'Deadline', label: 'Deadline', type: 'date' }),
    new SyslinkColumn({ field: 'ThirdId.Fullname', label: 'Third' }),
    new SyslinkColumn({ field: 'SaleDocumentId.NameFormatted', label: 'Document' }),
    new SyslinkColumn({ field: 'AccountId.FormattedName', label: 'Account' }),
    new SyslinkColumn({ field: 'TaxId.Alias', label: 'Tax' })
  ];

  constructor(
    public accountMoveLinesService: AccountMoveLinesService
  ) {
    super();
  }

}