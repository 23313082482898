import { Component, Input } from '@angular/core';
import { BootstrapBgColor } from '../helpers/BootstrapBgColor';
import { BootstrapTextColor } from '../helpers/BootstrapTextColor';

@Component({
  selector: 'syslink-block',
  templateUrl: './block.component.html',
  styleUrls: ['./block.component.scss']
})
export class BlockComponent {
  @Input() public title: string = '';
  @Input() public titleSize: 1 | 2 | 3 | 4 | 5 | 6 = 3;
  @Input() public titlePadding: 1 | 2 | 3 = 3;
  @Input() public translateTitle: boolean = true;
  @Input() public titleBgColor: BootstrapBgColor = 'secondary';
  @Input() public titleTextColor: BootstrapTextColor = 'white';

  @Input() public contentBgColor: BootstrapBgColor = 'white';
  @Input() public contentTextColor: BootstrapTextColor = 'black';

  @Input() public bordered: boolean = false;
  @Input() public borderColor: string = '$clr-gray';
}
