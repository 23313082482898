<syslink-block [title]="'Sale creditNotes'">
    <app-sale-credit-note-grid
        #grid
        [permissionKey]="'list'"
        [canDelete]="false"
        [items]="items"
        (add)="onAddButtonClicked()"
        [contextMenuItems]="contextMenuItems"
    ></app-sale-credit-note-grid>
</syslink-block>


<app-document-create-modal
  #createModal
  title="Create Credit Note"
  [thirdFilter]="customerFilter"
  detailsUrl="/thirds/customers/"
  (validate)="onCreateValidated($event)"
></app-document-create-modal>

<syslink-confirm-modal
    #confirmModal
    (onValidate)="onConfirmModalValidated($event)"
></syslink-confirm-modal>
<app-sale-credit-note-status-select-modal
    #statusModal
    (validate)="onConfirmModalValidated($event)"
></app-sale-credit-note-status-select-modal>

<!-- <app-payments
    *ngIf="paymentElement && paymentDocument"
    #paymentModal
    [documentType]="'SaleInvoice'"
    [element]="paymentElement"
    [document]="paymentDocument"
    (paymentsChange)="grid.refresh()"
    ></app-payments> -->
    <!-- [showGrid]="false" -->

    <syslink-modal
  *ngIf="paymentDocument && paymentDocument.Payments"
  #editPaymentModal
  title="payment"
  (onValidate)="onCreatePayment(newPayment)"
  validateText="Create"
  cancelText="Cancel"
>
  <app-payment-details
    *ngIf="newPayment"
    #paymentModal
    [(payment)]="newPayment"
    [documentType]="'SaleCreditNote'"
    [third]="paymentDocument.ThirdId"
    [amountRemaining]="paymentDocument.AmountRemaining"
    [documentNameFormatted]="paymentDocument.NameFormatted"
    [refundablePayments]="refundablePayments"
  ></app-payment-details>
</syslink-modal>

<app-document-copy-modal
  #copyModal
  (validate)="onCopyButtonClicked($event)"
></app-document-copy-modal>