<syslink-grid
    #gridComponent
    [gridName]="storageKey"
    [canView]="canView"
    [canSearch]="canSearch"
    [canRefresh]="canRefresh"
    [canAdd]="canAdd"
    [canSort]="canSort"
    [canUpdate]="canUpdate"
    [canDelete]="canDelete"
    [canFilter]="canFilter"
    [canExport]="canExport"
    [canDblClck]="canDblClck"
    [store]="sequencesService.store"
    [columns]="columns"
    [expand]="['ConfigId']"
    [storageKey]="storageKey"
></syslink-grid>
