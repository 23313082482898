import { UserGroup } from "../../../core/auth/user-groups/user-group.model";
import { User } from "../../../core/auth/users/user.model";
import { Entity } from "../../../core/models/entity";
import { Task } from "../tasks/task.model";

export class TaskParticipant extends Entity {
    public IsUser?:boolean=true;
    public FormattedName?:string="";
    public BackgroundColor?:string="";
    public ForegroundColor?:string="";
    public UserId?: User;
    public UserGroupId?: UserGroup;

    public TaskId:Task = new Task();

    constructor(entity?: TaskParticipant) {
        super();
        Object.assign(this, entity);
    }
}
