import { Component, Input, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmModalComponent, ModalComponent, NotificationsService, PageComponent } from 'projects/libraries/syslink-components/src/public-api';
import { ThirdsService } from '../../../thirds/thirds/thirds.service';
import { SaleCreditNotesService } from './sale-credit-notes.service';
import { SaleCreditNote } from './sale-credit-note.model';
import { PaymentsComponent } from '../../../base/payments/payments.component';
import { Payment } from '../../../base/payments/payment.model';
import { ContextMenuItemAction } from 'projects/libraries/syslink-components/src/lib/context-menus/context-menu-item-action';
import { ContextMenuItemClickEvent } from 'devextreme/ui/file_manager';
import { SaleCreditNoteGridComponent } from '../sale-credit-note-grid/sale-credit-note-grid.component';
import { SaleContexMenuItemActionCode } from '../../sale-documents/sale-documents/sale-documents.service';
import { SaleCreditNoteStatusSelectModalComponent } from '../sale-credit-note-status-select-modal/sale-credit-note-status-select-modal.component';
import { SaleCreditNoteHeaderHeaderStatus } from '../sale-credit-note-header-header-statuses/sale-credit-note-header-header-status.model';
import { SaleCreditNoteHeaderHeaderStatusesService } from '../sale-credit-note-header-header-statuses/sale-credit-note-header-header-statuses.service';
import { DocumentCreateModalComponent } from '../../../base/documents/document-create-modal/document-create-modal.component';
import { PaymentsService } from '../../../base/payments/payments.service';
import { PaymentMethodsService } from '../../../base/payments/payment-methods/payment-methods.service';
import { PaymentTypesService } from '../../../base/payments/payment-types/payment-types.service';
import { ConfigurationsService } from '../../../base/modules/configurations/configurations.service';
import { SaleCreditNoteHeaderStatus } from '../sale-credit-note-header-statuses/sale-credit-note-header-status.model';
import { DocumentCopyModalComponent } from '../../../base/documents/document-copy-modal/document-copy-modal.component';
import { NgxUiLoaderService } from 'ngx-ui-loader';
@Component({
  selector: 'app-sale-credit-notes',
  templateUrl: './sale-credit-notes.component.html',
  styleUrls: ['./sale-credit-notes.component.scss']
})
export class SaleCreditNotesComponent extends PageComponent {
  @Input() public filter: string | string[] = "";
  @Input() public canRefresh: boolean = false;


  @Input() public items?: SaleCreditNote[];


  @ViewChild('createModal') createModal?: DocumentCreateModalComponent;
  @ViewChild('grid') grid?: SaleCreditNoteGridComponent;
  @ViewChild("confirmModal") confirmModal?: ConfirmModalComponent;
  @ViewChild("statusModal") statusModal?: SaleCreditNoteStatusSelectModalComponent;
  @ViewChild("editPaymentModal") editPaymentModal?: ModalComponent;
  @ViewChild("paymentModal") paymentModal?: PaymentsComponent;
  @ViewChild('copyModal') public copyModal?: DocumentCopyModalComponent;

  // ContextMenuItemAction
  // ---------------------
  public contextMenuItems: ContextMenuItemAction[] = [
    { code: SaleContexMenuItemActionCode.PrintToPDF, text: "Preview to PDF", icon: "print", onItemClick: (e: any) => this.onMenuItemClicked(e), visible: this.authService.hasPermission('sales.credit-notes.list.contextMenu.preview') },
    { code: SaleContexMenuItemActionCode.PrintToZIP, text: "Download to ZIP", icon: "print", onItemClick: (e: any) => this.onMenuItemClicked(e), visible: this.authService.hasPermission('sales.credit-notes.list.contextMenu.preview') },
    { code: SaleContexMenuItemActionCode.Status, text: "Change status", icon: "edit", onItemClick: (e: any) => this.onMenuItemClicked(e), visible: this.authService.hasPermission('sales.credit-notes.list.contextMenu.changeStatus') },
    { code: SaleContexMenuItemActionCode.payment, text: "Add payment", icon: "money", onItemClick: (e: any) => this.onMenuItemClicked(e), visible: this.authService.hasPermission('sales.credit-notes.list.contextMenu.addPayment') },
    { code: SaleContexMenuItemActionCode.copy, text: "Copy", icon: "edit", onItemClick: (e: any) => this.onMenuItemClicked(e), visible: this.authService.hasPermission('sales.credit-notes.list.contextMenu.copy') }

  ];

  public customerFilter: string | string[] = ["Roles/any(r:r/Code eq 'customer')"];

  // Payment
  // -------
  public paymentDocument?: SaleCreditNote;
  public newPayment?: Payment;
  public refundablePayments: Payment[] = [];

  constructor(
    public saleCreditNotesService: SaleCreditNotesService,
    public override activatedRoute: ActivatedRoute,
    public thirdsService: ThirdsService,
    public override translateService: TranslateService,
    public saleCreditNoteHeaderHeaderStatusesService: SaleCreditNoteHeaderHeaderStatusesService,
    // private saleCreditNotesService: SaleCreditNotesService,
    // private saleCreditNoteHeaderHeaderStatusesService: SaleCreditNoteHeaderHeaderStatusesService,
    // private reportsService: ReportsService,
    private paymentsService: PaymentsService,
    public paymentMethodsService: PaymentMethodsService,
    private configurationsService: ConfigurationsService,
    private paymentTypesService: PaymentTypesService,
    public ngxUiLoaderService: NgxUiLoaderService
  ) {
    super();
  }

  public onAddButtonClicked() {
    this.createModal?.open();
  };

  public onCreateValidated(e: any) {
    if (!e.ThirdId.Id) {
      NotificationsService.sendErrorMessage("Please select third");
      return;
    }
    if (e.ThirdId.Blocked == true) {
      NotificationsService.sendErrorMessage("Third is blocked");
      return;
    }
    this.router.navigateByUrl('/sales/credit-notes/new?ThirdId=' + e.ThirdId.Id + (e.Subject != undefined ? '&Subject=' + e.Subject : '') + (e.ExternalReference != undefined ? '&ExternalReference=' + e.ExternalReference : ''));
  }

  // ContextMenu
  // -----------
  public async onMenuItemClicked(e: ContextMenuItemClickEvent) {

    if (!this.grid) return;

    switch (e.itemData.code) {
      case SaleContexMenuItemActionCode.PrintToPDF:
        this.showConfirmModal("Would you like preview selected rows?", e.itemData);
        break;
      case SaleContexMenuItemActionCode.PrintToZIP:
        this.showConfirmModal("Would you like download selected rows?", e.itemData);
        break;
      case SaleContexMenuItemActionCode.Status:
        this.showStatusModal("Would you like change status selected rows?", e.itemData);
        break;
      case SaleContexMenuItemActionCode.payment:
        if (this.grid?.getSelectedRows(e.itemData.rowData) && this.grid?.getSelectedRows(e.itemData.rowData)?.length > 1) {
          this.showConfirmModal("Would you like to pay the selected rows?", e.itemData);
        }
        else {
          await this.showPaymentModal("Would you like add payment", e.itemData);
        }
        break;
      case SaleContexMenuItemActionCode.copy:
        if (this.grid?.getSelectedRows(e.itemData.rowData).length > 1) {
          NotificationsService.sendSuccess('Please select only one line');
        }
        this.copyModal?.open({
          subject: e.itemData.rowData.Subject,
          elementId: e.itemData.rowData.Id,
          showThird: true,
          showSubject: true,
          title: "Copy",
          thirdFilter: this.customerFilter
        });
        break;
    }
  }

  private showConfirmModal(text: string, data: ContextMenuItemAction) {
    if (!this.confirmModal) return;

    this.confirmModal.title = data.text ? data.text : "Confirm";
    this.confirmModal.content = text;
    this.confirmModal.open({ rows: this.grid?.getSelectedRows(data.rowData), action: data.code });
  }
  private showStatusModal(text: string, data: ContextMenuItemAction) {
    if (!this.statusModal) return;

    this.statusModal.title = data.text ? data.text : "Confirm";
    this.statusModal.content = text;
    this.statusModal.open({ rows: this.grid?.getSelectedRows(data.rowData), action: data.code });
  }

  // Payments
  // --------
  public async initModalDatas(payment?: Payment) {
    this.newPayment = payment ?? await this.paymentsService.getInstance();
    this.newPayment.PaymentTypeId = (await this.paymentTypesService.load({ filter: ["Code eq 'Payment'"] }))[0];
    this.newPayment.DocumentId = this.paymentDocument?.Id;
    this.newPayment.Amount = this.paymentDocument?.AmountRemaining ?? 0;
    if (this.paymentDocument?.ThirdId.CustomerId?.PaymentMethodId != undefined) {
      this.newPayment.PaymentMethodPrimary = this.paymentDocument?.ThirdId.CustomerId?.PaymentMethodId;
    }
    if (this.newPayment.PaymentMethodPrimary == undefined || this.newPayment.PaymentMethodPrimary.Id == undefined) {
      var configurations = await this.configurationsService.getConfigurationAsNumber("paymentMethod", "Thirds.Customers", "Thirds");
      this.newPayment.PaymentMethodPrimary = await this.paymentMethodsService.findByID(configurations ?? 1);
    }
    this.newPayment.RoundedAmount = this.newPayment.Amount;
  }

  private updateRefundablePayments(payments: Payment[]): void {
    this.refundablePayments = payments.filter((payment: Payment) => {
      if (!payment.PaymentMethodPrimary) return false;
      if (
        // Mollie
        (payment.PaymentMethodPrimary.Code == "Mollie-Terminal") &&
        // Not refund
        payment.Amount > 0 &&
        // Paid
        payment.PaymentStateId != null && payment.PaymentStateId.Code == "paid") {

        const totalRefunded = payment.Refunds?.reduce((total, refund) => total + refund.Amount, 0) ?? 0;
        return payment.Amount > totalRefunded;
      }
      return false;
    });
  }

  private async showPaymentModal(text: string, data: ContextMenuItemAction) {
    if (this.grid?.getSelectedRows(data.rowData) && this.grid?.getSelectedRows(data.rowData)?.length > 1) {
      NotificationsService.sendErrorMessage("Please select only one line");
      return;
    }
    this.paymentDocument = await this.saleCreditNotesService.findByID(this.grid?.getSelectedRows(data.rowData)[0].Id, {
      select: ["ThirdId.Id", "AmountRemaining", "Id", "ThirdId.CustomerId.PaymentMethodId", "NameFormatted"],
      expand: ['Payments']
    })

    this.updateRefundablePayments(this.paymentDocument.Payments);

    this.newPayment = new Payment();
    await this.initModalDatas();
    this.editPaymentModal?.open({ newItem: true });
  }

  public async onCreatePayment(e: any) {
    this.editPaymentModal?.close();
    e.DocumentType = 'SaleCreditNoteHeader';
    if (e.PaymentTypeId && e.PaymentTypeId.Code == "Refund") {
      e.Amount = -e.Amount;
      e.RoundedAmount = -e.RoundedAmount;
    }
    await this.paymentsService.insert(this.paymentsService.format(e));
    this.grid?.refresh();
  }
  private async paidRows(rows: SaleCreditNote[]) {
    this.ngxUiLoaderService.start();
    let defaultPaymentMethod = (await this.paymentMethodsService.load({ filter: ["Code eq 'Bancontact'"] }))[0];
    let defaultPaymentType = (await this.paymentTypesService.load({ filter: ["Code eq 'Payment'"] }))[0];

    for (let index = 0; index < rows.length; index++) {
      let element = rows[index];
      if (element.AmountRemaining && element.AmountRemaining > 0) {
        var payment: Payment = await this.paymentsService.getInstance();
        payment.DocumentId = element.Id;
        payment.DocumentType = "SaleCreditNoteHeader";
        payment.Reference = element.No;
        payment.Amount = element.AmountRemaining;
        payment.RoundedAmount = element.AmountRemaining;
        payment.PaymentMethodPrimary = defaultPaymentMethod;
        payment.PaymentTypeId = defaultPaymentType;
        payment.AmountToReturn = 0;
        this.paymentsService.insert(this.paymentsService.format(payment));
      }
    }
    this.grid?.refresh();
    this.ngxUiLoaderService.stop();
  }

  public async onConfirmModalValidated(modalData: { rows: SaleCreditNote[], action: string } | any) {
    switch (modalData.action) {
      // Invoicing
      // ---------
      case SaleContexMenuItemActionCode.PrintToPDF:
        await this.saleCreditNotesService.printRows(modalData.rows, "pdf", "SaleCreditNote");
        break;
      case SaleContexMenuItemActionCode.PrintToZIP:
        await this.saleCreditNotesService.printRows(modalData.rows, "zip", "SaleCreditNote");
        break;
      case SaleContexMenuItemActionCode.Status:
        await this.updateStatus(modalData.rows, modalData.CreditNoteStatus);
        break;
      case SaleContexMenuItemActionCode.payment:
        await this.paidRows(modalData.rows);
        break;
    }

    this.grid?.refresh();
    this.confirmModal?.close();
    this.statusModal?.close();
  }
  // ------------------------------------------------------------------------------------------------



  // Actions
  // -------
  private async updateStatus(selectedRows: SaleCreditNote[], status: SaleCreditNoteHeaderStatus) {
    if (!this.saleCreditNotesService.canUpdateStatus(selectedRows, status)) return;
    selectedRows.sort((a, b) => { return a.Date.getTime() - b.Date.getTime(); });

    for (var element of selectedRows) {
      if (element.Id) {
        const link: SaleCreditNoteHeaderHeaderStatus = {
          HeaderId: element,
          StatusId: { Id: status.Id },
          Date: new Date()
        };
        await this.updateDocumentStatus(link);
      }
    }
    NotificationsService.sendSuccess('Status updated');
  }
  private async updateDocumentStatus(link: any) {
    let documentStatus = await this.saleCreditNoteHeaderHeaderStatusesService.insert(this.saleCreditNoteHeaderHeaderStatusesService.format(link));
  }

  // Copy
  // ----
  public async onCopyButtonClicked(e: any) {
    let elementId = e.data.elementId;
    let newThirdId = e.data.ThirdId.Id;
    let subject = e.data.subject;

    if (!elementId || !newThirdId) {
      NotificationsService.sendErrorMessage('Please select third');
      return;
    }
    this.ngxUiLoaderService.start();
    await this.saleCreditNotesService.copy("SaleCreditNote", await this.saleCreditNotesService.findByID(elementId), subject, await this.thirdsService.findByID(newThirdId));
    this.ngxUiLoaderService.stop();
  }
}