<syslink-grid
    #grid
    [canView]="canView"
    [canSearch]="canSearch"
    [canRefresh]="canRefresh"
    [canAdd]="canAdd"
    [canSort]="canSort"
    [canUpdate]="canUpdate"
    [canDelete]="canDelete"
    [canFilter]="canFilter"
    [canExport]="canExport"
    [canDblClck]="canDblClck"
    [items]="supplierCatalogs"
    [columns]="columns"
    storageKey="grid-products-purchase-supplier-catalog"
    exportFileName="Products suppliers catalogs"
    (add)="add.emit($event)"
    (beforeUpdate)="beforeUpdate.emit($event)"
    (beforeDelete)="beforeDelete.emit($event)"
    ></syslink-grid>