import { Component, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ConfirmModalComponent, DrawerItem, ModalComponent, NotificationsService, PageComponent, userMenuItem } from '../../../../../../libraries/syslink-components/src/public-api';
import { LanguagesService } from '../../../base/languages/languages.service';
import { ConfirmPasswordComponent } from 'projects/libraries/syslink-components/src/lib/modal/confirm-password/confirm-password.component';
import { User } from '../../auth/users/user.model';
import { ModuleInfoService } from '../../../base/modules/module-info/module-info.service';
import { environment } from '../../../../environments/environment';
import { NavigationEnd } from '@angular/router';
import { DrawerMenuItemsService } from '../../../base/menus/drawer-menu-items/drawer-menu-items.service';
import { UserPermission } from '../../auth/user-permissions/user-permission.model';
import { GroupPageService } from '../../../base/group-pages/group-page.service';
import { ModificationService } from '../../services/modification.service';
import { PrivateConfigurationsService } from '../../../base/private-configurations/private-configurations.service';
import { UserStoragesService } from '../../auth/user-storages/user-storage.service';
import { UserStorage } from '../../auth/user-storages/user-storage.model';

@Component({
  selector: 'app-admin',
  templateUrl: 'admin.component.html',
  styleUrls: ['admin.component.scss']
})
export class AdminComponent extends PageComponent implements OnInit, OnDestroy {
  @ViewChild('logoutConfirm') public logoutConfirm: ConfirmModalComponent = new ConfirmModalComponent();
  @ViewChild('profileModal') public profileModal: ModalComponent = new ModalComponent();
  @ViewChild('aboutModal') public aboutModal: ModalComponent = new ModalComponent();
  @ViewChild('passwordConfirm') public passwordConfirm: ConfirmPasswordComponent = new ConfirmPasswordComponent(this.translateService);//ConfirmPasswordComponent

  private shouldUpdateLanguage: boolean = false;
  public currentUser: User = new User();
  public checkUserPassword: boolean = false;
  // public moduleInfo: ModuleInfo = new ModuleInfo();
  public versionText: string = '';

  public permissionsIsLoaded: boolean = false;
public isLocalStorageLoaded: boolean = false;

  constructor(
    private languagesService: LanguagesService,
    // public authService: AuthService,
    // public translateService: TranslateService,
    // public usersService: UsersService,
    private moduleInfoService: ModuleInfoService,
    // private router: Router,
    private drawerMenuItemsService: DrawerMenuItemsService,
    public groupPageService: GroupPageService,
    private modService: ModificationService,
    public privateConfigurationsService: PrivateConfigurationsService,
    private userStoragesService: UserStoragesService
  ) {
    super();
    this.navigationSubscription = this.router.events.subscribe(async (event) => {
      if (event instanceof NavigationEnd) {
        this.router.navigated = false;
      }
    });
  }

  canDeactivate(): boolean {
    if (this.modService.hasModifications) {
      this.modService.setModifications(false);
      return confirm(this.translateService.instant("You have unsaved changes. Are you sure you want to leave?"));
    }
    return true;
  }
  
  @HostListener('window:beforeunload', ['$event'])
  unloadNotification($event: any): void {
    if (this.modService.hasModifications) {
      $event.returnValue = true; // Compatible avec certains navigateurs
      $event.preventDefault(); // Standard moderne pour certains navigateurs
      $event.returnValue = 'You have unsaved changes. Are you sure you want to leave?';
      this.modService.setModifications(false);
      return $event;
    }
  }

  override async ngOnInit() {
    if (this.languagesService.languages.length == 0) {
      this.languagesService.loadAvailableLanguages();
    }
    if (this.groupPageService.groupPages.length == 0) {
      this.groupPageService.groupPages = await this.groupPageService.load({ select: ['Id', 'GroupUrl'], expand: ['Parent'] });
      await this.loadLocalStorage();
    }
    if (this.privateConfigurationsService.allConfiguration.length == 0) {
      this.privateConfigurationsService.allConfiguration = await this.privateConfigurationsService.load();
      this.privateConfigurationsService.setConfigurations();
    }
    
    if (this.groupPageService.moduleInfoSyslink == undefined) {
      this.groupPageService.moduleInfoSyslink = (await this.moduleInfoService.load({ filter: ["Name eq 'ApplicationModule'"] }))[0];
      this.groupPageService.moduleInfoDevExpress = (await this.moduleInfoService.load({ filter: ["Name eq 'SystemModule'"] }))[0];
    }
    this.versionText = `© ${environment.erpName} ${this.groupPageService.moduleInfoSyslink.Version}`;

    if (this.authService.user) {
      var pageUrl = (this.newBasePermissionKey?.split("."))?.slice(0, 3).join(".");
      if (pageUrl != undefined && !this.groupPageService.isLoaded(pageUrl.replace(".details", ""))) {
        await this.groupPageService.loadUserPermissions(pageUrl.replace(".details", ""));
      }

      this.permissionsIsLoaded = true;

      this.currentUser = this.authService.user;

      // Fetching DrawwerMenuItems depending on userPermissions of Type 'MenuItem'
      // -------------------------------------------------------------------------
      var menuItemsPermissions: UserPermission[] = this.currentUser.AllPermissions?.filter(e => e.Type?.Code == "MenuItem");
      var menuItemsPermissionCodes: string[] = menuItemsPermissions?.map(e => e.Code);

      if (this.groupPageService.drawerItems == undefined) {
        this.groupPageService.drawerItems = this.filterPermissions((await this.drawerMenuItemsService.get("items")) ?? [], menuItemsPermissionCodes);
      }
    }
    
  }

  private filterPermissions(items?: DrawerItem[], menuItemsPermissionCodes: string[] = []): DrawerItem[] {
    items = items?.filter(e => menuItemsPermissionCodes.includes(e.id)) || [];
    items.forEach((child: DrawerItem) => {
      child.items = this.filterPermissions(child.items, menuItemsPermissionCodes);
    });

    return items || [];
  }

  // private override navigationSubscription: Subscription;
  override ngOnDestroy() {
    if (this.navigationSubscription) {
      this.navigationSubscription.unsubscribe();
    }
  }

  public userMenuItems: userMenuItem[] = [
    { id: 'profile', text: 'Profile', icon: 'fa-solid fa-user', click: () => this['onProfileButtonClicked']() },
    { id: 'logout', text: 'Logout', icon: 'fa-solid fa-right-from-bracket', click: () => { this.logoutConfirm.open(); } },
    { id: 'about', text: 'About', icon: 'fa-solid fa-circle-info', click: () => { this.aboutModal.open(); } },
    // {
    //   id: 'local-storage-clear', text: 'Clear local storage', icon: 'fa-solid fa-trash', click: () => {
    //     Object.keys(localStorage).forEach((key: string) => {
    //       if (key === 'access_token') return;
    //       localStorage.removeItem(key);
    //     });
    //   }
    // }
  ]

  // Profile
  // -------
  onProfileButtonClicked() {
    if (this.authService.user != undefined) {
      this.currentUser = this.authService.user;
    }
    this.profileModal.open();
  }
  onProfileModalValidate() {
    if (this.checkUserPassword == true) {
      this.passwordConfirm.open();
    }
    else {
      this.saveProfile('');
    }
  }

  public async saveProfile(password: any) {
    if (!this.currentUser || !this.currentUser.Oid) return;
    this.passwordConfirm.close();
    if (this.currentUser.Password != null || this.currentUser.PasswordConfirmation != null) {
      if (this.currentUser.Password != this.currentUser.PasswordConfirmation) {
        NotificationsService.sendErrorMessage("Password and password confirmation is not correct");
        return;
      }
      await this.usersService.updatePassword(this.currentUser);
    }

    await this.usersService.update(this.currentUser.Oid, this.usersService.format(this.currentUser));

    await this.authService.reloadUser();
    this.profileModal.close();
    NotificationsService.sendSuccess("Record updated");
    // if (this.shouldUpdateLanguage) {
    //   this.updateLanguage();
    // }
  }

  private async loadLocalStorage() {
    if(!this.authService.user || !this.authService.user?.Oid?.toString()) return;
    var userStorages = await this.userStoragesService.load({filter:["UserId.Oid eq "+this.authService.user?.Oid?.toString()]});
    Object.keys(localStorage).forEach((key: string) => {
      if (key === 'access_token') return;
      localStorage.removeItem(key);
    });

    userStorages.forEach((element:UserStorage) => {
      localStorage.setItem(element.Key, element.Value);
    });
    this.isLocalStorageLoaded = true;
  }
}
