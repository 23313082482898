import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { DxSelectBoxComponent } from 'devextreme-angular';
import DataSource from 'devextreme/data/data_source';
import ODataStore from 'devextreme/data/odata/store';
import { CustomItemCreatingEvent, FocusInEvent, FocusOutEvent } from 'devextreme/ui/select_box';
import { InputComponent } from '../input/input.component';

@Component({
  selector: 'syslink-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss']
})
export class SelectComponent extends InputComponent implements OnInit {
  // Local items
  // -----------
  @Input() public items?: any[];
  @Output() public itemsChange: EventEmitter<any[]> = new EventEmitter<any[]>;

  // Remote items
  // ------------
  public dataSource: DataSource = new DataSource({});
  @Input() public store?: ODataStore;
  @Input() public expand?: string | string[];
  @Input() public select?: string | string[];
  @Input() public sort?: string | string[];
  // Filter
  private _filter?: any[];
  @Input()
  set filter(value: any) {
    this._filter = value;
    this.filterChange.emit(this._filter);
    this.forceReload();
  }
  get filter(): any[] | undefined {
    return this._filter;
  }
  @Output() public filterChange: EventEmitter<any[]> = new EventEmitter<any[]>();

  @Input() public displayKey: string = 'Name';
  @Input() public valueKey: string = 'this';
  @Input() public keyType: string = 'Int32';

  @Input() public allowSearch: boolean = true;
  @Input() public allowAddCustomItem: boolean = true;

  // Custom item
  // -----------
  @Output() public onCustomValue: EventEmitter<string> = new EventEmitter<string>();

  // Additional icons
  // ----------------
  @Input() public detailsUrl?: string;
  @Input() public canView: boolean = false;
  @Input() public canRefresh: boolean = false;
  @Input() public canAdd: boolean = false;
  @Output() public onShowAdvancedSearch: EventEmitter<string> = new EventEmitter<string>();
  @Output() public onAdd: EventEmitter<any> = new EventEmitter<any>();
  public override class: string = 'py-2';
  @Input() public allowClearing: boolean = false;

  private modelWhenFocusedIn : any;

  @ViewChild('select') public selectComponent?: DxSelectBoxComponent;

  ngOnInit(): void {
    if (!this.store){
      // WIP Try Fix Bug Items
      // this.model = this.model[this.valueKey];
      return;
    }

    this.items = undefined;
    this.reloadDatasource();
  }

  public getDisplayValue(item: any): string {
    if (!item) return '';

    let value: any = item;
    this.displayKey.split('.').forEach((key: string) => {
      value = value.hasOwnProperty(key) ? value[key] : value
    });

    return typeof value === 'string' ? value : '';
  }

  public onCustomItemCreating(event: CustomItemCreatingEvent) {
    if (!this.dataSource) return;
    event.customItem = { [this.valueKey]: this.dataSource.totalCount() + 1, [this.displayKey]: event.text };
    this.onCustomValue.emit(event.text);
  }

  private reloadDatasource() {
    this.dataSource = new DataSource({
      store: this.store,
      sort: this.sort ?? [this.displayKey],
      filter: this.filter,
      expand: this.expand,
      select: this.select,
    })
  }

  public forceReload(): void {
    this.reloadDatasource();
    this.selectComponent?.instance.getDataSource().reload();
  }

  public onFocusIn(event: FocusInEvent){
    if(this.allowSearch && !this.allowClearing){
      this.modelWhenFocusedIn = this.model;
      this.model = null;
    }
  }

  public onFocusOut(event: FocusOutEvent){
    if(this.allowSearch && this.model == null && !this.allowClearing){
      this.model = this.modelWhenFocusedIn;
    }
  }
}
