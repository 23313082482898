import { SaleDocumentHeaderStatus } from "../../sale-documents/sale-document-header-status/sale-document-header-status.model";
import { SaleContractHeaderStatus } from "../sale-contract-header-statuses/sale-contract-header-status.model";
import { SaleContract } from "../sale-contracts/sale-contract.model";

export class SaleContractHeaderHeaderStatus extends SaleDocumentHeaderStatus {
    public override HeaderId?: SaleContract;
    public override StatusId?: SaleContractHeaderStatus = new SaleContractHeaderStatus();

    constructor(entity?: Partial<SaleContractHeaderHeaderStatus>) {
        super();
        Object.assign(this, entity);
    }

}