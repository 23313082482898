import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './core/pages/login/login.component';
import { AdminComponent } from './core/pages/admin/admin.component';
import { DashboardComponent } from './core/pages/dashboard/dashboard.component';
import { BasicComponent } from './base/basics/basic.component';
import { FindByIdResolver } from './core/resolvers/find-by-id.resolver';
import { ModuleDetailsComponent } from './base/modules/modules/module-details/module-details.component';
import { ProductsComponent } from './products/products/products.component';
import { CustomersComponent } from './thirds/customers/customers/customers.component';
import { SuppliersComponent } from './thirds/suppliers/suppliers.component';
import { ThirdDetailsComponent } from './thirds/thirds/third-details/third-details.component';
import { ThirdRole, ThirdRoleIds } from './thirds/thirds/third-roles/third-role.model';
import { ThirdsComponent } from './thirds/thirds/thirds.component';
import { AuthGuard } from './core/auth/auth.guard';
import { ModulesComponent } from './base/modules/modules/modules.component';
import { ProductDetailsComponent } from './products/products/product-details/product-details.component';
import { SaleQuotesComponent } from './sales/sale-quotes/sale-quotes/sale-quotes.component';
import { RoleGuard } from './core/pages/role.guard';
import { ThirdsService } from './thirds/thirds/thirds.service';
import { ProductsService } from './products/products/product.service';
import { ModulesService } from './base/modules/modules/modules.service';
import { TaxesComponent } from './accountings/taxes/taxes.component';
import { AccountsComponent } from './accountings/accounts/accounts.component';
import { PaymentComponent } from './core/pages/payment/payment.component';
import { SaleQuotesService } from './sales/sale-quotes/sale-quotes/sale-quotes.service';
import { SaleInvoicesComponent } from './sales/sale-invoices/sale-invoices/sale-invoices.component';
import { SaleInvoicesService } from './sales/sale-invoices/sale-invoices/sale-invoices.service';
import { SaleQuoteDetailsComponent } from './sales/sale-quotes/sale-quote-details/sale-quote-details.component';
import { SaleInvoiceDetailsComponent } from './sales/sale-invoices/sale-invoice-details/sale-invoice-details.component';
import { SaleCreditNotesComponent } from './sales/sale-credit-notes/sale-credit-notes/sale-credit-notes.component';
import { SaleCreditNoteDetailsComponent } from './sales/sale-credit-notes/sale-credit-note-details/sale-credit-note-details.component';
import { SaleCreditNotesService } from './sales/sale-credit-notes/sale-credit-notes/sale-credit-notes.service';
import { UsersComponent } from './core/auth/users/users.component';
import { UserDetailsComponent } from './core/auth/users/user-details/user-details.component';
import { UsersService } from './core/auth/users/users.service';
import { FindByOidResolver } from './core/resolvers/find-by-oid.resolver';
import { ReportpreviewComponent } from './core/pages/reportpreview/reportpreview.component';
import { UserGroupDetailsComponent } from './core/auth/user-groups/user-group-details/user-group-details.component';
import { UserGroupsService } from './core/auth/user-groups/user-groups.service';
import { UserGroupsComponent } from './core/auth/user-groups/user-groups.component';
import { UserPermissionsComponent } from './core/auth/user-permissions/user-permissions.component';
import { UserPermissionDetailsComponent } from './core/auth/user-permissions/user-permission-details/user-permission-details.component';
import { UserPermissionsService } from './core/auth/user-permissions/user-permissions.service';
import { UserRolesComponent } from './core/auth/user-roles/user-roles.component';
import { UserRoleDetailsComponent } from './core/auth/user-roles/user-role-details/user-role-details.component';
import { UserRolesService } from './core/auth/user-roles/user-roles.service';
import { SaleContractsService } from './sales/sale-contracts/sale-contracts/sale-contracts.service';
import { SaleContractDetailsComponent } from './sales/sale-contracts/sale-contract-details/sale-contract-details.component';
import { SaleContractsComponent } from './sales/sale-contracts/sale-contracts/sale-contracts.component';
import { TasksComponent } from './time-management/tasks/tasks/tasks.component';
import { TaskDetailComponent } from './time-management/tasks/tasks/task-detail/task-detail.component';
import { TasksService } from './time-management/tasks/tasks/tasks.service';
import { FetchConfigurationResolver } from './core/resolvers/fetch-configuration.resolver';
import { MailTemplatesComponent } from './mails/mail-templates/mail-templates.component';
import { WorkTimesComponent } from './time-management/work-times/work-times/work-times.component';
import { AssignementPriceCoefsComponent } from './time-management/shared/assignement-price-coefs/assignement-price-coefs.component';
import { SaleOrdersComponent } from './sales/sale-orders/sale-orders/sale-orders.component';
import { SaleOrderDetailsComponent } from './sales/sale-orders/sale-order-details/sale-order-details.component';
import { SaleOrdersService } from './sales/sale-orders/sale-orders/sale-orders.service';
import { PurchaseOrdersComponent } from './purchases/purchase-orders/purchase-orders/purchase-orders.component';
import { PurchaseOrdersService } from './purchases/purchase-orders/purchase-orders/purchase-orders.service';
import { PurchaseOrderDetailsComponent } from './purchases/purchase-orders/purchase-order-details/purchase-order-details.component';
import { PurchaseInvoicesComponent } from './purchases/purchase-invoices/purchase-invoices/purchase-invoices.component';
import { PurchaseInvoiceDetailsComponent } from './purchases/purchase-invoices/purchase-invoice-details/purchase-invoice-details.component';
import { PurchaseInvoicesService } from './purchases/purchase-invoices/purchase-invoices/purchase-invoices.service';
import { PurchaseCreditNotesComponent } from './purchases/purchase-credit-notes/purchase-credit-notes/purchase-credit-notes.component';
import { PurchaseCreditNoteDetailsComponent } from './purchases/purchase-credit-notes/purchase-credit-note-details/purchase-credit-note-details.component';
import { PurchaseCreditNotesService } from './purchases/purchase-credit-notes/purchase-credit-notes/purchase-credit-notes.service';
import { StockMoveCustomerOutgoingsComponent } from './stocks/stock-move-customer-outgoings/stock-move-customer-outgoings.component';
import { StockMoveCustomerOutgoingDetailsComponent } from './stocks/stock-move-customer-outgoings/stock-move-customer-outgoing-details/stock-move-customer-outgoing-details.component';
import { StockMoveOutgoingsService } from './stocks/stock-move-outgoings/stock-move-outgoings/stock-move-outgoings.service';
import { StockMoveCustomerIncomingsComponent } from './stocks/stock-move-customer-incomings/stock-move-customer-incomings.component';
import { StockMoveCustomerIncomingDetailsComponent } from './stocks/stock-move-customer-incomings/stock-move-customer-incoming-details/stock-move-customer-incoming-details.component';
import { StockMoveIncomingsService } from './stocks/stock-move-incomings/stock-move-incomings/stock-move-incomings.service';
import { StockMoveSupplierIncomingsComponent } from './stocks/stock-move-supplier-incomings/stock-move-supplier-incomings.component';
import { StockMoveSupplierIncomingDetailsComponent } from './stocks/stock-move-supplier-incomings/stock-move-supplier-incoming-details/stock-move-supplier-incoming-details.component';
import { StockMoveSupplierOutgoingsComponent } from './stocks/stock-move-supplier-outgoings/stock-move-supplier-outgoings.component';
import { StockMoveSupplierOutgoingDetailsComponent } from './stocks/stock-move-supplier-outgoings/stock-move-supplier-outgoing-details/stock-move-supplier-outgoing-details.component';
import { StockMoveInternalsComponent } from './stocks/stock-move-internals/stock-move-internals/stock-move-internals.component';
import { StockMoveInternalDetailsComponent } from './stocks/stock-move-internals/stock-move-internals/stock-move-internal-details/stock-move-internal-details.component';
import { StockMoveInternalsService } from './stocks/stock-move-internals/stock-move-internals/stock-move-internals.service';
import { CustomerCatalogGeneralFormComponent } from './products/customer-catalogs/customer-catalog-general-form/customer-catalog-general-form.component';
import { UnsavedChangesGuard } from './core/pages/unsaved-changes-guard/unsaved-changes-guard';
import { AccountMovesComponent } from './accountings/account-moves/account-moves.component';
import { AccountMoveDetailsComponent } from './accountings/account-moves/account-move-details/account-move-details.component';
import { AccountMovesService } from './accountings/account-moves/account-move.service';
import { AccountMoveLinesComponent } from './accountings/account-move-lines/account-move-lines.component';
import { SequenceComponent } from './core/sequences/sequence/sequence.component';
import { SequenceConfigurationsComponent } from './core/sequences/sequence-configurations/sequence-configurations.component';
import { BankReconciliationsComponent } from './accountings/bank-reconciliations/bank-reconciliations.component';
import { BankReconciliationDetailsComponent } from './accountings/bank-reconciliations/bank-reconciliation-details/bank-reconciliation-details.component';
import { BankReconciliationsService } from './accountings/bank-reconciliations/bank-reconciliation.service';


const routes: Routes = [
  { path: 'login', component: LoginComponent, canActivate: [AuthGuard] },
  {
    path: '', component: AdminComponent, canActivate: [AuthGuard], children: [
      { path: '', component: DashboardComponent },
      {
        path: 'thirds', canActivate: [RoleGuard], data: { expectedPermissions: ['ApplicationUser'] },
        children: [
          { path: '', pathMatch: 'full', redirectTo: 'customers' },
          {
            path: 'customers',
            children: [
              { path: '', component: CustomersComponent },
              { path: 'archives', component: CustomersComponent },
              { path: ':id', canDeactivate: [UnsavedChangesGuard], component: ThirdDetailsComponent, data: { service: ThirdsService, params: { Roles: [new ThirdRole({ Id: ThirdRoleIds.Customer })]/*, ThirdTypeId: new ThirdType({ Id: ThirdTypeIds.Person }) */ } }, resolve: { element: FindByIdResolver } }
            ]
          },
          {
            path: 'suppliers',
            children: [
              { path: '', component: SuppliersComponent },
              { path: 'archives', component: SuppliersComponent },
              { path: ':id', canDeactivate: [UnsavedChangesGuard], component: ThirdDetailsComponent, data: { service: ThirdsService, params: { Roles: [new ThirdRole({ Id: ThirdRoleIds.Supplier })]/*, ThirdTypeId: new ThirdType({ Id: ThirdTypeIds.Company })*/ } }, resolve: { element: FindByIdResolver } }
            ]
          }
        ]
      },
      {
        path: 'products',
        children: [
          { path: '', pathMatch: 'full', redirectTo: 'products' },
          {
            path: 'products',
            children: [
              { path: '', component: ProductsComponent, data: { subModules: ['Products.General'] }, resolve: { configurations: FetchConfigurationResolver } },
              { path: 'archives', component: ProductsComponent },
              { path: ':id', canDeactivate: [UnsavedChangesGuard], component: ProductDetailsComponent, data: { service: ProductsService, subModules: ['Products.General', 'Products.SupplierCatalogs', 'Products.CustomerCatalogs'] }, resolve: { element: FindByIdResolver, configurations: FetchConfigurationResolver } },
            ]
          }
        ]
      },
      {
        path: 'sales',
        children: [
          { path: '', pathMatch: 'full', redirectTo: 'quotes' },
          {
            path: 'quotes',
            children: [
              { path: '', component: SaleQuotesComponent, data: { subModules: ['Sales.Quote'] }, resolve: { configurations: FetchConfigurationResolver } },
              { path: 'copy', canDeactivate: [UnsavedChangesGuard], component: SaleQuoteDetailsComponent },
              { path: ':id', canDeactivate: [UnsavedChangesGuard], component: SaleQuoteDetailsComponent, data: { service: SaleQuotesService, subModules: ['Base.General', 'Sales.Quote'] }, resolve: { element: FindByIdResolver, configurations: FetchConfigurationResolver } }
            ]
          },
          { path: '', pathMatch: 'full', redirectTo: 'orders' },
          {
            path: 'orders',
            children: [
              { path: '', component: SaleOrdersComponent, data: { subModules: ['Sales.Order'] }, resolve: { configurations: FetchConfigurationResolver } },
              { path: 'copy', canDeactivate: [UnsavedChangesGuard], component: SaleOrderDetailsComponent },
              { path: ':id', canDeactivate: [UnsavedChangesGuard], component: SaleOrderDetailsComponent, data: { service: SaleOrdersService, subModules: ['Base.General', 'Sales.Order'] }, resolve: { element: FindByIdResolver, configurations: FetchConfigurationResolver } }
            ]
          },
          { path: '', pathMatch: 'full', redirectTo: 'invoices' },
          {
            path: 'invoices',
            children: [
              { path: '', component: SaleInvoicesComponent, data: { subModules: ['Sales.Invoice'] }, resolve: { configurations: FetchConfigurationResolver } },
              { path: 'copy', canDeactivate: [UnsavedChangesGuard], component: SaleInvoiceDetailsComponent },
              { path: ':id', canDeactivate: [UnsavedChangesGuard], component: SaleInvoiceDetailsComponent, data: { service: SaleInvoicesService, subModules: ['Base.General', 'Sales.Invoice'] }, resolve: { element: FindByIdResolver, configurations: FetchConfigurationResolver } }
            ]
          },
          { path: '', pathMatch: 'full', redirectTo: 'credit-notes' },
          {
            path: 'credit-notes',
            children: [
              { path: '', component: SaleCreditNotesComponent, data: { subModules: ['Sales.CreditNote'] }, resolve: { configurations: FetchConfigurationResolver } },
              { path: 'copy', canDeactivate: [UnsavedChangesGuard], component: SaleCreditNoteDetailsComponent },
              { path: ':id', canDeactivate: [UnsavedChangesGuard], component: SaleCreditNoteDetailsComponent, data: { service: SaleCreditNotesService, subModules: ['Base.General', 'Sales.CreditNote'] }, resolve: { element: FindByIdResolver, configurations: FetchConfigurationResolver } }
            ]
          },
          { path: '', pathMatch: 'full', redirectTo: 'contracts' },
          {
            path: 'contracts',
            children: [
              { path: '', component: SaleContractsComponent, data: { subModules: ['Sales.Contract'] }, resolve: { configurations: FetchConfigurationResolver } },
              { path: 'copy', canDeactivate: [UnsavedChangesGuard], component: SaleContractDetailsComponent },
              { path: ':id', component: SaleContractDetailsComponent, data: { service: SaleContractsService, subModules: ['Base.General', 'Sales.Contract'] }, resolve: { element: FindByIdResolver, configurations: FetchConfigurationResolver } }
            ]
          },
          { path: 'payment', component: PaymentComponent },
        ]
      },
      {
        path: 'purchases',
        children: [
          { path: '', pathMatch: 'full', redirectTo: 'orders' },
          {
            path: 'orders',
            children: [
              { path: '', component: PurchaseOrdersComponent, data: { subModules: ['Purchases.Order'] }, resolve: { configurations: FetchConfigurationResolver } },
              { path: ':id', component: PurchaseOrderDetailsComponent, data: { service: PurchaseOrdersService, subModules: ['Base.General', 'Purchases.Order'] }, resolve: { element: FindByIdResolver, configurations: FetchConfigurationResolver } }
            ]
          },
          { path: '', pathMatch: 'full', redirectTo: 'invoices' },
          {
            path: 'invoices',
            children: [
              { path: '', component: PurchaseInvoicesComponent, data: { subModules: ['Purchases.Invoice'] }, resolve: { configurations: FetchConfigurationResolver } },
              { path: ':id', component: PurchaseInvoiceDetailsComponent, data: { service: PurchaseInvoicesService, subModules: ['Base.General', 'Purchases.Invoice'] }, resolve: { element: FindByIdResolver, configurations: FetchConfigurationResolver } }
            ]
          },
          { path: '', pathMatch: 'full', redirectTo: 'quotes' },
          {
            path: 'credit-notes',
            children: [
              { path: '', component: PurchaseCreditNotesComponent, data: { subModules: ['Purchases.CreditNote'] }, resolve: { configurations: FetchConfigurationResolver } },
              { path: ':id', component: PurchaseCreditNoteDetailsComponent, data: { service: PurchaseCreditNotesService, subModules: ['Base.General', 'Purchases.CreditNote'] }, resolve: { element: FindByIdResolver, configurations: FetchConfigurationResolver } }
            ]
          },
        ]
      },
      {
        path: 'stockmoves',
        children: [
          { path: '', pathMatch: 'full', redirectTo: 'stockmoves' },
          {
            path: 'customeroutgoings',
            children: [
              { path: '', component: StockMoveCustomerOutgoingsComponent, data: { subModules: ['Stocks.CustomerOutgoing'] }, resolve: { configurations: FetchConfigurationResolver } },
              { path: ':id', component: StockMoveCustomerOutgoingDetailsComponent, data: { service: StockMoveOutgoingsService, subModules: ['Base.General', 'Stocks.CustomerOutgoing'] }, resolve: { element: FindByIdResolver, configurations: FetchConfigurationResolver } }
            ]
          },
          {
            path: 'customerincomings',
            children: [
              { path: '', component: StockMoveCustomerIncomingsComponent, data: { subModules: ['Stocks.CustomerIncoming'] }, resolve: { configurations: FetchConfigurationResolver } },
              { path: ':id', component: StockMoveCustomerIncomingDetailsComponent, data: { service: StockMoveIncomingsService, subModules: ['Base.General', 'Stocks.CustomerIncoming'] }, resolve: { element: FindByIdResolver, configurations: FetchConfigurationResolver } }
            ]
          },
          {
            path: 'supplierincomings',
            children: [
              { path: '', component: StockMoveSupplierIncomingsComponent, data: { subModules: ['Stocks.SupplierIncoming'] }, resolve: { configurations: FetchConfigurationResolver } },
              { path: ':id', component: StockMoveSupplierIncomingDetailsComponent, data: { service: StockMoveIncomingsService, subModules: ['Base.General', 'Stocks.SupplierIncoming'] }, resolve: { element: FindByIdResolver, configurations: FetchConfigurationResolver } }
            ]
          },
          {
            path: 'supplieroutgoings',
            children: [
              { path: '', component: StockMoveSupplierOutgoingsComponent, data: { subModules: ['Stocks.SupplierOutgoing'] }, resolve: { configurations: FetchConfigurationResolver } },
              { path: ':id', component: StockMoveSupplierOutgoingDetailsComponent, data: { service: StockMoveOutgoingsService, subModules: ['Base.General', 'Stocks.SupplierOutgoing'] }, resolve: { element: FindByIdResolver, configurations: FetchConfigurationResolver } }
            ]
          },
          {
            path: 'internalstockmoves',
            children: [
              { path: '', component: StockMoveInternalsComponent, data: { subModules: ['Stocks.Internal'] }, resolve: { configurations: FetchConfigurationResolver } },
              { path: ':id', component: StockMoveInternalDetailsComponent, data: { service: StockMoveInternalsService, subModules: ['Base.General', 'Stocks.Internal'] }, resolve: { element: FindByIdResolver, configurations: FetchConfigurationResolver } }
            ]
          },
        ]
      },
      {
        path: 'time-management',
        children: [
          { path: '', pathMatch: 'full', redirectTo: 'tasks' },
          {
            path: 'tasks',
            children: [
              { path: '', component: TasksComponent },
              // { path: 'me', component: MyTaskComponent, },
              { path: ':id', canDeactivate: [UnsavedChangesGuard], component: TaskDetailComponent, data: { service: TasksService, subModules: ['Base.General'] }, resolve: { element: FindByIdResolver, configurations: FetchConfigurationResolver } }
            ]
          },
          {
            path: 'work-times',
            children: [
              { path: '', component: WorkTimesComponent },
              // { path: 'me', component: MyTaskComponent, },
              // { path: ':id', component: TaskDetailComponent, data: { service: TasksService, subModules: ['Base.General'] }, resolve: { element: FindByIdResolver, configurations: FetchConfigurationResolver } }
            ]
          },
          // {
          //   path: 'appointments',
          //   children: [
          //     { path: '', component: AppointmentsComponent },
          //     // { path: 'me', component: MyTaskComponent, },
          //     // { path: ':id', component: TaskDetailComponent, data: { service: TasksService, subModules: ['Base.General'] }, resolve: { element: FindByIdResolver, configurations: FetchConfigurationResolver } }
          //   ]
          // },
        ]
      },
      {
        path: 'account',
        children: [
          { path: '', pathMatch: 'full', redirectTo: 'moves' },
          {
            path: 'moves',
            children: [
              { path: '', component: AccountMovesComponent },
              { path: ':id', canDeactivate: [UnsavedChangesGuard], component: AccountMoveDetailsComponent, data: { service: AccountMovesService, subModules: ['Accountings.General'] }, resolve: { element: FindByIdResolver, configurations: FetchConfigurationResolver } }
            ]
          },
          {
            path: 'move-lines',
            children: [
              { path: '', component: AccountMoveLinesComponent },
            ]
          },
          {
            path: 'bankreconciliations',
            children: [
              { path: '', component: BankReconciliationsComponent },
              { path: ':id', canDeactivate: [UnsavedChangesGuard], component: BankReconciliationDetailsComponent, data: { service: BankReconciliationsService, subModules: ['Accountings.General'] }, resolve: { element: FindByIdResolver, configurations: FetchConfigurationResolver } }
            ]
          },
        ]
      },
      {
        path: 'settings',
        children: [
          {
            path: 'modules',
            children: [
              { path: '', component: ModulesComponent },
              { path: ':id', component: ModuleDetailsComponent, data: { service: ModulesService }, resolve: { element: FindByIdResolver } }
            ]
          },
        ]
      },
      {
        path: 'securities',
        children: [
          { path: '', pathMatch: 'full', redirectTo: 'users' },
          {
            path: 'userGroups',
            children: [
              { path: '', component: UserGroupsComponent },
              { path: ':id', component: UserGroupDetailsComponent, data: { service: UserGroupsService }, resolve: { element: FindByIdResolver } }
            ]
          },
          {
            path: 'userPermissions',
            children: [
              { path: '', component: UserPermissionsComponent },
              { path: ':id', component: UserPermissionDetailsComponent, data: { service: UserPermissionsService }, resolve: { element: FindByIdResolver } }
            ]
          },
          {
            path: 'userRoles',
            children: [
              { path: '', component: UserRolesComponent },
              { path: ':id', component: UserRoleDetailsComponent, data: { service: UserRolesService }, resolve: { element: FindByIdResolver } }
            ]
          },
          {
            path: 'users',
            children: [
              { path: '', component: UsersComponent },
              { path: ':oid', component: UserDetailsComponent, data: { service: UsersService }, resolve: { element: FindByOidResolver } }
            ]
          },

        ]
      },
      {
        path: 'basics',
        children: [
          {
            path: 'base',
            children: [
              { path: 'origins', component: BasicComponent, data: { basic: 'Origin' } },
              { path: 'taxrates', component: BasicComponent, data: { basic: 'TaxRate' } },
              { path: 'countries', component: BasicComponent, data: { basic: 'Country' } },
              { path: 'currencies', component: BasicComponent, data: { basic: 'Currency' } },
              { path: 'units', component: BasicComponent, data: { basic: 'Unit' } },
              { path: 'labeltranslations', component: BasicComponent, data: { basic: 'LabelTranslation' } },
              { path: 'banks', component: BasicComponent, data: { basic: 'Bank' } },
            ]
          },
          {
            path: 'thirds',
            children: [
              {
                path: 'thirds',
                children: [
                  { path: '', component: ThirdsComponent },
                  { path: 'archives', component: ThirdsComponent },
                  { path: ':id', component: ThirdDetailsComponent, data: { service: ThirdsService }, resolve: { element: FindByIdResolver } }
                ]
              },
              { path: 'legalforms', component: BasicComponent, data: { basic: 'LegalForm' } },
              { path: 'activitysectors', component: BasicComponent, data: { basic: 'ActivitySector' } },
              { path: 'thirdtitles', component: BasicComponent, data: { basic: 'ThirdTitle' } },
              { path: 'contacttypes', component: BasicComponent, data: { basic: 'ContactType' } },
              { path: 'contactqualifiers', component: BasicComponent, data: { basic: 'ContactQualifier' } },
              { path: 'thirdrelationtype', component: BasicComponent, data: { basic: 'Thirdrelationtype' } },
            ]
          },
          {
            path: 'customers',
            children: [
              { path: 'documentdelays', component: BasicComponent, data: { basic: 'DocumentDelay' } },
              { path: 'paymentmethods', component: BasicComponent, data: { basic: 'PaymentMethod' } },
              { path: 'pricelists', component: BasicComponent, data: { basic: 'PriceList' } },
              { path: 'paymenttypes', component: BasicComponent, data: { basic: 'PaymentType' } },
            ]
          },
          {
            path: 'suppliers',
            children: [
              { path: 'deliverytimes', component: BasicComponent, data: { basic: 'DeliveryTime' } }
            ]
          },
          {
            path: 'products',
            children: [
              { path: 'categories', component: BasicComponent, data: { basic: 'ProductCategory' } },
              { path: 'brands', component: BasicComponent, data: { basic: 'Brand' } },
              { path: 'makes', component: BasicComponent, data: { basic: 'Make' } },
              { path: 'discountoperations', component: BasicComponent, data: { basic: 'DiscountOperation' } },
              { path: 'customercatalogs', component: CustomerCatalogGeneralFormComponent, data: { basic: 'CustomerCatalog' } },
            ]
          },
          {
            path: 'time-management',
            children: [
              { path: 'tasktags', component: BasicComponent, data: { basic: 'TaskTag' } },
              { path: 'worktypes', component: BasicComponent, data: { basic: 'WorkType' } },
              { path: 'priorities', component: BasicComponent, data: { basic: 'TaskPriority' } },
              { path: 'timetables', component: BasicComponent, data: { basic: 'Timetable' } },
              { path: 'priceCoefs', component: BasicComponent, data: { basic: 'PriceCoef' } },
              { path: 'assignementPriceCoef', component: AssignementPriceCoefsComponent, data: { basic: 'AssignementPriceCoef' } },
              { path: 'timetableLineTypes', component: BasicComponent, data: { basic: 'TimetableLineType' } },
            ]
          },
          {
            path: 'stocks',
            children: [
              { path: 'stocklocations', component: BasicComponent, data: { basic: 'StockLocation' } },
            ]
          },
          {
            path: 'accountings',
            children: [
              { path: 'taxtypes', component: BasicComponent, data: { basic: 'TaxType' } },
              { path: 'accounttypes', component: BasicComponent, data: { basic: 'AccountType' } },
              { path: 'accountjournaltypes', component: BasicComponent, data: { basic: 'AccountJournalType' } },
              { path: 'accountjournals', component: BasicComponent, data: { basic: 'AccountJournal' } },
              { path: 'taxes', component: TaxesComponent, data: { basic: 'Tax' } },
              { path: 'accounts', component: AccountsComponent },//, data: { basic: 'Tax' }
              { path: 'accountperiods', component: BasicComponent, data: { basic: 'AccountPeriod' } },
            ]
          },
          {
            path: 'mails',
            children: [
              { path: 'mailtemplatetypes', component: BasicComponent, data: { basic: 'MailTemplateType' } },
              { path: 'mailtemplateparams', component: BasicComponent, data: { basic: 'MailTemplateParam' } },
              { path: 'mailtemplates', component: MailTemplatesComponent }
            ]
          },
          {
            path: 'sequences',
            children: [
              { path: 'sequences', component: SequenceComponent },
              { path: 'sequencesconfigurations', component: SequenceConfigurationsComponent },
            ]
          },
        ]
      },
    ]
  },
  { path: 'reportpreview', component: ReportpreviewComponent, canActivate: [AuthGuard] }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
