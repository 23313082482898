import { Component, Input } from '@angular/core';
import { SaleCreditNote } from '../sale-credit-notes/sale-credit-note.model';
import { BaseGridComponent } from 'projects/libraries/syslink-components/src/lib/grid/base-grid/base-grid.component';
import { ContextMenuItemAction } from 'projects/libraries/syslink-components/src/lib/context-menus/context-menu-item-action';
import { SyslinkColumn } from 'projects/libraries/syslink-components/src/lib/helpers/SyslinkColumn';
import { SaleCreditNotesService } from '../sale-credit-notes/sale-credit-notes.service';
import { DXCell } from 'projects/libraries/syslink-components/src/lib/grid/cells/dxcell';
import { SelectDescriptor } from 'devextreme/data';
import { AppInjectorService } from 'projects/libraries/syslink-components/src/lib/services/app-injector.service';

@Component({
  selector: 'app-sale-credit-note-grid',
  templateUrl: './sale-credit-note-grid.component.html',
  styleUrls: ['./sale-credit-note-grid.component.scss']
})
export class SaleCreditNoteGridComponent extends BaseGridComponent<SaleCreditNote> {

  @Input() override detailsUrl: string = '/sales/credit-notes';
  @Input() override storageKey: string = 'sale-credit-notes';
  @Input() public contextMenuItems: ContextMenuItemAction[] = [];
  @Input() showPager: boolean = true;
  @Input() items?: any[];

  @Input() public override expand?: string | string[] = ['CurrentStatusLink.StatusId'];
  @Input() public override select?: SelectDescriptor<any> = ['Id', 'No', 'ThirdId.Fullname', 'Subject', 'ExternalReference', 'ExTaxTotal', 'InTaxTotal', 'ResponsibleUserId.Fullname', 'Date', 'AmountRemaining'];

  public columns: SyslinkColumn[] = [
    new SyslinkColumn({ field: "No", label: "GRID.CAPTION.NO", width: 150, sortIndex: 2, sortDirection: 'desc' }),
    new SyslinkColumn({ field: "ThirdId.Fullname", label: "Customer", width: 250 }),
    new SyslinkColumn({ field: "Subject", label: "Subject" }),
    new SyslinkColumn({ field: "CurrentStatusLink.StatusId.Name", label: "Status", cellTemplate: 'block-cell', data: { getBlockLabel: this.getStatusCellLabel, getBlockClass: this.getStatusCellClass, getBlockColor: this.getStatusCellColor }, width: 120 }),
    new SyslinkColumn({ field: "ExternalReference", label: "External reference", visible: true, width: 150 }),
    new SyslinkColumn({ field: "ExTaxTotal", label: "exTaxTotal", type: "number", cellTemplate: 'currency-cell', data: { key: 'TotalPrecision' }, width: 80 }),
    new SyslinkColumn({ field: "InTaxTotal", label: "Total", type: "number", cellTemplate: 'currency-cell', data: { key: 'TotalPrecision' }, width: 80 }),
    new SyslinkColumn({ field: "ResponsibleUserId.Fullname", label: "Responsible", width: 120 }),
    new SyslinkColumn({ field: "Date", label: "Date", type: "date", visible: false, width: 150 }),
    new SyslinkColumn({ field: "AmountRemaining", label: 'Remaining amount', cellTemplate: "block-cell", width: 120, data: { getBlockLabel: this.getAmountRemainingCellLabel.bind(this), getBlockClass: this.getAmountRemainingCellClass.bind(this), getBlockColor: this.getAmountRemainingCellColor.bind(this) }, filterable: false, headerFilterable: false, sortable: false }),
    new SyslinkColumn({ field: "CurrentStatusLink.StatusId.Sequence", label: 'status order', sortIndex: 1, sortDirection: 'asc', width: 0, visible: false, filterable: false, headerFilterable: false, sortable: false })
  ];

  constructor(
    public saleCreditNotesService: SaleCreditNotesService
  ) {
    super();
  }

  // Status cell
  getStatusCellLabel(cell: DXCell) {
    return cell.data.CurrentStatusLink.StatusId.Name;
  }

  getStatusCellClass(cell: DXCell) {
    let statusClass = 'text-white text-center'

    const statusCode = (<string>cell.data.CurrentStatusLink.StatusId.Code).toLowerCase().split('.')[1];
    if (statusCode == undefined) return;
    statusClass += ' ' + statusCode;
    return statusClass;
  }

  getStatusCellColor(cell: DXCell) {
    return cell.data.CurrentStatusLink.StatusId.Color;
  }

  // AmountRemaining
  getAmountRemainingCellLabel(cell: DXCell) {
    const currencyFormatter = new Intl.NumberFormat('fr-FR', {
      style: 'currency',
      currency: 'EUR'
    });

    return currencyFormatter.format(this.getAmountRemainingCell(cell) ?? 0);
  }

  getAmountRemainingCellClass(cell: DXCell) {
    if (this.getAmountRemainingCell(cell) != '0') {
      return 'text-white text-center';
    }
    return 'text-center';
  }

  getAmountRemainingCellColor(cell: DXCell) {
    if (this.getAmountRemainingCell(cell) != '0' && this.getCellOverdue(cell) > 0) {
      return "#d9534f";
    }
    if (this.getAmountRemainingCell(cell) != '0') {
      return "#03A9F4";
    }
    return "";
  }

  public getAmountRemainingCell(cell: DXCell) {
    if (cell.data && cell.data.CurrentStatusLink && cell.data.CurrentStatusLink.StatusId && cell.data.CurrentStatusLink.StatusId.Sequence == 1) {
      return 0;
    }
    let result = cell.data.AmountRemaining.toFixed(AppInjectorService.config.getPrecision("TotalPrecision"));
    if(result == '0.00' || result == '-0.00')
      result = 0;
    return result;
  }

  getCellOverdue(cell: DXCell): number {
    if (!cell.data.Deadline) return 0;
    var deadline = cell.data.Deadline;
    var today = new Date();
    var one_day = 1000 * 60 * 60 * 24
    if (today.getMonth() == 11 && today.getDate() > 25)
      deadline.setFullYear(deadline.getFullYear() + 1)
    var Result = Math.round(deadline.getTime() - today.getTime()) / (one_day);
    if (Result < 0) {
      Result = Math.abs(Result);
    }
    else {
      Result = 0;
    }
    var Final_Result = Result.toFixed(0);
    return parseFloat(Final_Result);
  }

  public reload() {
    this.grid?.grid?.instance.refresh();
  }
}