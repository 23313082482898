<div class="row">
  <div class="col-2">
    <h2 class="mb-4">{{"Period" | translate}}</h2>
  </div>
  <div class="col-2">
    <syslink-input-number
      [(model)]="startYear"
      [label]="'StartYear' | translate"
    >
    </syslink-input-number>
  </div>
  <div class="col-2">
    <syslink-input-number
      [(model)]="endYear"
      [label]="'EndYear' | translate"
    >
    </syslink-input-number>
  </div>
</div>

<div
  *ngIf="element.Sellable"
  class="row"
>
  <div class="col-4">
    <syslink-block
      [title]="'Sale quotes'"
      [titleSize]="4"
      titleBgColor="gray"
      [bordered]="true"
      [titlePadding]="2"
    >
      <syslink-chart
        *ngIf="authService.hasPermission(basePermissionKey + '.statistics.saleQuote.view')"
        [title]="{text:'Confirmed sale quote ex tax total per month' | translate, font:{size:14}}"
        [data]="confirmedSaleQuoteExTaxTotalPerMonth"
        [series]="series"
        [size]=" {height: 300}"
      ></syslink-chart>

    </syslink-block>
  </div>
  <div class="col-4">
    <syslink-block
      [title]="'Sale invoices'"
      [titleSize]="4"
      titleBgColor="gray"
      [bordered]="true"
      [titlePadding]="2"
    >
      <syslink-chart
        *ngIf="authService.hasPermission(basePermissionKey + '.statistics.saleInvoice.view')"
        [title]="{text:'Validated and ventilated sale invoices ex tax total per month' | translate, font:{size:14}}"
        [data]="validatedVentilatedSaleInvoiceExTaxTotalPerMonth"
        [series]="series"
        [size]=" {height: 300}"
      ></syslink-chart>
    </syslink-block>
  </div>
  <div class="col-4">
    <syslink-block
      [title]="'Sale credit notes'"
      [titleSize]="4"
      titleBgColor="gray"
      [bordered]="true"
      [titlePadding]="2"
    >
      <syslink-chart
        *ngIf="authService.hasPermission(basePermissionKey + '.statistics.saleCreditNote.view')"
        [title]="{text:'Validated and ventilated sale credit note ex tax total per month' | translate, font:{size:14}}"
        [data]="validatedVentilatedSaleCreditNoteExTaxTotalPerMonth"
        [series]="series"
        [size]=" {height: 300}"
      ></syslink-chart>
    </syslink-block>
  </div>
</div>
<br>

<div
  *ngIf="element.Purchasable"
  class="row"
>
  <div class="col-4">
    <syslink-block
      [title]="'Purchase invoices'"
      [titleSize]="4"
      titleBgColor="gray"
      [bordered]="true"
      [titlePadding]="2"
    >
      <syslink-chart
        *ngIf="authService.hasPermission(basePermissionKey + '.statistics.purchaseInvoice.view')"
        [title]="{text:'Validated and ventilated purchase invoices ex tax total per month' | translate, font:{size:14}}"
        [data]="validatedVentilatedPurchaseInvoiceExTaxTotalPerMonth"
        [series]="series"
        [size]=" {height: 300}"
      ></syslink-chart>
    </syslink-block>
  </div>
  <div class="col-4">
    <syslink-block
      [title]="'Purchase credit notes'"
      [titleSize]="4"
      titleBgColor="gray"
      [bordered]="true"
      [titlePadding]="2"
    >
      <syslink-chart
        *ngIf="authService.hasPermission(basePermissionKey + '.statistics.purchaseCreditNote.view')"
        [title]="{text:'Validated and ventilated purchase credit note ex tax total per month' | translate, font:{size:14}}"
        [data]="validatedVentilatedPurchaseCreditNoteExTaxTotalPerMonth"
        [series]="series"
        [size]=" {height: 300}"
      ></syslink-chart>
    </syslink-block>
  </div>
</div>