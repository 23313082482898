import { Injectable } from '@angular/core';
import { LoadOptions } from 'devextreme/data';
import { SaleDocumentHeaderStatusesService } from '../../sale-documents/sale-document-header-status/sale-document-header-statuses.service';
import { SaleContractHeaderHeaderStatus } from './sale-contract-header-header-status.model';

@Injectable({
  providedIn: 'root'
})
export class SaleContractHeaderHeaderStatusesService extends SaleDocumentHeaderStatusesService<SaleContractHeaderHeaderStatus> {
  public override url: string = 'SaleContractHeaderHeaderStatus';
  public override defaultOptions: LoadOptions = {
    expand: ["StatusId"]
  };

}
