
<div class="{{class}}" [hidden]="hidden">
    <dx-text-box
        id="{{type}}-{{name}}"
        [name]="name"
        [mode]="type"

        [value]="model"
        (onValueChanged)="onModelChange($event.value)"
        (onFocusOut)="focusOut.emit(model)"
        (onFocusIn)="focusIn.emit(model)"
        valueChangeEvent="input"
        [stylingMode]="mode"
        [label]="getLabel()"
        [labelMode]="labelMode"
        [placeholder]="placeholder ? getLabel() : ''"
        (onEnterKey)="onEnterKey.emit($event)"
        [readOnly]="disabled"
        [maxLength]="maxLength"
        [mask]="mask"
        [maskChar]="maskChar"
    ></dx-text-box>
</div>