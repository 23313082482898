<div class="row mx-3">
    
    <div class="col-9 fw-bolder">{{'Debit' | translate}}</div>
    <div class="col-3 fw-bolder text-end">{{getDebit() | syslinkCurrency:"TotalPrecision"}}</div>
    
    <div class="col-9 mt-2 fw-bolder">{{'Credit' | translate}}</div>
    <div class="col-3 mt-2 fw-bolder text-end">{{getCredit() | syslinkCurrency:"TotalPrecision"}}</div>
    
    
    <div class="col-12 mt-2"></div>
    <div class="col-12 border-top border-2"></div>
    <div class="col-12 mt-2"></div>

    <div class="col-9 fw-bolder">{{'Total' | translate}}</div>
    <div class="col-3 fw-bolder text-end text-{{getTotalStateColor()}}">{{ getTotal() | syslinkCurrency:"TotalPrecision"}}</div>
</div>