<div class="row">
  <div class="col-sm-4 col-xs-12">
    <syslink-select
      *ngIf="authService.hasPermission('root-stock-moves')"
      label="DefaultStockLocation"
      [store]="stockLocationService.store"
      [(model)]="element.DefaultStockLocationId"
    ></syslink-select>
  </div>
  <div class="col-sm-8 col-xs-12 mt-3 mt-sm-0">
    <div class="text-end">
      <span class="fw-bold py-2 me-2 text-end"
        >{{ "Stock valorization" | translate }}:</span
      >
      <span
        class="d-inline-block totalPriceValue py-2 px-5 bg-secondary text-white text-center"
      >
        {{
          getValorization()
            | syslinkCurrency
              : "TotalPrecision"
        }}
      </span>
    </div>
  </div>
</div>
<div class="row mt-3">
  <ng-container *ngIf="!authService.hasPermission('root-stock-moves')">
    <div class="col-sm-4 col-xs-12">
      <syslink-stock-card
        [(number)]="element.Stock"
        [text]="'Real quantity' | translate"
        icon="warehouse"
      ></syslink-stock-card>
    </div>
    <div class="col-sm-4 col-xs-12 mt-3 mt-sm-0" id="stockReserved" *ngIf="element.ReservedStock">
      <syslink-stock-card
        [(number)]="element.ReservedStock"
        [text]="'Reserved quantity' | translate"
        icon="warehouse"
        [canEdit]="false"
      ></syslink-stock-card>
    </div>
    <div
      class="col-sm-4 col-xs-12 mt-3 mt-sm-0"
      id="stockEstimated"
      *ngIf="element.AvailableStock && element.AvailableStock != element.Stock"
    >
      <syslink-stock-card
        [(number)]="element.AvailableStock"
        [text]="'Available quantity' | translate"
        icon="warehouse"
        [canEdit]="false"
      ></syslink-stock-card>
    </div>
    <div class="col-12 mt-3 mt-sm-0">
      <app-product-stock-stock-move-grid
        *ngIf="element.StockMoveHistorical"
        [items]="element.StockMoveHistorical"
        [permissionKey]="'stock.stockMoves.list'"
      ></app-product-stock-stock-move-grid>
    </div>
  </ng-container>

  <ng-container *ngIf="authService.hasPermission('root-stock-moves')">
    <div class="col-sm-4 col-xs-12 mt-3 mt-sm-0" (click)="onClickGrid('stockMoveModal')">
      <syslink-stock-card
        [number]="element.StockMove"
        [text]="'Real quantity' | translate"
        icon="warehouse"
        [canEdit]="false"
      ></syslink-stock-card>
    </div>

    <div class="col-sm-4 col-xs-12 mt-3 mt-sm-0" (click)="onClickGrid('stockModalOutgoing')">
      <syslink-stock-card
        [number]="element.DeliveryStockQty"
        [text]="'Quantity on delivery' | translate"
        icon="fas fa-shipping-fast"
        [canEdit]="false"
      ></syslink-stock-card>
    </div>

    <div class="col-sm-4 col-xs-12 mt-3 mt-sm-0" (click)="onClickGrid('stockModalIncoming')">
      <syslink-stock-card
        [number]="element.ReceiptStockQty"
        [text]="'Quantity on receipt' | translate"
        icon="fas fa-shipping-fast"
        [canEdit]="false"
      ></syslink-stock-card>
    </div>
    <div class="col-12 mt-3 mt-sm-0">
      <app-product-stock-move-location-grid
        *ngIf="element.StockLocationProducts.length > 1"
        [permissionKey]="'stock.stockMoveLocations.list'"
        [items]="element.StockLocationProducts"
      ></app-product-stock-move-location-grid>
    </div>
    <syslink-modal
      *ngIf="
        authService.hasPermission(
          this.basePermissionKey + '.stock.stockMoveLineReceipt.view'
        ) ||
        authService.hasPermission(
          this.basePermissionKey + '.stock.stockMoveLineDelivery.view'
        )
      "
      #stockModal
      title="Stock"
    >
      <ng-container
        *ngIf="
          showIncomingGrid &&
          authService.hasPermission(
            this.basePermissionKey + '.stock.stockMoveLineReceipt.view'
          )
        "
      >
        <syslink-title text="Receipt"></syslink-title>
        <app-stock-move-incoming-lines-grid
          [permissionKey]="'stock.stockMoveLineReceipt.list'"
          [filter]="stockMoveLineIncomingsFilter"
        ></app-stock-move-incoming-lines-grid>
      </ng-container>
      <ng-container
        *ngIf="
          showOutgoingGrid &&
          authService.hasPermission(
            this.basePermissionKey + '.stock.stockMoveLineDelivery.view'
          )
        "
      >
        <syslink-title text="Delivery"></syslink-title>
        <app-stock-move-outgoing-lines-grid
          [permissionKey]="'stock.stockMoveLineDelivery.list'"
          [filter]="stockMoveLineOutgoingsFilter"
        ></app-stock-move-outgoing-lines-grid>
      </ng-container>
    </syslink-modal>
  </ng-container>
</div>
