import { Injectable } from '@angular/core';
import { LoadOptions } from 'devextreme/data';
import { PurchaseDocumentsService } from '../../purchase-documents/purchase-documents/purchase-documents.service';
import { PurchaseCreditNote } from './purchase-credit-note.model';
import { PurchaseCreditNoteHeaderStatus } from '../purchase-credit-note-header-statuses/purchase-credit-note-header-status.model';

@Injectable({
  providedIn: 'root'
})
export class PurchaseCreditNotesService extends PurchaseDocumentsService<PurchaseCreditNote, PurchaseCreditNoteHeaderStatus> {
  public override url: string = 'PurchaseCreditNoteHeader';
  
  public override defaultOptions: LoadOptions = {
    expand: [
      'CurrentStatusLink.StatusId',
      'ResponsibleUserId',
      'DocumentDelayId',
      'Lines($orderby=LineNo;$expand=TaxRateId($expand=NameTranslationId),UnitId,Discount,Children,PurchaseDocumentLineDiscountOperations,ParentId, ProductId)',
      'Statuses.StatusId',
      'DocumentDataCollection.ThirdId.ThirdContacts',
      'DocumentDataCollection.ThirdId.Addresses',
      'DocumentDataCollection',
      'DocumentDataCollection.AddressId',
      'DocumentDataCollection.ThirdId.SupplierId.PurchaseCreditNoteDocumentDelayId',
      'Payments.PaymentMethodPrimary',
      'Payments.PaymentStateId',    
      'Payments.PaymentTypeId',
      'ThirdId.ContactInformations.ThirdId',
      'ThirdId.ContactInformations.ContactTypeId',
      'DocumentRelations'
    ]
  };

  public override async getInstance(quote?: Partial<PurchaseCreditNote>): Promise<any> {

    let element = {
      Date: new Date(),
      ...quote,
    };

    element = await this.refreshResponsibleUser(element);
    element = await this.refreshThirdDeliveryTimeFromSupplier(element);
    element = await this.refreshThirdDocumentDelayFromSupplier(element);

    return element;
  }
}
export enum PurchaseCreditNoteActionCode {
  payment = 'Payment'
}
