export class ApiDomainError<T = any> {

  private static readonly UNKNOWN_TYPE = 'unknown';
  private static readonly UNKNOWN_MESSAGE = 'Unknown error';
  private static readonly DEFAULT_DATA = {};

  public constructor(
    public readonly type: string,
    public readonly message: string,
    public readonly data: T) {
  }

  public static fromObject(object : any){
    let type = object?.type ?? this.UNKNOWN_TYPE;
    let message = object?.message ?? this.UNKNOWN_MESSAGE;
    let data = object?.data ?? this.DEFAULT_DATA;

    return new ApiDomainError(type,message,data);
  }

  public static default(){
    return this.fromObject({});
  }
}
