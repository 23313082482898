import { Component, Input } from '@angular/core';
import { SaleOrder } from '../sale-orders/sale-order.model';
import { BaseGridComponent } from 'projects/libraries/syslink-components/src/lib/grid/base-grid/base-grid.component';
import { ContextMenuItemAction } from 'projects/libraries/syslink-components/src/lib/context-menus/context-menu-item-action';
import { SyslinkColumn } from 'projects/libraries/syslink-components/src/lib/helpers/SyslinkColumn';
import { SaleOrdersService } from '../sale-orders/sale-orders.service';
import { DXCell } from 'projects/libraries/syslink-components/src/lib/grid/cells/dxcell';
import { SelectDescriptor } from 'devextreme/data';

@Component({
  selector: 'app-sale-order-grid',
  templateUrl: './sale-order-grid.component.html',
  styleUrls: ['./sale-order-grid.component.scss']
})
export class SaleOrderGridComponent extends BaseGridComponent<SaleOrder> {

  @Input() override detailsUrl: string = '/sales/orders';
  @Input() override storageKey: string = 'sale-orders';
  @Input() public contextMenuItems: ContextMenuItemAction[] = [];
  @Input() showPager: boolean = true;
  @Input() items?: any[];
  @Input() public override expand?: string | string[] = ['CurrentStatusLink.StatusId'];
  @Input() public override select?: SelectDescriptor<any> = ['Id', 'No', 'ThirdId.Fullname', 'Subject', 'Deadline', 'ExternalReference', 'ExTaxTotal', 'InTaxTotal', 'ResponsibleUserId.Fullname', 'Date', 'AmountRemaining','FormattedStructuredCommunication'];

  public columns: SyslinkColumn[] = [
    new SyslinkColumn({ field: "No", label: "GRID.CAPTION.NO", width: 150, sortIndex: 2, sortDirection: 'desc' }),
    new SyslinkColumn({ field: "ThirdId.Fullname", label: "Customer", width: 250 }),
    new SyslinkColumn({ field: "Subject", label: "Subject" }),
    new SyslinkColumn({ field: "CurrentStatusLink.StatusId.Name", label: "Status", cellTemplate: 'block-cell', data: { getBlockLabel: this.getStatusCellLabel, getBlockClass: this.getStatusCellClass, getBlockColor: this.getStatusCellColor }, width: 120 }),
    new SyslinkColumn({ field: "ExternalReference", label: "External reference", visible: true, width: 150 }),
    new SyslinkColumn({ field: "ExTaxTotal", label: "exTaxTotal", type: "number", cellTemplate: 'currency-cell', data: { key: 'TotalPrecision' }, width: 80 }),
    new SyslinkColumn({ field: "InTaxTotal", label: "Total", type: "number", cellTemplate: 'currency-cell', data: { key: 'TotalPrecision' }, width: 80 }),
    new SyslinkColumn({ field: "ResponsibleUserId.Fullname", label: "Responsible", width: 120 }),
    new SyslinkColumn({ field: "Date", label: "Date", type: "date", visible: false, width: 150 }),
    new SyslinkColumn({ field: "CurrentStatusLink.StatusId.Sequence", label: 'status order', sortIndex: 1, sortDirection: 'asc', width: 0, visible: false, filterable: false, headerFilterable: false, sortable: false })
  ];

  constructor(
    public saleOrdersService: SaleOrdersService,
  ) {
    super();
  }

  // Status cell
  getStatusCellLabel(cell: DXCell) {
    return cell.data.CurrentStatusLink.StatusId.Name;
  }

  getStatusCellClass(cell: DXCell) {
    let statusClass = 'text-white text-center'

    const statusCode = (<string>cell.data.CurrentStatusLink.StatusId.Code).toLowerCase().split('.')[1];
    if (statusCode == undefined) return;
    statusClass += ' ' + statusCode;
    return statusClass;
  }

  getStatusCellColor(cell: DXCell) {
    return cell.data.CurrentStatusLink.StatusId.Color;
  }

  public reload() {
    this.grid?.grid?.instance.refresh();
  }
}


