import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ViewComponent } from 'projects/libraries/syslink-components/src/lib/helpers/view/view.component';
import { AccountMove } from '../account-move.model';
import { ThirdsService } from '../../../thirds/thirds/thirds.service';
import { AccountJournalsService } from '../../account-journals/account-journals.service';
import { AccountPeriodService } from '../../account-periods/account-period.service';
import { AllSaleDocumentsService } from '../../../sales/sale-documents/sale-documents/all-sale-documents.service';

@Component({
  selector: 'app-account-move-form',
  templateUrl: './account-move-form.component.html',
  styleUrls: ['./account-move-form.component.scss']
})
export class AccountMoveFormComponent extends ViewComponent {
  @Input() public element: AccountMove = new AccountMove();
  @Output() public elementChange: EventEmitter<AccountMove> = new EventEmitter<AccountMove>();
 
  @Input() public disabled: boolean = false;

  constructor(
    public thirdsService:ThirdsService,
    public accountJournalsService:AccountJournalsService,
    public accountPeriodService:AccountPeriodService,
    public allSaleDocumentsService:AllSaleDocumentsService
  ) {
    super();
  }

  public async refreshBOBReference(){
    if(!this.element?.ThirdId?.Id) return;
    this.element.ThirdId = await this.thirdsService.findByID(this.element.ThirdId.Id);
  }

}
