import { Origin } from "../../base/origins/origins.service";
import { Entity } from "../../core/models/entity";
import { Customer } from "../customers/customers/customer.model";
import { Supplier } from "../suppliers/supplier.model";
import { ThirdRole } from "./third-roles/third-role.model";
import { ThirdRelation } from "./third-relations/third-relation.model";
import { Country } from "../../base/addresses/countries/country";
import { Language } from "../../base/languages/language";
import { ThirdAddress } from "./third-addresses/third-addresses/third-address.model";
import { BankAccount } from "../bank-accounts/bank-account.model";
import { User } from "../../core/auth/users/user.model";
import { SubjugationCategory } from "../../accountings/subjugation-categories/subjugation-category.model";
import { LegalForm } from "../legal-forms/legal-form.model";
import { ThirdTitle } from "../third-titles/third-titles.service";
import { ThirdContactInformation } from "./third-contacts/third-contact-informations/third-contact-information.model";
import { CreditsafeLine } from "../../connectors/creditsafe/creditsafe-line/creditsafe-line.model";

export class Third extends Entity {
    public IsProfessional?:boolean;
    public No?: string;
    public Alias?: string;
    public Note?: string;
    public Fullname?: string;
    public CompanyNumber?: string;
    public VatNumber?: string;
    public Subjugated?: boolean;
    public Blocked?: boolean;
    public ExternalRef?: string;

    public Roles: Array<ThirdRole> = [];
    public CustomerId?: Customer;
    public SupplierId?: Supplier;

    public OriginId?: Origin;
    public LanguageId: Language = new Language();
    public CountryId: Country = new Country();
    public SubjugationCategoryId: SubjugationCategory = new SubjugationCategory();

    // Relations
    // public ThirdRelations: ThirdRelation[] = [];
    // public ThirdRelations1: ThirdRelation[] = [];
    public AllRelationThirds: ThirdRelation[] = [];
    public ThirdContacts: Third[] = [];
    

    public Addresses: ThirdAddress[] = [];

    // Communication
    public DisplayEmail?: string;
    public DisplayPhoneNumber?: string;
    public DisplayAddress?: string;
    public ContactInformations: ThirdContactInformation[] = [];

    // BankAccounts
    // ------------
    public BankAccounts: BankAccount[] = [];

    // User
    // ----
    public UserId?: User;

    // Person
    // ------
    public TitleId: ThirdTitle = new ThirdTitle();
    public Firstname?: string;
    public Lastname?: string;

    // Company
    // -------
    public Name: string="";
    public LegalFormId: LegalForm = new LegalForm();

    // Roles
    // -----
    public HasCustomerRole?: boolean;
    public HasSupplierRole?: boolean;

    // CreditSafe
    // ----------
    public CreditSafeLines: CreditsafeLine[]=[];
    public CreditSafeLineId?:CreditsafeLine;

    // Accounting
    public AccountingBOBReference?:string;

    constructor(third?: Partial<Third>) {
        super();
        Object.assign(this, third);
    }
}
