import { Entity } from "../../../core/models/entity";
import { SaleDocument } from "../../../sales/sale-documents/sale-documents/sale-document.model";

export class DocumentRelation extends Entity {
    public FromId?: number;
    public ToId?: number;
    public FromType?: string;
    public ToType?: string;
    public To?: SaleDocument;
    public From?: SaleDocument;

    constructor(entity?: Partial<DocumentRelation>) {
        super();
        Object.assign(this, entity);
    }
}
