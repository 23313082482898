import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { Task } from '../task.model';
import { ConfirmModalComponent, NotificationsService, PageComponent, SyslinkToolbarActionButton, SyslinkToolbarFileButton, TabData, TabsComponent } from 'projects/libraries/syslink-components/src/public-api';
import { TaskStatus } from '../../task-statuses/task-status.model';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ActivatedRoute } from '@angular/router';
import { ThirdsService } from 'projects/erp-app/src/app/thirds/thirds/thirds.service';
import { TaskActionCode, TasksService } from '../tasks.service';
import { DocumentStatusChangedEvent, DocumentStatusesComponent } from 'projects/erp-app/src/app/base/documents/document-statuses/document-statuses.component';
import { TaskTaskStatusesService } from '../../task-task-statuses/task-task-statuses.service';
import { TaskStatusesService } from '../../task-statuses/task-statuses.service';
import { WorkTimeModalComponent } from '../../../work-times/work-times/work-time-modal/work-time-modal.component';
import { WorkTimeActionCode, WorkTimesService } from '../../../work-times/work-times/work-times.service';
import { WorkTime } from '../../../work-times/work-times/work-time';
import { WorkTimeGridComponent } from '../../../work-times/work-times/work-time-grid/work-time-grid.component';
import { SaleInvoiceGridComponent } from 'projects/erp-app/src/app/sales/sale-invoices/sale-invoice-grid/sale-invoice-grid.component';
import { SyslinkColumn } from 'projects/libraries/syslink-components/src/lib/helpers/SyslinkColumn';
// import { AppointmentGridComponent } from '../../../appointments/appointments/appointment-grid/appointment-grid.component';
// import { AppointmentModalComponent } from '../../../appointments/appointments/appointment-modal/appointment-modal.component';
// import { Appointment } from '../../../appointments/appointments/appointment';
// import { AppointmentsService } from '../../../appointments/appointments/appointments.service';
import { TaskFormComponent } from '../task-form/task-form.component';
// import { AppointmentSchedulerComponent } from '../../../appointments/appointments/appointment-scheduler/appointment-scheduler.component';
import { SaleInvoiceSelectModalComponent } from 'projects/erp-app/src/app/sales/sale-invoices/sale-invoice-select-modal/sale-invoice-select-modal.component';
import { BooleanCellData } from 'projects/libraries/syslink-components/src/lib/grid/cells/boolean-cell/boolean-cell.component';
import { SaleInvoicesService } from 'projects/erp-app/src/app/sales/sale-invoices/sale-invoices/sale-invoices.service';
import { DynamicPriceInvoicingComponent } from '../../dynamic-price-invoicing/dynamic-price-invoicing.component';
import { TaskBillingTypeCode } from '../../task-billing-types/task-billing-type.model';
import { DatePipe } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { AppInjectorService } from 'projects/libraries/syslink-components/src/lib/services/app-injector.service';
import { ContextMenuItemAction } from 'projects/libraries/syslink-components/src/lib/context-menus/context-menu-item-action';
import { ContextMenuItemClickEvent } from 'devextreme/ui/file_manager';
import { WorkTimeTypeSelectModalComponent } from '../../../work-times/work-time-type-select-modal/work-time-type-select-modal.component';
import { WorkType } from '../../../works/work-types/work-type.model';

@Component({
  selector: 'app-task-detail',
  templateUrl: './task-detail.component.html',
  styleUrls: ['./task-detail.component.scss']
})
export class TaskDetailComponent extends PageComponent implements OnInit, AfterViewInit {
  public element: Task = new Task();

  // @ViewChild('form') form?: TaskFormComponent;
  @ViewChild('taskTabs') private taskTabs: TabsComponent = new TabsComponent;
  @ViewChild('deleteConfirm') deleteConfirm: ConfirmModalComponent = new ConfirmModalComponent();
  @ViewChild('documentStatuses') documentStatuses: DocumentStatusesComponent = new DocumentStatusesComponent();


  public tabsData: TabData[] = [
    { id: 0, key: "Performable", label: "Worktimes", loaded: this.authService.hasPermission(this.basePermissionKey + '.worktime.tab.view') },
    { id: 1, key: "Billable", label: "TaskDetailBilled", loaded: !this.authService.hasPermission(this.basePermissionKey + '.worktime.tab.view') && this.authService.hasPermission(this.newBasePermissionKey + '.saleInvoice.tab.view') }
  ]
  public onChangeSelectedId(e: number) {
    if (this.tabsData[e].loaded == false) {
      this.tabsData[e].loaded = true;
    }
  }

  // @ViewChild('tabs') tabs: TabsComponent = new TabsComponent();
  @ViewChild('worktimeModal') worktimeModal?: WorkTimeModalComponent;
  @ViewChild('worktimeGrid') worktimeGrid?: WorkTimeGridComponent;
  @ViewChild('saleInvoiceGrid') saleInvoiceGrid?: SaleInvoiceGridComponent;
  @ViewChild('saleInvoiceSelectModal') saleInvoiceSelectModal?: SaleInvoiceSelectModalComponent;
  @ViewChild('dynamicPriceInvoicing') dynamicPriceInvoicing?: DynamicPriceInvoicingComponent;
  @ViewChild('typeSelectModal') typeSelectModal?: WorkTimeTypeSelectModalComponent;
  @ViewChild("confirmModal") confirmModal?: ConfirmModalComponent;

  public WorktimeColumns: SyslinkColumn[] = [
    new SyslinkColumn({ field: "TypeId.Name", label: "Type" }),
    new SyslinkColumn({ field: "StartDate", name: 'StartDate-date', label: "Start date", type: "date", cellTemplate: "date-cell" }),
    new SyslinkColumn({ field: "StartDate", name: 'StartDate-hours', label: "Start hours", type: "datetime", cellTemplate: 'time-cell' }),
    new SyslinkColumn({ field: "EndDate", name: "EndDate-date", label: "End date", type: "date", cellTemplate: "date-cell" }),
    new SyslinkColumn({ field: "EndDate", name: "EndDate-hours", label: "End hours", type: "datetime", cellTemplate: 'time-cell' }),
    new SyslinkColumn({ field: "Duration", label: "Duration", type: "number", cellTemplate: 'duration-cell' }),
    new SyslinkColumn({ field: "Description", label: "Description", cellTemplate: 'html-cell' }),
    new SyslinkColumn({ field: "UserId.ThirdId.Fullname", label: "User" }),
    new SyslinkColumn({ field: "IsBillable", label: "Billable", cellTemplate: 'boolean-cell', data: new BooleanCellData({ type: 'box', getColor: this.worktimeGrid?.getIsBillableColor }) }),
    new SyslinkColumn({ field: "IsBilled", label: "Billed", cellTemplate: 'boolean-cell', data: new BooleanCellData({ type: 'box', getColor: this.worktimeGrid?.getIsBilledColor, getText: this.worktimeGrid?.getIsBilledText }) }),
  ];

  // Invoices
  // --------
  @ViewChild('invoiceGridComponent') invoiceGridComponent: SaleInvoiceGridComponent = new SaleInvoiceGridComponent(this.saleInvoicesService, this.datePipe, this.translateService);
  public SaleInvoiceColumns: SyslinkColumn[] = [
    new SyslinkColumn({ field: "No", label: "GRID.CAPTION.NO", width: 150, sortIndex: 2, sortDirection: 'desc' }),
    new SyslinkColumn({ field: "Subject", label: "Subject" }),
    new SyslinkColumn({ field: "CurrentStatusLink.StatusId.Name", label: "Status", cellTemplate: 'block-cell', data: { getBlockLabel: this.invoiceGridComponent.getStatusCellLabel, getBlockClass: this.invoiceGridComponent.getStatusCellClass, getBlockColor: this.invoiceGridComponent.getStatusCellColor }, width: 120 }),
    new SyslinkColumn({ field: "Deadline", label: 'SaleInvoice.Validity', type: "date", cellTemplate: "block-cell", width: 120, data: { getBlockLabel: this.invoiceGridComponent.getDeadlineCellLabel, getBlockClass: this.invoiceGridComponent.getDeadlineCellClass.bind(this.invoiceGridComponent), getBlockColor: this.invoiceGridComponent.getDeadlineCellColor.bind(this.invoiceGridComponent), getBlockText: this.invoiceGridComponent.getDeadlineCellText.bind(this.invoiceGridComponent) } }),
    new SyslinkColumn({ field: "ExTaxTotal", label: "exTaxTotal", type: "number", cellTemplate: 'currency-cell', data: { key: 'TotalPrecision' }, width: 80 }),
    new SyslinkColumn({ field: "InTaxTotal", label: "Total", type: "number", cellTemplate: 'currency-cell', data: { key: 'TotalPrecision' }, width: 80 }),
    new SyslinkColumn({ field: "Date", label: "Date", type: "date", visible: false, width: 150 }),
    new SyslinkColumn({ field: "AmountRemaining", label: 'Remaining amount', cellTemplate: "block-cell", width: 120, data: { getBlockLabel: this.invoiceGridComponent.getAmountRemainingCellLabel.bind(this.invoiceGridComponent), getBlockClass: this.invoiceGridComponent.getAmountRemainingCellClass.bind(this.invoiceGridComponent), getBlockColor: this.invoiceGridComponent.getAmountRemainingCellColor.bind(this.invoiceGridComponent) }, filterable: false, headerFilterable: false, sortable: false }),
    new SyslinkColumn({ field: "CurrentStatusLink.StatusId.Sequence", label: 'status order', sortIndex: 1, sortDirection: 'asc', width: 0, visible: false, filterable: false, headerFilterable: false, sortable: false })
  ];

  constructor(
    private ngxUiLoaderService: NgxUiLoaderService,
    public override activatedRoute: ActivatedRoute,
    public tasksService: TasksService,
    private taskStatusesService: TaskStatusesService,
    private taskTaskStatusesService: TaskTaskStatusesService,
    private workTimesService: WorkTimesService,
    private saleInvoicesService: SaleInvoicesService,
    private datePipe: DatePipe,
    public override translateService: TranslateService
  ) {
    super();
  }

  override ngOnInit(): void {
    this.ngxUiLoaderService.start();
    this.activatedRoute.data.subscribe(async ({ element }) => {
      if (!element.Id) {
        this.goToUrl('../');
      }
      else {
        this.element = element;
        await this.refresh();
        this.updateToolbar();
        this.ngxUiLoaderService.stop();
      }
    });
  }

  // KeyDown
  // -------
  public override onKeyDownSave() { this.update() }
  // -------------------------------------------------



  private async refresh() {
    this.initStatusBar();
  }

  public updateToolbar() {
    this.toolbarActions = [
      new SyslinkToolbarActionButton({ code: 'save', icon: 'save', text: 'Save', onClick: async () => { await this.update() }, hotkey: 'control.s', visible: this.authService.hasPermission(this.newBasePermissionKey + '.update') }),
      new SyslinkToolbarFileButton({ entityType: `Task`, entityId: this.element.Id }),
      new SyslinkToolbarActionButton({ code: 'delete', icon: 'trash', text: 'Delete', onClick: () => { this.onDeleteBtnClicked() }, visible: this.authService.hasPermission(this.basePermissionKey + '.delete') })
    ];
  }

  // Document Statuses
  // -----------------
  public initStatusBar() {
    this.taskStatusesService.load().then((statuses: TaskStatus[]) => {
      statuses.map((status: TaskStatus) => {
        status.Color = status.Sequence && this.element.CurrentStatusLink?.StatusId?.Sequence && this.element.CurrentStatusLink?.StatusId?.Sequence < status.Sequence ? "#A0A0A0" : status.Color;
      });
      this.documentStatuses.statuses = statuses;
      this.documentStatuses.documentStatuses = this.element.Statuses || [];
      this.documentStatuses.refreshItems();
    });
  }
  public async onDocumentStatusChanged(event: DocumentStatusChangedEvent) {
    if (!this.authService.hasPermission(this.basePermissionKey + '.changeStatus')) {
      NotificationsService.sendErrorMessage("You do not have the required permission!");
      return;
    }

    const link = await this.taskTaskStatusesService.getInstance({
      TaskId: this.element,
      StatusId: { Id: event.status.Id },
      Date: new Date()
    })

    // Checking not updating to same status
    // ------------------------------------
    if (this.element.CurrentStatusLink?.StatusId.Id == link.StatusId.Id) {
      NotificationsService.sendInfo("Task already has this status");
    }

    var documentStatus = await this.taskTaskStatusesService.insert(this.taskTaskStatusesService.format(link));
    if (!documentStatus.Id) return;
    documentStatus = await this.taskTaskStatusesService.findByID(documentStatus.Id);
    if (!this.element.Statuses || !documentStatus) {
      throw "This status cannot be created";
    }
    this.element.CurrentStatusLink = documentStatus;
    this.element.Statuses.push(documentStatus);
    // await this.reloadElement();
    await this.refresh();
  }

  // Save/Update
  // -----------
  public async update(showMessage: boolean = true) {
    if (!this.authService.hasPermission(this.basePermissionKey + '.update')) {
      NotificationsService.sendErrorMessage("You do not have the required permission!");
      return;
    }

    if (this.tasksService.validateElementInformations(this.element) == false) {
      throw new Error();
    }

    await this.tasksService.createOrUpdate(this.element);

    if (showMessage == true) {
      NotificationsService.sendSuccess("Record updated");
    }
    // TODO Check better method
    setTimeout(() => {
      AppInjectorService.config.setModificationGuard(false);
    }, 50);
  }

  // delete
  // --------------
  public onDeleteBtnClicked() {
    this.deleteConfirm.open();
  }

  public async onDeleteConfirmed() {
    if (!this.element?.Id) return
    this.deleteConfirm.close();
    await this.tasksService?.remove(this.element.Id);
    this.router.navigate(['../'], { relativeTo: this.activatedRoute });
    NotificationsService.sendSuccess("Record deleted");
  }

  // WorkTime
  // --------
  // Context menu
  public workTimeContextMenuItems: ContextMenuItemAction[] = [
    {
      text: "Invoicing", icon: "money", items: [
        { code: WorkTimeActionCode.MarkAsNotBillable, text: "Mark as not billable", onItemClick: (e: any) => this.onMenuItemClicked(e), visible: this.authService.hasPermission('time-management.tasks.contextMenu.updateInvoicingStatus') },
        { code: WorkTimeActionCode.MarkAsBillable, text: "Mark as billable", onItemClick: (e: any) => this.onMenuItemClicked(e), visible: this.authService.hasPermission('time-management.tasks.contextMenu.updateInvoicingStatus') },
      ], visible: this.authService.hasPermission('time-management.tasks.contextMenu.updateInvoicingStatus')
    },
    { code: WorkTimeActionCode.Type, text: "Update type", icon: "edit", onItemClick: (e: any) => this.onMenuItemClicked(e), visible: this.authService.hasPermission('time-management.tasks.contextMenu.editType') }
  ];

  public async onMenuItemClicked(e: ContextMenuItemClickEvent) {
    if (!this.worktimeGrid) return;
    switch (e.itemData.code) {
      case WorkTimeActionCode.MarkAsNotBillable:
        this.showConfirmModal("Would you like to make all selected rows not billable?", e.itemData)
        break;
      case WorkTimeActionCode.MarkAsBillable:
        this.showConfirmModal("Would you like to make all selected rows billable?", e.itemData)
        break;
      case WorkTimeActionCode.Type:
        this.showTypeSelectModal("Would you like to update type for selected rows?", e.itemData);
        break;
    }
  }

  private showConfirmModal(text: string, data: ContextMenuItemAction) {
    if (!this.confirmModal) return;

    this.confirmModal.title = data.text ? data.text : "Confirm";
    this.confirmModal.content = text;
    this.confirmModal.open({ rows: this.getRows(data), action: data.code });
  }
  private showTypeSelectModal(text: string, data: ContextMenuItemAction) {
    if (!this.typeSelectModal) return;

    this.typeSelectModal.title = data.text ? data.text : "Confirm";
    this.typeSelectModal.content = text;
    this.typeSelectModal.open({ rows: this.getRows(data), action: data.code });
  }

  private getRows(data: ContextMenuItemAction) {
    return this.worktimeGrid?.getSelectedRows(data.rowData)
  }

  public async onConfirmModalValidated(modalData: { rows: WorkTime[], action: string } | any) {
    switch (modalData.action) {
      case WorkTimeActionCode.MarkAsBillable:
        await this.workTimesService.tryUpdateMultiples(modalData.rows, [{ field: 'IsBillable', value: true }]);
        break;
      case WorkTimeActionCode.MarkAsNotBillable:
        await this.workTimesService.tryUpdateMultiples(modalData.rows, [{ field: 'IsBillable', value: false }]);
        break;
      case WorkTimeActionCode.Type:
        await this.updateWorkTimeTypes(modalData.rows, modalData.Type);
        break;
    }

    this.worktimeGrid?.refresh();
    this.confirmModal?.close();
  }
  private async updateWorkTimeTypes(selectedRows: WorkTime[], type: WorkType | undefined) {
    if (type == undefined) {
      NotificationsService.sendErrorMessage('Type is empty');
      return;
    }

    if (selectedRows?.some(row => row.IsBilled == true)) {
      NotificationsService.sendErrorMessage("One or more selected line(s) are already billed");
      return;
    }

    for (const work of selectedRows) {
      if (!work.Id) return;
      work.TypeId = type;
      await this.workTimesService.update(work.Id, this.workTimesService.format(work));
    };
    NotificationsService.sendSuccess('Worktimes updated');
    this.typeSelectModal?.close();
  }
  public async onAddWorkTimeButtonClicked() {
    this.worktimeModal?.open(await this.workTimesService.getInstance({
      ThirdId: this.element.ThirdId,
      TaskId: this.element,
    }));
  }

  // -----------------------------------
  public async onValidateWorkTimeCreation(e: WorkTime) {
    try {
      this.worktimeGrid?.grid?.grid?.instance.refresh();
      // TODO add reload 
    } catch (_error) { }
  }

  public async onDeletedWorkTime(e: any) {
    this.worktimeGrid?.grid?.grid?.instance.refresh();
    // TODO add reload 
  }

  // Invoicing
  // ---------
  public async onChangeBillingType() {
    if (this.element.TaskBillingTypeId.Code == TaskBillingTypeCode.NotBillable) {
      this.tabsData[1].loaded = false;
      return;
    }
    this.onChangeSelectedId(1);
    // TODO check best solution
    setTimeout(() => {
      this.taskTabs.ngAfterViewInit();
    }, 50);
  }

  public showInvoiceModal() {
    if (!this.saleInvoiceSelectModal) return;
    this.saleInvoiceSelectModal.content = "Would you like to invoice selected rows?";
    this.saleInvoiceSelectModal.open({ thirdId: this.element.ThirdId?.Id });
    this.saleInvoiceSelectModal.updateFilter();
  }

  public getInvoiceRelationFilter() {
    let saleInvoiceLineIds: Set<number> = new Set<number>();

    if (this.element.SaleInvoiceLineId?.Id) {
      saleInvoiceLineIds.add(this.element.SaleInvoiceLineId.Id);
    }

    for (let workTime of this.element.WorkTimeTasks) {
      if (workTime.SaleInvoiceLineId?.Id) {
        saleInvoiceLineIds.add(workTime.SaleInvoiceLineId.Id);
      }
    }

    return `(Lines/any(l:l/Id in (${Array.from(saleInvoiceLineIds).join(',')})))`;
  }

  public async onConfirmInvoiceModalValidated(event: any) {
    await this.update(true);

    const selectedRows = [this.element];

    if (!this.tasksService.can(TaskActionCode.Invoice, selectedRows)) return;

    let dynamicPriceTasks = selectedRows.filter(task => task.TaskBillingTypeId.Code != TaskBillingTypeCode.FixedPrice);

    if (dynamicPriceTasks.length > 0) {
      if (this.dynamicPriceInvoicing === undefined) throw new Error("Error with dynamic price invoicing component.");
      this.dynamicPriceInvoicing.tasks = dynamicPriceTasks;
      this.dynamicPriceInvoicing.tasksToInvoice = selectedRows.filter(task => task.TaskBillingTypeId.Code == TaskBillingTypeCode.FixedPrice);
      this.dynamicPriceInvoicing.invoiceId = event.Invoice?.Id;
      this.dynamicPriceInvoicing.onAddWorkTimes();
    } else {
      await this.tasksService.invoice({
        taskIds: selectedRows.map(e => e.Id!),
        invoiceId: event.Invoice?.Id
      });
      await this.onInvoiceCreated();
      NotificationsService.sendSuccess('Invoice created');
    }

    this.saleInvoiceSelectModal?.close();
  }

  public async onInvoiceCreated() {
    if (!this.element.Id) return;

    var element = await this.tasksService.findByID(this.element.Id, { expand: ['SaleInvoiceLineId', 'WorkTimeTasks.SaleInvoiceLineId', 'BilledTimeState'] });

    // Reload invoice data
    this.element.BilledTimeState = element.BilledTimeState;
    this.element.BilledTime = element.BilledTime;
    this.element.WorkTimeTasks = element.WorkTimeTasks;
    this.element.SaleInvoiceLineId = element.SaleInvoiceLineId;

    this.saleInvoiceGrid?.grid?.dataSource.filter(this.getInvoiceRelationFilter());
    setTimeout(() => {
      this.saleInvoiceGrid?.grid?.dataSource.reload();
    }, 150)
  }
}
