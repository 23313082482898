<syslink-grid
  #grid
  [gridName]="storageKey"
  [canView]="canView"
  [canSearch]="canSearch"
  [canRefresh]="canRefresh"
  [canAdd]="canAdd"
  [canSort]="canSort"
  [canFilter]="canFilter"
  [canExport]="canExport"
  [canDblClck]="canDblClck"
  [canDelete]="canDelete"
  [service]="StockMoveOutgoingsService"
  [columns]="columns"
  [filter]="filter"
  [canUpdate]="false"
  (add)="onAddButtonClicked()"
  [detailsUrl]="detailsUrl"
  [storageKey]="storageKey"
  [showPager]="showPager"
  [contextMenuItems]="contextMenuItems"
  [select]="select"
  [expand]="expand"
  (refresh)="refresh()"
  (delete)="onDeleteClicked($event)"
></syslink-grid>
