import { MetaFile } from "../../connectors/filesystem/meta-file";
import { User } from "../../core/auth/users/user.model";
import { Entity } from "../../core/models/entity";
import { BankReconciliationLine } from "../bank-reconciliation-lines/bank-reconciliation-line.model";
import { BankReconciliationHeaderStatus } from "./bank-reconciliation-header-status/bank-reconciliation-header-status.model";

export class BankReconciliation extends Entity {
    public No?:string;
    public Reference?:string;
    public Date?:Date;
    public ResponsibleUserId?:User;
    public BankReconciliationLines:BankReconciliationLine[]=[];
    public CodaFile : MetaFile | null = null;

    public CurrentStatusLink? : BankReconciliationHeaderStatus;
    public Statuses : any[]= [];

    constructor(entity?: Partial<BankReconciliation>) {
        super();
        Object.assign(this, entity);
    }
}
