import { Injectable } from '@angular/core';
import { ODataService } from '../../core/services/oData.service';
import { AccountJournal } from './account-journal.model';

@Injectable({
  providedIn: 'root'
})
export class AccountJournalsService  extends ODataService<AccountJournal> {
  public url = 'AccountJournal';
}
