import { Component, EventEmitter, Input, Output } from '@angular/core';
import { User } from '../user.model';
import { AuthService } from '../../auth.service';

@Component({
  selector: 'app-user-form',
  templateUrl: './user-form.component.html',
  styleUrls: ['./user-form.component.scss']
})
export class UserFormComponent {
  @Input() user: User = new User();
  @Output() userChange: EventEmitter<User> = new EventEmitter<User>();

  constructor(public authService: AuthService) { }
}
