import { Component, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { DocumentDatasService } from 'projects/erp-app/src/app/base/documents/document-datas/document-datas.service';
import { DocumentDelay } from 'projects/erp-app/src/app/base/documents/document-delays/document-delay';
import { DocumentStatus } from 'projects/erp-app/src/app/base/documents/document-statuses/document-status.model';
import { DocumentStatusChangedEvent } from 'projects/erp-app/src/app/base/documents/document-statuses/document-statuses.component';
import { ConfigurationsService } from 'projects/erp-app/src/app/base/modules/configurations/configurations.service';
import { ReportsService } from 'projects/erp-app/src/app/connectors/reports/reports.service';
import { ThirdsService } from 'projects/erp-app/src/app/thirds/thirds/thirds.service';
import { NotificationsService, } from 'projects/libraries/syslink-components/src/public-api';
import { PurchaseDocument } from '../purchase-document.model';
import { PurchaseDocumentsService } from '../purchase-documents.service';
import { PurchaseDocumentLine } from '../../purchase-document-lines/purchase-document-line.model';
import { PurchaseDocumentLinesService } from '../../purchase-document-lines/purchase-document-lines.service';
import { PurchaseDocumentStatus } from '../../purchase-document-statuses/purchase-document-status.model';
import { PurchaseDocumentHeaderStatus } from '../../purchase-document-header-status/purchase-document-header-status.model';
import { PurchaseDocumentHeaderStatusesService } from '../../purchase-document-header-status/purchase-document-header-statuses.service';
import { PurchaseDocumentStatusesService } from '../../purchase-document-statuses/purchase-document-statuses.service';
import { DocumentData } from 'projects/erp-app/src/app/base/documents/document-datas/document-data.model';
import { getNew } from 'projects/libraries/syslink-components/src/lib/helpers/tools';
import { MailTemplateTypesService } from 'projects/erp-app/src/app/mails/mail-template-types/mail-template-types.service';
import { DocumentDetailsComponent } from 'projects/erp-app/src/app/base/documents/documents/document-details/document-details.component';
import { DocumentsService } from 'projects/erp-app/src/app/base/documents/documents/documents.service';
import { PaymentsService } from 'projects/erp-app/src/app/base/payments/payments.service';
import { DocumentRelationsService } from 'projects/erp-app/src/app/base/documents/document-relations/document-relation.service';
import { ModificationService } from 'projects/erp-app/src/app/core/services/modification.service';
import { DocumentLineRelationsService } from 'projects/erp-app/src/app/base/documents/document-line-relations/document-relation.service';

@Component({
  selector: 'app-purchase-document-details',
  templateUrl: './purchase-document-details.component.html',
  styleUrls: ['./purchase-document-details.component.scss']
})
export class PurchaseDocumentDetailsComponent<
  TPurchaseDocument extends PurchaseDocument,
  TPurchaseDocumentService extends PurchaseDocumentsService<TPurchaseDocument, TPurchaseDocumentStatus>,
  TPurchaseDocumentLine extends PurchaseDocumentLine,
  TPurchaseDocumentLineService extends PurchaseDocumentLinesService<PurchaseDocumentLine>,
  TPurchaseDocumentStatus extends PurchaseDocumentStatus,
  TPurchaseDocumentStatusesService extends PurchaseDocumentStatusesService<TPurchaseDocumentStatus>,
  TPurchaseDocumentHeaderStatus extends PurchaseDocumentHeaderStatus,
  TPurchaseDocumentHeaderStatusesService extends PurchaseDocumentHeaderStatusesService<TPurchaseDocumentHeaderStatus>,
> extends DocumentDetailsComponent {

  public override element: TPurchaseDocument = <TPurchaseDocument>getNew<TPurchaseDocument>();
  public override documentUrlController: string = "PurchaseDocument";
  public PurchaseDocumentService: TPurchaseDocumentService = <TPurchaseDocumentService>getNew<TPurchaseDocumentService>();
  public PurchaseDocumentLinesService: TPurchaseDocumentLineService = <TPurchaseDocumentLineService>getNew<TPurchaseDocumentLineService>();
  public PurchaseDocumentStatusesService: TPurchaseDocumentStatusesService = <TPurchaseDocumentStatusesService>getNew<TPurchaseDocumentStatusesService>();
  public PurchaseDocumentHeaderStatusesService: TPurchaseDocumentHeaderStatusesService = <TPurchaseDocumentHeaderStatusesService>getNew<TPurchaseDocumentHeaderStatusesService>();

  public availableStatuses: TPurchaseDocumentStatus[] = [];

  constructor(
    public override   ngxUiLoaderService: NgxUiLoaderService,
    public override activatedRoute: ActivatedRoute,
    public override  reportsService: ReportsService,
    public configurationsService: ConfigurationsService,
    public override  documentDatasService: DocumentDatasService,
    public thirdsService: ThirdsService,
    public  mailTemplateTypesService: MailTemplateTypesService,
    public override  documentService: DocumentsService,
    public override paymentsService: PaymentsService,
    public override documentRelationsService: DocumentRelationsService,    
    public override documentLineRelationsService: DocumentLineRelationsService,
    public override modificationService : ModificationService
  ) {
    super(ngxUiLoaderService,reportsService,documentDatasService,documentService,paymentsService,documentRelationsService,documentLineRelationsService,modificationService);
    // super(ngxUiLoaderService, reportsService, documentDatasService, documentService, mailTemplateTypesService);
  }

  // override async ngOnInit(): Promise<void> {
  //   this.ngxUiLoaderService.start();
  //   this.activatedRoute.data.subscribe(async ({ element }) => {
  //     this.loadDependencies();

  //     if (!element.Id) {
  //       element = await this.initFromParams(element);
  //       element.ResponsibleUserId = this.authService.user?.ThirdId != undefined ? this.thirdsService.format(this.authService.user.ThirdId) : null;
  //       element = await this.PurchaseDocumentService?.insert(element);
  //       this.goToUrl('../' + element.Id);
  //     } else {
  //       this.element = element;
  //       this.element.Lines = this.PurchaseDocumentLinesService.formatLines(element.Lines);
  //       await this.refresh();
  //     }
  //   });
  // }


  // private async initFromParams(element: any) {
  //   element = this.defaultInitFromParams(element);
  //   element = await this.PurchaseDocumentService?.refreshThirdDocumentDelayFromSupplier(element);
  //   if (this.documentType === 'PurchaseOrder') {
  //     element = await this.PurchaseDocumentService?.refreshThirdDeliveryTimeFromSupplier(element);
  //   }
  //   return element;
  // }

  // private async reloadElement() {
  //   if (!this.element.Id) return;
  //   if (!this.PurchaseDocumentService) return;

  //   const option: any = this.PurchaseDocumentService.defaultOptions;
  //   const element: TPurchaseDocument = await this.PurchaseDocumentService.findByID(this.element.Id, option);
  //   if (element.Lines) {
  //     this.element.Lines = this.PurchaseDocumentLinesService.formatLines(element.Lines);
  //   }
  //   this.element = element;
  // }

  // // DocumentRelation
  // public async reloadDocumentRelation() {
  //   if (!this.element.Id) return;
  //   if (!this.PurchaseDocumentService) return;
  //   const element: TPurchaseDocument = await this.PurchaseDocumentService.findByID(this.element.Id, { expand: ["DocumentRelations"] });
  //   if (element.DocumentRelations) {
  //     this.element.DocumentRelations = element.DocumentRelations;
  //   }
  // }

  // // Document Statuses
  // // -----------------
  // public override initStatusBar() {
  //   this.PurchaseDocumentStatusesService.load().then((statuses: DocumentStatus[]) => {
  //     this.documentStatuses.statuses = statuses;
  //     this.documentStatuses.documentStatuses = this.element.Statuses || [];
  //     this.documentStatuses.refreshItems();
  //   });
  // }
  // public async onDocumentStatusChanged(event: DocumentStatusChangedEvent) {
  //   if (!this.authService.hasPermission(this.basePermissionKey + '.ChangeStatus')) {
  //     NotificationsService.sendErrorMessage("You do not have the required permission!");
  //     return;
  //   }
  //   const link = {
  //     HeaderId: { Id: this.element.Id },
  //     StatusId: { Id: event.status.Id },
  //     Date: new Date(),
  //   };

  //   if (event.documentStatus)
  //     throw "PurchaseDocumentHeaderStatus already exists";

  //   const documentStatus = await this.updateDocumentStatus(<TPurchaseDocumentHeaderStatus>link);
  //   if (!this.element.Statuses || !documentStatus) {
  //     throw "PurchaseDocumentHeaderStatus cannot be created";
  //   }

  //   this.element.Statuses.push(documentStatus);
  //   await this.reloadElement();
  //   await this.refresh();
  // }

  // private async updateDocumentStatus(link: TPurchaseDocumentHeaderStatus): Promise<TPurchaseDocumentHeaderStatus | undefined> {
  //   let documentStatus: TPurchaseDocumentHeaderStatus = await this.PurchaseDocumentHeaderStatusesService.insert(link);
  //   if (!documentStatus.Id) return;

  //   return await this.PurchaseDocumentHeaderStatusesService.findByID(documentStatus.Id);
  // }

  // //-------------------------------------------------------------------------


  // // Document Delay
  // // --------------
  // override async updateDocumentDelay(delay: DocumentDelay) {
  //   if (!delay.Id) return;

  //   const deadline = await this.PurchaseDocumentService?.computeDeadline(this.element.Date, delay.Id,this.documentType);
  //   this.element.Deadline = new Date(deadline);
  // }

  // public async onDateChanged() {
  //   if (!this.authService.hasPermission(this.basePermissionKey + '.update')) {
  //     NotificationsService.sendErrorMessage("You do not have the required permission!");
  //     return;
  //   }
  //   if (!this.element.DocumentDelayId) return;
  //   await this.updateDocumentDelay(this.element.DocumentDelayId);
  // }

  // public override async update(showMessage: boolean = true) {
  //   if (!this.authService.hasPermission(this.basePermissionKey + '.update')) {
  //     NotificationsService.sendErrorMessage("You do not have the required permission!");
  //     return;
  //   }

  //   if (!this.element.Id) return;
  //   if (!this.PurchaseDocumentService) return;

  //   await this.saveDocumentData();
  //   await this.PurchaseDocumentService?.update(this.element.Id, await this.PurchaseDocumentService?.format(this.element));
  //   let PurchaseDocument = await this.PurchaseDocumentService?.findByID(this.element.Id);
  //   this.updatePrices(PurchaseDocument);
  //   await this.reloadDocumentData();
  //   await this.refresh();
  //   if (showMessage == true) {
  //     NotificationsService.sendSuccess("Record updated");
  //   }

  //   this.element.DocumentRelations = PurchaseDocument.DocumentRelations;
  //   this.onElementLineChange()
  // }

  // // Document data
  // // -------------
  // public async reloadDocumentData() {
  //   if (!this.element.Id) return;
  //   let element = await this.PurchaseDocumentService.findByID(this.element.Id, {
  //     expand: ['DocumentDataCollection.ThirdId.ThirdContacts',
  //       'DocumentDataCollection.ThirdId.Addresses',
  //       'DocumentDataCollection.ContactId', 'DocumentDataCollection.DeliveryAddressId',
  //       'DocumentDataCollection.AddressId', 'ThirdId', 'IntermediateThirdId']
  //   });
  //   this.element.DocumentDataCollection = [];
  //   this.element.DocumentDataCollection = element.DocumentDataCollection;
  //   await this.updateDocumentData(element);
  // }

  // public override async updateDocumentData(PurchaseDocument: TPurchaseDocument) {
  //   this.element.ThirdDocumentDataIndex = PurchaseDocument.DocumentDataCollection.findIndex((d: DocumentData) => d.ThirdId.Id == PurchaseDocument.ThirdId.Id);
  //   this.element.ThirdDocumentData = PurchaseDocument.DocumentDataCollection[this.element.ThirdDocumentDataIndex];
  //   if (PurchaseDocument.IntermediateThirdId && PurchaseDocument.IntermediateThirdId.Id) {
  //     this.element.IntermediateThirdIdDocumentDataIndex = PurchaseDocument.DocumentDataCollection.findIndex((d: DocumentData) => d.ThirdId.Id == PurchaseDocument.IntermediateThirdId.Id) ?? -1;
  //     this.element.IntermediateThirdIdDocumentData = PurchaseDocument.DocumentDataCollection.find((d: DocumentData) => d.ThirdId.Id == PurchaseDocument.IntermediateThirdId.Id) ?? await this.documentDatasService.getInstance();
  //   }
  //   else {
  //     this.element.IntermediateThirdIdDocumentData = await this.documentDatasService.getInstance();
  //   }
  // }

  // public async onDeleteConfirmed() {
  //   if (!this.element?.Id) return
  //   this.deleteConfirm.close();
  //   await this.PurchaseDocumentService?.remove(this.element.Id);
  //   this.router.navigate(['../'], { relativeTo: this.activatedRoute });
  //   NotificationsService.sendSuccess("Record deleted");
  // }

  // public async setStatusTransmitted() {
  //   // Change Status if Purchase.Status == Draft
  //   // -------------------------------------
  //   if (this.element.CurrentStatusLink?.StatusId?.Code != this.defaultStatusCode) return;
  //   const transmittedStatusId = this.documentStatuses.statuses.find((e: DocumentStatus) => e.Code == "Order.Transmitted")?.Id;
  //   const link = {
  //     HeaderId: { Id: this.element.Id },
  //     StatusId: { Id: transmittedStatusId },
  //     Date: new Date(),
  //   };
  //   const documentStatus = await this.updateDocumentStatus(<TPurchaseDocumentHeaderStatus>link);
  //   if (!this.element.Statuses || !documentStatus) {
  //     throw "PurchaseDocumentHeaderStatus cannot be updated";
  //   }

  //   this.element.Statuses.push(documentStatus);
  //   await this.refresh();
  // }

  // // Document line
  // // -------------
  // public async onElementLineChange(line?: TPurchaseDocumentLine) {
  //   if (!this.element.Id) return;
  //   if (!this.PurchaseDocumentService) return;

  //   // for test
  //   const element = (await this.PurchaseDocumentService?.findByID(this.element.Id)); // Add select and expand
  //   if (element.Lines) {
  //     this.element.Lines = this.PurchaseDocumentLinesService.formatLines(element.Lines);
  //   }
  //   this.updatePrices(element);
  // }

  // // Payments
  // // --------
  // public async onPaymentChange(e: any) {
  //   if (!this.element.Id) return;
  //   const element = (await this.PurchaseDocumentService?.findByID(this.element.Id, { expand: ['Payments.PaymentStateId', 'Payments.PaymentMethodPrimary', 'Payments.PaymentTypeId'] }));
  //   this.element.Payments = element.Payments;
  //   this.updatePrices(element);
  // }
}
