import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PageComponent } from 'projects/libraries/syslink-components/src/public-api';
import { Product } from './product.model';
import { ProductsService } from './product.service';
import { ProductGridComponent } from './product-grid/product-grid.component';

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.scss']
})
export class ProductsComponent extends PageComponent {

  @Input() expand: string | string[] = ["ProductCategoryId", "DefaultUnitId", "SupplierCatalogs"];
  @Input() canRefresh: boolean = false;

  @Output() dblClicked: EventEmitter<Product> = new EventEmitter<Product>();

  @Output() onAddNewProductBtn: EventEmitter<void> = new EventEmitter<void>();

  @ViewChild('productGrid') public productGrid?: ProductGridComponent;

  constructor(
    public productsService: ProductsService,
    protected override activatedRoute: ActivatedRoute
  ) {
    super();
  }

  public addNewProduct() {
    if (this.onAddNewProductBtn.observed) return this.onAddNewProductBtn.emit();
    this.router.navigate(['new'], { relativeTo: this.activatedRoute });
  }

  public onDoubleClicked(product: Product) {
    if (this.dblClicked.observed) {
      this.dblClicked.emit(product);
    }
    else {
      this.router.navigate([product.Id], { relativeTo: this.activatedRoute });
    }
  }

  public foo() {
    this.productGrid?.gridComponent?.grid?.instance.refresh();
  }
}

