import { Injectable } from '@angular/core';
import { LoadOptions } from 'devextreme/data';
import { StockMoveDocumentsService } from '../../stock-move-documents/stock-move-documents/stock-move-documents.service';
import { StockMoveInternal } from './stock-move-internal.model';
import { StockMoveInternalHeaderStatus } from '../stock-move-internal-header-statuses/stock-move-internal-header-status.model';

@Injectable({
  providedIn: 'root'
})
export class StockMoveInternalsService extends StockMoveDocumentsService<StockMoveInternal, StockMoveInternalHeaderStatus> {
  public override url: string = 'StockMoveInternalHeader';
  
  public override defaultOptions: LoadOptions = {
    expand: [
     'CurrentStatusLink.StatusId',
      'ResponsibleUserId',
      'Lines($orderby=LineNo;$expand=UnitId,Children,ParentId,FromStockLocationId,ToStockLocationId)',
      'Statuses.StatusId',
      'DocumentDataCollection.ThirdId.ThirdContacts',
      'DocumentDataCollection.ThirdId.Addresses',
      'DocumentDataCollection.AddressId',
      'DocumentDataCollection.DeliveryAddressId',
      'ThirdId.ContactInformations.ThirdId',
      'ThirdId.ContactInformations.ContactTypeId',
      'DocumentRelations'
    ]
  };

  public override async getInstance(internal?: Partial<StockMoveInternal>): Promise<any> {

    let element = {
      Date: new Date(),
      ...internal,
    };
    element = await this.refreshResponsibleUser(element);
    // element = await this.refreshThirdDeliveryTimeFromSupplier(element);
    return element;
  }
}

export enum StockMoveInternalContextMenuItemActionCode {
  PrintToZIP = 'PrintToZIP',
  PrintToPDF = 'PrintToPDF',
  Status = 'Status'
}