import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SaleContractsService } from '../../sale-contracts/sale-contracts/sale-contracts.service';
import { SaleContract } from '../../sale-contracts/sale-contracts/sale-contract.model';
import { DocumentRelationsService } from '../../../base/documents/document-relations/document-relation.service';

@Component({
  selector: 'app-sale-invoice-contract-form',
  templateUrl: './sale-invoice-contract-form.component.html',
  styleUrls: ['./sale-invoice-contract-form.component.scss']
})
export class SaleInvoiceContractFormComponent {
  @Input() public element: any;
  @Output() public elementChange: EventEmitter<any> = new EventEmitter<any>();
  
  @Output() public onChangeSaleContrat: EventEmitter<any> = new EventEmitter<any>();
  
  @Input() public disabled: boolean = false;
 
  constructor(
    public saleContractsService:SaleContractsService,
    private documentRelationsService:DocumentRelationsService
  ){}

  public async onChangeContract(saleContract:SaleContract){
    if(!this.element.DateFrom || this.element.DateFrom.getFullYear().toString() == "1"){
      this.element.DateFrom = new Date();
    }

    if(!this.element.DateTo || this.element.DateTo.getFullYear().toString() == "1"){
      this.element.DateTo = new Date(this.element.DateFrom.setFullYear(this.element.DateFrom.getFullYear() + 1));
    }

    if(!this.element.SaleContractId && saleContract != undefined && saleContract.Id != undefined){
      // Create relation
      await this.createRelation(saleContract);
    }
    else if(this.element.SaleContractId && saleContract != undefined && saleContract.Id != this.element.SaleContractId.Id){
      // Update relation
      this.removeOldRelation();
      await this.createRelation(saleContract);
    }
    else if(this.element.SaleContractId && (saleContract == undefined || saleContract == null)){
      // Delete relation
      this.removeOldRelation();
    }

    this.element.SaleContractId = saleContract;
    this.onChangeSaleContrat.emit();
  }

  private async createRelation(saleContract:SaleContract){
    var documentRelation = await this.documentRelationsService.getInstance({
      FromId: this.element.Id,
      FromType: "SaleInvoiceHeader",
      ToId: saleContract.Id,
      ToType: "SaleContractHeader"
    });
    this.element.DocumentRelations.push(documentRelation);
  }
  
  private removeOldRelation(){
    var index = this.element.DocumentRelations.findIndex((e: any) =>
      (e.FromType == ("SaleInvoiceHeader") && e.FromId == this.element.Id && e.ToType == ("SaleContractHeader") && e.ToId == this.element.SaleContractId.Id) ||
      (e.ToType == ("SaleInvoiceHeader") && e.ToId == this.element.Id && e.FromType == ("SaleContractHeader") && e.FromId == this.element.SaleContractId.Id))
    if (index >= 0) {
      this.element.DocumentRelations.splice(index, 1);
    }
  }
}
