<syslink-block [title]="'Tasks'">
    <app-task-grid
    #taskGrid
    [permissionKey]="'list'"
    (add)="onAddButtonClicked()"
    [contextMenuItems]="taskGridContextMenuItems"
    (rowDoubleClicked)="ondRowDoubleClicked($event)"
    ></app-task-grid>
    <!-- [hidden]="viewType != 'grid'" -->
</syslink-block>


<!-- <app-task-kanban
  [hidden]="viewType != 'kanban'"
  (add)="onAddButtonClicked()"
  [toolbarActions]="[gridSwitchToolbarButton]"
></app-task-kanban> -->



<app-task-modal
  #taskModal
  (validate)="onValidateModalButtonClicked($event)"
  (onCancel)="onCancelModal()"
  ></app-task-modal>
  
  <app-task-multiple-edit-modal
  #taskMultipleEditModal
  (validated)="onValidateModalMultipleEdit()"
  (onCancel)="onCancelModal()"
></app-task-multiple-edit-modal>

<syslink-confirm-modal
  #confirmModal
  width="350px"
  (onValidate)="onConfirmModalValidated($event)"
></syslink-confirm-modal>

<app-work-time-modal
  #worktimeModal
  (validate)="onValidateWorkTimeCreation($event)"
  [canEditType]="true"
  [canEditTask]="false"
  [updatingPermissionKey]="this.newBasePermissionKey + '.details.workTimes.list.doubleClick'"
  [addingPermissionKey]="this.newBasePermissionKey + '.details.workTimes.list.add'"
></app-work-time-modal>
<!-- <app-appointment-modal
  #appointmentModal
  [canEditType]="false"
  [canEditTask]="false"
  (validate)="onValidateAppointmentCreation($event)"
></app-appointment-modal> -->

<app-task-task-status-modal
  #statusModal
  (validate)="onConfirmModalValidated($event)"
></app-task-task-status-modal>

<app-sale-invoice-select-modal
  #saleInvoiceSelectModal
  (validate)="onConfirmModalValidated($event)"
  (onNewInvoiceButtonClicked)="onConfirmModalValidated($event)"
  [expand]="['CurrentStatusLink.StatusId', 'ThirdId']"
  ></app-sale-invoice-select-modal>

<syslink-modal
    #participantTagboxModal
    title="Users"
    (onValidate)="onConfirmModalValidated(participantTagboxModal.data)"
    width="350"
>
  <syslink-tagbox 
    #usersTagbox 
    label="Users" 
    valueKey="Id" 
    [items]="userAndUserGroupItems" 
    displayKey="FormattedName"
    [(model)]="participantTagboxModal.data.tagBoxTaskParticipants"
  ></syslink-tagbox>
</syslink-modal>
<!-- TODO Remove components -->
<!-- <app-user-tagbox-modal
  #userTagboxModal
  (validate)="onConfirmModalValidated($event)"
></app-user-tagbox-modal>

<app-user-group-tagbox-modal
  #userGroupTagboxModal
  (validate)="onConfirmModalValidated($event)"
></app-user-group-tagbox-modal> -->

<app-dynamic-price-invoicing
  #dynamicPriceInvoicing
  (onInvoiceCreated)="taskGrid.refresh()"
></app-dynamic-price-invoicing>