<syslink-block [title]="'Users'">
  <ng-container *ngIf="authService.hasPermission('securities.users.list.view')">
    <app-user-grid
      [canUpdate]="false"
      [canDelete]="false"
      [permissionKey]="'list'"
      [newTab]="false"
      storageKey="users-grid"
    ></app-user-grid>
  </ng-container>
</syslink-block>