import { Injectable } from '@angular/core';
import { ODataService } from '../../core/services/oData.service';
import { AccountPeriod } from './account-period.model';

@Injectable({
  providedIn: 'root'
})
export class AccountPeriodService extends ODataService<AccountPeriod> {
  public url = 'AccountPeriod';
}

