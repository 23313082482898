import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Task } from '../task.model';
import { TaskTagsService } from '../../task-tags/task-tags.service';
import { TaskPrioritiesService } from '../../task-priorities/task-priorities.service';

@Component({
  selector: 'app-task-other',
  templateUrl: './task-other.component.html',
  styleUrls: ['./task-other.component.scss']
})
export class TaskOtherComponent {
  @Input() public element: Task = new Task();
  @Output() public elementChange: EventEmitter<Task> = new EventEmitter<Task>();

  constructor(
    public taskTagsService:TaskTagsService,
    public taskPrioritiesService:TaskPrioritiesService
  ){}
}
