import { AfterViewInit, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { SyslinkColumn } from 'projects/libraries/syslink-components/src/lib/helpers/SyslinkColumn';
import { DocumentLine, DocumentLineType } from './document-line.model';
import { TreelistComponent } from '../../../../../../libraries/syslink-components/src/public-api';
import { BaseGridComponent } from 'projects/libraries/syslink-components/src/lib/grid/base-grid/base-grid.component';
import { FocusedCellChangingEvent } from 'devextreme/ui/tree_list';

@Component({
  selector: 'app-document-lines',
  templateUrl: './document-lines.component.html',
  styleUrls: ['./document-lines.component.scss']
})
export class DocumentLinesComponent extends BaseGridComponent<DocumentLine> implements AfterViewInit {

  @Input() public documentLines?: DocumentLine[] = [];
  @Input() public columns: SyslinkColumn[] = [];
  @Input() public override expand: string | string[] = ['UnitId', 'TaxRateId'];
  @Input() public selectedKey: number[] = [];
  @Input() public documentType: String = '';
  @Input() public subModuleCode: string = '';
  override storageKey?: string | undefined = "documents-lines-treelist-" + this.documentType;

  private _disabled: boolean = false;
  @Input() set disabled(value: boolean) {
    this._disabled = value;
    this.updateTreelistOptions();
  }
  get disabled(): boolean { return this._disabled; };

  @Output() public deleteLine: EventEmitter<any> = new EventEmitter<any>();
  @Output() public selectedKeyChange: EventEmitter<number[]> = new EventEmitter<number[]>();
  @Output() public selectionChanged: EventEmitter<any> = new EventEmitter<any>();
  @Output() public updateLine: EventEmitter<any> = new EventEmitter<any>();
  @Output() public onReorder: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild('treelist') public treelist?: TreelistComponent;

  constructor() {
    super();
  }

  override ngAfterViewInit(): void {
    this.updateTreelistOptions();
  }

  // Update TreeList options
  // -----------------------
  private updateTreelistOptions(): void {
    this.treelist?.treelist?.instance.option('editing.allowUpdating', !this.disabled);
    this.treelist?.treelist?.instance.option('editing.allowDeleting', !this.disabled);
  }

  // Format Row
  // ----------
  public onRowPrepared(e: any) {
    if (e.rowType != 'data' || !e.data.LineType) return;
    switch (e.data.LineType) {
      case 'post': this.formatPostRow(e);
        break;
      case 'text': this.formatTextRow(e);
        break;
      case 'various': this.formatVariousRow(e);
        break;
      case 'page': this.formatPageRow(e);
        break;
      default: this.formatProductRow(e);
    }
  }

  private formatPostRow(e: any) {
    e.rowElement.classList.add('postCell');
  }

  private formatProductRow(e: any) {
    e.rowElement.classList.add('productCell');
  }

  private formatTextRow(e: any) {
    e.rowElement.classList.add('textCell');
  }

  private formatVariousRow(e: any) {
    e.rowElement.classList.add('variousCell');
  }

  private formatPageRow(e: any) {
    e.rowElement.classList.add('pageCell');
  }

  public onChangeEditCell(e: any) {
    e.cancel = !this.canEditCell(e.data.LineType, e.column.dataField);
  }

  public onFocusedCellChanging(event: FocusedCellChangingEvent<DocumentLine>) {
    /**
     * Cas de figure
     * 
     * - Click de souris sur une case
     *      - Peut editer => Ouvrir l'édition
     *      - Ne peut pas => Cancel l'event
     * 
     * - Touche "Tab" en étant déjà en édition sur une case
     *      - Peut éditer => Ouvrir l'édition
     *      - Ne peut pas => trouver la première cell éditable de la row (descendre à la row suivante si aucune trouvé)
     *              - Attention au edge case
     * - Item avec avec "Maj" + "Tab" (inverse)
     */

    // Mouse click
    if (event.event?.type === 'dxpointerdown') {
      return;
    } else if (event.event?.type === 'keydown') {
      const diff = event.newColumnIndex - event.prevColumnIndex;

      while (!this.canEditCell(event.rows[event.newRowIndex].data.LineType, event.columns[event.newColumnIndex].dataField)) {
        console.log(this.canEditCell(event.rows[event.newRowIndex].data.LineType, event.columns[event.newColumnIndex].dataField));
        console.log('+1');


        event.newColumnIndex += diff;

        if (event.newColumnIndex === event.columns.length) {
          event.newRowIndex += diff;
          event.newColumnIndex = 0;
        } else if (event.newColumnIndex === -1) {
          event.newRowIndex += diff;
          event.newColumnIndex = event.columns.length - 1;
        }

        console.log(event.columns[event.newColumnIndex]);

      }
    }
  }

  private canEditCell(lineType: DocumentLineType | undefined, dataField: string | undefined): boolean {
    if (lineType == "post" && dataField != "Description" && dataField != "Reference") {
      return false;
    }
    if (lineType == "text" && dataField != "Description") {
      return false;
    }
    if (lineType == "page") {
      return false;
    }
    if ((lineType == "various" || lineType == "product") && (dataField == "ExTaxSaleGrossPrice" || dataField == "TaxAmount" || dataField == "ExTaxTotalPrice" || dataField == "InTaxTotalPrice" || dataField == "ExTaxUnitPrice")) {
      return false;
    }
    if (dataField == "ExTaxGrossPrice") {
      return false;
    }
    return true;
  }
}
