import { Injectable } from '@angular/core';
import { ToolbarItem } from 'devextreme/ui/data_grid';
import { SyslinkToolbarAction } from './toolbar.component';

@Injectable({
  providedIn: 'root'
})
export class ToolbarService {

  public static generateArchivedSwitchButtonItem(click: Function, text?: string, hint?: string, icon: string = 'clock'): ToolbarItem {
    return {
      name: 'archivedSwitch',
      widget: 'dxButton',
      options: {
        elementAttr: {
          id: 'archivedSwitch',
        },
        icon: icon,
        text: text,
        hint: hint,
        visible: true,
        activeStateEnabled: false,
        focusStateEnabled: false,
        hoverStateEnabled: true,
        onClick: click
      },
      locateInMenu: 'always'
    }
  }

  public static generateToolbarItem(item: SyslinkToolbarAction): ToolbarItem {
    if (item.widget !== 'dxButton') return {};

    const toolbarItem: ToolbarItem = {
      name: item.code,
      widget: 'dxButton',
      options: {
        elementAttr: {
          id: item.code,
          style: {
            "background-color": item.bgColor,
            "color": item.textColor,
            "border": item.border
          },
          class: item.classes
        },
        type: item.type,
        icon: item.icon,
        text: item.text,
        hint: item.hint,
        visible: item.visible,
        activeStateEnabled: false,
        focusStateEnabled: false,
        hoverStateEnabled: true,
        onClick: item.onClick || null
      },
      // html: "<sylink-button [text]='"+item.text+"'></sylink-button>",
      location: item.location
    }

    return toolbarItem;
  }
}
