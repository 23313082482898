<div class="row mb-3">
  <div class="col-sm-3 col-xs-12">
    <syslink-input-text
      label="Reference"
      [(model)]="element.InternalReference"
    ></syslink-input-text>
  </div>
  <div class="col-sm-3 col-xs-12">
    <syslink-select
      label="Brand"
      [store]="brandsService.store"
      (onCustomValue)="customNewBrandValue($event)"
      [(model)]="element.BrandId"
    ></syslink-select>
  </div>
  <div class="col-sm-3 col-xs-12">
    <syslink-input-text
      label="Make"
      [(model)]="element.Make"
    ></syslink-input-text>
  </div>

  <div class="offset-3 offset-xs-12"></div>
  <div class="col-sm-3 col-xs-12">
    <syslink-input-number
      label="Dry cost price"
      subModuleCode="Products.General"
      key="CalculatedPrecision"
      [(model)]="element.ForcedSalePrice"
      (focusOut)="onBasePriceChange()"
    ></syslink-input-number>
  </div>
  <div class="col-sm-3 col-xs-12">
    <syslink-select
      label="Unit"
      [store]="unitsService.store"
      [(model)]="element.DefaultUnitId"
    ></syslink-select>
  </div>
  <div class="col-sm-3 col-xs-12">
    <syslink-input-number
      label="Labor (Hours)"
      [(model)]="element.Labor"
    ></syslink-input-number>
  </div>
</div>

<syslink-block
  [title]="'Customer catalog'"
  [titleBgColor]="'gray'"
  [titleTextColor]="'white'"
  [bordered]="true"
  [titlePadding]="2"
>
    <app-product-customer-catalogs
      [(element)]="element"
      ></app-product-customer-catalogs>
      <!-- (elementChange)="customerCatalogChanged.emit($event)" -->
</syslink-block>