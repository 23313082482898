import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CustomerCatalog } from '../customer-catalog.model';
import { CustomersService } from '../../../thirds/customers/customers/customers.service';
import { PriceListsService } from '../../../thirds/customers/price-lists/price-lists.service';

@Component({
  selector: 'app-customer-catalog-form',
  templateUrl: './customer-catalog-form.component.html',
  styleUrls: ['./customer-catalog-form.component.scss']
})
export class CustomerCatalogFormComponent {
  @Input() element: CustomerCatalog = new CustomerCatalog;
  @Output() elementChange: EventEmitter<CustomerCatalog> = new EventEmitter<CustomerCatalog>();
  @Input() public readOnly: boolean = false;

  constructor(
    public customersService: CustomersService,
    public priceListsService: PriceListsService
  ) { }

  public onChangeMarginValue(e: any) {
    if (!this.element.MarginId) return;
    this.element.MarginId.Value = this.element.MarginId.IsDiscountFixed ? e : Number((e / 100));
  }

  public onChangeMarginFixed(e: any) {
    if (!this.element.MarginId) return;
    if (e == 0)
      this.element.MarginId.IsDiscountFixed = false;
    else
      this.element.MarginId.IsDiscountFixed = true;
  }

  public onChangeDiscountValue(e: any) {
    if (!this.element.DiscountId) return;
    this.element.DiscountId.Value = this.element.DiscountId.IsDiscountFixed ? e : Number((e / 100));
  }

  public onChangeDiscountFixed(e: any) {
    if (!this.element.DiscountId) return;
    if (e == 0)
      this.element.DiscountId.IsDiscountFixed = false;
    else
      this.element.DiscountId.IsDiscountFixed = true;
  }
}