import { PaymentState } from "./payment-states/payment-state.model";
import { Entity } from "../../core/models/entity";
import { PaymentMethod } from "./payment-methods/payment-method";
import { PaymentType } from "./payment-types/payment-type.model";
import { MollieTerminal } from "../../connectors/mollie/mollie-terminal/mollie-terminal.model";

export class Payment extends Entity {
    public DocumentId?: number;
    public DocumentType?: string = "";
    public PaymentIdMollie: string = "";
    public Amount: number = 0;
    public RoundedAmount: number = 0;
    public PaymentMethodPrimary: PaymentMethod = new PaymentMethod();
    public AmountToReturn?: number;
    public Date?: Date;
    public Note?: string;
    public Reference?: string;
    public PaymentStateId?: PaymentState;
    public RefundPayment?: Payment;
    public Refunds?: Payment[];
    public PaymentTypeId?:PaymentType;
    public ExportToBob:boolean = false;

    // Linked document
    public LinkedDocumentId?: number;
    public LinkedDocumentType?: string;
    public LinkedDocument? : any;

    // Mollie terminal
    public SelectedMollieTerminal?: MollieTerminal;
    public SelectedMolliePayment?: any[];
    public MollieDocumentNameFormatted?: string;

    constructor(payment?: Partial<Payment>) {
        super();
        Object.assign(this, payment);
    }
}
