<syslink-modal
  #modal
  [title]="title"
  (onValidate)="onValidateButtonClicked(modal.data)"
  width="350"
>
  <p
    *ngIf="content"
    class="text-center m-0"
    [innerHTML]="content | translate"
  ></p>
  <syslink-select
    #select
    label="Type"
    [filter]="selectFilters"
    [store]="workTypesService.store"
    [(model)]="modal.data.Type"
    [expand]="expand"
  ></syslink-select>
</syslink-modal>