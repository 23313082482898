import { Component, Input } from '@angular/core';
import { CreditsafeData } from '../creditsafe.model';

@Component({
  selector: 'app-creditsafe-payment-data',
  templateUrl: './creditsafe-payment-data.component.html',
  styleUrls: ['./creditsafe-payment-data.component.scss']
})
export class CreditsafePaymentDataComponent {
  @Input() element: CreditsafeData = new CreditsafeData();
  private formatter = new Intl.NumberFormat('de-DE');

  public getField(field: string): string | number | undefined {
    if (!this.element.Report?.PaymentData) return undefined;
    if (!isNaN((this.element.Report?.PaymentData as any)[field])) {
      return this.formatter.format((this.element.Report?.PaymentData as any)[field]);
    } else {
      return (this.element.Report?.PaymentData as any)[field];
    }
  }
}
