import { Component, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AccountTypesService } from '../account-types/account-types.service';
import { Account } from './account.model';
import { AccountsService } from './accounts.service';
import { AccountModalComponent } from './account-modal/account-modal.component';
import { AccountTreelistComponent } from './account-treelist/account-treelist.component';
import { ModificationService } from '../../core/services/modification.service';

@Component({
  selector: 'app-accounts',
  templateUrl: './accounts.component.html',
  styleUrls: ['./accounts.component.scss']
})
export class AccountsComponent {
  @ViewChild('accountModal') public accountModal: AccountModalComponent = new AccountModalComponent(this.accountsService, this.accountTypesService);
  @ViewChild('treelist') public treelist?: AccountTreelistComponent;

  constructor(
    public translateService: TranslateService,
    public accountTypesService: AccountTypesService,
    public accountsService: AccountsService
  ) {
  }

  public async onAdd() {
    this.accountModal.element = await this.accountsService.getInstance();
    this.accountModal.modal?.open();
  }

  public async onUpdate(e: any) {
    this.accountModal.element = e;
    this.accountModal.modal?.open();
  }

  public async onValidateModal(element: Account) {
    await this.accountsService.createOrUpdate(element);
    this.accountModal.modal?.close();
    ModificationService.modificationDetected = false;
    this.treelist?.treelistComponent?.treelist?.instance.refresh();
  }
}
