import { AfterContentInit, AfterViewInit, Component, ContentChildren, EventEmitter, Input, Output, QueryList, ViewChild } from '@angular/core';
import { DxTabsComponent } from 'devextreme-angular';
import { TabComponent } from './tab/tab.component';

// Inspired by: https://stackblitz.com/edit/angular-dynamic-tabs?file=app%2Ftabs%2Ftabs.component.ts

@Component({
  selector: 'syslink-tabs',
  templateUrl: './tabs.component.html'
})
export class TabsComponent implements AfterContentInit, AfterViewInit {
  @Input() public defaultKey?: string;
  public selectedKey?: string = this.defaultKey;
  @Input() public selectedId: number = 0;
  @Output() public selectedIdChange: EventEmitter<number> = new EventEmitter<number>();
  @Output() public selectedKeyChange: EventEmitter<string> = new EventEmitter<string>();

  @ContentChildren(TabComponent) public tabs: QueryList<TabComponent> = new QueryList();
  @ViewChild(DxTabsComponent) public tabsContainer?: DxTabsComponent;

  // ContentChildren are set
  ngAfterContentInit(): void {
    this.tabs.forEach((tab: TabComponent, index: number) => (tab.id = index));
  }

  ngAfterViewInit(): void {
    if (!this.tabsContainer) return;
    this.tabsContainer.instance.option('items', this.tabs.toArray());
    this.updateSelectedTabIfRequired(this.defaultKey || 0);
  }

  public selectTab(key: string | number): void {
    const tab: TabComponent | undefined = this.tabs.toArray().find((tab: TabComponent) => (typeof key === 'string' ? tab.key : tab.id) === key);
    if (!tab || tab.id === undefined) return;

    // Deactivate all tabs
    this.tabs.toArray().forEach((tab: TabComponent) => (tab.active = false));
    tab.active = true;
    this.selectedId = tab.id;

    this.selectedIdChange.emit(tab.id);
    this.selectedKeyChange.emit(tab.key);
  }

  public updateSelectedTabIfRequired(tabSelector?: string | number) {
    if (tabSelector !== undefined && this.tabs.find(tab => tab.id === tabSelector || tab.key === tabSelector)?.visible) {
      this.selectTab(tabSelector);
    } else {
      const tab = this.tabs.find(tab => tab.visible);

      if (!tab?.id) return;

      this.selectTab(tab.id);
    }
  }

  public hasAtLeastOneTabVisible(): boolean {
    return this.tabs.some(tab => tab.visible)
  }

  // openTab(label: string, template, data, isCloseable = false) {
  //   // get a component factory for our TabComponent
  //   const componentFactory = this._componentFactoryResolver.resolveComponentFactory(
  //     TabComponent
  //   );

  //   // fetch the view container reference from our anchor directive
  //   const viewContainerRef = this.dynamicTabPlaceholder.viewContainer;

  //   // alternatively...
  //   // let viewContainerRef = this.dynamicTabPlaceholder;

  //   // create a component instance
  //   const componentRef = viewContainerRef.createComponent(componentFactory);

  //   // set the according properties on our component instance
  //   const instance: TabComponent = componentRef.instance as TabComponent;
  //   instance.label = label;
  //   instance.template = template;
  //   instance.dataContext = data;
  //   instance.isCloseable = isCloseable;

  //   // remember the dynamic component for rendering the
  //   // tab navigation headers
  //   this.dynamicTabs.push(componentRef.instance as TabComponent);

  //   // set it active
  //   this.selectTab(this.dynamicTabs[this.dynamicTabs.length - 1]);
  // }

  // closeTab(tab: TabComponent) {
  //   for (let i = 0; i < this.dynamicTabs.length; i++) {
  //     if (this.dynamicTabs[i] === tab) {
  //       // remove the tab from our array
  //       this.dynamicTabs.splice(i, 1);

  //       // destroy our dynamically created component again
  //       let viewContainerRef = this.dynamicTabPlaceholder.viewContainer;
  //       // let viewContainerRef = this.dynamicTabPlaceholder;
  //       viewContainerRef.remove(i);

  //       // set tab index to 1st one
  //       this.selectTab(this.tabs.first);
  //       break;
  //     }
  //   }
  // }

  // closeActiveTab() {
  //   const activeTabs = this.dynamicTabs.filter(tab => tab.active);
  //   if (activeTabs.length > 0) {
  //     // close the 1st active tab (should only be one at a time)
  //     this.closeTab(activeTabs[0]);
  //   }
  // }
}

export type TabData = {
  id:number,
  key:string,
  loaded:boolean,
  label: string,
  data?:any
}