<div class="row">
    <div class="col-10">
    </div>
    <div class="col-2">
        <syslink-input-number
            [max]="getMaxDate()"
            [min]="2023"
            label="Year"
            [(model)]="selectedYear"
            (modelChange)="onChangeDate($event)"
        ></syslink-input-number>
    </div>
</div>
<div
    *ngIf="element.HasCustomerRole"
    class="row"
>
    <div class="col-4">
        <syslink-block
            [title]="'Sale quotes'"
            [titleSize]="4"
            titleBgColor="gray"
            [bordered]="true"
            [titlePadding]="2"
        >
            <syslink-chart
                *ngIf="authService.hasPermission(this.basePermissionKey + '.statistics.tab.SaleQuoteStatistic')"
                [title]="{text:'Sale quotes' | translate, font:{size:14}}"
                [legendHeader]="saleQuoteLegendHeader"
                [data]="saleQuoteData"
                [series]="series"
                [size]=" {height: 300}"
                ></syslink-chart>
        </syslink-block>
    </div>
    <div class="col-4">
        <syslink-block
            [title]="'Sale invoices'"
            [titleSize]="4"
            titleBgColor="gray"
            [bordered]="true"
            [titlePadding]="2"
        >
            <syslink-chart
                *ngIf="authService.hasPermission(this.basePermissionKey + '.statistics.tab.SaleInvoiceStatistic')"
                [title]="{text:'Sale invoices' | translate, font:{size:14}}"
                [legendHeader]="saleInvoiceLegendHeader"
                [data]="saleInvoiceData"
                [series]="series"
                [size]=" {height: 300}"
            ></syslink-chart>
        </syslink-block>
    </div>
    <div class="col-4">
        <syslink-block
            [title]="'Sale credit notes'"
            [titleSize]="4"
            titleBgColor="gray"
            [bordered]="true"
            [titlePadding]="2"
        >
            <syslink-chart
                *ngIf="authService.hasPermission(this.basePermissionKey + '.statistics.tab.SaleCreditNoteStatistic')"
                [title]="{text:'Sale credit notes' | translate, font:{size:14}}"
                [legendHeader]="saleCreditNoteLegendHeader"
                [data]="saleCreditNoteData"
                [series]="series"
                [size]=" {height: 300}"
            ></syslink-chart>
        </syslink-block>
    </div>
</div>

<div
    *ngIf="element.HasSupplierRole"
    class="row mt-2"
>
    <div class="col-4">
        <syslink-block
            [title]="'Purchase invoices'"
            [titleSize]="4"
            titleBgColor="gray"
            [bordered]="true"
            [titlePadding]="2"
        >
            <syslink-chart
                *ngIf="authService.hasPermission(this.basePermissionKey + '.statistics.tab.PurchaseInvoiceStatistic')"
                [title]="{text:'Purchase invoices' | translate, font:{size:14}}"
                [legendHeader]="purchaseInvoiceLegendHeader"
                [data]="purchaseInvoiceData"
                [series]="series"
                [size]=" {height: 300}"
            ></syslink-chart>
        </syslink-block>
    </div>
    <div class="col-4">
        <syslink-block
            [title]="'Purchase credit notes'"
            [titleSize]="4"
            titleBgColor="gray"
            [bordered]="true"
            [titlePadding]="2"
        >
            <syslink-chart
                *ngIf="authService.hasPermission(this.basePermissionKey + '.statistics.tab.PurchaseCreditNoteStatistic')"
                [title]="{text:'Purchase credit notes' | translate, font:{size:14}}"
                [legendHeader]="purchaseCreditNoteLegendHeader"
                [data]="purchaseCreditNoteData"
                [series]="series"
                [size]=" {height: 300}"
            ></syslink-chart>
        </syslink-block>
    </div>
</div>