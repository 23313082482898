import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Third } from '../third.model';
import { CountriesService } from '../../../base/addresses/countries/countries.service';
import { SubjugationCategoriesService } from '../../../accountings/subjugation-categories/subjugation-category.service';
import { TaxRatesService } from '../../../base/tax-rates/tax-rates.service';
import { TaxTypesService } from '../../../accountings/tax-types/tax-type.service';

@Component({
  selector: 'app-third-finance-form',
  templateUrl: './third-finance-form.component.html',
  styleUrls: ['./third-finance-form.component.scss']
})
export class ThirdFinanceFormComponent {
  @Input() public element: Third = new Third();
  @Output() public elementChange: EventEmitter<Third> = new EventEmitter<Third>();

  constructor(
    public countriesService: CountriesService,
    public subjugationCategoriesService: SubjugationCategoriesService,
    public taxRatesService:TaxRatesService,
    public taxTypesService:TaxTypesService
  ) { }

  public onBeforeAddBankAccount(e: any) {
    e.data.ThirdId = this.element.Id;
  }
}
