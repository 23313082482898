import { Injectable } from '@angular/core';
import { ODataService } from '../../../core/services/oData.service';
import { WorkTime } from './work-time';
import { ThirdsService } from '../../../thirds/thirds/thirds.service';
import { AuthService } from '../../../core/auth/auth.service';
import { WorkTypesService } from '../../works/work-types/work-types.service';
import { LoadOptions } from 'devextreme/data';
import { UsersService } from '../../../core/auth/users/users.service';
import { WorkTimeInvoicingLine } from '../work-time-invoicing-line/work-time-invoicing-line.model';
import { NotificationsService } from 'projects/libraries/syslink-components/src/public-api';
import { Task } from '../../tasks/tasks/task.model';
import { TaskBillingTypeCode } from '../../tasks/task-billing-types/task-billing-type.model';
@Injectable({
  providedIn: 'root'
})
export class WorkTimesService extends ODataService<WorkTime> {

  public override url: string = "WorkTime";
  private instance: WorkTime | undefined;
  public override defaultOptions: LoadOptions<any> = {
    expand: [
      'ThirdId',
      'UserId.ThirdId',
      'TypeId',
      'TaskId',
      'TaskId.ThirdId',
      'TaskId.TaskBillingTypeId',
      'SaleInvoiceLineId.HeaderId'
    ]
  }

  constructor(
    private authService: AuthService,
    private workTypesService: WorkTypesService,
    private thirdsService: ThirdsService,
    private usersService: UsersService,
  ) {
    super();
  }

  public override format(element: Partial<WorkTime>): WorkTime {
    var result: any = {
      ...element,
      ThirdId: element.ThirdId?.Id,
      TypeId: element.TypeId?.Id,
      UserId: element.UserId?.Oid,
      TaskId: element.TaskId?.Id,
      SaleInvoiceLineId: element.SaleInvoiceLineId?.Id,
    };

    delete result.Duration;

    return result;
  }

  public override async getInstance(params?: Partial<WorkTime> | undefined): Promise<any> {
    const currentUser = this.authService.user != undefined ? this.usersService.format(this.authService.user) : undefined;
    const defaultWorktype = await this.workTypesService.findByID(1);

    // Calculate dates and durations
    // -----------------------------
    let startDate = new Date();
    
    startDate.setSeconds(0);
    startDate.setMilliseconds(0);    

    let endDate = new Date(startDate);
    endDate.setMinutes(endDate.getMinutes() + 15);

    if (!this.instance) {
      this.instance = new WorkTime({
        TypeId: defaultWorktype,
        StartDate: startDate,
        EndDate: endDate,
        UserId: currentUser,
        ...params
      });
    }

    this.updateDuration(this.instance);

    const result: Partial<WorkTime> = this.instance;
    this.instance = undefined;
    return result;
  }

  public invoice(invoiceAssignementDTO: any): Promise<void> {
    return this.apiService.sendRequest(`/api/TimeManagement/invoice`, "POST", invoiceAssignementDTO);
  }

  public addInvoiceLine(invoiceId: number, workTimeInvoicingLines: WorkTimeInvoicingLine[]): Promise<void> {
    return this.apiService.sendRequest(`/api/odata/${this.url}/invoice/${invoiceId}`, "POST", workTimeInvoicingLines);
  }

  public getInvoicingLines(thirdId: number): Promise<WorkTimeInvoicingLine[]> {
    return this.apiService.sendRequest(`/api/odata/${this.url}/invoicingLines/${thirdId}`);
  }
  // -------------------------------------------------------------------------------------------------------

  public async tryUpdateMultiples(elements: WorkTime[], fields: Array<{ field: string, value: any }>) {
    if (!this.validateFieldValueAllowed(elements, fields)) return;

    var updateValues: any = {};
    fields.forEach(fieldDatas => {
      updateValues[fieldDatas.field] = fieldDatas.value;
      elements.map((e:any)=>e[fieldDatas.field] = fieldDatas.value)
    });
    await this.updateMultiples(elements.map(row => row.Id), updateValues);
    return elements;
  }
  public validateFieldValueAllowed(elements: WorkTime[], fields: Array<{ field: string, value: any }>): boolean {
    var result = true;
    fields.forEach(fieldDatas => {
      switch (fieldDatas.field) {
        case "IsBillable":
          result = fieldDatas.value ? this.canMarkAsBillable(elements) : this.canMarkAsNotBillable(elements);
          break;
      }
    });

    return result;
  }
  public can(actionCode: WorkTimeActionCode, elements: WorkTime[], value?: Partial<WorkTime>) {
    switch (actionCode) {
      case WorkTimeActionCode.Invoice: return this.canInvoice(elements, value);
      default: throw "Action on Task is not allowed";
    }
  }
  // Invoicing validations
  // ---------------------
  // Invoice
  // -------
  private canInvoice(elements: WorkTime[], value?: Partial<WorkTime>) {
    var result: boolean = true;

    if (elements?.some(row => row.IsBillable == false)) {
      NotificationsService.sendErrorMessage("One or more selected line(s) are not billable");
      result = false;
    }

    if (elements?.filter(row => row.IsBilled).length == elements?.length) {
      NotificationsService.sendErrorMessage("One or more selected line(s) are already billed");
      result = false;
    }

    if (elements?.some(row => !row.ThirdId)) {
      NotificationsService.sendErrorMessage("Third is required");
      result = false;
    }

    if (elements?.reduce((thirdIds: Set<number>, row: WorkTime) => {
      if (row.ThirdId?.Id)
        thirdIds.add(row.ThirdId.Id)

      return thirdIds;
    }, new Set<number>()).size > 1) {
      NotificationsService.sendErrorMessage("Third must be unique");
      result = false;
    }

    return result;
  }
  // Billable
  // --------
  private canMarkAsBillable(elements: WorkTime[], value?: Partial<WorkTime>) {
    var result: boolean = true;

    // Checking it still not billable elements
    // ---------------------------------------
    if (elements.filter(row => row.IsBillable).length == elements.length) {
      NotificationsService.sendErrorMessage("All selected line(s) are already billable");
      result = false;
    }

    if (elements?.filter(row => row.TaskId && row.TaskId.TaskBillingTypeId.Code == TaskBillingTypeCode.NotBillable).length) {
      NotificationsService.sendErrorMessage("One or more selected line(s) are linked to not billable task");
      result = false;
    }

    return result;
  }

  // Not billable
  // ------------
  private canMarkAsNotBillable(elements: WorkTime[], value?: Partial<WorkTime>) {
    var result: boolean = true;

    // Checking it still not billable elements
    // ---------------------------------------
    if (elements.filter(row => !row.IsBillable).length == elements.length) {
      NotificationsService.sendErrorMessage("All selected line(s) are already not billable");
      result = false;
    }

    if (elements?.some(row => row.IsBilled == true)) {
      NotificationsService.sendErrorMessage("One or more selected line(s) are already billed");
      result = false;
    }

    return result;
  }

  // Task validations
  // ---------------------
  // Task
  // -------
  public canTask(elements: WorkTime[],task:Task|undefined, value?: Partial<WorkTime>) {
    var result: boolean = true;

    if(task==undefined){
      NotificationsService.sendErrorMessage('Task cannot be empty');
      result = false;
    }

    if (elements?.some(row => !row.ThirdId)) {
      NotificationsService.sendErrorMessage("Third is required");
      result = false;
    }

    if (elements?.reduce((thirdIds: Set<number>, row: WorkTime) => {
      if (row.ThirdId?.Id)
        thirdIds.add(row.ThirdId.Id)

      return thirdIds;
    }, new Set<number>()).size > 1) {
      NotificationsService.sendErrorMessage("Third must be unique");
      result = false;
    }

    if (task?.ThirdId?.Id!=elements[0]?.ThirdId?.Id) {
      NotificationsService.sendErrorMessage("Third is different");
      result = false;
    }

    return result;
  }

  public updateDuration(element:WorkTime) {
    if (element.EndDate.getTime() <= element.StartDate.getTime()) {
        element.Duration = 0;
        return;
    }
    element.Duration = (element.EndDate.getTime() - element.StartDate.getTime()) / 1000 / 60;
    return element;
}

  // -------------------------------------------------------------------------------------------------------

  
  public async getStatistics(filter: any) {
    var filters = this.convertODataFiltersToString(filter);
    if (filters == "") return undefined;
    return this.apiService.sendRequest('/api/worktime/getStatistiques/' + filters, 'GET');
  }

  
  // Statistics
  // ----------
  public convertODataFiltersToString(filters: any[]): string {
    if (!filters || filters == null) return '';
    var result = '';

    // single filter
    if (typeof (filters[0]) === 'string' && (filters[0] == "StartDate" || filters[0] == "EndDate" || filters[0] == "Date")) {
      result = this.formatDate(filters[2], filters[1], filters[0]);
    }
    else if (typeof (filters[0]) === 'string' && (filters[0] == "ExTaxTotal" || filters[0] == "InTaxTotal" || filters[0] == "AmountRemaining")) {
      result = this.formatNumber(filters[2], filters[1], filters[0]);
    }
    else if (typeof (filters[0]) === 'string') {
      switch (filters[1]) {
        case 'contains':
          result = `contains(${filters[0]}%2C%27${filters[2]}%27)`;
          break;
        case 'notcontains':
          result = `not%20contains(${filters[0]}%2C%27${filters[2]}%27)`;
          break;
        case 'startswith':
          result = `startswith(${filters[0]}%2C%27${filters[2]}%27)`;
          break;
        case 'endswith':
          result = `endswith(${filters[0]}%2C%27${filters[2]}%27)`;
          break;
        case '=':
          result = `${filters[0]}%20eq%20%27${filters[2]}%27)`;
          break;
        case '<>':
          result = `${filters[0]}%20ne%20%27${filters[2]}%27)`;
          break;
      }
    }
    // multiple filter
    else if (Array.isArray(filters[0])) {
      for (var i = 0; i < filters.length; i++) {
        if (filters[i + 1] != undefined) {
          result += `(${this.convertODataFiltersToString(filters[i])})%20${filters[i + 1]}%20`;
          i++;
        }
        else {
          result += `(${this.convertODataFiltersToString(filters[i])})`
        }
      }
    } else return '';

    return result;
  }
  private formatDate(value: string, type: string, field: string) {
    if (type == 'between') {
      const firstdate = new Date(value[0]);
      const firstyear = firstdate.getFullYear();
      const firstmonth = String(firstdate.getMonth() + 1).padStart(2, '0');
      const firstday = String(firstdate.getDate()).padStart(2, '0');

      const seconddate = new Date(value[1]);
      const secondyear = seconddate.getFullYear();
      const secondmonth = String(seconddate.getMonth() + 1).padStart(2, '0');
      const secondday = String(seconddate.getDate()).padStart(2, '0');

      return `${field} >= %23${firstyear}-${firstmonth}-${firstday}%23  AND ${field} <= %23${secondyear}-${secondmonth}-${secondday}%23 `;
    }
    const date = new Date(value);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');

    const nextValue = new Date(date.setDate(date.getDate() + 1))
    const nextyear = nextValue.getFullYear();
    const nextmonth = String(nextValue.getMonth() + 1).padStart(2, '0');
    const nextday = String(nextValue.getDate()).padStart(2, '0');

    switch (type) {
      case '=':
        return `${field} >= %23${year}-${month}-${day}%23 AND ${field} < %23${nextyear}-${nextmonth}-${nextday}%23 `;
      case '<>':
        return `${field} != %23${year}-${month}-${day}%23 `;
      case '<':
        return `${field} < %23${year}-${month}-${day}%23 `;
      case '>':
        return `${field} > %23${nextyear}-${nextmonth}-${nextday}%23 `;
      case '<=':
        return `${field} <= %23${year}-${month}-${day}%23 `;
      case '>=':
        return `${field} >= %23${year}-${month}-${day}%23 `;
    }
    return "";
  }

  private formatNumber(value: string, type: string, field: string) {
    switch (type) {
      case '=':
        return `${field} ==  ${value}`;
      case '<>':
        return `${field} !=  ${value}`;
      case '<':
        return `${field} <  ${value}`;
      case '>':
        return `${field} >  ${value}`;
      case '<=':
        return `${field} <=  ${value}`;
      case '>=':
        return `${field} >=  ${value}`;
      case 'between':
        return `${field} >= ${value[0]} AND ${field} <= ${value[1]}`;
    }
    return "";
  }
}
export enum WorkTimeActionCode {
  Task = 'Task',
  Invoice = 'Invoice',
  MarkAsNotBillable = 'MarkAsNotBillable',
  MarkAsBillable = 'MarkAsBillable',
  Delete = 'Delete',
  Type = 'Type'
}
