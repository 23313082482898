import { Injectable } from '@angular/core';
import { ODataService } from '../../core/services/oData.service';
import { BankReconciliation } from './bank-reconciliation.model';
import { LoadOptions } from 'devextreme/data';
import { BankReconciliationStatusService } from './bank-reconciliation-status/bank-reconciliation-status.service';
import { BankReconciliationHeaderStatus } from './bank-reconciliation-header-status/bank-reconciliation-header-status.model';
import { ApiService } from '../../core/api.service';

@Injectable({
  providedIn: 'root'
})
export class BankReconciliationsService extends ODataService<BankReconciliation> {
  public url = 'BankReconciliation';
  public override defaultOptions: LoadOptions = {
    expand: [
      'ResponsibleUserId',
      'BankReconciliationLines($orderby=LineNo;$expand=StatusId,SaleDocumentHeaderId,ThirdId, PaymentId)',
      'CurrentStatusLink.StatusId',
      'Statuses.StatusId'
    ]
  };

  constructor(
    private api: ApiService,
    private bankReconciliationStatusService: BankReconciliationStatusService
  ) {
    super();
  }

  public async importBankReconciliation(modalData: { CodaFile: any, Reference: string }) {
    let url = "/api/BankReconciliation/ImportBankReconciliation";
   
    const fileContent = await new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = () => {
        resolve(reader.result); // Résoudre la promesse avec le contenu du fichier
      };

      reader.onerror = (error) => {
        reject(error); // Rejeter la promesse en cas d'erreur
      };

      reader.readAsText(modalData.CodaFile); // Lire le fichier (par exemple, comme texte)
    });

    // Une fois que le fichier est lu, envoyer la requête avec le contenu du fichier
    const response = await this.api.sendRequest(url, 'POST', {
      "CodaFileStr": fileContent,
      "Reference": modalData.Reference,
      "FileName": modalData.CodaFile.name,
      "FileSize": modalData.CodaFile.size
    });
    return response;
  }

  public override format(element: Partial<BankReconciliation>): BankReconciliation {
    var result: any = {
      ...element,
      ResponsibleUserId: element.ResponsibleUserId?.Oid
    };

    // Update Date
    delete result.CurrentStatusLink;
    delete result.Statuses;
    delete result.BankReconciliationLines;

    if (result.Date) {
      let date: Date = new Date(result.Date);
      if (!date || date.getFullYear().toString() == "1" || date.getFullYear().toString() == "0") {
        delete result.Date;
      }
    }

    return result;
  }

  public override async getInstance(params?: Partial<BankReconciliation>): Promise<BankReconciliation> {
    var statuses = await this.bankReconciliationStatusService.load();

    var defaultStatus = new BankReconciliationHeaderStatus({
      StatusId: statuses.find((s: any) => s.Code == 'BankReconciliation.Draft'),
      Date: new Date()
    });

    var element = new BankReconciliation({
      CurrentStatusLink: defaultStatus,
      Statuses: [defaultStatus],
      Date: new Date(),
      ...params
    });

    return element;
  }

  public canSave(move: BankReconciliation): boolean {
    // if (!move.Date) {
    //   NotificationsService.sendErrorMessage('Date cannot be empty')
    //   return false;
    // }
    // if (!move.AccountJournalId) {
    //   NotificationsService.sendErrorMessage('Journal cannot be empty')
    //   return false;
    // }
    // if (!move.AccountPeriodId) {
    //   NotificationsService.sendErrorMessage('Period cannot be empty')
    //   return false;
    // }

    // var resultLine = 0;
    // move.BankReconciliationLines.forEach((line: BankReconciliationLine) => {
    //   if (line.Debit == 0 && line.Credit == 0) {
    //     resultLine++;
    //   }
    //   if (line.AccountId == null || line.AccountId == undefined) {
    //     resultLine++;
    //   }

    //   if(line.AccountId?.Code != "400000" && line.AccountId?.Code != "451000" && line.TaxId == null){
    //     resultLine++;
    //   }
    // });
    // if (resultLine != 0) {
    //   NotificationsService.sendErrorMessage('Error in the lines')
    //   return false;
    // }

    // var debit = move.BankReconciliationLines.reduce((sum, item) => sum + (item.Debit ?? 0), 0);
    // var credit = move.BankReconciliationLines.reduce((sum, item) => sum + (item.Credit ?? 0), 0);
    // var total = debit - credit;
    // if (total != 0) {
    //   NotificationsService.sendErrorMessage('Error in the lines')
    //   return false;
    // }
    return true;
  }



}