import { Entity } from "../../../core/models/entity";
import { AccountMoveStatus } from "../account-move-status/account-move-status.model";
import { AccountMove } from "../account-move.model";

export class AccountMoveHeaderStatus extends Entity {
    public Date?:Date;
    public MoveId?:AccountMove;
    public StatusId?:AccountMoveStatus;
    
    constructor(entity?: Partial<AccountMoveHeaderStatus>) {
        super();
        Object.assign(this, entity);
    }
}
