<!-- Toolbar -->
<syslink-toolbar [actions]="toolbarActions" class="me-3" [withLine]="false"></syslink-toolbar>
<div class="row">
    <div class="col-sm-9 col-xs-12">
        <!-- Toolbar -->

        <syslink-block [title]="getFormattedTitle()" [translateTitle]="false">

            <app-document-statuses 
                *ngIf="element?.Statuses" 
                #documentStatuses 
                [statuses]="availableStatuses"
                [documentStatuses]="element.Statuses" 
                (statusChanged)="onDocumentStatusChanged($event)"
                [showConfirmMessageSaveDocument]="true"
            ></app-document-statuses>

            <app-account-move-form 
                [(element)]="element"  
                [disabled]="!canEditDocument()"
            ></app-account-move-form>
        </syslink-block>
        <br>
        <syslink-block  [title]="'Move-lines'"  [translateTitle]="true">
           <app-account-move-line-grid-items 
                *ngIf="showGrid"
                #accountMoveLineGridItems
                [disabled]="!canEditDocument()"   
                [permissionKey]="'line.list'"
                [storageKey]= "'account-move-account-move-line-items'"
                [(elements)]="element.AccountMoveLines"
           ></app-account-move-line-grid-items>
        </syslink-block>
    </div>

    <div class="col-sm-3 col-xs-12 mt-3 mt-sm-0">
        <syslink-block
        [title]="'Totals'"
        [titleSize]="6"
        >
            <app-account-move-total [(element)]="element"></app-account-move-total>
    </syslink-block>
  </div>
</div>

<syslink-confirm-modal
  #deleteConfirm
  title="Delete"
  content="DeleteConfirmMessage"
  (onValidate)="accountMoveDelete()"
  validateText="Confirm"
  cancelText="Cancel"
></syslink-confirm-modal>