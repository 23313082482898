import { Component } from '@angular/core';
import { AppInjectorService } from 'projects/libraries/syslink-components/src/lib/services/app-injector.service';
import { StockMoveDocumentDetailsComponent } from '../../stock-move-documents/stock-move-documents/stock-move-document-details/stock-move-document-details.component';
import { StockMoveIncoming } from '../../stock-move-incomings/stock-move-incomings/stock-move-incoming.model';
import { StockMoveIncomingsService } from '../../stock-move-incomings/stock-move-incomings/stock-move-incomings.service';
import { StockMoveIncomingLine } from '../../stock-move-incomings/stock-move-incoming-lines/stock-move-incoming-line.model';
import { StockMoveIncomingLinesService } from '../../stock-move-incomings/stock-move-incoming-lines/stock-move-incoming-lines.service';
import { StockMoveIncomingHeaderStatus } from '../../stock-move-incomings/stock-move-incoming-header-statuses/stock-move-incoming-header-status.model';
import { StockMoveIncomingHeaderStatusesService } from '../../stock-move-incomings/stock-move-incoming-header-statuses/stock-move-incoming-header-statuses.service';
import { StockMoveIncomingHeaderHeaderStatus } from '../../stock-move-incomings/stock-move-incoming-header-header-statuses/stock-move-incoming-header-header-status.model';
import { StockMoveIncomingHeaderHeaderStatusesService } from '../../stock-move-incomings/stock-move-incoming-header-header-statuses/stock-move-incoming-header-header-statuses.service';

@Component({
  selector: 'app-stock-move-supplier-incoming-details',
  templateUrl: '../../stock-move-documents/stock-move-documents/stock-move-document-details/stock-move-document-details.component.html',
  styleUrls: ['./stock-move-supplier-incoming-details.component.scss']
})
export class StockMoveSupplierIncomingDetailsComponent extends StockMoveDocumentDetailsComponent<
  StockMoveIncoming,
  StockMoveIncomingsService,
  StockMoveIncomingLine,
  StockMoveIncomingLinesService,
  StockMoveIncomingHeaderStatus,
  StockMoveIncomingHeaderStatusesService,
  StockMoveIncomingHeaderHeaderStatus,
  StockMoveIncomingHeaderHeaderStatusesService
>{

  protected override loadDependencies() {
    this.StockMoveDocumentService = AppInjectorService.injector.get(StockMoveIncomingsService);
    this.StockMoveDocumentLinesService = AppInjectorService.injector.get(StockMoveIncomingLinesService);
    this.StockMoveDocumentStatusesService = AppInjectorService.injector.get(StockMoveIncomingHeaderStatusesService);
    this.StockMoveDocumentHeaderStatusesService = AppInjectorService.injector.get(StockMoveIncomingHeaderHeaderStatusesService);

    this.documentType = "StockMoveSupplierIncoming";
    this.type = "supplier";
    this.subModuleCode = "Stocks.SupplierIncoming";
    this.defaultStatusCode = "Stocks.Draft";
    this.documentLinesColumns = [...this.StockMoveDocumentService.getDefaultDocumentLineColumns(this.subModuleCode,this.documentType)];
  }

  public override canEditDocument(): boolean {
    return !this.hasActiveStatus("Stocks.Achieved");
  }

}
