<syslink-modal
  #modal
  [title]="title"
  (onValidate)="onValidateButtonClicked(modal.data)"
  width="400"
>
  <p
    *ngIf="content"
    class="text-center m-0"
    [innerHTML]="content | translate"
  ></p>
  <br>
  <syslink-switch
  label="Only show related invoice"
  [(model)]="FilteredByThird"
  (modelChange)="updateFilter()"
></syslink-switch>

  <syslink-select
    #select
    label="Invoice"
    [filter]="selectFilters"
    [store]="saleInvoicesService.store"
    [(model)]="modal.data.Invoice"
    [displayKey]="FilteredByThird?'NameFormatted':'NameFormattedWithThirdName'"
    [expand]="expand"
  ></syslink-select>

  <syslink-switch
    *ngIf="modal.data.OpenInNewTab != undefined"
    label="Open invoice in a new tab"
    [(model)]="modal.data.OpenInNewTab"
  ></syslink-switch>
</syslink-modal>