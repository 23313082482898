import { Injectable } from '@angular/core';
import { LoadOptions } from 'devextreme/data';
import { SaleDocumentsService } from '../../sale-documents/sale-documents/sale-documents.service';
import { SaleQuote } from './sale-quote.model';
import { SaleQuoteHeaderStatus } from '../sale-quote-header-statuses/sale-quote-header-status.model';
import { ApiService } from '../../../core/api.service';
import { ReportsService } from '../../../connectors/reports/reports.service';
import { SaleQuoteHeaderStatusesService } from '../sale-quote-header-statuses/sale-quote-header-statuses.service';
import { DocumentsService } from '../../../base/documents/documents/documents.service';
import { ThirdsService } from '../../../thirds/thirds/thirds.service';
import { DocumentDatasService } from '../../../base/documents/document-datas/document-datas.service';
import { SaleQuoteHeaderHeaderStatus } from '../sale-quote-header-header-statuses/sale-quote-header-header-status.model';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class SaleQuotesService extends SaleDocumentsService<SaleQuote, SaleQuoteHeaderStatus> {
  public override url: string = 'SaleQuoteHeader';
  
  constructor(
    public override apiService: ApiService,
    public override reportsService: ReportsService,
    private saleQuoteHeaderStatusesService: SaleQuoteHeaderStatusesService,
    public documentsService: DocumentsService,
    private thirdsService: ThirdsService,
    public override documentDatasService: DocumentDatasService,
    public override router: Router
  ) {
    super(reportsService, documentDatasService,documentsService,router);
  }
 
  public override defaultOptions: LoadOptions = {
    expand: [
      'ThirdId.CustomerId.PaymentMethodId',
      'ThirdId.CustomerId.SaleInvoiceDocumentDelayId',
      'CurrentStatusLink.StatusId',
      'ResponsibleUserId',
      'Lines($orderby=LineNo;$expand=TaxRateId($expand=NameTranslationId),UnitId,Margin,Discount,Children,SaleDocumentLineDiscountOperations,ParentId,TaxId,AccountId, ProductId)',
      'Statuses.StatusId',
      'DocumentDataCollection.ThirdId.ContactInformations.ContactTypeId',
      'DocumentDataCollection.ThirdId.ContactInformations.AddressId',
      'DocumentDataCollection.ThirdId.Addresses.LocalityId',
      'DocumentDataCollection.ThirdId.Addresses.PostalCodeId',
      'DocumentDataCollection.AddressId.LocalityId',
      'DocumentDataCollection.AddressId.PostalCodeId',
      'DocumentDataCollection.ThirdId.CustomerId.SaleInvoiceDocumentDelayId',
      'DocumentDataCollection.PostalCodeId', 'DocumentDataCollection.LocalityId', 'DocumentDataCollection.DeliveryPostalCodeId', 'DocumentDataCollection.DeliveryLocalityId',
      'ThirdId.ContactInformations.ThirdId',
      'ThirdId.ContactInformations.ContactTypeId',
      'DocumentDelayId',
      'Report',
      'DocumentRelations'
    ]
  };

  public override async getInstance(invoice?: Partial<SaleQuote>): Promise<any> {
    var statuses = await this.saleQuoteHeaderStatusesService.load();

    var defaultStatus = new SaleQuoteHeaderHeaderStatus({
      StatusId: statuses.find((s: any) => s.Code == 'Quote.Draft')
    });
    let element: Partial<SaleQuote> = {
      Date: new Date(),
      CurrentStatusLink: defaultStatus,
      Statuses: [defaultStatus],
      Payments:[],
      DocumentRelations:[],
      ...invoice,
    };

    if (element.ThirdId && !element.ThirdId.CustomerId && element.ThirdId.Id) {
      element.ThirdId = await this.thirdsService.findByID(element.ThirdId.Id, { expand: ['CustomerId.SaleInvoiceDocumentDelayId','ContactInformations.AddressId.LocalityId','ContactInformations.AddressId.PostalCodeId'] });
    }

    if (element.ThirdId) {
      element.DocumentDataCollection = [await this.documentDatasService.getInstance(undefined, "Sale", undefined, element.ThirdId)];
    }

    if (element.ThirdId && element.ThirdId.CustomerId) {
      element.DocumentDelayId = element.ThirdId.CustomerId.SaleInvoiceDocumentDelayId;
    }

    if (element.DocumentDelayId) {
      element.Deadline = await this.documentsService.computeDeadline(element.Date ?? new Date(), element.DocumentDelayId?.Id ?? 0);
    }

    return element;
  }

  public async getStatistics(filter: any) {
    var filters = this.convertODataFiltersToString(filter);
    if (filters == "") return undefined;
    return this.apiService.sendRequest('/api/SaleQuote/getStatistiques/' + filters, 'GET');
  }
}
