<!-- <syslink-toolbar
  *ngIf="authService.hasPermission(this.newBasePermissionKey + '.content.list.add')"
  [actions]="toolbarActions"
  [withLine]="false"
></syslink-toolbar> -->
<app-document-lines
  #documentLines
  *ngIf="element?.Lines && toolbarActions"
  [permissionKey]="'content.list'"
  [storageKey]= "'documents-lines-treelist-' + documentType"
  [toolbarActions]="toolbarActions"
  [documentLines]="element.Lines"
  (onReorder)="disabled?null:OnReorder($event)"
  [columns]="columns"
  [documentType]="documentType"
  [subModuleCode]="subModuleCode"
  [disabled]="disabled"
  (selectionChanged)="onSelectedKeyChange($event)"
  (deleteLine)="onDeleteLine($event)"
  (updateLine)="onUpdateLine($event)"
></app-document-lines>
<syslink-modal
  *ngIf="loadAddingProductModal"
  #addingProductModal
  title="Product"
  width="85%"
  height="80%"
>
  <app-product-grid
    #productGrid
    [permissionKey]="'productPicker.list'"
    [filter]="['Sellable eq true']"
    [canUpdate]="false"
    [canDelete]="false"
    [canAdd]="false"
    [canDblClck]="true"
    (rowDoubleClicked)="onProductDoubleClick($event)"
  ></app-product-grid>
</syslink-modal>

<!-- <syslink-modal
  #createNewProduct
  title="Create"
  width="500px"
  validateText="Yes"
  cancelText="No"
  (onValidate)="onCreateProduct()"
>
  <div class="text-center p-3">
    {{ "Searched text not found." | translate }} <br />
    {{ "Do you want to create a new One?" | translate }}
  </div>
</syslink-modal> -->