import { Injectable } from '@angular/core';
import { GroupPage } from './group-page.model';
import { ODataService } from '../../core/services/oData.service';
import { UserPermission } from '../../core/auth/user-permissions/user-permission.model';
import { User } from '../../core/auth/users/user.model';
import { AuthService } from '../../core/auth/auth.service';
import { ModuleInfo } from '../modules/module-info/module-info.model';
import { VersionDetail } from '../version-details/version-detail.model';
import { DrawerItem } from 'projects/libraries/syslink-components/src/public-api';

@Injectable({
  providedIn: 'root'
})
export class GroupPageService extends ODataService<GroupPage> {
  public url = 'GroupPage';

  constructor(
    private authService: AuthService
  ) {
    super();
  }
  public groupPages: any[] = [];
  public moduleInfoSyslink?: ModuleInfo;
  public moduleInfoDevExpress?: ModuleInfo;
  public versionDetails?: VersionDetail[];
  public drawerItems?: DrawerItem[];

  public isLoaded(groupUrl: string): boolean {
    var selectedGroupPage = this.groupPages.find((g: GroupPage) => g.GroupUrl == groupUrl);
    return selectedGroupPage?.Loaded ?? false;
  }

  public resetIsLoaded() {
    this.groupPages.map((g: GroupPage) => g.Loaded = false);
  }


  public async loadUserPermissions(groupUrl: string) {
    var selectedIndex = this.groupPages.findIndex((g: GroupPage) => g.GroupUrl == groupUrl);
    if (selectedIndex < 0 || !this.authService.user) return;
    if (!this.groupPages[selectedIndex] || !this.groupPages[selectedIndex].Id) return;
    var groupPage = await this.findByID(this.groupPages[selectedIndex]?.Id ?? 1, { select: ['Id', 'Parent.Id', 'Parent.GroupUrl', 'UserPermissions.Enabled', 'UserPermissions.ModuleId.Id', 'UserPermissions.SubModuleId.Id', 'UserPermissions.ModuleId.Enabled', 'UserPermissions.SubModuleId.Enabled', 'UserPermissions.Id', 'UserPermissions.Code', 'UserPermissions.Type.Code', 'UserPermissions.Users.Oid', 'UserPermissions.ActionGroups.Id', 'UserPermissions.UserRoles.Id'] })

    groupPage.UserPermissions = groupPage.UserPermissions?.filter((p: UserPermission) => p.ModuleId?.Enabled == true && p.SubModuleId?.Enabled == true && p.Enabled == true);
    var permissions: UserPermission[] = [];

    const userUserPermission = this.getPermissionsWithUserPermissions(groupPage);
    const actionGroupUserPermissions = this.getPermissionsWithUserActionGroup(groupPage);
    const userRoleUserPermissions = this.getPermissionsWithUserRole(groupPage);
    const userGroupUserPermissions = this.getPermissionsWithUserGroup(groupPage);

    permissions = [
      ...userUserPermission ?? [],
      ...actionGroupUserPermissions ?? [],
      ...userRoleUserPermissions ?? [],
      ...userGroupUserPermissions ?? []
    ];

    this.groupPages[selectedIndex].UserPermissions = permissions;
    this.authService.user.AllPermissions = [...permissions, ...(this.authService.user.AllPermissions ?? [])];
    this.groupPages[selectedIndex].Loaded = true;
    if (this.groupPages[selectedIndex] != undefined && this.groupPages[selectedIndex].Parent) {
      const group = this.groupPages[selectedIndex].Parent?.GroupUrl;
      if (group != undefined && this.groupPages[selectedIndex].Parent.GroupUrl != undefined && !this.isLoaded(this.groupPages[selectedIndex].Parent.GroupUrl)) {
        await this.loadUserPermissions(group);
      }
    }
  }

  private getPermissionsWithUserPermissions(groupPage: GroupPage) {
    return groupPage.UserPermissions?.filter((p: UserPermission) => {
      if (p.Users?.some((u: User) => u.Oid == this.authService.user?.Oid)) return true;
      return false;
    });
  }

  private getPermissionsWithUserActionGroup(groupPage: GroupPage) {
    return groupPage.UserPermissions?.filter(permission =>
      this.authService.user?.ActionGroups?.some(actionGroup => {
        if (permission.ActionGroups?.some(e => e.Id == actionGroup.Id)) {
          return true;
        }
        return false
      })
    ).map(permission => ({
      ...permission,
      Element: permission.ActionGroups?.filter(element =>
        this.authService.user?.ActionGroups?.some(actionGroup => actionGroup.Id === element.Id)
      )
    }));
  }

  private getPermissionsWithUserRole(groupPage: GroupPage) {
    return groupPage.UserPermissions?.filter(permission =>
      this.authService.user?.UserRoles?.some(userRoles => {
        if (permission.UserRoles?.some(e => e.Id == userRoles.Id)) {
          return true;
        }
        return false
      })
    ).map(permission => ({
      ...permission,
      Element: permission.UserRoles?.filter(element =>
        this.authService.user?.UserRoles?.some(actionGroup => actionGroup.Id === element.Id)
      )
    }));
  }

  private getPermissionsWithUserGroup(groupPage: GroupPage) {
    // Extraire tous les Ids des ActionGroups du User
    const userActionGroupIds = this.authService.user?.Groups.flatMap(group => group.ActionGroups.map(actionGroup => actionGroup.Id));

    // Filtrer les UsersPermissions du GroupPage
    return groupPage?.UserPermissions?.filter(permission =>
      permission?.ActionGroups?.some(actionGroup => userActionGroupIds?.includes(actionGroup.Id))
    );
  }
}
