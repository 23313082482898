import { Component, Input } from '@angular/core';
import { StockMoveIncoming } from '../stock-move-incomings/stock-move-incoming.model';
import { BaseGridComponent } from 'projects/libraries/syslink-components/src/lib/grid/base-grid/base-grid.component';
import { ContextMenuItemAction } from 'projects/libraries/syslink-components/src/lib/context-menus/context-menu-item-action';
import { SyslinkColumn } from 'projects/libraries/syslink-components/src/lib/helpers/SyslinkColumn';
import { StockMoveIncomingsService } from '../stock-move-incomings/stock-move-incomings.service';
import { DXCell } from 'projects/libraries/syslink-components/src/lib/grid/cells/dxcell';
import { SelectDescriptor } from 'devextreme/data';

@Component({
  selector: 'app-stock-move-incoming-grid',
  templateUrl: './stock-move-incoming-grid.component.html',
  styleUrls: ['./stock-move-incoming-grid.component.scss']
})
export class StockMoveIncomingGridComponent extends BaseGridComponent<StockMoveIncoming> {

  @Input() override detailsUrl: string = '/stockmoves/incomings';
  @Input() override storageKey: string = 'stock-move-incomings';
  @Input() public contextMenuItems: ContextMenuItemAction[] = [];
  @Input() showPager: boolean = true;
  @Input() public override expand?: string | string[] = ['CurrentStatusLink.StatusId'];
  @Input() public override select?: SelectDescriptor<any> = ['Id', 'No', 'ThirdId.Fullname', 'Deadline', 'ExternalReference','ResponsibleUserId.Fullname', 'Date'];

  public columns: SyslinkColumn[] = [
    new SyslinkColumn({ field: "No", label: "GRID.CAPTION.NO", width: 150, sortIndex: 2, sortDirection: 'desc' }),
    new SyslinkColumn({ field: "ThirdId.Fullname", label: "Customer", width: 250 }),
    new SyslinkColumn({ field: "CurrentStatusLink.StatusId.Name", label: "Status", cellTemplate: 'block-cell', data: { getBlockLabel: this.getStatusCellLabel, getBlockClass: this.getStatusCellClass, getBlockColor: this.getStatusCellColor }, width: 120 }),
    new SyslinkColumn({ field: "Deadline", label: 'StockMoveIncoming.Validity', type: "date" }),
    new SyslinkColumn({ field: "ExternalReference", label: "External reference", visible: true, width: 150 }),
    new SyslinkColumn({ field: "ResponsibleUserId.Fullname", label: "Responsible", width: 120 }),
    new SyslinkColumn({ field: "Date", label: "Date", type: "date", visible: false, width: 150 }),
    new SyslinkColumn({ field: "CurrentStatusLink.StatusId.Sequence", label: 'status incoming', sortIndex: 1, sortDirection: 'asc', width: 0, visible: false, filterable: false, headerFilterable: false, sortable: false })
  ];

  constructor(
    public StockMoveIncomingsService: StockMoveIncomingsService,
  ) {
    super();
  }

  getStatusCellLabel(cell: DXCell) {
    return cell.data.CurrentStatusLink.StatusId.Name;
  }


  getStatusCellClass(cell: DXCell) {
    let statusClass = 'text-white text-center'

    const statusCode = (<string>cell.data.CurrentStatusLink.StatusId.Code).toLowerCase().split('.')[1];
    if (statusCode == undefined) return;
    statusClass += ' ' + statusCode;
    return statusClass;
  }

  getStatusCellColor(cell: DXCell) {
    return cell.data.CurrentStatusLink.StatusId.Color;
  }
}


