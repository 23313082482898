import {
  CommonModule,
  CurrencyPipe,
  DATE_PIPE_DEFAULT_OPTIONS,
  registerLocaleData,
} from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import {
  APP_INITIALIZER,
  DEFAULT_CURRENCY_CODE,
  LOCALE_ID,
  NgModule,
} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { JwtModule } from '@auth0/angular-jwt';
import { TranslateModule } from '@ngx-translate/core';
import { NgxUiLoaderModule } from 'ngx-ui-loader';
import {
  GridComponent,
  NotificationsService,
  SyslinkComponentsModule,
} from '../../../libraries/syslink-components/src/public-api';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './core/pages/login/login.component';
import { AdminComponent } from './core/pages/admin/admin.component';
import { DashboardComponent } from './core/pages/dashboard/dashboard.component';
import { ThirdDetailsComponent } from './thirds/thirds/third-details/third-details.component';
import { ThirdFormComponent } from './thirds/thirds/third-form/third-form.component';
import { environment } from '../environments/environment';
import { BasicComponent } from './base/basics/basic.component';
import { CustomersComponent } from './thirds/customers/customers/customers.component';
import { SuppliersComponent } from './thirds/suppliers/suppliers.component';
import { CustomerFormComponent } from './thirds/customers/customers/customer-form/customer-form.component';
import { SupplierFormComponent } from './thirds/suppliers/supplier-form/supplier-form.component';
import { ThirdReferenceFormComponent } from './thirds/thirds/third-reference-form/third-reference-form.component';
import { ModuleDetailsComponent } from './base/modules/modules/module-details/module-details.component';
import { ThirdsComponent } from './thirds/thirds/thirds.component';
import { ThirdRelationsComponent } from './thirds/thirds/third-relations/third-relations.component';
import { ThirdRelationFormComponent } from './thirds/thirds/third-relations/third-relation-form/third-relation-form.component';
import { AddressesComponent } from './thirds/thirds/third-addresses/third-addresses/third-addresses.component';
import { AddressFormComponent } from './thirds/thirds/third-addresses/third-address-form/third-address-form.component';
import { AuthService } from './core/auth/auth.service';
import { TranslationsService } from './base/translations/translations/translations.service';
import { ModulesComponent } from './base/modules/modules/modules.component';
import { ProductsComponent } from './products/products/products.component';
import { ProductDetailsComponent } from './products/products/product-details/product-details.component';
import { ProductFormComponent } from './products/products/product-form/product-form.component';
import { ProductPurchaseFormComponent } from './products/products/product-purchase-form/product-purchase-form.component';
import { ProductStockFormComponent } from './products/products/product-stock-form/product-stock-form.component';
import { ProductSaleFormComponent } from './products/products/product-sale-form/product-sale-form.component';
import { SaleQuotesComponent } from './sales/sale-quotes/sale-quotes/sale-quotes.component';
import { SaleDocumentDetailsComponent } from './sales/sale-documents/sale-documents/sale-document-details/sale-document-details.component';
import { SaleDocumentFormComponent } from './sales/sale-documents/sale-documents/sale-document-form/sale-document-form.component';
import { DocumentStatusesComponent } from './base/documents/document-statuses/document-statuses.component';
import { SaleDocumentOtherComponent } from './sales/sale-documents/sale-documents/sale-document-other/sale-document-other.component';
import { SaleDocumentContentComponent } from './sales/sale-documents/sale-documents/sale-document-content/sale-document-content.component';
import { DocumentLinesComponent } from './base/documents/document-lines/document-lines.component';
import { ProductPickerComponent } from './products/products/product-picker/product-picker.component';
import { ProductSupplierCatalogsComponent } from './products/products/product-purchase-form/product-supplier-catalogs/product-supplier-catalogs.component';
import {
  DEFAULT_HTTP_REQUEST_TIMEOUT,
  DEFAULT_NOTIFICATIONS_DISPLAYTIME,
  DEFAULT_DATE_FORMAT,
  DEFAULT_PAGE_SIZE,
  DEFAULT_PAGE_SIZES,
} from 'projects/libraries/syslink-components/src/lib/helpers/defaults';
import { ProductCustomerCatalogsComponent } from './products/products/product-sale-form/product-customer-catalogs/product-customer-catalogs.component';
import { appFactory } from './app.factory';
import { UserFormComponent } from './core/auth/users/user-form/user-form.component';
import { ODataService } from './core/services/oData.service';
import { TaxesComponent } from './accountings/taxes/taxes.component';
import { AccountsComponent } from './accountings/accounts/accounts.component';
import { PaymentComponent } from './core/pages/payment/payment.component';
import { SaleInvoicesComponent } from './sales/sale-invoices/sale-invoices/sale-invoices.component';
import { SaleQuoteDetailsComponent } from './sales/sale-quotes/sale-quote-details/sale-quote-details.component';
import { SaleInvoiceDetailsComponent } from './sales/sale-invoices/sale-invoice-details/sale-invoice-details.component';
import { PaymentsComponent } from './base/payments/payments.component';
import { SaleCreditNotesComponent } from './sales/sale-credit-notes/sale-credit-notes/sale-credit-notes.component';
import { SaleCreditNoteDetailsComponent } from './sales/sale-credit-notes/sale-credit-note-details/sale-credit-note-details.component';
import { UsersComponent } from './core/auth/users/users.component';
import { UserDetailsComponent } from './core/auth/users/user-details/user-details.component';
import { ReportpreviewComponent } from './core/pages/reportpreview/reportpreview.component';
import { UserGroupDetailsComponent } from './core/auth/user-groups/user-group-details/user-group-details.component';
import { UserGroupsComponent } from './core/auth/user-groups/user-groups.component';
import { UserGroupFormComponent } from './core/auth/user-groups/user-group-form/user-group-form.component';
import { UserPermissionsComponent } from './core/auth/user-permissions/user-permissions.component';
import { UserPermissionDetailsComponent } from './core/auth/user-permissions/user-permission-details/user-permission-details.component';
import { UserPermissionFormComponent } from './core/auth/user-permissions/user-permission-form/user-permission-form.component';
import { UserRoleFormComponent } from './core/auth/user-roles/user-role-form/user-role-form.component';
import { UserRoleDetailsComponent } from './core/auth/user-roles/user-role-details/user-role-details.component';
import { UserRolesComponent } from './core/auth/user-roles/user-roles.component';
import { UserRoleGridComponent } from './core/auth/user-roles/user-role-grid/user-role-grid.component';
import { UserGroupGridComponent } from './core/auth/user-groups/user-group-grid/user-group-grid.component';
import { UserPermissionGridComponent } from './core/auth/user-permissions/user-permission-grid/user-permission-grid.component';
import { UserGridComponent } from './core/auth/users/user-grid/user-grid.component';
import { SaleContractDetailsComponent } from './sales/sale-contracts/sale-contract-details/sale-contract-details.component';
import { SaleContractsComponent } from './sales/sale-contracts/sale-contracts/sale-contracts.component';
import { SaleContractScheduleComponent } from './sales/sale-contracts/sale-contract-schedule/sale-contract-schedule.component';
import { TasksComponent } from './time-management/tasks/tasks/tasks.component';
import { TaskDetailComponent } from './time-management/tasks/tasks/task-detail/task-detail.component';
import { TaskFormComponent } from './time-management/tasks/tasks/task-form/task-form.component';
import { TaskOtherComponent } from './time-management/tasks/tasks/task-other/task-other.component';
import { AboutComponent } from './core/pages/about/about.component';
import { VersionDetailsComponent } from './base/version-details/version-details.component';
import { AppHttpInterceptor } from './core/app-http-interceptor';
import { SaleContractRenewalComponent } from './sales/sale-contracts/sale-contract-renewals/sale-contract-renewal.component';
import { ConfigurationsService } from './base/modules/configurations/configurations.service';

// Currency local
import localeFr from '@angular/common/locales/fr';
import { ThirdProfessionalFormComponent } from './thirds/thirds/third-professional-form/third-professional-form.component';
import { ThirdPrivateFormComponent } from './thirds/thirds/third-private-form/third-private-form.component';
import { MailTemplatesComponent } from './mails/mail-templates/mail-templates.component';
import { ThirdContactsComponent } from './thirds/thirds/third-contacts/third-contacts.component';
import { ThirdViesFormComponent } from './thirds/thirds/third-vies-form/third-vies-form.component';
import { TaskModalComponent } from './time-management/tasks/tasks/task-modal/task-modal.component';
import { TaskGridComponent } from './time-management/tasks/tasks/task-grid/task-grid.component';
import { TaskKanbanComponent } from './time-management/tasks/tasks/task-kanban/task-kanban.component';
import { CustomerGridComponent } from './thirds/customers/customers/customer-grid/customer-grid.component';
import { SaleInvoiceGridComponent } from './sales/sale-invoices/sale-invoice-grid/sale-invoice-grid.component';
import { WorkTimesComponent } from './time-management/work-times/work-times/work-times.component';
import { WorkTimeGridComponent } from './time-management/work-times/work-times/work-time-grid/work-time-grid.component';
import { WorkTimeModalComponent } from './time-management/work-times/work-times/work-time-modal/work-time-modal.component';
import { WorkTimeSchedulerComponent } from './time-management/work-times/work-times/work-time-scheduler/work-time-scheduler.component';
import { WorkTimeFiltersPopoverComponent } from './time-management/work-times/work-times/work-time-filters-popover/work-time-filters-popover.component';
import { AppointmentsComponent } from './time-management/appointments/appointments/appointments.component';
import { AppointmentGridComponent } from './time-management/appointments/appointments/appointment-grid/appointment-grid.component';
import { AppointmentModalComponent } from './time-management/appointments/appointments/appointment-modal/appointment-modal.component';
import { AppointmentSchedulerComponent } from './time-management/appointments/appointments/appointment-scheduler/appointment-scheduler.component';
import { AppointmentFiltersPopoverComponent } from './time-management/appointments/appointments/appointment-filters-popover/appointment-filters-popover.component';
import { ThirdTaskTabComponent } from './time-management/tasks/thirds/third-task-tab/third-task-tab.component';
import { TaskMultipleEditModalComponent } from './time-management/tasks/tasks/task-multiple-edit-modal/task-multiple-edit-modal.component';
import { UserSelectModalComponent } from './core/auth/users/user-select-modal/user-select-modal.component';
import { UserGroupSelectModalComponent } from './core/auth/user-groups/user-group-select-modal/user-group-select-modal.component';
import { WorkTimesPickerComponent } from './time-management/work-times/work-times/work-times-picker/work-times-picker.component';
import { TimetableLineGridComponent } from './time-management/timetables/timetable-lines/timetable-line-grid/timetable-line-grid.component';
import { TimetableComponent } from './time-management/timetables/timetables/timetable/timetable.component';
import { GeneralTabContentComponent } from './time-management/configurations/general-tab-content/general-tab-content.component';
import { SaleInvoiceSelectModalComponent } from './sales/sale-invoices/sale-invoice-select-modal/sale-invoice-select-modal.component';
import { UserTagboxModalComponent } from './core/auth/users/user-tagbox-modal/user-tagbox-modal.component';
import { UserGroupTagboxModalComponent } from './core/auth/user-groups/user-group-tagbox-modal/user-group-tagbox-modal.component';
import { TaskSelectModalComponent } from './time-management/tasks/tasks/task-select-modal/task-select-modal.component';
import { ReportConfigurationsComponent } from './connectors/reports/configurations/report-configurations.component';
import { SaleInvoiceStatusSelectModalComponent } from './sales/sale-invoices/sale-invoice-status/sale-invoice-status-select-modal/sale-invoice-status-select-modal.component';
import { ProductGridComponent } from './products/products/product-grid/product-grid.component';
import { SaleContractGridComponent } from './sales/sale-contracts/sale-contract-grid/sale-contract-grid.component';
import { SaleCreditNoteGridComponent } from './sales/sale-credit-notes/sale-credit-note-grid/sale-credit-note-grid.component';
import { SaleQuoteGridComponent } from './sales/sale-quotes/sale-quote-grid/sale-quote-grid.component';
import { ProductStockStockMoveGridComponent } from './products/products/product-stock-stock-move-grid/product-stock-stock-move-grid.component';
import { ThirdGridComponent } from './thirds/thirds/third-grid/third-grid.component';
import { BankAccountGridComponent } from './thirds/bank-accounts/bank-account-grid/bank-account-grid.component';
import { SupplierGridComponent } from './thirds/suppliers/supplier-grid/supplier-grid.component';
import { BasicGridComponent } from './base/basics/basic-grid/basic-grid.component';
import { AccountTreelistComponent } from './accountings/accounts/account-treelist/account-treelist.component';
import { DynamicPriceInvoicingComponent } from './time-management/tasks/dynamic-price-invoicing/dynamic-price-invoicing.component';
import { TimetableLineModalComponent } from './time-management/timetables/timetable-lines/timetable-line-modal/timetable-line-modal.component';
import { AssignementPriceCoefGridComponent } from './time-management/shared/assignement-price-coefs/assignement-price-coef-grid/assignement-price-coef-grid.component';
import { AssignementPriceCoefsComponent } from './time-management/shared/assignement-price-coefs/assignement-price-coefs.component';
import { SaleOrdersComponent } from './sales/sale-orders/sale-orders/sale-orders.component';
import { SaleOrderDetailsComponent } from './sales/sale-orders/sale-order-details/sale-order-details.component';
import { SaleOrderGridComponent } from './sales/sale-orders/sale-order-grid/sale-order-grid.component';
import { SaleOrderStatusSelectModalComponent } from './sales/sale-orders/sale-order-status/sale-order-status-select-modal/sale-order-status-select-modal.component';
import { PurchaseDocumentFinanceGridComponent } from './purchases/purchase-documents/purchase-document-finances/purchase-document-finance-grid/purchase-document-finance-grid.component';
import { PurchaseDocumentContentComponent } from './purchases/purchase-documents/purchase-documents/purchase-document-content/purchase-document-content.component';
import { PurchaseDocumentDetailsComponent } from './purchases/purchase-documents/purchase-documents/purchase-document-details/purchase-document-details.component';
import { PurchaseDocumentFinanceComponent } from './purchases/purchase-documents/purchase-documents/purchase-document-finance/purchase-document-finance.component';
import { PurchaseDocumentFormComponent } from './purchases/purchase-documents/purchase-documents/purchase-document-form/purchase-document-form.component';
import { PurchaseDocumentOtherComponent } from './purchases/purchase-documents/purchase-documents/purchase-document-other/purchase-document-other.component';
import { PurchaseDocumentThirdComponent } from './purchases/purchase-documents/purchase-documents/purchase-document-third/purchase-document-third.component';
import { PurchaseOrdersComponent } from './purchases/purchase-orders/purchase-orders/purchase-orders.component';
import { PurchaseOrderDetailsComponent } from './purchases/purchase-orders/purchase-order-details/purchase-order-details.component';
import { PurchaseOrderGridComponent } from './purchases/purchase-orders/purchase-order-grid/purchase-order-grid.component';
import { PurchaseOrderStatusSelectModalComponent } from './purchases/purchase-orders/purchase-order-status/purchase-order-status-select-modal/purchase-order-status-select-modal.component';
import { PurchaseInvoiceDetailsComponent } from './purchases/purchase-invoices/purchase-invoice-details/purchase-invoice-details.component';
import { PurchaseInvoiceGridComponent } from './purchases/purchase-invoices/purchase-invoice-grid/purchase-invoice-grid.component';
import { PurchaseInvoiceStatusSelectModalComponent } from './purchases/purchase-invoices/purchase-invoice-status/purchase-invoice-status-select-modal/purchase-invoice-status-select-modal.component';
import { PurchaseInvoicesComponent } from './purchases/purchase-invoices/purchase-invoices/purchase-invoices.component';
import { PurchaseCreditNoteDetailsComponent } from './purchases/purchase-credit-notes/purchase-credit-note-details/purchase-credit-note-details.component';
import { PurchaseCreditNoteGridComponent } from './purchases/purchase-credit-notes/purchase-credit-note-grid/purchase-credit-note-grid.component';
import { PurchaseCreditNotesComponent } from './purchases/purchase-credit-notes/purchase-credit-notes/purchase-credit-notes.component';
import { PurchaseCreditNoteStatusSelectModalComponent } from './purchases/purchase-credit-notes/purchase-credit-note-status/purchase-credit-note-status-select-modal/purchase-credit-note-status-select-modal.component';
import { MailModalComponent } from './mails/mail-modal/mail-modal.component';
import { CreditsafeDetailComponent } from './connectors/creditsafe/creditsafe-detail/creditsafe-detail.component';
import { CreditsafeCurrentComponent } from './connectors/creditsafe/creditsafe-current/creditsafe-current.component';
import { CreditsafePreviousComponent } from './connectors/creditsafe/creditsafe-previous/creditsafe-previous.component';
import { CreditsafePaymentDataComponent } from './connectors/creditsafe/creditsafe-payment-data/creditsafe-payment-data.component';
import { CreditsafeCompanySignificantEventsComponent } from './connectors/creditsafe/creditsafe-company-significant-events/creditsafe-company-significant-events.component';
import { CreditsafeCommentariesComponent } from './connectors/creditsafe/creditsafe-commentaries/creditsafe-commentaries.component';
import { CreditsafeFinancialStatementsComponent } from './connectors/creditsafe/creditsafe-financial-statements/creditsafe-financial-statements.component';
import { CreditsafeSocialBalanceSheetsComponent } from './connectors/creditsafe/creditsafe-social-balance-sheets/creditsafe-social-balance-sheets.component';
import { CreditsafeRatingHistoryComponent } from './connectors/creditsafe/creditsafe-rating-history/creditsafe-rating-history.component';
import { CreditsafeCreditLimitHistoryComponent } from './connectors/creditsafe/creditsafe-credit-limit-history/creditsafe-credit-limit-history.component';
import { CreditsafeModalComponent } from './connectors/creditsafe/creditsafe-modal/creditsafe-modal.component';
import { StockMoveDocumentContentComponent } from './stocks/stock-move-documents/stock-move-documents/stock-move-document-content/stock-move-document-content.component';
import { StockMoveDocumentDetailsComponent } from './stocks/stock-move-documents/stock-move-documents/stock-move-document-details/stock-move-document-details.component';
import { StockMoveDocumentFormComponent } from './stocks/stock-move-documents/stock-move-documents/stock-move-document-form/stock-move-document-form.component';
import { StockMoveDocumentOtherComponent } from './stocks/stock-move-documents/stock-move-documents/stock-move-document-other/stock-move-document-other.component';
import { StockMoveDocumentThirdComponent } from './stocks/stock-move-documents/stock-move-documents/stock-move-document-third/stock-move-document-third.component';
import { DocumentCreateModalComponent } from './base/documents/document-create-modal/document-create-modal.component';
import { StockMoveOutgoingGridComponent } from './stocks/stock-move-outgoings/stock-move-outgoing-grid/stock-move-outgoing-grid.component';
import { StockMoveOutgoingStatusSelectModalComponent } from './stocks/stock-move-outgoings/stock-move-outgoing-status/stock-move-outgoing-status-select-modal/stock-move-outgoing-status-select-modal.component';
import { StockMoveCustomerOutgoingsComponent } from './stocks/stock-move-customer-outgoings/stock-move-customer-outgoings.component';
import { StockMoveCustomerOutgoingDetailsComponent } from './stocks/stock-move-customer-outgoings/stock-move-customer-outgoing-details/stock-move-customer-outgoing-details.component';
import { DocumentCopyModalComponent } from './base/documents/document-copy-modal/document-copy-modal.component';
import { StockMoveIncomingGridComponent } from './stocks/stock-move-incomings/stock-move-incoming-grid/stock-move-incoming-grid.component';
import { StockMoveIncomingStatusSelectModalComponent } from './stocks/stock-move-incomings/stock-move-incoming-status/stock-move-incoming-status-select-modal/stock-move-incoming-status-select-modal.component';
import { StockMoveCustomerIncomingsComponent } from './stocks/stock-move-customer-incomings/stock-move-customer-incomings.component';
import { StockMoveCustomerIncomingDetailsComponent } from './stocks/stock-move-customer-incomings/stock-move-customer-incoming-details/stock-move-customer-incoming-details.component';
import { StockMoveSupplierOutgoingsComponent } from './stocks/stock-move-supplier-outgoings/stock-move-supplier-outgoings.component';
import { StockMoveSupplierOutgoingDetailsComponent } from './stocks/stock-move-supplier-outgoings/stock-move-supplier-outgoing-details/stock-move-supplier-outgoing-details.component';
import { StockMoveSupplierIncomingsComponent } from './stocks/stock-move-supplier-incomings/stock-move-supplier-incomings.component';
import { StockMoveSupplierIncomingDetailsComponent } from './stocks/stock-move-supplier-incomings/stock-move-supplier-incoming-details/stock-move-supplier-incoming-details.component';
import { DocumentDetailsComponent } from './base/documents/documents/document-details/document-details.component';
import { StockMoveInternalGridComponent } from './stocks/stock-move-internals/stock-move-internal-grid/stock-move-internal-grid.component';
import { StockMoveInternalStatusSelectModalComponent } from './stocks/stock-move-internals/stock-move-internal-status/stock-move-internal-status-select-modal/stock-move-internal-status-select-modal.component';
import { StockMoveInternalDetailsComponent } from './stocks/stock-move-internals/stock-move-internals/stock-move-internal-details/stock-move-internal-details.component';
import { StockMoveInternalsComponent } from './stocks/stock-move-internals/stock-move-internals/stock-move-internals.component';
import { StockMoveIncomingLinesGridComponent } from './stocks/stock-move-incomings/stock-move-incoming-lines/stock-move-incoming-lines-grid/stock-move-incoming-lines-grid.component';
import { StockMoveOutgoingLinesGridComponent } from './stocks/stock-move-outgoings/stock-move-outgoing-lines/stock-move-outgoing-lines-grid/stock-move-outgoing-lines-grid.component';
import { ProductStockMoveLocationGridComponent } from './products/products/product-stock-move-location-grid/product-stock-move-location-grid.component';
import { CreditsafeFinancialStatementsPercentColComponent } from './connectors/creditsafe/creditsafe-financial-statements-percent-col/creditsafe-financial-statements-percent-col.component';
import { DocumentRelationsComponent } from './base/documents/documents/document-relations/document-relations.component';
import { ActionGroupGridComponent } from './core/auth/action-groups/action-group-grid/action-group-grid.component';
import { ActionGroupSelectModalComponent } from './core/auth/action-groups/action-group-select-modal/action-group-select-modal.component';
import { TaskTaskStatusModalComponent } from './time-management/tasks/task-task-statuses/task-task-status-modal/task-task-status-modal.component';
import { MailTemplateGridComponent } from './mails/mail-templates/mail-template-grid/mail-template-grid.component';
import { SaleCreditNoteStatusSelectModalComponent } from './sales/sale-credit-notes/sale-credit-note-status-select-modal/sale-credit-note-status-select-modal.component';
import { ThirdFinanceFormComponent } from './thirds/thirds/third-finance-form/third-finance-form.component';
import { ProductFinanceFormComponent } from './products/products/product-finance-form/product-finance-form.component';
import { ThirdStatisticsComponent } from './thirds/thirds/third-statistics/third-statistics.component';
import { ProductStatisticsComponent } from './products/products/product-statistics/product-statistics.component';
import { ReadonlyProductGridComponent } from './products/products/readonly-product-grid/readonly-product-grid.component';
import { ErrorService } from './core/error.service';
import { ProductSummaryComponent } from './products/products/product-summary/product-summary.component';
import { DocumentRelationsV2Component } from './base/documents/documents/document-relations-v2/document-relations-v2.component';
import { DocumentRelationsBlockComponent } from './base/documents/documents/document-relations-block/document-relations-block.component';
import { ThirdDocumentRelationsComponent } from './thirds/thirds/third-document-relations/third-document-relations.component';
import { ThirdContactFormComponent } from './thirds/thirds/third-contacts/third-contact-form/third-contact-form.component';
import { ThirdTotalComponent } from './thirds/thirds/third-total/third-total.component';
import { SaleContractFormComponent } from './sales/sale-contracts/sale-contract-form/sale-contract-form.component';
import { SaleContractContentComponent } from './sales/sale-contracts/sale-contract-content/sale-contract-content.component';
import { SaleContractOtherComponent } from './sales/sale-contracts/sale-contract-other/sale-contract-other.component';
import { ThirdDocumentDataListComponent } from './base/documents/document-datas/third-document-data-list/third-document-data-list.component';
import { ThirdDocumentDataModalComponent } from './base/documents/document-datas/third-document-data-modal/third-document-data-modal.component';
import { ThirdDocumentDataBlockComponent } from './base/documents/document-datas/third-document-data-block/third-document-data-block.component';
import { PaymentDetailsComponent } from './base/payments/payment-details/payment-details.component';
import { DocumentTotalsComponent } from './base/documents/document-totals/document-totals.component';
import { SupplierCatalogGridComponent } from './products/supplier-catalogs/supplier-catalog-grid/supplier-catalog-grid.component';
import { ProductSupplierCatalogFormComponent } from './products/products/product-purchase-form/product-supplier-catalogs/product-supplier-catalog-form/product-supplier-catalog-form.component';
import { CustomerCatalogGridComponent } from './products/customer-catalogs/customer-catalog-grid/customer-catalog-grid.component';
import { CustomerCatalogGeneralFormComponent } from './products/customer-catalogs/customer-catalog-general-form/customer-catalog-general-form.component';
import { CustomerCatalogFormComponent } from './products/customer-catalogs/customer-catalog-form/customer-catalog-form.component';
import { TaskPerformableBlockComponent } from './time-management/tasks/tasks/task-performable-block/task-performable-block.component';
import { TaskBillingsComponent } from './time-management/tasks/tasks/task-billings/task-billings.component';
import { TaskThirdComponent } from './time-management/tasks/tasks/task-third/task-third.component';
import { TaskParticipantComponent } from './time-management/tasks/tasks/task-participant/task-participant.component';
import { TaskParticipantModalComponent } from './time-management/tasks/tasks/task-participant/task-participant-modal/task-participant-modal.component';
import { ModificationService } from './core/services/modification.service';
import { PrivateConfigurationsService } from './base/private-configurations/private-configurations.service';
import { UserStoragesService } from './core/auth/user-storages/user-storage.service';
import { SaleQuoteSelectModalComponent } from './sales/sale-quotes/sale-quote-select-modal/sale-quote-select-modal.component';
import { SaleQuoteStatusSelectModalComponent } from './sales/sale-quotes/sale-quote-status-select-modal/sale-quote-status-select-modal.component';
import { SaleInvoiceContractFormComponent } from './sales/sale-invoices/sale-invoice-contract-form/sale-invoice-contract-form.component';
import { AccountMoveGridComponent } from './accountings/account-moves/account-move-grid/account-move-grid.component';
import { AccountMovesComponent } from './accountings/account-moves/account-moves.component';
import { AccountMoveDetailsComponent } from './accountings/account-moves/account-move-details/account-move-details.component';
import { AccountMoveFormComponent } from './accountings/account-moves/account-move-form/account-move-form.component';
import { AccountMoveTotalComponent } from './accountings/account-moves/account-move-total/account-move-total.component';
import { AccountMoveLineGridComponent } from './accountings/account-move-lines/account-move-line-grid/account-move-line-grid.component';
import { AccountMoveLinesComponent } from './accountings/account-move-lines/account-move-lines.component';
import { AccountMoveLineGridItemsComponent } from './accountings/account-move-lines/account-move-line-grid/account-move-line-grid-items/account-move-line-grid-items.component';
import { AccountModalComponent } from './accountings/accounts/account-modal/account-modal.component';
import { SequenceGridComponent } from './core/sequences/sequence-grid/sequence-grid.component';
import { SequenceComponent } from './core/sequences/sequence/sequence.component';
import { SequenceConfigurationsComponent } from './core/sequences/sequence-configurations/sequence-configurations.component';
import { SequenceConfigurationGridComponent } from './core/sequences/sequence-configuration-grid/sequence-configuration-grid.component';
import { WorkTimeTypeSelectModalComponent } from './time-management/work-times/work-time-type-select-modal/work-time-type-select-modal.component';
import { BankReconciliationGridComponent } from './accountings/bank-reconciliations/bank-reconciliation-grid/bank-reconciliation-grid.component';
import { BankReconciliationsComponent } from './accountings/bank-reconciliations/bank-reconciliations.component';
import { BankReconciliationDetailsComponent } from './accountings/bank-reconciliations/bank-reconciliation-details/bank-reconciliation-details.component';
import { BankReconciliationFormComponent } from './accountings/bank-reconciliations/bank-reconciliation-form/bank-reconciliation-form.component';
import { BankReconciliationLineGridItemsComponent } from './accountings/bank-reconciliation-lines/bank-reconciliation-line-grid/bank-reconciliation-line-grid-items/bank-reconciliation-line-grid-items.component';

registerLocaleData(localeFr);

@NgModule({
  imports: [
    SyslinkComponentsModule.forRoot({
      getToken: AuthService.getToken,
      setModificationGuard: ModificationService.setModificationGuard,
      getPrecision: PrivateConfigurationsService.getPrecision,
      updateStateStoring:UserStoragesService.updateStateStoring,
      useStateStoring: environment.useStateStoring,
      defaultInputStylingMode: 'underlined',
      componentContainers: [
        {
          key: 'ConfigurationComponentContainer_TimeManagement.General',
          componentEntries: [
            {
              type: GeneralTabContentComponent,
              init: (instance: GeneralTabContentComponent, data: any) => {
                instance.subModule = data;
              },
            },
          ],
        },
        {
          key: 'ConfigurationComponentContainer_Base.General',
          componentEntries: [
            {
              type: ReportConfigurationsComponent,
              init: (instance: ReportConfigurationsComponent, data: any) => {
                instance.subModule = data;
              },
            },
          ],
        },
      ],
      baseUrl: environment.base_url,
    }),
    JwtModule.forRoot({ config: { tokenGetter: AuthService.getToken } }),
    NgxUiLoaderModule.forRoot({
      'bgsColor': '#ffcb08',
      'bgsOpacity': 0.8,
      'bgsPosition': 'bottom-right',
      'bgsSize': 70,
      'bgsType': 'three-strings',
      'blur': 6,
      'delay': 0,
      'fastFadeOut': true,
      'fgsColor': '#ffcb08',
      'fgsPosition': 'center-center',
      'fgsSize': 80,
      'fgsType': 'three-strings',
      'gap': 30,
      'logoPosition': 'center-center',
      'logoSize': 100,
      'logoUrl': 'assets/logo-picto.png',
      'masterLoaderId': 'master',
      'overlayBorderRadius': '0',
      'overlayColor': 'rgba(40, 40, 40, 0.8)',
      'pbColor': '#ffcb08',
      'pbDirection': 'ltr',
      'pbThickness': 4,
      'hasProgressBar': true,
      'text': '',
      'textColor': '#ffcb08',
      'textPosition': 'center-center',
      'maxTime': -1,
      'minTime': 300
    }),
    TranslateModule.forRoot(TranslationsService.getTranslateModuleConfig()),
    BrowserModule,
    CommonModule,
    HttpClientModule,
    AppRoutingModule,
  ],
  declarations: [
    AppComponent,
    LoginComponent,
    AdminComponent,
    DashboardComponent,
    ThirdsComponent,
    ThirdDetailsComponent,
    ThirdFormComponent,
    BasicComponent,
    CustomersComponent,
    SuppliersComponent,
    ThirdPrivateFormComponent,
    AddressesComponent,
    AddressFormComponent,
    ThirdProfessionalFormComponent,
    CustomerFormComponent,
    SupplierFormComponent,
    ThirdReferenceFormComponent,
    ModulesComponent,
    ModuleDetailsComponent,
    ThirdRelationsComponent,
    ThirdRelationFormComponent,
    ProductsComponent,
    ProductDetailsComponent,
    ProductFormComponent,
    ProductPurchaseFormComponent,
    ProductStockFormComponent,
    ProductSaleFormComponent,
    CustomerCatalogGeneralFormComponent,
    SaleQuotesComponent,
    SaleDocumentFormComponent,
    SaleDocumentContentComponent,
    DocumentStatusesComponent,
    SaleDocumentOtherComponent,
    DocumentLinesComponent,
    ProductPickerComponent,
    SupplierCatalogGridComponent,
    ProductSupplierCatalogsComponent,
    CustomerCatalogGridComponent,
    ProductCustomerCatalogsComponent,
    UserFormComponent,
    TaxesComponent,
    AccountsComponent,
    PaymentComponent,
    SaleInvoicesComponent,
    SaleCreditNotesComponent,
    SaleQuoteDetailsComponent,
    SaleContractDetailsComponent,
    SaleContractsComponent,
    SaleDocumentDetailsComponent,
    SaleInvoiceDetailsComponent,
    PaymentsComponent,
    SaleCreditNoteDetailsComponent,
    UsersComponent,
    UserDetailsComponent,
    UserRolesComponent,
    ReportpreviewComponent,
    UserGroupDetailsComponent,
    UserGroupsComponent,
    UserGroupFormComponent,
    UserPermissionsComponent,
    UserPermissionDetailsComponent,
    UserPermissionFormComponent,
    UserRoleFormComponent,
    UserRoleDetailsComponent,
    UserRoleGridComponent,
    UserGroupGridComponent,
    UserPermissionGridComponent,
    UserGridComponent,
    SaleContractScheduleComponent,
    TasksComponent,
    TaskDetailComponent,
    TaskFormComponent,
    TaskOtherComponent,
    AboutComponent,
    VersionDetailsComponent,
    SaleContractRenewalComponent,
    MailTemplatesComponent,
    ThirdContactsComponent,
    ThirdViesFormComponent,
    TasksComponent,
    TaskModalComponent,
    TaskGridComponent,
    TaskKanbanComponent,
    CustomerGridComponent,
    SaleInvoiceGridComponent,
    WorkTimesComponent,
    WorkTimeGridComponent,
    WorkTimeModalComponent,
    WorkTimeSchedulerComponent,
    WorkTimeFiltersPopoverComponent,
    AppointmentsComponent,
    AppointmentGridComponent,
    AppointmentModalComponent,
    AppointmentSchedulerComponent,
    AppointmentFiltersPopoverComponent,
    ThirdTaskTabComponent,
    TaskMultipleEditModalComponent,
    UserSelectModalComponent,
    UserGroupSelectModalComponent,
    WorkTimesPickerComponent,
    TimetableComponent,
    TimetableLineGridComponent,
    GeneralTabContentComponent,
    SaleInvoiceSelectModalComponent,
    UserTagboxModalComponent,
    UserGroupTagboxModalComponent,
    TaskSelectModalComponent,
    ReportConfigurationsComponent,
    SaleInvoiceStatusSelectModalComponent,
    ProductGridComponent,
    SaleContractGridComponent,
    SaleCreditNoteGridComponent,
    SaleQuoteGridComponent,
    ProductStockStockMoveGridComponent,
    ThirdGridComponent,
    BankAccountGridComponent,
    SupplierGridComponent,
    BasicGridComponent,
    AccountTreelistComponent,
    DynamicPriceInvoicingComponent,
    TimetableLineModalComponent,
    AssignementPriceCoefGridComponent,
    AssignementPriceCoefsComponent,
    SaleOrdersComponent,
    SaleOrderDetailsComponent,
    SaleOrderGridComponent,
    PaymentDetailsComponent,
    SaleOrderStatusSelectModalComponent,
    PurchaseDocumentFinanceGridComponent,
    PurchaseDocumentContentComponent,
    PurchaseDocumentDetailsComponent,
    PurchaseDocumentFinanceComponent,
    PurchaseDocumentFormComponent,
    PurchaseDocumentOtherComponent,
    PurchaseDocumentThirdComponent,
    PurchaseOrdersComponent,
    PurchaseOrderDetailsComponent,
    PurchaseOrderGridComponent,
    PurchaseOrderStatusSelectModalComponent,
    PurchaseOrdersComponent,
    PurchaseOrdersComponent,
    PurchaseInvoiceDetailsComponent,
    PurchaseInvoiceGridComponent,
    PurchaseInvoiceStatusSelectModalComponent,
    PurchaseInvoicesComponent,
    PurchaseCreditNoteDetailsComponent,
    PurchaseCreditNoteGridComponent,
    PurchaseCreditNoteStatusSelectModalComponent,
    PurchaseCreditNotesComponent,
    MailModalComponent,
    CreditsafeDetailComponent,
    CreditsafeCurrentComponent,
    CreditsafePreviousComponent,
    CreditsafePaymentDataComponent,
    CreditsafeCompanySignificantEventsComponent,
    CreditsafeCommentariesComponent,
    CreditsafeFinancialStatementsComponent,
    CreditsafeSocialBalanceSheetsComponent,
    CreditsafeRatingHistoryComponent,
    CreditsafeCreditLimitHistoryComponent,
    CreditsafeModalComponent,
    StockMoveDocumentContentComponent,
    StockMoveDocumentDetailsComponent,
    StockMoveDocumentFormComponent,
    StockMoveDocumentOtherComponent,
    StockMoveDocumentThirdComponent,
    StockMoveOutgoingGridComponent,
    StockMoveOutgoingStatusSelectModalComponent,
    DocumentCreateModalComponent,
    DocumentCopyModalComponent,
    StockMoveIncomingGridComponent,
    StockMoveIncomingStatusSelectModalComponent,
    StockMoveCustomerOutgoingsComponent,
    StockMoveCustomerOutgoingDetailsComponent,
    StockMoveCustomerIncomingsComponent,
    StockMoveCustomerIncomingDetailsComponent,
    StockMoveSupplierOutgoingsComponent,
    StockMoveSupplierOutgoingDetailsComponent,
    StockMoveSupplierIncomingsComponent,
    StockMoveSupplierIncomingDetailsComponent,
    DocumentDetailsComponent,
    StockMoveInternalGridComponent,
    StockMoveInternalStatusSelectModalComponent,
    StockMoveInternalDetailsComponent,
    StockMoveInternalsComponent,
    StockMoveIncomingLinesGridComponent,
    StockMoveOutgoingLinesGridComponent,
    ProductStockMoveLocationGridComponent,
    CreditsafeFinancialStatementsPercentColComponent,
    DocumentRelationsComponent,
    ActionGroupGridComponent,
    ActionGroupSelectModalComponent,
    TaskTaskStatusModalComponent,
    MailTemplateGridComponent,
    SaleCreditNoteStatusSelectModalComponent,
    SaleContractFormComponent,
    SaleContractContentComponent,
    SaleContractOtherComponent,
    ThirdFinanceFormComponent,
    ProductFinanceFormComponent,
    ThirdStatisticsComponent,
    ProductStatisticsComponent,
    ReadonlyProductGridComponent,
    ProductSummaryComponent,
    DocumentRelationsV2Component,
    DocumentRelationsBlockComponent,
    ThirdDocumentRelationsComponent,
    ThirdContactFormComponent,
    ThirdTotalComponent,
    ThirdDocumentDataListComponent,
    ThirdDocumentDataModalComponent,
    ThirdDocumentDataBlockComponent,
    DocumentTotalsComponent,
    ProductSupplierCatalogFormComponent,
    CustomerCatalogFormComponent,
    TaskPerformableBlockComponent,
    TaskBillingsComponent,
    TaskThirdComponent,
    TaskParticipantComponent,
    TaskParticipantModalComponent,
    SaleQuoteSelectModalComponent,
    SaleQuoteStatusSelectModalComponent,
    SaleInvoiceContractFormComponent,
    AccountMoveGridComponent,
    AccountMovesComponent,
    AccountMoveDetailsComponent,
    AccountMoveFormComponent,
    AccountMoveTotalComponent,
    AccountMoveLineGridComponent,
    AccountMoveLinesComponent,
    AccountMoveLineGridItemsComponent,
    AccountModalComponent,
    SequenceGridComponent,
    SequenceComponent,
    SequenceConfigurationsComponent,
    SequenceConfigurationGridComponent,
    WorkTimeTypeSelectModalComponent,
    BankReconciliationGridComponent,
    BankReconciliationsComponent,
    BankReconciliationDetailsComponent,
    BankReconciliationFormComponent,
    BankReconciliationLineGridItemsComponent
  ],
  providers: [
    AuthService,
    ErrorService,
    [
      { provide: DEFAULT_HTTP_REQUEST_TIMEOUT, useValue: 20000 },
      { provide: DEFAULT_NOTIFICATIONS_DISPLAYTIME, useValue: 8000 },
      { provide: DEFAULT_DATE_FORMAT, useValue: 'dd/MM/yyyy HH:mm:ss' },
      { provide: DEFAULT_CURRENCY_CODE, useValue: 'EUR' },
      { provide: LOCALE_ID, useValue: 'fr-FR' },
      { provide: DEFAULT_PAGE_SIZE, useValue: 20 },
      { provide: DEFAULT_PAGE_SIZES, useValue: [20] },
      {
        provide: DATE_PIPE_DEFAULT_OPTIONS,
        useValue: { dateFormat: 'dd/MM/yyyy HH:mm:ss' },
      },
    ],
    {
      provide: APP_INITIALIZER,
      useFactory: appFactory,
      deps: [AuthService],
      multi: true,
    },
    { provide: HTTP_INTERCEPTORS, useClass: AppHttpInterceptor, multi: true },
    CurrencyPipe,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}

