import { Component } from '@angular/core';
import { AppInjectorService } from 'projects/libraries/syslink-components/src/lib/services/app-injector.service';
import { StockMoveDocumentDetailsComponent } from '../../stock-move-documents/stock-move-documents/stock-move-document-details/stock-move-document-details.component';
import { StockMoveOutgoing } from '../../stock-move-outgoings/stock-move-outgoings/stock-move-outgoing.model';
import { StockMoveOutgoingsService } from '../../stock-move-outgoings/stock-move-outgoings/stock-move-outgoings.service';
import { StockMoveOutgoingLine } from '../../stock-move-outgoings/stock-move-outgoing-lines/stock-move-outgoing-line.model';
import { StockMoveOutgoingLinesService } from '../../stock-move-outgoings/stock-move-outgoing-lines/stock-move-outgoing-lines.service';
import { StockMoveOutgoingHeaderStatus } from '../../stock-move-outgoings/stock-move-outgoing-header-statuses/stock-move-outgoing-header-status.model';
import { StockMoveOutgoingHeaderStatusesService } from '../../stock-move-outgoings/stock-move-outgoing-header-statuses/stock-move-outgoing-header-statuses.service';
import { StockMoveOutgoingHeaderHeaderStatus } from '../../stock-move-outgoings/stock-move-outgoing-header-header-statuses/stock-move-outgoing-header-header-status.model';
import { StockMoveOutgoingHeaderHeaderStatusesService } from '../../stock-move-outgoings/stock-move-outgoing-header-header-statuses/stock-move-outgoing-header-header-statuses.service';

@Component({
  selector: 'app-stock-move-supplier-outgoing-details',
  templateUrl: '../../stock-move-documents/stock-move-documents/stock-move-document-details/stock-move-document-details.component.html',
  styleUrls: ['./stock-move-supplier-outgoing-details.component.scss']
})
export class StockMoveSupplierOutgoingDetailsComponent extends StockMoveDocumentDetailsComponent<
  StockMoveOutgoing,
  StockMoveOutgoingsService,
  StockMoveOutgoingLine,
  StockMoveOutgoingLinesService,
  StockMoveOutgoingHeaderStatus,
  StockMoveOutgoingHeaderStatusesService,
  StockMoveOutgoingHeaderHeaderStatus,
  StockMoveOutgoingHeaderHeaderStatusesService
>{

  protected override loadDependencies() {
    this.StockMoveDocumentService = AppInjectorService.injector.get(StockMoveOutgoingsService);
    this.StockMoveDocumentLinesService = AppInjectorService.injector.get(StockMoveOutgoingLinesService);
    this.StockMoveDocumentStatusesService = AppInjectorService.injector.get(StockMoveOutgoingHeaderStatusesService);
    this.StockMoveDocumentHeaderStatusesService = AppInjectorService.injector.get(StockMoveOutgoingHeaderHeaderStatusesService);

    this.documentType = "StockMoveSupplierOutgoing";
    this.type = "supplier";
    this.subModuleCode = "Stocks.SupplierOutgoing";
    this.defaultStatusCode = "Stocks.Draft";
    this.documentLinesColumns = [...this.StockMoveDocumentService.getDefaultDocumentLineColumns(this.subModuleCode,this.documentType)];
  }

  public override canEditDocument(): boolean {
    return !this.hasActiveStatus("Stocks.Achieved");
  }

}
