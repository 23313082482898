<syslink-grid
    #gridComponent
    id="bank-reconciliation-grid"
    [storageKey]="storageKey"
    [contextMenuItems]="contextMenuItems"
    [canView]="canView"
    [canSearch]="canSearch"
    [canRefresh]="canRefresh"
    [canAdd]="false"
    [canSort]="false"
    [canUpdate]="canUpdate"
    [canDelete]="false"
    [canFilter]="canFilter"
    [canExport]="canExport"
    [canDblClck]="false"
    [(items)]="elements"
    [columns]="columns"
    [storageKey]="storageKey"
    [(selectedItems)]="selectedItems"
    [filter]="filter"
    [(selectedKeys)]="selectedKeys"    
    (onEditingStart)="onChangeEditCell($event)"
    [draggable]="false"
    ></syslink-grid>