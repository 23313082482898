import { Injectable } from '@angular/core';
import { ODataService } from '../../../core/services/oData.service';
import { SaleDocumentLine } from '../sale-document-lines/sale-document-line.model';
import { SaleDocumentLineDiscountOperation } from './sale-document-line-discount-operation.model';

@Injectable({
  providedIn: 'root'
})
export class SaleDocumentLineDiscountOperationsService extends ODataService<SaleDocumentLineDiscountOperation>{
  public url: string = 'SaleDocumentLineDiscountOperation';
 
  constructor() { 
    super();
  }
  
  // Format
  // ------
  public override format(params?: Partial<SaleDocumentLineDiscountOperation>): any {
    var result: any = {
      ...params,
      LineId: params?.LineId?.Id,
    };

    delete result["RealValue"];
    delete result["UpdatedAt"];
    delete result["CreatedAt"];

    return result;
  }
}
