import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { SyslinkToolbarActionButton, SyslinkToolbarFileButton } from '../../../../../../libraries/syslink-components/src/lib/toolbar/toolbar.component';
import { ConfirmModalComponent, NotificationsService, PageComponent, TabData, TabsComponent } from '../../../../../../libraries/syslink-components/src/public-api';
import { CustomersService } from '../../customers/customers/customers.service';
import { Customer } from '../../customers/customers/customer.model';
import { Third } from '../third.model';
import { ThirdsService } from '../thirds.service';
import { SuppliersService } from '../../suppliers/suppliers.service';
import { Supplier } from '../../suppliers/supplier.model';
import { ThirdRole, ThirdRoleIds } from '../third-roles/third-role.model';
import { ThirdRelation } from '../third-relations/third-relation.model';
import { ThirdRelationsService } from '../third-relations/third-relations.service';
import { ThirdAddressService } from '../third-addresses/third-addresses/third-addresses.service';
import { ThirdAddress } from '../third-addresses/third-addresses/third-address.model';
import { ViesService } from '../../../connectors/vies/vies.service';
import { LocalityService } from '../../../base/addresses/localities/locality.service';
import { PostalCodeService } from '../../../base/addresses/postal-codes/postal-code.service';
import { AddressTypeService } from '../../../base/addresses/address-types/address-type.service';
import { Entity } from '../../../core/models/entity';
import { ODataService } from '../../../core/services/oData.service';
import { CountriesService } from '../../../base/addresses/countries/countries.service';
import { DocumentDatasService } from '../../../base/documents/document-datas/document-datas.service';
import { LoadOptions } from 'devextreme/data';
import { ThirdModalComponent } from 'projects/libraries/syslink-components/src/lib/modal/third-modal/third-modal.component';
import { DocumentData } from '../../../base/documents/document-datas/document-data.model';
import { jsonToOdataFormat } from 'projects/libraries/syslink-components/src/lib/helpers/tools';
import { ThirdContactInformationsService } from '../third-contacts/third-contact-informations/third-contact-informations.service';
import { ThirdContactInformation } from '../third-contacts/third-contact-informations/third-contact-information.model';
import { CreditsafeModalComponent } from '../../../connectors/creditsafe/creditsafe-modal/creditsafe-modal.component';
import { CreditsafeData } from '../../../connectors/creditsafe/creditsafe.model';
import { AppInjectorService } from 'projects/libraries/syslink-components/src/lib/services/app-injector.service';

@Component({
  templateUrl: './third-details.component.html',
  styleUrls: ['./third-details.component.scss']
})
export class ThirdDetailsComponent extends PageComponent implements OnInit, AfterViewInit {

  public tabsData: TabData[] = [
    { id: 0, key: "Third.Private", label: "Third.Private", loaded: false },
    { id: 1, key: "Professional", label: "Professional", loaded: false },
    { id: 2, key: "Customer", label: "Customer", loaded: false },
    { id: 3, key: "Supplier", label: "Supplier", loaded: false },
    { id: 4, key: "Finances", label: "Finances", loaded: false },
    { id: 5, key: "User", label: "User", loaded: false },
    { id: 6, key: "Statistics", label: "Statistics", loaded: false }
  ]
  public onChangeSelectedId(e: number) {
    if (this.tabsData[e].loaded == false) {
      this.tabsData[e].loaded = true;
    }
  }

  ThirdRoleIds = ThirdRoleIds;

  public tabIndex?: number;

  // Third
  public element: Third = new Third();
  public formattedTitle: string = '';

  public isAutocompleteLoading: boolean = false;

  @ViewChild('deleteConfirm') deleteConfirm?: ConfirmModalComponent;
  @ViewChild('updateThirdAutocomplete') public updateThirdAutocomplete?: ConfirmModalComponent = new ConfirmModalComponent();
  @ViewChild('infoTabs') infoTabs: TabsComponent = new TabsComponent();
  @ViewChild('thirdModaltransfert') thirdModaltransfert?: ThirdModalComponent;
  @ViewChild('creditsafeModal') creditsafeModal?: CreditsafeModalComponent;
  @ViewChild('confirmSaveModal') confirmSaveModal: ConfirmModalComponent = new ConfirmModalComponent();

  public thirdModaltransfertFilter: string | string[] = ["Roles/any(r:r/Code eq 'customer')"];
  public thirdModaltransfertFields: any[] = [];

  public currentMainAddress: ThirdAddress = new ThirdAddress();
  public newMainAddress: ThirdAddress | undefined;

  public currentOtherAddresses: ThirdAddress[] = [];
  public newOtherAddresses: ThirdAddress[] = [];

  public currentOtherAddressChoices: boolean[] = [];
  public newOtherAddressChoices: boolean[] = [];

  public currentCommunicationChoices: boolean[] = [];
  public newCommunicationChoices: boolean[] = [];

  public currentRelationChoices: boolean[] = [];
  public newRelationChoices: boolean[] = [];

  public detailChoice: boolean = false;
  public mainAddressChoice: boolean = false;

  public onModificationDetailCheck($event: boolean) {
    this.detailChoice = $event;
  }

  public onModificationMainAddressCheck($event: boolean) {
    this.mainAddressChoice = $event;
  }

  // VIES
  // ----
  public thirdAutocompleted?: Third;
  public usedCurrentDenomination?: boolean;
  public usedCurrentLegalForm?: boolean;
  public usedCurrentCountry?: boolean;
  public viesContactInformations: ThirdContactInformation[] = [];

  // -----------------------

  // CreditSafe
  // ----
  public creditSafeElement?: CreditsafeData;
  // -----------------------

  constructor(
    private customersService: CustomersService,
    private suppliersService: SuppliersService,
    public postalCodeService: PostalCodeService,
    public addressTypeService: AddressTypeService,
    public localityService: LocalityService,
    public thirdAddressService: ThirdAddressService,
    private ngxUiLoaderService: NgxUiLoaderService,
    private thirdRelationsService: ThirdRelationsService,
    override activatedRoute: ActivatedRoute,
    public thirdsService: ThirdsService,
    private companyDataService: ViesService,
    public addressTypesService: AddressTypeService,
    public countriesService: CountriesService,
    private documentDatasService: DocumentDatasService,
    private thirdContactInformationsService: ThirdContactInformationsService
  ) {
    super();
  }
  override async ngAfterViewInit(): Promise<void> {
    super.ngAfterViewInit();
    await this.refreshElement();
  }

  // Lifecycle Events
  // ----------------
  override ngOnInit(): void {
    super.ngOnInit();

    this.activatedRoute.data.subscribe(async ({ element }) => {
      this.ngxUiLoaderService.start();
      this.element = element;
      this.ngxUiLoaderService.stop();
    });
  }

  // KeyDown
  // -------
  public override onKeyDownSave(){this.save()}
  //------------------------------------------------------------------


  private async refreshElement() {
    this.formattedTitle = this.getFormattedTitle();
    this.updateBreadCrumb(this.formattedTitle);
    this.initToolbarActions();
  }

  public initToolbarActions() {
    this.toolbarActions = [];
    this.toolbarActions.push(new SyslinkToolbarActionButton({ icon: 'save', text: 'Save', onClick: () => this.save(), location: 'before', inMenu: 'never', hotkey: 'control.s', visible: this.element.Id ? this.authService.hasPermission(this.newBasePermissionKey + '.update') : this.authService.hasPermission(this.newBasePermissionKey + '.add') }));
    if (this.element.Id) {
      this.toolbarActions.push(new SyslinkToolbarActionButton({ code: 'add', icon: 'plus', text: 'New', onClick: () => this.goToUrl('../new'), hotkey: 'control.n', visible: this.authService.hasPermission(this.newBasePermissionKey + '.add') }));

      if (this.element.VatNumber != undefined) {
        this.toolbarActions.push(new SyslinkToolbarActionButton({ code: 'CreditSafe', icon: 'chart', text: 'Show creditSafe', onClick: () => this.showCreditSafeModal(), visible: this.authService.hasPermission(this.newBasePermissionKey + '.creditsafe') }));
      }
      this.toolbarActions.push(new SyslinkToolbarFileButton({ entityType: 'Third', entityId: this.element.Id }));
      this.toolbarActions.push(new SyslinkToolbarActionButton({ code: 'delete', icon: 'trash', text: 'Delete', onClick: () => this.deleteConfirm?.modal.open(), visible: this.authService.hasPermission(this.newBasePermissionKey + '.delete') }));
    }
  }

  // Save
  // ----
  public async save(checkBeforSave:boolean = true) {
    try {
      if (checkBeforSave == true && !this.validateElementInformations(this.element)) {
        return;
      }
      this.confirmSaveModal.close();
      this.ngxUiLoaderService.start();
      const isCreate = this.element.Id ? false : true;
      await this.createOrUpdateThird();

      if (!isCreate && this.element.Id) {
        NotificationsService.sendSuccess("Record updated");
        this.element = await this.thirdsService.find(this.element.Id);
        await this.refreshElement();
      }
      else {
        NotificationsService.sendSuccess("Record created");
        AppInjectorService.config.setModificationGuard(false);
        this.goToUrl('../' + this.element?.Id);
      }
      this.ngxUiLoaderService.stop();
      // TODO Check better method
      setTimeout(() => {
        AppInjectorService.config.setModificationGuard(false);
      }, 50);
    } catch (e: any) {
      this.ngxUiLoaderService.stopAll();
     }
  }

  // Third
  // -----
  private async createOrUpdateThird() {
    const createThird: Third | undefined = await this.createOrUpdate<Third>(this.thirdsService, this.element);

    if (!this.element.Id) {
      this.element.Id = createThird?.Id;

      this.element.AllRelationThirds.map((relation: ThirdRelation) => {
        relation.FromThirdId = this.element;
      })

    }

    await this.createorUpdateThirdRelations();
    await this.createorUpdateThirdContactInformation();
    await this.createorUpdateThirdRoles();
  }

  // -------------------------------------------------------

  async validateElementInformations(element: Third) {
    let result = true;

    // Checking person fields
    // ----------------------
    if (this.element.IsProfessional == false) {
      if (this.element.Firstname == '') {
        NotificationsService.sendErrorMessage(this.translateService.instant("Field is required: %s"), [this.translateService.instant("Firstname")])
        result = false;
      }

      if (this.element.Lastname == '') {
        NotificationsService.sendErrorMessage(this.translateService.instant("Field is required: %s"), [this.translateService.instant("Lastname")])
        result = false;
      }

      var filter = this.element.Id ? ["toupper(Firstname) eq '"+this.element.Firstname?.toUpperCase()+"' and toupper(Lastname) eq '"+this.element.Lastname?.toUpperCase()+"' and Id ne "+this.element.Id] :["toupper(Firstname) eq '"+this.element.Firstname?.toUpperCase()+"'and toupper(Lastname) eq '"+this.element.Lastname?.toUpperCase()+"'"];
      if ((await this.thirdsService.load({filter:filter})).length > 0) {
       this.confirmSaveModal.open();
        result = false;
      }
    }

    // Checking company fields
    // -----------------------
    if (this.element.IsProfessional == true) {
      if (this.element.Name == '') {
        NotificationsService.sendErrorMessage(this.translateService.instant("Field is required: %s"), [this.translateService.instant("Name")])
        result = false;
      }
    }

    // Check third exist
    // -----------------
    if (this.element.IsProfessional == true && this.element.VatNumber!= undefined) {
      var filter = this.element.Id ? ["IsProfessional eq true and toupper(VatNumber) eq '"+this.element.VatNumber.toUpperCase()+"' and Id ne "+this.element.Id] :["IsProfessional eq true and toupper(VatNumber) eq '"+this.element.VatNumber.toUpperCase()+"'"];
      if ((await this.thirdsService.load({filter:filter})).length > 0) {
        NotificationsService.sendErrorMessage(this.translateService.instant("This third already exist"))
        result = false;
      }
    }

    return result;
  }

  // Delete
  // ------
  public async delete(third: Third) {
    if (!this.element.Id) return;

    // Check if a documentData exist
    // -----------------------------
    var options: LoadOptions = {
      filter: ['ThirdId.Id eq ' + this.element.Id],
      expand: ['SaleDocumentHeaderId']
    };
    var documentDataList = await this.documentDatasService.load(options);
    if (documentDataList.length > 0) {
      this.thirdModaltransfertFields = [];
      documentDataList.forEach((documentdate: DocumentData) => {
        this.thirdModaltransfertFields.push(
          {
            Key: documentdate.SaleDocumentHeaderId.Id,
            Type: "string",
            Name: this.getSaleDocumentType(documentdate.SaleDocumentHeaderId),
            Disabled: true,
            ColumnWidth: 12,
            Value: documentdate.SaleDocumentHeaderId.No
          }
        );
      });
      this.deleteConfirm?.close();
      this.thirdModaltransfertFilter = ["Roles/any(r:r/Code eq 'customer') and Id ne " + this.element.Id];
      this.thirdModaltransfert?.selectThird?.forceReload();
      this.thirdModaltransfert?.selectThird?.selectComponent?.instance.getDataSource().filter(this.thirdModaltransfertFilter);
      this.thirdModaltransfert?.selectThird?.selectComponent?.instance.getDataSource().reload();
      this.thirdModaltransfert?.open();
      return;
    }

    // Remove Customer/Supplier
    // ------------------------
    if (this.element.Roles.find(e => e.Id == ThirdRoleIds.Customer) && this.element.CustomerId && this.element.CustomerId.Id) {
      await this.customersService.remove(this.element.CustomerId.Id);
    }
    if (this.element.SupplierId && this.element.SupplierId.Id) {
      await this.suppliersService.remove(this.element.SupplierId.Id);
    }
    if (this.element.UserId && this.element.UserId.Oid) {
      await this.usersService.remove(this.element.UserId.Oid);
    }

    await this.thirdsService.remove(this.element.Id);
    this.goToUrl('../');

    NotificationsService.sendSuccess("Record deleted");
  }

  public async deleteThirdRole(role: ThirdRole | undefined) {
    if (!this.element.Id || !role || !role.Id) return;
    await this.thirdsService.removeThirdRole(this.element.Id, role.Id)
  }
  //------------------------------------------------------------------

  // Transfer document data third
  // ----------------------------
  public getSaleDocumentType(document: any): string {
    if (document["@odata.type"].includes("SaleQuoteHeader")) {
      return "Quote";
    }
    if (document["@odata.type"].includes("SaleInvoiceHeader")) {
      return "Invoice";
    }
    if (document["@odata.type"].includes("SaleCreditNoteHeader")) {
      return "Credit note";
    }
    if (document["@odata.type"].includes("SaleContractHeader")) {
      return "Contract";
    }
    return "Other";
  }

  public async thirdModaltransfertValidate(e: any) {
    if (!e.third.Id || !this.element.Id) return;
    await this.documentDatasService.updateThird(this.element.Id, e.third.Id);
    await this.delete(this.element);
  }
  //------------------------------------------------------------------

  // Types
  // -----
  public async onIsProfessionalChanged(e: any) {
    await this.refreshElement();
  }

  // Roles
  // -----
  public async onThirdRoleChanged(e: any) {
    if (this.element.Roles.find(e => e.Id == ThirdRoleIds.Customer) && !this.element.CustomerId) {
      this.element.CustomerId = await this.customersService.getInstance({ ThirdId: this.element });
    }
    if (this.element.Roles.find(e => e.Id == ThirdRoleIds.Supplier) && !this.element.SupplierId) {
      this.element.SupplierId = await this.suppliersService.getInstance({ ThirdId: this.element });
    }
  }

  public async createorUpdateThirdRoles() {
    if (!this.element.Roles || !this.element.Id) return;
    var third = await this.thirdsService.findByID(this.element.Id, { expand: ['Roles'] });
    if (third.Roles.find(e => e.Id == ThirdRoleIds.Customer) && !this.element.Roles.find(e => e.Id == ThirdRoleIds.Customer)) {
      this.deleteThirdRole(third.Roles.find(e => e.Id == ThirdRoleIds.Customer));
    }
    if (third.Roles.find(e => e.Id == ThirdRoleIds.Supplier) && !this.element.Roles.find(e => e.Id == ThirdRoleIds.Supplier)) {
      this.deleteThirdRole(third.Roles.find(e => e.Id == ThirdRoleIds.Supplier));
    }
    if (third.Roles.find(e => e.Id == ThirdRoleIds.User) && !this.element.Roles.find(e => e.Id == ThirdRoleIds.User)) {
      this.deleteThirdRole(third.Roles.find(e => e.Id == ThirdRoleIds.User));
    }

    // Creating Customer
    // ---------------
    if (this.element.Roles.find(e => e.Id == ThirdRoleIds.Customer) && this.element.CustomerId) {
      this.element.CustomerId.ThirdId = this.element;
      this.element.CustomerId = await this.createOrUpdate<Customer>(this.customersService, this.element.CustomerId, true);
    }

    // Creating Supplier
    // ---------------
    if (this.element.Roles.find(e => e.Id == ThirdRoleIds.Supplier) && this.element.SupplierId) {
      this.element.SupplierId.ThirdId = this.element;
      this.element.SupplierId = await this.createOrUpdate<Supplier>(this.suppliersService, this.element.SupplierId, true);
    }
    await this.createOrUpdate<Third>(this.thirdsService, this.element);
  }
  //------------------------------------------------------------------

  // Relations
  // ---------
  public async createorUpdateThirdRelations() {
    if (!this.element.Id) return;
    var third = await this.thirdsService.findByID(this.element.Id, { select: ['Id', 'AllRelationThirds.Id', 'AllRelationThirds.FromThirdId.Id', 'AllRelationThirds.ToThirdId.Id', 'AllRelationThirds.ThirdRelationTypeId.Id', 'AllRelationThirds.IsPublic'] });
    var currentthirdRelation: ThirdRelation[] = [...this.element.AllRelationThirds]
    var oldthirdRelation: ThirdRelation[] = [...third.AllRelationThirds]

    currentthirdRelation.forEach((relation: ThirdRelation) => {
      if (!relation.Id) {
        this.onThirdRelationChanged(relation, true);
      }
      if (oldthirdRelation.find(e => e.Id == relation.Id) && !this.thirdRelationsService.isSimilar(oldthirdRelation.find(e => e.Id == relation.Id), relation)) {
        this.onThirdRelationChanged(relation);
        oldthirdRelation = oldthirdRelation.filter(e => e.Id != relation.Id)
      }
      else if (oldthirdRelation.find(e => e.Id == relation.Id)) {
        oldthirdRelation = oldthirdRelation.filter(e => e.Id != relation.Id)
      }
    });
    if (!this.element.Id) return;
    oldthirdRelation.forEach(async (relation: ThirdRelation) => {
      if (!relation.Id) return;
      await this.thirdRelationsService.remove(relation.Id);
    });
  }

  public async onThirdRelationChanged(element: ThirdRelation, isCreate: boolean = false) {
    element.FromThirdId = this.element;
    element = <ThirdRelation>await this.createOrUpdate<ThirdRelation>(this.thirdRelationsService, element, true);
  }

  // Third contact information
  // -------------------------
  public async createorUpdateThirdContactInformation() {
    if (!this.element.Id) return;

    this.element.ContactInformations.map((e: ThirdContactInformation) => e.ThirdId = this.element)

    var third = await this.thirdsService.findByID(this.element.Id, { expand: ['ContactInformations'] });
    var currentthirdContactInformation: ThirdContactInformation[] = [...this.element.ContactInformations]
    var oldthirdContactInformation: ThirdContactInformation[] = [...third.ContactInformations]
    oldthirdContactInformation.map((e: ThirdContactInformation) => e.ThirdId = this.element)

    currentthirdContactInformation.forEach((contactInformation: ThirdContactInformation) => {
      if (!contactInformation.Id) {
        this.onThirdContactInformationChanged(contactInformation);
      }
      if (oldthirdContactInformation.find(e => e.Id == contactInformation.Id) && !this.thirdContactInformationsService.isSimilar(oldthirdContactInformation.find(e => e.Id == contactInformation.Id), contactInformation)) {
        this.onThirdContactInformationChanged(contactInformation);
        oldthirdContactInformation = oldthirdContactInformation.filter(e => e.Id != contactInformation.Id)
      }
      else if (oldthirdContactInformation.find(e => e.Id == contactInformation.Id)) {
        oldthirdContactInformation = oldthirdContactInformation.filter(e => e.Id != contactInformation.Id)
      }
    });
    if (!this.element.Id) return;
    oldthirdContactInformation.forEach(async (contactInformation: ThirdContactInformation) => {
      if (!contactInformation.Id) return;
      await this.thirdContactInformationsService.remove(contactInformation.Id);
    });
  }

  public async onThirdContactInformationChanged(element: ThirdContactInformation) {
    element.ThirdId = this.element;
    if (element.AddressId) {
      element.AddressId = <ThirdAddress>await this.createOrUpdate<ThirdAddress>(this.thirdAddressService, element.AddressId, true);
    }
    element = <ThirdContactInformation>await this.createOrUpdate<ThirdContactInformation>(this.thirdContactInformationsService, element, true);
  }
  //------------------------------------------------------------------

  public getFormattedTitle(): string {
    let formattedTitle = '';
    formattedTitle += this.element.No ? this.element.No + ' - ' : '';

    let typePart = 'third';
    typePart = this.hasCustomerRole() && !this.hasSupplierRole() ? 'customer' : typePart;
    typePart = !this.hasCustomerRole() && this.hasSupplierRole() ? 'supplier' : typePart;

    formattedTitle += this.element.Id ? this.element.Fullname : this.translateService.instant('New ' + typePart);

    return formattedTitle;
  }

  public hasUserRole(): boolean {
    return this.element.Roles.find((e: ThirdRole) => e.Id == ThirdRoleIds.User) != undefined;
  }

  public hasCustomerRole(): boolean {
    return this.element.Roles.find((e: ThirdRole) => e.Id == ThirdRoleIds.Customer) != undefined;
  }

  public hasSupplierRole(): boolean {
    return this.element.Roles.find((e: ThirdRole) => e.Id == ThirdRoleIds.Supplier) != undefined;
  }

  //------------------------------------------------------------------

  // Autocompletion with VAT
  public getThirdDataSummaryFromVat(): void {
    let vat: string | undefined = 'BE' + this.element.CompanyNumber;
    if (vat?.length === 12) {
      this.isAutocompleteLoading = true;
      this.companyDataService.getSummaryDataFromThird(vat, this.element.IsProfessional).then((response: Third) => {
        response = jsonToOdataFormat(response);
        this.thirdAutocompleted = response;

        this.updateThirdOrOpenUpdateModal(response);

        this.isAutocompleteLoading = false;
      }).catch((e: any) => {
        this.isAutocompleteLoading = false;
        NotificationsService.sendErrorMessage(e.error)
      });
    }
  }

  public async onModalModificationReplace(): Promise<void> {
    this.updateThirdAutocomplete?.close();
    if (this.thirdAutocompleted) {
      await this.replaceThirdDataFrom(this.thirdAutocompleted);
    }
  }

  private async updateThirdOrOpenUpdateModal(newThird: Third): Promise<void> {
    if (!this.element.Name) {
      this.usedCurrentDenomination = false;
      this.usedCurrentLegalForm = false;
      this.usedCurrentCountry = false;
      newThird.ContactInformations.forEach(e => e.IsChecked = true);
      await this.replaceThirdDataFrom(newThird, false);
    }
    else {
      this.updateThirdAutocomplete?.open();
    }
  }

  private async replaceThirdDataFrom(thirdAutocompleted: Third, autosave: boolean = true): Promise<void> {
    if (this.usedCurrentDenomination == false) {
      this.element.Name = thirdAutocompleted.Name;
    }
    // this.element.CompanyNumber = this.element.VatNumber?.substring(3);
    if (this.usedCurrentLegalForm == false) {
      this.element.LegalFormId = thirdAutocompleted.LegalFormId;
    }
    if (this.usedCurrentCountry == false) {
      this.element.CountryId = thirdAutocompleted.CountryId;
    }
    await this.replaceThirdContactInformationFrom(thirdAutocompleted);
    this.thirdAutocompleted = undefined;
  }

  private async replaceThirdContactInformationFrom(thirdAutocompleted: Third): Promise<void> {
    this.viesContactInformations = [];
    for (var contact of this.element.ContactInformations) {
      await this.addOrRemoveThirdContactInformation(contact);
    }
    for (var contact of thirdAutocompleted.ContactInformations) {
      await this.addOrRemoveThirdContactInformation(contact);
    }
    this.element.ContactInformations = [];
    this.element.ContactInformations = this.viesContactInformations;
  }
  private async addOrRemoveThirdContactInformation(contact: ThirdContactInformation) {
    if (contact.IsChecked == true) {
      this.viesContactInformations.push(contact);
    }
  }

  private async createOrUpdate<T extends Entity>(service: ODataService<T>, object?: T, forceRefresh: boolean = false): Promise<T | undefined> {
    if (!object) return;
    let response: T = object?.Id ? await service.update(object.Id, service.format(object)) : await service.insert(service.format(object));
    if (!response?.Id) {
      NotificationsService.sendErrorMessage("Unable to create or update record: %s", [this.translateService.instant("Person")]);
      return;
    }
    if (forceRefresh) {
      response = await service.findByID(response.Id);
    }
    return response;
  }

  // CreditSafe
  // ----------
  public showCreditSafeModal() {
    if (this.element.CreditSafeLineId != null) {
      var data = this.element.CreditSafeLineId.Data;
      this.creditSafeElement = JSON.parse(data);
    }
    this.creditsafeModal?.open();
  }
}
