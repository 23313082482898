import { DocumentDelay } from "../../../base/documents/document-delays/document-delay";
import { PriceList } from "../price-lists/price-list";
import { PaymentMethod } from "../../../base/payments/payment-methods/payment-method";
import { TaxRate } from "../../../base/tax-rates/tax-rate";
import { Entity } from "../../../core/models/entity";
import { Third } from "../../thirds/third.model";
import { TaxType } from "../../../accountings/tax-types/tax-type.model";

export class Customer extends Entity {
    public ThirdId: Third = new Third();
    public HourlyRate?:number;
    public PriceListId? : PriceList;
    public PaymentMethodId? : PaymentMethod;
    public SaleTaxRateId? : TaxRate;
    public SaleTaxTypeId? : TaxType;
    public Debt?:number;
    public Balance?:number;
    public SaleInvoiceDocumentDelayId?: DocumentDelay;
    public InTaxOpenAmount?: number;
    public InTaxDueAmount?: number;
    constructor(customer?: Partial<Customer>) {
        super();
        Object.assign(this, customer);
    }
}
