<ng-container  *ngFor="let ThirdRelation of element.AllRelationThirds">
    <div  *ngIf="authService.hasPermission(newBasePermissionKey + '.relations.view')" class="h-100 d-flex  border-bottom mb-1 pb-1 justify-content-center align-items-center gap-2">
        <i
            class="text-gray fa-solid fa-user"
            (click)="onThirdClick((!this.element.Id || ThirdRelation.ToThirdId?.Id != this.element.Id) ? ThirdRelation.ToThirdId?.Id:ThirdRelation.FromThirdId?.Id)"
        ></i>
        <div class=" flex-grow-1 align-items-center ">
            <syslink-link
                url="/basics/thirds/thirds/{{((!this.element.Id || ThirdRelation.ToThirdId?.Id != this.element.Id) ? ThirdRelation.ToThirdId?.Id:ThirdRelation.FromThirdId?.Id)}}"
                class="fst-italic fw-light text-truncate"
                [text]="(!this.element.Id || ThirdRelation.ToThirdId?.Id != this.element.Id) ? ThirdRelation.ToThirdId?.Fullname:ThirdRelation.FromThirdId?.Fullname"
                [newTab]="true"
            >
            </syslink-link>
            <br>
            {{ThirdRelation.ThirdRelationTypeId?.Name}}
        </div>
        <div class="d-flex align-items-center gap-2 mx-2">
            <i
                class="fa-{{ThirdRelation.IsPublic? 'solid' : 'regular'}} fa-eye text-center text-gray"
                [title]="'Public' | translate"
            ></i>
            <i
                *ngIf="authService.hasPermission(newBasePermissionKey + '.relations.update')"
                class="fa-solid fa-pencil text-center text-gray"
                role="button"
                (click)="onUpdateBtnClicked(ThirdRelation)"
                [title]="'Update' | translate"
            ></i>
            <i
                *ngIf="authService.hasPermission(newBasePermissionKey + '.relations.delete')"
                class="fa-solid fa-trash text-center text-danger"
                role="button"
                (click)="onDeleteBtnClicked(ThirdRelation)"
                [title]="'Delete' | translate"
            ></i>
        </div>
    </div>
</ng-container>
<div
    *ngIf="authService.hasPermission(newBasePermissionKey + '.relations.add')"
    class="full-width-container"
>
    <syslink-button
        class="full-width-button"
        icon="plus"
        (click)="onCreateBtnClicked()"
    ></syslink-button>
</div>

<syslink-modal
    #editModal
    title="Relation"
    width="300px"
    (onValidate)="editModal.data.newItem==false ? onUpdate(relation) : onCreate(relation)"
    [validateText]="(relation.Id ? 'Save' : 'Create')"
    cancelText="Cancel"
>
    <app-third-relation-form [(element)]="relation"></app-third-relation-form>
</syslink-modal>
<syslink-confirm-modal
    #deleteConfirm
    title="Delete"
    content="DeleteConfirmMessage"
    validateText="Confirm"
    cancelText="Cancel"
    (onValidate)="onDelete()"
></syslink-confirm-modal>