import { Injectable } from '@angular/core';
import { ODataService } from '../../../core/services/oData.service';
import { BankReconciliationStatus } from './bank-reconciliation-status.model';

@Injectable({
  providedIn: 'root'
})
export class BankReconciliationStatusService extends ODataService<BankReconciliationStatus> {
  public url = 'BankReconciliationStatus';

}
