import { Injectable } from '@angular/core';
import { Third } from '../../thirds/thirds/third.model';
import { ApiService } from '../../core/api.service';
import { ThirdsService } from '../../thirds/thirds/thirds.service';
import { ThirdAddressService } from '../../thirds/thirds/third-addresses/third-addresses/third-addresses.service';
import { ThirdRelationsService } from '../../thirds/thirds/third-relations/third-relations.service';
import { ThirdRelation } from '../../thirds/thirds/third-relations/third-relation.model';
import { ThirdContactInformationsService } from '../../thirds/thirds/third-contacts/third-contact-informations/third-contact-informations.service';
import { ThirdContactTypesService } from '../../thirds/thirds/third-contacts/third-contact-types/third-contact-types.service';
import { LegalFormsService } from '../../thirds/legal-forms/legal-forms.service';

@Injectable({
  providedIn: 'root'
})
export class ViesService {

  constructor(
    private api: ApiService,
    private thirdsService: ThirdsService,
    private thirdAddressService: ThirdAddressService,
    private thirdContactInformationsService: ThirdContactInformationsService,
    private thirdContactTypesService: ThirdContactTypesService,
    private thirdRelationsService: ThirdRelationsService,
    private legalFormsService: LegalFormsService,
  ) { }

  async getSummaryDataFromThird(vat: string, isProfessional?: boolean): Promise<Third> {

    try {
      return this.api.sendRequest(
        `/api/third/informations/summary/vat/${vat}`,
        'POST',
        {
          "isProfessional": isProfessional,
        }
      )
        .then(async (response: any) => {

          var third = await this.createThirdFromResponse(response);

          return third;
        })

    } catch (e) {
      throw e;
    }

  }

  private async createThirdFromResponse(response: any): Promise<Third> {
    var contactTypeAddress = (await this.thirdContactTypesService.load({ filter: ["Code eq 'Address'"] }))[0];
    var third = await this.thirdsService.getInstance();
    third.LegalFormId = await this.legalFormsService.getInstance(response.legalFormId);

    //-------------------
    // Country
    third.CountryId = response.countryId

    third.Name = response.name;
    third.Firstname = response.firstname;
    third.Lastname = response.lastname;
    third.IsProfessional = response.isProfessional
    if (third.IsProfessional == true) {
      third.Subjugated = true;
      third.VatNumber = response.vatNumber?.substring(1);
      // third.CompanyNumber = third.VatNumber?.substring(2);
    }

    //-------------------
    // Addresses
    for (let addressFromResponse of response.addresses) {
      var address = await this.thirdAddressService.getInstance();

      address.Number = addressFromResponse.number;
      address.Street = addressFromResponse.street;
      address.LocalityId = addressFromResponse.localityId;
      address.PostalCodeId = addressFromResponse.postalCodeId;
      address.LocalityId.CountryId = response.countryId;
      address.AddressTypeId = addressFromResponse.addressTypeId;
      address.FormattedAddress = addressFromResponse.formattedAddress;
      address.IsPublic = true;

      var contactInformation = await this.thirdContactInformationsService.getInstance({ ContactTypeId: contactTypeAddress, AddressId: address, IsFavorite: true });

      third.ContactInformations.push(contactInformation);
    };


    return third;
  }
}
