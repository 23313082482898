import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { ConfirmModalComponent, ModalComponent, NotificationsService } from 'projects/libraries/syslink-components/src/public-api';
import { Document } from '../documents/documents/document.model';
import { PaymentMethodsService } from './payment-methods/payment-methods.service';
import { Payment } from './payment.model';
import { PaymentsService } from './payments.service';
import { ConfigurationsService } from '../modules/configurations/configurations.service';
import { PaymentTypesService } from './payment-types/payment-types.service';
import { ViewComponent } from 'projects/libraries/syslink-components/src/lib/helpers/view/view.component';
import { AppInjectorService } from 'projects/libraries/syslink-components/src/lib/services/app-injector.service';
import { PaymentStatesService } from './payment-states/payment-states.service';

@Component({
  selector: 'app-payments',
  templateUrl: './payments.component.html',
  styleUrls: ['./payments.component.scss']
})
export class PaymentsComponent extends ViewComponent {
  @Input() public element: Payment[] = [];
  @Input() public filterPaymentType: string | string[] = "";
  @Input() public document?: Document;
  @Input() public documentType: string = '';
  @Input() public InTaxTotal: number = 0;

  @Output() public elementChange: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild('editModal') public editModal: ModalComponent = new ModalComponent();
  @ViewChild('deleteConfirm') public deleteConfirm: ConfirmModalComponent = new ConfirmModalComponent();

  public payment?: Payment;

  public refundablePayments: Payment[] = [];

  constructor(
    private paymentsService: PaymentsService,
    public paymentMethodsService: PaymentMethodsService,
    private configurationsService: ConfigurationsService,
    private paymentTypesService: PaymentTypesService,
    private paymentStatesService: PaymentStatesService
  ) {
    super();
  }

  // Payment label
  // -------------
  private formatDate(date: any) {
    const formatter = new Intl.DateTimeFormat('fr-FR', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    });
    return formatter.format(date);
  }

  public getPaymentLabel(payment: Payment): string {
    return this.formatDate(payment.Date) + ' - ' + payment.PaymentMethodPrimary.Name;
  }

  // Payment amount
  // --------------
  public getPaymentAmount(payment: Payment) {
    return payment.RoundedAmount.toLocaleString('fr-FR', { style: 'currency', currency: 'EUR' });
  }

  // Payment editing
  // ---------------
  public async initModalDatas(payment?: Payment) {
    this.payment = payment ?? await this.paymentsService.getInstance();
    if (this.documentType == "SaleInvoice") {
      this.payment.PaymentTypeId = (await this.paymentTypesService.load({ filter: ["Code eq 'Payment'"] }))[0];
    }
    this.updateRefundablePayments();
  }


  // Create
  // ------
  public async onCreateBtnClicked() {
    this.payment = new Payment();
    await this.initModalDatas();
    this.payment.DocumentId = this.document?.Id;
    this.payment.Amount = this.document?.AmountRemaining ?? 0;
    if (this.document?.ThirdId.CustomerId?.PaymentMethodId != undefined) {
      this.payment.PaymentMethodPrimary = this.document?.ThirdId.CustomerId?.PaymentMethodId;
    }
    if (this.payment.PaymentMethodPrimary == undefined || this.payment.PaymentMethodPrimary.Id == undefined) {
      var configurations = await this.configurationsService.getConfigurationAsNumber("paymentMethod", "Thirds.Customers", "Thirds");
      this.payment.PaymentMethodPrimary = await this.paymentMethodsService.findByID(configurations ?? 1);
    }
    this.payment.RoundedAmount = this.payment.Amount;
    this.editModal.open({ newItem: true });
  }

  public async onCreate(payment: Payment) {
    if (payment.PaymentMethodPrimary.Code == 'Mollie-Terminal' && !this.document?.Id) {
      NotificationsService.sendErrorMessage("Please save before add mollie payment");
      return;
    }
  
    if (payment.PaymentTypeId && payment.PaymentTypeId.Code == "Refund") {
      payment.Amount = -payment.Amount;
      payment.RoundedAmount = -payment.RoundedAmount;
      payment.PaymentStateId = (await this.paymentStatesService.load({ filter: ["Code eq 'paid'"] }))[0];
    }
    
    if (payment.PaymentMethodPrimary.Code == 'Mollie-Terminal') {
      payment = await this.paymentsService.createMollieTerminalPayment(payment);
    }
  
    this.element.push(payment);
    this.editModal.close();
    this.reloadPaymentAmount();
  }

  // ----------------------------------------------

  // Update
  // ------
  public async onUpdateBtnClicked(payment: Payment) {
    // if (!this.authService.hasPermission(this.basePermissionKey + '.contacts.update')) {
    //   NotificationsService.sendErrorMessage("You do not have the required permission!");
    //   return;
    // }
    await this.initModalDatas(payment);

    this.editModal.open({ newItem: false });
  }

  public async onUpdate(payment: Payment) {
    if (payment.PaymentMethodPrimary.Code == 'Mollie-Terminal' && !this.payment?.Id) {
      NotificationsService.sendErrorMessage("Please save before update mollie payment");
      return;
    }

    if (payment.PaymentTypeId && payment.PaymentTypeId.Code == "Refund") {
      payment.Amount = -(Math.abs(payment.Amount));
      payment.RoundedAmount = -(Math.abs(payment.RoundedAmount));
      payment.PaymentStateId = (await this.paymentStatesService.load({ filter: ["Code eq 'paid'"] }))[0];
    }

    this.element.map((e: Payment) => {
      if (e.Id !== payment.Id) return;
      e = payment;
    });
    // this.checkFavoriteElement(contactInformation);
    this.editModal.close();
    this.reloadPaymentAmount();
  }
  // ----------------------------------------------

  // Delete
  // ------
  public onDeleteBtnClicked(payment: Payment) {
    if (!payment.Id) {
      var index = this.element.findIndex((e: Payment) =>
        e.Amount == payment.Amount &&
        e.PaymentMethodPrimary.Id == payment.PaymentMethodPrimary.Id &&
        e.PaymentStateId?.Id == payment.PaymentStateId?.Id &&
        e.PaymentTypeId?.Id == payment.PaymentTypeId?.Id &&
        e.Date == payment.Date &&
        e.Note == payment.Note &&
        e.Reference == payment.Reference &&
        e.DocumentId == payment.DocumentId
      );
      this.element.splice(index, 1);
      this.reloadPaymentAmount();
      AppInjectorService.config.setModificationGuard(true);
      return;
    }
    // if (!this.authService.hasPermission(this.basePermissionKey + '.contacts.delete')) {
    //   NotificationsService.sendErrorMessage("You do not have the required permission!");
    //   return;
    // }
    this.deleteConfirm.open({ paymentId: payment.Id });
  }

  public async onDelete() {
    this.element = this.element.filter((e: Payment) => e.Id !== this.deleteConfirm.modal.data.paymentId);
    this.deleteConfirm.close();
    this.reloadPaymentAmount();
    AppInjectorService.config.setModificationGuard(true);
    this.elementChange.emit(this.element);
  }


  // Total amount
  // ------------
  public getAmountRemainingColor() {
    if (!this.document) return;
    var result = 'primary';
    const datetime = new Date(Date.now());
    const deadline = this.document.Deadline;

    if (
      !this.document ||
      !this.document.Deadline ||
      !this.document.Date ||
      !deadline ||
      !datetime ||
      !this.document.AmountReceived ||
      !this.document.AmountRemaining
    )
      return result;

    if (this.document.AmountReceived > this.document.AmountRemaining) {
      result = 'warning';
    } else if (this.document.AmountRemaining != 0 && deadline > datetime) {
      result = 'primary';
    } else if (this.document.AmountRemaining != 0 && deadline <= datetime) {
      result = 'danger';
    } else if (this.document.AmountRemaining == 0) {
      result = 'secondary';
    }

    return result;
  }

  // Reload Amount
  // -------------
  private reloadPaymentAmount() {
    if (!this.document) return;
    this.document.AmountReceived = (this.element.reduce((acc, objet) => acc + (objet.PaymentStateId?.Code == "paid" ? objet.Amount : 0), 0))
    this.document.AmountRemaining = this.document.InTaxTotal - this.document.AmountReceived;
  }

  private updateRefundablePayments(): void {
    this.refundablePayments = this.element.filter((payment: Payment) => {
      if (!payment.PaymentMethodPrimary) return false;
      if (
        // Mollie
        (payment.PaymentMethodPrimary.Code == "Mollie-Terminal") &&
        // Not refund
        payment.Amount > 0 &&
        // Paid
        payment.PaymentStateId != null && payment.PaymentStateId.Code == "paid") {

        const totalRefunded = payment.Refunds?.reduce((total, refund) => total + refund.Amount, 0) ?? 0;
        return payment.Amount > totalRefunded;
      }
      return false;
    });
  }
}