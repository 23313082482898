import { Injectable } from '@angular/core';
import { ODataService } from '../../../core/services/oData.service';
import { TaskBillingType } from './task-billing-type.model';
import { LoadOptions } from 'devextreme/data';

@Injectable({
  providedIn: 'root'
})
export class TaskBillingTypesService extends ODataService<TaskBillingType> {
  public url = 'TaskBillingType';
  public override defaultOptions: LoadOptions = {
    expand: ['NameTranslationId.Translations.LanguageId']
  };

  
}