import { Component } from '@angular/core';
import { AccountMove } from './account-move.model';
import { ActivatedRoute, Router } from '@angular/router';
import { PageComponent } from 'projects/libraries/syslink-components/src/public-api';

@Component({
  selector: 'app-account-moves',
  templateUrl: './account-moves.component.html',
  styleUrls: ['./account-moves.component.scss']
})
export class AccountMovesComponent  extends PageComponent{

  constructor(
    protected override activatedRoute: ActivatedRoute
  ) {
    super();
  }

  public addNewProduct() {
    this.router.navigate(['new'], { relativeTo: this.activatedRoute });
  }

  public onDoubleClicked(element: AccountMove) {
    this.router.navigate([element.Id], { relativeTo: this.activatedRoute });
  }
}
