<div
    *ngIf="element.HasCustomerRole && element.CustomerId"
    class="h-100 d-flex my-1 pb-1 justify-content-center"
>
    <div class="align-items-left">
        <div class="totalPriceLabel fw-bold">{{'InTaxOpenAmount' | translate }} :</div>
        <br>
        <div class="totalPriceLabel fw-bold">{{'InTaxDueAmount' | translate }} :</div>
    </div>
    <div class=" flex-grow-1 align-items-center">
        <div class="fw-bold text-success text-center">{{element.CustomerId.InTaxOpenAmount |
            currency }} 
        </div>
        <br>
        <div class="fw-bold text-danger text-center">{{element.CustomerId.InTaxDueAmount |
            currency }}
        </div>
    </div>
</div>