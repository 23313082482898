import { Component, Input } from '@angular/core';
import { DocumentData } from '../document-data.model';

@Component({
  selector: 'app-third-document-data-block',
  templateUrl: './third-document-data-block.component.html',
  styleUrls: ['./third-document-data-block.component.scss']
})
export class ThirdDocumentDataBlockComponent {
  @Input() public documentDataCollection: DocumentData[] = [];
  @Input() public showDeliveryAddress: boolean = false;

  public isProfessional: boolean = false;

  public getName(): string {
    var result = '';
    var principalDocumentData = this.documentDataCollection.find((dd) => dd.HasPrincipal == true);
    if (principalDocumentData?.ThirdId.IsProfessional) {
      result = principalDocumentData?.ThirdId.Fullname ?? "";
      this.isProfessional = true;
    }
    if (!principalDocumentData?.ThirdId.IsProfessional) {
      var secondDocumentData = this.documentDataCollection.find((dd) => dd.HasSecondary == true);
      result = (principalDocumentData?.ThirdId.Fullname ?? "") + (secondDocumentData ? (" & " + secondDocumentData.ThirdId.Fullname) : "");
      this.isProfessional = false;
    }
    return result;
  }

  public getSecondName(): string {
    var result = '';
    var secondDocumentData = this.documentDataCollection.find((dd) => dd.HasSecondary == true);
    if (secondDocumentData) {
      result = secondDocumentData.ThirdId.Fullname ?? "";
    }
    return result;
  }

  public getAddress1(): string {
    var result = '';
    var principalDocumentData = this.documentDataCollection.find((dd) => dd.HasPrincipal == true);
    result = (principalDocumentData?.Street ?? "") + " " + (principalDocumentData?.Number ?? "") + (principalDocumentData?.Box && principalDocumentData?.Box != '' ? ("/" + principalDocumentData?.Box) : "");
    return result;
  }

  public getAddress2(): string {
    var result = '';
    var principalDocumentData = this.documentDataCollection.find((dd) => dd.HasPrincipal == true);
    result = (principalDocumentData?.LocalityId?.LongName ?? "") + " " + (principalDocumentData?.PostalCodeId?.Value ?? "");
    return result;
  }

  public getVat(): string {
    var result = '';
    var principalDocumentData = this.documentDataCollection.find((dd) => dd.HasPrincipal == true);
    result = principalDocumentData?.ThirdId.VatNumber ?? "";
    return result;
  }

  // Delivery Address
  // ----------------
  public getDeliveryAddress1(): string {
    var result = '';
    var deliveryDocumentData = this.documentDataCollection.find((dd) => dd.UsedDeliveryAddress == true);
    result = (deliveryDocumentData?.DeliveryStreet ?? "") + " " + (deliveryDocumentData?.DeliveryNumber ?? "") + (deliveryDocumentData?.DeliveryBox && deliveryDocumentData?.DeliveryBox != '' ? ("/" + deliveryDocumentData?.DeliveryBox) : "");
    return result;
  }

  public getDeliveryAddress2(): string {
    var result = '';
    var deliveryDocumentData = this.documentDataCollection.find((dd) => dd.UsedDeliveryAddress == true);
    result = (deliveryDocumentData?.DeliveryLocalityId?.LongName ?? "") + " " + (deliveryDocumentData?.DeliveryPostalCodeId?.Value ?? "");
    return result;
  }
}
