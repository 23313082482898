import { Component } from '@angular/core';

@Component({
  selector: 'app-account-move-lines',
  templateUrl: './account-move-lines.component.html',
  styleUrls: ['./account-move-lines.component.scss']
})
export class AccountMoveLinesComponent {

}
