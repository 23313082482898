import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ViewComponent } from 'projects/libraries/syslink-components/src/lib/helpers/view/view.component';
import { BankReconciliation } from '../bank-reconciliation.model';
import { UsersService } from '../../../core/auth/users/users.service';

@Component({
  selector: 'app-bank-reconciliation-form',
  templateUrl: './bank-reconciliation-form.component.html',
  styleUrls: ['./bank-reconciliation-form.component.scss']
})
export class BankReconciliationFormComponent extends ViewComponent {
  @Input() public element: BankReconciliation = new BankReconciliation();
  @Output() public elementChange: EventEmitter<BankReconciliation> = new EventEmitter<BankReconciliation>();
 
  @Input() public disabled: boolean = false;

  constructor(
    public usersService : UsersService
  ) {
    super();
  }
}
