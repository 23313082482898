    <div class="row">
      <div class="{{ isAutocompleteLoading ? 'col-5' : 'col-6' }}">
        <syslink-input-text
        label="Company number"
        [(model)]="element.CompanyNumber"
        (modelChange)="onCompanyNumberInputChange($event)"
        (paste)="onCompanyNumberPaste($event)"
        [maxLength]="30"
        [disabled]="isAutocompleteLoading"
      ></syslink-input-text>
      </div>
      <div
        *ngIf="isAutocompleteLoading"
        class="col-1 d-flex align-items-center"
      >
        <i class="fas fa-sync fa-spin"></i>
      </div>
    </div>
<div class="row">
  <div class="col-sm-3 col-xs-12">
    <syslink-input-text
      label="Denomination"
      [(model)]="element.Name"
      [disabled]="disabled"
    ></syslink-input-text>
  </div>
  <div class="col-sm-3 col-xs-12">
    <syslink-select
      label="Legal form"
      [store]="legalFormsService.store"
      [(model)]="element.LegalFormId"
      [disabled]="disabled"
    ></syslink-select>
  </div>
</div>
<div class="row">
  <div class="col-sm-3 col-xs-12">
    <syslink-switch
      label="Subjugated"
      [(model)]="element.Subjugated"
      [disabled]="isAutocompleteLoading"
    ></syslink-switch>
  </div>
  <div
    class="col-sm-3 col-xs-12"
    *ngIf="element.Subjugated"
  >
    <div class="row">
      <div class="{{ isAutocompleteLoading ? 'col-11' : 'col-12' }}">
        <syslink-input-text
          label="Vat number"
          [(model)]="element.VatNumber"
          (modelChange)="onVatNumberInputUpdate($event)"
          (paste)="onVatPaste($event)"
          [disabled]="vatDisabled"
          [maxLength]="30"
        ></syslink-input-text>
      </div>
      <div
        *ngIf="isAutocompleteLoading"
        class="col-1 d-flex align-items-center"
      >
        <i class="fas fa-sync fa-spin"></i>
      </div>
    </div>
  </div>
</div>


<!-- <div class="row">
		<div class="col">
			<syslink-button
				[visible]="!!element.Subjugated"
				[text]="'AutoComplete' "
				(click)="onCompleteInformationsButtonClick()"
				[disabled]="isAutocompleteLoading"
			></syslink-button>
		</div>
	</div> -->