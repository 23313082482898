<syslink-title text="Purchase invoices"></syslink-title>

<app-purchase-invoice-grid
  #grid
  [permissionKey]="'list'"
  [canDelete]="false"
  (add)="onAddButtonClicked()"
  [contextMenuItems]="contextMenuItems"
></app-purchase-invoice-grid>

<app-document-create-modal
  #createModal
  title="Create Invoice"
  [thirdFilter]="supplierFilter"
  detailsUrl="/thirds/suppliers/"
  (validate)="onCreateValidated($event)"
></app-document-create-modal>

<app-purchase-invoice-status-select-modal
  #statusModal
  (validate)="onConfirmModalValidated($event)"
></app-purchase-invoice-status-select-modal>
<app-payments
  *ngIf="paymentElement && paymentDocument"
  #paymentModal
  [documentType]="'PurchaseInvoice'"
  [element]="paymentElement"
  [document]="paymentDocument"
  (paymentsChange)="grid.refresh()"
  ></app-payments>
  <!-- [showGrid]="false" -->