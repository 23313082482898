import { Component } from '@angular/core';
import { AppInjectorService } from 'projects/libraries/syslink-components/src/lib/services/app-injector.service';
import { SaleQuoteHeaderHeaderStatusesService } from '../sale-quote-header-header-statuses/sale-quote-header-header-statuses.service';
import { SaleQuoteHeaderHeaderStatus } from '../sale-quote-header-header-statuses/sale-quote-header-header-status.model';
import { SaleQuoteHeaderStatusesService } from '../sale-quote-header-statuses/sale-quote-header-statuses.service';
import { SaleQuoteHeaderStatus } from '../sale-quote-header-statuses/sale-quote-header-status.model';
import { SaleQuoteLinesService } from '../sale-quote-lines/sale-quote-lines.service';
import { SaleQuoteLine } from '../sale-quote-lines/sale-quote-line.model';
import { SaleQuotesService } from '../sale-quotes/sale-quotes.service';
import { SaleQuote } from '../sale-quotes/sale-quote.model';
import { SaleDocumentDetailsComponent } from '../../sale-documents/sale-documents/sale-document-details/sale-document-details.component';
import { DocumentData } from '../../../base/documents/document-datas/document-data.model';
import { ThirdContactInformation } from '../../../thirds/thirds/third-contacts/third-contact-informations/third-contact-information.model';
import { SaleOrderHeaderStatusesService } from '../../sale-orders/sale-order-header-statuses/sale-order-header-statuses.service';
import { SaleInvoiceHeaderStatusesService } from '../../sale-invoices/sale-invoice-header-statuses/sale-invoice-header-statuses.service';
import { SaleCreditNoteHeaderStatusesService } from '../../sale-credit-notes/sale-credit-note-header-statuses/sale-credit-note-header-statuses.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ActivatedRoute } from '@angular/router';
import { ReportsService } from '../../../connectors/reports/reports.service';
import { MailTemplateTypesService } from '../../../mails/mail-template-types/mail-template-types.service';
import { DocumentsService } from '../../../base/documents/documents/documents.service';
import { ThirdsService } from '../../../thirds/thirds/thirds.service';
import { DocumentDatasService } from '../../../base/documents/document-datas/document-datas.service';
import { PaymentsService } from '../../../base/payments/payments.service';
import { DocumentRelationsService } from '../../../base/documents/document-relations/document-relation.service';
import { SaleDocumentLineDiscountOperationsService } from '../../sale-documents/sale-document-line-discount-operations/sale-document-line-discount-operations.service';
import { ModificationService } from '../../../core/services/modification.service';
import { SaleContractHeaderStatusesService } from '../../sale-contracts/sale-contract-header-statuses/sale-contract-header-statuses.service';
import { MailModel } from '../../../connectors/mail/mail.model';
import { DocumentLineRelationsService } from '../../../base/documents/document-line-relations/document-relation.service';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-sale-quote-details',
  templateUrl: '../../sale-documents/sale-documents/sale-document-details/sale-document-details.component.html',
  styleUrls: ['./sale-quote-details.component.scss']
})
export class SaleQuoteDetailsComponent extends SaleDocumentDetailsComponent<
  SaleQuote,
  SaleQuotesService,
  SaleQuoteLine,
  SaleQuoteLinesService,
  SaleQuoteHeaderStatus,
  SaleQuoteHeaderStatusesService,
  SaleQuoteHeaderHeaderStatus,
  SaleQuoteHeaderHeaderStatusesService
> {

  constructor(
    public override saleQuoteHeaderStatusesService: SaleQuoteHeaderStatusesService,
    public override saleOrderHeaderStatusesService: SaleOrderHeaderStatusesService,
    public override saleInvoiceHeaderStatusesService: SaleInvoiceHeaderStatusesService,
    public override saleCreditNoteHeaderStatusesService: SaleCreditNoteHeaderStatusesService,
    public override saleContractHeaderStatusesService: SaleContractHeaderStatusesService,
    public override ngxUiLoaderService: NgxUiLoaderService,
    public override activatedRoute: ActivatedRoute,
    public override reportsService: ReportsService,
    private mailTemplateTypesService: MailTemplateTypesService,
    public override documentService: DocumentsService,
    public override thirdsService: ThirdsService,
    public override documentDatasService: DocumentDatasService,
    public override paymentsService: PaymentsService,
    public override documentRelationsService: DocumentRelationsService,
    public override saleDocumentLineDiscountOperationsService: SaleDocumentLineDiscountOperationsService,
    public override documentLineRelationsService: DocumentLineRelationsService,
    public override modificationService: ModificationService,
    public override sanitizer: DomSanitizer
  ) {
    super(saleQuoteHeaderStatusesService, saleOrderHeaderStatusesService, saleInvoiceHeaderStatusesService, saleCreditNoteHeaderStatusesService, saleContractHeaderStatusesService, ngxUiLoaderService, activatedRoute, reportsService, documentDatasService, thirdsService, paymentsService, documentRelationsService, saleDocumentLineDiscountOperationsService, documentService, documentLineRelationsService, modificationService, sanitizer);
  }

  protected override loadDependencies() {
    this.saleDocumentService = AppInjectorService.injector.get(SaleQuotesService);
    this.saleDocumentLinesService = AppInjectorService.injector.get(SaleQuoteLinesService);
    this.saleDocumentStatusesService = AppInjectorService.injector.get(SaleQuoteHeaderStatusesService);
    this.saleDocumentHeaderStatusesService = AppInjectorService.injector.get(SaleQuoteHeaderHeaderStatusesService);

    this.documentType = "SaleQuote";
    this.subModuleCode = "Sales.Quote";
    this.defaultStatusCode = "Quote.Draft";
    this.documentLinesColumns = [...this.saleDocumentService.getDefaultDocumentLineColumns(this.subModuleCode, this.documentType)];
  }

  public override canEditDocument(): boolean {
    return !this.hasActiveStatus("Quote.Validated");
  }

  // Mail
  // ----
  public override async onSendMailButtonClicked() {
    if (this.modificationService.hasModifications || !this.element.Report) {
      if (!this.saveConfirmModel) return;
      this.saveConfirmModel.content = 'Please confirm saving and generate report before send mail';
      this.saveConfirmModel.open({ event: 'SendMail' });
    }
    else {
      this.mailModel = undefined;
      setTimeout(async () => {
        this.mailModel = new MailModel();

        this.mailTemplateType = (await this.mailTemplateTypesService.load({ filter: ["Code eq 'SaleQuote'"] }))[0];

        this.element.DocumentDataCollection.forEach((d: DocumentData) => {
          if ((d.HasPrincipal || d.HasSecondary) && this.mailModel) {
            this.mailModel.ToThirdContactInformationId.push(...d.ThirdId.ContactInformations.filter((c: ThirdContactInformation) => c.ContactTypeId?.Code == 'email' && c.UsedForQuote == true));
          }
          if (d.HasCC && this.mailModel) {
            this.mailModel.CCThirdContactInformationId.push(...d.ThirdId.ContactInformations.filter((c: ThirdContactInformation) => c.ContactTypeId?.Code == 'email' && c.UsedForQuote == true));
          }
          if (d.HasCCI && this.mailModel) {
            this.mailModel.CCIThirdContactInformationId.push(...d.ThirdId.ContactInformations.filter((c: ThirdContactInformation) => c.ContactTypeId?.Code == 'email' && c.UsedForQuote == true));
          }
        })

        this.mailModel.ReportType = this.documentType;
        this.mailModel.HeaderId = this.element.Id?.toString() ?? "0";
        this.mailModel.FileName = this.reportsService.updateFileName(this.documentType, this.element.No + ".pdf");
        if (!this.emailModal) return;
        this.emailModal.mailTemplateType = this.mailTemplateType;
        await this.emailModal.loadMailTemplateData();

        await this.emailModal?.open();
      }, 50);
    }
  }
  //-------------------------------------------------------------------------
}