<div class="row">
  <div class="col-sm-4 col-xs-12">
    <syslink-block
      [title]="'Products'"
      [titleBgColor]="'gray'"
      [titleTextColor]="'white'"
      [bordered]="true"
      [titlePadding]="2"
    >
      <syslink-select
        label="Price list"
        [store]="priceListsService.store"
        [(model)]="element.PriceListId"
      ></syslink-select>
      <syslink-input-number
        label="Hourly Rate"
        [(model)]="element.HourlyRate"
      ></syslink-input-number>
    </syslink-block>
  </div>
  <div class="col-sm-4 col-xs-12">
    <syslink-block
      [title]="'Sale invoices'"
      [titleBgColor]="'gray'"
      [titleTextColor]="'white'"
      [bordered]="true"
      [titlePadding]="2"
    >
      <syslink-select
        label="Sale invoices DocumentDelay"
        [store]="documentDelaysService.store"
        [(model)]="element.SaleInvoiceDocumentDelayId"
      ></syslink-select>
      <syslink-select
        label="Payment method"
        [store]="paymentMethodsService.store"
        [(model)]="element.PaymentMethodId"
        [filter]="['IsVisible eq true']"
      ></syslink-select>
    </syslink-block>
  </div>
</div>