import { Injectable } from '@angular/core';
import { LoadOptions } from 'devextreme/data';
import { PurchaseDocumentsService } from '../../purchase-documents/purchase-documents/purchase-documents.service';
import { PurchaseInvoice } from './purchase-invoice.model';
import { ApiService } from '../../../core/api.service';
import { ReportsService } from '../../../connectors/reports/reports.service';
import { PurchaseInvoiceHeaderStatus } from '../purchase-invoice-header-statuses/purchase-invoice-header-status.model';

@Injectable({
  providedIn: 'root'
})
export class PurchaseInvoicesService extends PurchaseDocumentsService<PurchaseInvoice, PurchaseInvoiceHeaderStatus> {
  public override url: string = 'PurchaseInvoiceHeader';

  constructor(
    public override apiService: ApiService,
    public override reportsService: ReportsService
  ) {
    super(reportsService);
  }
  public override defaultOptions: LoadOptions = {
    select: [],
    expand: [
      'CurrentStatusLink.StatusId',
      'ResponsibleUserId',
      'DocumentDelayId',
      'Lines($orderby=LineNo;$expand=TaxRateId($expand=NameTranslationId),UnitId,Discount,Children,PurchaseDocumentLineDiscountOperations,ParentId, ProductId)',
      'Statuses.StatusId',
      'DocumentDataCollection.ThirdId.ThirdContacts',
      'DocumentDataCollection.ThirdId.Addresses',
      'DocumentDataCollection.AddressId',
      'DocumentDataCollection.ThirdId.SupplierId.PurchaseInvoiceDocumentDelayId',
      'Payments.PaymentMethodPrimary',
      'Payments.PaymentStateId',
      'Payments.PaymentTypeId',
      'ThirdId.ContactInformations.ThirdId',
      'ThirdId.ContactInformations.ContactTypeId',
      'DocumentRelations'
    ]
  };

  public override async getInstance(quote?: Partial<PurchaseInvoice>): Promise<any> {

    let element = {
      Date: new Date(),
      ...quote,
    };

    element = await this.refreshResponsibleUser(element);
    element = await this.refreshThirdDeliveryTimeFromSupplier(element);
    element = await this.refreshThirdDocumentDelayFromSupplier(element);

    return element;
  }

}
