<app-customer-catalog-grid
    #customerCatalogGrid
    *ngIf="element.FilteredCustomerCatalogs"
    [permissionKey]="'sale.customerCatalogs.list'"
    [(customerCatalogs)]="element.FilteredCustomerCatalogs"
    (beforeDelete)="onDeleteBtnClicked($event)"
    (add)="onCreateBtnClicked()"
    (afterUpdate)="onBeforeUpdate($event)"
></app-customer-catalog-grid>



<syslink-modal
    #editModal
    title="Customer catalog"
    width="400px"
    (onValidate)="editModal.data.newItem==false ?undefined : onCreate(customerCatalog)"
    [validateText]="(customerCatalog.Id ? 'Save' : 'Create')"
    cancelText="Cancel"
>
    <app-customer-catalog-form [(element)]="customerCatalog"></app-customer-catalog-form>
</syslink-modal>