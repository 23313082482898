<div *ngIf="element" class="row">
    <div class="col-6">
        <div class="row">
            <div class="col-12">
                <syslink-input-text
                label="Reference"
                [(model)]="element.Reference"
                [disabled]="disabled"
                icon="fa-solid fa-paragraph"
                [maxLength]="10"
                ></syslink-input-text>
            </div>
            <div class="col-6">
                <syslink-select
                label="User"
                [store]="usersService.store"
                [disabled]="disabled"
                [(model)]="element.ResponsibleUserId"
                [select]="['Oid', 'Name']"
                ></syslink-select>
            </div>
            <div class="col-6">
                <syslink-input-date
                name="Date"
                label="Date"
                [(model)]="element.Date"
                [disabled]="disabled"
                type="date"
                format="date"
            ></syslink-input-date>
            </div>
        </div>
    </div>
</div>
