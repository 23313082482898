import { Component, ViewChild } from '@angular/core';
import { BankReconciliation } from './bank-reconciliation.model';
import { ActivatedRoute } from '@angular/router';
import { ModalComponent, PageComponent } from 'projects/libraries/syslink-components/src/public-api';
import { BankReconciliationsService } from './bank-reconciliation.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';

@Component({
  selector: 'app-bank-reconciliations',
  templateUrl: './bank-reconciliations.component.html',
  styleUrls: ['./bank-reconciliations.component.scss']
})
export class BankReconciliationsComponent extends PageComponent {
  @ViewChild('modal') modal?: ModalComponent;

  public modalData: { CodaFile: any[], Reference: string } = { CodaFile: [], Reference: "" }

  constructor(
    protected override activatedRoute: ActivatedRoute,
    private bankReconciliationsService: BankReconciliationsService,
    private ngxUiLoaderService: NgxUiLoaderService
  ) {
    super();
  }

  public addNewReconciliation() {
    this.modal?.open();
  }

  public async onValidateModalButtonClicked() {
    this.ngxUiLoaderService.start();
    this.onOpenBankReconciliation(await this.bankReconciliationsService.importBankReconciliation(this.modalData));
    this.ngxUiLoaderService.stop();
  }

  public onOpenBankReconciliation(element: BankReconciliation) {
    this.router.navigate([element.Id], { relativeTo: this.activatedRoute });
  }
}
