import { Component, Input, OnInit } from '@angular/core';
import { TabData } from '../tabs.component';

@Component({
  selector: 'syslink-tab',
  templateUrl: './tab.component.html',
  styleUrls: ['./tab.component.scss']
})
export class TabComponent implements OnInit {
  public id?: number;
  @Input() public tabData?: TabData;
  @Input() public key: string = '';
  @Input() public label: string = 'label';
  @Input() public active: boolean = false;
  @Input() public visible: boolean = true;

  ngOnInit(): void {
    if (this.tabData) {
      this.key = this.tabData.key
      this.label = this.tabData.label
    }
  }

  // @Input() public isCloseable: boolean = false;

  // @Input() public template?: any;
  // @Input() public data?: any;
}
