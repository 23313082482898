<!-- Toolbar -->
<syslink-toolbar [actions]="toolbarActions" class="me-3" [withLine]="false"></syslink-toolbar>
<div class="row">

       <syslink-block [title]="getFormattedTitle()" [translateTitle]="false">
            <app-document-statuses 
                *ngIf="element?.Statuses" 
                #documentStatuses 
                [statuses]="availableStatuses"
                [documentStatuses]="element.Statuses" 
                (statusChanged)="onDocumentStatusChanged($event)"
                [showConfirmMessageSaveDocument]="true"
            ></app-document-statuses>

            <app-bank-reconciliation-form 
                [(element)]="element"  
                [disabled]="!canEditDocument()"
            ></app-bank-reconciliation-form>
        </syslink-block>
        <br>
        <syslink-block  [title]="'Bank reconciliation lines'"  [translateTitle]="true">
           <app-bank-reconciliation-line-grid-items 
                #bankReconciliationLineGridItems
                [disabled]="!canEditDocument()"   
                [permissionKey]="'line.list'"
                [storageKey]= "'bank-reconciliation-line-items'"
                [(elements)]="element.BankReconciliationLines"
           ></app-bank-reconciliation-line-grid-items>
        </syslink-block>
    </div>

<syslink-confirm-modal
  #deleteConfirm
  title="Delete"
  content="DeleteConfirmMessage"
  (onValidate)="bankReconciliationDelete()"
  validateText="Confirm"
  cancelText="Cancel"
></syslink-confirm-modal>