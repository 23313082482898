import { Module } from "../../../base/modules/modules/module.model";
import { SubModule } from "../../../base/modules/sub-modules/sub-module.model";
import { Entity } from "../../models/entity";
import { ActionGroup } from "../action-groups/action-group.model";
import { UserGroup } from "../user-groups/user-group.model";
import { UserPermissionType } from "../user-permission-types/user-permission-type";
import { UserRole } from "../user-roles/user-role.model";
import { User } from "../users/user.model";

export class UserPermission  extends Entity {

    public Code:string="";
    public Description:string="";
    public IsActive:boolean = false;
    public Enabled:boolean = false;
    public Users?:User[];
    public UserRoles?:UserRole[];
    public UserGroups?:UserGroup[];
    public ActionGroups?:ActionGroup[];
    public Type?:UserPermissionType;
    public ModuleId?:Module;
    public SubModuleId?:SubModule;


    constructor(user?: Partial<UserPermission>) {
        super();
        Object.assign(this, user);
    }
}