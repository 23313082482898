<syslink-select 
    label="Contract" 
    [store]="saleContractsService.store" 
    [filter]="['ThirdId.id eq '+element.ThirdId.Id]"
    detailsUrl="/sales/contracts/" 
    displayKey="NameFormatted" 
    [model]="element.SaleContractId"
    (modelChange)="onChangeContract($event)"
    [disabled]="disabled"
    [canView]="true"
></syslink-select>
<div class="row">
    <div class="col-6" *ngIf="element.SaleContractId">
        <syslink-input-date
        [name]="'Start date' | translate"
        [(model)]="element.DateFrom"
        type="date"
        format="date"
        ></syslink-input-date>
    </div>
    <div class="col-6" *ngIf="element.SaleContractId">
        <syslink-input-date
        [name]="'End date' | translate"
        [(model)]="element.DateTo"
        type="date"
        format="date"
        ></syslink-input-date>
    </div>
</div>