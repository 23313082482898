import { Injectable } from '@angular/core';
import { ApiService } from '../../../core/api.service';
import { ODataService } from '../../../core/services/oData.service';
import { SaleDocumentLine } from './sale-document-line.model';

@Injectable({
  providedIn: 'root'
})
export class SaleDocumentLinesService<T extends SaleDocumentLine> extends ODataService<T> {
  public url: string = 'saleDocumentLine';
  public documentType: string = "";

  constructor(
    public api: ApiService
  ) {
    super();
  }

  // Format lines
  // ------------
  public formatLines(lines: any[]) {
    lines.forEach(line => {
      if (line.LineType == "post" || line.LineType == "text" || line.LineType == "page") {
        this.clearColumnsPost(line);
      }
      if (line.LineType == "text" || line.LineType == "page") {
        this.clearColumnsText(line);
      }
      if (line.LineType != "product" && line.LineType != "various") {
        line.ExTaxPurchasePrice = undefined;
      }
    });
    return lines;
  }

  private clearColumnsPost(line: any) { // TODO: check type
    line.Quantity = undefined;
    line.UnitId = undefined;
    line.Discount = undefined;
    line.ExTaxPurchasePrice = undefined;
    line.ExTaxSaleGrossPrice = undefined;
    line.ExTaxGrossPrice = undefined;
    line.ForcedPrice = undefined;
    line.HourlyRate = undefined;
    line.Labor = undefined;
    line.Margin = undefined;
    line.ExTaxUnitPrice = undefined;
    line.TaxAmount = undefined;
    line.TaxRateId = undefined;
    line.AccountId = undefined;
    line.TaxId = undefined;
  }

  private clearColumnsText(line: any) { // TODO: check type
    this.clearColumnsPost(line);
    line.ExTaxTotalPrice = undefined;
    line.InTaxTotalPrice = undefined;
    line.SaleDocumentLineDiscountOperations = undefined;
    line.IsPenaltyFixed = undefined;
    line.PenaltyValue = undefined;
  }


  public async createVariousLine(thirdId: number) {
    return await this.api.sendRequest('/api/SaleDocumentLine/createvariousline/' + thirdId.toString(), 'POST');
  }

  public async createProductLine(thirdId: number, productId: number) {
    return await this.api.sendRequest('/api/SaleDocumentLine/createproductline/' + thirdId.toString() + '/' + productId.toString(), 'POST');
  }

  public async ComputeLinePrice(line: SaleDocumentLine) {
    return await this.api.sendRequest('/api/SaleDocumentLine/computelineprice', 'POST', {
      ExTaxPurchasePrice: line.ExTaxPurchasePrice,
      Quantity: line.Quantity,
      ExTaxGrossPrice: line.ExTaxGrossPrice,
      ExTaxSaleGrossPrice: line.ExTaxSaleGrossPrice,
      ExTaxTotalPrice: line.ExTaxTotalPrice,
      ExTaxUnitPrice: line.ExTaxUnitPrice,
      ForcedPrice: line.ForcedPrice,
      Margin: { IsDiscountFixed: line.Margin.IsDiscountFixed, RealValue: -line.Margin.Value },
      Discount: { IsDiscountFixed: line.Discount.IsDiscountFixed, RealValue: line.Discount.Value },
      HourlyRate: line.HourlyRate,
      Labor: line.Labor,
      TaxRateValue: line.TaxRateId?.Value,
      TaxAmount: line.TaxAmount,
      InTaxTotal: line.InTaxTotalPrice
    });
  }


  public async updateParentPrice(targetParentId: number) {
    return await this.api.sendRequest('/api/SaleDocumentLine/' + targetParentId + '/updateParent/', 'POST');
  }

  // Format
  // ------
  public override format(params?: Partial<T>): T {
    var result: any = {
      ...params,
      HeaderId: params?.HeaderId?.Id,
      ParentId: params?.ParentId != null && params?.ParentId != undefined ? params?.ParentId?.Id : null,
      ProductId: params?.ProductId?.Id,
      TaxRateId: params?.TaxRateId?.Id,
      UnitId: params?.UnitId?.Id,
    };

    delete result["Margin"];
    delete result["Discount"];
    delete result["Penalty"];
    delete result["Children"];
    delete result["SaleDocumentLineDiscountOperations"];
    delete result["TimeManagementLinked"];
    delete result["InvoicedAmount"];

    if(this.documentType=='SaleCreditNote'  || this.documentType=='SaleOrder' || this.documentType=='SaleQuote' || this.documentType=='SaleContract'){
      delete result["AssignementPriceCoefId"];
    }
    return result;
  }
}
