<div class="row">
  <div class="col-sm-3 col-xs-12 ">
    <div>
      <i
        class="text-primary text-center w-100 h5 py-1 m-0 fa-solid fa-user bg-light"
        style="font-size: 5rem"
      ></i>
    </div>
    <div>
      <syslink-switch
        name="isActive"
        [(model)]="element.IsActive"
        type="box"
        [falseText]="'Blocked' | translate"
        [trueText]="'Active' | translate"
      ></syslink-switch>
    </div>
  </div>
  <div class="col-sm-6">
    <syslink-input-text
      name="code"
      label="Code"
      [(model)]="element.Code"
    ></syslink-input-text>

    <syslink-input-text
      *ngIf="!element.Id"
      name="nameText"
      label="Name"
      [(model)]="element.Name"
    ></syslink-input-text>

    <syslink-edit-translations-cell
      *ngIf="element.Id"
      class="py-2"
      name="name"
      (modelUpdate)="onTranslationsUpdate($event)"
      [model]="element.NameTranslationId?.TranslatedValue"
      (onTranslationsBtnClicked)="onTranslationsBtnClicked()"
    ></syslink-edit-translations-cell>
  </div>
</div>

<syslink-translations-modal
  #translationsCellModal
  (onValidated)="onTranslationsModalValidated($event)"
  [languages]="languagesService.languages"
></syslink-translations-modal>