import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SaleQuote } from '../sale-quotes/sale-quote.model';
import { BaseGridComponent } from 'projects/libraries/syslink-components/src/lib/grid/base-grid/base-grid.component';
import { ContextMenuItemAction } from 'projects/libraries/syslink-components/src/lib/context-menus/context-menu-item-action';
import { SyslinkColumn } from 'projects/libraries/syslink-components/src/lib/helpers/SyslinkColumn';
import { SaleQuotesService } from '../sale-quotes/sale-quotes.service';
import { DXCell } from 'projects/libraries/syslink-components/src/lib/grid/cells/dxcell';

@Component({
  selector: 'app-sale-quote-grid',
  templateUrl: './sale-quote-grid.component.html',
  styleUrls: ['./sale-quote-grid.component.scss']
})
export class SaleQuoteGridComponent extends BaseGridComponent<SaleQuote> {

  @Input() override detailsUrl: string = '/sales/quotes';
  @Input() override storageKey: string = 'sale-quotes';
  @Input() public contextMenuItems: ContextMenuItemAction[] = [];
  @Input() showPager: boolean = true;
  @Input() items?: any[];
  
  // FilterValue
  // -----------
  @Output() onFilterValueChange: EventEmitter<any[]> = new EventEmitter<any[]>();

  public columns: SyslinkColumn[] = [
    new SyslinkColumn({ field: "No", label: 'GRID.CAPTION.NO', width: 150, sortIndex: 2, sortDirection: 'desc' }),
    new SyslinkColumn({ field: "ThirdId.Fullname", label: "Customer", width: 250 }),
    new SyslinkColumn({ field: "Subject", label: "Subject" }),
    new SyslinkColumn({ field: "CurrentStatusLink.StatusId.Name", label: "Status", cellTemplate: 'block-cell', data: { getBlockLabel: this.getStatusCellLabel, getBlockClass: this.getStatusCellClass, getBlockColor: this.getStatusCellColor }, width: 120 }),
    new SyslinkColumn({ field: "Deadline", label: "SaleQuote.Validity", type: "date", visible: false, width: 150 }),
    new SyslinkColumn({ field: "ExternalReference", label: "External reference", visible: true, width: 150 }),
    new SyslinkColumn({ field: "ExTaxTotal", label: "exTaxTotal", type: "number", data: { key: 'TotalPrecision' }, cellTemplate: 'currency-cell', width: 80 }),
    new SyslinkColumn({ field: "InTaxTotal", label: "Total", type: "number", data: { key: 'TotalPrecision' }, cellTemplate: 'currency-cell', width: 80 }),
    new SyslinkColumn({ field: "ResponsibleUserId.Fullname", label: "Responsible", width: 120 }),
    new SyslinkColumn({ field: "Date", label: "Date", type: "date", visible: false, width: 150 }),
    new SyslinkColumn({ field: "CurrentStatusLink.StatusId.Sequence", label: 'status order', sortIndex: 1, sortDirection: 'asc', filterable: false, headerFilterable: false, width: 0, visible: false })
  ];

  @Input() public override expand?: string | string[] = ['CurrentStatusLink.StatusId'];
  @Input() public override select?:  string[] = ['Id', 'No', 'ThirdId.Fullname', 'Subject', 'Deadline', 'ExternalReference', 'ExTaxTotal', 'InTaxTotal', 'ResponsibleUserId.Fullname', 'Date','FormattedStructuredCommunication'];
 
  constructor(
    public saleQuotesService: SaleQuotesService
  ) {
    super();
  }

  // status
  // ------
  getStatusCellLabel(cell: DXCell) {
    return cell.data.CurrentStatusLink.StatusId.Name;
  }

  getStatusCellClass(cell: DXCell) {
    let statusClass = 'text-white text-center'

    const statusCode = (<string>cell.data.CurrentStatusLink.StatusId.Code).toLowerCase().split('.')[1];
    if (statusCode == undefined) return;
    statusClass += ' ' + statusCode;
    return statusClass;
  }

  getStatusCellColor(cell: DXCell) {
    return cell.data.CurrentStatusLink.StatusId.Color;
  }
  // ------------------------------------------------

  public reload() {
    this.grid?.grid?.instance.refresh();
  }
}


