<app-document-relations-v2
    #documentRelationComponent
    [elements]="documentsRelationData ?? []"
    (onAddClicked)="disabled?null : onAddClicked($event)"
    (onDeleteClicked)="disabled?null : onDeleteClicked($event)"
></app-document-relations-v2>

<syslink-modal
    #modal
    [title]="'Select' | translate"
    width="400"
    (onValidate)="onValidateSelectModal(modal.data)"
>
    <syslink-select
        #modalSelect
        *ngIf="modal.data.type && modal.data.service"
        [label]="modal.data.type"
        [store]="modal.data.service"
        [filter]="modal.data.filter??''"
        [select]="['id', 'NameFormatted']"
        [displayKey]="'NameFormatted'"
        [(model)]="modal.data.element"
    ></syslink-select>
</syslink-modal>