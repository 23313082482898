import { Component, Input } from '@angular/core';
import { BootstrapBgColor } from '../helpers/BootstrapBgColor';
import { BootstrapTextColor } from '../helpers/BootstrapTextColor';

@Component({
  selector: 'syslink-title',
  templateUrl: './title.component.html',
  styleUrls: ['./title.component.scss']
})
export class TitleComponent {
  @Input() public text: string = 'Title';
  @Input() public shouldTranslate: boolean = true;
  @Input() public size: 1 | 2 | 3 | 4 | 5 | 6 = 3;
  @Input() public padding: 1 | 2 | 3 = 3;

  @Input() public bgColor: BootstrapBgColor = 'secondary';
  @Input() public textColor: BootstrapTextColor = 'white';

}
