import { BasicEntity } from "../../../core/models/basic-entity";

export enum TaskBillingTypeCode {
    NotBillable = 'NotBillable',
    NotFixedPrice = 'NotFixedPrice',
    FixedPrice = 'FixedPrice'
}
export class TaskBillingType extends BasicEntity {
    
    constructor(entity?: TaskBillingType) {
        super();
        Object.assign(this, entity);
    }
}
