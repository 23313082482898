<div *ngFor="let group of getGroups()" class="mb-4">
    <ng-container *ngIf="getItemsByGroup(group).length>0">
        <div class="fw-bolder pb-1">{{group | translate}}</div>
        <div style="display: grid; grid-template-columns: repeat(3,1fr); gap: 0.5rem;">
            <ng-container *ngFor="let item of getItemsByGroup(group)">
                <div *ngIf="item.visible && authService.hasPermission(this.basePermissionKey + '.' + item.key + '.block.view')"
                    class="bg-accent text-center text-white" (click)="onItemClick(item.key)" role="button"
                    style="user-select: none;">
                    <app-document-relations-block [title]="item.title" [value]="item.value">
                    </app-document-relations-block>
                </div>
            </ng-container>
        </div>
    </ng-container>
</div>


<syslink-modal #modalSaleQuote *ngIf="modalData[0].loaded" [title]="modalData[0].label" [width]="'90%'"
    (loaded)="this.modalSaleQuote.open()" [noFooter]="true">
    <app-sale-quote-grid #saleQuoteGridComponent *ngIf="modalSaleQuote.isShown" [permissionKey]="'saleQuotes.list'"
        [filter]="modalData[0].data?.filter ?? ''" (add)="onAddClicked.emit({type:modalData[0].key})"
        (delete)="onDeleteClicked.emit({type:modalData[0].key, event: $event})"
        [columns]="modalData[0].data.columns??saleQuoteGridComponent.columns"></app-sale-quote-grid>
</syslink-modal>

<syslink-modal #modalSaleOrder *ngIf="modalData[1].loaded" [title]="modalData[1].label" [width]="'90%'"
    (loaded)="this.modalSaleOrder.open()" [noFooter]="true">
    <app-sale-order-grid #saleOrderGridComponent *ngIf="modalSaleOrder.isShown" [permissionKey]="'saleOrders.list'"
        [filter]="modalData[1].data?.filter ?? ''" (add)="onAddClicked.emit({type:modalData[1].key})"
        (delete)="onDeleteClicked.emit({type:modalData[1].key, event: $event})"
        [columns]="modalData[1].data.columns??saleOrderGridComponent.columns"></app-sale-order-grid>
</syslink-modal>

<syslink-modal #modalSaleInvoice *ngIf="modalData[2].loaded" [title]="modalData[2].label" [width]="'90%'"
    (loaded)="this.modalSaleInvoice.open()" [noFooter]="true">
    <app-sale-invoice-grid #saleInvoiceGridComponent *ngIf="modalSaleInvoice.isShown"
        [permissionKey]="'saleInvoices.list'" [filter]="modalData[2].data?.filter ?? ''"
        (add)="onAddClicked.emit({type:modalData[2].key})"
        (delete)="onDeleteClicked.emit({type:modalData[2].key, event: $event})"
        [columns]="modalData[2].data.columns??saleInvoiceGridComponent.columns"></app-sale-invoice-grid>
</syslink-modal>

<syslink-modal #modalSaleCreditNote *ngIf="modalData[3].loaded" [title]="modalData[3].label" [width]="'90%'"
    (loaded)="this.modalSaleCreditNote.open()" [noFooter]="true">
    <app-sale-credit-note-grid #saleCreditNoteGridComponent storageKey="saleQuoteDocumentRelationV2"
        *ngIf="modalSaleCreditNote.isShown" [permissionKey]="'saleCreditNotes.list'"
        [filter]="modalData[3].data?.filter ?? ''" (add)="onAddClicked.emit({type:modalData[3].key})"
        (delete)="onDeleteClicked.emit({type:modalData[3].key, event: $event})"
        [columns]="modalData[3].data.columns??saleCreditNoteGridComponent.columns"></app-sale-credit-note-grid>
</syslink-modal>

<syslink-modal #modalSaleContract *ngIf="modalData[4].loaded" [title]="modalData[4].label" [width]="'90%'"
    (loaded)="this.modalSaleContract.open()" [noFooter]="true">
    <app-sale-contract-grid #saleContractGridComponent *ngIf="modalSaleContract.isShown"
        [permissionKey]="'saleContracts.list'" [filter]="modalData[4].data?.filter ?? ''"
        (add)="onAddClicked.emit({type:modalData[4].key})"
        (delete)="onDeleteClicked.emit({type:modalData[4].key, event: $event})"
        [columns]="modalData[4].data.columns??saleContractGridComponent.columns"></app-sale-contract-grid>
</syslink-modal>

<syslink-modal #modalPurchaseOrder *ngIf="modalData[5].loaded" [title]="modalData[5].label" [width]="'90%'"
    (loaded)="this.modalPurchaseOrder.open()" [noFooter]="true">
    <app-purchase-order-grid #purchaseOrderGridComponent *ngIf="modalPurchaseOrder.isShown"
        [permissionKey]="'purchaseOrders.list'" [filter]="modalData[5].data?.filter ?? ''"
        (add)="onAddClicked.emit({type:modalData[5].key})"
        (delete)="onDeleteClicked.emit({type:modalData[5].key, event: $event})"
        [columns]="modalData[5].data.columns??purchaseOrderGridComponent.columns"></app-purchase-order-grid>
</syslink-modal>

<syslink-modal #modalPurchaseInvoice *ngIf="modalData[6].loaded" [title]="modalData[6].label" [width]="'90%'"
    (loaded)="this.modalPurchaseInvoice.open()" [noFooter]="true">
    <app-purchase-invoice-grid #purchaseInvoiceGridComponent *ngIf="modalPurchaseInvoice.isShown"
        [permissionKey]="'purchaseInvoices.list'" [filter]="modalData[6].data?.filter ?? ''"
        (add)="onAddClicked.emit({type:modalData[6].key})"
        (delete)="onDeleteClicked.emit({type:modalData[6].key, event: $event})"
        [columns]="modalData[6].data.columns??purchaseInvoiceGridComponent.columns"></app-purchase-invoice-grid>
</syslink-modal>

<syslink-modal #modalPurchaseCreditNote *ngIf="modalData[7].loaded" [title]="modalData[7].label" [width]="'90%'"
    (loaded)="this.modalPurchaseCreditNote.open()" [noFooter]="true">
    <app-purchase-credit-note-grid #purchaseCreditNoteGridComponent *ngIf="modalPurchaseCreditNote.isShown"
        [permissionKey]="'purchaseCreditNotes.list'" [filter]="modalData[7].data?.filter ?? ''"
        (add)="onAddClicked.emit({type:modalData[7].key})"
        (delete)="onDeleteClicked.emit({type:modalData[7].key, event: $event})"
        [columns]="modalData[7].data.columns??purchaseCreditNoteGridComponent.columns"></app-purchase-credit-note-grid>
</syslink-modal>

<syslink-modal #modalStockMoveCustomerOutgoing *ngIf="modalData[8].loaded" [title]="modalData[8].label" [width]="'90%'"
    (loaded)="this.modalStockMoveCustomerOutgoing.open()" [noFooter]="true">
    <app-stock-move-outgoing-grid #stockMoveOutgoingGridComponent *ngIf="modalStockMoveCustomerOutgoing.isShown"
        [permissionKey]="'stockMoveCustomerOutgoings.list'" [filter]="modalData[8].data?.filter ?? ''"
        (add)="onAddClicked.emit({type:modalData[8].key})"
        (delete)="onDeleteClicked.emit({type:modalData[8].key, event: $event})"
        [columns]="modalData[8].data.columns??stockMoveOutgoingGridComponent.columns"></app-stock-move-outgoing-grid>
</syslink-modal>

<syslink-modal #modalStockMoveCustomerIncoming *ngIf="modalData[9].loaded" [title]="modalData[9].label" [width]="'90%'"
    (loaded)="this.modalStockMoveCustomerIncoming.open()" [noFooter]="true">
    <app-stock-move-incoming-grid #stockMoveIncomingGridComponent *ngIf="modalStockMoveCustomerIncoming.isShown"
        [permissionKey]="'stockMoveCustomerIncomings.list'" [filter]="modalData[9].data?.filter ?? ''"
        (add)="onAddClicked.emit({type:modalData[9].key})"
        (delete)="onDeleteClicked.emit({type:modalData[9].key, event: $event})"
        [columns]="modalData[9].data.columns??stockMoveIncomingGridComponent.columns"></app-stock-move-incoming-grid>
</syslink-modal>

<syslink-modal #StockMoveSupplierOutgoing *ngIf="modalData[10].loaded" [title]="modalData[10].label" [width]="'90%'"
    (loaded)="this.StockMoveSupplierOutgoing.open()" [noFooter]="true">
    <app-stock-move-outgoing-grid #stockMoveOutgoingGridComponent *ngIf="StockMoveSupplierOutgoing.isShown"
        [permissionKey]="'stockMoveSupplierOutgoings.list'" [filter]="modalData[10].data?.filter ?? ''"
        (add)="onAddClicked.emit({type:modalData[10].key})"
        (delete)="onDeleteClicked.emit({type:modalData[10].key, event: $event})"
        [columns]="modalData[10].data.columns??stockMoveOutgoingGridComponent.columns"></app-stock-move-outgoing-grid>
</syslink-modal>

<syslink-modal #StockMoveSupplierIncoming *ngIf="modalData[11].loaded" [title]="modalData[11].label" [width]="'90%'"
    (loaded)="this.StockMoveSupplierIncoming.open()" [noFooter]="true">
    <app-stock-move-incoming-grid #stockMoveIncomingGridComponent *ngIf="StockMoveSupplierIncoming.isShown"
        [permissionKey]="'stockMoveSupplierIncomings.list'" [filter]="modalData[11].data?.filter ?? ''"
        (add)="onAddClicked.emit({type:modalData[11].key})"
        (delete)="onDeleteClicked.emit({type:modalData[11].key, event: $event})"
        [columns]="modalData[11].data.columns??stockMoveIncomingGridComponent.columns"></app-stock-move-incoming-grid>
</syslink-modal>

<syslink-modal #TimeManagementTask *ngIf="modalData[12].loaded" [title]="modalData[12].label" [width]="'90%'"
    (loaded)="this.TimeManagementTask.open()" [noFooter]="true">
    <app-task-grid #taskGridComponent *ngIf="TimeManagementTask.isShown" [permissionKey]="'tasks.list'"
        [filter]="modalData[12].data?.filter ?? ''" (add)="onAddClicked.emit({type:modalData[12].key})"
        (delete)="onDeleteClicked.emit({type:modalData[12].key, event: $event})"
        [columns]="modalData[12].data.columns??taskGridComponent.columns"
        (rowDoubleClicked)="onDoubleClicked.emit({type:modalData[12].key, task:$event})"></app-task-grid>
</syslink-modal>

<syslink-modal #TimeManagementWorkTime *ngIf="modalData[13].loaded" [title]="modalData[13].label" [width]="'90%'"
    (loaded)="this.TimeManagementWorkTime.open()" [noFooter]="true">
    <app-work-time-grid #workTimeGridComponent *ngIf="TimeManagementWorkTime.isShown" [permissionKey]="'workTimes.list'"
        [filter]="modalData[13].data?.filter ?? ''" (add)="onAddClicked.emit({type:modalData[13].key})"
        (delete)="onDeleteClicked.emit({type:modalData[13].key, event: $event})"
        [columns]="modalData[13].data.columns??workTimeGridComponent.columns"
        (rowDoubleClicked)="onDoubleClicked.emit({type:modalData[13].key, worktime:$event})"></app-work-time-grid>
</syslink-modal>