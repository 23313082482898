import { Component } from '@angular/core';

@Component({
  selector: 'app-sequence-configurations',
  templateUrl: './sequence-configurations.component.html',
  styleUrls: ['./sequence-configurations.component.scss']
})
export class SequenceConfigurationsComponent {

}
