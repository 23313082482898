<dx-popup
  [hideOnOutsideClick]="hideOnOutside"
  [dragEnabled]="drag"
  [fullScreen]="fullScreen"
  [position]="position"
  [resizeEnabled]="resize"
  [showCloseButton]="closeBtn"
  [hoverStateEnabled]="false"
  [focusStateEnabled]="false"
  [title]="title | translate"
  [(visible)]="visible"
  [width]="width"
  [height]="height"
  [maxHeight]="maxHeight"
  [shading]="shading.length ? true : false"
  [shadingColor]="shading"
  contentTemplate="content"
  [deferRendering]="deferRendering"
  (onHiding)="onCancelBtnClicked()"
  (onShown)="isShown = true"
  [toolbarItems]="toolbarItems"
>
<!-- TODO FIX: -->
<!-- Problème dans la modale de facturations de prestation -->
<!-- [style.height]="'100%'" -->
  <div
    class="modal-body"
  >
    <ng-content></ng-content>
  </div>
  <!-- <ng-container *ngIf="!noFooter">
        <dxi-toolbar-item
            widget="dxButton"
            toolbar="bottom"
            location="before"
            [options]="cancelOption">
        </dxi-toolbar-item>
        <dxi-toolbar-item
            *ngIf="secondaryButton"
            widget="dxButton"
            toolbar="bottom"
            location="center"
            [options]="secondaryOption">
        </dxi-toolbar-item>
        <dxi-toolbar-item
            #validateButton
            widget="dxButton"
            toolbar="bottom"
            location="after"
            [options]="validateOption">
        </dxi-toolbar-item>
    </ng-container> -->
</dx-popup>
