import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ThirdContactInformation } from '../third-contact-informations/third-contact-information.model';
import { ThirdContactTypesService } from '../third-contact-types/third-contact-types.service';
import { ThirdContactQualifiersService } from '../third-contact-qualifiers/third-contact-qualifiers.service';
import { ThirdContactType } from '../third-contact-types/third-contact-type.model';
import { ThirdAddress } from '../../third-addresses/third-addresses/third-address.model';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-third-contact-form',
  templateUrl: './third-contact-form.component.html',
  styleUrls: ['./third-contact-form.component.scss']
})
export class ThirdContactFormComponent {
  @Input() element: ThirdContactInformation = new ThirdContactInformation;
  @Output() elementChange: EventEmitter<ThirdContactInformation> = new EventEmitter<ThirdContactInformation>();
  @Input() public readOnly: boolean = false;

  constructor(
    public thirdContactTypesService: ThirdContactTypesService,
    public thirdContactQualifiersService: ThirdContactQualifiersService,
    public translateService: TranslateService
  ) { }

  public onChangeContactType(e: ThirdContactType) {
    if (!e || e.Code != 'Address') return;
    if (!this.element.AddressId) {
      this.element.AddressId = new ThirdAddress();
    }
  }

  public focusInValue(e: string) {
    if (e == this.translateService.instant("New value")) {
      this.element.Value = "";
    }
  }
}
