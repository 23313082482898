import { Component, Input } from '@angular/core';
import { SyslinkColumn } from 'projects/libraries/syslink-components/src/lib/helpers/SyslinkColumn';
import { NotificationsService } from '../../../../../../libraries/syslink-components/src/public-api';
import { CustomersService } from '../../../thirds/customers/customers/customers.service';
import { PriceListsService } from '../../../thirds/customers/price-lists/price-lists.service';
import { ProductCategoriesService } from '../../product-categories/product-category.service';
import { ProductsService } from '../../products/product.service';
import { CustomerCatalogDiscountOperationService } from '../customer-catalog-discount-operation.service';
import { CustomerCatalogDiscountOperations } from '../customer-catalog-discount-operations/customer-catalog-discount-operations';
import { CustomerCatalog } from '../customer-catalog.model';
import { CustomerCatalogsService } from '../customer-catalog.service';
import { InitNewRowEvent as InitNewRowEventGrid } from 'devextreme/ui/data_grid';
import { InitNewRowEvent as InitNewRowEventTree } from 'devextreme/ui/tree_list';
import { BaseGridComponent } from 'projects/libraries/syslink-components/src/lib/grid/base-grid/base-grid.component';
@Component({
  selector: 'app-customer-catalog-general-form',
  templateUrl: './customer-catalog-general-form.component.html',
  styleUrls: ['./customer-catalog-general-form.component.scss']
})
export class CustomerCatalogGeneralFormComponent extends BaseGridComponent<CustomerCatalog> {

  @Input() storageKey: string = 'customer-catalog-grid-basic';

  public columns: SyslinkColumn[] = [
    new SyslinkColumn({ field: 'ProductId', label: 'Products', cellTemplate: 'select-value', editCellTemplate: 'select-cell', data: { service: this.productsService, displayKey: 'Name' } }),
    new SyslinkColumn({ field: 'ProductCategoryId', label: 'Products Categories', cellTemplate: 'select-value', editCellTemplate: 'select-cell', data: { service: this.productCategoriesService, displayKey: 'Name' } }),
    new SyslinkColumn({ field: 'CustomerId', label: 'Customer', cellTemplate: 'select-value', editCellTemplate: 'select-cell', data: { service: this.customersService, expand: 'ThirdId', displayKey: 'ThirdId.Fullname' } }),
    new SyslinkColumn({ field: 'PriceListId', label: 'Price list', cellTemplate: 'select-value', editCellTemplate: 'select-cell', data: { service: this.priceListsService, displayKey: 'Name' } }),
    new SyslinkColumn({ field: 'MarginId', label: 'Margin', cellTemplate: 'object-discount-cell', editCellTemplate: 'edit-object-discount-cell', data: { discountTypeField: 'IsDiscountFixed' }, width: 160 }),
    new SyslinkColumn({ field: 'DiscountId', label: 'Discount', cellTemplate: 'object-discount-cell', editCellTemplate: 'edit-object-discount-cell', data: { discountTypeField: 'IsDiscountFixed' }, width: 160 }),
    new SyslinkColumn({ field: 'PenaltyId', label: 'Penalty', cellTemplate: 'object-discount-cell', editCellTemplate: 'edit-object-discount-cell', data: { discountTypeField: 'IsDiscountFixed' }, width: 160 }),
  ];

  public override expand: Array<string> = [
    'ProductId',
    'ProductCategoryId',
    'CustomerId.ThirdId',
    'PriceListId',
    'MarginId',
    'DiscountId',
    'PenaltyId'
  ];

  public tempDicountOperation?: {
    MarginId: CustomerCatalogDiscountOperations | undefined,
    DiscountId: CustomerCatalogDiscountOperations | undefined,
    PenaltyId: CustomerCatalogDiscountOperations | undefined
  };

  constructor(
    private customersService: CustomersService,
    private priceListsService: PriceListsService,
    private productsService: ProductsService,
    private productCategoriesService: ProductCategoriesService,
    public customerCatalogsService: CustomerCatalogsService,
    private customerCatalogDiscountOperationService: CustomerCatalogDiscountOperationService
  ) {
    super();
  }

  // New CustomerCatalog
  // -------------------
  public onNewElement(event: InitNewRowEventGrid<CustomerCatalog> | InitNewRowEventTree<CustomerCatalog>) {
    event.data.DiscountId = new CustomerCatalogDiscountOperations({ Value: 0, IsDiscountFixed: false });
    event.data.MarginId = new CustomerCatalogDiscountOperations({ Value: 0, IsDiscountFixed: false });
    event.data.PenaltyId = new CustomerCatalogDiscountOperations({ Value: 0, IsDiscountFixed: false });
  }

  public async onBeforeInserting(event: any) {
    event.cancel = new Promise<void>(async (resolve, reject) => {
      const customerCatalog: CustomerCatalog = event.data;

      // Check if not product & product category set at the same time
      if (!customerCatalog.ProductId && !customerCatalog.ProductCategoryId && !customerCatalog.CustomerId && !customerCatalog.PriceListId) {
        NotificationsService.sendErrorMessage('Cannot create a customer catalog liked to anything');
        reject();
      }

      // Check if not product & product category set at the same time
      if (customerCatalog.ProductId && customerCatalog.ProductCategoryId) {
        NotificationsService.sendErrorMessage('Cannot create a customer catalog with product and product category set at same time');
        reject();
      }

      // Check if not customer & price list set at the same time
      if (customerCatalog.CustomerId && customerCatalog.PriceListId) {
        NotificationsService.sendErrorMessage('Cannot create customer catalog with customer and price list set at same time');
        reject();
      }

      // Check if already exist
      const filter: Array<any> = []
      if (customerCatalog.ProductId?.Id) filter.push(['ProductId.Id', '=', customerCatalog.ProductId?.Id]);
      if (customerCatalog.ProductCategoryId?.Id) filter.push(['ProductCategoryId.Id', '=', customerCatalog.ProductCategoryId?.Id]);
      if (customerCatalog.CustomerId?.Id) filter.push(['CustomerId.Id', '=', customerCatalog.CustomerId?.Id]);
      if (customerCatalog.PriceListId?.Id) filter.push(['PriceListId.Id', '=', customerCatalog.PriceListId?.Id]);

      if ((await this.customerCatalogsService.load({ filter, select: ['Id'] })).length) {
        NotificationsService.sendErrorMessage('This customer catalog already exist');
        reject();
      }

      this.tempDicountOperation = {
        MarginId: customerCatalog.MarginId,
        DiscountId: customerCatalog.DiscountId,
        PenaltyId: customerCatalog.PenaltyId
      }

      resolve();
    });
  }

  public async onAfterInserting(event: any): Promise<void> {
    const customerCatalog: CustomerCatalog = {
      ...event.data,
      ...this.tempDicountOperation
    };

    this.tempDicountOperation = undefined;

    const discount: any = customerCatalog.DiscountId;
    if (discount) {
      discount.CustomerCatalogId = customerCatalog.Id;
      discount.DiscountOperationId = 1;
      discount.Order = 2;
      await this.customerCatalogDiscountOperationService.insert(discount);
    }

    const margin: any = customerCatalog.MarginId;
    if (margin) {
      margin.CustomerCatalogId = customerCatalog.Id;
      margin.DiscountOperationId = 2;
      margin.Order = 1;
      await this.customerCatalogDiscountOperationService.insert(margin);
    }

    const penalty: any = customerCatalog.PenaltyId
    if (penalty) {
      penalty.CustomerCatalogId = customerCatalog.Id;
      penalty.DiscountOperationId = 3;
      penalty.Order = 3;
      await this.customerCatalogDiscountOperationService.insert(penalty);
    }

    this.grid?.grid?.instance.refresh();
  }

  // Update CustomerCatalog
  // ----------------------
  public async onBeforeUpdate(event: any) {
    event.cancel = new Promise<void>(async (resolve, reject) => {
      const oldCustomerCatalog: Partial<CustomerCatalog> = JSON.parse(JSON.stringify(event.oldData));
      const newCustomerCatalog: Partial<CustomerCatalog> = JSON.parse(JSON.stringify(event.newData));

      if (oldCustomerCatalog.Id != 1) {
        if (newCustomerCatalog.ProductId && oldCustomerCatalog.ProductCategoryId) {
          (<any>oldCustomerCatalog.ProductCategoryId) = null;
          (<any>newCustomerCatalog.ProductCategoryId) = null;
        }
        if (newCustomerCatalog.ProductCategoryId && oldCustomerCatalog.ProductId) {
          (<any>oldCustomerCatalog.ProductId) = null;
          (<any>newCustomerCatalog.ProductId) = null;
        }
        if (newCustomerCatalog.CustomerId && oldCustomerCatalog.PriceListId) {
          (<any>oldCustomerCatalog.PriceListId) = null;
          (<any>newCustomerCatalog.PriceListId) = null;
        }
        if (newCustomerCatalog.PriceListId && oldCustomerCatalog.CustomerId) {
          (<any>oldCustomerCatalog.CustomerId) = null;
          (<any>newCustomerCatalog.CustomerId) = null;
        }

        // Check if already exist
        const customerCatalog: CustomerCatalog = {
          ...oldCustomerCatalog,
          ...newCustomerCatalog
        };
        const filter: Array<any> = [
          ['ProductId.Id', '=', customerCatalog.ProductId?.Id || null],
          ['ProductCategoryId.Id', '=', customerCatalog.ProductCategoryId?.Id || null],
          ['CustomerId.Id', '=', customerCatalog.CustomerId?.Id || null],
          ['PriceListId.Id', '=', customerCatalog.PriceListId?.Id || null]
        ];

        const sameCustomerCatalog: CustomerCatalog[] = await this.customerCatalogsService.load({ filter, select: ['Id'] });
        if (sameCustomerCatalog.length && sameCustomerCatalog[0].Id !== customerCatalog.Id) {
          NotificationsService.sendErrorMessage('This customer catalog already exist');
          reject();
        }
      }

      event.newData = newCustomerCatalog

      // Customer catalog discount operation
      // -----------------------------------
      if (newCustomerCatalog.DiscountId?.Id) {
        await this.customerCatalogDiscountOperationService.update(newCustomerCatalog.DiscountId?.Id, newCustomerCatalog.DiscountId);
      }
      if (newCustomerCatalog.MarginId?.Id) {
        await this.customerCatalogDiscountOperationService.update(newCustomerCatalog.MarginId?.Id, newCustomerCatalog.MarginId);
      }
      if (newCustomerCatalog.PenaltyId?.Id) {
        await this.customerCatalogDiscountOperationService.update(newCustomerCatalog.PenaltyId?.Id, newCustomerCatalog.PenaltyId);
      }

      // Check if customer catalog Id = 1
      // --------------------------------
      if (oldCustomerCatalog.Id == 1 && newCustomerCatalog.DiscountId == undefined && newCustomerCatalog.MarginId == undefined && newCustomerCatalog.PenaltyId == undefined) {
        NotificationsService.sendErrorMessage("You can't update this line");
        this.grid?.grid?.instance.cancelEditData();
        reject();
      }
      else {
        delete newCustomerCatalog.DiscountId;
        delete newCustomerCatalog.MarginId;
        delete newCustomerCatalog.PenaltyId;
      }
      resolve();
    });
  }

  public onBeforeDelete(e: any) {
    if (e.key == 1) {
      NotificationsService.sendErrorMessage("You can't delete this line");
      e.cancel = true;
    }
  }
}
