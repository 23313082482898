<div class="mt-2">
    <div> {{getName()}}</div>
    <div *ngIf="isProfessional && (getSecondName()!='')"> {{getSecondName()}}</div>
    <div> {{getAddress1()}}</div>
    <div> {{getAddress2()}}</div>
    <div *ngIf="isProfessional && (getVat()!='')"> {{getVat()}}</div>
</div>

<div class="mt-2" *ngIf="showDeliveryAddress && getDeliveryAddress2()!=''">
{{'Delivery address :'| translate}}
<div> {{getDeliveryAddress1()}}</div>
<div> {{getDeliveryAddress2()}}</div>
</div>