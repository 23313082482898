import { Entity } from "../../core/models/entity";
import { SaleDocument } from "../../sales/sale-documents/sale-documents/sale-document.model";
import { Third } from "../../thirds/thirds/third.model";
import { AccountJournal } from "../account-journals/account-journal.model";
import { AccountMoveLine } from "../account-move-lines/account-move-line.model";
import { AccountPeriod } from "../account-periods/account-period.model";
import { AccountMoveHeaderStatus } from "./account-move-header-status/account-move-header-status.model";

export class AccountMove extends Entity {
    public No?:string;
    public AccountJournalId?:AccountJournal;
    public AccountPeriodId?:AccountPeriod;
    public Date?:Date;
    public ThirdId?:Third;
    public DocumentId?:SaleDocument;

    public CurrentStatusLink? : AccountMoveHeaderStatus;
    public Statuses : any[]= [];

    public AccountMoveLines:AccountMoveLine[]= [];

    constructor(entity?: Partial<AccountMove>) {
        super();
        Object.assign(this, entity);
    }
}
