import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Third } from '../third.model';
import { LanguagesService } from '../../../base/languages/languages.service';
import { CountriesService } from '../../../base/addresses/countries/countries.service';
import { SubjugationCategoriesService } from '../../../accountings/subjugation-categories/subjugation-category.service';

@Component({
  selector: 'app-third-reference-form',
  templateUrl: './third-reference-form.component.html',
  styleUrls: ['./third-reference-form.component.scss']
})
export class ThirdReferenceFormComponent {
  @Input() public element: Third = new Third();
  @Output() public elementChange: EventEmitter<Third> = new EventEmitter<Third>();

  constructor(
    public languagesService: LanguagesService,
  ) { }

  public onBeforeAddBankAccount(e: any) {
    e.data.ThirdId = this.element.Id;
  }


}
