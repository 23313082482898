<div
    *ngIf="element.Purchasable && element.DefaultSupplierCatalogId"
    class=" d-flex justify-content-between align-items-center"
>
    <div class="col-5">
        <label>{{ "Purchase price excl. VAT" | translate }}</label>
    </div>
    <div class="col-6 product-header-price-box text-white">
        <div
            class="border py-2 text-center m-0"
            [class.bg-success]="
    getPurchasePriceStatus() === 'best' &&
    element.DefaultSupplierCatalogId.IsFavorite
  "
          [class.bg-warning]="
     getPurchasePriceStatus() === 'middle' &&
    element.DefaultSupplierCatalogId.IsFavorite
  "
          [class.bg-danger]="
     getPurchasePriceStatus() === 'worst' &&
    element.DefaultSupplierCatalogId.IsFavorite
  "
          [class.text-gray]="
    ( getPurchasePriceStatus() ==='' &&
      element.DefaultSupplierCatalogId.IsFavorite) ||
    !element.DefaultSupplierCatalogId.IsFavorite
  "
        >
            {{
            element.DefaultSupplierCatalogId.ExTaxNetPrice
            | syslinkCurrency
            : "TotalPrecision"
            : ""
            }}
            {{ element.DefaultSupplierCatalogId.CurrencyId?.Symbol }}
        </div>
        <div
            class="border py-2 text-center m-0"
            [class.bg-success]="
    getPurchasePriceStatus() === 'best' &&
    element.DefaultSupplierCatalogId.IsFavorite
  "
          [class.bg-warning]="
     getPurchasePriceStatus() === 'middle' &&
    element.DefaultSupplierCatalogId.IsFavorite
  "
          [class.bg-danger]="
     getPurchasePriceStatus() === 'worst' &&
    element.DefaultSupplierCatalogId.IsFavorite
  "
          [class.text-gray]="
    ( getPurchasePriceStatus() ==='' &&
      element.DefaultSupplierCatalogId.IsFavorite) ||
    !element.DefaultSupplierCatalogId.IsFavorite
  "
        >
            {{ element.DefaultSupplierCatalogId.UnitId?.Name }}
        </div>
    </div>

    <div class="col-1">
        <span class="d-flex justify-content-center h5 m-0 align-items-center"><i class="fa-{{
        element.DefaultSupplierCatalogId.IsFavorite
          ? 'solid'
          : 'regular'
      }} fa-star text-center text-primary"></i></span>
    </div>
</div>

<div
    *ngIf="element.Sellable && element.SalePrice !== undefined"
    class="mt-2 d-flex justify-content-between align-items-center"
>
    <div class="col-5">
        <label>{{ "Selling price excl. VAT" | translate }}</label>
    </div>
    <div class="col-6 product-header-price-box">
        <div class="py-2 text-white text-center bg-primary m-0">
            {{
            element.SalePrice
            | syslinkCurrency
            : "TotalPrecision"
            : ""
            }}
            {{ element.CurrencyId.Symbol }}
        </div>
        <div class="py-2 text-white text-center bg-primary m-0">
            {{ element.DefaultUnitId.Name }}
        </div>
    </div>
    <div class="col-1"></div>
</div>

<div
    *ngIf="element.Stockable"
    class="mt-2 d-flex align-items-center"
>
    <div class="col-5">
        <label>{{ "Stock" | translate }}</label>
    </div>
    <div class="col-6 product-header-price-box">
        <div
            *ngIf="!authService.hasPermission('root-stock-moves')"
            class="py-2 text-white text-center bg-black m-0"
        >
            {{ element.Stock }}
        </div>
        <div
            *ngIf="authService.hasPermission('root-stock-moves')"
            class="py-2 text-white text-center bg-black m-0"
        >
            {{ element.StockMove }}
        </div>
        <div class="py-2 text-white text-center bg-black m-0">Principal</div>
    </div>
    <div class="col-1"></div>
</div>