import { Location } from '@angular/common';
import {
  AfterViewInit,
  Component,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  ViewChildren,
} from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { BreadcrumbItem } from '../../breadcrumbs/breadcrumbs.component';
import { BreadcrumbsService } from '../../breadcrumbs/breadcrumbs.service';
import { AppInjectorService } from '../../services/app-injector.service';
import { ComponentContainerDirective } from '../../component-container/component-container.directive';
import { ComponentContainerService } from '../../component-container/component-container.service';
import { goToUrl } from '../tools';
import { SyslinkToolbarAction } from '../../toolbar/toolbar.component';
import { AuthService } from 'projects/erp-app/src/app/core/auth/auth.service';
import { UsersService } from 'projects/erp-app/src/app/core/auth/users/users.service';

@Component({
  selector: 'syslink-page',
  templateUrl: './page.component.html',
  styleUrls: ['./page.component.scss'],
})
export class PageComponent implements OnDestroy, OnInit, AfterViewInit {
  @Input() basePermissionKey?: string;
  @Input() newBasePermissionKey?: string;

  public toolbarActions: SyslinkToolbarAction[] = [];
  public navigationSubscription: Subscription;

  protected breadcrumbsService: BreadcrumbsService;
  protected router: Router;
  protected location: Location;
  protected activatedRoute: ActivatedRoute;
  protected translateService: TranslateService;
  protected authService: AuthService;
  protected usersService: UsersService;
  protected componentContainerService: ComponentContainerService;
  @ViewChildren(ComponentContainerDirective)
  componentContainers?: ComponentContainerDirective[];

  constructor() {
    this.breadcrumbsService =
      AppInjectorService.injector.get(BreadcrumbsService);
    this.router = AppInjectorService.injector.get(Router);
    this.location = AppInjectorService.injector.get(Location);
    this.activatedRoute = AppInjectorService.injector.get(ActivatedRoute);
    this.translateService = AppInjectorService.injector.get(TranslateService);
    this.componentContainerService = AppInjectorService.injector.get(
      ComponentContainerService
    );
    this.authService = AppInjectorService.injector.get(AuthService);
    this.usersService = AppInjectorService.injector.get(UsersService);

    this.router.routeReuseStrategy.shouldReuseRoute = () => false;

    this.navigationSubscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        // Trick the Router into believing it's last link wasn't previously loaded
        this.router.navigated = false;
      }
    });
    this.initPermissionKey();
  }

  ngAfterViewInit(): void {
    this.componentContainers?.forEach(
      (container: ComponentContainerDirective) => {
        container.viewContainerRef.clear();
        this.componentContainerService.createComponent(container);
      }
    );
  }

  ngOnInit(): void {

  }

  ngOnDestroy() {
    if (this.navigationSubscription) {
      this.navigationSubscription.unsubscribe();
    }
  }

  @HostListener('window:keydown', ['$event'])
  handleKeyDown(event: KeyboardEvent) {
    if (event.ctrlKey && event.key.toLowerCase() === 's') {
      event.preventDefault();
      this.onKeyDownSave();
    }
  }

  public onKeyDownSave(){}

  protected updateBreadCrumb(nextItemText?: string): void {
    this.breadcrumbsService.reloadItems((segment: string, index: number) => {
      const path = index
        ? this.breadcrumbsService.textSegments.slice(0, index).join('/') +
        '/' +
        segment
        : segment;
      if (index == this.breadcrumbsService.textSegments.length - 1) {
        return new BreadcrumbItem({
          text: nextItemText,
          path: path,
        });
      }
      return new BreadcrumbItem({
        text: this.translateService.instant(
          segment.charAt(0).toUpperCase() + segment.slice(1).toLowerCase()
        ),
        path: path,
      });
    });
  }

  protected goToUrl(
    path: string,
    relative: boolean = true,
    reload: boolean = true,
    newTab: boolean = false,
    queryParams?: any
  ) {
    goToUrl(
      this.router,
      this.activatedRoute,
      this.location,
      path,
      relative,
      reload,
      newTab,
      queryParams
    );
  }
  protected initPermissionKey(): void {
    const url = this.router.url;
    const parts: string[] = url.split('/');
    let desiredPart: string = parts.slice(1, parts.length - 1).join('/');

    // Check if the last part is a number (integer ID) or a GUID
    const lastPart: string = parts[parts.length - 1];
    const guidPattern: RegExp =
      /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/;
    if (isNaN(Number(lastPart)) && !guidPattern.test(lastPart)) {
      desiredPart += '/' + lastPart;
    }

    this.basePermissionKey = desiredPart
      .replaceAll('/', '.')
      .replaceAll('.new', '');
    this.newBasePermissionKey = this.basePermissionKey?.replace(/\?.*/, ".details").replace('.copy', ".details");

    const lastUrlPart = this.router.url.split('/').pop();
    if (
      lastUrlPart &&
      (!isNaN(+lastUrlPart) ||
        lastUrlPart == 'new' ||
        guidPattern.test(lastUrlPart))
    ) {
      this.basePermissionKey += '.details';
      this.newBasePermissionKey = this.basePermissionKey?.replace(/\?.*/, ".details").replace('.copy', ".details");
      }
  }
}
