import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SyslinkColumn } from 'projects/libraries/syslink-components/src/lib/helpers/SyslinkColumn';
import { AppInjectorService } from 'projects/libraries/syslink-components/src/lib/services/app-injector.service';
import { ConfigurationsService } from '../../../base/modules/configurations/configurations.service';
import { TaxRatesService } from '../../../base/tax-rates/tax-rates.service';
import { UnitsService } from '../../../base/units/unit.service';
import { ODataService } from '../../../core/services/oData.service';
import { CustomersService } from '../../../thirds/customers/customers/customers.service';
import { SaleDocument } from './sale-document.model';
import { NotificationsService } from 'projects/libraries/syslink-components/src/public-api';
import { SaleDocumentStatus } from '../sale-document-statuses/sale-document-status.model';
import { ReportsService } from '../../../connectors/reports/reports.service';
import { Third } from '../../../thirds/thirds/third.model';
import { DocumentDatasService } from '../../../base/documents/document-datas/document-datas.service';
import { DocumentsService } from '../../../base/documents/documents/documents.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export abstract class SaleDocumentsService<TSaleDocument extends SaleDocument, TSaleDocumentHeaderStatus extends SaleDocumentStatus> extends ODataService<TSaleDocument> {
  public url: string = 'saleDocument';
  protected configurationsService: ConfigurationsService;

  constructor(
    public reportsService: ReportsService,
    public documentDatasService: DocumentDatasService,
    public documentService: DocumentsService,
    public router: Router
  ) {
    super();
    this.configurationsService = AppInjectorService.injector.get(ConfigurationsService);
  }

  public getDefaultDocumentLineColumns(subModuleCode: string, documentType: string): SyslinkColumn[] {
    const translateService = AppInjectorService.injector.get(TranslateService);
    const unitsService = AppInjectorService.injector.get(UnitsService);
    const taxRatesService = AppInjectorService.injector.get(TaxRatesService);

    return [
      new SyslinkColumn({ order: 1, width: 75 }),
      new SyslinkColumn({ order: 1, field: 'LineNo', label: 'GRID.CAPTION.NO', editable: false }),
      new SyslinkColumn({ order: 2, field: 'Reference', label: 'Reference' }),
      new SyslinkColumn({ order: 3, field: 'Description', label: 'Description', cellTemplate: 'html-cell', editCellTemplate: 'edit-html-cell', data: { showImage: false, showAlignElement: false, showFontAndSize: false, showTextFormatting: true, showUndoRedo: false, showColorStyle: false, showListOption: true, showTextFormat: false, showLink: false, showExtensionOption: false, showTable: false } }),
      new SyslinkColumn({ order: 4, field: 'Quantity', label: 'Quantity' }),
      new SyslinkColumn({ order: 5, field: 'UnitId', label: 'Unit', cellTemplate: 'select-value', editCellTemplate: 'select-cell', data: { service: unitsService, displayKey: 'Name' } }),
      new SyslinkColumn({ order: 6, field: 'ExTaxPurchasePrice', visible: true, label: 'ExTaxPurchasePrice', type: 'number', cellTemplate: 'currency-cell', data: { key: 'CalculatedPrecision' } }),
      new SyslinkColumn({ order: 7, field: 'Margin', visible: false, label: 'Discount margin', cellTemplate: 'object-discount-cell', editCellTemplate: 'edit-object-discount-cell', data: { discountTypeField: 'IsDiscountFixed' } }),
      new SyslinkColumn({ order: 8, field: 'ExTaxGrossPrice', label: 'Base price', type: 'number', cellTemplate: 'currency-cell', editable: false, data: { key: 'CalculatedPrecision' } }),
      new SyslinkColumn({ order: 8, field: 'Labor', visible: false, label: 'Qty M.O', type: 'number' }),
      new SyslinkColumn({ order: 9, field: 'HourlyRate', visible: false, label: 'H. Rate', type: 'number', cellTemplate: 'currency-cell', data: { key: 'CalculatedPrecision' } }),
      new SyslinkColumn({ order: 10, field: 'ExTaxSaleGrossPrice', visible: false, label: 'Brut price', type: 'number', data: { key: 'CalculatedPrecision' }, cellTemplate: 'currency-cell', editable: false }),
      new SyslinkColumn({ order: 11, field: 'Discount', label: 'Discount', cellTemplate: 'object-discount-cell', editCellTemplate: 'edit-object-discount-cell', data: { discountTypeField: 'IsDiscountFixed' } }),
      new SyslinkColumn({ order: 12, field: 'ExTaxUnitPrice', label: 'Net price', type: 'number', cellTemplate: 'currency-cell', data: { key: 'CalculatedPrecision' }, editable: false }),
      new SyslinkColumn({ order: 13, field: 'ForcedPrice', label: 'ForcedPrice', type: 'number', data: { key: 'CalculatedPrecision' }, cellTemplate: 'currency-cell', editable: true }),
      new SyslinkColumn({ order: 14, field: 'TaxRateId', label: 'Tax rate', type: 'string', cellTemplate: 'select-value', editCellTemplate: 'select-cell', data: { service: taxRatesService, displayKey: 'Name', filter: "IsVisible eq true" } }),
      new SyslinkColumn({ order: 15, field: 'TaxAmount', visible: false, label: 'Tax Amount', type: 'number', data: { key: 'TotalPrecision' }, cellTemplate: 'currency-cell', editable: false }),
      new SyslinkColumn({ order: 16, field: 'ExTaxTotalPrice', label: 'ExTaxTotalPrice', data: { key: 'TotalPrecision' }, type: 'number', cellTemplate: 'currency-cell', editable: false, fixed: { position: 'right' } }),
      new SyslinkColumn({ order: 17, field: 'InTaxTotalPrice', visible: false, label: 'InTaxTotalPrice', data: { key: 'TotalPrecision' }, type: 'number', cellTemplate: 'currency-cell', editable: false, fixed: { position: 'right' } }),
      // new SyslinkColumn({ order: 18, field: 'PenaltyValue', label: 'Penalty Value', type: 'number', cellTemplate: 'currency-cell', editable: false, width: 70, visible: false })
    ];
  }



  // Document delay
  // --------------
  public async refreshThirdDocumentDelayFromCustomer(element: any): Promise<TSaleDocument> {

    if (element.ThirdId && element.ThirdId.Id) {
      const customersService = AppInjectorService.injector.get(CustomersService);
      const documentDelayId = (await customersService.getCustomerDocumentDelayByThirdId(element.ThirdId.Id)).Id;
      if (documentDelayId) {
        element.DocumentDelayId = { Id: documentDelayId };
      }
    }
    return element;
  }

  // Responsible User
  // ----------------
  // public async refreshResponsibleUser(element: any): Promise<TSaleDocument> {

  //   const authService = AppInjectorService.injector.get(AuthService);
  //   const third = await authService.getUserThird();
  //   if (third) {
  //     element.ResponsibleUserId = { Id: third.Id };
  //     // element.responsibleUser = await this.usersService.getThird(userId);
  //   }
  //   return element;
  // }


  // Format
  // ------
  public override async format(params?: Partial<TSaleDocument>): Promise<TSaleDocument> {
    var result: any = {
      ...params,
      DocumentDelayId: params?.DocumentDelayId?.Id,
      ThirdId: params?.ThirdId?.Id,
      // IntermediateThirdId: params?.IntermediateThirdId?.Id,
      ResponsibleUserId: params?.ResponsibleUserId?.Id,
      CurrentStatusLink: params?.CurrentStatusLink?.Id,
      // ContractId: params?.ContractId?.Id ?? null,
      // PeriodicityId: params?.PeriodicityId?.Id,
      // IndexingTypeId: params?.IndexingTypeId?.Id
    };

    result.SaleContractId = result.SaleContractId?.Id;
    delete result.DocumentDataCollection;
    delete result.ThirdDocumentData;
    delete result.IntermediateThirdIdDocumentData;
    delete result.ThirdDocumentDataIndex;
    delete result.IntermediateThirdIdDocumentDataIndex;
    delete result.Lines;
    delete result.Statuses;
    delete result.DocumentFinances;
    delete result.SaleQuoteHeaders;
    delete result.Payments;
    delete result.ExtensionDate;
    delete result.RenewalEndDate;
    delete result.DocumentRelations;
    delete result.DocumentLineRelations;
    delete result.FormattedStructuredCommunication;
    delete result.StructuredCommunication;
    delete result.Margin;
    delete result.MarginPercent;
    delete result.AmountReceived;
    delete result.AmountRemaining;
    delete result.DocumentFinances;
    delete result.ExTaxTotalWithoutGlobalDiscount;

    delete result.AutomaticallyValidated;
    delete result.AutomaticallySend;
    delete result.FormattedDocumentPayment;
    delete result.IsStartPeriodDateOffsetPositive;
    delete result.RRule;
    delete result.PeriodicityId;
    delete result.Renewal;
    delete result.StartPeriodDate;
    delete result.StartPeriodDateOffset;

    delete result.StopDate;
    delete result.CurrentPeriodStartDate;
    delete result.CurrentPeriodEndDate;
    delete result.GenerateDocumentNextDate;
    delete result.Type;

    //delete result.LastIndexingDate;
    if (this.url != "SaleInvoiceHeader") {
      delete result.DateFrom;
      delete result.DateTo;
      delete result.LateFeeInterestPercentage;
      delete result.LateFeeInterestAmount;
      delete result.DaysOverdue;
      delete result.TotalWithLateFee;
      delete result.ReminderCount;
      delete result.FixedLateFeeAmount;
      delete result.SaleContractId;
      delete result.Report;
      delete result.FirstReminder;
      delete result.SecondReminder;
      delete result.ThirdReminder;
      delete result.WorkTimeReport;
    }
    else {
      let dateFrom: any = result.DateFrom;
      if(typeof (dateFrom)=="string"){
        dateFrom = new Date(dateFrom);
      }
      
      if (!dateFrom || (typeof (dateFrom) != 'object' && dateFrom.includes("0000")) || dateFrom.getFullYear().toString() == "1") {
        delete result.DateFrom;
        delete result.DateTo;
      }
    }

    return result;
  }

  public async printRows(elements: TSaleDocument[], extensionType: string, type: string) {
    await this.reportsService.printRows(elements.map((row: TSaleDocument) => row?.Id), type, type + ".pdf", extensionType);
  }

  /***********************************/
  /****   ContextMenuItemAction   ****/
  /***********************************/

  // Action
  // ------
  public canUpdateStatus(elements: TSaleDocument[], status: TSaleDocumentHeaderStatus): boolean {
    var result: boolean = true;

    // Checking status is not empty
    // ----------------------------
    if (status == null) {
      NotificationsService.sendErrorMessage("Status is empty");
      result = false;
    }

    // Checking status is not empty
    // ----------------------------
    if (elements?.some(row => row.CurrentStatusLink?.StatusId?.Sequence != null && status.Sequence != null && row.CurrentStatusLink?.StatusId?.Sequence >= status?.Sequence)) {
      NotificationsService.sendErrorMessage("Current status is greater or equal than selected status");
      result = false;
    }
    return result;
  }

  // Statistics
  // ----------
  public convertODataFiltersToString(filters: any[]): string {
    if (!filters || filters == null) return '';
    var result = '';

    // single filter
    if (typeof (filters[0]) === 'string' && (filters[0] == "Deadline" || filters[0] == "Date")) {
      result = this.formatDate(filters[2], filters[1], filters[0]);
    }
    else if (typeof (filters[0]) === 'string' && (filters[0] == "ExTaxTotal" || filters[0] == "InTaxTotal" || filters[0] == "AmountRemaining")) {
      result = this.formatNumber(filters[2], filters[1], filters[0]);
    }
    else if (typeof (filters[0]) === 'string') {
      switch (filters[1]) {
        case 'contains':
          result = `contains(${filters[0]}%2C%27${filters[2]}%27)`;
          break;
        case 'notcontains':
          result = `not%20contains(${filters[0]}%2C%27${filters[2]}%27)`;
          break;
        case 'startswith':
          result = `startswith(${filters[0]}%2C%27${filters[2]}%27)`;
          break;
        case 'endswith':
          result = `endswith(${filters[0]}%2C%27${filters[2]}%27)`;
          break;
        case '=':
          result = `${filters[0]}%20eq%20%27${filters[2]}%27)`;
          break;
        case '<>':
          result = `${filters[0]}%20ne%20%27${filters[2]}%27)`;
          break;
      }
    }
    // multiple filter
    else if (Array.isArray(filters[0])) {
      for (var i = 0; i < filters.length; i++) {
        if (filters[i + 1] != undefined) {
          result += `(${this.convertODataFiltersToString(filters[i])})%20${filters[i + 1]}%20`;
          i++;
        }
        else {
          result += `(${this.convertODataFiltersToString(filters[i])})`
        }
      }
    } else return '';

    return result;
  }
  private formatDate(value: string, type: string, field: string) {
    if (type == 'between') {
      const firstdate = new Date(value[0]);
      const firstyear = firstdate.getFullYear();
      const firstmonth = String(firstdate.getMonth() + 1).padStart(2, '0');
      const firstday = String(firstdate.getDate()).padStart(2, '0');
      const firstHours = "00"// String(firstdate.getHours()).padStart(2, '0');
      const firstMinutes = "00" // String(firstdate.getMinutes()).padStart(2, '0');

      const seconddate = new Date(value[1]);
      const secondyear = seconddate.getFullYear();
      const secondmonth = String(seconddate.getMonth() + 1).padStart(2, '0');
      const secondday = String(seconddate.getDate()).padStart(2, '0');
      const secondHours = "23" //String(seconddate.getHours()).padStart(2, '0');
      const secondMinutes = "59"//String(seconddate.getMinutes()).padStart(2, '0');

      return `${field} >= %23${firstyear}-${firstmonth}-${firstday} ${firstHours}:${firstMinutes}%23  AND ${field} <= %23${secondyear}-${secondmonth}-${secondday} ${secondHours}:${secondMinutes}%23 `;
    }
    const date = new Date(value);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');

    const nextValue = new Date(date.setDate(date.getDate() + 1))
    const nextyear = nextValue.getFullYear();
    const nextmonth = String(nextValue.getMonth() + 1).padStart(2, '0');
    const nextday = String(nextValue.getDate()).padStart(2, '0');

    switch (type) {
      case '=':
        return `${field} >= %23${year}-${month}-${day}%23 AND ${field} < %23${nextyear}-${nextmonth}-${nextday}%23 `;
      case '<>':
        return `${field} != %23${year}-${month}-${day}%23 `;
      case '<':
        return `${field} < %23${year}-${month}-${day}%23 `;
      case '>':
        return `${field} > %23${nextyear}-${nextmonth}-${nextday}%23 `;
      case '<=':
        return `${field} <= %23${year}-${month}-${day}%23 `;
      case '>=':
        return `${field} >= %23${year}-${month}-${day}%23 `;
    }
    return "";
  }

  private formatNumber(value: string, type: string, field: string) {
    switch (type) {
      case '=':
        return `${field} ==  ${value}`;
      case '<>':
        return `${field} !=  ${value}`;
      case '<':
        return `${field} <  ${value}`;
      case '>':
        return `${field} >  ${value}`;
      case '<=':
        return `${field} <=  ${value}`;
      case '>=':
        return `${field} >=  ${value}`;
      case 'between':
        return `${field} >= ${value[0]} AND ${field} <= ${value[1]}`;
    }
    return "";
  }

  public async copy(documentType: string, document: any, subject: string, ThirdId: Third) {
    if ((!ThirdId || !ThirdId.Id)) {
      NotificationsService.sendErrorMessage("Third cannot be empty");
      return;
    }

    var currentElement = JSON.parse(JSON.stringify(document));
    currentElement.Id = null;

    currentElement.DocumentRelations = [];
    currentElement.dateFrom = undefined;
    currentElement.No = undefined;
    currentElement.Subject = subject;
    currentElement.StructuredCommunication = undefined;
    currentElement.Payments = [];
    currentElement = await this.copyLoadDate(currentElement, ThirdId);
    currentElement = await this.copyLoadLine(currentElement);
    currentElement = await this.copyLoadDocumentData(currentElement, ThirdId, documentType);
    currentElement = await this.copyLoadStatus(currentElement);

    if (documentType == "SaleOrder") {
      delete currentElement['DocumentDelayId'];
    }
    localStorage.setItem("SaleHeader", JSON.stringify(currentElement));
    this.goToDocumentTypeUrlCopy(documentType);

  }

  private async copyLoadDocumentData(currentElement: any, selectedThird: Third, documentType: string) {
    currentElement.DocumentDataCollection = [];
    currentElement.ThirdId = selectedThird;
    currentElement.DocumentDataCollection.push(await this.documentDatasService.getInstance(undefined, documentType, undefined, selectedThird));
    return currentElement;
  }

  private async copyLoadDate(currentElement: any, selectedThird: Third) {
    currentElement.Date = new Date();
    currentElement.DocumentDelayId = selectedThird.CustomerId?.SaleInvoiceDocumentDelayId;
    currentElement.Deadline = await this.documentService.computeDeadline(currentElement.Date, currentElement.DocumentDelayId?.Id ?? 3);;
    return currentElement;
  }

  private async copyLoadLine(currentElement: any) {
    for (let index = 0; index < currentElement.Lines.length; index++) {
      currentElement.Lines[index].Id = -(index + 1);
      currentElement.Lines[index].HeaderId = undefined;
      if (currentElement.Lines[index].Margin) { currentElement.Lines[index].Margin.Id = undefined; }
      if (currentElement.Lines[index].Discount) { currentElement.Lines[index].Discount.Id = undefined; }
    }
    for (let index = 0; index < currentElement.Lines.length; index++) {
      if (currentElement.Lines[index].ParentId && currentElement.Lines[index].ParentId.Id) {
        currentElement.Lines[index].ParentId = currentElement.Lines.find((l: any) => l.LineNo == currentElement.Lines[index].ParentLineNo);
      }
    }
    return currentElement;
  }

  private goToDocumentTypeUrlCopy(goToDocumentType: string) {
    switch (goToDocumentType) {
      case "SaleQuote":
        this.router.navigateByUrl("/sales/quotes/copy");
        break;
      case "SaleOrder":
        this.router.navigateByUrl("/sales/orders/copy");
        break;
      case "SaleInvoice":
        this.router.navigateByUrl("/sales/invoices/copy");
        break;
      case "SaleCreditNote":
        this.router.navigateByUrl("/sales/credit-notes/copy");
        break;
    }
  }

  private async copyLoadStatus(currentElement: any) {
    currentElement.CurrentStatusLink = currentElement.Statuses.find((s: any) => { return s.StatusId.Sequence == 1 });
    currentElement.CurrentStatusLink.Id = undefined;
    currentElement.CurrentStatusLink.Date = new Date();
    currentElement.Statuses = [];
    currentElement.Statuses.push(currentElement.CurrentStatusLink);
    return currentElement;
  }



}

export enum SaleContexMenuItemActionCode {
  PrintToZIP = 'PrintToZIP',
  PrintToPDF = 'PrintToPDF',
  Status = 'Status',
  payment = 'Payment',
  copy = 'Copy',
}


