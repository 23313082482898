import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SaleDocumentLine } from '../../sale-document-lines/sale-document-line.model';
import { SaleDocumentLinesService } from '../../sale-document-lines/sale-document-lines.service';
import { SaleDocument } from '../sale-document.model';
import { SaleDocumentsService } from '../sale-documents.service';
import { SaleContractsService } from '../../../sale-contracts/sale-contracts/sale-contracts.service';
import { ConfigurationsService } from '../../../../base/modules/configurations/configurations.service';
import { getNew } from 'projects/libraries/syslink-components/src/lib/helpers/tools';
import { TranslateService } from '@ngx-translate/core';
import { SaleDocumentStatus } from '../../sale-document-statuses/sale-document-status.model';
import { DocumentsService } from 'projects/erp-app/src/app/base/documents/documents/documents.service';
import { DocumentDelaysService } from 'projects/erp-app/src/app/base/documents/document-delays/document-delays.service';

@Component({
  selector: 'app-sale-document-form',
  templateUrl: './sale-document-form.component.html',
  styleUrls: ['./sale-document-form.component.scss'],
})
export class SaleDocumentFormComponent<
  TSaleDocument extends SaleDocument,
  TSaleDocumentService extends SaleDocumentsService<
    SaleDocument,
    SaleDocumentStatus
  >,
  TSaleDocumentLine extends SaleDocumentLine,
  TSaleDocumentLineService extends SaleDocumentLinesService<SaleDocumentLine>
>  { // implements OnInit
  @Input() public element: TSaleDocument = <TSaleDocument>( getNew<TSaleDocument>() );
  @Output() public elementChange: EventEmitter<TSaleDocument> = new EventEmitter<TSaleDocument>();

  // @Output() public dateChange: EventEmitter<Date> = new EventEmitter<Date>();

  @Input() public disabled: boolean = false;
  @Input() public documentType: string = '';
  // @Input() public subModuleCode: string = '';

  // public dateDiff: string = '';
  // @Input() public showExtensionDate: boolean = true;

  constructor(
    public documentDelaysService: DocumentDelaysService,
    private documentsService:DocumentsService
  //   // public saleContractsService: SaleContractsService,
  //   // public configurationsService: ConfigurationsService,
  //   public translateService: TranslateService
  ) { }
  // ngOnInit(): void {
  //   // if (this.documentType == 'SaleContract') {
  //   //   this.computeDateDiff();
  //   // }
  // }

  // public getAmountRemainingColor() {
  //   var result = 'primary';
  //   const datetime = new Date(Date.now());
  //   const deadline = this.element.Deadline;

  //   if (
  //     !this.element ||
  //     !this.element.Deadline ||
  //     !this.element.Date ||
  //     !deadline ||
  //     !datetime ||
  //     !this.element.AmountReceived ||
  //     !this.element.AmountRemaining
  //   )
  //     return result;

  //   if (this.element.AmountReceived > this.element.AmountRemaining) {
  //     result = 'warning';
  //   } else if (this.element.AmountRemaining != 0 && deadline > datetime) {
  //     result = 'primary';
  //   } else if (this.element.AmountRemaining != 0 && deadline <= datetime) {
  //     result = 'danger';
  //   } else if (this.element.AmountRemaining == 0) {
  //     result = 'secondary';
  //   }

  //   return result;
  // }
  // public onChangeDeadLine() {
  //   if (this.documentType == 'SaleContract') {
  //     this.computeDateDiff();
  //     this.saleContractsService.updateContractScheduler();
  //     this.dateChange.emit();
  //   }
  // }

  // public computeDateDiff() {
  //   if (!this.element.Date || !this.element.Deadline) {
  //     this.dateDiff = '';
  //     return;
  //   }

  //   const numberOfDayInMonth: number = 30.4167; // Value found with Google converter (month to days)
  //   const diffInMilliseconds =
  //     this.element.Deadline.getTime() - this.element.Date.getTime();
  //   const diffInDays = diffInMilliseconds / (1000 * 60 * 60 * 24);
  //   const diffInMonth = this.roundToNearestHalf(
  //     diffInDays / numberOfDayInMonth
  //   );
  //   const diffInMonthString = diffInMonth.toFixed(diffInMonth % 1 != 0 ? 1 : 0); // Return no decimal point if number is an integer, inspired from https://stackoverflow.com/a/2304062

  //   this.dateDiff =
  //     diffInMonthString + ' ' + this.translateService.instant('Month(s)') + ' ';
  // }

  /**
   * From https://stackoverflow.com/questions/6137986/javascript-roundoff-number-to-nearest-0-5
   * /!\ Doesn't work for negative number
   * @param number
   * @returns
   */
  // private roundToNearestHalf(number: number): number {
  //   return Math.round(number * 2) / 2;
  // }

  public async onDateChanged() {
    if(!this.element.DocumentDelayId || this.element.DocumentDelayId == null) return;
    
    // Compute Deadline
    // ----------------
    this.element.Deadline = await this.documentsService.computeDeadline(this.element.Date, this.element.DocumentDelayId?.Id ?? 0);

    // if (this.documentType == 'SaleContract') {
    //   this.computeDateDiff();
    // }
    // this.dateChange.emit(date);
  }
}
