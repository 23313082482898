<syslink-switch
    label="Favorite"
    [(model)]="element.IsFavorite"
    [disabled]="readOnly"
></syslink-switch>
<syslink-select
    label="Supplier name"
    [store]="suppliersService.store"
    [displayKey]="'ThirdId.Fullname'"
    [expand]="['ThirdId','PurchaseTaxRateId']"
    [select]="['Id','ThirdId.Id', 'ThirdId.Fullname']"
    [(model)]="element.SupplierId"
    [disabled]="readOnly"
    (modelChange)="onChangeSupplier($event)"
></syslink-select>
<syslink-input-text
    label="Supplier reference"
    [(model)]="element.SupplierReference"
></syslink-input-text>
<syslink-input-number
    label="Gross price excl. VAT"
    [min]="0"
    [precision]="3"
    [(model)]="element.ExTaxGrossPrice"
></syslink-input-number>
<div
    *ngIf="element.DiscountId && element.DiscountId.Value != undefined && element.DiscountId.IsDiscountFixed != undefined"
    class="d-flex justify-content-between"
>
    <syslink-input-number
        label="Discount"
        [min]="0"
        [max]="element.DiscountId.IsDiscountFixed == true ? undefined : 100"
        [model]="element.DiscountId.IsDiscountFixed? element.DiscountId.Value: (element.DiscountId.Value*100)"
        (modelChange)="onChangeDiscountValue($event)"
    ></syslink-input-number>
    <syslink-select
        labelMode="hidden"
        [displayKey]="'name'"
        valueKey="id"
        [items]="[
          { id: 0, name: '%' },
          { id: 1, name: '€' }
        ]"
        [model]="element.DiscountId.IsDiscountFixed? 1:0"
        (modelChange)="onChangeDiscountFixed($event)"
    ></syslink-select>
</div>
<syslink-select
    label="Unit"
    [store]="unitsService.store"
    [displayKey]="'Name'"
    [(model)]="element.UnitId"
    [disabled]="readOnly"
></syslink-select>
<syslink-select
    label="Tax rate"
    [store]="taxRatesService.store"
    [displayKey]="'Name'"
    [(model)]="element.TaxRateId"
    [disabled]="readOnly"
></syslink-select>
<syslink-select
    label="Brand"
    [store]="brandsService.store"
    (onCustomValue)="customNewBrandValue($event)"
    [(model)]="element.BrandId"
    [disabled]="readOnly"
></syslink-select>
<syslink-input-text
    label="Make"
    [(model)]="element.Make"
></syslink-input-text>
<syslink-input-number
    label="Minimum quantity"
    [(model)]="element.MinimumQty"
></syslink-input-number>
<syslink-input-number
    label="Stock"
    [(model)]="element.SupplierStock"
></syslink-input-number>