<syslink-input-number
    name="GlobalDiscountValue"
    label="Global discount value(%)"
    [(model)]="element.GlobalDiscountValue"
    (modelChange)="onChangeGlobalDiscount()"
    [disabled]="documentType!='SaleContract' && disabled"
    [min]="0"
    [max]="100"
></syslink-input-number>

    <div>
        <syslink-select
            label="Responsible"
            [store]="thirdsService.store"
            filter="UserId ne null"
            detailsUrl="/admin/customers/"
            displayKey="Fullname"
            [(model)]="element.ResponsibleUserId"
            [canView]="authService.hasPermission('settings-thirds-thirds')"
            [disabled]="disabled"
        ></syslink-select>
    </div>
    <div
        class="mt-3 p-3 border border-secondary"
        *ngIf="element.ResponsibleUserId"
    >
        <syslink-input-text
            label="Name"
            [(model)]="element.ResponsibleUserId.Fullname"
            [disabled]="true"
        ></syslink-input-text>

        <div *ngIf="element.ResponsibleUserId.DisplayEmail">
            <syslink-input-text
                label="Email"
                [disabled]="true"
                [(model)]="element.ResponsibleUserId.DisplayEmail"
            ></syslink-input-text>
        </div>

        <div *ngIf="element.ResponsibleUserId.DisplayPhoneNumber">
            <syslink-input-text
                label="Phone"
                [disabled]="true"
                [(model)]="element.ResponsibleUserId.DisplayPhoneNumber"
            ></syslink-input-text>
        </div>
    </div>

<syslink-wysiwyg
    name="Note"
    label="Note"
    [(model)]="element.Note"
    [showTable]="false"
    [toolbar]="false"
    height="140"
    minHeight="140"
    [labelMode]="'hidden'"
    [disabled]="disabled"
></syslink-wysiwyg>


<!-- 
<div class="row">
    <div class="col-sm-3">
        <syslink-input-text
            name="ExternalReference"
            label="External reference"
            [(model)]="element.ExternalReference"
            [disabled]="documentType!='SaleContract' && disabled"
        ></syslink-input-text>
    </div>

    <div *ngIf="documentType!='SaleOrder' && documentType!='SaleContract'" class="col-sm-3">        
        <syslink-select
            name="DocumentDelay"
            [label]="documentType + '.DocumentDelay' | translate"
            [store]="documentDelaysService.store"
            [(model)]="element.DocumentDelayId"
            [disabled]="documentType!='SaleContract' && disabled"
            (modelChange)="documentDelayChanged.emit($event)"
        ></syslink-select>
    </div>

    <div class="col-sm-3">
        <syslink-input-number
            name="GlobalDiscountValue"
            label="Global discount value(%)"
            [(model)]="element.GlobalDiscountValue"
            [disabled]="documentType!='SaleContract' && disabled"
            (focusOut)="globalDiscountChanged.emit($event)"
            [min]="0"
            [max]="100"
        ></syslink-input-number>
    </div>
    <div class="col-sm-3">
    </div>
    <div
        class="col-sm-3"
        *ngIf="documentType=='SaleInvoice'"
        
    >
        <syslink-select
            #contractSelect
            [label]="'Contrat'"
            [store]="saleContractsService.store"
            [(model)]="element.ContractId"
            [filter]="filterContractList"
            displayKey="NameFormatted"
            (modelChange)="onChangeContract()"
            [canRefresh]="authService.hasPermission('sales.contracts.list.view')"
            [canAdd]="authService.hasPermission('sales.contracts.list.add')"
            [canView]="authService.hasPermission('sales.contracts.list.view')"
            detailsUrl="/sales/contracts/"
            (onAdd)="onCreateContract.emit()"
            [disabled]="!authService.hasPermission('sales.contracts.list.view')"
        ></syslink-select>
    </div>
    <div
        class="col-sm-3"
        *ngIf="element.ContractId"
    >
        <syslink-input-date
            [name]="'Start date'| translate"
            [(model)]="element.DateFrom"
            type="date"
            format="date"
        ></syslink-input-date>
    </div>
    <div
        class="col-sm-3"
        *ngIf="element.ContractId"
    >
        <syslink-input-date
            [name]="'End date'| translate"
            [(model)]="element.DateTo"
            type="date"
            format="date"
        ></syslink-input-date>
    </div> -->
<!-- <div class="col-sm-3">
        <syslink-select
            label="'Origin' "
            [store]="originsService.store"
            [(model)]="element.OriginId"
        ></syslink-select>
    </div> -->
<!-- </div>

<div class="row">
    <div class="col-12">
        <syslink-wysiwyg
            name="htmlEditor-Note"
            label="Note"
            [(model)]="element.Note"
            [table]="false"
            [multilineToolbar]="true"
            height="200"
            [disabled]="documentType!='SaleContract' && disabled"
        ></syslink-wysiwyg>
    </div>
</div> -->