<div
    class="blockTextCell {{getBlockClass(cell)}} p-2"
    [style.backgroundColor]="getBlockColor(cell)"
>
    <span class="">{{getBlockLabel(cell)}}</span>
    <div *ngIf="getBlockText!=undefined">
        {{getBlockText(cell)??""}}
    </div>
    
</div>
