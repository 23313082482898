import { Entity } from "../../../core/models/entity";
import { BankReconciliationStatus } from "../bank-reconciliation-status/bank-reconciliation-status.model";
import { BankReconciliation } from "../bank-reconciliation.model";

export class BankReconciliationHeaderStatus extends Entity {
    public Date?:Date;
    public BankReconciliationId?:BankReconciliation;
    public StatusId?:BankReconciliationStatus;
    
    constructor(entity?: Partial<BankReconciliationHeaderStatus>) {
        super();
        Object.assign(this, entity);
    }
}
