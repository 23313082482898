import { Component, Input } from '@angular/core';
import { SyslinkColumn } from 'projects/libraries/syslink-components/src/lib/helpers/SyslinkColumn';

@Component({
  selector: 'app-document-relations-block',
  templateUrl: './document-relations-block.component.html',
  styleUrls: ['./document-relations-block.component.scss']
})
export class DocumentRelationsBlockComponent {
  @Input() public title: string = '';
  @Input() public value: number = 0;
}

export type DocumentRelationData = {
  id:number,
  key: 'SaleQuote' | 'SaleOrder' | 'SaleInvoice' | 'SaleCreditNote' | 'SaleContract' | 'PurchaseOrder' | 'PurchaseInvoice' | 'PurchaseCreditNote' | 'StockMoveCustomerIncoming' | 'StockMoveCustomerOutgoing' | 'StockMoveSupplierIncoming' | 'StockMoveSupplierOutgoing' | 'TimeManagementTask' | 'TimeManagementWorkTime',
  title: string,
  group: 'Sale' | 'Purchase' | 'Stock' | 'TimeManagement',
  value: number,
  visible: boolean,
  ids: number[],
  gridFilter: string | Array<string>,
  columns?:SyslinkColumn[]
}