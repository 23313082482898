import { Injectable } from '@angular/core';
import { Sequence } from './sequence.model';
import { ODataService } from '../services/oData.service';

@Injectable({
  providedIn: 'root'
})
export class SequencesService  extends ODataService<Sequence> {
  public url = 'Sequence';
}
