import { Injectable } from "@angular/core";
import { CanDeactivate } from "@angular/router";
import { Observable } from "rxjs";
import { ModificationService } from "../../services/modification.service";

export interface CanComponentDeactivate {
    canDeactivate: () => Observable<boolean> | Promise<boolean> | boolean;
  }
  
  @Injectable({
    providedIn: 'root',
  })
  export class UnsavedChangesGuard implements CanDeactivate<CanComponentDeactivate> {
    
    constructor(private modService: ModificationService) {
    }
  
    canDeactivate(component: CanComponentDeactivate): Observable<boolean> | Promise<boolean> | boolean {
      if (this.modService.hasModifications) {
        this.modService.setModifications(false);
        return confirm('Vous avez des modifications non enregistrées. Êtes-vous sûr de vouloir quitter ?');
      }
      return true;
    }
  }