import { Injectable } from '@angular/core';
import { SyslinkColumn } from 'projects/libraries/syslink-components/src/lib/helpers/SyslinkColumn';
import { AppInjectorService } from 'projects/libraries/syslink-components/src/lib/services/app-injector.service';
import { ConfigurationsService } from '../../../base/modules/configurations/configurations.service';
import { TaxRatesService } from '../../../base/tax-rates/tax-rates.service';
import { UnitsService } from '../../../base/units/unit.service';
import { AuthService } from '../../../core/auth/auth.service';
import { ODataService } from '../../../core/services/oData.service';
import { SuppliersService } from '../../../thirds/suppliers/suppliers.service';
import { PurchaseDocument } from './purchase-document.model';
import { NotificationsService } from 'projects/libraries/syslink-components/src/public-api';
import { PurchaseDocumentStatus } from '../purchase-document-statuses/purchase-document-status.model';
import { ReportsService } from '../../../connectors/reports/reports.service';

@Injectable({
  providedIn: 'root'
})
export abstract class PurchaseDocumentsService<TPurchaseDocument extends PurchaseDocument, TPurchaseDocumentHeaderStatus extends PurchaseDocumentStatus> extends ODataService<TPurchaseDocument> {
  public url: string = 'PurchaseDocument';
  protected configurationsService: ConfigurationsService;

  constructor(
    public reportsService: ReportsService
  ) {
    super();
    this.configurationsService = AppInjectorService.injector.get(ConfigurationsService);
  }

  public getDefaultDocumentLineColumns(subModuleCode: string, documentType: string): SyslinkColumn[] {
    const unitsService = AppInjectorService.injector.get(UnitsService);
    const taxRatesService = AppInjectorService.injector.get(TaxRatesService);

    return [
      new SyslinkColumn({ order: 1, width: 75 }),
      new SyslinkColumn({ order: 2, field: 'Reference', label: 'Reference', width: 150 }),
      new SyslinkColumn({ order: 3, field: 'Description', label: 'Description', cellTemplate: 'html-cell', editCellTemplate: 'edit-html-cell' }),
      new SyslinkColumn({ order: 4, field: 'Quantity', label: 'Quantity', width: 70 }),
      new SyslinkColumn({ order: 5, field: 'UnitId', label: 'Unit', cellTemplate: 'select-value', editCellTemplate: 'select-cell', data: { service: unitsService, displayKey: 'Name' }, width: 100 }),
      new SyslinkColumn({ order: 6, field: 'ExTaxGrossPrice', label: 'ExTaxGrossPrice', type: 'number', cellTemplate: 'currency-cell', editCellTemplate: 'edit-currency-cell', width: 70, data: { key: 'CalculatedPrecision' } }),
      new SyslinkColumn({ order: 11, field: 'Discount', label: 'Discount', cellTemplate: 'object-discount-cell', editCellTemplate: 'edit-object-discount-cell', data: { discountTypeField: 'IsDiscountFixed' }, width: 90 }),
      new SyslinkColumn({ order: 12, field: 'ExTaxUnitPrice', label: 'ExTaxUnitPrice', type: 'number', cellTemplate: 'currency-cell', data: { key: 'CalculatedPrecision' }, editable: false, width: 70 }),
      new SyslinkColumn({ order: 13, field: 'ForcedPrice', label: 'ForcedPrice', type: 'number', data: { key: 'CalculatedPrecision' }, cellTemplate: 'currency-cell', editCellTemplate: 'edit-currency-cell', editable: true, width: 70 }),
      new SyslinkColumn({ order: 14, field: 'TaxRateId', label: 'Tax rate', type: 'string', cellTemplate: 'select-value', editCellTemplate: 'select-cell', data: { service: taxRatesService, displayKey: 'Name', filter: "IsVisible eq true" }, width: 70 }),
      new SyslinkColumn({ order: 15, field: 'TaxAmount', label: 'Tax Amount', type: 'number', data: { key: 'TotalPrecision' }, cellTemplate: 'currency-cell', editable: false, width: 70 }),
      new SyslinkColumn({ order: 16, field: 'ExTaxTotalPrice', label: 'ExTaxTotalPrice', data: { key: 'TotalPrecision' }, type: 'number', cellTemplate: 'currency-cell', editable: false, width: 70, fixed: { position: 'right' } }),
      new SyslinkColumn({ order: 17, field: 'InTaxTotalPrice', label: 'InTaxTotalPrice', data: { key: 'TotalPrecision' }, type: 'number', cellTemplate: 'currency-cell', editable: false, width: 70, fixed: { position: 'right' } }),
    ];
  }

  // public computeDeadline(startDate: Date, documentDelayId: number, documentType : string, documentId?: number): Promise<Date> {
  //   return this.apiService.sendRequest('/api/PurchaseDocument/computeDeadline', 'POST', {
  //     DocumentId: documentId,
  //     Date: startDate,
  //     DocumentDelayId: documentDelayId,      
  //     ReportType: documentType
  //   });
  // }

  // Document delay
  // --------------
  public async refreshThirdDocumentDelayFromSupplier(element: any): Promise<TPurchaseDocument> {

    if (element.ThirdId && element.ThirdId.Id) {
      const suppliersService = AppInjectorService.injector.get(SuppliersService);
      const documentDelayId = (await suppliersService.getSupplierDocumentDelayByThirdId(element.ThirdId.Id)).Id;
      if (documentDelayId) {
        element.DocumentDelayId = { Id: documentDelayId };
      }
    }
    return element;
  }

  // Delivery time
  // --------------
  public async refreshThirdDeliveryTimeFromSupplier(element: any): Promise<TPurchaseDocument> {

    if (element.ThirdId && element.ThirdId.Id) {
      const suppliersService = AppInjectorService.injector.get(SuppliersService);
      const deliveryTime = (await suppliersService.getSupplierDeliveryTimeByThirdId(element.ThirdId.Id)).Id;
      if (deliveryTime) {
        element.DeliveryTimeId = { Id: deliveryTime };
      }
    }
    return element;
  }

  // Responsible User
  // ----------------
  public async refreshResponsibleUser(element: any): Promise<TPurchaseDocument> {

    const authService = AppInjectorService.injector.get(AuthService);
    const third = await authService.getUserThird();
    if (third) {
      element.ResponsibleUserId = { Id: third.Id };
    }
    return element;
  }

  // Format
  // ------
  public override async format(params?: Partial<TPurchaseDocument>): Promise<TPurchaseDocument> {
    var result: any = {
      ...params,
      DocumentDelayId: params?.DocumentDelayId?.Id,
      DeliveryTimeId: params?.DeliveryTimeId?.Id,
      ThirdId: params?.ThirdId?.Id,
      IntermediateThirdId: params?.IntermediateThirdId?.Id,
      ResponsibleUserId: params?.ResponsibleUserId?.Id,
      CurrentStatusLink: params?.CurrentStatusLink?.Id,
    };

    delete result.DocumentDataCollection;
    delete result.ThirdDocumentData;
    delete result.IntermediateThirdIdDocumentData;
    delete result.ThirdDocumentDataIndex;
    delete result.IntermediateThirdIdDocumentDataIndex;
    delete result.Lines;
    delete result.Statuses;
    delete result.DocumentFinances;
    delete result.Payments;
    delete result.DocumentRelations;

    let dateFrom: Date | undefined = result.DateFrom;
    if (!dateFrom || dateFrom.getFullYear().toString() == "1") {
      delete result.DateFrom;
      delete result.DateTo;
    }
    let deadline: Date | undefined = result.Deadline;
    if (deadline && typeof deadline !== "string" && deadline.getFullYear().toString() == "1") {
      delete result.Deadline;
    }
    return result;
  }



  public async printRows(elements: TPurchaseDocument[], extensionType: string, type: string) {
    await this.reportsService.printRows(elements.map((row: TPurchaseDocument) => row?.Id), type, type + ".pdf", extensionType);
  }

  /***********************************/
  /****   ContextMenuItemAction   ****/
  /***********************************/

  // Action
  // ------
  public canUpdateStatus(elements: TPurchaseDocument[], status: TPurchaseDocumentHeaderStatus): boolean {
    var result: boolean = true;

    // Checking status is not empty
    // ----------------------------
    if (status == null) {
      NotificationsService.sendErrorMessage("Status is empty");
      result = false;
    }

    // Checking status is not empty
    // ----------------------------
    if (elements?.some(row => row.CurrentStatusLink?.StatusId?.Sequence != null && status.Sequence != null && row.CurrentStatusLink?.StatusId?.Sequence >= status?.Sequence)) {
      NotificationsService.sendErrorMessage("Current status is greater or equal than selected status");
      result = false;
    }
    return result;
  }
}

export enum PurchaseContexMenuItemActionCode {
  PrintToZIP = 'PrintToZIP',
  PrintToPDF = 'PrintToPDF',
  Status = 'Status',
  payment = 'Payment'
}
