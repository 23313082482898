<syslink-modal width="40vw" #modal [title]="title" (onValidate)="validate.emit(element)">
      <syslink-input-text label="Code" [(model)]="element.Code"></syslink-input-text>
   
      <syslink-input-text
      name="Name"
      label="Name"
      [(model)]="element.Name"
      ></syslink-input-text>

        <syslink-switch
        name="Taxable"
        label="Taxable"
        [(model)]="element.Taxable"
        ></syslink-switch>

        <syslink-switch
        name="Letterable"
        label="Letterable"
        [(model)]="element.Letterable"
        ></syslink-switch>

      <syslink-select 
      label="Account type" 
      [store]="accountTypesService.store" 
      [(model)]="element.AccountTypeId"
      ></syslink-select>

      <syslink-select 
      label="Parent" 
      [displayKey]="'Code'"
      [store]="accountsService.store" 
      [(model)]="element.ParentAccountId"
      ></syslink-select>
  </syslink-modal>
  