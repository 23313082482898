import { Injectable } from '@angular/core';
import { ODataService } from '../../core/services/oData.service';
import { LoadOptions } from 'devextreme/data';
import { AccountMoveLine } from './account-move-line.model';

@Injectable({
  providedIn: 'root'
})
export class AccountMoveLinesService extends ODataService<AccountMoveLine> {
  public url = 'AccountMoveLine';
  public override defaultOptions: LoadOptions = {
    expand: [
      'AccountMoveId',
      'ThirdId',
      'SaleDocumentId',
      'AccountId',
      'TaxId',
    ]
  };

  public override format(element: Partial<AccountMoveLine>): AccountMoveLine {
    var result: any = {
      ...element,
      AccountMoveId: element.AccountMoveId?.Id,
      ThirdId: element.ThirdId?.Id,
      SaleDocumentId: element.SaleDocumentId?.Id,
      TaxId: element.TaxId?.Id
    };

    if (result.TaxId == undefined) {
      result.TaxId = null;
    }
    if (result.Date) {
      let date: Date = new Date(result.Date);
      if (!date || date.getFullYear().toString() == "1" || date.getFullYear().toString() == "0") {
        delete result.Date;
      }
    }
    if (result.Deadline) {
      let date: Date = new Date(result.Deadline);
      if (!date || date.getFullYear().toString() == "1" || date.getFullYear().toString() == "0") {
        delete result.Deadline;
      }
    }

    return result;
  }

}