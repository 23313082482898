import { Component, Input, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ModalComponent, NotificationsService, PageComponent } from 'projects/libraries/syslink-components/src/public-api';
import { ThirdsService } from '../../../thirds/thirds/thirds.service';
import { SaleContract } from './sale-contract.model';
import { SaleContractsService } from './sale-contracts.service';
import { FilterDescriptor } from 'devextreme/data';
import { jsonToOdataFormat } from 'projects/libraries/syslink-components/src/lib/helpers/tools';

@Component({
  selector: 'app-sale-contracts',
  templateUrl: './sale-contracts.component.html',
  styleUrls: ['./sale-contracts.component.scss']
})
export class SaleContractsComponent extends PageComponent {
  @Input() public filter: string | string[] = "";
  @Input() public canRefresh: boolean = false;
  @Input() showPager: boolean = true;

  public customerFilter: FilterDescriptor | Array<FilterDescriptor> = [['IsCustomer eq true']];

  @Input() public items?: SaleContract[];

  @ViewChild('initModal') initModal?: ModalComponent;

  public globalResult?: { MonthlyExTaxTotal: number, MonthlyInTaxTotal: number, AnnualExTaxTotal: number, AnnualInTaxTotal: number };

  constructor(
    public saleContractsService: SaleContractsService,
    public override activatedRoute: ActivatedRoute,
    public thirdsService: ThirdsService,
    public override translateService: TranslateService
  ) { super(); }


  public onAddButtonClicked() {
    this.initModal?.open();
  };

  public onCreateValidated(e: any) {
    if (!e.ThirdId.Id) {
      NotificationsService.sendErrorMessage("Please select third");
      return;
    }
    if (e.ThirdId.Blocked == true) {
      NotificationsService.sendErrorMessage("Third is blocked");
      return;
    }
    this.router.navigateByUrl('/sales/contracts/new?ThirdId=' + e.ThirdId.Id + (e.Subject != undefined ? '&Subject=' + e.Subject : '') + (e.ExternalReference != undefined ? '&ExternalReference=' + e.ExternalReference : ''));
 
    // this.router.navigate(['new'], {
    //   relativeTo: this.activatedRoute, queryParams: {
    //     ThirdId: e.ThirdId.Id,
    //     Subject: e.Subject
    //   }
    // });
  }

  public async onFilterValueChange(e: any[]) {
    var request = await this.saleContractsService.getStatistics(e);
    if(!request){
      this.globalResult = undefined;
      return;
    }
    this.globalResult = jsonToOdataFormat(request);
  }
}