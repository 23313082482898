import { Injectable } from '@angular/core';
import { BankReconciliationLineStatus } from './bank-reconciliation-line-status.model';
import { ODataService } from '../../../core/services/oData.service';

@Injectable({
  providedIn: 'root'
})
export class BankReconciliationLineStatusesService extends ODataService<BankReconciliationLineStatus> {
  public url = 'BankReconciliationLineStatus';

}