import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CurrenciesService } from '../../../base/currencies/currency.service';
import { UnitsService } from '../../../base/units/unit.service';
import { Brand } from '../../brands/brand.model';
import { BrandsService } from '../../brands/brand.service';
import { Product } from '../product.model';
import { ProductsService } from '../product.service';

@Component({
  selector: 'app-product-sale-form',
  templateUrl: './product-sale-form.component.html',
  styleUrls: ['./product-sale-form.component.scss']
})
export class ProductSaleFormComponent {
  @Input() public element: Product = new Product();
  @Output() public elementChange: EventEmitter<Product> = new EventEmitter<Product>();
  @Output() public basePriceChanged: EventEmitter<number> = new EventEmitter<number>();
  @Output() public customerCatalogChanged: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    public brandsService: BrandsService,
    public currenciesService: CurrenciesService,
    public unitsService: UnitsService,
    public productsService :ProductsService
  ) { }

  public onBasePriceChange() {
    if (this.element.ForcedSalePrice == null) this.element.ForcedSalePrice = 0;
    this.element = this.productsService.reloadPrice(this.element);
  }

  public async customNewBrandValue(customeValue: string) {
    if (customeValue == '') return;
    this.element.BrandId = await this.brandsService.insert(new Brand({ Code: customeValue, Name: customeValue }));
  }
}


