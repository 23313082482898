<ng-container *ngFor="let participant of element.TaskParticipants; let i = index; let last = last">
    <div
        class="h-100 d-flex mb-1 pb-1 justify-content-center align-items-center gap-2"
        [class.border-bottom]="!last"
    >
        <i
            *ngIf="!participant.IsUser"
            class="fa-solid fa-building bg-light text-center text-gray mx-2"
        ></i>
        <i
            *ngIf="participant.IsUser"
            class="fa-solid fa-user bg-light text-center text-gray mx-2"
        ></i>

        <div class="align-items-center" style="width: 38%;">
            <a
                class="text-gray fst-italic fw-light"
                role="button"
                target="_blank"
            >
                {{participant.FormattedName}}
            </a>
        </div>
        <div
            *ngIf="!disabled"
            class="d-flex align-items-center gap-2 mx-2"
        >

            <i
                class="fa-solid fa-pencil text-center text-gray"
                role="button"
                (click)="onUpdateParticipantBtnClicked(participant,i)"
                [title]="'Update' | translate"
            ></i>
            <i
                class="fa-solid fa-trash text-center text-danger"
                role="button"
                (click)="onDeleteParticipantBtnClicked(participant)"
                [title]="'Delete' | translate"
            ></i>
        </div>
    </div>
</ng-container>

<div
    *ngIf="!disabled"
    class="full-width-container"
>
    <syslink-button
        class="full-width-button"
        icon="plus"
        (click)="onAddNewParticipantBtnClicked()"
    ></syslink-button>
</div>

<syslink-modal
    #editParticipantModal
    title="User"
    width="350px"
    (onValidate)="editParticipantModal.data.newItem==false ? onUpdate(editParticipantModal.data.selectedUser,editParticipantModal.data.index) : onCreate(editParticipantModal.data.selectedUser)"
    [validateText]="(participant.Id ? 'Save' : 'Create')"
    cancelText="Cancel"
    >
    <app-task-participant-modal
        #taskParticipantModal
        *ngIf="userIsLoaded"
        [(element)]="participant"
        [items]="userAndUserGroupItems"
        [(selectedUser)]="editParticipantModal.data.selectedUser"
    ></app-task-participant-modal>
</syslink-modal>

<syslink-confirm-modal
    #deleteConfirm
    title="Delete"
    content="DeleteConfirmMessage"
    (onValidate)="onDelete()"
></syslink-confirm-modal> 