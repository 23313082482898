import { Component, EventEmitter, Input, Output, ViewChild, createPlatform } from '@angular/core';
import { ThirdContactInformation } from './third-contact-informations/third-contact-information.model';
import { ConfirmModalComponent, ModalComponent, NotificationsService } from 'projects/libraries/syslink-components/src/public-api';
import { ThirdContactInformationsService } from './third-contact-informations/third-contact-informations.service';
import { Third } from '../third.model';
import { ViewComponent } from 'projects/libraries/syslink-components/src/lib/helpers/view/view.component';
import { AppInjectorService } from 'projects/libraries/syslink-components/src/lib/services/app-injector.service';

@Component({
  selector: 'app-third-contacts',
  templateUrl: './third-contacts.component.html',
  styleUrls: ['./third-contacts.component.scss']
})
export class ThirdContactsComponent extends ViewComponent {

  @Input() public element: Third = new Third();
  @Output() elementChange: EventEmitter<Third> = new EventEmitter<Third>();

  @Input() public canUpdate: boolean = true;

  @ViewChild('editModal') public editModal: ModalComponent = new ModalComponent();
  @ViewChild('deleteConfirm') public deleteConfirm: ConfirmModalComponent = new ConfirmModalComponent();

  public contactInformation: ThirdContactInformation = new ThirdContactInformation();

  constructor(
    private thirdContactInformationsService: ThirdContactInformationsService,
  ) {
    super();
  }

  public async initModalDatas(contactInformation?: ThirdContactInformation) {
    this.contactInformation = contactInformation ?? await this.thirdContactInformationsService.getInstance();
  }
  // Create
  // ------
  public async onCreateBtnClicked() {
    this.contactInformation = new ThirdContactInformation();
    await this.initModalDatas();
    this.editModal.open({ newItem: true });
  }

  public async onCreate(contactInformation: ThirdContactInformation) {
    this.checkFavoriteElement(contactInformation);
    this.element.ContactInformations.push(contactInformation);
    this.editModal.close();
  }

  // ----------------------------------------------

  // Update
  // ------
  public async onUpdateBtnClicked(contactInformation: ThirdContactInformation) {
    if (!this.authService.hasPermission(this.newBasePermissionKey + '.contacts.update')) {
      NotificationsService.sendErrorMessage("You do not have the required permission!");
      return;
    }
    await this.initModalDatas(contactInformation);
    this.editModal.open({ newItem: false });
  }

  public async onUpdate(contactInformation: ThirdContactInformation) {
    this.element.ContactInformations.map((e: ThirdContactInformation) => {
      if (e.Id !== contactInformation.Id) return;
      e = contactInformation;
    });
    this.checkFavoriteElement(contactInformation);
    this.editModal.close();
  }
  // ----------------------------------------------

  // Delete
  // ------
  public onDeleteBtnClicked(contactInformation: ThirdContactInformation) {
    if (!contactInformation.Id) {
      var index = this.element.ContactInformations.findIndex((e: ThirdContactInformation) =>
        e.Value == contactInformation.Value &&
        e.ContactQualifierId?.Id == contactInformation.ContactQualifierId?.Id &&
        e.ContactTypeId?.Id == contactInformation.ContactTypeId?.Id &&
        e.AddressId?.Id == contactInformation.AddressId?.Id &&
        e.UsedForQuote == contactInformation.UsedForQuote &&
        e.UsedForDelivery == contactInformation.UsedForDelivery &&
        e.UsedForInvoice == contactInformation.UsedForInvoice &&
        e.IsFavorite == contactInformation.IsFavorite &&
        e.IsPublic == contactInformation.IsPublic
      );
      this.element.ContactInformations.splice(index, 1);
      return;
    }
    if (!this.authService.hasPermission(this.newBasePermissionKey + '.contacts.delete')) {
      NotificationsService.sendErrorMessage("You do not have the required permission!");
      return;
    }
    this.deleteConfirm.open({ contactInformationId: contactInformation.Id });
  }

  public async onDelete() {
    this.element.ContactInformations = this.element.ContactInformations.filter((e: ThirdContactInformation) => e.Id !== this.deleteConfirm.modal.data.contactInformationId);
    AppInjectorService.config.setModificationGuard(true);
    this.deleteConfirm.close();
  }

  // ----------------------------------------------
  private checkFavoriteElement(contactInformation: ThirdContactInformation) {
    if (contactInformation.IsFavorite == true) {
      var similarElements = this.element.ContactInformations.filter((c: ThirdContactInformation) => {
       return ((c.Id == undefined || c.Id != contactInformation.Id) &&
        c.ContactTypeId?.Id == contactInformation.ContactTypeId?.Id &&
        c.IsFavorite == true)});
      similarElements.forEach(e => {
        e.IsFavorite = false;
      }
      )
    }
    else {
      if (!this.element.ContactInformations.some((c: ThirdContactInformation) => c.ContactTypeId?.Code == contactInformation.ContactTypeId?.Code)) {
        contactInformation.IsFavorite = true;
      }
    }
  }
}
