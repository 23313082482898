<syslink-input-text
    label="Internal reference"
    [(model)]="element.InternalRef"
></syslink-input-text>

<syslink-input-text
    label="External reference"
    [(model)]="element.ExternalRef"
></syslink-input-text>

<syslink-tagbox
    label="Tags"
    [store]="taskTagsService.store"
    displayKey="Name"
    [select]="['Id', 'Name']"
    [(model)]="element.Tags"
></syslink-tagbox>

<syslink-select
    label="Priority"
    [store]="taskPrioritiesService.store"
    [(model)]="element.TaskPriorityId"
    [select]="['Id', 'Name']"
    [allowClearing]="true"
></syslink-select>

<syslink-wysiwyg
    name="Note"
    label="Note"
    [(model)]="element.Note"
    [showTable]="false"
    [toolbar]="false"
    height="140"
    minHeight="140"
    [labelMode]="'hidden'"
></syslink-wysiwyg>
