<div class="third-reference-form">
  <syslink-input-text
    label="External reference"
    [(model)]="element.ExternalRef"
  ></syslink-input-text>
  <syslink-select
      label="Language"
      [store]="languagesService.store"
      [(model)]="element.LanguageId"
    ></syslink-select>
    <syslink-wysiwyg
      name="Note"
      label="Note"
      [(model)]="element.Note"
      [showTable]="false"
      [toolbar]="false"
      height="140"
      minHeight="140"
      [labelMode]="'hidden'"
    ></syslink-wysiwyg>
</div>
