import { Injectable } from '@angular/core';
import { ODataService } from '../../../core/services/oData.service';
import { BankReconciliationHeaderStatus } from './bank-reconciliation-header-status.model';

@Injectable({
  providedIn: 'root'
})
export class BankReconciliationHeaderStatusService extends ODataService<BankReconciliationHeaderStatus> {
  public url = 'BankReconciliationHeaderStatus';

  public override async getInstance(params?: Partial<BankReconciliationHeaderStatus>): Promise<BankReconciliationHeaderStatus> {
    var element = new BankReconciliationHeaderStatus({
      ...params,
      Date: new Date()
    });
    return element;
  }

}
