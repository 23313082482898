import { Language } from "../../../base/languages/language";
import { Third } from "../../../thirds/thirds/third.model";
import { ActionGroup } from "../action-groups/action-group.model";
import { UserGroup } from "../user-groups/user-group.model";
import { UserPermission } from "../user-permissions/user-permission.model";
import { UserRole } from "../user-roles/user-role.model";
import { UserStorage } from "../user-storages/user-storage.model";

export class User {
    public Oid?: string;
    public LanguageId?: Language;
    public UserName?: string;
    public Email?: string;
    public Password?: string;
    public PasswordConfirmation?: string;
    public ResetPassword?: boolean = false;
    public ThirdId?: Third;
    public IsActive?: boolean;
    public Name?: string;

    public Groups: UserGroup[] = [];
    public UserRoles: UserRole[] = [];
    public UserPermissions: UserPermission[] = [];

    public BackgroundColor: string = "";
    public ForegroundColor: string = "";

    public AllPermissions: UserPermission[] = [];
    public ActionGroups: ActionGroup[]=[];

    public UserStorages: UserStorage[]=[];

    constructor(params?: Partial<User>) {
        Object.assign(this, params);
    }
}
