import { Injectable } from '@angular/core';
import { ThirdRelation } from './third-relation.model';
import { Third } from '../third.model';
import { ODataService } from '../../../core/services/oData.service';
import { LoadOptions } from 'devextreme/data';

@Injectable({
  providedIn: 'root'
})
export class ThirdRelationsService extends ODataService<ThirdRelation> {
  public url = 'ThirdRelation';
  public override defaultOptions: LoadOptions = {
    expand: [
    ]
  }

  public async getRelationsByThird(thirdId: number): Promise<ThirdRelation[]> {
    let options = {
      ...this.defaultOptions,
      filter: "ToThirdId.Id eq " + thirdId + "or FromThirdId.Id eq " + thirdId,
      expand: ["ToThirdId", "FromThirdId", "ThirdRelationTypeId"]
    };
    return await this.load(options);
  }

  public override async getInstance(third?: Third): Promise<any> {
    return new ThirdRelation({
      FromThirdId: third,
      IsPublic: true,
    });
  };

  public override format(element: ThirdRelation): any {
    var result: any = {
      ...element,
      FromThirdId: element.FromThirdId?.Id,
      ThirdRelationTypeId: element.ThirdRelationTypeId?.Id,
      ToThirdId: element.ToThirdId?.Id
    };

    return result;
  }

  public isSimilar(obj1: any, obj2: any): boolean {
    if (obj1.Id != obj2.Id) {
      return false;
    }
    else if (obj1.FromThirdId.Id != obj2.FromThirdId.Id) {
      return false;
    }
    else if (obj1.ToThirdId.Id != obj2.ToThirdId.Id) {
      return false;
    }
    else if (obj1.IsPublic != obj2.IsPublic) {
      return false;
    }

    return true;
  }
}
