import { Component, Input, OnInit } from '@angular/core';
import { VersionDetail } from './version-detail.model';
import { VersionDetailsService } from './version-details.service';
import { GroupPageService } from '../group-pages/group-page.service';

@Component({
  selector: 'app-version-details',
  templateUrl: './version-details.component.html',
  styleUrls: ['./version-details.component.scss']
})
export class VersionDetailsComponent implements OnInit {
  @Input() public currentVersion: string = "";

  public versions: string[] = [];

  constructor(
    private versionDetailsService: VersionDetailsService,
    public groupPageService: GroupPageService
  ) { }
  async ngOnInit(): Promise<void> {
    if(this.groupPageService.versionDetails == undefined){
      this.groupPageService.versionDetails = await this.versionDetailsService.load(this.versionDetailsService.defaultOptions);
    }
    this.loadVersions();
  }

  public loadVersions() {
    this.versions = [];
    var version = "";
    this.groupPageService.versionDetails?.forEach((detail: VersionDetail) => {
      if (version != detail.Version) {
        version = detail.Version;
        this.versions.push(detail.Version);
      }
    });
  }

  public changeVersionDetail(version: string) {
    this.currentVersion = version;
    this.loadVersions();
  }
}
