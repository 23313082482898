import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'syslink-file-uploader',
  templateUrl: './file-uploader.component.html',
  styleUrls: ['./file-uploader.component.scss']
})
export class FileUploaderComponent {
  @Input() public files: any[] = [];
  @Output() public filesChange: EventEmitter<any[]> = new EventEmitter<any[]>();

  @Input() public buttonText: string = "Choose a file";
  @Input() public label: string = "";
  @Input() public accept: string = "*";
  @Input() public allowedFileExtensions: string[] = [];
  @Input() public multiple: boolean = false;

  onFileSelected(event: any) {
    const files = event.value;
    if (files.length > 0) {
      this.files = files[0];
      this.filesChange.emit(this.files);
    }
  }
}
