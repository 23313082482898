import { Component, Input, ViewChild } from '@angular/core';
import { Product } from '../product.model';
import { BaseGridComponent } from 'projects/libraries/syslink-components/src/lib/grid/base-grid/base-grid.component';
import { SyslinkColumn } from 'projects/libraries/syslink-components/src/lib/helpers/SyslinkColumn';
import { BooleanCellData } from 'projects/libraries/syslink-components/src/lib/grid/cells/boolean-cell/boolean-cell.component';
import { ProductContexMenuItemActionCode, ProductsService } from '../product.service';
import { ConfirmModalComponent, GridComponent, NotificationsService } from 'projects/libraries/syslink-components/src/public-api';
import { ContextMenuItemAction } from 'projects/libraries/syslink-components/src/lib/context-menus/context-menu-item-action';
import { ContextMenuItemClickEvent } from 'devextreme/ui/file_manager';
import { NgxUiLoaderService } from 'ngx-ui-loader';

@Component({
  selector: 'app-product-grid',
  templateUrl: './product-grid.component.html',
  styleUrls: ['./product-grid.component.scss']
})
export class ProductGridComponent extends BaseGridComponent<Product> {

  public override canDelete: boolean = true;
  @Input() storageKey: string = 'grid-products';
  @Input() public showContextMenu: boolean = false;

  @ViewChild('gridComponent') public gridComponent?: GridComponent;
  @ViewChild('deleteConfirmModal') public deleteConfirmModal: ConfirmModalComponent = new ConfirmModalComponent();

  public columns: SyslinkColumn[] = [
    new SyslinkColumn({ field: 'No', label: 'Id', width: 80, sortable: true, sortIndex: 0, sortDirection: 'desc' }),
    new SyslinkColumn({ field: 'InternalReference', label: 'Internal reference', width: 200 }),
    new SyslinkColumn({ field: 'Name', label: 'Name' }),
    new SyslinkColumn({ field: 'ProductCategoryId.Name', label: 'Products Categories', width: 200 }),
    new SyslinkColumn({ field: 'Stock', label: 'Stock', width: 80, type: 'number', cellTemplate: 'number-cell', technical: this.authService.hasPermission('root-stock-moves'), data: { shouldHideData(lineData: Product) { return !lineData.Stockable } } }),
    new SyslinkColumn({ field: 'StockMove', label: 'Stock', width: 80, type: 'number', technical: !this.authService.hasPermission('root-stock-moves') }),
    new SyslinkColumn({ field: 'DefaultUnitId.Name', label: 'Unit', width: 120 }),
    new SyslinkColumn({ field: 'SalePrice', label: 'Sale price ex tax', data: { key: 'TotalPrecision', shouldHideData(lineData: Product) { return !lineData.Sellable } }, cellTemplate: 'currency-cell', width: 120, type: 'number', filterable: false, headerFilterable: false, sortable: false }),
    new SyslinkColumn({ field: 'PurchasePrice', label: 'Purchase price ex tax', data: { key: 'TotalPrecision', shouldHideData(lineData: Product) { return !lineData.Purchasable } }, cellTemplate: 'currency-cell', width: 120, type: 'number', filterable: false, headerFilterable: false, sortable: false }),
    new SyslinkColumn({ field: 'SalePriceInTax', label: 'Sale price in tax', data: { key: 'TotalPrecision', shouldHideData(lineData: Product) { return !lineData.Sellable } }, cellTemplate: 'currency-cell', width: 120, type: 'number', filterable: false, headerFilterable: false, sortable: false }),
    new SyslinkColumn({ field: 'PurchasePriceInTax', label: 'Purchase price in tax', data: { key: 'TotalPrecision', shouldHideData(lineData: Product) { return !lineData.Purchasable } }, cellTemplate: 'currency-cell', width: 120, type: 'number', filterable: false, headerFilterable: false, sortable: false }),
    new SyslinkColumn({ field: 'Purchasable', label: 'Purchasable', type: 'boolean', cellTemplate: 'boolean-cell', data: new BooleanCellData({ type: 'box', }), width: 80 }),
    new SyslinkColumn({ field: 'Sellable', label: 'Sellable', type: 'boolean', cellTemplate: 'boolean-cell', data: new BooleanCellData({ type: 'box', }), width: 80 }),
    new SyslinkColumn({ field: 'Stockable', label: 'Stockable', type: 'boolean', cellTemplate: 'boolean-cell', data: new BooleanCellData({ type: 'box', }), width: 80 }),
    new SyslinkColumn({ field: 'Blocked', label: 'Blocked', type: 'boolean', cellTemplate: 'boolean-cell', data: new BooleanCellData({ type: 'box', trueColor: 'danger', falseColor: 'success' }), width: 80 })
  ];

  constructor(
    public ngxUiLoaderService: NgxUiLoaderService,
    public productsService: ProductsService,
  ) {
    super();
  }

  // ContextMenu
  // -----------

  public getContextMenuText(code: ProductContexMenuItemActionCode, product: Product): string {
    switch (code) {
      case ProductContexMenuItemActionCode.Blocked:
        return product.Blocked ? "Active" : "Blocked";
      case ProductContexMenuItemActionCode.Sellable:
        return product.Sellable ? "Not Sellable" : "Sellable";
      case ProductContexMenuItemActionCode.Purchasable:
        return product.Purchasable ? "Not Purchasable" : "Purchasable";
    }
    return "Error";
  }

  contextMenuPreparing(e: any) {
    if (!this.showContextMenu) return;
    if (e.target !== 'content') return;

    let product = e.row?.data;
    if (!product) return;

    let contextMenuItems: ContextMenuItemAction[] = [
      { code: ProductContexMenuItemActionCode.Copy, text: "Copy", onItemClick: (e: any) => this.onMenuItemClicked(e, product), visible: this.authService.hasPermission('products.products.list.contextMenu.copy') },//onItemClick: (e: any) => this.onMenuItemClicked(e)
      { code: ProductContexMenuItemActionCode.Blocked, onItemClick: (e: any) => this.onMenuItemClicked(e, product), text: this.getContextMenuText(ProductContexMenuItemActionCode.Blocked, product), visible: this.authService.hasPermission('products.products.list.contextMenu.blocked') },
      { code: ProductContexMenuItemActionCode.Sellable, onItemClick: (e: any) => this.onMenuItemClicked(e, product), text: this.getContextMenuText(ProductContexMenuItemActionCode.Sellable, product), visible: this.authService.hasPermission('products.products.list.contextMenu.sellable') },
      { code: ProductContexMenuItemActionCode.Delete, onItemClick: (e: any) => this.onMenuItemClicked(e, product), text: "Delete", visible: this.authService.hasPermission('products.products.list.contextMenu.delete') },
    ];
    if (
      contextMenuItems.filter(
        (items: ContextMenuItemAction) => items.visible == false
      ).length == contextMenuItems.length
    ) {
      return;
    }
    e.items = this.gridComponent?.formatContextMenuItems(contextMenuItems, e.row?.data);
  }
  boundParentMethod = this.contextMenuPreparing.bind(this);

  public async onMenuItemClicked(e: ContextMenuItemClickEvent, product: Product) {
    switch (e.itemData.code) {
      case ProductContexMenuItemActionCode.Copy:
        await this.copy(product);
        break;
      case ProductContexMenuItemActionCode.Blocked:
        product.Blocked = !product.Blocked;
        await this.updateProduct(product);
        break;
      case ProductContexMenuItemActionCode.Sellable:
        product.Sellable = !product.Sellable;
        await this.updateProduct(product);
        break;
      case ProductContexMenuItemActionCode.Delete:
        this.deleteConfirmModal.open(product);
        break;
    }
  }

  public async onDeleteProduct(product: Product) {
    if (!product.Id) return;
    this.ngxUiLoaderService.start();
    await this.productsService.remove(product.Id)
    this.gridComponent?.grid?.instance.refresh();
    this.deleteConfirmModal.close();
    this.ngxUiLoaderService.stop();
  }

  private async updateProduct(product: Product) {
    if (!product.Id) return;
    await this.productsService.update(product.Id, this.productsService.format(product))
  }

  private async copy(product:Product){
    if (!product.Id) return;
    this.ngxUiLoaderService.start();
    let newProductId = await this.productsService.copy(product.Id);
    this.ngxUiLoaderService.stop();
    this.router.navigateByUrl("/products/products/"+newProductId);
  }
}
