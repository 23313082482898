import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { ThirdsService } from 'projects/erp-app/src/app/thirds/thirds/thirds.service';
import { ModalComponent, NotificationsService } from 'projects/libraries/syslink-components/src/public-api';
import { TasksService } from '../tasks.service';
import { WorkTypesService } from '../../../works/work-types/work-types.service';
import { TaskStatusesService } from '../../task-statuses/task-statuses.service';
import { Task } from '../task.model';
import { TranslateService } from '@ngx-translate/core';
import { TaskStatus } from '../../task-statuses/task-status.model';
import { Entity } from 'projects/erp-app/src/app/core/models/entity';
import { ODataService } from 'projects/erp-app/src/app/core/services/oData.service';
import { TaskTaskStatusesService } from '../../task-task-statuses/task-task-statuses.service';
import { TaskTaskStatus } from '../../task-task-statuses/task-task-status.model';
import { ViewComponent } from 'projects/libraries/syslink-components/src/lib/helpers/view/view.component';

@Component({
  selector: 'app-task-multiple-edit-modal',
  templateUrl: './task-multiple-edit-modal.component.html',
  styleUrls: ['./task-multiple-edit-modal.component.scss']
})
export class TaskMultipleEditModalComponent extends ViewComponent {
  @Input() tasks: Task[] = [];
  @Output() public validate: EventEmitter<any> = new EventEmitter<any>();
  @Output() public validated: EventEmitter<any> = new EventEmitter<any>();
  @Output() public onCancel: EventEmitter<any> = new EventEmitter<any>();
  
  @ViewChild("modal") modal?: ModalComponent;

  constructor(
    public WorkTypesService: WorkTypesService,
    public tasksService: TasksService,
    public thirdsService: ThirdsService,
    public taskStatusesService: TaskStatusesService,
    public translateService: TranslateService,
    private taskTaskStatusesService: TaskTaskStatusesService
  ) {
    super();
  }

  public open() {
    this.modal?.open();
  }
  public close() {
    this.modal?.close();
    if (this.modal) {
      this.modal.data.Users = undefined;
      this.modal.data.UserGroups = undefined;
    }
    this.onCancel.emit();
  }
  public loadData() {
    if (this.modal && this.tasks.length > 0) {
      this.modal.data.ThirdId = (this.tasks.filter((t: Task) => t.ThirdId?.Id == this.tasks[0].ThirdId?.Id)).length == this.tasks.length ? this.tasks[0].ThirdId?.Id : undefined;
      this.modal.data.TypeId = (this.tasks.filter((t: Task) => t.TypeId?.Id == this.tasks[0].TypeId?.Id)).length == this.tasks.length ? this.tasks[0].TypeId?.Id : undefined;
      this.modal.data.Status = (this.tasks.filter((t: Task) => t.CurrentStatusLink?.StatusId.Id == this.tasks[0].CurrentStatusLink?.StatusId.Id)).length == this.tasks.length ? this.tasks[0].CurrentStatusLink?.StatusId.Id : undefined;
      this.modal.data.StartDate = (this.tasks.filter((t: Task) => t.StartDate.getDate() == this.tasks[0].StartDate.getDate())).length == this.tasks.length ? this.tasks[0].StartDate : undefined;
      this.modal.data.EndDate = (this.tasks.filter((t: Task) => t.EndDate?.getDate() == this.tasks[0].EndDate?.getDate())).length == this.tasks.length ? this.tasks[0].EndDate : undefined;
    }
  }
  public isEqual(array1: any[], array2: any[], fieldSort: string = "Id") {
    if (array1.length !== array2.length) return false
    // Sort with field
    // ---------------
    array1.sort((a, b) => { return a[fieldSort] - b[fieldSort]; });
    array2.sort((a, b) => { return a[fieldSort] - b[fieldSort]; });
    return array1.every((value, index) => value.Id === array2[index].Id)
  }

  public async onValidate(e: any) {
    if (!this.authService.hasPermission('time-management.tasks.modals.update')) {
      NotificationsService.sendErrorMessage("You do not have the required permission!");
      return;
    }

    if (this.validate.observed) {
      this.validate.emit(e);
    }
    else {      
      if (this.modal?.data.ThirdId) {
        this.tasks.map((t: Task) => {
          t.ThirdId = this.modal?.data.ThirdId;
        })
      }
      if (this.modal?.data.TypeId?.Id) {
        this.tasks.map((t: Task) => {
          t.TypeId = this.modal?.data.TypeId;
        })
      }
      if (this.modal?.data?.Status?.Id) {
        await this.createStatus(this.modal?.data?.Status);
      }
      if (this.modal?.data.StartDate) {
        this.tasks.map((t: Task) => {
          t.StartDate = this.modal?.data.StartDate;
        })
      }
      if (this.modal?.data.EndDate) {
        this.tasks.map((t: Task) => {
          t.EndDate = this.modal?.data.EndDate;
        })
      }
      await this.saveTask();
      this.modal?.close();
      this.validated.emit();
    }
  }
  public async saveTask() {
    for (const element of this.tasks) {
        await this.tasksService.createOrUpdate(element);
    };
  }
  public async createStatus(status: TaskStatus) {
    for (var element of this.tasks) {
      if (element.Id) {
        const link = await this.taskTaskStatusesService.getInstance({
          TaskId: element,
          StatusId: { Id: status.Id },
          Date: new Date()
        })
        await this.createOrUpdate(this.taskTaskStatusesService, <TaskTaskStatus>link, true);
      }
    }
  }

  private async createOrUpdate<T extends Entity>(service: ODataService<T>, object?: T, forceRefresh: boolean = false): Promise<T | undefined> {
    if (!object) return;
    let response: T = object?.Id ? await service.update(object.Id, service.format(object)) : await service.insert(service.format(object));
    if (!response?.Id) {
      NotificationsService.sendErrorMessage("Unable to create or update record: %s", [this.translateService.instant("Person")]);
      return;
    }
    if (forceRefresh) {
      response = await service.findByID(response.Id);
    }
    return response;
  }


}
