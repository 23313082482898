<syslink-input-text
  #productNameInput
  name="Name"
  label="Name"
  [(model)]="element.Name"
></syslink-input-text>

<syslink-select
  label="Category"
  [store]="productCategoriesService.store"
  [model]="element.ProductCategoryId"
  (modelChange)="onChangeCategory($event)"
></syslink-select>
<div class="row">
  <div class="col-3 {{element.Purchasable?'syslink_succes_color':'syslink_danger_color'}}">
    <syslink-switch
      [(model)]="element.Purchasable"
      (modelChange)="productsService.onStatusSwitch('Purchasable')"
      type="box"
      [falseText]="'Not Purchasable' | translate"
      [trueText]="'Purchasable' | translate"
    ></syslink-switch>
  </div>
  <div class="col-3 {{element.Sellable?'syslink_succes_color':'syslink_danger_color'}}">
    <syslink-switch
      [(model)]="element.Sellable"
      (modelChange)="productsService.onStatusSwitch('Sellable')"
      type="box"
      [falseText]="'Not Sellable' | translate"
      [trueText]="'Sellable' | translate"
    ></syslink-switch>
  </div>
  <div class="col-3 {{element.Stockable?'syslink_succes_color':'syslink_danger_color'}}">
    <syslink-switch
      [(model)]="element.Stockable"
      (modelChange)="
        productsService.onStatusSwitch('Stockable'); element.Stock = 0
      "
      type="box"
      [falseText]="'Not Stockable' | translate"
      [trueText]="'Stockable' | translate"
    ></syslink-switch>
  </div>
  <div class="col-3 {{!element.Blocked?'syslink_succes_color':'syslink_danger_color'}}">
    <syslink-switch
      [model]="!element.Blocked"
      (modelChange)="element.Blocked = !$event"
      type="box"
      [falseText]="'Blocked' | translate"
      [trueText]="'Active' | translate"
    ></syslink-switch>
  </div>
</div>

<syslink-confirm-modal
  #changeAccountConfirm
  title="Change category"
  (onValidate)="onChangeAccount()"
  validateText="Validate"
  cancelText="Cancel"
  width="350px"
  content="Do you want to change sale and purchase accounts?"
></syslink-confirm-modal>