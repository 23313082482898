import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { DocumentDatasService } from 'projects/erp-app/src/app/base/documents/document-datas/document-datas.service';
import { DocumentStatus } from 'projects/erp-app/src/app/base/documents/document-statuses/document-status.model';
import { DocumentStatusChangedEvent } from 'projects/erp-app/src/app/base/documents/document-statuses/document-statuses.component';
import { ReportsService } from 'projects/erp-app/src/app/connectors/reports/reports.service';
import { ThirdsService } from 'projects/erp-app/src/app/thirds/thirds/thirds.service';
import { NotificationsService } from 'projects/libraries/syslink-components/src/public-api';
import { SaleDocument } from '../sale-document.model';
import { SaleDocumentsService } from '../sale-documents.service';
import { SaleDocumentLine } from '../../sale-document-lines/sale-document-line.model';
import { SaleDocumentLinesService } from '../../sale-document-lines/sale-document-lines.service';
import { SaleDocumentStatus } from '../../sale-document-statuses/sale-document-status.model';
import { SaleDocumentHeaderStatus } from '../../sale-document-header-status/sale-document-header-status.model';
import { SaleDocumentHeaderStatusesService } from '../../sale-document-header-status/sale-document-header-statuses.service';
import { SaleDocumentStatusesService } from '../../sale-document-statuses/sale-document-statuses.service';
import { getNew } from 'projects/libraries/syslink-components/src/lib/helpers/tools';
import { DocumentDetailsComponent } from 'projects/erp-app/src/app/base/documents/documents/document-details/document-details.component';
import { DocumentsService } from 'projects/erp-app/src/app/base/documents/documents/documents.service';
import { PaymentsService } from 'projects/erp-app/src/app/base/payments/payments.service';
import { DocumentRelationsService } from 'projects/erp-app/src/app/base/documents/document-relations/document-relation.service';
import { SaleDocumentLineDiscountOperationsService } from '../../sale-document-line-discount-operations/sale-document-line-discount-operations.service';
import { AppInjectorService } from 'projects/libraries/syslink-components/src/lib/services/app-injector.service';
import { ModificationService } from 'projects/erp-app/src/app/core/services/modification.service';
import { SaleInvoiceHeaderStatusesService } from '../../../sale-invoices/sale-invoice-header-statuses/sale-invoice-header-statuses.service';
import { SaleCreditNoteHeaderStatusesService } from '../../../sale-credit-notes/sale-credit-note-header-statuses/sale-credit-note-header-statuses.service';
import { SaleOrderHeaderStatusesService } from '../../../sale-orders/sale-order-header-statuses/sale-order-header-statuses.service';
import { SaleQuoteHeaderStatusesService } from '../../../sale-quotes/sale-quote-header-statuses/sale-quote-header-statuses.service';
import { DocumentRelationsComponent } from 'projects/erp-app/src/app/base/documents/documents/document-relations/document-relations.component';
import { SaleContractHeaderStatusesService } from '../../../sale-contracts/sale-contract-header-statuses/sale-contract-header-statuses.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { DocumentData } from 'projects/erp-app/src/app/base/documents/document-datas/document-data.model';
import { DocumentLineRelationsService } from 'projects/erp-app/src/app/base/documents/document-line-relations/document-relation.service';
import { FileData } from 'projects/erp-app/src/app/connectors/filesystem/file-data';
import { from } from 'rxjs';

@Component({
  selector: 'app-sale-document-details',
  templateUrl: './sale-document-details.component.html',
  styleUrls: ['./sale-document-details.component.scss']
})
export class SaleDocumentDetailsComponent<
  TSaleDocument extends SaleDocument,
  TSaleDocumentService extends SaleDocumentsService<TSaleDocument, TSaleDocumentStatus>,
  TSaleDocumentLine extends SaleDocumentLine,
  TSaleDocumentLineService extends SaleDocumentLinesService<SaleDocumentLine>,
  TSaleDocumentStatus extends SaleDocumentStatus,
  TSaleDocumentStatusesService extends SaleDocumentStatusesService<TSaleDocumentStatus>,
  TSaleDocumentHeaderStatus extends SaleDocumentHeaderStatus,
  TSaleDocumentHeaderStatusesService extends SaleDocumentHeaderStatusesService<TSaleDocumentHeaderStatus>,
> extends DocumentDetailsComponent implements OnInit {

  public override element: TSaleDocument = <TSaleDocument>getNew<TSaleDocument>();
  public override documentUrlController: string = "SaleDocument";

  public saleDocumentService: TSaleDocumentService = <TSaleDocumentService>getNew<TSaleDocumentService>();
  public saleDocumentLinesService: TSaleDocumentLineService = <TSaleDocumentLineService>getNew<TSaleDocumentLineService>();
  public saleDocumentStatusesService: TSaleDocumentStatusesService = <TSaleDocumentStatusesService>getNew<TSaleDocumentStatusesService>();
  public saleDocumentHeaderStatusesService: TSaleDocumentHeaderStatusesService = <TSaleDocumentHeaderStatusesService>getNew<TSaleDocumentHeaderStatusesService>();

  public availableStatuses: TSaleDocumentStatus[] = [];

  // public showExtensionDate: boolean = true;

  @ViewChild('documentRelation') public documentRelation?: DocumentRelationsComponent;
  // @ViewChild('createFromContractModal') public createFromContractModal: ModalComponent = new ModalComponent;
  // @ViewChild('addInvoiceFromContractModal') public addInvoiceFromContractModal: ModalComponent = new ModalComponent;
  // @ViewChild('saleDocumentRelation') public saleDocumentRelation?: DocumentRelationsComponent;
  // @ViewChild('saleDocumentOther') public saleDocumentOther: any;
  // @ViewChild('saleDocumentThird') public saleDocumentThird?: any;

  // // Contract
  // // --------
  // public InvoiceFromContract?: SaleInvoice;
  // public gridRelationToolbarItems: any[] = [];
  // public filterLinkContract: string | string[] = ['ContractId.Id eq null'];

  public thirdDocumentDataFilters: string | string[] = ["Roles/any(r:r/Code eq 'customer')"];

  constructor(
    public saleQuoteHeaderStatusesService: SaleQuoteHeaderStatusesService,
    public saleOrderHeaderStatusesService: SaleOrderHeaderStatusesService,
    public saleInvoiceHeaderStatusesService: SaleInvoiceHeaderStatusesService,
    public saleCreditNoteHeaderStatusesService: SaleCreditNoteHeaderStatusesService,
    public saleContractHeaderStatusesService: SaleContractHeaderStatusesService,
    public override ngxUiLoaderService: NgxUiLoaderService,
    public override activatedRoute: ActivatedRoute,
    public override reportsService: ReportsService,
    public override documentDatasService: DocumentDatasService,
    public thirdsService: ThirdsService,
    public override paymentsService: PaymentsService,
    public override documentRelationsService: DocumentRelationsService,
    public saleDocumentLineDiscountOperationsService: SaleDocumentLineDiscountOperationsService,
    public override documentService: DocumentsService,
    public override documentLineRelationsService: DocumentLineRelationsService,
    public override modificationService: ModificationService,
    public sanitizer : DomSanitizer
  ) {
    super(ngxUiLoaderService, reportsService, documentDatasService, documentService, paymentsService, documentRelationsService, documentLineRelationsService, modificationService);
   
  }

  override async ngOnInit(): Promise<void> {
    this.ngxUiLoaderService.start();

    var copyElement: any = localStorage.getItem("SaleHeader");
    if (this.router.url.includes("copy") && copyElement != undefined) {
      copyElement = JSON.parse(copyElement);
      this.breadcrumbsService.items.pop();
    }
    if (this.router.url.includes("copy") && copyElement == undefined) {
      this.goToUrl('../');
    }

    if (copyElement != undefined && copyElement != null) {
      copyElement.Report = undefined;
      copyElement.WorkTimeReport = undefined;
      copyElement.FirstReminder = undefined;
      copyElement.SecondReminder = undefined;
      copyElement.ThirdReminder = undefined;
      await this.loadData(copyElement);
      this.element.DocumentDataCollection = copyElement.DocumentDataCollection;
      // Todo check best method
      setTimeout(() => {
        this.saleDocumentContent?.setInsertLine((copyElement?.Lines?.length ?? 0) + 1);
      }, 100)
    }
    else {
      this.activatedRoute.data.subscribe(async ({ element }) => {
        if (element != undefined) {
          await this.loadData(element);
        }
      });
    }
  }

  private async loadData(element: any) {
    this.loadDependencies();
    if (!element.Id) {

      element = await this.initFromParams(element);
      element.ResponsibleUserId = this.authService.user?.ThirdId != undefined ? this.thirdsService.format(this.authService.user.ThirdId) : null;
    }
    this.element = element;
    this.element.Lines = this.saleDocumentLinesService.formatLines(element.Lines ?? []);
    await this.refresh();

    this.loadFormattedTitle((this.element?.No) ?? undefined);
    this.ngxUiLoaderService.stop();
  }

  private async initFromParams(element: any) {
    element = await this.defaultInitFromParams(element);
    element = await this.saleDocumentService.getInstance(element);
    return element;
  }

  public override async getDefaultToTypeStatusElement(currentElement: any, toType: string) {
    var newStatus = await this.saleDocumentHeaderStatusesService.getInstance();
    switch (toType) {
      case "SaleQuote":
        delete currentElement['ContractId'];
        delete currentElement['PeriodLabel'];
        delete currentElement['SequenceNo'];
        delete currentElement['SequenceNumber'];
        delete currentElement['UpdatedAt'];
        delete currentElement['UsedSequenceId'];
        var statuses = await this.saleQuoteHeaderStatusesService.load();
        newStatus.StatusId = statuses.find((s: any) => s.Code == "Quote.Draft");
        break;
      case "SaleOrder":
        delete currentElement['ContractId'];
        delete currentElement['PeriodLabel'];
        delete currentElement['SequenceNo'];
        delete currentElement['SequenceNumber'];
        delete currentElement['UpdatedAt'];
        delete currentElement['UsedSequenceId'];
        delete currentElement['DocumentDelayId'];
        var statuses = await this.saleOrderHeaderStatusesService.load();
        newStatus.StatusId = statuses.find((s: any) => s.Code == "Order.Draft");
        break;
      case "SaleInvoice":
        var statuses = await this.saleInvoiceHeaderStatusesService.load();
        newStatus.StatusId = statuses.find((s: any) => s.Code == "Invoice.Proforma");
        break;
      case "SaleCreditNote":
        delete currentElement['ContractId'];
        delete currentElement['PeriodLabel'];
        delete currentElement['SequenceNo'];
        delete currentElement['SequenceNumber'];
        delete currentElement['UpdatedAt'];
        delete currentElement['UsedSequenceId'];
        var statuses = await this.saleCreditNoteHeaderStatusesService.load();
        newStatus.StatusId = statuses.find((s: any) => s.Code == "CreditNote.Proforma");
        break;
      case "SaleContract":
        delete currentElement['ContractId'];
        delete currentElement['PeriodLabel'];
        delete currentElement['SequenceNo'];
        delete currentElement['SequenceNumber'];
        delete currentElement['UpdatedAt'];
        delete currentElement['UsedSequenceId'];
        delete currentElement['stopDate'];
        var statuses = await this.saleContractHeaderStatusesService.load();
        newStatus.StatusId = statuses.find((s: any) => s.Code == "Contract.Draft");
        break;
    }
    currentElement.CurrentStatusLink = newStatus;
    currentElement.CurrentStatusLink.Date = new Date();
    currentElement.Statuses = [];
    currentElement.Statuses.push(currentElement.CurrentStatusLink);
    return currentElement;
  }

  // Document Statuses
  // -----------------
  public override initStatusBar() {
    this.saleDocumentStatusesService.load().then((statuses: DocumentStatus[]) => {
      this.documentStatuses.statuses = statuses;
      this.documentStatuses.documentStatuses = this.element.Statuses || [];
      this.documentStatuses.refreshItems();
    });
  }

  public async onDocumentStatusChanged(event: DocumentStatusChangedEvent) {
    if (!this.authService.hasPermission(this.newBasePermissionKey + '.ChangeStatus')) {
      NotificationsService.sendErrorMessage("You do not have the required permission!");
      return;
    }

    if (!event.status || !event.status.Sequence || !this.element || !this.element.CurrentStatusLink || !this.element.CurrentStatusLink.StatusId) {
      NotificationsService.sendErrorMessage("Status error");
      return;
    }

    if (event.status.Sequence <= (this.element.CurrentStatusLink.StatusId.Sequence ?? 5)) {
      NotificationsService.sendErrorMessage("This status is impossible");
      return;
    }
    var statuses = await this.saleDocumentStatusesService.load();
    var status = statuses.find((s: any) => s.Code == event.status.Code);
    var newStatus = await this.saleDocumentHeaderStatusesService.getInstance();
    var intermediateStatus = statuses.filter((e: SaleDocumentStatus) => {
      return e.Sequence && status?.Sequence && this.element.CurrentStatusLink?.StatusId?.Sequence
        && status?.Sequence != e.Sequence
        && e.Sequence > this.element.CurrentStatusLink?.StatusId?.Sequence
        && e.Sequence < status?.Sequence;
    });

    for (let index = 0; index < intermediateStatus.length; index++) {
      var intermediateNewStatus = await this.saleDocumentHeaderStatusesService.getInstance();
      intermediateNewStatus.StatusId = intermediateStatus[index];
      intermediateNewStatus.Date = new Date(intermediateNewStatus.Date.setSeconds(intermediateNewStatus.Date.getSeconds() - (index + 1)));
      this.element.Statuses?.push(intermediateNewStatus);
    }

    newStatus.StatusId = status;
    try {
      this.element.CurrentStatusLink = newStatus;
      this.element.Statuses?.push(newStatus);
      this.documentStatuses.refreshItems();

      await this.update();

      if (this.element.Id) {
        const element = await this.saleDocumentService?.findByID(this.element.Id, { select: ['No'], expand: [] });
        this.element.No = element?.No;
        this.loadFormattedTitle((element?.No) ?? undefined);
      }
    } catch (error) {
      this.element.Statuses = this.element.Statuses?.filter((s: SaleDocumentHeaderStatus) => { return s.StatusId?.Sequence != newStatus.StatusId?.Sequence });
      this.element.CurrentStatusLink = this.element.Statuses?.find((s: SaleDocumentHeaderStatus) => s.StatusId?.Sequence == ((newStatus.StatusId?.Sequence ?? 3) - 1));
      setTimeout(() => {
        this.documentStatuses.refreshItems()
        this.ngxUiLoaderService.stop();
      }, 50);
    }
  }

  public override async update(showMessage: boolean = true) {
    if (!this.authService.hasPermission(this.newBasePermissionKey + '.update')) {
      NotificationsService.sendErrorMessage("You do not have the required permission!");
      return;
    }
    localStorage.removeItem("SaleHeader");
    var isNew = false;
    var documentLineRelation = this.element.DocumentLineRelations;
    this.ngxUiLoaderService.start();
    this.onChangeDocumentDataCollection();
    if (!this.element.Id) {
      const elementId = (await this.saleDocumentService.insert(await this.saleDocumentService?.format(this.element))).Id;
      this.element.Id = elementId;
      isNew = true;
    } else {
      await this.saleDocumentService?.update(this.element.Id, await this.saleDocumentService?.format(this.element));
    }

    await this.saveDocumentData();
    await this.saveLines();
    await this.saveStatus();

    if (this.documentType != "SaleQuote" && this.documentType != "SaleOrder") {
      await this.savePayments();
    }
    await this.saveDocumentRelations();
    if (documentLineRelation && isNew == true) {
      this.element.DocumentLineRelations = documentLineRelation;
      await this.saveDocumentLineRelations();
      delete this.element.DocumentLineRelations;
    }

    if (isNew == true) {
      AppInjectorService.config.setModificationGuard(false);
      this.goToUrl('../' + this.element.Id);
    }

    if (showMessage == true) {
      NotificationsService.sendSuccess("Record updated");
    }
    this.ngxUiLoaderService.stop();
    // TODO Check better method
    setTimeout(() => {
      AppInjectorService.config.setModificationGuard(false);
    }, 100);
  }

  public override async saveStatus() {
    if (!this.element.Statuses) return;
    for (let index = 0; index < this.element.Statuses.length; index++) {
      if (this.element.Statuses[index].Id == undefined) {
        this.element.Statuses[index].HeaderId = await this.saleDocumentService.format(this.element);
        var newElement: any = this.element.Statuses[index];
        this.element.Statuses[index] = await this.saleDocumentHeaderStatusesService.insert(await this.saleDocumentHeaderStatusesService.format(newElement));
      }
    }
  }

  public override async saveLines(): Promise<void> {
    if (!this.element.Lines) return;
    var filter: any = [];
    filter = ["HeaderId.Id eq " + this.element.Id];
    this.element.Lines.map((d: SaleDocumentLine) => { d.HeaderId = this.element });
    var existingElement = await this.saleDocumentLinesService.load({ filter: filter, expand: this.getDocumentLineExpand() });
    existingElement.map((d: SaleDocumentLine) => {
      var margin = d.SaleDocumentLineDiscountOperations.find((o) => o.DiscountOperationId?.Code === "Margin");
      if (margin != undefined) {
        d.Margin = margin;
      }
      var discount = d.SaleDocumentLineDiscountOperations.find((o) => o.DiscountOperationId?.Code === "Discount");
      if (discount != undefined) {
        d.Discount = discount;
      }
    })
    await this.createOrUpdateArray(this.saleDocumentLinesService, this.element.Lines, existingElement, ['ExTaxSaleGrossPrice', 'ExTaxTotalPrice', 'InTaxTotalPrice', 'ExTaxUnitPrice', 'Margin', 'Discount', 'LineNo', 'Description', 'Reference', 'UnitId', 'IsPenaltyFixed', 'PenaltyValue', 'HourlyRate', 'Quantity', 'Labor', 'ExTaxGrossPrice', 'ForcedPrice', 'TaxRateId', 'ParentId', 'TaxId', 'AccountId', 'ParentLineNo'], this.saleDocumentLineDiscountOperationsService);
    this.element.Lines = await this.saleDocumentLinesService.load({ filter: filter, expand: this.getDocumentLineExpand(), sort: ['LineNo', 'ParentLineNo'] });
    this.element.Lines.map((d: SaleDocumentLine) => {
      var margin = d.SaleDocumentLineDiscountOperations.find((o) => o.DiscountOperationId?.Code === "Margin");
      if (margin != undefined) {
        d.Margin = margin;
      }
      var discount = d.SaleDocumentLineDiscountOperations.find((o) => o.DiscountOperationId?.Code === "Discount");
      if (discount != undefined) {
        d.Discount = discount;
      }
    });
    this.element.Lines = this.saleDocumentLinesService.formatLines(this.element.Lines ?? []);
    setTimeout(() => {
      this.saleDocumentContent.documentLines?.treelist?.treelist?.instance.getDataSource().items().forEach((i: any) => {
        this.saleDocumentContent.documentLines?.treelist?.treelist?.instance.expandRow(i.key);
      });
      setTimeout(() => {
        this.saleDocumentContent.documentLines?.treelist?.treelist?.instance.refresh();
      }, 100);
    }, 100);
  }

  public getDocumentLineExpand(): string[] {
    var result = ['ProductId', 'UnitId', 'TaxRateId', 'ParentId', 'TaxId', 'AccountId', 'SaleDocumentLineDiscountOperations.DiscountOperationId'];

    if (this.documentType == 'SaleInvoice') {
      result.push('AssignementPriceCoefId');
    }
    return result;
  }

  public async onDeleteConfirmed() {
    this.deleteConfirm.close();
    if (this.element?.Id) {
      await this.saleDocumentService?.remove(this.element.Id);
    }
    this.router.navigate(['../'], { relativeTo: this.activatedRoute });
    NotificationsService.sendSuccess("Record deleted");
  }

  public updateLateFeeAmount() {
    this.element.LateFeeInterestAmount = this.element.LateFeeInterestPercentage * (this.element.AmountRemaining ?? 0) / 100;
    this.element.TotalWithLateFee = this.element.LateFeeInterestAmount + this.element.FixedLateFeeAmount + (this.element.AmountRemaining ?? 0);
  }

  // Contract
  // --------
  public onChangeSaleContrat() {
    this.documentRelation?.load();
  }

  protected override onRegeneratePreviewButtonClicked(event: Event): void {
    this.element.Report = null;
    this.onPreviewModalButtonClicked();
  }

  principalThirdChange() {
    if (this.documentType != "SaleInvoice") return;
    let principal = this.element.DocumentDataCollection.find(((d: DocumentData) => d.HasPrincipal == true));
    if (!principal || !principal.ThirdId || !principal.ThirdId.CustomerId || !principal.ThirdId.CustomerId.SaleInvoiceDocumentDelayId || principal.ThirdId.CustomerId.SaleInvoiceDocumentDelayId == this.element.DocumentDelayId) return;
    this.element.DocumentDelayId = principal.ThirdId.CustomerId.SaleInvoiceDocumentDelayId;
  }

  // Preview
  // -------
  public override fileDataLoadSuccess(fileData: FileData) {
    this.previewData = this.sanitizer.bypassSecurityTrustResourceUrl(fileData.fileContent + '#');
    from(this.saleDocumentService.findByID(this.element.Id!)).subscribe({
      next: (remoteInvoice) => {
        this.element.Report = remoteInvoice.Report;
        this.previewId = fileData.fileName;
        this.previewModal.open();
        this.ngxUiLoaderService.stop();
      }
    });
  }
}
