import { Injectable } from '@angular/core';
import { SignalrService } from '../../connectors/signalr/signalr.service';
import { ApiService } from '../../core/api.service';
import { MollieTerminal } from './mollie-terminal/mollie-terminal.model';
import { MolliePayment } from './mollie-payment/mollie-payment.model';
import { Payment } from '../../base/payments/payment.model';

@Injectable({
  providedIn: 'root'
})
export class MollieService {

  constructor(private api: ApiService, private signalR: SignalrService) { }

  async postPaymentCheckout(molliePayment: MolliePayment): Promise<any> {

    let url = molliePayment.IdMethod === undefined ? `/api/Mollie/Payment` : `/api/Mollie/Payment/${molliePayment.IdMethod}`;

    try {
      return this.api.sendRequest(
        url,
        'POST',
        {
          "connectionId": this.signalR.getConnectionId()!,
          "terminalId": molliePayment.TerminalId,
          "description": molliePayment.Description,
          "amount": molliePayment.Amount,
          "DocumentId" : molliePayment.DocumentId,
          "DocumentType" : molliePayment.DocumentType,
          "RoundedAmount" : molliePayment.RoundedAmount
        }
      ).then(r => {
        // console.log(r);
        return r
      })
        .catch((error) => {
          // console.log(error);
        });
    } catch (e) {
      // console.log(e);
      return "";
    }
  }

  async postRefundCheckout(idPayment: string, amount: string, currency: string, description: string): Promise<string> {

    let body = {
      "description": description,
      "amount": {
        "currency": currency,
        "value": amount
      }
    };

    try {
      return this.api.sendRequest(
        `/api/Mollie/Refund/${idPayment}`,
        'POST',
        body
      ).then(r => {
        // console.log(r);
        return r
      })
    } catch (e) {
      // console.log(e);
      return "";
    }
  }

  public async postRefundPayment(paymentIds: number[], description: string): Promise<Payment[]> {
    let url = "/api/Mollie/Refund";
    return await this.api.sendRequest(url, 'POST', { "paymentIds": paymentIds, "description": description });
  }

  public async getMollieListOfTerminal(): Promise<MollieTerminal[]> {
    return this.api.sendRequest(`/api/Mollie/Detail/listofterminal`);
  }

  public async getMollieTerminal(terminalId: string): Promise<MollieTerminal> {
    return this.api.sendRequest(`/api/Mollie/Detail/terminal/${terminalId}`);
  }


  // async getOrganizationDetails(idOrganization: string): Promise<string> {
  //   const httpOptions = {
  //     headers: new HttpHeaders({
  //       'Authorization': `Bearer ${AuthService.getToken()!}`
  //     }),
  //     responseType: 'text' as const
  //   };

  //   try {
  //     return lastValueFrom(this.http.get(
  //       `https://localhost:9000/api/Mollie/Detail/Organization/${idOrganization}`,
  //       httpOptions
  //     ))
  //       .then(r => {
  //         console.log(r);
  //         return r
  //       })
  //   } catch (e) {
  //     console.log(e);
  //     return "";
  //   }
  // }

}
