import { Injectable } from '@angular/core';
import { ConfigurationsService } from '../../base/modules/configurations/configurations.service';
import { TaxRate } from '../../base/tax-rates/tax-rate';
import { ODataService } from '../../core/services/oData.service';
import { Product } from '../products/product.model';
import { ProductsService } from '../products/product.service';
import { CustomerCatalog } from './customer-catalog.model';
import { CustomerCatalogDiscountOperations } from './customer-catalog-discount-operations/customer-catalog-discount-operations';

@Injectable({
  providedIn: 'root'
})
export class CustomerCatalogsService extends ODataService<CustomerCatalog> {
  public url = 'CustomerCatalog';

  constructor(
    private configurationsService: ConfigurationsService,
    private productsService: ProductsService
  ) {
    super();
  }

  public async getInstanceWithProduct(product: Product, customerCatalogData?: CustomerCatalog): Promise<CustomerCatalog> {
    var customerCatalog = new CustomerCatalog({
      ProductId: new Product(this.productsService.format(product)),
      TaxRateId: new TaxRate({ Id: await this.configurationsService.getConfigurationAsNumber('taxRate', 'Products.CustomerCatalogs', 'Products') }),
      DiscountId: new CustomerCatalogDiscountOperations({ Value: 0, IsDiscountFixed: false }),
      MarginId: new CustomerCatalogDiscountOperations({ Value: 0, IsDiscountFixed: false }),
      ...customerCatalogData,
    });

    return customerCatalog;
  }

  public override format(element: CustomerCatalog): any {
    var result: any = {
      ...element,
      CustomerId: element.CustomerId?.Id || null,
      PriceListId: element.PriceListId?.Id || null,
      ProductCategoryId: element.ProductCategoryId?.Id,
      ProductId: element.ProductId?.Id,
      TaxRateId: element.TaxRateId?.Id,
    };

    delete result.DiscountId;
    delete result.MarginId;
    delete result.PenaltyId;

    return result;
  }

  public async insertCustomerCatalog(customerCatalog: CustomerCatalog) {
    return await this.apiService.sendRequest('/api/CustomerCatalog/insertcustomercatalog', 'POST', {
      CustomerId: customerCatalog.CustomerId?.Id,
      ProductId: customerCatalog.ProductId?.Id,
      PriceListId: customerCatalog.PriceListId?.Id,
      TaxRateId: customerCatalog.TaxRateId?.Id,
      DiscountValue:customerCatalog.DiscountId?.Value ?? 0,
      DiscountIsDiscountFixed:customerCatalog.DiscountId?.IsDiscountFixed,
      MarginValue:customerCatalog.MarginId?.Value ?? 0,
      MarginDiscountFixed:customerCatalog.MarginId?.IsDiscountFixed,
      PenaltyValue:customerCatalog.PenaltyId?.Value ?? 0,
      PenaltyDiscountFixed:customerCatalog.PenaltyId?.IsDiscountFixed
    }
    );
  }
}