import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NotificationsService, PageComponent } from '../../../../../libraries/syslink-components/src/public-api';
import { LanguagesService } from '../languages/languages.service';
import { TranslatableString } from '../translations/translatable-strings/translatable-string';
import { TranslatableStringsService } from '../translations/translatable-strings/translatable-strings.service';
import { Translation } from '../translations/translations/translation';
import { TranslationsService } from '../translations/translations/translations.service';
import { BasicEntity } from '../../core/models/basic-entity';
import { ODataService } from '../../core/services/oData.service';
import { BasicService } from './basic.service';
import { SyslinkColumn } from 'projects/libraries/syslink-components/src/lib/helpers/SyslinkColumn';
import { AccountsService } from '../../accountings/accounts/accounts.service';
import { AccountJournalTypesService } from '../../accountings/account-journal-types/account-journal-types.service';
import { ProductCategoriesService } from '../../products/product-categories/product-category.service';
import { MailTemplateTypesService } from '../../mails/mail-template-types/mail-template-types.service';
import { StockLocationService } from '../../stocks/stock-location/stock-location.service';
import { DepartmentService } from '../configurations/department/department.service';
import { ProductsService } from '../../products/products/product.service';

@Component({
  selector: 'app-basic',
  templateUrl: './basic.component.html',
  styleUrls: ['./basic.component.scss']
})
export class BasicComponent extends PageComponent implements OnInit {
  public service: ODataService<BasicEntity> = new BasicService();

  public columns: SyslinkColumn[] = [
    new SyslinkColumn({ field: 'Code', label: "Code" }),
    new SyslinkColumn({ field: 'NameTranslationId.TranslatedValue', label: "Name", editCellTemplate: 'translations-cell' })
  ]

  public expand: string[] = ['NameTranslationId.Translations.LanguageId'];
  public canAdd: boolean = true;
  public canDelete: boolean = true;
  public canUpdate: boolean = true;
  public canRefresh: boolean = true;

  constructor(
    public languagesService: LanguagesService,
    public translatableStringsService: TranslatableStringsService,
    public translationsService: TranslationsService,
    public override activatedRoute: ActivatedRoute,
    public accountsService: AccountsService,
    public accountJournalTypesService: AccountJournalTypesService,
    public productCategoriesService: ProductCategoriesService,
    public mailTemplateTypesService: MailTemplateTypesService,
    public departmentService: DepartmentService,
    public stockLocationService: StockLocationService,
    private productService: ProductsService
  ) {
    super();
    this.service.url = <string>this.activatedRoute.snapshot.data['basic'];
  }

  public onPrepareRow(basic: any): void {
    switch (this.service.url) {
      case 'Brand':
        basic.data = {
          Code: '',
          Name: ''
        };
        break;
        case 'AccountPeriod':
          basic.data = {
            Code: '',
            StartDate: new Date(),
            EndDate: new Date(),
            IsClosed: false,
          };
          break;
      case 'Bank':
        basic.data = {
          IsVisible: true,
          Code: '',
          Bic: ''
        };
        break;
      default:
        basic.data = {
          Code: '',
          NameTranslationId: new TranslatableString({
            TranslatedValue: ''
          })
        };
        break;
    }
  }

  public async onBeforeInsert(event: any): Promise<void> {
    event.cancel = new Promise<boolean>(async (resolve) => {
      const code: string = event.data.Code;
      if (code == undefined || code == "") {
        NotificationsService.sendErrorMessage("Code cannot be empty");
        resolve(true);
      }
      switch (this.service.url) {
        case 'Brand':
          resolve(false);
          break;
        case 'AccountPeriod':
          resolve(false);
          break;
        case 'Bank':
          resolve(false);
          break;
        case 'TaskPriority':
          if (isNaN(event.data.Value)) {
            NotificationsService.sendErrorMessage("Value must be a number");
            resolve(true);
          }
          break;
        default:
          const nameTranslationCode: string = `${this.service.url}.${code}`;
          const value: string = event.data.NameTranslationId.TranslatedValue;

          let translatableString = await this.translatableStringsService.findByCode(nameTranslationCode, { expand: ["Translations", "Translations.LanguageId"] });

          if (translatableString === null) {
            translatableString = await this.translatableStringsService.insert({
              Code: nameTranslationCode
            });

            for (const language of this.languagesService.languages) {
              await this.translationsService.insert({
                TranslatableStringId: translatableString,
                LanguageId: language,
                Value: language.IsoCode === 'fr' ? value : ''
              });
            }
          } else {
            //TODO handle this part with user's language
            let frenchTranslation = translatableString.Translations.find(translation => translation.LanguageId.IsoCode === 'fr');
            if (frenchTranslation === undefined) {
              frenchTranslation = await this.translationsService.insert({
                TranslatableStringId: translatableString,
                LanguageId: this.languagesService.languages.find(language => language.IsoCode === "fr"),
                Value: value
              });
            } else {
              frenchTranslation.Value = value;
            }
          }
          event.data.NameTranslationId = translatableString;
          if (this.service.url == 'StockLocation') {
            event.data.DepartmentId = (await this.departmentService.load())[0] ?? undefined
          }
          resolve(false);
          break;
      }
    });
  }

  public onBeforeUpdate(event: any): void {
    switch (this.service.url) {
      default:
        if (event.newData?.NameTranslationId?.TranslatedValue) {
          event.oldData.NameTranslationId.Translations = event.oldData.NameTranslationId.Translations.map((translation: Translation) => {
            return {
              ...translation,
              Value: translation.LanguageId.IsoCode === 'fr' ? event.newData.NameTranslationId.TranslatedValue : translation.Value
            }
          });
          event.newData = event.oldData;
        } break;
    }
  }

  override ngOnInit(): void {
    super.ngOnInit();
    switch (this.service.url) {
      case 'Country': this.updateCountry(); break;
      case 'Currency': this.updateCurrency(); break;
      case 'ContactType': this.updateContactType(); break;
      case 'DocumentDelay': this.updateDocumentDelay(); break;
      case 'DiscountOperation': this.updateDiscountOperation(); break;
      case 'TaxRate': this.updateTaxRate(); break;
      case 'Brand': this.updateBrand(); break;
      case 'ProductCategory': this.updateProductCategory(); break;
      case 'AccountJournal': this.updateAccountJournal(); break;
      case 'PaymentMethod': this.updatePaymentMethod(); break;
      case 'Bank': this.updateBank(); break;
      case 'MailTemplateType': this.updateMailTemplateType(); break;
      case 'MailTemplateParam': this.updateMailTemplateParam(); break;
      case 'TaskPriority': this.updatePriorities(); break;
      case 'WorkType': this.updateWorkTypes(); break;
      case 'Timetable': this.updateTimetables(); break;
      case 'PriceCoef': this.updatePriceCoefs(); break;
      case 'TimetableLineType': this.updateTimetableLineTypes(); break;
      case 'StockLocation': this.updateStockLocations(); break;
      case 'AccountPeriod': this.updateAccountPeriods(); break;
      default: break;
    }
  }

  private updateCountry() {
    this.columns = [
      new SyslinkColumn({ field: 'IsVisible', showEditorAlways: true, label: 'Visible', type: 'boolean', cellTemplate: "icon-cell", editCellTemplate: 'icon-cell', data: { icon: 'fa-light fa-eye', activeClass: "cl-accent", inactiveClass: "cl-gray" }, width: 80 }),
      ...this.columns
    ];
  }

  private updateCurrency() {
    this.columns.push(new SyslinkColumn({ field: 'Symbol' }));
  }

  private updateBrand() {
    const column: SyslinkColumn | undefined = this.columns.find((column: SyslinkColumn) => column.field === 'NameTranslationId.TranslatedValue');
    if (!column) return;
    column.field = 'Name';
    column.editCellTemplate = '';
    this.expand = [];
  }

  private updateAccountPeriods() {
    this.columns = this.columns.filter((column: SyslinkColumn) => column.field !== 'NameTranslationId.TranslatedValue');
    // if (!column) return;
    // column.field = 'Name';
    // column.editCellTemplate = '';
    this.expand = [];
    this.columns.push(new SyslinkColumn({ field: 'Year' }));
    this.columns.push(new SyslinkColumn({ field: 'StartDate', label: "Start date", type: 'date' }));
    this.columns.push(new SyslinkColumn({ field: 'EndDate', label: "End date", type: 'date' }));
    this.columns.push(new SyslinkColumn({ field: 'IsClosed', type: 'boolean' }));
  }

  private updateContactType() {
    this.columns.push(new SyslinkColumn({ field: 'Icon' }));
  }

  private updateDocumentDelay() {
    this.columns.push(...[new SyslinkColumn({ field: 'Value', label: "value", type: 'number' }),
    new SyslinkColumn({ field: 'EndMonth', label: "End Month", type: 'boolean' })]);
  }

  private updateDiscountOperation() {
    this.columns.push(...[new SyslinkColumn({ field: 'Reverse', type: 'boolean', cellTemplate: 'boolean-cell' })]);
  }

  private updateTaxRate() {
    this.columns = [
      new SyslinkColumn({ field: 'IsVisible', showEditorAlways: true, label: 'Visible', type: 'boolean', cellTemplate: "icon-cell", editCellTemplate: 'icon-cell', data: { icon: 'fa-light fa-eye', activeClass: "cl-accent", inactiveClass: "cl-gray" }, width: 80 }),
      ...this.columns,
      new SyslinkColumn({ field: 'Value', label: 'value', type: 'number' }),
      new SyslinkColumn({ field: 'Description' })
    ];
  }

  private updateProductCategory() {
    this.expand = ['NameTranslationId.Translations.LanguageId', "SaleAccountId", "PurchaseAccountId", "ParentProductCategoryId"];
    this.columns.push(...[new SyslinkColumn({ field: "SaleAccountId", type: "object", label: this.translateService.instant("Sale account"), cellTemplate: 'select-value', editCellTemplate: "select-cell", data: { service: this.accountsService, displayKey: 'FormattedName' } }),
    new SyslinkColumn({ field: "PurchaseAccountId", type: "object", label: this.translateService.instant("Purchase account"), cellTemplate: 'select-value', editCellTemplate: "select-cell", data: { service: this.accountsService, displayKey: 'FormattedName' } }),
    new SyslinkColumn({ field: "ParentProductCategoryId", type: "object", label: this.translateService.instant("Parent"), cellTemplate: 'select-value', editCellTemplate: "select-cell", data: { service: this.productCategoriesService, displayKey: 'Name' } }),]);
  }

  private updateAccountJournal() {
    this.expand = ['NameTranslationId.Translations.LanguageId', "AccountJournalTypeId"];
    this.columns.push(...[new SyslinkColumn({ field: "AccountJournalTypeId", label: "Account journal types", type: 'object', cellTemplate: 'select-value', editCellTemplate: "select-cell", data: { service: this.accountJournalTypesService, displayKey: 'Name' } }),]);
  }

  private updatePaymentMethod() {
    this.columns = [
      new SyslinkColumn({ field: 'IsVisible', label: 'Visible', type: 'boolean', cellTemplate: "icon-cell", editCellTemplate: 'icon-cell', data: { icon: 'fa-light fa-eye', activeClass: "cl-accent", inactiveClass: "cl-gray" }, showEditorAlways: true, width: 80 }),
      ...this.columns,
      new SyslinkColumn({ field: 'ApplyReturn', type: 'boolean', cellTemplate: 'boolean-cell' }),
      new SyslinkColumn({ field: 'ApplyRound', type: 'boolean', cellTemplate: 'boolean-cell' })
    ];
  }

  private updateBank() {
    this.expand = [];
    this.columns = [
      new SyslinkColumn({ field: 'IsVisible', showEditorAlways: true, label: 'Visible', type: 'boolean', cellTemplate: "icon-cell", editCellTemplate: 'icon-cell', data: { icon: 'fa-light fa-eye', activeClass: "cl-accent", inactiveClass: "cl-gray" }, width: 80 }),
      new SyslinkColumn({ field: 'Code', label: "Code" }),
      new SyslinkColumn({ field: 'Bic', label: "Bic" }),
    ];
  }

  private updateMailTemplateType() {
    this.canAdd = false;
    this.canDelete = false;
    this.canUpdate = false;
  }

  private updateMailTemplateParam() {
    this.canAdd = false;
    this.canDelete = false;
    this.canUpdate = false;

    this.expand = [... this.expand, "MailTemplateTypeId"];
    this.columns = [
      ...this.columns,
      new SyslinkColumn({ field: 'MailTemplateTypeId', label: 'Mail template type', type: 'object', cellTemplate: 'select-value', editCellTemplate: 'select-cell', data: { service: this.mailTemplateTypesService, displayKey: 'Name' } }),
    ];
  }

  private updatePriorities() {
    this.columns.push(...[new SyslinkColumn({ field: 'Value', type: 'number' })]);
  }

  private updateWorkTypes() {
    this.expand = [... this.expand, "ProductId"];
    this.columns.push(...[
      new SyslinkColumn({ field: 'ProductId', label: 'Product', type: 'object', cellTemplate: 'select-value', editCellTemplate: 'select-cell', data: { service: this.productService, displayKey: "Name" } }),
      new SyslinkColumn({ field: 'HourlyRate', label: 'Hourly rate', type: 'number', cellTemplate: 'currency-cell', editable: false })
    ]);
  }

  private updateTimetables() {
    this.columns.push(...[new SyslinkColumn({ field: 'IsDefault', label: 'Default', type: "boolean", showEditorAlways: true })]);
  }

  private updateTimetableLineTypes() {
    this.columns.push(...[new SyslinkColumn({ field: 'IsDefault', label: 'Default', type: "boolean", showEditorAlways: true })]);
  }

  private updatePriceCoefs() {
    this.columns.push(...[new SyslinkColumn({ field: 'Value', label: 'Value', type: 'number' })]);
  }

  private updateStockLocations() {
    this.expand = [... this.expand, "ParentId", "DepartmentId"];
    this.columns.push(...[
      new SyslinkColumn({ field: "ParentId", type: "object", label: "Parent", cellTemplate: 'select-value', editCellTemplate: "select-cell", data: { service: this.stockLocationService, displayKey: 'Name' } }),
    ]);
  }
}
