import { Injectable } from '@angular/core';
import { LoadOptions } from 'devextreme/data';
import { LanguagesService } from '../../base/languages/languages.service';
import { TranslatableString } from '../../base/translations/translatable-strings/translatable-string';
import { TranslatableStringsService } from '../../base/translations/translatable-strings/translatable-strings.service';
import { Translation } from '../../base/translations/translations/translation';
import { TranslationsService } from '../../base/translations/translations/translations.service';
import { ODataService } from '../../core/services/oData.service';
import { AccountType } from '../account-types/account-type.model';
import { Account } from './account.model';

@Injectable({
  providedIn: 'root'
})
export class AccountsService extends ODataService<Account> {
  public url = 'Account';
  public override defaultOptions: LoadOptions = {
    expand: [],
    select: ['AccountTypeId.Id', 'AccountTypeId.Name', 'ParentAccountId.Id']
  };
  constructor(
    public translationsService: TranslationsService,
    public languagesService: LanguagesService,
    public translatableStringsService: TranslatableStringsService
  ) {
    super();
  }
  public getDefaultOptions() {
    return this.defaultOptions;
  }

  public override async createOrUpdate(element: Account): Promise<Account> {

    let nametranslation = undefined;
    if (element.NameTranslationId?.Id) {
      nametranslation = await this.translatableStringsService.findByID(element.NameTranslationId.Id, {expand:['Translations.LanguageId']})
    }
    nametranslation = await this.saveTranslation(element.Name, nametranslation, "Account" + element.Code);
    element.NameTranslationId = nametranslation;

    if (element.Id) {
      return this.update(element.Id, await this.format(element));
    }
    else {
      return this.insert(await this.format(element));
    }
  }

  public async saveTranslation(value: string = "", translatableString: TranslatableString | undefined, defaultTranslatableStringCode: string = "Account.+Code") {
     if (translatableString === undefined || translatableString === null) {
      translatableString = await this.translatableStringsService.insert({
        Code: defaultTranslatableStringCode
      });

      for (const language of this.languagesService.languages) {
        await this.translationsService.insert({
          TranslatableStringId: translatableString,
          LanguageId: language,
          Value: language.IsoCode === 'fr' ? value : ''
        });
      }
    } else {
      //TODO handle this part with user's language
      let frenchTranslation = translatableString.Translations.find(translation => translation.LanguageId.IsoCode === 'fr');
      if (frenchTranslation === undefined) {
        frenchTranslation = await this.translationsService.insert({
          TranslatableStringId: translatableString,
          LanguageId: this.languagesService.languages.find(language => language.IsoCode === "fr"),
          Value: value
        });
      } else if (frenchTranslation.Id) {
        frenchTranslation.Value = value;
        await this.translationsService.update(frenchTranslation.Id, frenchTranslation);
      }
    }
    return translatableString;
  }

  public override async format(params: Partial<Account>): Promise<Account> {
    var result: any = {
      ...params,
      AccountTypeId: params?.AccountTypeId?.Id,
      ParentAccountId: params?.ParentAccountId?.Id
    };
    return result;
  }
  public override async getInstance(params?: Partial<Account>): Promise<Account> {
    var account = new Account({
      ...params,
      AccountTypeId: new AccountType(params?.AccountTypeId)
    });
    return account;
  }
}
