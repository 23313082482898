import { Injectable } from "@angular/core";
import { ODataService } from "../../../core/services/oData.service";
import { SaleDocument } from "./sale-document.model";


@Injectable({
  providedIn: 'root'
})
export class AllSaleDocumentsService extends ODataService<SaleDocument> {
  public url = 'SaleDocumentHeader';

}