<div class="d-flex gap-2">
    <div class="card text-center flex-grow-1">
        <div class="card-body"  [ngStyle]="getCommonValueColor(element.Report?.CompanySummary?.CreditRating?.CommonValue)">
            <h5 class="card-title">{{'International score' | translate}}</h5>
            <p class="card-text h4">{{element.Report?.CompanySummary?.CreditRating?.CommonValue}}</p>
        </div>
    </div>
    <div class="card text-center flex-grow-1" [ngStyle]="getProviderValueColor(element.Report?.CompanySummary?.CreditRating?.ProviderValue?.Value)">
        <div class="card-body">
            <h5 class="card-title">{{'Risk score' | translate}}</h5>
            <p class="card-text h4">{{element.Report?.CompanySummary?.CreditRating?.ProviderValue?.Value}}</p>
        </div>
    </div>
    <div class="card text-center flex-grow-1" [ngStyle]="getCommonDescriptionColor(element.Report?.CompanySummary?.CreditRating?.CommonDescription)">
        <div class="card-body">
            <h5 class="card-title">{{'Risk score description' | translate}}</h5>
            <p class="card-text h4">{{element.Report?.CompanySummary?.CreditRating?.CommonDescription}}</p>
        </div>
    </div>
    <div class="card text-center flex-grow-1">
        <div class="card-body">
            <h5 class="card-title">{{'CreditLimit' | translate}}</h5>
            <p class="card-text h4">{{getCreditLimit()}}</p>
        </div>
    </div>
    <div class="card text-center flex-grow-1">
        <div class="card-body">
            <h5 class="card-title">{{'DefaultPropability' | translate}}</h5>
            <p class="card-text h4">{{getDefaultProbability()}}</p>
        </div>
    </div>
</div>