import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { ViewComponent } from 'projects/libraries/syslink-components/src/lib/helpers/view/view.component';
import { DocumentRelation } from '../../document-relations/document-relation.model';
import { DocumentRelationData } from '../document-relations-block/document-relations-block.component';
import { DocumentRelationsService } from '../../document-relations/document-relation.service';
import { ModalComponent, SelectComponent } from 'projects/libraries/syslink-components/src/public-api';
import { SaleQuotesService } from 'projects/erp-app/src/app/sales/sale-quotes/sale-quotes/sale-quotes.service';
import { SaleOrdersService } from 'projects/erp-app/src/app/sales/sale-orders/sale-orders/sale-orders.service';
import { SaleInvoicesService } from 'projects/erp-app/src/app/sales/sale-invoices/sale-invoices/sale-invoices.service';
import { SaleCreditNotesService } from 'projects/erp-app/src/app/sales/sale-credit-notes/sale-credit-notes/sale-credit-notes.service';
import { SaleContractsService } from 'projects/erp-app/src/app/sales/sale-contracts/sale-contracts/sale-contracts.service';
import { PurchaseOrdersService } from 'projects/erp-app/src/app/purchases/purchase-orders/purchase-orders/purchase-orders.service';
import { PurchaseInvoicesService } from 'projects/erp-app/src/app/purchases/purchase-invoices/purchase-invoices/purchase-invoices.service';
import { PurchaseCreditNotesService } from 'projects/erp-app/src/app/purchases/purchase-credit-notes/purchase-credit-notes/purchase-credit-notes.service';
import { StockMoveIncomingsService } from 'projects/erp-app/src/app/stocks/stock-move-incomings/stock-move-incomings/stock-move-incomings.service';
import { StockMoveOutgoingsService } from 'projects/erp-app/src/app/stocks/stock-move-outgoings/stock-move-outgoings/stock-move-outgoings.service';
import { DocumentRelationsV2Component } from '../document-relations-v2/document-relations-v2.component';

@Component({
  selector: 'app-document-relations',
  templateUrl: './document-relations.component.html',
  styleUrls: ['./document-relations.component.scss']
})
export class DocumentRelationsComponent extends ViewComponent {

  @Input() public documentId?: number;
  @Input() public documentType: string = '';

  public _elements: DocumentRelation[] = [];
  @Input() set elements(value: DocumentRelation[]) {
    this._elements = value;
    this.load();
  }
  get elements() {
    return this._elements;
  }
  @Output() public elementsChange: EventEmitter<DocumentRelation[]> = new EventEmitter<DocumentRelation[]>();

  @Input() disabled: boolean = false;
  public documentsRelationData?: DocumentRelationData[];
  // @ViewChild("workTimeModal") workTimeModal?: WorkTimeModalComponent;
  @ViewChild("modal") modal?: ModalComponent;
  @ViewChild("documentRelationComponent") documentRelationComponent?: DocumentRelationsV2Component;
  @ViewChild("modalSelect") modalSelect?: SelectComponent;

  constructor(
    private documentRelationsService: DocumentRelationsService,
    public saleQuotesService: SaleQuotesService,
    public saleOrdersService: SaleOrdersService,
    public saleInvoicesService: SaleInvoicesService,
    public saleCreditNotesService: SaleCreditNotesService,
    public saleContractsService: SaleContractsService,
    public purchaseOrdersService: PurchaseOrdersService,
    public purchaseInvoicesService: PurchaseInvoicesService,
    public purchaseCreditNotesService: PurchaseCreditNotesService,
    public stockMoveIncomingsService: StockMoveIncomingsService,
    public stockMoveOutgoingsService: StockMoveOutgoingsService
  ) {
    super();
  }
  override async ngAfterViewInit(): Promise<void> {
    await this.load();
  }

  public async onAddClicked(data: { type: any }) {
    var modalData: any = {
      'type': data.type,
      'element': null
    };

    switch (data.type) {
      case "SaleQuote":
        modalData.service = this.saleQuotesService.store;
        break;
      case "SaleOrder":
        modalData.service = this.saleOrdersService.store;
        break;
      case "SaleInvoice":
        modalData.service = this.saleInvoicesService.store;
        break;
      case "SaleCreditNote":
        modalData.service = this.saleCreditNotesService.store;
        break;
      case "SaleContract":
        modalData.service = this.saleContractsService.store;
        break;
      case "PurchaseOrder":
        modalData.service = this.purchaseOrdersService.store;
        break;
      case "PurchaseInvoice":
        modalData.service = this.purchaseInvoicesService.store;
        break;
      case "PurchaseCreditNote":
        modalData.service = this.purchaseCreditNotesService.store;
        break;
      case "StockMoveCustomerOutgoing":
        modalData.service = this.stockMoveOutgoingsService.store;
        modalData.filter = ["Type eq 'customer'"];
        break;
      case "StockMoveCustomerIncoming":
        modalData.service = this.stockMoveIncomingsService.store;
        modalData.filter = ["Type eq 'customer'"];
        break;
      case "StockMoveSupplierIncoming":
        modalData.service = this.stockMoveOutgoingsService.store;
        modalData.filter = ["Type eq 'supplier'"];
        break;
      case "StockMoveSupplierOutgoing":
        modalData.service = this.stockMoveIncomingsService.store;
        modalData.filter = ["Type eq 'supplier'"];
        break;
    }
    this.modal?.open(modalData);
    setTimeout(() => {
      this.modalSelect?.forceReload();
    }, 50);
  }

  public async onValidateSelectModal(modal: any) {
    var documentRelation = await this.documentRelationsService.getInstance({
      FromId: this.documentId,
      FromType: this.documentType + "Header",
      ToId: modal.element.Id,
      ToType: modal.type + "Header"
    });
    this.elements.push(documentRelation);
    this.modal?.close();
    await this.load();
  }

  public async onDeleteClicked(data: { type: string, event: any }) {
    if (!this.documentId) return;
    var index = this.elements.findIndex((e: any) =>
      (e.FromType == (this.documentType + "Header") && e.FromId == this.documentId && e.ToType == (data.type + "Header") && e.ToId == data.event.Id) ||
      (e.ToType == (this.documentType + "Header") && e.ToId == this.documentId && e.FromType == (data.type + "Header") && e.FromId == data.event.Id))
    if (index >= 0) {
      this.elements.splice(index, 1);
    }
    await this.load();
  }

  public async load() {
    if (this.documentId) {
      await this.loadData();
    }
    // console.log(this.itemsSaleInvoiceIds);

    this.documentsRelationData = undefined;
    this.documentsRelationData = [ // interne caché
      { id: 0, key: 'SaleQuote', group:'Sale' , title: "Quotes", visible: this.authService.hasPermission(this.basePermissionKey + '.SaleQuote.block.view'), ids: this.itemsSaleQuoteIds, value: this.itemsSaleQuoteIds.length, gridFilter: this.itemsSaleQuoteIds.length > 0 ? ['Id in (' + (this.itemsSaleQuoteIds).join(',') + ')'] : ['Id eq 0'] },
      { id: 1, key: 'SaleOrder', group:'Sale', title: "Orders", visible: this.authService.hasPermission(this.basePermissionKey + '.SaleOrder.block.view'), ids: this.itemsSaleOrderIds, value: this.itemsSaleOrderIds.length, gridFilter: this.itemsSaleOrderIds.length > 0 ? ['Id in (' + (this.itemsSaleOrderIds).join(',') + ')'] : ['Id eq 0'] },
      { id: 2, key: 'SaleInvoice', group:'Sale', title: "Invoices", visible: this.authService.hasPermission(this.basePermissionKey + '.SaleInvoice.block.view'), ids: this.itemsSaleInvoiceIds, value: this.itemsSaleInvoiceIds.length, gridFilter: this.itemsSaleInvoiceIds.length > 0 ? ['Id in (' + (this.itemsSaleInvoiceIds).join(',') + ')'] : ['Id eq 0'] },
      { id: 3, key: 'SaleCreditNote', group:'Sale', title: "Credit notes", visible: this.authService.hasPermission(this.basePermissionKey + '.SaleCreditNote.block.view'), ids: this.itemsSaleCreditNoteIds, value: this.itemsSaleCreditNoteIds.length, gridFilter: this.itemsSaleCreditNoteIds.length > 0 ? ['Id in (' + (this.itemsSaleCreditNoteIds).join(',') + ')'] : ['Id eq 0'] },
      { id: 4, key: 'SaleContract', group:'Sale', title: "Contracts", visible: this.authService.hasPermission(this.basePermissionKey + '.SaleContract.block.view'), ids: this.itemsSaleContractIds, value: this.itemsSaleContractIds.length, gridFilter: this.itemsSaleContractIds.length > 0 ? ['Id in (' + (this.itemsSaleContractIds).join(',') + ')'] : ['Id eq 0'] },
      { id: 5, key: 'PurchaseOrder', group:'Purchase', title: "Orders", visible: this.authService.hasPermission(this.basePermissionKey + '.PurchaseOrder.block.view'), ids: this.itemsPurchaseOrderIds, value: this.itemsPurchaseOrderIds.length, gridFilter: this.itemsPurchaseOrderIds.length > 0 ? ['Id in (' + (this.itemsPurchaseOrderIds).join(',') + ')'] : ['Id eq 0'] },
      { id: 6, key: 'PurchaseInvoice', group:'Purchase', title: "Invoices", visible: this.authService.hasPermission(this.basePermissionKey + '.PurchaseInvoice.block.view'), ids: this.itemsPurchaseInvoiceIds, value: this.itemsPurchaseInvoiceIds.length, gridFilter: this.itemsPurchaseInvoiceIds.length > 0 ? ['Id in (' + (this.itemsPurchaseInvoiceIds).join(',') + ')'] : ['Id eq 0'] },
      { id: 7, key: 'PurchaseCreditNote', group:'Purchase', title: "Credit notes", visible: this.authService.hasPermission(this.basePermissionKey + '.PurchaseCreditNote.block.view'), ids: this.itemsPurchaseCreditNoteIds, value: this.itemsPurchaseCreditNoteIds.length, gridFilter: this.itemsPurchaseCreditNoteIds.length > 0 ? ['Id in (' + (this.itemsPurchaseCreditNoteIds).join(',') + ')'] : ['Id eq 0'] },
      { id: 8, key: 'StockMoveCustomerIncoming', group:'Stock', title: "Customer Incomings", visible: this.authService.hasPermission(this.basePermissionKey + '.StockMoveCustomerIncoming.block.view'), ids: this.itemsStockMoveCustomerIncomingIds, value: this.itemsStockMoveCustomerIncomingIds.length, gridFilter: this.itemsStockMoveCustomerIncomingIds.length > 0 ? ['Id in (' + (this.itemsStockMoveCustomerIncomingIds).join(',') + ')'] : ['Id eq 0'] },
      { id: 9, key: 'StockMoveCustomerOutgoing', group:'Stock', title: "Customer Outgoings", visible: this.authService.hasPermission(this.basePermissionKey + '.StockMoveCustomerOutgoing.block.view'), ids: this.itemsStockMoveCustomerOutgoingIds, value: this.itemsStockMoveCustomerOutgoingIds.length, gridFilter: this.itemsStockMoveCustomerOutgoingIds.length > 0 ? ['Id in (' + (this.itemsStockMoveCustomerOutgoingIds).join(',') + ')'] : ['Id eq 0'] },
      { id: 10, key: 'StockMoveSupplierOutgoing', group:'Stock', title: "Supplier Outgoings", visible: this.authService.hasPermission(this.basePermissionKey + '.StockMoveSupplierOutgoing.block.view'), ids: this.itemsStockMoveSupplierOutgoingIds, value: this.itemsStockMoveSupplierOutgoingIds.length, gridFilter: this.itemsStockMoveSupplierOutgoingIds.length > 0 ? ['Id in (' + (this.itemsStockMoveSupplierOutgoingIds).join(',') + ')'] : ['Id eq 0'] },
      { id: 11, key: 'StockMoveSupplierIncoming', group:'Stock', title: "Supplier Incomings", visible: this.authService.hasPermission(this.basePermissionKey + '.StockMoveSupplierIncoming.block.view'), ids: this.itemsStockMoveSupplierIncomingIds, value: this.itemsStockMoveSupplierIncomingIds.length, gridFilter: this.itemsStockMoveSupplierIncomingIds.length > 0 ? ['Id in (' + (this.itemsStockMoveSupplierIncomingIds).join(',') + ')'] : ['Id eq 0'] },
    ]
  }


  // Sale
  // ----
  public itemsSaleQuoteIds: number[] = [];
  public itemsSaleOrderIds: number[] = [];
  public itemsSaleInvoiceIds: number[] = [];
  public itemsSaleCreditNoteIds: number[] = [];
  public itemsSaleContractIds: number[] = [];

  // Purchase
  // --------
  public itemsPurchaseOrderIds: number[] = [];
  public itemsPurchaseInvoiceIds: number[] = [];
  public itemsPurchaseCreditNoteIds: number[] = [];

  // Stock
  // -----
  public itemsStockMoveCustomerIncomingIds: number[] = [];
  public itemsStockMoveCustomerOutgoingIds: number[] = [];
  public itemsStockMoveSupplierIncomingIds: number[] = [];
  public itemsStockMoveSupplierOutgoingIds: number[] = [];

  public async loadData() {
    this.loadSale();
    this.loadPurchase();
    this.loadStock();
  }

  public loadSale() {
    var items: number[] = [];
    this.elements.filter((e: any) =>
      (e.ToType == "SaleQuoteHeader" && e.ToId != this.documentId) ||
      (e.FromType == "SaleQuoteHeader" && e.FromId != this.documentId))
      .map((e: any) => {
        items.push(e.ToType == "SaleQuoteHeader" && e.ToId != this.documentId ? e.ToId : e.FromId);
      });
    this.itemsSaleQuoteIds = items;

    var items: number[] = [];
    this.elements.filter((e: any) =>
      (e.ToType == "SaleOrderHeader" && e.ToId != this.documentId) ||
      (e.FromType == "SaleOrderHeader" && e.FromId != this.documentId))
      .map((e: any) => {
        items.push(e.ToType == "SaleOrderHeader" && e.ToId != this.documentId ? e.ToId : e.FromId);
      });
    this.itemsSaleOrderIds = items;

    var items: number[] = [];
    this.elements.filter((e: any) =>
      (e.ToType == "SaleInvoiceHeader" && e.ToId != this.documentId) ||
      (e.FromType == "SaleInvoiceHeader" && e.FromId != this.documentId))
      .map((e: any) => {
        items.push(e.ToType == "SaleInvoiceHeader" && e.ToId != this.documentId ? e.ToId : e.FromId);
      });
    this.itemsSaleInvoiceIds = items;

    var items: number[] = [];
    this.elements.filter((e: any) =>
      (e.ToType == "SaleCreditNoteHeader" && e.ToId != this.documentId) ||
      (e.FromType == "SaleCreditNoteHeader" && e.FromId != this.documentId))
      .map((e: any) => {
        items.push(e.ToType == "SaleCreditNoteHeader" && e.ToId != this.documentId ? e.ToId : e.FromId);
      });
    this.itemsSaleCreditNoteIds = items;

    var items: number[] = [];
    this.elements.filter((e: any) =>
      (e.ToType == "SaleContractHeader" && e.ToId != this.documentId) ||
      (e.FromType == "SaleContractHeader" && e.FromId != this.documentId))
      .map((e: any) => {
        items.push(e.ToType == "SaleContractHeader" && e.ToId != this.documentId ? e.ToId : e.FromId);
      });
    this.itemsSaleContractIds = items;
  }

  public loadPurchase() {
    var items: number[] = [];
    this.elements.filter((e: any) =>
      (e.ToType == "PurchaseOrderHeader" && e.ToId != this.documentId) ||
      (e.FromType == "PurchaseOrderHeader" && e.FromId != this.documentId))
      .map((e: any) => {
        items.push(e.ToType == "PurchaseOrderHeader" && e.ToId != this.documentId ? e.ToId : e.FromId);
      });
    this.itemsPurchaseOrderIds = items;

    var items: number[] = [];
    this.elements.filter((e: any) =>
      (e.ToType == "PurchaseInvoiceHeader" && e.ToId != this.documentId) ||
      (e.FromType == "PurchaseInvoiceHeader" && e.FromId != this.documentId))
      .map((e: any) => {
        items.push(e.ToType == "PurchaseInvoiceHeader" && e.ToId != this.documentId ? e.ToId : e.FromId);
      });
    this.itemsPurchaseInvoiceIds = items;

    var items: number[] = [];
    this.elements.filter((e: any) =>
      (e.ToType == "PurchaseCreditNoteHeader" && e.ToId != this.documentId) ||
      (e.FromType == "PurchaseCreditNoteHeader" && e.FromId != this.documentId))
      .map((e: any) => {
        items.push(e.ToType == "PurchaseCreditNoteHeader" && e.ToId != this.documentId ? e.ToId : e.FromId);
      });
    this.itemsPurchaseCreditNoteIds = items;

  }

  public loadStock() {
    var items: number[] = [];
    this.elements.filter((e: any) =>
      (e.ToType == "StockMoveOutgoingHeader" && e.ToId != this.documentId) ||
      (e.FromType == "StockMoveOutgoingHeader" && e.FromId != this.documentId))
      .map((e: any) => {
        items.push(e.ToType == "StockMoveOutgoingHeader" && e.ToId != this.documentId ? e.ToId : e.FromId);
      });
    this.itemsStockMoveCustomerOutgoingIds = items;

    var items: number[] = [];
    this.elements.filter((e: any) =>
      (e.ToType == "StockMoveIncomingHeader" && e.ToId != this.documentId) ||
      (e.FromType == "StockMoveIncomingHeader" && e.FromId != this.documentId))
      .map((e: any) => {
        items.push(e.ToType == "StockMoveIncomingHeader" && e.ToId != this.documentId ? e.ToId : e.FromId);
      });
    this.itemsStockMoveCustomerIncomingIds = items;

    var items: number[] = [];
    this.elements.filter((e: any) =>
      (e.ToType == "StockMoveOutgoingHeader" && e.ToId != this.documentId) ||
      (e.FromType == "StockMoveOutgoingHeader" && e.FromId != this.documentId))
      .map((e: any) => {
        items.push(e.ToType == "StockMoveOutgoingHeader" && e.ToId != this.documentId ? e.ToId : e.FromId);
      });
    this.itemsStockMoveSupplierOutgoingIds = items;

    var items: number[] = [];
    this.elements.filter((e: any) =>
      (e.ToType == "StockMoveIncomingHeader" && e.ToId != this.documentId) ||
      (e.FromType == "StockMoveIncomingHeader" && e.FromId != this.documentId))
      .map((e: any) => {
        items.push(e.ToType == "StockMoveIncomingHeader" && e.ToId != this.documentId ? e.ToId : e.FromId);
      });
    this.itemsStockMoveSupplierIncomingIds = items;
  }


  // public onDeleteRelation(e: any) {
  //   const relationId = e.data.DocumentRelationId;
  //   this.service.deleteRelation(relationId).then((r: any) => {
  //     console.log(r);
  //   });
  // }

}
