import { SaleDocumentHeaderStatus } from "../../sale-documents/sale-document-header-status/sale-document-header-status.model";
import { SaleOrderHeaderStatus } from "../sale-order-header-statuses/sale-order-header-status.model";
import { SaleOrder } from "../sale-orders/sale-order.model";

export class SaleOrderHeaderHeaderStatus extends SaleDocumentHeaderStatus {
    public override HeaderId?: SaleOrder;
    public override StatusId?: SaleOrderHeaderStatus = new SaleOrderHeaderStatus();

    constructor(entity?: Partial<SaleOrderHeaderHeaderStatus>) {
        super();
        Object.assign(this, entity);
    }

}