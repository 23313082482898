import { Entity } from "../../../core/models/entity";
import { SaleDocumentLine } from "../../../sales/sale-documents/sale-document-lines/sale-document-line.model";

export class DocumentLineRelation extends Entity {
    public FromId?: number;
    public ToId?: number;
    public FromType?: string;
    public ToType?: string;
    public To?: SaleDocumentLine;
    public From?: SaleDocumentLine;

    constructor(entity?: Partial<DocumentLineRelation>) {
        super();
        Object.assign(this, entity);
    }
}