<div class="d-flex">
  <!-- <div class="col-4" > -->
    <div class="row" style="width: 40%;">
      <div class="col-6">
        <syslink-input-date
          name="Date"
          label="Date"
          [(model)]="element.Date"
          [disabled]="disabled"
          (modelChange)="onDateChanged()"
          type="date"
          format="date"
        ></syslink-input-date>
      </div>
      <div *ngIf="documentType!='SaleOrder' && documentType!='SaleCreditNote'" class="col-6">
        <syslink-select
         
          [label]="documentType + '.DocumentDelay' | translate"
          [store]="documentDelaysService.store"
          [(model)]="element.DocumentDelayId"
          [disabled]="disabled"
          (modelChange)="onDateChanged()"
        ></syslink-select>
      </div>
      <div *ngIf="documentType != 'SaleOrder' && documentType!='SaleCreditNote'" class="col-6">
        <syslink-input-date
          
          name="Validity"
          [label]="(documentType + '.Validity') | translate"
          [(model)]="element.Deadline"
          [disabled]="true"
          type="date"
          format="date"
        ></syslink-input-date>
      </div>
      <div class="col-6">
        <syslink-input-text
          label="External reference"
          [(model)]="element.ExternalReference"
          [disabled]="disabled"
        ></syslink-input-text>
      </div>
      <div class="col-12">
        <syslink-input-text
          label="Subject"
          [(model)]="element.Subject"
          [disabled]="disabled"
        ></syslink-input-text>
      </div>
    </div>
  <!-- </div> -->
  <!-- <div class="col-4"></div> -->
  <div class="d-flex justify-content-center align-items-center"  style="width: 60%;">
    <app-third-document-data-block [documentDataCollection]="element.DocumentDataCollection"  [showDeliveryAddress]="documentType == 'SaleOrder' ? true:false"></app-third-document-data-block>
  </div>
</div>

<!-- <div class="row">

  <div class="col-{{documentType === 'SaleContract' ? 4 : 6}}">
    <syslink-input-text
      name="Subject"
      label="Subject"
      [(model)]="element.Subject"
      [disabled]="disabled"
    ></syslink-input-text>
  </div>

  <div
    *ngIf="documentType === 'SaleContract'"
    class="col-2"
  >
    <syslink-input-date
      *ngIf="element.ExtensionDate?.getTime() != element.Deadline?.getTime() && element.ExtensionDate && element.ExtensionDate.getFullYear() > 1 && showExtensionDate"
      name="Validity"
      label="Extension date"
      [(model)]="element.ExtensionDate"
      [disabled]="true"
      type="date"
      format="date"
    ></syslink-input-date>
  </div>

  <div class="col-3">
    <ng-container *ngIf="documentType === 'SaleOrder' || documentType === 'SaleInvoice' || documentType === 'SaleCreditNote'">
      <div class="row">
        <div class="col-6">
          <div class="totalPriceLabel fw-bold py-2 text-end">
            {{'Remaining amount' | translate }} :
          </div>
        </div>
        <div class="col-6">
          <div class="totalPriceValue py-2 text-white text-center bg-{{getAmountRemainingColor()}}">
            {{ (element.AmountRemaining ?? 0) | syslinkCurrency:subModuleCode:'Precision.'+documentType+'Header.AmountRemaining' }}
          </div>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="documentType === 'SaleQuote'">
      <div class="row">
        <div class="col-6">
          <div class="totalPriceLabel fw-bold py-2 text-end">
            {{'TotalMargin' | translate }} :
          </div>
        </div>
        <div class="col-6">
          <div class="totalPriceValue py-2 bg-secondary text-white text-center">
            {{ (element.Margin ?? 0) | syslinkCurrency:subModuleCode:'Precision.'+documentType+'Header.ExTaxTotal' }}
          </div>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="documentType == 'SaleContract'">
      <div class="row">
        <div class="col-6">
          <div class="totalPriceLabel fw-bold py-2 text-end">
            {{ 'ExTaxTotalInvoiced' | translate }} :
          </div>
        </div>
        <div class="col-6">
          <div class="totalPriceValue py-2 bg-secondary text-white text-center">
            {{ element.ExTaxTotalInvoiced | syslinkCurrency:subModuleCode:'Precision.SaleInvoiceHeader.ExTaxTotal' }}
          </div>
        </div>
      </div>
    </ng-container>
  </div>

  <div class="col-3">
    <div class="row">
      <div class="col-6">
        <div class="totalPriceLabel fw-bold py-2 text-end">
          {{ 'ExTaxTotalPrice' | translate }} :
        </div>
      </div>
      <div class="col-6">
        <div class="totalPriceValue py-2 bg-secondary text-white text-center">
          {{ element.ExTaxTotal | syslinkCurrency:subModuleCode:'Precision.'+documentType+'Header.ExTaxTotal' }}
        </div>
      </div>
    </div>
  </div>
</div>

<div class="row">

  <div class="col-{{documentType === 'SaleContract' ? 2 : 3}}">
    <syslink-input-date
      name="Date"
      [label]="(documentType === 'SaleContract'?(documentType + '.Date') : 'Date')|translate"
      [(model)]="element.Date"
      [disabled]="(documentType === 'SaleQuote' || documentType === 'SaleInvoice' || documentType === 'SaleCreditNote') && disabled"
      (modelChange)="onDateChanged($event)"
      type="date"
    ></syslink-input-date>
  </div>

  <div class="col-{{documentType === 'SaleContract' ? 2 : 3}}">
    <syslink-input-date
      *ngIf="element.Deadline && documentType != 'SaleOrder'"
      name="Validity"
      [label]="(documentType + '.Validity') | translate"
      [(model)]="element.Deadline"
      (modelChange)="onChangeDeadLine()"
      [disabled]="documentType!='SaleContract' && (documentType === 'SaleQuote' || documentType === 'SaleInvoice' || documentType === 'SaleCreditNote' || disabled)"
      type="date"
      format="date"
    ></syslink-input-date>
  </div>

  <div
    *ngIf="documentType === 'SaleContract'"
    class="col-2"
  >
    <syslink-input-text
      *ngIf="element.Date && element.ExtensionDate && dateDiff != ''"
      name="Month"
      label="Month"
      [model]="dateDiff"
      [disabled]="true"
      [labelMode]="'hidden'"
    ></syslink-input-text>
  </div>

  <div class="col-3">
    <ng-container *ngIf="documentType === 'SaleOrder' || documentType === 'SaleInvoice' || documentType === 'SaleCreditNote'">
      <div class="row">
        <div class="col-6">
          <div class="totalPriceLabel fw-bold py-2 text-end">
            {{'Amount received' | translate }} :
          </div>
        </div>
        <div class="col-6">
          <div class="totalPriceValue py-2 text-white text-center bg-{{element.AmountReceived != 0 ? 'success' : 'secondary'}}">
            {{(element.AmountReceived??0) | syslinkCurrency:subModuleCode:'Precision.'+documentType+'Header.AmountReceived' }}
          </div>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="documentType === 'SaleQuote'">
      <div class="row">
        <div class="col-6">
          <div class="totalPriceLabel fw-bold py-2 text-end">
            {{'Margin (%)' | translate }} :
          </div>
        </div>
        <div class="col-6">
          <div class="totalPriceValue py-2 bg-secondary text-white text-center">
            {{ (element.MarginPercent ?? 0) }}&nbsp;%
          </div>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="documentType == 'SaleContract'">
      <div class="row">
        <div class="col-6">
          <div class="totalPriceLabel fw-bold py-2 text-end">
            {{ 'InTaxTotalInvoiced' | translate }} :
          </div>
        </div>
        <div class="col-6">
          <div class="totalPriceValue py-2 bg-secondary text-white text-center">
            {{ element.InTaxTotalInvoiced | syslinkCurrency:subModuleCode:'Precision.SaleInvoiceHeader.InTaxTotal' }}
          </div>
        </div>
      </div>
    </ng-container>
  </div>

  <div class="col-3">
    <div class="row">
      <div class="col-6">
        <div class="totalPriceLabel fw-bold py-2 text-end">
          {{ 'InTaxTotalPrice' | translate }} :
        </div>
      </div>
      <div class="col-6">
        <div class="totalPriceValue py-2 bg-secondary text-white text-center">
          {{ element.InTaxTotal | syslinkCurrency:subModuleCode:'Precision.'+documentType+'Header.InTaxTotal' }}
        </div>
      </div>
      <ng-container *ngIf="documentType != 'SaleQuote' && documentType != 'SaleCreditNote'">
        <div class="col-4"></div>
        <div class="col-4">
          <syslink-input-number
            [disabled]="true"
            label="TotalMargin"
            [labelMode]="'static'"
            [model]="element.Margin"
            format="#0.00 €"
          ></syslink-input-number>
        </div>
        <div class="col-4">
          <syslink-input-number
            [disabled]="true"
            label="Margin (%)"
            [labelMode]="'static'"
            [model]="(element.MarginPercent??0)/100"
            format="#0 %"
          ></syslink-input-number>
        </div>
      </ng-container>
    </div>
  </div>
</div> -->