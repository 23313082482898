import { Periodicity } from "../../../base/periodicities/periodicities/periodicity.model";
import { SaleDocument } from "../../sale-documents/sale-documents/sale-document.model";
import { SaleContractFinance } from "../sale-contract-finances/sale-contract-finance.model";
import { SaleContractHeaderHeaderStatus } from "../sale-contract-header-header-statuses/sale-contract-header-header-status.model";

export class SaleContract extends SaleDocument {
    public override DocumentFinances?: SaleContractFinance[];
    public override CurrentStatusLink?: SaleContractHeaderHeaderStatus;
    public override Statuses: SaleContractHeaderHeaderStatus[] = [];
    
    public AutomaticallySend: boolean = false;
    public AutomaticallyValidated: boolean = false;
    public StartPeriodDate?: Date;
    public StartPeriodDateOffset?: number;
    public IsStartPeriodDateOffsetPositive: boolean = false;
    public PeriodicityId?: Periodicity;
    public CurrentPeriodStartDate?: Date;
    public CurrentPeriodEndDate?: Date;
    public GenerateDocumentNextDate?: Date;
    // public DateFrom?:Date;
    // public DateTo?:Date;
    // public ContractId?:SaleDocument;
    public RRule?: string;
    // public NextIndexingDate?: Date;
    // public LastIndexingDate?: Date;
    public Renewal?: number
    // public RenewalDate?: Date;
    // public IndexingTypeId?:IndexingType;
    // public NotificationDelay?:number;
    // public IndexCoefficient?:number;
    // //public IndexFormula?:string;
    // public ExtensionDate?:Date;
    public StopDate?:Date;
    // public Indexing?:number;
    // public LastIndexingValue?:number|undefined;
    // public RenewalEndDate?:Date;

    // // TODO find a way to place these fields in SaleContract
    // public ExTaxTotalInvoiced : number = 0;
    // public InTaxTotalInvoiced : number = 0;
        



    constructor(entity?: Partial<SaleContract>) {
        super();
        Object.assign(this, entity);
    }
}
