import { Component, Input } from '@angular/core';
import { SyslinkColumn } from 'projects/libraries/syslink-components/src/lib/helpers/SyslinkColumn';
import { CustomersService } from '../customers.service';
import { Customer } from '../customer.model';
import { BooleanCellData } from 'projects/libraries/syslink-components/src/lib/grid/cells/boolean-cell/boolean-cell.component';
import { BaseGridComponent } from 'projects/libraries/syslink-components/src/lib/grid/base-grid/base-grid.component';

@Component({
  selector: 'app-customer-grid',
  templateUrl: './customer-grid.component.html',
  styleUrls: ['./customer-grid.component.scss']
})
export class CustomerGridComponent extends BaseGridComponent<Customer> {
  override storageKey?: string | undefined = "customers-grid";

  @Input() public columns: SyslinkColumn[] = [
    new SyslinkColumn({ field: 'ThirdId.No', label: 'Id', width: 80, sortIndex: 1, sortDirection: 'desc' }),
    new SyslinkColumn({ field: 'ThirdId.Fullname', label: 'fullname' }),
    new SyslinkColumn({ field: 'ThirdId.DisplayAddress', label: 'Adresse' }),
    new SyslinkColumn({ field: 'ThirdId.DisplayPhoneNumber', label: 'Phone number', width: 200 }),
    new SyslinkColumn({ field: 'ThirdId.DisplayEmail', label: 'Professional email', width: 200 }),
    new SyslinkColumn({ field: 'ThirdId.VatNumber', label: 'Vat number', width: 120 }),
    new SyslinkColumn({ field: 'ThirdId.Alias', label: 'Alias' }),
    new SyslinkColumn({ field: 'PriceListId.Name', label: 'PriceList', width: 120 }),
    new SyslinkColumn({ field: 'ThirdId.Blocked', label: 'Blocked', type: 'boolean', cellTemplate: 'boolean-cell', data: new BooleanCellData({ type: 'box', trueColor: 'danger', falseColor: "success" }), width: 100 }),
    new SyslinkColumn({ field: 'ThirdId.AccountingBOBReference', label: 'BOB reference', visible:false }),
    new SyslinkColumn({ field: 'ThirdId.Id', technical: true }),
  ];

  constructor(
    public customersService: CustomersService,
  ) {
    super();
  }

  public override expand?: string | string[] = ['ThirdId', 'PriceListId'];
  public override select?: string | string[] = [...this.columns.map((c: SyslinkColumn) => c.field)]
}
