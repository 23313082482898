<syslink-modal
    #modal
    [title]="title"
    width="450px"
    (onValidate)="onValidateButtonClicked(element)"
    validateText="Validate"
    cancelText="Cancel"
>
    <syslink-select
        *ngIf="showThird && thirdFilter"
        name="third"
        label="Third"
        [store]="thirdsService.store"
        [filter]="thirdFilter"
        [select]="['Fullname', 'Id', 'Blocked']"
        displayKey="Fullname"
        [(model)]="element.ThirdId"
        [detailsUrl]="detailsUrl"
        [canAdd]="thirdFilter[0].includes('customer')?(authService.hasPermission('thirds-customers') && authService.hasPermission('thirds.customers.list.add')):(authService.hasPermission('thirds-suppliers') && authService.hasPermission('thirds.suppliers.list.add'))"
        [canView]="thirdFilter[0].includes('customer')?authService.hasPermission('thirds-customers'):authService.hasPermission('thirds-suppliers')"
        [canRefresh]="true"
    ></syslink-select>
    <syslink-input-text
        *ngIf="showSubject"
        name="subject"
        label="Subject"
        [(model)]="element.Subject"
    ></syslink-input-text>
    <syslink-input-text
        *ngIf="showReference"
        name="reference"
        label="Reference"
        [(model)]="element.ExternalReference"
    ></syslink-input-text>
</syslink-modal>