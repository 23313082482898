import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ConfirmModalComponent, NotificationsService, PageComponent, SyslinkToolbarAction, SyslinkToolbarActionButton } from 'projects/libraries/syslink-components/src/public-api';
import { UserGroup } from '../user-group.model';
import { UserGroupsService } from '../user-groups.service';
import { UsersService } from '../../users/users.service';
import { UserSelectModalComponent } from '../../users/user-select-modal/user-select-modal.component';
import { User } from '../../users/user.model';
import { UserGridComponent } from '../../users/user-grid/user-grid.component';
import { BeforeDeleteEvent } from 'projects/libraries/syslink-components/src/lib/helpers/crud-component/events/BeforeDeleteEvent';
import { FilterDescriptor } from 'devextreme/data';
import { TranslatableStringsService } from '../../../../base/translations/translatable-strings/translatable-strings.service';
import { TranslatableString } from '../../../../base/translations/translatable-strings/translatable-string';
import { LanguagesService } from '../../../../base/languages/languages.service';
import { TranslationsService } from '../../../../base/translations/translations/translations.service';
import { ActionGroupSelectModalComponent } from '../../action-groups/action-group-select-modal/action-group-select-modal.component';
import { ActionGroupGridComponent } from '../../action-groups/action-group-grid/action-group-grid.component';
import { ActionGroupsService } from '../../action-groups/action-groups.service';
import { ActionGroup } from '../../action-groups/action-group.model';

@Component({
  selector: 'app-user-group-details',
  templateUrl: './user-group-details.component.html',
  styleUrls: ['./user-group-details.component.scss']
})
export class UserGroupDetailsComponent extends PageComponent implements OnInit {

  public element: UserGroup = new UserGroup();

  @ViewChild('deleteConfirm') deleteConfirm?: ConfirmModalComponent;
  @ViewChild('userSelectModal') userSelectModal?: UserSelectModalComponent;
  @ViewChild('userGrid') userGrid?: UserGridComponent;

  public usersFilters: FilterDescriptor | Array<FilterDescriptor>;

  // Action groups
  // -----------
  public actionGroupsFilters: FilterDescriptor | Array<FilterDescriptor>;
  @ViewChild("actionGroupsGrid") public actionGroupsGrid?: ActionGroupGridComponent;
  @ViewChild("actionGroupSelectModal") public actionGroupSelectModal?: ActionGroupSelectModalComponent;

  constructor(
    private ngxUiLoaderService: NgxUiLoaderService,
    public override activatedRoute: ActivatedRoute,
    private userGroupsService: UserGroupsService,
    private translatableStringsService: TranslatableStringsService,
    private languagesService: LanguagesService,
    private translationsService: TranslationsService,
    public actionGroupsService: ActionGroupsService
  ) {
    super();
  }

  override ngOnInit(): void {
    super.ngOnInit();
    this.activatedRoute.data.subscribe(async ({ element }) => {
      this.element = element;
      await this.refreshElement();
      this.ngxUiLoaderService.stop();
    });
  }
  public refreshElement() {
    this.updateBreadCrumb(this.element.Code);
    this.updateToolbar();
    this.updateUsersFilters();
    this.updateActionGroupsFilters();
  }
  public updateToolbar() {
    this.toolbarActions = [];
    this.toolbarActions.push(new SyslinkToolbarActionButton({ icon: "save", text: "Save", code: "Save", onClick: () => this.onSavebtnClicked(), hotkey: 'control.s', visible: this.element.Id ? this.authService.hasPermission(this.basePermissionKey + '.update') : this.authService.hasPermission(this.basePermissionKey + '.add') }));
    if (!this.element.Id) return;
    this.toolbarActions.push(new SyslinkToolbarActionButton({ icon: "trash", text: "Delete", code: "Delete", onClick: () => this.onGroupDeleteBtn(), visible: this.authService.hasPermission(this.basePermissionKey + '.delete') }));
  }

  // Save btn
  // --------
  public async onSavebtnClicked() {
    if (((this.element.Id && !this.authService.hasPermission(this.basePermissionKey + '.update')) || (!this.element.Id && !this.authService.hasPermission(this.basePermissionKey + '.add')))) {
      NotificationsService.sendErrorMessage("You do not have the required permission!");
      return;
    }

    if (!this.element.Id) {
      const translatableString: TranslatableString = await this.translatableStringsService.insert({
        Code: this.element.Code
      });

      for (const language of this.languagesService.languages) {
        await this.translationsService.insert({
          TranslatableStringId: translatableString,
          LanguageId: language,
          Value: language.IsoCode === 'fr' ? this.element.Name : ''
        });
      }

      this.element.NameTranslationId = translatableString;

      if (this.element.NameTranslationId?.Translations) {
        this.element.NameTranslationId.Translations.forEach((t: any) => {
          delete t.LanguageId;
        });
      }
    }


    // Checking Code field
    // -------------------
    if (this.element.Code == "") {
      NotificationsService.sendErrorMessage("Code cannot be empty");
      return;
    }

    var element = await this.userGroupsService.createOrUpdate(this.element);
    this.goToUrl('../' + element.Id);
  }

  // Group delete
  // --------------
  public onGroupDeleteBtn() {
    this.deleteConfirm?.open();
  }
  public async groupDelete() {
    this.deleteConfirm?.close();
    if (!this.element?.Id) return;
    await this.userGroupsService.remove(this.element.Id);
    NotificationsService.sendInfo('Record deleted');
    this.goToUrl('../');
  }


  public onAddUserButtonClicked() {
    this.userSelectModal?.modal?.open();
  }

  public async onDeleteButtonClicked(e: BeforeDeleteEvent) {
    e.cancel = true;
    if (!this.element.Id || !e.data.Oid) return;

    await this.userGroupsService.removeUser(this.element.Id, e.data.Oid);

    await this.refreshElement();
    this.userGrid?.refresh();
    this.userSelectModal?.modal?.close();

  }

  public async onValidateUserAddButtonClicked(e: User) {
    if (!this.element.Id || !e.Oid) return;

    this.element.Users?.push(this.usersService.format(e));
    await this.userGroupsService.update(this.element.Id, this.element);

    this.userGrid?.refresh();
    this.userSelectModal?.modal?.close();
  }

  public updateUsersFilters() {
    if (this.element.Id)
      this.usersFilters = ['Groups/any(g:g/Id eq ' + this.element.Id + ')'];
  }

  // Action Groups
  // -----------
  public updateActionGroupsFilters() {
    if (this.element.Id)
      this.actionGroupsFilters = ['UserGroups/any(e:e/Id eq ' + this.element.Id + ')'];
  }

  public async addActionGroupButtonClicked() {
    if (!this.actionGroupSelectModal) return;

    const currentGroupsFilter = ["not(Id in (" + this.element.ActionGroups?.map(e => e.Id).join(',') + "))"];

    // Checking if groups are available
    // --------------------------------
    if (this.element.ActionGroups?.length != 0) {
      const availableGroups = await this.actionGroupsService.filter(currentGroupsFilter);
      if (availableGroups.length == 0) {
        NotificationsService.sendInfo("No action group available");
        return;
      }

      this.actionGroupSelectModal.selectFilters = currentGroupsFilter;
    }

    this.actionGroupSelectModal?.open();

  }

  public async onValidateActionGroupAddButtonClicked(e: ActionGroup) {
    if (!this.element.Id || !e.Id) return;
    if (!this.element.ActionGroups) {
      this.element.ActionGroups = [];
    }
    this.element.ActionGroups.push(this.actionGroupsService.format(e));
    await this.userGroupsService.update(this.element.Id, this.userGroupsService.format(this.element));

    this.actionGroupsGrid?.refresh();
    this.actionGroupSelectModal?.close();
  }

  public async onDeleteActionGroup(group: ActionGroup) {
    if (!this.element.Id || !group.Id) return;

    this.element.ActionGroups = this.element.ActionGroups.filter(e => e.Id != group.Id);
    await this.userGroupsService.removeActionGroup(this.element.Id, group.Id);

    this.actionGroupsGrid?.refresh();
  }
}
