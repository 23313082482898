import { Component } from '@angular/core';
import { ThirdsService } from './thirds.service';
import { PageComponent } from 'projects/libraries/syslink-components/src/public-api';
import { ActivatedRoute } from '@angular/router';
import { Third } from './third.model';

@Component({
  selector: 'app-thirds',
  templateUrl: './thirds.component.html',
  styleUrls: ['./thirds.component.scss']
})
export class ThirdsComponent extends PageComponent {

  constructor(
    public thirdsService: ThirdsService,
    protected override activatedRoute: ActivatedRoute
  ) {
    super();
  }

  public onAddButtonClicked() {
    this.router.navigate(['new'], { relativeTo: this.activatedRoute });
  }

  public onRowDoubleClicked(element: Third): void {
    this.router.navigate([element.Id], { relativeTo: this.activatedRoute });
  }
}
