import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { BaseGridComponent } from 'projects/libraries/syslink-components/src/lib/grid/base-grid/base-grid.component';
import { AccountMoveLine } from '../../account-move-line.model';
import { GridComponent } from 'projects/libraries/syslink-components/src/public-api';
import { SyslinkColumn } from 'projects/libraries/syslink-components/src/lib/helpers/SyslinkColumn';
import { ThirdsService } from 'projects/erp-app/src/app/thirds/thirds/thirds.service';
import { AllSaleDocumentsService } from 'projects/erp-app/src/app/sales/sale-documents/sale-documents/all-sale-documents.service';
import { AccountsService } from '../../../accounts/accounts.service';
import { TaxesService } from '../../../taxes/taxes.service';
// import { AccountMoveLinesService } from '../../account-move-line.service';

@Component({
  selector: 'app-account-move-line-grid-items',
  templateUrl: './account-move-line-grid-items.component.html',
  styleUrls: ['./account-move-line-grid-items.component.scss']
})
export class AccountMoveLineGridItemsComponent extends BaseGridComponent<AccountMoveLine> implements OnInit {

  @Input() storageKey: string = 'grid-account-move-line-items';
  @Input() public override expand?: string | string[] = ['AccountMoveId', 'AccountId', 'SaleDocumentId', 'TaxId'];

  @Input() public elements: AccountMoveLine[] = [];
  @Output() public elementsChange: EventEmitter<any> = new EventEmitter<any>();

  private _disabled: boolean = false;
  @Input() set disabled(value: boolean) {
    this._disabled = value;
    this.updateListOptions();
  }
  get disabled(): boolean { return this._disabled; };

  @ViewChild('gridComponent') public gridComponent?: GridComponent;

  public columns: SyslinkColumn[] = [
    new SyslinkColumn({ field: 'LineNo', label: 'No', editable: false }),
    new SyslinkColumn({ field: 'Date', label: 'Date', type: 'date' }),
    new SyslinkColumn({ field: 'Debit', type: 'number' }),
    new SyslinkColumn({ field: 'Credit', type: 'number' }),
    new SyslinkColumn({ field: 'Deadline', label: 'Deadline', type: 'date' }),
    // new SyslinkColumn({ field: "ThirdId", label: "Third", cellTemplate: 'select-value', editCellTemplate: "select-cell", data: { service: this.thirdsService, displayKey: 'Fullname' } }),    
    new SyslinkColumn({ field: "SaleDocumentId", label: "Document", cellTemplate: 'select-value', editCellTemplate: "select-cell", data: { service: this.allSaleDocumentsService, displayKey: 'NameFormatted' } }),
    new SyslinkColumn({ field: "AccountId", label: "Account", cellTemplate: 'select-value', editCellTemplate: "select-cell", data: { service: this.accountsService, displayKey: 'FormattedName' } }),
    new SyslinkColumn({ field: "TaxId", label: "Tax", cellTemplate: 'select-value', editCellTemplate: "select-cell", data: { service: this.taxesService, displayKey: 'Alias' } })
  ];

  constructor(
    public thirdsService: ThirdsService,
    public allSaleDocumentsService: AllSaleDocumentsService,
    public accountsService: AccountsService,
    public taxesService: TaxesService,

  ) {
    super();
  }

  public override ngOnInit(): void {
    this.updateListOptions();
  }
  public updateListOptions(): void {
    if (this.disabled) {
      this.canDelete = false;
      this.canAdd = false;
      this.canUpdate = false;
    }
    else {
      this.canDelete = true;
      this.canAdd = true;
      this.canUpdate = true;
    }
    this.gridComponent?.ngOnInit();

    // this.gridComponent?.grid?.instance.option('editing.allowAdding', !this.disabled);
    // this.gridComponent?.grid?.instance.option('editing.allowUpdating', !this.disabled);
    // this.gridComponent?.grid?.instance.option('editing.allowDeleting', !this.disabled);
  }

}