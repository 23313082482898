import { Injectable } from '@angular/core';
import { LoadOptions } from 'devextreme/data';
import { SaleDocumentHeaderStatusesService } from '../../sale-documents/sale-document-header-status/sale-document-header-statuses.service';
import { SaleQuoteHeaderHeaderStatus } from './sale-quote-header-header-status.model';

@Injectable({
  providedIn: 'root'
})
export class SaleQuoteHeaderHeaderStatusesService extends SaleDocumentHeaderStatusesService<SaleQuoteHeaderHeaderStatus> {
  public override url: string = 'SaleQuoteHeaderHeaderStatus';
  public override defaultOptions: LoadOptions = {
    expand: ["StatusId"]
  };

  public override format(element: Partial<SaleQuoteHeaderHeaderStatus>): SaleQuoteHeaderHeaderStatus {
    var result: any = {
      ...element,
      HeaderId:element.HeaderId?.Id,
      StatusId:element.StatusId?.Id
    };
    return result;
  }

  public override async getInstance(params?: Partial<SaleQuoteHeaderHeaderStatus>): Promise<SaleQuoteHeaderHeaderStatus> {
    var element = new SaleQuoteHeaderHeaderStatus({
      Date: new Date(),
      ...params
    });
    return element;
  }

}
