import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { Third } from '../third.model';
import { DocumentRelationData } from '../../../base/documents/documents/document-relations-block/document-relations-block.component';
import { ThirdsService } from '../thirds.service';
import { SyslinkColumn } from 'projects/libraries/syslink-components/src/lib/helpers/SyslinkColumn';
import { SaleQuoteGridComponent } from '../../../sales/sale-quotes/sale-quote-grid/sale-quote-grid.component';
import { SaleQuotesService } from '../../../sales/sale-quotes/sale-quotes/sale-quotes.service';
import { SaleOrdersService } from '../../../sales/sale-orders/sale-orders/sale-orders.service';
import { SaleInvoicesService } from '../../../sales/sale-invoices/sale-invoices/sale-invoices.service';
import { SaleCreditNotesService } from '../../../sales/sale-credit-notes/sale-credit-notes/sale-credit-notes.service';
import { SaleContractsService } from '../../../sales/sale-contracts/sale-contracts/sale-contracts.service';
import { PurchaseOrdersService } from '../../../purchases/purchase-orders/purchase-orders/purchase-orders.service';
import { PurchaseInvoicesService } from '../../../purchases/purchase-invoices/purchase-invoices/purchase-invoices.service';
import { PurchaseCreditNotesService } from '../../../purchases/purchase-credit-notes/purchase-credit-notes/purchase-credit-notes.service';
import { SaleOrderGridComponent } from '../../../sales/sale-orders/sale-order-grid/sale-order-grid.component';
import { SaleInvoiceGridComponent } from '../../../sales/sale-invoices/sale-invoice-grid/sale-invoice-grid.component';
import { SaleCreditNoteGridComponent } from '../../../sales/sale-credit-notes/sale-credit-note-grid/sale-credit-note-grid.component';
import { SaleContractGridComponent } from '../../../sales/sale-contracts/sale-contract-grid/sale-contract-grid.component';
import { PurchaseInvoiceGridComponent } from '../../../purchases/purchase-invoices/purchase-invoice-grid/purchase-invoice-grid.component';
import { PurchaseOrderGridComponent } from '../../../purchases/purchase-orders/purchase-order-grid/purchase-order-grid.component';
import { PurchaseCreditNoteGridComponent } from '../../../purchases/purchase-credit-notes/purchase-credit-note-grid/purchase-credit-note-grid.component';
import { StockMoveIncomingGridComponent } from '../../../stocks/stock-move-incomings/stock-move-incoming-grid/stock-move-incoming-grid.component';
import { StockMoveOutgoingGridComponent } from '../../../stocks/stock-move-outgoings/stock-move-outgoing-grid/stock-move-outgoing-grid.component';
import { StockMoveOutgoingsService } from '../../../stocks/stock-move-outgoings/stock-move-outgoings/stock-move-outgoings.service';
import { StockMoveIncomingsService } from '../../../stocks/stock-move-incomings/stock-move-incomings/stock-move-incomings.service';
import { BooleanCellData } from 'projects/libraries/syslink-components/src/lib/grid/cells/boolean-cell/boolean-cell.component';
import { WorkTimeGridComponent } from '../../../time-management/work-times/work-times/work-time-grid/work-time-grid.component';
import { WorkTime } from '../../../time-management/work-times/work-times/work-time';
import { WorkTimesService } from '../../../time-management/work-times/work-times/work-times.service';
import { WorkTimeModalComponent } from '../../../time-management/work-times/work-times/work-time-modal/work-time-modal.component';
import { TaskModalComponent } from '../../../time-management/tasks/tasks/task-modal/task-modal.component';
import { TaskGridComponent } from '../../../time-management/tasks/tasks/task-grid/task-grid.component';
import { Task, TaskTimeStateCode } from '../../../time-management/tasks/tasks/task.model';
import { TasksService } from '../../../time-management/tasks/tasks/tasks.service';
import { BadgeListCellData, ColoredDateCellData } from 'projects/libraries/syslink-components/src/public-api';
import { TranslateService } from '@ngx-translate/core';
import { DatePipe } from '@angular/common';
import { ViewComponent } from 'projects/libraries/syslink-components/src/lib/helpers/view/view.component';

@Component({
  selector: 'app-third-document-relations',
  templateUrl: './third-document-relations.component.html',
  styleUrls: ['./third-document-relations.component.scss']
})
export class ThirdDocumentRelationsComponent extends ViewComponent implements AfterViewInit {
  @Input() public element: Third = new Third();

  public documentsRelation?: DocumentRelationData[];
  @ViewChild("workTimeModal") workTimeModal?: WorkTimeModalComponent;
  @ViewChild("taskModal") taskModal?: TaskModalComponent;

  constructor(
    private saleQuotesService: SaleQuotesService,
    private saleOrdersService: SaleOrdersService,
    private saleInvoicesService: SaleInvoicesService,
    private saleCreditNotesService: SaleCreditNotesService,
    private saleContractsService: SaleContractsService,
    private purchaseOrdersService: PurchaseOrdersService,
    private purchaseInvoicesService: PurchaseInvoicesService,
    private purchaseCreditNotesService: PurchaseCreditNotesService,
    private stockMoveOutgoingsService: StockMoveOutgoingsService,
    private stockMoveIncomingsService: StockMoveIncomingsService,
    private workTimesService: WorkTimesService,
    private tasksService: TasksService,
    private translateService: TranslateService,
    private ThirdsService: ThirdsService,
    private datePipe: DatePipe
  ) {
    super();
  }

  override async ngAfterViewInit(): Promise<void> {
    await this.load();
  }

  public async onAddClicked(data: { type: any }) {
    switch (data.type) {
      case "SaleQuote":
        window.open('/sales/quotes/new?ThirdId=' + this.element.Id);
        break;
      case "SaleOrder":
        window.open('/sales/orders/new?ThirdId=' + this.element.Id);
        break;
      case "SaleInvoice":
        window.open('/sales/invoices/new?ThirdId=' + this.element.Id);
        break;
      case "SaleCreditNote":
        window.open('/sales/credit-notes/new?ThirdId=' + this.element.Id);
        break;
      case "SaleContract":
        window.open('/sales/contracts/new?ThirdId=' + this.element.Id);
        break;
      case "PurchaseOrder":
        window.open('/purchases/orders/new?ThirdId=' + this.element.Id);
        break;
      case "PurchaseInvoice":
        window.open('/purchases/invoices/new?ThirdId=' + this.element.Id);
        break;
      case "PurchaseCreditNote":
        window.open('/purchases/credit-notes/new?ThirdId=' + this.element.Id);
        break;
      case "StockMoveCustomerOutgoing":
        window.open('/stockmoves/customeroutgoings/new?ThirdId=' + this.element.Id);
        break;
      case "StockMoveCustomerIncoming":
        window.open('/stockmoves/customerincomings/new?ThirdId=' + this.element.Id);
        break;
      case "StockMoveSupplierIncoming":
        window.open('/stockmoves/supplierincomings/new?ThirdId=' + this.element.Id);
        break;
      case "StockMoveSupplierOutgoing":
        window.open('/stockmoves/supplieroutgoings/new?ThirdId=' + this.element.Id);
        break;
      case "TimeManagementWorkTime":
        this.workTimeModal?.open(await this.workTimesService.getInstance({
          ThirdId: this.element
        }));
        break;
      case "TimeManagementTask":
        if (this.taskModal) {
          this.taskModal.title = this.translateService.instant("Create new Task");
        }
        this.taskModal?.open(await this.tasksService.getInstance({
          ThirdId: this.element
        }));
        break;
    }
  }
  public async onDoubleClicked(data: { type: any, task: any, worktime: any }) {
    switch (data.type) {
      case "TimeManagementWorkTime":
        this.workTimeModal?.open(data.task.worktime)
        break;
      case "TimeManagementTask":
        if (this.taskModal) {
          this.taskModal.title = this.translateService.instant("Edit task");
        }
        this.taskModal?.open(new Task(data.task));
        break;
    }
  }

  public async load() {
    if (!this.element.Id) return;
    var relations = await this.ThirdsService.GetDocumentRelation(this.element.Id)

    this.documentsRelation = undefined;
    this.documentsRelation = [ // interne caché
      { id: 0, key: 'SaleQuote', group:'Sale', title: "Quotes", visible: this.authService.hasPermission(this.basePermissionKey + '.SaleQuote.block.view'), ids: [...relations.saleQuoteIds], value: relations.saleQuoteIds.length, gridFilter: "ThirdId.Id eq " + this.element.Id, columns: this.SaleQuoteColumns },
      { id: 1, key: 'SaleOrder', group:'Sale', title: "Orders", visible: this.authService.hasPermission(this.basePermissionKey + '.SaleOrder.block.view'), ids: [...relations.saleOrderIds], value: relations.saleOrderIds.length, gridFilter: "ThirdId.Id eq " + this.element.Id, columns: this.SaleOrderColumns },
      { id: 2, key: 'SaleInvoice', group:'Sale', title: "Invoices", visible: this.authService.hasPermission(this.basePermissionKey + '.SaleInvoice.block.view'), ids: [...relations.saleInvoiceIds], value: relations.saleInvoiceIds.length, gridFilter: "ThirdId.Id eq " + this.element.Id, columns: this.SaleInvoiceColumns },
      { id: 3, key: 'SaleCreditNote', group:'Sale', title: "Credit notes", visible: this.authService.hasPermission(this.basePermissionKey + '.SaleCreditNote.block.view'), ids: [...relations.saleCreditNoteIds], value: relations.saleCreditNoteIds.length, gridFilter: "ThirdId.Id eq " + this.element.Id, columns: this.SaleCreditNoteColumns },
      { id: 4, key: 'SaleContract', group:'Sale', title: "Contracts", visible: this.authService.hasPermission(this.basePermissionKey + '.SaleContract.block.view'), ids: [...relations.saleContractIds], value: relations.saleContractIds.length, gridFilter: "ThirdId.Id eq " + this.element.Id, columns: this.SaleContractColumns },
      { id: 5, key: 'PurchaseOrder', group:'Purchase', title: "Purchase orders", visible: this.authService.hasPermission(this.basePermissionKey + '.PurchaseOrder.block.view'), ids: [...relations.purchaseOrderIds], value: relations.purchaseOrderIds.length, gridFilter: "ThirdId.Id eq " + this.element.Id, columns: this.PurchaseOrderColumns },
      { id: 6, key: 'PurchaseInvoice', group:'Purchase', title: "Purchase invoices", visible: this.authService.hasPermission(this.basePermissionKey + '.PurchaseInvoice.block.view'), ids: [...relations.purchaseInvoiceIds], value: relations.purchaseInvoiceIds.length, gridFilter: "ThirdId.Id eq " + this.element.Id, columns: this.PurchaseInvoiceColumns },
      { id: 7, key: 'PurchaseCreditNote', group:'Purchase', title: "Purchase credit notes", visible: this.authService.hasPermission(this.basePermissionKey + '.PurchaseCreditNote.block.view'), ids: [...relations.purchaseCreditNoteIds], value: relations.purchaseCreditNoteIds.length, gridFilter: "ThirdId.Id eq " + this.element.Id, columns: this.PurchaseCreditNoteColumns },
      { id: 8, key: 'StockMoveCustomerIncoming', group:'Stock', title: "Stock Move Customer Incomings", visible: this.authService.hasPermission(this.basePermissionKey + '.StockMoveCustomerIncoming.block.view'), ids: [...relations.stockMoveCustomerIncomingIds], value: relations.stockMoveCustomerIncomingIds.length, gridFilter: "ThirdId.Id eq " + this.element.Id + "and Type eq 'customer'", columns: this.StockMoveIncomingColumns },
      { id: 9, key: 'StockMoveCustomerOutgoing', group:'Stock', title: "Stock Move Customer Outgoings", visible: this.authService.hasPermission(this.basePermissionKey + '.StockMoveCustomerOutgoing.block.view'), ids: [...relations.stockMoveCustomerOutgoingIds], value: relations.stockMoveCustomerOutgoingIds.length, gridFilter: "ThirdId.Id eq " + this.element.Id + "and Type eq 'customer'", columns: this.StockMoveOutgoingColumns },
      { id: 10, key: 'StockMoveSupplierOutgoing', group:'Stock', title: "Stock Move Supplier Outgoings", visible: this.authService.hasPermission(this.basePermissionKey + '.StockMoveSupplierOutgoing.block.view'), ids: [...relations.stockMoveSupplierOutgoingIds], value: relations.stockMoveSupplierOutgoingIds.length, gridFilter: "ThirdId.Id eq " + this.element.Id + "and Type eq 'supplier'", columns: this.StockMoveOutgoingColumns },
      { id: 11, key: 'StockMoveSupplierIncoming', group:'Stock', title: "Stock Move Supplier Incomings", visible: this.authService.hasPermission(this.basePermissionKey + '.StockMoveSupplierIncoming.block.view'), ids: [...relations.stockMoveSupplierIncomingIds], value: relations.stockMoveSupplierIncomingIds.length, gridFilter: "ThirdId.Id eq " + this.element.Id + "and Type eq 'supplier'", columns: this.StockMoveIncomingColumns },
      { id: 12, key: 'TimeManagementTask', group:'TimeManagement', title: "Tasks", visible: this.authService.hasPermission(this.basePermissionKey + '.TimeManagementTask.block.view'), ids: [...relations.timeManagementTaskIds], value: relations.timeManagementTaskIds.length, gridFilter: "ThirdId.Id eq " + this.element.Id, columns: this.TaskColumns },
      { id: 13, key: 'TimeManagementWorkTime', group:'TimeManagement', title: "Worktimes", visible: this.authService.hasPermission(this.basePermissionKey + '.TimeManagementWorkTime.block.view'), ids: [...relations.timeManagementWotkTimeIds], value: relations.timeManagementWotkTimeIds.length, gridFilter: "ThirdId.Id eq " + this.element.Id, columns: this.WorktimeColumns },
    ]
  }

  // Columns
  // -------
  @ViewChild('saleGuoteGridComponent') saleQuoteGridComponent: SaleQuoteGridComponent = new SaleQuoteGridComponent(this.saleQuotesService);
  @ViewChild('saleOrderGridComponent') saleOrderGridComponent: SaleOrderGridComponent = new SaleOrderGridComponent(this.saleOrdersService);
  @ViewChild('saleInvoiceGridComponent') saleInvoiceGridComponent: SaleInvoiceGridComponent = new SaleInvoiceGridComponent(this.saleInvoicesService, this.datePipe, this.translateService);
  @ViewChild('saleCreditNoteGridComponent') saleCreditNoteGridComponent: SaleCreditNoteGridComponent = new SaleCreditNoteGridComponent(this.saleCreditNotesService);
  @ViewChild('saleContractGridComponent') saleContractGridComponent: SaleContractGridComponent = new SaleContractGridComponent(this.saleContractsService);
  @ViewChild('purchaseOrderGridComponent') purchaseOrderGridComponent: PurchaseOrderGridComponent = new PurchaseOrderGridComponent(this.purchaseOrdersService);
  @ViewChild('purchaseInvoiceGridComponent') purchaseInvoiceGridComponent: PurchaseInvoiceGridComponent = new PurchaseInvoiceGridComponent(this.purchaseInvoicesService);
  @ViewChild('purchaseCreditNoteGridComponent') purchaseCreditNoteGridComponent: PurchaseCreditNoteGridComponent = new PurchaseCreditNoteGridComponent(this.purchaseCreditNotesService);
  @ViewChild('stockMoveIncomingGridComponent') stockMoveIncomingGridComponent: StockMoveIncomingGridComponent = new StockMoveIncomingGridComponent(this.stockMoveIncomingsService);
  @ViewChild('stockMoveOutgoingGridComponent') stockMoveOutgoingGridComponent: StockMoveOutgoingGridComponent = new StockMoveOutgoingGridComponent(this.stockMoveOutgoingsService);
  @ViewChild("workTimeGrid") workTimeGrid?: WorkTimeGridComponent;
  @ViewChild('taskGridComponent') taskGridComponent: TaskGridComponent = new TaskGridComponent(this.tasksService, this.workTimesService);

  public SaleQuoteColumns: SyslinkColumn[] = [
    new SyslinkColumn({ field: "No", label: 'GRID.CAPTION.NO', width: 150, sortIndex: 2, sortDirection: 'desc' }),
    new SyslinkColumn({ field: "ThirdId.Fullname", label: "Customer", width: 250 }),
    new SyslinkColumn({ field: "Subject", label: "Subject" }),
    new SyslinkColumn({ field: "CurrentStatusLink.StatusId.Name", label: "Status", cellTemplate: 'block-cell', data: { getBlockLabel: this.saleQuoteGridComponent.getStatusCellLabel, getBlockClass: this.saleQuoteGridComponent.getStatusCellClass, getBlockColor: this.saleQuoteGridComponent.getStatusCellColor }, width: 120 }),
    new SyslinkColumn({ field: "Deadline", label: "SaleQuote.Validity", type: "date", visible: false, width: 150 }),
    new SyslinkColumn({ field: "ExternalReference", label: "External reference", visible: true, width: 150 }),
    new SyslinkColumn({ field: "ExTaxTotal", label: "exTaxTotal", type: "number", data: { key: 'TotalPrecision' }, cellTemplate: 'currency-cell', width: 80 }),
    new SyslinkColumn({ field: "InTaxTotal", label: "Total", type: "number", data: { key: 'TotalPrecision' }, cellTemplate: 'currency-cell', width: 80 }),
    new SyslinkColumn({ field: "ResponsibleUserId.Fullname", label: "Responsible", width: 120 }),
    new SyslinkColumn({ field: "Date", label: "Date", type: "date", visible: false, width: 150 }),
    new SyslinkColumn({ field: "CurrentStatusLink.StatusId.Sequence", label: 'status order', sortIndex: 1, sortDirection: 'asc', filterable: false, headerFilterable: false, width: 0, visible: false })
 ];
  public SaleOrderColumns: SyslinkColumn[] = [
    new SyslinkColumn({ field: "No", label: "GRID.CAPTION.NO", width: 150, sortIndex: 2, sortDirection: 'desc' }),
    new SyslinkColumn({ field: "ThirdId.Fullname", label: "Customer", width: 250 }),
    new SyslinkColumn({ field: "Subject", label: "Subject" }),
    new SyslinkColumn({ field: "CurrentStatusLink.StatusId.Name", label: "Status", cellTemplate: 'block-cell', data: { getBlockLabel: this.saleOrderGridComponent.getStatusCellLabel, getBlockClass: this.saleOrderGridComponent.getStatusCellClass, getBlockColor: this.saleOrderGridComponent.getStatusCellColor }, width: 120 }),
    new SyslinkColumn({ field: "ExternalReference", label: "External reference", visible: true, width: 150 }),
    new SyslinkColumn({ field: "ExTaxTotal", label: "exTaxTotal", type: "number", cellTemplate: 'currency-cell', data: { key: 'TotalPrecision' }, width: 80 }),
    new SyslinkColumn({ field: "InTaxTotal", label: "Total", type: "number", cellTemplate: 'currency-cell', data: { key: 'TotalPrecision' }, width: 80 }),
    new SyslinkColumn({ field: "ResponsibleUserId.Fullname", label: "Responsible", width: 120 }),
    new SyslinkColumn({ field: "Date", label: "Date", type: "date", visible: false, width: 150 }),
    new SyslinkColumn({ field: "CurrentStatusLink.StatusId.Sequence", label: 'status order', sortIndex: 1, sortDirection: 'asc', width: 0, visible: false, filterable: false, headerFilterable: false, sortable: false })
  ];
  public SaleInvoiceColumns: SyslinkColumn[] = [
    new SyslinkColumn({ field: "No", label: "GRID.CAPTION.NO", width: 150, sortIndex: 2, sortDirection: 'desc' }),
    new SyslinkColumn({ field: "Subject", label: "Subject" }),
    new SyslinkColumn({ field: "CurrentStatusLink.StatusId.Name", label: "Status", cellTemplate: 'block-cell', data: { getBlockLabel: this.saleInvoiceGridComponent.getStatusCellLabel, getBlockClass: this.saleInvoiceGridComponent.getStatusCellClass, getBlockColor: this.saleInvoiceGridComponent.getStatusCellColor }, width: 120 }),
    new SyslinkColumn({ field: "Deadline", label: 'SaleInvoice.Validity', type: "date", cellTemplate: "block-cell", width: 120, data: { getBlockLabel: this.saleInvoiceGridComponent.getDeadlineCellLabel, getBlockClass: this.saleInvoiceGridComponent.getDeadlineCellClass.bind(this.saleInvoiceGridComponent), getBlockColor: this.saleInvoiceGridComponent.getDeadlineCellColor.bind(this.saleInvoiceGridComponent), getBlockText: this.saleInvoiceGridComponent.getDeadlineCellText.bind(this.saleInvoiceGridComponent) } }),
    new SyslinkColumn({ field: "ExternalReference", label: "External reference", visible: true, width: 150 }),
    new SyslinkColumn({ field: "ExTaxTotal", label: "exTaxTotal", type: "number", cellTemplate: 'currency-cell', data: { key: 'TotalPrecision' }, width: 80 }),
    new SyslinkColumn({ field: "InTaxTotal", label: "Total", type: "number", cellTemplate: 'currency-cell', data: { key: 'TotalPrecision' }, width: 80 }),
    new SyslinkColumn({ field: "SaleContractId.No", label: "Contract", width: 150 }),
    new SyslinkColumn({ field: "PeriodLabel", label: "Period", filterable: false, headerFilterable: false, sortable: false }),
    new SyslinkColumn({ field: "ResponsibleUserId.Fullname", label: "Responsible", width: 120 }),
    new SyslinkColumn({ field: "Date", label: "Date", type: "date", visible: false, width: 150 }),
    new SyslinkColumn({ field: "AmountRemaining", label: 'Remaining amount', cellTemplate: "block-cell", width: 120, data: { getBlockLabel: this.saleInvoiceGridComponent.getAmountRemainingCellLabel.bind(this.saleInvoiceGridComponent), getBlockClass: this.saleInvoiceGridComponent.getAmountRemainingCellClass.bind(this.saleInvoiceGridComponent), getBlockColor: this.saleInvoiceGridComponent.getAmountRemainingCellColor.bind(this.saleInvoiceGridComponent) } }),
    new SyslinkColumn({ field: "FormattedStructuredCommunication", label: 'Structured communication', visible: false, editable: false }),
    new SyslinkColumn({ field: "CurrentStatusLink.StatusId.Sequence", label: 'status order', sortIndex: 1, sortDirection: 'asc', width: 0, visible: false, filterable: false, headerFilterable: false, sortable: false })
  ];
  public SaleCreditNoteColumns: SyslinkColumn[] = [
    new SyslinkColumn({ field: "No", label: "GRID.CAPTION.NO", width: 150, sortIndex: 2, sortDirection: 'desc' }),
    new SyslinkColumn({ field: "ThirdId.Fullname", label: "Customer", width: 250 }),
    new SyslinkColumn({ field: "Subject", label: "Subject" }),
    new SyslinkColumn({ field: "CurrentStatusLink.StatusId.Name", label: "Status", cellTemplate: 'block-cell', data: { getBlockLabel: this.saleCreditNoteGridComponent.getStatusCellLabel, getBlockClass: this.saleCreditNoteGridComponent.getStatusCellClass, getBlockColor: this.saleCreditNoteGridComponent.getStatusCellColor }, width: 120 }),
    new SyslinkColumn({ field: "ExternalReference", label: "External reference", visible: true, width: 150 }),
    new SyslinkColumn({ field: "ExTaxTotal", label: "exTaxTotal", type: "number", cellTemplate: 'currency-cell', data: { key: 'TotalPrecision' }, width: 80 }),
    new SyslinkColumn({ field: "InTaxTotal", label: "Total", type: "number", cellTemplate: 'currency-cell', data: { key: 'TotalPrecision' }, width: 80 }),
    new SyslinkColumn({ field: "ResponsibleUserId.Fullname", label: "Responsible", width: 120 }),
    new SyslinkColumn({ field: "Date", label: "Date", type: "date", visible: false, width: 150 }),
    new SyslinkColumn({ field: "AmountRemaining", label: 'Remaining amount', cellTemplate: "block-cell", width: 120, data: { getBlockLabel: this.saleCreditNoteGridComponent.getAmountRemainingCellLabel.bind(this.saleCreditNoteGridComponent), getBlockClass: this.saleCreditNoteGridComponent.getAmountRemainingCellClass.bind(this.saleCreditNoteGridComponent), getBlockColor: this.saleCreditNoteGridComponent.getAmountRemainingCellColor.bind(this.saleCreditNoteGridComponent) } }),
    new SyslinkColumn({ field: "CurrentStatusLink.StatusId.Sequence", label: 'status order', sortIndex: 1, sortDirection: 'asc', width: 0, visible: false, filterable: false, headerFilterable: false, sortable: false })
  ];
  public SaleContractColumns: SyslinkColumn[] = [
    new SyslinkColumn({ field: "No", label: "GRID.CAPTION.NO", width: 150, sortIndex: 2, sortDirection: 'desc' }),
    new SyslinkColumn({ field: "Subject", label: "Subject" }),
    new SyslinkColumn({ field: "CurrentStatusLink.StatusId.Name", label: "Status", cellTemplate: 'block-cell', data: { getBlockLabel: this.saleContractGridComponent.getStatusCellLabel, getBlockClass: this.saleContractGridComponent.getStatusCellClass, getBlockColor: this.saleContractGridComponent.getStatusCellColor }, width: 120 }),
    new SyslinkColumn({ field: "RRule", label: "Repeat", visible: true, cellTemplate: 'rrule-cell' }),
    new SyslinkColumn({ field: "GenerateDocumentNextDate", label: "GenerateDocumentNextDate", type: 'date', visible: true, width: 110, filterable: false, sortable: false, headerFilterable: false }),
    new SyslinkColumn({ field: "ExternalReference", label: "External reference", visible: true, width: 150 }),
    new SyslinkColumn({ field: "Deadline", label: "SaleContract.Validity", type: 'date', width: 110 }),
    new SyslinkColumn({ field: "ResponsibleUserId.Fullname", label: "Responsible", width: 120 }),
    new SyslinkColumn({ field: "CurrentStatusLink.StatusId.Sequence", label: 'status order', sortIndex: 1, sortDirection: 'asc', width: 0, visible: false })
  ];
  public PurchaseOrderColumns: SyslinkColumn[] = [
    new SyslinkColumn({ field: "No", label: "GRID.CAPTION.NO", width: 150, sortIndex: 2, sortDirection: 'desc' }),
    new SyslinkColumn({ field: "Subject", label: "Subject" }),
    new SyslinkColumn({ field: "CurrentStatusLink.StatusId.Name", label: "Status", cellTemplate: 'block-cell', data: { getBlockLabel: this.purchaseOrderGridComponent.getStatusCellLabel, getBlockClass: this.purchaseOrderGridComponent.getStatusCellClass, getBlockColor: this.purchaseOrderGridComponent.getStatusCellColor }, width: 120 }),
    new SyslinkColumn({ field: "Deadline", label: 'PurchaseOrder.Validity', type: "date" }),
    new SyslinkColumn({ field: "ExternalReference", label: "External reference", visible: true, width: 150 }),
    new SyslinkColumn({ field: "ExTaxTotal", label: "exTaxTotal", type: "number", cellTemplate: 'currency-cell', data: { key: 'TotalPrecision' }, width: 80 }),
    new SyslinkColumn({ field: "InTaxTotal", label: "Total", type: "number", cellTemplate: 'currency-cell', data: { key: 'TotalPrecision' }, width: 80 }),
    new SyslinkColumn({ field: "ResponsibleUserId.Fullname", label: "Responsible", width: 120 }),
    new SyslinkColumn({ field: "Date", label: "Date", type: "date", visible: false, width: 150 }),
    new SyslinkColumn({ field: "AmountRemaining", label: 'Remaining amount', type: "number", visible: false, cellTemplate: 'currency-cell', data: { key: 'TotalPrecision' }, width: 80, filterable: false, headerFilterable: false, sortable: false }),
    new SyslinkColumn({ field: "FormattedStructuredCommunication", label: 'Structured communication', visible: false, editable: false }),
    new SyslinkColumn({ field: "CurrentStatusLink.StatusId.Sequence", label: 'status order', sortIndex: 1, sortDirection: 'asc', width: 0, visible: false, filterable: false, headerFilterable: false, sortable: false })
  ];
  public PurchaseInvoiceColumns: SyslinkColumn[] = [
    new SyslinkColumn({ field: "No", label: "GRID.CAPTION.NO", width: 150, sortIndex: 2, sortDirection: 'desc' }),
    new SyslinkColumn({ field: "Subject", label: "Subject" }),
    new SyslinkColumn({ field: "CurrentStatusLink.StatusId.Name", label: "Status", cellTemplate: 'block-cell', data: { getBlockLabel: this.purchaseInvoiceGridComponent.getStatusCellLabel, getBlockClass: this.purchaseInvoiceGridComponent.getStatusCellClass, getBlockColor: this.purchaseInvoiceGridComponent.getStatusCellColor }, width: 120 }),
    new SyslinkColumn({ field: "Deadline", label: 'PurchaseInvoice.Validity', type: "date", cellTemplate: "template-cell", width: 80, data: { template: "TemplateDeadline", currentDate: new Date(), getAmountRemaining: this.purchaseInvoiceGridComponent.getAmountRemainingCellLabel, getCellOverdue: this.purchaseInvoiceGridComponent.getCellOverdue } }),
    new SyslinkColumn({ field: "ExternalReference", label: "External reference", visible: true, width: 150 }),
    new SyslinkColumn({ field: "ExTaxTotal", label: "exTaxTotal", type: "number", cellTemplate: 'currency-cell', data: { key: 'TotalPrecision' }, width: 80 }),
    new SyslinkColumn({ field: "InTaxTotal", label: "Total", type: "number", cellTemplate: 'currency-cell', data: { key: 'TotalPrecision' }, width: 80 }),
    new SyslinkColumn({ field: "ResponsibleUserId.Fullname", label: "Responsible", width: 120 }),
    new SyslinkColumn({ field: "Date", label: "Date", type: "date", visible: false, width: 150 }),
    new SyslinkColumn({ field: "AmountRemaining", label: 'Remaining amount', type: "number", visible: false, cellTemplate: 'currency-cell', data: { key: 'TotalPrecision' }, width: 80, filterable: false, headerFilterable: false, sortable: false }),
    new SyslinkColumn({ field: "FormattedStructuredCommunication", label: 'Structured communication', visible: false, editable: false }),
    new SyslinkColumn({ field: "CurrentStatusLink.StatusId.Sequence", label: 'status order', sortIndex: 1, sortDirection: 'asc', width: 0, visible: false, filterable: false, headerFilterable: false, sortable: false })
  ];
  public PurchaseCreditNoteColumns: SyslinkColumn[] = [
    new SyslinkColumn({ field: "No", label: "GRID.CAPTION.NO", width: 150, sortIndex: 2, sortDirection: 'desc' }),
    new SyslinkColumn({ field: "Subject", label: "Subject" }),
    new SyslinkColumn({ field: "CurrentStatusLink.StatusId.Name", label: "Status", cellTemplate: 'block-cell', data: { getBlockLabel: this.purchaseCreditNoteGridComponent.getStatusCellLabel, getBlockClass: this.purchaseCreditNoteGridComponent.getStatusCellClass, getBlockColor: this.purchaseCreditNoteGridComponent.getStatusCellColor }, width: 120 }),
    new SyslinkColumn({ field: "Deadline", label: 'PurchaseCreditNote.Validity', type: "date", cellTemplate: "template-cell", width: 80, data: { template: "TemplateDeadline", currentDate: new Date(), getAmountRemaining: this.purchaseCreditNoteGridComponent.getAmountRemainingCellLabel, getCellOverdue: this.purchaseCreditNoteGridComponent.getCellOverdue } }),
    new SyslinkColumn({ field: "ExternalReference", label: "External reference", visible: true, width: 150 }),
    new SyslinkColumn({ field: "ExTaxTotal", label: "exTaxTotal", type: "number", data: { key: 'TotalPrecision' }, cellTemplate: 'currency-cell', width: 80 }),
    new SyslinkColumn({ field: "InTaxTotal", label: "Total", type: "number", data: { key: 'TotalPrecision' }, cellTemplate: 'currency-cell', width: 80 }),
    new SyslinkColumn({ field: "ResponsibleUserId.Fullname", label: "Responsible", width: 120 }),
    new SyslinkColumn({ field: "Date", label: "Date", type: "date", visible: false, width: 150 }),
    new SyslinkColumn({ field: "AmountRemaining", label: 'Remaining amount', type: "number", visible: false, cellTemplate: 'currency-cell', data: { key: 'TotalPrecision' }, width: 80, filterable: false, headerFilterable: false, sortable: false }),
    new SyslinkColumn({ field: "FormattedStructuredCommunication", label: 'Structured communication', visible: false, editable: false }),
    new SyslinkColumn({ field: "CurrentStatusLink.StatusId.Sequence", label: 'status order', sortIndex: 1, sortDirection: 'asc', width: 0, visible: false })
  ];
  public StockMoveIncomingColumns: SyslinkColumn[] = [
    new SyslinkColumn({ field: "No", label: "GRID.CAPTION.NO", width: 150, sortIndex: 2, sortDirection: 'desc' }),
    new SyslinkColumn({ field: "Subject", label: "Subject" }),
    new SyslinkColumn({ field: "CurrentStatusLink.StatusId.Name", label: "Status", cellTemplate: 'block-cell', data: { getBlockLabel: this.stockMoveIncomingGridComponent.getStatusCellLabel, getBlockClass: this.stockMoveIncomingGridComponent.getStatusCellClass, getBlockColor: this.stockMoveIncomingGridComponent.getStatusCellColor }, width: 120 }),
    new SyslinkColumn({ field: "Deadline", label: 'StockMoveIncoming.Validity', type: "date" }),
    new SyslinkColumn({ field: "ExternalReference", label: "External reference", visible: true, width: 150 }),
    new SyslinkColumn({ field: "ResponsibleUserId.Fullname", label: "Responsible", width: 120 }),
    new SyslinkColumn({ field: "Date", label: "Date", type: "date", visible: false, width: 150 }),
    new SyslinkColumn({ field: "CurrentStatusLink.StatusId.Sequence", label: 'status incoming', sortIndex: 1, sortDirection: 'asc', width: 0, visible: false, filterable: false, headerFilterable: false, sortable: false })
  ];
  public StockMoveOutgoingColumns: SyslinkColumn[] = [
    new SyslinkColumn({ field: "No", label: "GRID.CAPTION.NO", width: 150, sortIndex: 2, sortDirection: 'desc' }),
    new SyslinkColumn({ field: "Subject", label: "Subject" }),
    new SyslinkColumn({ field: "CurrentStatusLink.StatusId.Name", label: "Status", cellTemplate: 'block-cell', data: { getBlockLabel: this.stockMoveOutgoingGridComponent.getStatusCellLabel, getBlockClass: this.stockMoveOutgoingGridComponent.getStatusCellClass, getBlockColor: this.stockMoveOutgoingGridComponent.getStatusCellColor }, width: 120 }),
    new SyslinkColumn({ field: "Deadline", label: 'StockMoveOutgoing.Validity', type: "date" }),
    new SyslinkColumn({ field: "ExternalReference", label: "External reference", visible: true, width: 150 }),
    new SyslinkColumn({ field: "ResponsibleUserId.Fullname", label: "Responsible", width: 120 }),
    new SyslinkColumn({ field: "Date", label: "Date", type: "date", visible: false, width: 150 }),
    new SyslinkColumn({ field: "CurrentStatusLink.StatusId.Sequence", label: 'status outgoing', sortIndex: 1, sortDirection: 'asc', width: 0, visible: false, filterable: false, headerFilterable: false, sortable: false })
  ];
  public WorktimeColumns = [
    new SyslinkColumn({ field: "TypeId.Name", label: "Type", width: 100 }),
    new SyslinkColumn({ field: "StartDate", name: 'StartDate-date', label: "Start date", type: "date", cellTemplate: "date-cell", width: 80 }),
    new SyslinkColumn({ field: "StartDate", name: 'StartDate-hours', label: "Start hours", type: "datetime", cellTemplate: 'time-cell', width: 80 }),
    new SyslinkColumn({ field: "EndDate", name: "EndDate-date", label: "End date", type: "date", cellTemplate: "date-cell", width: 100 }),
    new SyslinkColumn({ field: "EndDate", name: "EndDate-hours", label: "End hours", type: "datetime", cellTemplate: 'time-cell', width: 80 }),
    new SyslinkColumn({ field: "Duration", label: "Duration", type: "number", cellTemplate: 'duration-cell', width: 60 }),
    new SyslinkColumn({ field: "Description", label: "Description", cellTemplate: 'html-cell' }),
    new SyslinkColumn({ field: "UserId.ThirdId.Fullname", label: "User", width: 100 }),
    new SyslinkColumn({ field: "IsBillable", label: "Billable", width: 100, cellTemplate: 'boolean-cell', data: new BooleanCellData({ type: 'box', getColor: this.workTimeGrid?.getIsBillableColor }) }),
    new SyslinkColumn({ field: "IsBilled", label: "Billed", width: 100, cellTemplate: 'boolean-cell', data: new BooleanCellData({ type: 'box', getColor: this.workTimeGrid?.getIsBilledColor, getText: this.workTimeGrid?.getIsBilledText }) }),
    new SyslinkColumn({ field: "TaskId.FormattedName", label: "Task", visible: false }),
  ];

  public TaskColumns: SyslinkColumn[] = [
    new SyslinkColumn({ field: "No", label: 'GRID.CAPTION.NO', sortIndex: 1, sortDirection: 'desc', width: 120 }),
    new SyslinkColumn({ field: "Subject", label: "Subject" }),
    new SyslinkColumn({ field: "ThirdId.Fullname", label: "Third", width: 150 }),
    new SyslinkColumn({ field: "CurrentStatusLink.StatusId.Name", label: "Status", cellTemplate: 'block-cell', data: { getBlockLabel: this.taskGridComponent.getStatusCellLabel, getBlockClass: this.taskGridComponent.getStatusCellClass, getBlockColor: this.taskGridComponent.getStatusCellColor }, width: 120 }),
    new SyslinkColumn({ field: "TaskParticipants", label: "Users", type: 'badge', width: 100, cellTemplate: 'badge-list-cell', filterable: true, headerFilterable: true, sortable: false, data: new BadgeListCellData({ contentKey: 'FormattedName' }) }),
    new SyslinkColumn({ field: "EndDate", label: "Deadline", cellTemplate: 'block-cell', data: { getBlockLabel: this.taskGridComponent.getDeadlineCellLabel, getBlockClass: this.taskGridComponent.getDeadlineCellClass, getBlockColor: this.taskGridComponent.getDeadlineCellColor }, width: 120 }),
    new SyslinkColumn({ field: "BilledTimeState.Name", label: "Billing", cellTemplate: 'block-cell', data: { getBlockLabel: this.taskGridComponent.getBillingCellLabel, getBlockClass: this.taskGridComponent.getBillingCellClass, getBlockColor: this.taskGridComponent.getBillingCellColor } }),
  ];

  public async onValidateModalWorkTimeButtonClicked(e: WorkTime) {
    await this.workTimesService.createOrUpdate(e);
    this.workTimeModal?.close();
  }
  public async onValidateModalTaskButtonClicked(e: Task) {
    await this.tasksService.createOrUpdate(e);
    this.taskModal?.close();
  }
}
