<div class="block {{ bordered ? 'border border-' + borderColor : '' }}">
  <syslink-title
    [text]="title"
    [shouldTranslate]="translateTitle"
    [size]="6"
    [bgColor]="titleBgColor"
    [textColor]="titleTextColor"
    [padding]="titlePadding"
  ></syslink-title>

  <dx-scroll-view
    [useNative]="true"
    class="{{ 'bg-' + contentBgColor }}
  {{ 'text-' + contentTextColor }}
"
  >
    <div class="block-body p-2">
      <ng-content [select]="['content']"></ng-content>
    </div>
  </dx-scroll-view>
</div>
