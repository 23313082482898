<syslink-block [title]="'Sale orders'">
  <app-sale-order-grid
    #grid
    [permissionKey]="'list'"
    [canUpdate]="false"
    (add)="onAddButtonClicked()"
    [contextMenuItems]="contextMenuItems"
    [items]="items"
    [expand]="expand"
  >
  </app-sale-order-grid>
</syslink-block>

<app-document-create-modal
  #createModal
  title="Create Order"
  [thirdFilter]="customerFilter"
  detailsUrl="/thirds/customers/"
  (validate)="onCreateValidated($event)"
></app-document-create-modal>

<syslink-confirm-modal
  #confirmModal
  (onValidate)="onConfirmModalValidated($event)"
></syslink-confirm-modal>

<app-sale-order-status-select-modal
  #statusModal
  (validate)="onConfirmModalValidated($event)"
></app-sale-order-status-select-modal>

<app-document-copy-modal
  #copyModal
  (validate)="onCopyButtonClicked($event)"
></app-document-copy-modal>