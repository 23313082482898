import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LoadOptions } from 'devextreme/data';
import { Account } from '../../accountings/accounts/account.model';
import { TaxType } from '../../accountings/tax-types/tax-type.model';
import { ConfigurationsService } from '../../base/modules/configurations/configurations.service';
import { TaxRate } from '../../base/tax-rates/tax-rate';
import { ODataService } from '../../core/services/oData.service';
import { ProductCategory } from '../product-categories/product-category.model';
import { Product } from './product.model';
import { BehaviorSubject } from 'rxjs';
import { StockLocation } from '../../stocks/stock-location/stock-location.model';
import { ProductStatisticsDTO } from './product-statistics/product-statistics-dto';
import { SupplierCatalog } from '../supplier-catalogs/supplier-catalog.model';
import { CustomerCatalog } from '../customer-catalogs/customer-catalog.model';
import { UnitsService } from '../../base/units/unit.service';
import { CurrenciesService } from '../../base/currencies/currency.service';

@Injectable({
  providedIn: 'root',
})
export class ProductsService extends ODataService<Product> {
  public url = 'Product';
  public override defaultOptions: LoadOptions = {
    expand: [
      'BrandId',
      'ProductCategoryId.SaleAccountId',
      'ProductCategoryId.PurchaseAccountId',
      'DefaultUnitId',
      'SaleTaxRateId',
      'PurchaseTaxRateId',
      'CurrencyId',
      'FilteredCustomerCatalogs.MarginId',
      'FilteredCustomerCatalogs.DiscountId',
      'FilteredCustomerCatalogs.PenaltyId',
      'FilteredCustomerCatalogs.TaxRateId',
      'FilteredCustomerCatalogs.ProductId',
      'FilteredCustomerCatalogs.ProductCategoryId',
      'FilteredCustomerCatalogs.PriceListId',
      'FilteredCustomerCatalogs.CustomerId.ThirdId',
      'SupplierCatalogs.SupplierId.ThirdId',
      'SupplierCatalogs.CurrencyId',
      'SupplierCatalogs.UnitId',
      'SupplierCatalogs.TaxRateId',
      'SupplierCatalogs.BrandId',
      'SupplierCatalogs.DiscountId',
      'SupplierCatalogs.ProductId',
      'DefaultSupplierCatalogId.UnitId',
      'DefaultSupplierCatalogId.CurrencyId',
      'SaleTaxTypeId',
      'PurchaseTaxTypeId',
      'SaleAccountId',
      'PurchaseAccountId',
      'DefaultStockLocationId'
    ],
  };

  constructor(
    private configurationsService: ConfigurationsService,
    private translateService: TranslateService,
    private unitsService: UnitsService,
    private currenciesService: CurrenciesService
  ) {
    super();
  }

  public async copy(productId: number): Promise<number> {
    return this.apiService.sendRequest('/api/product/copy/' + productId, 'POST');
  }

  public override format(element: Partial<Product>): Product {
    var result: any = {
      ...element,
      BrandId: element.BrandId?.Id,
      CurrencyId: element.CurrencyId?.Id,
      DefaultSupplierCatalogId: element.DefaultSupplierCatalogId?.Id,
      DefaultUnitId: element.DefaultUnitId?.Id,
      ProductCategoryId: element.ProductCategoryId?.Id,
      PurchaseAccountId: element.PurchaseAccountId?.Id,
      PurchaseTaxRateId: element.PurchaseTaxRateId?.Id,
      PurchaseTaxTypeId: element.PurchaseTaxTypeId?.Id,
      SaleAccountId: element.SaleAccountId?.Id,
      SaleTaxRateId: element.SaleTaxRateId?.Id,
      SaleTaxTypeId: element.SaleTaxTypeId?.Id,
      DefaultStockLocationId: element.DefaultStockLocationId?.Id,
    };

    delete result.AllCustomerCatalogs;
    delete result.DefaultCustomerCatalogs;
    delete result.FilteredCustomerCatalogs;
    delete result.ProductCategoryCustomerCatalogs;
    delete result.StockMoveHistorical;
    delete result.SupplierCatalogs;
    delete result.IsFavorite;
    delete result.SalePriceInTax;
    delete result.PurchasePriceInTax;
    delete result.SalePriceWithNetPrice;
    return result;
  }

  public override async getInstance(params?: Partial<Product>): Promise<Product> {
    var sellable = await this.configurationsService.getConfigurationAsBoolean('sellable', 'Products.General', 'Products');
    var unitId = await this.configurationsService.getConfigurationAsNumber('unit', 'Products.General', 'Products');
    var currencyId = await this.configurationsService.getConfigurationAsNumber('currency', 'Products.General', 'Products');
    var product = new Product({
      Name: this.translateService.instant('New product'),
      Purchasable: await this.configurationsService.getConfigurationAsBoolean('purchasable', 'Products.General', 'Products'),
      Sellable: sellable,
      Stockable: await this.configurationsService.getConfigurationAsBoolean('stockable', 'Products.General', 'Products'),
      Blocked: await this.configurationsService.getConfigurationAsBoolean('blocked', 'Products.General', 'Products'),
      DefaultUnitId: unitId != undefined ? await this.unitsService.findByID(unitId) : undefined,
      CurrencyId: currencyId != undefined ? await this.currenciesService.findByID(currencyId) : undefined,
      PurchaseTaxRateId: new TaxRate({ Id: await this.configurationsService.getConfigurationAsNumber('purchaseTaxRate', 'Products.General', 'Products'), }),
      SaleTaxRateId: new TaxRate({ Id: await this.configurationsService.getConfigurationAsNumber('saleTaxRate', 'Products.General', 'Products'), }),
      ProductCategoryId: new ProductCategory({ Id: await this.configurationsService.getConfigurationAsNumber('category', 'Products.General', 'Products'), }),
      SaleTaxTypeId: new TaxType({ Id: await this.configurationsService.getConfigurationAsNumber('saleTaxType', 'Accountings.Products', 'Accountings'), }),
      PurchaseTaxTypeId: new TaxType({ Id: await this.configurationsService.getConfigurationAsNumber('purchaseTaxType', 'Accountings.Products', 'Accountings'), }),
      DefaultStockLocationId: new StockLocation({ Id: await this.configurationsService.getConfigurationAsNumber('defaultStockLocation', 'Products.General', 'Products'), }),
      SaleAccountId: params?.ProductCategoryId != null && params?.ProductCategoryId.SaleAccountId != null ? params?.ProductCategoryId.SaleAccountId : new Account({ Id: await this.configurationsService.getConfigurationAsNumber('saleAccount', 'Accountings.Products', 'Accountings'), }),
      PurchaseAccountId: params?.ProductCategoryId != null && params?.ProductCategoryId.PurchaseAccountId != null ? params?.ProductCategoryId.PurchaseAccountId : new Account({ Id: await this.configurationsService.getConfigurationAsNumber('purchaseAccount', 'Accountings.Products', 'Accountings'), }),
      Stock: 0,
      ForcedSalePrice: 0,
    });

    return product;
  }

  public async getStockAudits(productId: number) {
    return this.apiService.sendRequest(
      '/api/product/' + productId + '/audits',
      'POST',
      {
        Properties: ['Stock'],
      }
    );
  }

  public setSupplierCatalogIsFavorite(productId: number, supplierId: number) {
    return this.apiService.sendRequest(
      '/api/product/' + productId + '/setFavoriteSupplier/' + supplierId,
      'POST'
    );
  }

  // Product status observer
  private _statusSwitchSubscription =
    new BehaviorSubject<ProductDetailStatusTab>('Purchasable');
  public statusSwitchSubscription$ =
    this._statusSwitchSubscription.asObservable();
  public onStatusSwitch(status: ProductDetailStatusTab): void {
    this._statusSwitchSubscription.next(status);
  }

  public getStatisticsForProduct(
    productId: number,
    startYear?: number,
    endYear?: number
  ): Promise<ProductStatisticsDTO[]> {
    let url = `/api/product/${productId}/Statistics?`;
    let queryParam = [
      { name: 'startYear', value: startYear },
      { name: 'endYear', value: endYear },
    ];
    let queryParamString = queryParam
      .filter((param) => param.value)
      .map((param) => `${param.name}=${param.value}`)
      .join('&');

    url += queryParamString;
    return this.apiService.sendRequest(url, 'GET');
  }

  public reloadPrice(element: Product): Product {
    element.DefaultSupplierCatalogId = this.getDefaultSupplierCatalogId(
      element.SupplierCatalogs
    );
    element.Price = this.getDefaultPrice(element);
    element.FilteredCustomerCatalogs = this.getComputeCustomerCatalogPrice(
      element.Price ?? 0,
      element.FilteredCustomerCatalogs
    );
    element.SalePrice = this.getDefaultSalePrice(
      element.FilteredCustomerCatalogs
    );
    return element;
  }

  private getDefaultSupplierCatalogId(
    supplierCatalogs: SupplierCatalog[]
  ): SupplierCatalog | undefined {
    var favoriteIndex = supplierCatalogs.findIndex(
      (s: SupplierCatalog) => s.IsFavorite == true
    );
    if (favoriteIndex >= 0) {
      return supplierCatalogs[favoriteIndex];
    }
    // Utiliser reduce pour trouver l'index de l'élément avec la valeur minimale
    const indexMinValue = supplierCatalogs.reduce(
      (minIndex, current: SupplierCatalog, index, array) => {
        return current.ExTaxNetPrice < array[minIndex].ExTaxNetPrice
          ? index
          : minIndex;
      },
      0
    ); // Initialiser avec l'index du premier élément

    if (indexMinValue >= 0) {
      return supplierCatalogs[indexMinValue];
    }
    return undefined;
  }

  private getDefaultPrice(element: Product): number | undefined {
    let ForcedSalePrice = element.ForcedSalePrice;
    let Purchasable = element.Purchasable;
    let SalePriceWithNetPrice = element.SalePriceWithNetPrice;
    let PurchasePrice = element.DefaultSupplierCatalogId?.ExTaxNetPrice ?? 0;
    let DefaultSupplierCatalogExTaxGrossPrice =
      element.DefaultSupplierCatalogId?.ExTaxGrossPrice ?? 0;

    // Traduction du PersistentAlias
    return ForcedSalePrice != null && ForcedSalePrice !== 0
      ? ForcedSalePrice
      : !Purchasable
        ? 0
        : SalePriceWithNetPrice
          ? PurchasePrice
          : DefaultSupplierCatalogExTaxGrossPrice;
  }

  private getComputeCustomerCatalogPrice(
    price: number,
    customerCatalogs: CustomerCatalog[]
  ): CustomerCatalog[] | [] {
    return customerCatalogs.map((cc: CustomerCatalog) =>
      this.computeExTaxNetPrice(price, cc)
    );
  }

  public computeExTaxNetPrice(
    basePrice: number,
    item: CustomerCatalog
  ): CustomerCatalog {
    var price = basePrice ?? 0;
    if (price < 0) {
      price = 0;
    }
    if (item.MarginId && item.MarginId.IsDiscountFixed) {
      price = price + (item.MarginId.Value ?? 0);
    } else if (item.MarginId && !item.MarginId.IsDiscountFixed) {
      price = price * (1 + (Number(item.MarginId.Value) ?? 0));
    }

    if (item.DiscountId && item.DiscountId.IsDiscountFixed) {
      price = price - (item.DiscountId.Value ?? 0);
    } else if (item.DiscountId && !item.DiscountId.IsDiscountFixed) {
      price = price * (1 - (Number(item.DiscountId.Value) ?? 0));
    }
    item.ExTaxNetPrice = price;
    return item;
  }

  private getDefaultSalePrice(
    customerCatalogs: CustomerCatalog[]
  ): number | undefined {
    return Math.max(
      ...customerCatalogs.map(
        (catalog: CustomerCatalog) => catalog.ExTaxNetPrice ?? 0
      )
    );
  }
}

export enum ProductContexMenuItemActionCode {
  Copy = 'Copy',
  Blocked = 'Blocked',
  Sellable = 'Sellable',
  Purchasable = 'Purchasable',
  Delete = 'Delete'
}


export type ProductDetailStatusTab =
  | 'Purchasable'
  | 'Sellable'
  | 'Stockable'
  | 'Other';
