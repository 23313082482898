import { Component, Input } from '@angular/core';
import { AppInjectorService } from 'projects/libraries/syslink-components/src/lib/services/app-injector.service';

@Component({
  selector: 'app-document-totals',
  templateUrl: './document-totals.component.html',
  styleUrls: ['./document-totals.component.scss']
})
export class DocumentTotalsComponent {
  @Input() public element?: any ;

  @Input() public documentType: String = '';

  public getDiscountLines(){
    if(!this.element.Lines) return '';
    return this.element.Lines.reduce((accumulateur:any, objetCourant:any) => {
      return accumulateur + (((objetCourant.ExTaxGrossPrice ?? 0)- ((objetCourant.ExTaxUnitPrice ?? 0)-((objetCourant.HourlyRate ??0) * (objetCourant.Labor??0))))*(objetCourant.Quantity ?? 0) );
    }, 0);
  }

  public getMarginPercent(e:number){
    return (e.toFixed( AppInjectorService.config.getPrecision( "TotalPrecision"))).toString() + "%";   
  }
}
