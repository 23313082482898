import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ConfirmModalComponent, NotificationsService, PageComponent, SyslinkToolbarAction, SyslinkToolbarActionButton, SyslinkToolbarFileButton, TabData, TabsComponent } from 'projects/libraries/syslink-components/src/public-api';
import { Product } from '../product.model';
import { ProductDetailStatusTab, ProductsService } from '../product.service';
import { ActivatedRoute } from '@angular/router';
import { jsonToOdataFormat } from 'projects/libraries/syslink-components/src/lib/helpers/tools';
import { Subscription } from 'rxjs';
import { SupplierCatalogsService } from '../../supplier-catalogs/supplier-catalog.service';
import { SupplierCatalog } from '../../supplier-catalogs/supplier-catalog.model';
import { CustomerCatalog } from '../../customer-catalogs/customer-catalog.model';
import { CustomerCatalogsService } from '../../customer-catalogs/customer-catalog.service';
import { CustomerCatalogDiscountOperationService } from '../../customer-catalogs/customer-catalog-discount-operation.service';
import { SupplierCatalogDiscountOperationService } from '../../supplier-catalogs/supplier-catalog-discount-operations/supplier-catalog-discount-operation.service';
import { AppInjectorService } from 'projects/libraries/syslink-components/src/lib/services/app-injector.service';

@Component({
  selector: 'app-product-details',
  templateUrl: './product-details.component.html',
  styleUrls: ['./product-details.component.scss']
})
export class ProductDetailsComponent extends PageComponent implements OnInit, OnDestroy, AfterViewInit {
  public hasNewProductLoaded: boolean = false;
  public element: Product = new Product();

  public override toolbarActions: SyslinkToolbarAction[] = [];

  @ViewChild('productTabs') private productTabs: TabsComponent = new TabsComponent;
  @ViewChild('deleteConfirm') deleteConfirm: ConfirmModalComponent = new ConfirmModalComponent;

  private statusSwitchSubscription: Subscription = new Subscription();

  public tabsData: TabData[] = [
    { id: 0, key: "Purchasable", label: "Purchase", loaded: false },
    { id: 1, key: "Sellable", label: "Sale", loaded: false },
    { id: 2, key: "Stockable", label: "Stock", loaded: false },
    { id: 3, key: "Finances", label: "Finances", loaded: false },
    { id: 4, key: "Statistics", label: "Statistics", loaded: false }
  ]
  public onChangeSelectedId(e: number) {
    if (this.tabsData[e].loaded == false) {
      this.tabsData[e].loaded = true;
    }
  }

  constructor(
    private ngxUiLoaderService: NgxUiLoaderService,
    private productsService: ProductsService,
    public override activatedRoute: ActivatedRoute,
    private supplierCatalogsService: SupplierCatalogsService,
    private customerCatalogsService: CustomerCatalogsService,
    private customerCatalogDiscountOperationService: CustomerCatalogDiscountOperationService,
    private supplierCatalogDiscountOperationService: SupplierCatalogDiscountOperationService
  ) {
    super();
  }

  override ngOnDestroy() {
    super.ngOnDestroy();
    this.statusSwitchSubscription.unsubscribe();
  }

  override ngAfterViewInit(): void {
    super.ngAfterViewInit();
    this.updateSeletedTab();
  }

  override async ngOnInit(): Promise<void> {
    super.ngOnInit();
    this.activatedRoute.data.subscribe(async ({ element }) => {
      this.ngxUiLoaderService.start();
      let name: string | null = null;
      if (this.activatedRoute.snapshot.queryParamMap.has('ProductName')) {
        name = this.activatedRoute.snapshot.queryParamMap.get('ProductName')
      };

      this.element = element;
      if (name != null && name != "") {
        this.element.Name = name;
      }
      await this.refresh();

      this.ngxUiLoaderService.stop();
      this.hasNewProductLoaded = true;
    });

    this.statusSwitchSubscription = this.productsService.statusSwitchSubscription$.subscribe(this.updateSeletedTab.bind(this));
  }

  // KeyDown
  // -------
  public override onKeyDownSave() { this.save() }
  // -------------------------------------------------

  public async refresh() {
    this.updateBreadCrumb(this.getFormattedTitle());
    this.initToolbar();
    if (!this.element.Id && this.element.ProductCategoryId != undefined && this.element.ProductCategoryId != undefined && this.element.Sellable === true) {
      this.element.FilteredCustomerCatalogs = await this.customerCatalogsService.load({ filter: ["ProductCategoryId.Id eq null or ProductCategoryId.Id eq " + this.element.ProductCategoryId.Id], expand: ['CustomerId', 'ProductCategoryId', 'PriceListId', 'TaxRateId', 'DiscountId', 'MarginId', 'PenaltyId'] });
    }
  }

  private async reloadElement() {
    if (!this.element.Id) return;
    var option: any = this.productsService.defaultOptions;
    this.element = await this.productsService.findByID(this.element.Id, option);
    await this.refreshStocks();
  }

  private initToolbar() {
    this.toolbarActions = [];

    this.toolbarActions.push(new SyslinkToolbarActionButton({ icon: 'save', text: 'Save', onClick: () => this.save(), location: 'before', inMenu: 'never', hotkey: 'control.s', visible: this.element.Id ? this.authService.hasPermission(this.basePermissionKey + '.update') : this.authService.hasPermission(this.newBasePermissionKey + '.add') }));
    if (this.element.Id) {
      this.toolbarActions.push(new SyslinkToolbarFileButton({ entityType: 'Product', entityId: this.element.Id }));
      this.toolbarActions.push(new SyslinkToolbarActionButton({ code: 'delete', icon: 'trash', text: 'Delete', onClick: () => this.delete(), visible: this.authService.hasPermission(this.basePermissionKey + '.delete') }));
      this.toolbarActions.push(new SyslinkToolbarActionButton({ code: 'copy', icon: 'copy', text: 'Duplicate', onClick: () => this.copy(), visible: this.authService.hasPermission(this.basePermissionKey + '.copy') }));
    }
  }

  private updateSeletedTab(first: ProductDetailStatusTab = 'Purchasable'): void {
    const order: Array<ProductDetailStatusTab> = [first, 'Purchasable', 'Sellable', 'Stockable'];
    let selectedTab: ProductDetailStatusTab = 'Other';

    for (const status of order) {
      if (this.element[status as 'Purchasable' | 'Sellable' | 'Stockable']) {
        selectedTab = status;
        break;
      }
    }

    this.productTabs.selectTab(selectedTab);
  }

  private async copy() {
    if (!this.element.Id) return;
    this.ngxUiLoaderService.start();
    let newProductId = await this.productsService.copy(this.element.Id);
    this.ngxUiLoaderService.stop();
    this.router.navigateByUrl("/products/products/" + newProductId);
  }

  private async refreshStocks() {
    if (!this.element.Id) return;
    this.element.StockMoveHistorical = (await this.productsService.getStockAudits(this.element.Id)).map((e: any) => jsonToOdataFormat(e));
  }

  // Create/Update
  // -------------
  public async save() {
    if ((!this.element.Id && !this.authService.hasPermission(this.basePermissionKey + '.add')) || (this.element.Id && !this.authService.hasPermission(this.basePermissionKey + '.update'))) {
      NotificationsService.sendErrorMessage("You do not have the required permission!");
      return;
    }

    if (this.element.Purchasable && this.element.SupplierCatalogs.length == 0 && this.element.Id) {
      NotificationsService.sendErrorMessage("Purchasable product must have at least one line in his supplier catalog");
      return;
    }

    this.ngxUiLoaderService.start();
    var supplierCatalogs = [...this.element.SupplierCatalogs];
    var customerCatalogs = [...this.element.FilteredCustomerCatalogs];
    var isNew = false;
    if (!this.element.Id) {
      this.element = <Product>(await this.productsService.insert(this.productsService.format(this.element)));
      isNew = true;
      if (!this.element) {
        isNew = true;
        NotificationsService.sendErrorMessage("Unable to create record");
        this.ngxUiLoaderService.stop();
        return;
      }
      NotificationsService.sendSuccess("Record created");
    } else {
      this.element = await this.productsService.update(this.element.Id, this.productsService.format(this.element));
      if (!this.element) {
        NotificationsService.sendErrorMessage("Unable to update record");
        this.ngxUiLoaderService.stop();
        return;
      }
      this.ngxUiLoaderService.stop();
      NotificationsService.sendSuccess("Record updated");
    }


    // Save Supplier Catalog
    // ---------------------
    await this.saveSupplierCatalog(supplierCatalogs);

    // Save Customer Catalog
    // ---------------------
    await this.saveCustomerCatalog(customerCatalogs);

    if (isNew == true) {
      AppInjectorService.config.setModificationGuard(false);
      this.goToUrl('../' + this.element.Id);
    }

    await this.reloadElement();
    // TODO Check better method
    setTimeout(() => {
      AppInjectorService.config.setModificationGuard(false);
    }, 100);
  }

  private async saveSupplierCatalog(supplierCatalogs: SupplierCatalog[]) {
    if (!this.element.Id || !supplierCatalogs) return;

    // Get existing element
    // --------------------
    var existingElement = await this.supplierCatalogsService.load({ filter: ["ProductId.Id eq " + this.element.Id], expand: ['UnitId', 'DiscountId', 'SupplierId', 'TaxRateId', 'BrandId', 'CurrencyId'] });

    // Get element to Add
    // ------------------
    var elementToAdd = supplierCatalogs.filter((s: SupplierCatalog) => { return s.Id != undefined && s.Id <= 0 });
    for (const s of elementToAdd) {
      s.ProductId = this.element;
      await this.supplierCatalogsService.insertSupplierCatalog(s);
    };

    // Get element to Update
    // ---------------------
    const clesAComparer = ['ExTaxNetPrice', 'UnitId', 'DiscountId', 'SupplierId', 'TaxRateId', 'SupplierReference', 'SupplierStock', 'MinimumQty', 'IsFavorite', 'BrandId', 'Make'];
    var elementToUpdate = supplierCatalogs.filter((obj1: any) => {
      const obj2: any = existingElement.find((obj2: any) => obj2.Id === obj1.Id);
      return clesAComparer.some((cle: any) => {
        if (obj2 == undefined) return true;
        else if ((obj1[cle] !== undefined && obj2[cle] === undefined) || (obj1[cle] === undefined && obj2[cle] !== undefined)) return true;
        else if (typeof obj1[cle] == 'object' && typeof obj2[cle] == 'object' && ((obj1[cle] === undefined && obj2[cle] === undefined) || (obj1[cle] === null && obj2[cle] === null))) return false;
        else if ((typeof obj1[cle] != 'object' && typeof obj2[cle] == 'object') || (typeof obj1[cle] == 'object' && typeof obj2[cle] != 'object')) return true;
        else if (typeof obj1[cle] == 'object' && typeof obj2[cle] == 'object' && (cle == "DiscountId") && (obj1[cle].Value != obj2[cle].Value || obj1[cle].IsDiscountFixed != obj2[cle].IsDiscountFixed)) return true;
        else if (typeof obj1[cle] == 'object' && typeof obj2[cle] == 'object' && obj1[cle].Id !== obj2[cle].Id) return true;
        else if (typeof obj1[cle] == 'object' && typeof obj2[cle] == 'object' && obj1[cle].Id === obj2[cle].Id) return false;
        else if (obj1[cle] !== obj2[cle]) return true;
        else return false;
      })
    });
    for (const s of elementToUpdate) {
      if (s.Id) {
        await this.supplierCatalogsService.update(s.Id, this.supplierCatalogsService.format(s));
      }
      if (s.DiscountId && s.DiscountId.Id) {
        await this.supplierCatalogDiscountOperationService.update(s.DiscountId.Id, s.DiscountId);
      }
    };

    // Get element to Delete
    // ---------------------
    var elementToDelete = existingElement.filter((obj1: SupplierCatalog) =>
      !supplierCatalogs.some((obj2: SupplierCatalog) => obj2.Id === obj1.Id)
    );
    for (const s of elementToDelete) {
      if (s.Id) {
        await this.supplierCatalogsService.remove(s.Id);
      }
    };
  }

  private async saveCustomerCatalog(customerCatalogs: CustomerCatalog[]) {
    if (!this.element.Id || !customerCatalogs) return;

    var filteredCustomerCatalog = customerCatalogs.filter((c: CustomerCatalog) => { return c.ProductId != undefined });

    // Get existing element
    // --------------------
    var existingElement = await this.customerCatalogsService.load({ filter: ["ProductId.Id eq " + this.element.Id], expand: ['CustomerId', 'ProductCategoryId', 'PriceListId', 'TaxRateId', 'DiscountId', 'MarginId', 'PenaltyId'] });

    // Get element to Add
    // ------------------
    var elementToAdd = filteredCustomerCatalog.filter((s: CustomerCatalog) => { return s.Id != undefined && s.Id <= 0 });
    for (const s of elementToAdd) {
      s.ProductId = this.element;
      await this.customerCatalogsService.insertCustomerCatalog(s);
    };

    // Get element to Update
    // ---------------------
    const clesAComparer = ['CustomerId', 'ProductCategoryId', 'PriceListId', 'TaxRateId', 'DiscountId', 'MarginId', 'PenaltyId'];
    var elementToUpdate = filteredCustomerCatalog.filter((obj1: any) => {
      const obj2: any = existingElement.find((obj2: any) => obj2.Id === obj1.Id);
      return clesAComparer.some((cle: any) => {
        if (obj2 == undefined) return false;
        else if (obj1.Id < 0 || obj2.Id < 0) return false;
        else if ((obj1[cle] !== undefined && obj2[cle] === undefined) || (obj1[cle] === undefined && obj2[cle] !== undefined)) return true;
        else if (typeof obj1[cle] == 'object' && typeof obj2[cle] == 'object' && ((obj1[cle] === undefined && obj2[cle] === undefined) || (obj1[cle] === null && obj2[cle] === null))) return false;
        else if ((typeof obj1[cle] != 'object' && typeof obj2[cle] == 'object') || (typeof obj1[cle] == 'object' && typeof obj2[cle] != 'object')) return true;
        else if (typeof obj1[cle] == 'object' && typeof obj2[cle] == 'object' && (cle == "DiscountId" || cle == "MarginId" || cle == "PenaltyId") && (obj1[cle].Value != obj2[cle].Value || obj1[cle].IsDiscountFixed != obj2[cle].IsDiscountFixed)) return true;
        else if (typeof obj1[cle] == 'object' && typeof obj2[cle] == 'object' && obj1[cle].Id !== obj2[cle].Id) return true;
        else if (typeof obj1[cle] == 'object' && typeof obj2[cle] == 'object' && obj1[cle].Id === obj2[cle].Id) return false;
        else if (obj1[cle] !== obj2[cle]) return true;
        else return false;
      })
    });
    for (const s of elementToUpdate) {
      if (s.Id) {
        await this.customerCatalogsService.update(s.Id, this.customerCatalogsService.format(s));
      }
      if (s.DiscountId && s.DiscountId.Id) {
        await this.customerCatalogDiscountOperationService.update(s.DiscountId.Id, s.DiscountId);
      }
      if (s.MarginId && s.MarginId.Id) {
        await this.customerCatalogDiscountOperationService.update(s.MarginId.Id, s.MarginId);
      }
    };

    // Get element to Delete
    // ---------------------
    var elementToDelete = existingElement.filter((obj1: CustomerCatalog) =>
      !filteredCustomerCatalog.some((obj2: CustomerCatalog) => obj2.Id === obj1.Id)
    );
    for (const s of elementToDelete) {
      if (s.Id) {
        await this.customerCatalogsService.remove(s.Id);
      }
    };
  }

  // Delete
  // ------
  public delete() {
    this.deleteConfirm.open();
  }

  public async productDelete() {
    if (!this.element?.Id) return

    await this.productsService.remove(this.element.Id);
    NotificationsService.sendInfo('Record deleted');
    this.goToUrl('../');
  }

  public getFormattedTitle(): string {
    let formattedTitle = '';
    formattedTitle += this.element.No ? this.element.No + ' - ' : '';

    formattedTitle += this.element.Name;
    return formattedTitle;
  }
}
