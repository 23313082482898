import { Component, OnInit, ViewChild } from '@angular/core';
import { BankReconciliation } from '../bank-reconciliation.model';
import { ConfirmModalComponent, NotificationsService, PageComponent, SyslinkToolbarActionButton } from 'projects/libraries/syslink-components/src/public-api';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { AppInjectorService } from 'projects/libraries/syslink-components/src/lib/services/app-injector.service';
import { BankReconciliationsService } from '../bank-reconciliation.service';
import { ActivatedRoute } from '@angular/router';
import { BankReconciliationStatus } from '../bank-reconciliation-status/bank-reconciliation-status.model';
import { DocumentStatusChangedEvent, DocumentStatusesComponent } from '../../../base/documents/document-statuses/document-statuses.component';
import { BankReconciliationStatusService } from '../bank-reconciliation-status/bank-reconciliation-status.service';
import { BankReconciliationHeaderStatusService } from '../bank-reconciliation-header-status/bank-reconciliation-header-status.service';
import { BankReconciliationHeaderStatus } from '../bank-reconciliation-header-status/bank-reconciliation-header-status.model';
import { Entity } from '../../../core/models/entity';
import { ODataService } from '../../../core/services/oData.service';
import { BankReconciliationLine } from '../../bank-reconciliation-lines/bank-reconciliation-line.model';
import { BankReconciliationLinesService } from '../../bank-reconciliation-lines/bank-reconciliation-line.service';
import { PaymentsService } from '../../../base/payments/payments.service';

@Component({
  selector: 'app-bank-reconciliation-details',
  templateUrl: './bank-reconciliation-details.component.html',
  styleUrls: ['./bank-reconciliation-details.component.scss']
})
export class BankReconciliationDetailsComponent extends PageComponent implements OnInit {

  public element: BankReconciliation = new BankReconciliation();

  public availableStatuses: BankReconciliationStatus[] = [];

  @ViewChild('deleteConfirm') deleteConfirm: ConfirmModalComponent = new ConfirmModalComponent;
  @ViewChild('documentStatuses') documentStatuses: DocumentStatusesComponent = new DocumentStatusesComponent();

  constructor(
    private ngxUiLoaderService: NgxUiLoaderService,
    private bankReconciliationsService: BankReconciliationsService,
    private bankReconciliationStatusService: BankReconciliationStatusService,
      private bankReconciliationHeaderStatusService: BankReconciliationHeaderStatusService,
    public override activatedRoute: ActivatedRoute,
    private bankReconciliationLinesService: BankReconciliationLinesService,
    private paymentsService: PaymentsService
  ) {
    super();
  }

  override async ngOnInit(): Promise<void> {
    super.ngOnInit();
    this.activatedRoute.data.subscribe(async ({ element }) => {
      this.ngxUiLoaderService.start();

      this.element = element;

      if (!this.element || !this.element.Id) {
        this.element = await this.bankReconciliationsService.getInstance(this.element);
      }

      await this.refresh();

      this.ngxUiLoaderService.stop();
    });

  }
  public async refresh() {
    this.updateBreadCrumb(this.getFormattedTitle());
    this.initToolbar();
    this.initStatusBar();
  }


  private initToolbar() {
    this.toolbarActions = [];

    this.toolbarActions.push(new SyslinkToolbarActionButton({ icon: 'save', text: 'Save', onClick: () => this.save(), location: 'before', inMenu: 'never', hotkey: 'control.s', visible: this.authService.hasPermission(this.newBasePermissionKey + '.update') }));
    if (this.element.Id) {
      this.toolbarActions.push(new SyslinkToolbarActionButton({ code: 'delete', icon: 'trash', text: 'Delete', onClick: () => this.delete(), visible: this.authService.hasPermission(this.newBasePermissionKey + '.delete') }));
    }
  }

  public canEditDocument(): boolean {
    return !this.hasActiveStatus('Move.Closed');
  }

  public hasActiveStatus(statusCode: string): boolean {
    if (!this.element || !this.element.Statuses) return false;
    return this.element.Statuses?.find((status: BankReconciliationHeaderStatus) => status.StatusId?.Code === statusCode) != undefined;
  }

  // Document Statuses
  // -----------------
  public initStatusBar() {
    this.bankReconciliationStatusService.load().then((statuses: any[]) => {
      this.documentStatuses.statuses = statuses;
      this.documentStatuses.documentStatuses = this.element.Statuses || [];
      this.documentStatuses.refreshItems();
    });
  }

  public async onDocumentStatusChanged(event: DocumentStatusChangedEvent) {
      if (!this.authService.hasPermission(this.newBasePermissionKey + '.ChangeStatus')) {
        NotificationsService.sendErrorMessage("You do not have the required permission!");
        return;
      }

      if (!event.status || !event.status.Sequence || !this.element || !this.element.CurrentStatusLink || !this.element.CurrentStatusLink.StatusId) {
        NotificationsService.sendErrorMessage("Status error");
        return;
      }

      if (event.status.Sequence <= (this.element.CurrentStatusLink.StatusId.Sequence ?? 5)) {
        NotificationsService.sendErrorMessage("This status is impossible");
        return;
      }

      if (!this.bankReconciliationsService.canSave(this.element)) {
        this.initStatusBar();
        return;
      }
      var statuses = await this.bankReconciliationStatusService.load();
      var status = statuses.find((s: any) => s.Code == event.status.Code);
      var newStatus: any = await this.bankReconciliationHeaderStatusService.getInstance();

      newStatus.StatusId = status;
      try {
        this.element.CurrentStatusLink = newStatus;
        this.element.Statuses?.push(newStatus);
        this.documentStatuses.refreshItems();

        await this.save();

        if (this.element.Id) {
          const element = await this.bankReconciliationsService?.findByID(this.element.Id, { select: ['No'], expand: [] });
          this.element.No = element?.No;
          this.refresh();
        }
      } catch (error) {
        this.element.Statuses = this.element.Statuses?.filter((s: BankReconciliationHeaderStatus) => { return s.StatusId?.Sequence != newStatus.StatusId?.Sequence });
        this.element.CurrentStatusLink = this.element.Statuses?.find((s: BankReconciliationHeaderStatus) => s.StatusId?.Sequence == ((newStatus.StatusId?.Sequence ?? 3) - 1));
        setTimeout(() => {
          this.documentStatuses.refreshItems()
          this.ngxUiLoaderService.stop();
        }, 50);
      }
  }

  // Create/Update
  // -------------
  public async save(showMessage: boolean = true) {
    if (!this.element.Id) return;
    if ((this.element.Id && !this.authService.hasPermission(this.basePermissionKey + '.update'))) {
      NotificationsService.sendErrorMessage("You do not have the required permission!");
      return;
    }

    if (!this.bankReconciliationsService.canSave(this.element)) {
      this.initStatusBar();
      return;
    }

    this.ngxUiLoaderService.start();
    await this.bankReconciliationsService.update(this.element.Id, await this.bankReconciliationsService.format(this.element));

    await this.saveLines();
    await this.saveStatus();

    if (showMessage == true) {
      NotificationsService.sendSuccess("Record updated");
    }
    // TODO Check better method
    setTimeout(() => {
      this.ngxUiLoaderService.stop();
      AppInjectorService.config.setModificationGuard(false);
    }, 100);
  }

  public async saveStatus() {
    if (!this.element.Statuses) return;
    for (let index = 0; index < this.element.Statuses.length; index++) {
      if (this.element.Statuses[index].Id == undefined) {
        this.element.Statuses[index].BankReconciliationId = await this.bankReconciliationsService.format(this.element);
        var newElement: any = this.element.Statuses[index];
        var newElementStatusId = newElement.StatusId;
        newElement.BankReconciliationId = {Id:newElement.BankReconciliationId.Id};
        this.element.Statuses[index] = await this.bankReconciliationHeaderStatusService.insert(await this.bankReconciliationHeaderStatusService.format(newElement));
        this.element.Statuses[index].StatusId = newElementStatusId;
      }
    }
  }


  public async saveLines(): Promise<void> {
    if (!this.element.BankReconciliationLines) return;
    var filter: any = [];
    filter = ["BankReconciliationId.Id eq " + this.element.Id];
    this.element.BankReconciliationLines.map((d: BankReconciliationLine) => { d.BankReconciliationId && d.BankReconciliationId.Id == this.element.Id });
    this.element.BankReconciliationLines.map((d: BankReconciliationLine) => {
      if (d.Id && typeof d.Id !== 'number') {
        d.Id = undefined;
      }
      d.BankReconciliationId = this.element;
    });
    var existingElement = await this.bankReconciliationLinesService.load({ filter: filter, expand: this.getDocumentLineExpand() });

    await this.createOrUpdateArray(this.bankReconciliationLinesService, this.element.BankReconciliationLines, existingElement, ['Debit', 'Credit', 'ThirdId', 'SaleDocumentHeaderId', 'PaymentId', 'StatusId']);

    this.element.BankReconciliationLines = await this.bankReconciliationLinesService.load({ filter: filter, expand: this.getDocumentLineExpand(), sort:["LineNo"] });
  }

  public getDocumentLineExpand(): string[] {
    var result = ['ThirdId', 'SaleDocumentHeaderId','StatusId','PaymentId'];
    return result;
  }

  public async createOrUpdateArray<T extends Entity>(service: ODataService<T>, listElements: any[], existingElement: any[], keyToCompare: string[] = [], discountService?: any): Promise<void> {

    // Get element to Add
    // ------------------
    var elementToAdd = listElements.filter((s: any) => { return s.Id == undefined || (s.Id != undefined && s.Id <= 0) });
    for (const s of elementToAdd) {
      s.Id = undefined;
      // CreatePayment
      if(s.PaymentId && !s.PaymentId.Id){
        var payment = await this.paymentsService.insert(this.paymentsService.format(s.PaymentId));
        s.PaymentId = payment;
      }
      var temp = await service.insert(service.format(s));
      s.Id = temp.Id;
    };

    // Get element to Update
    // ---------------------
    var elementToUpdate = listElements.filter((obj1: any) => {
      const obj2: any = existingElement.find((obj2: any) => obj2.Id === obj1.Id);
      return keyToCompare.some((cle: any) => {
        if (obj2 == undefined) return true;
        else if ((obj1[cle] !== undefined && obj2[cle] === undefined) || (obj1[cle] === undefined && obj2[cle] !== undefined)) return true;
        else if (typeof obj1[cle] == 'object' && typeof obj2[cle] == 'object' && ((obj1[cle] === undefined && obj2[cle] === undefined) || (obj1[cle] === null && obj2[cle] === null))) return false;
        else if ((typeof obj1[cle] != 'object' && typeof obj2[cle] == 'object') || (typeof obj1[cle] == 'object' && typeof obj2[cle] != 'object')) return true;
        else if (typeof obj1[cle] == 'object' && typeof obj2[cle] == 'object' && (cle == "Discount" || cle == "Margin") && (obj1[cle].Value != obj2[cle].Value || obj1[cle].IsDiscountFixed != obj2[cle].IsDiscountFixed)) return true;
        else if (typeof obj1[cle] == 'object' && typeof obj2[cle] == 'object' && ((obj1[cle] == undefined && obj2[cle] != undefined) || (obj2[cle] == undefined && obj1[cle] != undefined) || obj1[cle].Id !== obj2[cle].Id)) return true;
        else if (typeof obj1[cle] == 'object' && typeof obj2[cle] == 'object' && obj1[cle].Id === obj2[cle].Id) return false;
        else if (obj1[cle] !== obj2[cle]) return true;
        else return false;
      })
    });
    for (const s of elementToUpdate) {
       // CreatePayment
       if(s.PaymentId && !s.PaymentId.Id){
        var payment = await this.paymentsService.insert(this.paymentsService.format(s.PaymentId));
        s.PaymentId = payment;
      }

      if (s.Id) {
        await service.update(s.Id, service.format(s))
      }
    };
  }

  // Delete
  // ------
  public delete() {
    this.deleteConfirm.open();
  }

  public async bankReconciliationDelete() {
    if (!this.element?.Id) return

    await this.bankReconciliationsService.remove(this.element.Id);
    NotificationsService.sendInfo('Record deleted');
    this.goToUrl('../');
  }

  public getFormattedTitle(): string {
    let formattedTitle = this.translateService.instant("Bank reconciliation") + " " + (this.element?.No ? this.element.No : '');
    return formattedTitle;
  }
}
