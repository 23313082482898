import { Component } from '@angular/core';
import { PurchaseCreditNote } from '../purchase-credit-notes/purchase-credit-note.model';
import { PurchaseCreditNotesService } from '../purchase-credit-notes/purchase-credit-notes.service';
import { PurchaseCreditNoteLine } from '../purchase-credit-note-lines/purchase-credit-note-line.model';
import { PurchaseCreditNoteLinesService } from '../purchase-credit-note-lines/purchase-credit-note-lines.service';
import { PurchaseCreditNoteHeaderStatus } from '../purchase-credit-note-header-statuses/purchase-credit-note-header-status.model';
import { PurchaseCreditNoteHeaderStatusesService } from '../purchase-credit-note-header-statuses/purchase-credit-note-header-statuses.service';
import { PurchaseCreditNoteHeaderHeaderStatus } from '../purchase-credit-note-header-header-statuses/purchase-credit-note-header-header-status.model';
import { PurchaseCreditNoteHeaderHeaderStatusesService } from '../purchase-credit-note-header-header-statuses/purchase-credit-note-header-header-statuses.service';
import { PurchaseDocumentDetailsComponent } from '../../purchase-documents/purchase-documents/purchase-document-details/purchase-document-details.component';
import { AppInjectorService } from 'projects/libraries/syslink-components/src/lib/services/app-injector.service';

@Component({
  selector: 'app-purchase-credit-note-details',
  templateUrl: '../../purchase-documents/purchase-documents/purchase-document-details/purchase-document-details.component.html',
  styleUrls: ['./purchase-credit-note-details.component.scss']
})
export class PurchaseCreditNoteDetailsComponent extends PurchaseDocumentDetailsComponent<
  PurchaseCreditNote,
  PurchaseCreditNotesService,
  PurchaseCreditNoteLine,
  PurchaseCreditNoteLinesService,
  PurchaseCreditNoteHeaderStatus,
  PurchaseCreditNoteHeaderStatusesService,
  PurchaseCreditNoteHeaderHeaderStatus,
  PurchaseCreditNoteHeaderHeaderStatusesService
>{
  protected override loadDependencies() {
    this.PurchaseDocumentService = AppInjectorService.injector.get(PurchaseCreditNotesService);
    this.PurchaseDocumentLinesService = AppInjectorService.injector.get(PurchaseCreditNoteLinesService);
    this.PurchaseDocumentStatusesService = AppInjectorService.injector.get(PurchaseCreditNoteHeaderStatusesService);
    this.PurchaseDocumentHeaderStatusesService = AppInjectorService.injector.get(PurchaseCreditNoteHeaderHeaderStatusesService);

    this.documentType = 'PurchaseCreditNote';
    this.subModuleCode = 'Purchases.CreditNote';
    this.defaultStatusCode = 'CreditNote.Proforma';
    this.documentLinesColumns = [...this.PurchaseDocumentService.getDefaultDocumentLineColumns(this.subModuleCode,this.documentType)];

  }

  public override canEditDocument(): boolean {
    return !this.hasActiveStatus('CreditNote.Ventilated') && !this.hasActiveStatus('CreditNote.Canceled');
  }
}