<syslink-block [title]="'Sale invoices'">
  <ng-container *ngIf="globalResult">
    <div class="row gap-2">
      <div class="offset-4 col-1 rounded bg-success text-center text-white border border-dark">
        <div class="h-100 d-flex flex-column justify-content-between">
          <p class="h6 fw-bold my-2"> {{'Total amount' | translate}}</p>
          <div>
            <p class="my-1">{{globalResult.ExTaxTotal | syslinkCurrency:"TotalPrecision"}} H.T.V.A</p>
            <p class="my-1">{{globalResult.InTaxTotal | syslinkCurrency:"TotalPrecision"}} T.T.C</p>
          </div>
        </div>
      </div>
      <div class="col-1 rounded text-center border border-secondary">
        <div class="h-100 d-flex flex-column justify-content-between">
          <p class="h6 fw-bold my-2">{{'Open amount' | translate}}</p>
          <div>
            <p class="my-1">&nbsp;</p>
            <p class="my-1">{{globalResult.AmountRemaining | syslinkCurrency:"TotalPrecision"}} T.T.C</p>
          </div>
        </div>
      </div>
      <div class="col-1 rounded bg-danger text-center text-white border border-secondary">
        <div class="h-100 d-flex flex-column justify-content-between">
          <p class="h6 fw-bold my-2">{{'Amount due' | translate}}</p>
          <div>
            <p class="my-1">&nbsp;</p>
            <p class="my-1">{{globalResult.InTaxOverdueAmount | syslinkCurrency:"TotalPrecision"}} T.T.C</p>
          </div>
        </div>
      </div>
      <div class="col-1 rounded bg-gray text-center text-white border border-secondary">
        <div class="h-100 d-flex flex-column justify-content-between">
          <p class="h6 fw-bold my-2">{{'Unvalidated amount' | translate}}</p>
          <div>
            <p class="my-1">{{globalResult.ExTaxUnvalidatedTotalAmount | syslinkCurrency:"TotalPrecision"}} H.T.V.A</p>
            <p class="my-1">{{globalResult.InTaxUnvalidatedTotalAmount | syslinkCurrency:"TotalPrecision"}} T.T.C</p>
          </div>
        </div>
      </div>
    </div>
  </ng-container>

  <app-sale-invoice-grid
    #grid
    [toolbarActions]="toolbarActions"
    [permissionKey]="'list'"
    [canDelete]="false"
    (add)="onAddButtonClicked()"
    [contextMenuItems]="contextMenuItems"
    (onFilterValueChange)="onFilterValueChange($event)"
  ></app-sale-invoice-grid>
</syslink-block>

<app-document-create-modal
  #createModal
  title="Create Invoice"
  [thirdFilter]="customerFilter"
  detailsUrl="/thirds/customers/"
  (validate)="onCreateValidated($event)"
></app-document-create-modal>

<syslink-confirm-modal
  #confirmModal
  (onValidate)="onConfirmModalValidated($event)"
></syslink-confirm-modal>
<app-sale-invoice-status-select-modal
  #statusModal
  (validate)="onConfirmModalValidated($event)"
></app-sale-invoice-status-select-modal>

<syslink-modal
  *ngIf="paymentDocument && paymentDocument.Payments"
  #editPaymentModal
  title="payment"
  (onValidate)="onCreatePayment(newPayment)"
  validateText="Create"
  cancelText="Cancel"
>
  <app-payment-details
    *ngIf="newPayment"
    #paymentModal
    [(payment)]="newPayment"
    [documentType]="'SaleInvoice'"
    [third]="paymentDocument.ThirdId"
    [amountRemaining]="paymentDocument.AmountRemaining"
    [documentNameFormatted]="paymentDocument.NameFormatted"
    [refundablePayments]="refundablePayments"
  ></app-payment-details>
</syslink-modal>


<syslink-modal
  #globalReportModal
  title="Select year"
  width="350px"
  (onValidate)="previewSaleGlobalReportPreview()"
>
  <syslink-input-number
    [max]="globalReportModal.data.maxDate"
    [min]="2023"
    label="Year"
    [(model)]="globalReportModal.data.date"
  ></syslink-input-number>
  <syslink-switch
    label="byMonth"
    [(model)]="globalReportModal.data.byMonth"
  ></syslink-switch>
</syslink-modal>

<app-document-copy-modal
  #copyModal
  (validate)="onCopyButtonClicked($event)"
></app-document-copy-modal>