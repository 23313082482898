import { Component, Input, ViewChild } from '@angular/core';
import { BaseModalComponent } from 'projects/libraries/syslink-components/src/lib/modal/base-modal/base-modal.component';
import { FilterDescriptor } from 'devextreme/data';
import { SelectComponent } from 'projects/libraries/syslink-components/src/public-api';
import { SaleQuoteHeaderStatus } from '../sale-quote-header-statuses/sale-quote-header-status.model';
import { SaleQuoteHeaderStatusesService } from '../sale-quote-header-statuses/sale-quote-header-statuses.service';

@Component({
  selector: 'app-sale-quote-status-select-modal',
  templateUrl: './sale-quote-status-select-modal.component.html',
  styleUrls: ['./sale-quote-status-select-modal.component.scss']
})
export class SaleQuoteStatusSelectModalComponent extends BaseModalComponent<SaleQuoteHeaderStatus>{
  @Input() public selectFilters: FilterDescriptor | Array<FilterDescriptor>;
  @Input() public expand: FilterDescriptor | Array<FilterDescriptor>;
  @Input() public title: string = "Select quote status";
  @Input() public content?: string;
  @Input() public data: any;
  @ViewChild('select') select?: SelectComponent;

  constructor(public saleQuoteHeaderStatusesService: SaleQuoteHeaderStatusesService) {
    super();
  }

}