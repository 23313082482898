import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SupplierCatalog } from '../../../../supplier-catalogs/supplier-catalog.model';
import { SuppliersService } from 'projects/erp-app/src/app/thirds/suppliers/suppliers.service';
import { TaxRatesService } from 'projects/erp-app/src/app/base/tax-rates/tax-rates.service';
import { BrandsService } from '../../../../brands/brand.service';
import { UnitsService } from 'projects/erp-app/src/app/base/units/unit.service';
import { Supplier } from 'projects/erp-app/src/app/thirds/suppliers/supplier.model';
import { Brand } from '../../../../brands/brand.model';

@Component({
  selector: 'app-product-supplier-catalog-form',
  templateUrl: './product-supplier-catalog-form.component.html',
  styleUrls: ['./product-supplier-catalog-form.component.scss']
})
export class ProductSupplierCatalogFormComponent {
  @Input() element: SupplierCatalog = new SupplierCatalog;
  @Output() elementChange: EventEmitter<SupplierCatalog> = new EventEmitter<SupplierCatalog>();
  @Input() public readOnly: boolean = false;

  constructor(
    public taxRatesService: TaxRatesService,
    public brandsService: BrandsService,
    public unitsService: UnitsService,
    public suppliersService: SuppliersService
  ) { }

  public onChangeDiscountValue(e: any) {
    if (!this.element.DiscountId) return;
    this.element.DiscountId.Value = this.element.DiscountId.IsDiscountFixed ? e : Number((e / 100));
  }

  public onChangeDiscountFixed(e: any) {
    if (!this.element.DiscountId) return;
    if (e == 0)
      this.element.DiscountId.IsDiscountFixed = false;
    else
      this.element.DiscountId.IsDiscountFixed = true;
  }

  public onChangeSupplier(e:Partial<Supplier>){
    this.element.TaxRateId = e.PurchaseTaxRateId;
  }
  
  public async customNewBrandValue(customeValue: string) {
    if (customeValue == '') return;
    this.element.BrandId = await this.brandsService.insert(new Brand({ Code: customeValue, Name: customeValue }));
  }
}