import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Product } from '../product.model';

@Component({
  selector: 'app-product-purchase-form',
  templateUrl: './product-purchase-form.component.html',
  styleUrls: ['./product-purchase-form.component.scss']
})
export class ProductPurchaseFormComponent{
  @Input() public element: Product = new Product();
  @Output() public elementChange: EventEmitter<Product> = new EventEmitter<Product>();

}
