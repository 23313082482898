import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { DXCell } from 'projects/libraries/syslink-components/src/lib/grid/cells/dxcell';
import { BadgeListCellData, ColoredDateCellData } from 'projects/libraries/syslink-components/src/public-api';
import { WorkTimeStateCode } from '../../../works/work-time-states/work-time-state';
import { Task, TaskTimeStateCode } from '../task.model';
import { TasksService } from '../tasks.service';
import { SyslinkColumn } from 'projects/libraries/syslink-components/src/lib/helpers/SyslinkColumn';
import { BooleanCellData } from 'projects/libraries/syslink-components/src/lib/grid/cells/boolean-cell/boolean-cell.component';
import { BaseGridComponent } from 'projects/libraries/syslink-components/src/lib/grid/base-grid/base-grid.component';
import { ContextMenuItemAction } from 'projects/libraries/syslink-components/src/lib/context-menus/context-menu-item-action';
import { WorkTimesService } from '../../../work-times/work-times/work-times.service';
import { SelectDescriptor } from 'devextreme/data';

@Component({
  selector: 'app-task-grid',
  templateUrl: './task-grid.component.html',
  styleUrls: ['./task-grid.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TaskGridComponent extends BaseGridComponent<Task> {

  public override canDelete: boolean = true;
  @Input() public override storageKey: string = 'tasks-grid';
  @Input() public override detailsUrl: string = '/time-management/tasks';
  @Input() public columns: SyslinkColumn[] = [
    new SyslinkColumn({ field: "No", label: 'GRID.CAPTION.NO', sortIndex: 1, sortDirection: 'desc', width: 120 }),
    new SyslinkColumn({ field: "Subject", label: "Subject" }),
    new SyslinkColumn({ field: "ThirdId.Fullname", label: "Third", width: 150 }),
    new SyslinkColumn({ field: "CurrentStatusLink.StatusId.Name", label: "Status", cellTemplate: 'block-cell', data: { getBlockLabel: this.getStatusCellLabel, getBlockClass: this.getStatusCellClass, getBlockColor: this.getStatusCellColor }, width: 120 }),
    new SyslinkColumn({ field: "TaskParticipants", label: "Users", type: 'badge', width: 100, cellTemplate: 'badge-list-cell', filterable: true, headerFilterable: true, sortable: false, data: new BadgeListCellData({ contentKey: 'FormattedName' }) }),
    new SyslinkColumn({ field: "EndDate", label: "Deadline", cellTemplate: 'block-cell', data: { getBlockLabel: this.getDeadlineCellLabel, getBlockClass: this.getDeadlineCellClass, getBlockColor: this.getDeadlineCellColor }, width: 120 }),
    new SyslinkColumn({ field: "BilledTimeState.Name", label: "Billing", cellTemplate: 'block-cell', data: { getBlockLabel: this.getBillingCellLabel, getBlockClass: this.getBillingCellClass, getBlockColor: this.getBillingCellColor }, filterable: false, headerFilterable: false, sortable: false }),
  ];

  @Input() public override expand: string | string[] = ['TaskParticipants.UserId', 'TaskParticipants.UserGroupId', 'CurrentStatusLink.StatusId', 'DeadlineState', 'ThirdId', 'BilledTimeState', 'TaskBillingTypeId'];
  @Input() public override select: SelectDescriptor<any> = ['No', 'Subject', 'EndDate', 'FixedPrice'];

  @Input() public contextMenuItems: ContextMenuItemAction[] = [];

  constructor(
    public tasksService: TasksService,
    public workTimesService: WorkTimesService,
  ) {
    super();
  }

  // Status Cells
  // ------------
  getStatusCellLabel(cell: DXCell) {
    return cell.data.CurrentStatusLink.StatusId.Name;
  }

  getStatusCellClass(cell: DXCell) {
    let statusClass = 'text-white text-center'
    let statusCode: string | undefined = (<string>cell.data.CurrentStatusLink.StatusId.Code).toLowerCase();
    statusCode = statusCode.includes('.') ? statusCode.split('.').pop() : statusCode;
    if (statusCode == undefined) return;
    statusClass += ' ' + statusCode;
    return statusClass;
  }

  getStatusCellColor(cell: DXCell) {
    return cell.data.CurrentStatusLink.StatusId.Color;
  }
  // ---------------------------------

  // billing Cells
  // ------------
  getBillingCellLabel(cell: DXCell) {
    if (cell.data.TaskBillingTypeId.Code == "NotBillable")
      return "";
    return cell.data.BilledTimeState.Name;
  }

  getBillingCellClass(cell: DXCell) {
    let statusClass = 'text-white text-center'
    let statusCode: string | undefined = (<string>cell.data.CurrentStatusLink.StatusId.Code).toLowerCase();
    statusCode = statusCode.includes('.') ? statusCode.split('.').pop() : statusCode;
    if (statusCode == undefined) return;
    statusClass += ' ' + statusCode;
    return statusClass;
  }

  getBillingCellColor(cell: DXCell) {
    if (cell.data.TaskBillingTypeId.Code == "NotBillable")
      return "";
    return cell.data.BilledTimeState.Color;
  }
  // ---------------------------------

  // EndDate Cell
  // -------------
  getDeadlineCellLabel(cell: DXCell) {
    if (!cell || !cell.data || !cell.data.EndDate) return '';
    if ((cell.data.EndDate as Date)?.getFullYear() === 1) return '';
    return (cell.data.EndDate as Date).getDate().toString().padStart(2, '0') + '/' + ((cell.data.EndDate as Date).getMonth() + 1).toString().padStart(2, '0') + '/' + (cell.data.EndDate as Date).getFullYear();
  }

  getDeadlineCellClass(cell: DXCell) {
    return 'text-white text-center';
  }

  getDeadlineCellColor(cell: DXCell) {
    if (!cell || !cell.data || !cell.data.EndDate) return '';
    if ((cell.data.EndDate as Date)?.getFullYear() === 1) return '';
    return cell.data[TaskTimeStateCode.DeadlineState].Color;
  }
  // ---------------------------------
}
