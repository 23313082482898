<div class="{{class}}" [hidden]="hidden">
    <dx-text-area
        id="textarea-{{name}}"
        
        [value]="model"
        (onValueChanged)="onModelChange($event.value)"
        valueChangeEvent="input"

        [stylingMode]="'mode'"
        [label]="getLabel()"
        [labelMode]="labelMode"
        [placeholder]="labelMode === 'hidden' ? getLabel() : ''"
        [readOnly]="disabled"
        [height]="height"
    ></dx-text-area>
</div>