import { Entity } from "../../../core/models/entity";
import { Product } from "../../../products/products/product.model";
import { TaxRate } from "../../tax-rates/tax-rate";
import { Unit } from "../../units/unit.model";
import { DocumentLineDiscountOperation } from "../document-line-discount-operations/document-line-discount-operation-model";
import { Document } from "../documents/document.model";

export enum DocumentLineType {
    product = 'product',
    post = 'post',
    text = 'text',
    various = 'various',
    page = 'page'
}

export class DocumentLine extends Entity {
    public HeaderId: Document = new Document();
    public LineNo?: number;
    public ParentLineNo?: number;
    public Reference?: string;
    public LineType?: DocumentLineType;
    public Description?: string;
    public PenaltyValue?: number;
    public IsPenaltyFixed?: boolean;
    public HourlyRate?: number;
    public Quantity?: number;
    public Labor?: number;
    public ExTaxGrossPrice?: number;
    public ExTaxTotalPrice?: number;
    public ForcedPrice?: number;

    public ProductId?: Product;
    public ParentId?: DocumentLine;
    public UnitId?: Unit;
    public TaxRateId?: TaxRate;

    public SelectedLine?: number;
    public ExTaxSaleGrossPrice?: number;
    public ExTaxUnitPrice?: number;

    public SaleDocumentLineDiscountOperations?: DocumentLineDiscountOperation[] = [];
    public PurchaseDocumentLineDiscountOperations?: DocumentLineDiscountOperation[] = [];
    public Margin: DocumentLineDiscountOperation = new DocumentLineDiscountOperation();
    public Discount: DocumentLineDiscountOperation = new DocumentLineDiscountOperation();
    public Children: DocumentLine[] = [];

    constructor(entity?: Partial<DocumentLine>) {
        super(entity);
        Object.assign(this, entity);
    }
}
