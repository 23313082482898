import { Component, Input, OnInit } from '@angular/core';
import { DXCell } from '../dxcell';
import { DatePipe } from '@angular/common';

export type BlockTextCellMapping = {
  code:string,
  classString: string | string[]
}
@Component({
  selector: 'app-block-cell',
  templateUrl: './block-cell.component.html',
  styleUrls: ['./block-cell.component.scss']
})
export class BlockCellComponent{

  @Input() cell: DXCell = new DXCell();
  @Input() getBlockLabel: Function = (cell?: DXCell) => { return cell?.value };
  @Input() getBlockText?: Function = (cell?: DXCell) => { return cell?.value };
  @Input() getBlockClass: Function = (cell?: DXCell) => { return cell?.value };
  @Input() getBlockColor: Function = (cell?: DXCell) => { return cell?.value };

  constructor(public datePipe: DatePipe){}

}
