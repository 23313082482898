import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { DxSchedulerComponent } from 'devextreme-angular';
import { AppointmentTooltipShowingEvent, AppointmentClickEvent, AppointmentFormOpeningEvent, Properties, AppointmentContextMenuEvent, CellContextMenuEvent } from 'devextreme/ui/scheduler';
import { SyslinkToolbarAction, SyslinkToolbarActionButton } from '../toolbar/toolbar.component';
import { CrudComponent } from '../helpers/crud-component/crud-component.component';
import { ModalComponent } from '../modal/modal.component';
import { ODataService } from 'projects/erp-app/src/app/core/services/oData.service';
import { SyslinkDataSource } from '../helpers/SyslinkDataSource';
import { SyslinkDataSourceOptions } from '../helpers/SyslinkDataSourceOptions';
import { Appointment } from '../../../../../erp-app/src/app/time-management/appointments/appointments/appointment';
import { fromDotNotation } from '../helpers/tools';
import { ContextMenuItemAction } from '../context-menus/context-menu-item-action';
import { TranslateService } from '@ngx-translate/core';

export type ViewType = 'day' | 'week' | 'workWeek' | 'month' | 'timelineDay' | 'timelineWeek' | 'timelineWorkWeek' | 'timelineMonth' | 'agenda';

@Component({
  selector: 'syslink-scheduler',
  templateUrl: './scheduler.component.html',
  styleUrls: ['./scheduler.component.scss']
})
export class SchedulerComponent extends CrudComponent implements OnInit, AfterViewInit {
  // Datasource
  private _items?: any[];
  @Input()
  set items(value: any) {
    this._items = value;
    this.itemsChange.emit(this.items);
    if (this.autoRefreshAfterUpdateItem) { this.refresh(); }
  }
  get items(): any[] | undefined {
    return this._items;
  }
  @Output() public itemsChange: EventEmitter<any[]> = new EventEmitter<any[]>();

  public dataSource: SyslinkDataSource = new SyslinkDataSource({});
  @Input() public service?: ODataService<any>;
  @Input() public autoRefreshAfterUpdateItem: boolean = true;
  // -------------------------------------------------------------

  @Input() public currentDate: Date = new Date();
  @Input() public views: Array<ViewType> = ['day', 'week', 'month'];
  @Input() public currentView: ViewType = 'week';
  @Input() public toolbarActions: SyslinkToolbarAction[] = [];
  public toolbarItems: SyslinkToolbarAction[] = [];
  @Input() public canDrag: boolean = true;
  @Input() public canResize: boolean = true;
  @Input() public adaptivityEnabled: boolean = true;
  @Input() public editModal?: ModalComponent;
  @Input() public options: Properties = {}
  @Input() public height: string = '80vh';

  @Input() public startAppointmentField: string = "StartDate";
  @Input() public endAppointmentField: string = "EndDate";
  @Input() public textAppointmentField: string = "Subject";
  @Input() public descriptionAppointmentField: string = "Description";
  @Input() public allDayAppointmentField: string = "AllDay";
  @Input() public contextMenuItems: ContextMenuItemAction[] = [];
  @Input() public appointmentContextMenuItems: ContextMenuItemAction[] = [];

  @Output() validate: EventEmitter<any> = new EventEmitter<any>();
  @Output() selectionChanged: EventEmitter<any> = new EventEmitter<any>();
  @Output() formOpen: EventEmitter<any> = new EventEmitter<any>();
  @Output() onCurrentDateChange: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild('scheduler') public scheduler?: DxSchedulerComponent
  @ViewChild('defaultEditModal') defaultEditModal?: ModalComponent;

  constructor(
    private translateService: TranslateService
  ) {
    super();
  }

  override ngAfterViewInit(): void {
    super.ngAfterViewInit();
    this.refresh();
  }

  public initDataSource() {
    if (this.service) {
      let dataSourceOptions: Partial<SyslinkDataSourceOptions> = {};
      dataSourceOptions = {
        ...<Partial<SyslinkDataSourceOptions>>this.service.defaultOptions,
        store: this.service.store,
        filter: this.filter ? this.filter : this.service.defaultOptions.filter,
        expand: this.expand ? this.expand : this.service.defaultOptions.expand,
        // select: this.select ? this.select : this.service.defaultOptions.select,
      }

      this.dataSource = new SyslinkDataSource(dataSourceOptions);
    }
  }

  public initOptions() {
    this.options.views = this.views;
    this.options.currentView = <ViewType>localStorage.getItem('scheduler.' + this.storageKey + '.currentView') ?? this.currentView;
    this.options.currentDate = this.currentDate;
    this.options.editing = {
      allowAdding: this.canAdd,
      allowDeleting: this.canDelete,
      allowDragging: this.canDrag,
      allowResizing: this.canResize,
      allowTimeZoneEditing: false,
      allowUpdating: this.canUpdate,
    };

    // Fix limit page size
    var localDatasource: any = this.dataSource;
    if (!this.items) {
      localDatasource._pageSize = 100;
    }
    this.options.dataSource = this.items ?? localDatasource;

    this.options.adaptivityEnabled = this.adaptivityEnabled;
    this.options.cellDuration = 15;

    this.options.startDateExpr = this.startAppointmentField;
    this.options.endDateExpr = this.endAppointmentField;
    this.options.textExpr = this.textAppointmentField;
    this.options.allDayExpr = this.allDayAppointmentField;
    this.options.descriptionExpr = this.descriptionAppointmentField;

    if (this.scheduler) {
      this.scheduler.instance.element().onmouseup = (e: MouseEvent) => {
        if (this.scheduler?.selectedCellData?.length && this.scheduler?.selectedCellData?.length > 1) {
          this.selectionChanged.emit(this.scheduler?.selectedCellData);
        }
      };
    }

    this.scheduler?.instance.option(this.options);
  }


  public initToolbar() {
    this.toolbarItems = [];

    if (this.canAdd && !this.toolbarItems.find(e => e.code == 'add')) {
      this.toolbarItems.push(new SyslinkToolbarActionButton({
        code: "add",
        icon: "plus",
        location: 'before',
        onClick: () => {
          this.add.emit()
        }
      }));
    }

    if (this.canRefresh && !this.toolbarItems.find(e => e.code == 'refresh')) {
      this.toolbarItems.push(new SyslinkToolbarActionButton({
        code: "refresh",
        icon: "fa-solid fa-rotate",
        location: 'after',
        onClick: () => this.refresh()
      }));
    }
    this.toolbarItems = [
      ...this.toolbarItems,
      ...this.toolbarActions
    ]
  }

  // TODO : Améliorer la gestion de ces 2 méthodes
  public refresh(): void {
    this.initDataSource();
    this.initToolbar();
    this.initOptions();
    this.scheduler?.instance.repaint();
    this.scrollToTime();
  }

  public reload(): void {
    this.scheduler?.instance.getDataSource().reload();
  }

  public onAppointmentTooltipShowing(event: AppointmentTooltipShowingEvent): void {
    event.cancel = true;
  }

  public onAppointmentClick(event: AppointmentClickEvent): void {
    event.cancel = true;
  }

  public onAppointmentFormOpening(event: AppointmentFormOpeningEvent): void {
    event.cancel = true;

    if (!(event.appointmentData as Appointment).Id) {
      this.selectionChanged.emit([{
        startDate: (event.appointmentData as any).StartDate,
        endDate: (event.appointmentData as any).EndDate
      }]);
    } else {
      if (this.formOpen.observed) {
        this.formOpen.emit(event.appointmentData);
      }
      else {
        this.editModal ? this.editModal.open(event.appointmentData) : this.defaultEditModal?.open(event.appointmentData);
      }
    }
  }

  public onValidateButtonClicked(e: any) {
    this.validate.emit(e);
  }

  public scrollToTime(time: Date = new Date()) {
    this.currentDate = time;
    this.scheduler?.instance.scrollTo(this.currentDate);
  }

  public fromDotNotation(data: any, key: string) {
    if (data) {
      return fromDotNotation(data, key);
    }
    return null;
  }


  onAppointmentContextMenu({ appointmentData, targetedAppointmentData }: AppointmentContextMenuEvent) {
    this.contextMenuItems = [...this.appointmentContextMenuItems]
    this.contextMenuItems = this.formatContextMenuItems(this.contextMenuItems, appointmentData)//{ appointmentData, targetedAppointmentData }
  }

  onCellContextMenu({ cellData }: CellContextMenuEvent) {
    this.contextMenuItems = [...this.contextMenuItems]
    this.contextMenuItems = this.formatContextMenuItems(this.contextMenuItems, { cellData })
  }

  onContextMenuItemClick(e: any) {
    e.itemData.onItemClick(e);
  }

  private formatContextMenuItems(items: ContextMenuItemAction[], rowData?: any): ContextMenuItemAction[] {
    return items.map(item => new ContextMenuItemAction({
      ...item,
      text: item.text ? this.translateService.instant(item.text) : item.text,
      rowData: rowData,
      items: item.items ? this.formatContextMenuItems(item.items, rowData) : item.items
    }));
  }

  onCurrentViewChanged(viewCode: string) {
    localStorage.setItem('scheduler.' + this.storageKey + '.currentView', viewCode);
  }

  onOptionChanged(e: any) {
    if (e.name == "currentDate") {
      this.onCurrentDateChange.emit(e);
    }
  }
}

export type SchedulerSelectionChangedData = {
  startDate: Date,
  endDate: Date,
  allDay: Boolean,
  groups: { // present if grouping is enabled
    resourceKind: "resourceValue" // for example, room: "101"
  }
};