import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TaxTypesService } from '../../../accountings/tax-types/tax-type.service';
import { AccountsService } from '../../../accountings/accounts/accounts.service';
import { Product } from '../product.model';
import { TaxRatesService } from '../../../base/tax-rates/tax-rates.service';
import { PageComponent } from 'projects/libraries/syslink-components/src/public-api';

@Component({
  selector: 'app-product-finance-form',
  templateUrl: './product-finance-form.component.html',
  styleUrls: ['./product-finance-form.component.scss']
})
export class ProductFinanceFormComponent extends PageComponent {

  @Input() public element: Product = new Product();
  @Output() public elementChange: EventEmitter<Product> = new EventEmitter<Product>();

  constructor(
    public taxTypesService: TaxTypesService,
    public accountsService: AccountsService,
    public taxRatesService: TaxRatesService,
  ) {
    super();
  }
}
