import { Injectable } from '@angular/core';
import { DocumentLineRelation } from './document-relation.model';
import { ODataService } from '../../../core/services/oData.service';
import { LoadOptions } from 'devextreme/data';

@Injectable({
  providedIn: 'root'
})
export class DocumentLineRelationsService extends ODataService<DocumentLineRelation> {
  public url = 'DocumentLineRelation';
  public override defaultOptions: LoadOptions = {
    expand: []
  };
  
  public override async getInstance(params?: Partial<DocumentLineRelation>): Promise<DocumentLineRelation> {
    var element = new DocumentLineRelation({
      ...params
    });
    return element;
  }

}
