import { Component } from '@angular/core';
import { Module } from './module.model';
import { ModulesService } from './modules.service';
import { ViewComponent } from 'projects/libraries/syslink-components/src/lib/helpers/view/view.component';

@Component({
  selector: 'app-modules',
  templateUrl: './modules.component.html',
  styleUrls: ['./modules.component.scss']
})
export class ModulesComponent extends ViewComponent {
  public modules: Module[] = [];

  constructor(
    public modulesService: ModulesService
  ) {
    super();
  }

  override ngOnInit(): void {

    // Loading all modules
    // -------------------
    this.modulesService.load().then((modules: Module[]) => {
      this.modules = modules;
    });

  }
}
