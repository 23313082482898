import { Injectable } from '@angular/core';
import { ODataService } from '../../../core/services/oData.service';
import { AccountMoveHeaderStatus } from './account-move-header-status.model';

@Injectable({
  providedIn: 'root'
})
export class AccountMoveHeaderStatusService extends ODataService<AccountMoveHeaderStatus> {
  public url = 'AccountMoveHeaderStatus';

  public override async getInstance(params?: Partial<AccountMoveHeaderStatus>): Promise<AccountMoveHeaderStatus> {
    var element = new AccountMoveHeaderStatus({
      ...params,
      Date: new Date()
    });
    return element;
  }

}
