import { Injectable } from '@angular/core';
import { SaleDocumentStatusesService } from '../../sale-documents/sale-document-statuses/sale-document-statuses.service';
import { SaleContractHeaderStatus } from './sale-contract-header-status.model';

@Injectable({
  providedIn: 'root'
})
export class SaleContractHeaderStatusesService extends SaleDocumentStatusesService<SaleContractHeaderStatus> {
  public override url: string = 'SaleContractHeaderStatus';
 
  constructor() {
    super();
  }
}
