<div class="third-finance-form">
  <div class="row">
    <div class="col-sm-8 col-xs-12">
      <syslink-block
        *ngIf="element.Id"
        [title]="'Bank accounts'"
        [titleBgColor]="'gray'"
        [titleTextColor]="'white'"
        [bordered]="true"
        [titlePadding]="2"
      >
        <app-bank-account-grid
          [filter]="['ThirdId.Id eq ' + element.Id]"
          [expand]="['ThirdId', 'BankId']"
          (beforeAdd)="onBeforeAddBankAccount($event)"
          [permissionKey]="'other.bankAccounts.list'"
        ></app-bank-account-grid>
      </syslink-block>
    </div>

    <div class="col-sm-4 col-xs-12">
      <syslink-block
        [title]="'Accounting'"
        [titleBgColor]="'gray'"
        [titleTextColor]="'white'"
        [bordered]="true"
        [titlePadding]="2"
      >
          <syslink-input-text
          name="BOB reference"
          [(model)]="element.AccountingBOBReference"
          icon="fa-solid fa-paragraph"
          [maxLength]="10"
        ></syslink-input-text>
      
        <syslink-select
          *ngIf="element.CustomerId"
          label="Sale tax rate"
          [store]="taxRatesService.store"
          [filter]="['IsVisible eq true']"
          [(model)]="element.CustomerId.SaleTaxRateId"
        ></syslink-select>
       
        <syslink-select
          *ngIf="element.CustomerId"
          label="Sale tax type"
          [store]="taxTypesService.store"
          [(model)]="element.CustomerId.SaleTaxTypeId"
        ></syslink-select>

        <syslink-select
          *ngIf="element.SupplierId"
          label="Purchase tax rate"
          [store]="taxRatesService.store"
          [filter]="['IsVisible eq true']"
          [(model)]="element.SupplierId.PurchaseTaxRateId"
        ></syslink-select>

        <syslink-select
          label="Country"
          [store]="countriesService.store"
          [(model)]="element.CountryId"
          [filter]="['IsVisible eq true']"
        ></syslink-select>

        <syslink-select
          label="Subjugation category"
          [store]="subjugationCategoriesService.store"
          [(model)]="element.SubjugationCategoryId"
        ></syslink-select>
      </syslink-block>
    </div>
  </div>
</div>
