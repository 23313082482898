<syslink-modal
  #workTimesModal
  title="Invoice work times"
  width="85%"
  height="80%"
  shading=""
  [resize]="true"
  (onValidate)="validate()"
>
  <div class="lead">{{"Some tasks are not fixed-price, please select the work time you want to add to the invoice." | translate}}</div>
  <syslink-treelist
    [columns]="columns"
    [options]="{editing:{allowAdding:false, allowUpdating:false, allowDeleting:false},selection:{mode:'multiple'},pager:{visible:false}}"
    (onRowPrepared)="onRowPrepared($event)"
    parentIdExpr="ParentId"
    storageKey="dynamic-price-invoicing"
    [items]="lines"
    [showDragIcons]="false"
    [allowReordering]="false"
    [(selectedItems)]="selectedItems"
    [stateStoringEnabled]="false"
    ></syslink-treelist>
  </syslink-modal>
