import { Component, Input, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmModalComponent, ModalComponent, NotificationsService, PageComponent } from 'projects/libraries/syslink-components/src/public-api';
import { ThirdsService } from '../../../thirds/thirds/thirds.service';
import { SaleQuote } from '../sale-quotes/sale-quote.model';
import { SaleQuotesService } from './sale-quotes.service';
import { jsonToOdataFormat } from 'projects/libraries/syslink-components/src/lib/helpers/tools';
import { DocumentCreateModalComponent } from '../../../base/documents/document-create-modal/document-create-modal.component';
import { SaleQuoteGridComponent } from '../sale-quote-grid/sale-quote-grid.component';
import { ContextMenuItemAction } from 'projects/libraries/syslink-components/src/lib/context-menus/context-menu-item-action';
import { SaleContexMenuItemActionCode } from '../../sale-documents/sale-documents/sale-documents.service';
import { ContextMenuItemClickEvent } from 'devextreme/ui/file_manager';
import { SaleQuoteStatusSelectModalComponent } from '../sale-quote-status-select-modal/sale-quote-status-select-modal.component';
import { SaleQuoteHeaderStatus } from '../sale-quote-header-statuses/sale-quote-header-status.model';
import { SaleQuoteHeaderHeaderStatus } from '../sale-quote-header-header-statuses/sale-quote-header-header-status.model';
import { SaleQuoteHeaderHeaderStatusesService } from '../sale-quote-header-header-statuses/sale-quote-header-header-statuses.service';
import { SaleDocumentStatus } from '../../sale-documents/sale-document-statuses/sale-document-status.model';
import { SaleQuoteHeaderStatusesService } from '../sale-quote-header-statuses/sale-quote-header-statuses.service';
import { DocumentCopyModalComponent } from '../../../base/documents/document-copy-modal/document-copy-modal.component';
import { NgxUiLoaderService } from 'ngx-ui-loader';

@Component({
  selector: 'app-sale-quotes',
  templateUrl: './sale-quotes.component.html',
  styleUrls: ['./sale-quotes.component.scss']
})
export class SaleQuotesComponent extends PageComponent {
  @Input() public filter: string | string[] = "";
  public customerFilter: string | string[] = ["Roles/any(r:r/Code eq 'customer')"];

  @Input() public items?: SaleQuote[];
  @Input() public canRefresh: boolean = false;

  @ViewChild('createModal') createModal?: DocumentCreateModalComponent;
  @ViewChild('grid') grid?: SaleQuoteGridComponent;
  @ViewChild("confirmModal") confirmModal?: ConfirmModalComponent;
  @ViewChild("statusModal") statusModal?: SaleQuoteStatusSelectModalComponent;
  @ViewChild('copyModal') public copyModal?: DocumentCopyModalComponent;


  public globalResult?: {
    InTaxTotal: number, ExTaxTotal: number, DraftExTaxTotal: number, DraftInTaxTotal: number,
    TransmittedExTaxTotal: number, TransmittedInTaxTotal: number, ConfirmedExTaxTotal: number, ConfirmedInTaxTotal: number
  };

  // ContextMenuItemAction
  // ---------------------
  public contextMenuItems: ContextMenuItemAction[] = [
    { code: SaleContexMenuItemActionCode.PrintToPDF, text: "Preview to PDF", icon: "print", onItemClick: (e: any) => this.onMenuItemClicked(e), visible: this.authService.hasPermission('sales.quotes.list.contextMenu.preview') },
    { code: SaleContexMenuItemActionCode.PrintToZIP, text: "Download to ZIP", icon: "print", onItemClick: (e: any) => this.onMenuItemClicked(e), visible: this.authService.hasPermission('sales.quotes.list.contextMenu.preview') },
    { code: SaleContexMenuItemActionCode.Status, text: "Change status", icon: "edit", onItemClick: (e: any) => this.onMenuItemClicked(e), visible: this.authService.hasPermission('sales.quotes.list.contextMenu.changeStatus') },
    { code: SaleContexMenuItemActionCode.copy, text: "Copy", icon: "edit", onItemClick: (e: any) => this.onMenuItemClicked(e), visible: this.authService.hasPermission('sales.quotes.list.contextMenu.copy') }
  ];

  constructor(
    public saleQuotesService: SaleQuotesService,
    private saleQuoteHeaderHeaderStatusesService: SaleQuoteHeaderHeaderStatusesService,
    private saleQuoteHeaderStatusesService: SaleQuoteHeaderStatusesService,
    public override activatedRoute: ActivatedRoute,
    public thirdsService: ThirdsService,
    public override translateService: TranslateService,
    public ngxUiLoaderService: NgxUiLoaderService,
  ) { super(); }

  public onAddButtonClicked() {
    this.createModal?.open();
  };

  public onCreateValidated(e: any) {
    if (!e.ThirdId.Id) {
      NotificationsService.sendErrorMessage("Please select third");
      return;
    }
    if (e.ThirdId.Blocked == true) {
      NotificationsService.sendErrorMessage("Third is blocked");
      return;
    }
    this.router.navigateByUrl('/sales/quotes/new?ThirdId=' + e.ThirdId.Id + (e.Subject != undefined ? '&Subject=' + e.Subject : '') + (e.ExternalReference != undefined ? '&ExternalReference=' + e.ExternalReference : ''));
  }

  public async onFilterValueChange(e: any[]) {
    var request = await this.saleQuotesService.getStatistics(e);
    if (!request) {
      this.globalResult = undefined;
      return;
    }
    this.globalResult = jsonToOdataFormat(request);
  }

  // ContextMenu
  // -----------
  public async onMenuItemClicked(e: ContextMenuItemClickEvent) {
    if (!this.grid) return;

    switch (e.itemData.code) {
      // Printing
      // ---------
      case SaleContexMenuItemActionCode.PrintToPDF:
        this.showConfirmModal("Would you like preview selected rows?", e.itemData);
        break;
      case SaleContexMenuItemActionCode.PrintToZIP:
        this.showConfirmModal("Would you like download selected rows?", e.itemData);
        break;
      case SaleContexMenuItemActionCode.Status:
        this.showStatusModal("Would you like change status selected rows?", e.itemData);
        break;
      case SaleContexMenuItemActionCode.copy:
        if (this.grid?.getSelectedRows(e.itemData.rowData).length > 1) {
          NotificationsService.sendSuccess('Please select only one line');
        }
        this.copyModal?.open({
          subject: e.itemData.rowData.Subject,
          elementId: e.itemData.rowData.Id,
          showThird: true,
          showSubject: true,
          title: "Copy",
          thirdFilter: this.customerFilter
        });
        break;
    }
  }

  private showStatusModal(text: string, data: ContextMenuItemAction) {
    if (!this.statusModal) return;

    this.statusModal.title = data.text ? data.text : "Confirm";
    this.statusModal.content = text;
    this.statusModal.open({ rows: this.grid?.getSelectedRows(data.rowData), action: data.code });
  }

  private showConfirmModal(text: string, data: ContextMenuItemAction) {
    if (!this.confirmModal) return;

    this.confirmModal.title = data.text ? data.text : "Confirm";
    this.confirmModal.content = text;
    this.confirmModal.open({ rows: this.grid?.getSelectedRows(data.rowData), action: data.code });
  }

  public async onConfirmModalValidated(modalData: { rows: SaleQuote[], action: string } | any) {
    switch (modalData.action) {
      // Invoicing
      // ---------
      case SaleContexMenuItemActionCode.PrintToPDF:
        await this.saleQuotesService.printRows(modalData.rows, "pdf", "SaleQuote");
        break;
      case SaleContexMenuItemActionCode.PrintToZIP:
        await this.saleQuotesService.printRows(modalData.rows, "zip", "SaleQuote");
        break;
      case SaleContexMenuItemActionCode.Status:
        await this.updateStatus(modalData.rows, modalData.QuoteStatus);
        break;
    }

    this.grid?.refresh();
    this.confirmModal?.close();
    this.statusModal?.close();
  }
  // ------------------------------------------------------------------------------------------------
  // Actions
  // -------
  private async updateStatus(selectedRows: SaleQuote[], status: SaleQuoteHeaderStatus) {
    if (!this.saleQuotesService.canUpdateStatus(selectedRows, status)) return;
    selectedRows.sort((a, b) => { return a.Date.getTime() - b.Date.getTime(); });
    var statuses = await this.saleQuoteHeaderStatusesService.load();
    for (var element of selectedRows) {
      if (element.Id) {

        var intermediateStatus = statuses.filter((e: SaleDocumentStatus) => {
          return e.Sequence && status?.Sequence && element.CurrentStatusLink?.StatusId?.Sequence
            && status?.Sequence != e.Sequence
            && e.Sequence > element.CurrentStatusLink?.StatusId?.Sequence
            && e.Sequence < status?.Sequence;
        });

        for (let index = 0; index < intermediateStatus.length; index++) {
          var intermediateNewStatus = await this.saleQuoteHeaderHeaderStatusesService.getInstance();
          intermediateNewStatus.StatusId = intermediateStatus[index];
          intermediateNewStatus.HeaderId = element;
          intermediateNewStatus.Date = new Date(intermediateNewStatus.Date.setSeconds(intermediateNewStatus.Date.getSeconds() - (index + 1)));
          element.Statuses?.push(intermediateNewStatus);
          await this.updateDocumentStatus(intermediateNewStatus);
        }

        const link: SaleQuoteHeaderHeaderStatus = {
          HeaderId: element,
          StatusId: { Id: status.Id },
          Date: new Date()
        };
        await this.updateDocumentStatus(link);
      }
    }
    NotificationsService.sendSuccess('Status updated');
  }
  private async updateDocumentStatus(link: any) {
    let documentStatus = await this.saleQuoteHeaderHeaderStatusesService.insert(this.saleQuoteHeaderHeaderStatusesService.format(link));
  }

  // Copy
  // ----
  public async onCopyButtonClicked(e: any) {
    let elementId = e.data.elementId;
    let newThirdId = e.data.ThirdId.Id;
    let subject = e.data.subject;

    if (!elementId || !newThirdId) {
      NotificationsService.sendErrorMessage('Please select third');
      return;
    }
    this.ngxUiLoaderService.start();
    await this.saleQuotesService.copy("SaleQuote", await this.saleQuotesService.findByID(elementId), subject, await this.thirdsService.findByID(newThirdId));
    this.ngxUiLoaderService.stop();
  }

}