import { Component, Input, ViewChild } from '@angular/core';
import { BankReconciliation } from '../bank-reconciliation.model';
import { BaseGridComponent } from 'projects/libraries/syslink-components/src/lib/grid/base-grid/base-grid.component';
import { GridComponent } from 'projects/libraries/syslink-components/src/public-api';
import { SyslinkColumn } from 'projects/libraries/syslink-components/src/lib/helpers/SyslinkColumn';
import { BankReconciliationsService } from '../bank-reconciliation.service';
import { DXCell } from 'projects/libraries/syslink-components/src/lib/grid/cells/dxcell';

@Component({
  selector: 'app-bank-reconciliation-grid',
  templateUrl: './bank-reconciliation-grid.component.html',
  styleUrls: ['./bank-reconciliation-grid.component.scss']
})
export class BankReconciliationGridComponent extends BaseGridComponent<BankReconciliation> {

  @Input() storageKey: string = 'grid-bank-reconciliations';
  @Input() public override expand?: string | string[] = ['CurrentStatusLink.StatusId','ResponsibleUserId'];

  @ViewChild('gridComponent') public gridComponent?: GridComponent;
  
  public columns: SyslinkColumn[] = [
    new SyslinkColumn({ field: 'No', sortable: true, sortIndex: 0, sortDirection: 'desc' }),
    new SyslinkColumn({ field: 'ResponsibleUserId.Name', label: 'User'}),
    new SyslinkColumn({ field: "CurrentStatusLink.StatusId.Name", label: "Status", cellTemplate: 'block-cell', data: { getBlockLabel: this.getStatusCellLabel, getBlockClass: this.getStatusCellClass, getBlockColor: this.getStatusCellColor }}),
    new SyslinkColumn({ field: 'Date', label: 'Date', type: 'date'}),
    new SyslinkColumn({ field: 'NumberOfLines', label: 'Number of lines', type: 'number'}),
];

  constructor(
    public bankReconciliationsService: BankReconciliationsService
  ) {
    super();
  }

    // Status cell
    getStatusCellLabel(cell: DXCell) {
      return cell.data.CurrentStatusLink.StatusId.Name;
    }
  
    getStatusCellClass(cell: DXCell) {
      let statusClass = 'text-white text-center'
  
      const statusCode = (<string>cell.data.CurrentStatusLink.StatusId.Code).toLowerCase().split('.')[1];
      if (statusCode == undefined) return;
      statusClass += ' ' + statusCode;
      return statusClass;
    }
  
    getStatusCellColor(cell: DXCell) {
      return cell.data.CurrentStatusLink.StatusId.Color;
    }
}