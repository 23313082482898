<syslink-grid
  #grid
  [gridName]="storageKey"
  [canView]="canView"
  [canSearch]="canSearch"
  [canRefresh]="canRefresh"
  [canAdd]="canAdd"
  [canSort]="canSort"
  [canFilter]="canFilter"
  [canExport]="canExport"
  [canDblClck]="canDblClck"
  [canDelete]="canDelete"
  [items]="items"
  [service]="saleContractsService"
  [columns]="columns"
  [filter]="filter"
  [canUpdate]="false"
  (add)="onAddButtonClicked()"
  [detailsUrl]="detailsUrl"
  [storageKey]="storageKey"
  [showPager]="showPager"
  [contextMenuItems]="contextMenuItems"
  [expand]="expand"
  [select]="select"
  (refresh)="refresh()"  
  (onFilterValueChange)="onFilterValueChange.emit($event)"
  (beforeDelete)="onDeleteClicked($event)"
></syslink-grid>