import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SaleContract } from '../sale-contracts/sale-contract.model';
import { BaseGridComponent } from 'projects/libraries/syslink-components/src/lib/grid/base-grid/base-grid.component';
import { ContextMenuItemAction } from 'projects/libraries/syslink-components/src/lib/context-menus/context-menu-item-action';
import { SyslinkColumn } from 'projects/libraries/syslink-components/src/lib/helpers/SyslinkColumn';
import { SaleContractsService } from '../sale-contracts/sale-contracts.service';
import { DXCell } from 'projects/libraries/syslink-components/src/lib/grid/cells/dxcell';

@Component({
  selector: 'app-sale-contract-grid',
  templateUrl: './sale-contract-grid.component.html',
  styleUrls: ['./sale-contract-grid.component.scss']
})
export class SaleContractGridComponent extends BaseGridComponent<SaleContract> {
  @Input() override detailsUrl: string = '/sales/contracts';
  @Input() override storageKey: string = 'sale-contracts';
  @Input() public contextMenuItems: ContextMenuItemAction[] = [];
  @Input() showPager: boolean = true;
  @Input() items?: any[];

  // FilterValue
  // -----------
  @Output() onFilterValueChange: EventEmitter<any[]> = new EventEmitter<any[]>();

  public columns: SyslinkColumn[] = [
    new SyslinkColumn({ field: "No", label: "GRID.CAPTION.NO",  sortIndex: 2, sortDirection: 'desc' }),
    new SyslinkColumn({ field: "ThirdId.Fullname", label: "Customer" }),
    new SyslinkColumn({ field: "Subject", label: "Subject" }),
    new SyslinkColumn({ field: "CurrentStatusLink.StatusId.Name", label: "Status", cellTemplate: 'block-cell', data: { getBlockLabel: this.getStatusCellLabel, getBlockClass: this.getStatusCellClass, getBlockColor: this.getStatusCellColor } }),
    new SyslinkColumn({ field: "RRule", label: "Repeat", visible: true, cellTemplate: 'rrule-cell' }),
    new SyslinkColumn({ field: "GenerateDocumentNextDate", label: "GenerateDocumentNextDate", type: 'date', cellTemplate: 'date-cell', visible: true,  filterable: false, sortable: false, headerFilterable: false }),
    new SyslinkColumn({ field: "ExternalReference", label: "External reference", visible: true }),
    new SyslinkColumn({ field: "Deadline", label: "SaleContract.Validity", type: 'date' }),
    new SyslinkColumn({ field: "ResponsibleUserId.Fullname", label: "Responsible" }),
    new SyslinkColumn({ field: "CurrentStatusLink.StatusId.Sequence", label: 'status order', sortIndex: 1, sortDirection: 'asc', filterable: false, headerFilterable: false, visible: false })
  ];

  @Input() public override expand?: string | string[] = ['CurrentStatusLink.StatusId'];
  @Input() public override select?: string[] = ['Id', 'No', 'ThirdId.Fullname', 'Subject', 'RRule', 'GenerateDocumentNextDate', 'Deadline', 'ExternalReference', 'ResponsibleUserId.Fullname'];


  constructor(
    public saleContractsService: SaleContractsService,
  ) {
    super();
  }
  getStatusCellLabel(cell: DXCell) {
    return cell.data.CurrentStatusLink.StatusId.Name;
  }

  getStatusCellClass(cell: DXCell) {
    let statusClass = 'text-white text-center'

    const statusCode = (<string>cell.data.CurrentStatusLink.StatusId.Code).toLowerCase().split('.')[1];
    if (statusCode == undefined) return;
    statusClass += ' ' + statusCode;
    return statusClass;
  }

  getStatusCellColor(cell: DXCell) {
    return cell.data.CurrentStatusLink.StatusId.Color;
  }
  public reload() {
    this.grid?.grid?.instance.refresh();
  }
}


