import { Injectable } from '@angular/core';
import { LoadOptions } from 'devextreme/data';
import { TaskParticipant } from './task-participant.model';
import { ODataService } from '../../../core/services/oData.service';

@Injectable({
  providedIn: 'root'
})
export class TaskParticipantsService extends ODataService<TaskParticipant> {
  public url = 'TaskParticipant';
  public override defaultOptions: LoadOptions = {
    expand: ['UserId', 'UserGroupId']
  };

  public override format(element: Partial<TaskParticipant>): TaskParticipant {

    var result: any = {
      ...element,
      UserId: element.UserId?.Oid ?? null,
      UserGroupId: element.UserGroupId?.Id ?? null,
      TaskId: element.TaskId?.Id ?? null,
    };

    delete result.UpdatedAt;
    delete result.CreatedAt;
    delete result.FormattedName;

    return result;
  }

}
