import { ThirdContactInformation } from "../../thirds/thirds/third-contacts/third-contact-informations/third-contact-information.model";
import { Third } from "../../thirds/thirds/third.model";

export class MailModel {
    public Subject: string = "";
    public Content: string = "";
    public FromThirdId?: Third;
    public FromThirdEmail?: string;
    public ToThirdContactInformationId: Array<ThirdContactInformation> = [];
    public ToThirdEmails: Array<string> = [];
    public CCThirdContactInformationId: Array<ThirdContactInformation> = [];
    public CCThirdEmails: Array<string> = [];
    public CCIThirdContactInformationId: Array<ThirdContactInformation> = [];
    public CCIThirdEmails: Array<string> = [];

    // Files
    public FileIds: Array<number> = [];

    // ----------
    public FromThird?: string;

    // SendReport

    public SendReport: boolean = false;
    public ReportType: string = "";
    public HeaderId: string = "";
    public FileName: string = "";


    constructor(entity?: Partial<MailModel>) {
        Object.assign(this, entity);
    }
}