import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { PeriodicitiesService } from '../../../base/periodicities/periodicities/periodicities.service';
import { SaleDocument } from '../../sale-documents/sale-documents/sale-document.model';
import { SaleContractsService } from '../sale-contracts/sale-contracts.service';
import { Appointment } from 'devextreme/ui/scheduler';
import { TranslateService } from '@ngx-translate/core';
import { Options, RRule } from 'rrule';
import { PeriodicityIds } from '../../../base/periodicities/periodicities/periodicity.model';
import { SchedulerComponent } from '../../../../../../libraries/syslink-components/src/public-api';
import { Subscription } from 'rxjs';
import { getNew } from 'projects/libraries/syslink-components/src/lib/helpers/tools';
import { SaleContract } from '../sale-contracts/sale-contract.model';

@Component({
  selector: 'app-sale-contract-schedule',
  templateUrl: './sale-contract-schedule.component.html',
  styleUrls: ['./sale-contract-schedule.component.scss']
})
export class SaleContractScheduleComponent implements OnInit, OnDestroy {
  @Input() public element?: SaleContract;
  @Output() public elementChange: EventEmitter<SaleContract> = new EventEmitter<SaleContract>();

  @Output() public updateRRule: EventEmitter<any> = new EventEmitter<any>();

  public schedulerDatasource: Array<Appointment> = [];
  @ViewChild('invoiceScheduler') private invoiceScheduler?: SchedulerComponent;

  private contractSchedulerUpdater?: Subscription;

  constructor(
    public periodicitiesService: PeriodicitiesService,
    public saleContractsService: SaleContractsService,
    private translateService: TranslateService
  ) { }

  ngOnDestroy(): void {
    this.contractSchedulerUpdater?.unsubscribe();
  }

  ngOnInit(): void {
    if (!this.element?.RRule) {
      this.contractSchedulerUpdater = this.saleContractsService.contractSchedulerUpdater$.subscribe(this.generateRRule.bind(this));
      // this.updateRRule.emit();
    }

    this.updateSchedulerDatasource();

  }

  private updateSchedulerDatasource(): void {
    if(!this.element) return;
    if (!this.element.RRule) {
      this.schedulerDatasource = [];
      return;
    }

    if (!this.element.StartPeriodDate || this.element.StartPeriodDateOffset === undefined || !this.element.Deadline) return;

    const match = this.element.RRule.match(/RRULE:[^\n]+/g)
    if (!match) return;

    this.schedulerDatasource = [{
      Description: this.translateService.instant('Invoice'),
      StartDate: this.getDateBasedOnDaysOffset(this.element.StartPeriodDate, this.element.StartPeriodDateOffset, this.element.IsStartPeriodDateOffsetPositive),
      recurrenceRule: match[0].substring(6)
    }];
  }

  private getDateBasedOnDaysOffset(baseDate: Date, offset: number, isOffsetPositif: boolean): Date {
    return new Date((baseDate?? new Date()).getTime()  + ((offset * 24 * 60 * 60 * 1000) * (isOffsetPositif ? 1 : -1)));
  }

  // Contract RRule
  // Doc : https://github.com/jakubroztocil/rrule
  // --------------------------------------------
  private generateRRule() {
    if (!this.element || !this.element.StartPeriodDate || !this.element.Deadline || this.element.StartPeriodDateOffset === undefined || !this.element.PeriodicityId) return;

    const rruleOptionOverrides: Partial<Options> = {};

    switch (this.element.PeriodicityId.Id) {
      case PeriodicityIds.Daily:
        rruleOptionOverrides.freq = RRule.DAILY;
        break;

      case PeriodicityIds.Weekly:
        rruleOptionOverrides.freq = RRule.WEEKLY;
        break;

      case PeriodicityIds.Monthly:
        rruleOptionOverrides.freq = RRule.MONTHLY;
        break;

      case PeriodicityIds.Annual:
        rruleOptionOverrides.freq = RRule.YEARLY;
        break;

      case PeriodicityIds.Quarterly:
        rruleOptionOverrides.interval = 3; // <- Trimestriel
        rruleOptionOverrides.freq = RRule.MONTHLY;
        break;

      case PeriodicityIds.Biannual:
        rruleOptionOverrides.interval = 6; // <- BiAnnual
        rruleOptionOverrides.freq = RRule.MONTHLY;
        break;

      case PeriodicityIds.None:
        this.element.RRule = "";
        this.updateSchedulerDatasource();
        return;

      default:
        return;
    }

    const rule = new RRule({
      interval: 1,
      dtstart: this.getDateBasedOnDaysOffset(this.element.StartPeriodDate, this.element.StartPeriodDateOffset, this.element.IsStartPeriodDateOffsetPositive),
      until: this.getDateBasedOnDaysOffset(this.element.Deadline, this.element.StartPeriodDateOffset, this.element.IsStartPeriodDateOffsetPositive),
      ...rruleOptionOverrides
    });

    this.element.RRule = rule.toString();

    this.updateSchedulerDatasource();
  }
}
