<syslink-toolbar
  [actions]="toolbarActions"
  class="me-3"
  [withLine]="false"
></syslink-toolbar>

<div class="row">
  <div class="col-sm-9 col-xs-12">

    <syslink-block
      [title]="element.UserName??''"
      [translateTitle]="false"
    >
      <div class="col-sm-5 col-xs-12">
        <app-user-form [(user)]="element"></app-user-form>
      </div>
      <div class="mt-4">
        <syslink-tabs
          #userPermissions
          *ngIf="element.Oid"
        >
          <syslink-tab
            label="User groups"
            [visible]="authService.hasPermission(this.basePermissionKey + '.userGroup.tab.view')"
          >
            <app-user-group-grid
              #userGroupsGrid
              [canUpdate]="false"
              [permissionKey]="'userGroups.list'"
              storageKey="user-details-grid-user-groups"
              [filter]="userGroupsFilters"
              (add)="addUserGroupButtonClicked()"
              (delete)="onDeleteUserGroup($event)"
            >
            </app-user-group-grid>

            <app-user-group-select-modal
              #userGroupSelectModal
              (validate)="onValidateUserGroupAddButtonClicked($event)"
            ></app-user-group-select-modal>
          </syslink-tab>
          <syslink-tab
            label="Action groups"
            [visible]="authService.hasPermission(this.basePermissionKey + '.actionGroup.tab.view')"
          >
            <app-action-group-grid
              #actionGroupsGrid
              [canUpdate]="false"
              [permissionKey]="'actionGroups.list'"
              storageKey="user-details-grid-action-groups"
              [filter]="actionGroupsFilters"
              (add)="addActionGroupButtonClicked()"
              (delete)="onDeleteActionGroup($event)"
              [select]="['Id', 'Code','Name']"
              [expand]="['NameTranslationId.Translations.LanguageId']"
            >
            </app-action-group-grid>

            <app-action-group-select-modal
              #actionGroupSelectModal
              (validate)="onValidateActionGroupAddButtonClicked($event)"
            ></app-action-group-select-modal>
          </syslink-tab>
        </syslink-tabs>
      </div>
    </syslink-block>
  </div>

  <div class="col-sm-3 col-xs-12">
    <syslink-block
      [title]="'Security'"
      [titleSize]="6"
    >

      <syslink-switch [(model)]="element.ResetPassword" label="Reset password"></syslink-switch>
      <ng-container *ngIf="element.ResetPassword">
        <syslink-input-text
        label="New password"
        [(model)]="element.Password"
        type="password"
        ></syslink-input-text>
        
        <syslink-input-text
        label="New password confirmation"
        type="password"
        [(model)]="element.PasswordConfirmation"
        type="password"
        ></syslink-input-text>
      </ng-container>
    </syslink-block>

    <syslink-block
      [title]="'Other'"
      [titleSize]="6"
      class="ms-3"
    >
      <syslink-select
        label="Language"
        [store]="languagesService.store"
        [(model)]="element.LanguageId"
      ></syslink-select>
      <syslink-color-picker
        label="BackgroundColor"
        [(model)]="element.BackgroundColor"
      ></syslink-color-picker>
      <syslink-color-picker
        label="ForegroundColor"
        [(model)]="element.ForegroundColor"
      ></syslink-color-picker>
    </syslink-block>
  </div>
</div>




<syslink-confirm-password
  #passwordConfirm
  (onValidate)="onSavebtnClicked($event)"
></syslink-confirm-password>
<syslink-confirm-modal
  #deleteConfirm
  title="Delete"
  [content]="'DeleteConfirmMessage'"
  (onValidate)="userDelete()"
  validateText="Confirm"
  cancelText="Cancel"
></syslink-confirm-modal>