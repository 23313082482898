import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Task } from '../task.model';

@Component({
  selector: 'app-task-performable-block',
  templateUrl: './task-performable-block.component.html',
  styleUrls: ['./task-performable-block.component.scss']
})
export class TaskPerformableBlockComponent {
  @Input() public element: Task = new Task();
  @Output() public elementChange: EventEmitter<Task> = new EventEmitter<Task>();
  
}
