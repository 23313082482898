import { Injectable } from '@angular/core';
import { LoadOptions } from 'devextreme/data';
import { SaleDocumentHeaderStatusesService } from '../../sale-documents/sale-document-header-status/sale-document-header-statuses.service';
import { SaleCreditNoteHeaderHeaderStatus } from './sale-credit-note-header-header-status.model';

@Injectable({
  providedIn: 'root'
})
export class SaleCreditNoteHeaderHeaderStatusesService extends SaleDocumentHeaderStatusesService<SaleCreditNoteHeaderHeaderStatus> {
  public override url: string = 'SaleCreditNoteHeaderHeaderStatus';
  public override defaultOptions: LoadOptions = {
    expand: ["StatusId"]
  };

  public override format(element: Partial<SaleCreditNoteHeaderHeaderStatus>): SaleCreditNoteHeaderHeaderStatus {
    var result: any = {
      ...element,
      HeaderId:element.HeaderId?.Id,
      StatusId:element.StatusId?.Id
    };
    return result;
  }

  public override async getInstance(params?: Partial<SaleCreditNoteHeaderHeaderStatus>): Promise<SaleCreditNoteHeaderHeaderStatus> {
    var element = new SaleCreditNoteHeaderHeaderStatus({
      Date: new Date(),
      ...params
    });
    return element;
  }
}
