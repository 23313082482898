<syslink-select
    label="Customer"
    [store]="customersService.store"
    [displayKey]="'ThirdId.Fullname'"
    [expand]="['ThirdId']"
    [select]="['Id','ThirdId.Id', 'ThirdId.Fullname']"
    [(model)]="element.CustomerId"
    [disabled]="readOnly"
></syslink-select>
<syslink-select
    label="Price list"
    [store]="priceListsService.store"
    [(model)]="element.PriceListId"
    [disabled]="readOnly"
></syslink-select>
<div
    *ngIf="element.MarginId && element.MarginId.Value != undefined && element.MarginId.IsDiscountFixed != undefined"
    class="d-flex justify-content-between"
>
    <syslink-input-number
        label="Discount margin"
        [min]="0"
        [max]="element.MarginId.IsDiscountFixed == true ? undefined : 100"
        [model]="element.MarginId.IsDiscountFixed? element.MarginId.Value: (element.MarginId.Value*100)"
        (modelChange)="onChangeMarginValue($event)"
    ></syslink-input-number>
    <syslink-select
        labelMode="hidden"
        [displayKey]="'name'"
        valueKey="id"
        [items]="[
          { id: 0, name: '%' },
          { id: 1, name: '€' }
        ]"
        [model]="element.MarginId.IsDiscountFixed? 1:0"
        (modelChange)="onChangeMarginFixed($event)"
    ></syslink-select>
</div>
<div
    *ngIf="element.DiscountId && element.DiscountId.Value != undefined && element.DiscountId.IsDiscountFixed != undefined"
    class="d-flex justify-content-between"
>
    <syslink-input-number
        label="Discount"
        [min]="0"
        [max]="element.DiscountId.IsDiscountFixed == true ? undefined : 100"
        [model]="element.DiscountId.IsDiscountFixed? element.DiscountId.Value: (element.DiscountId.Value*100)"
        (modelChange)="onChangeDiscountValue($event)"
    ></syslink-input-number>
    <syslink-select
        labelMode="hidden"
        [displayKey]="'name'"
        valueKey="id"
        [items]="[
          { id: 0, name: '%' },
          { id: 1, name: '€' }
        ]"
        [model]="element.DiscountId.IsDiscountFixed? 1:0"
        (modelChange)="onChangeDiscountFixed($event)"
    ></syslink-select>
</div>