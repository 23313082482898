<!-- Toolbar -->
<syslink-toolbar [actions]="toolbarActions" class="me-3" [withLine]="false"></syslink-toolbar>
<div class="row">
  <div class="col-sm-9 col-xs-12">
    <syslink-block [title]="element.No" [translateTitle]="false">
      <app-document-statuses *ngIf="element?.Statuses" #documentStatuses [documentStatuses]="element.Statuses"
        (statusChanged)="onDocumentStatusChanged($event)"></app-document-statuses>
      <app-task-form [(element)]="element"></app-task-form>

      <div class="mt-4">
        <syslink-tabs
          #taskTabs
          (selectedIdChange)="onChangeSelectedId($event)"
        >
        <syslink-tab
        [tabData]="tabsData[0]"
        [visible]="authService.hasPermission(this.basePermissionKey + '.worktime.tab.view') && element.IsPerformable"
        >      
        <app-work-time-grid
        #worktimeGrid
        *ngIf="tabsData[0].loaded"
        storageKey="task-detail-work-time-grid"
        [permissionKey]="'workTimes.list'"
        [filter]="['TaskId.Id eq ' + element.Id]"
        (add)="onAddWorkTimeButtonClicked()"
        (rowDoubleClicked)="worktimeModal?.open($event)"
        (afterDelete)="onDeletedWorkTime($event)"
        [columns]="WorktimeColumns"
        [contextMenuItems]="workTimeContextMenuItems"  
      ></app-work-time-grid>

      </syslink-tab>
          <syslink-tab
            [tabData]="tabsData[1]"
            [visible]="
              authService.hasPermission(
                this.newBasePermissionKey + '.saleInvoice.tab.view'
                ) && element.TaskBillingTypeId.Code != 'NotBillable'
                "
          >
            <app-sale-invoice-grid
              #saleInvoiceGrid
              *ngIf="tabsData[1].loaded"
              [permissionKey]="'invoices.list'"
              storageKey="task-detail-sale-invoice-grid"
              [filter]="getInvoiceRelationFilter()"
              [canDelete]="false"
              (add)="showInvoiceModal()"
              [columns]="SaleInvoiceColumns"
          ></app-sale-invoice-grid>
          </syslink-tab>
        </syslink-tabs>
      </div>
    </syslink-block>
  </div>

  <div class="col-sm-3 col-xs-12 mt-3 mt-sm-0">
    <syslink-block *ngIf="authService.hasPermission(this.basePermissionKey + '.worktime.tab.view')" [title]="'Performance'" [titleSize]="6">
      <app-task-performable-block [(element)]="element"></app-task-performable-block>
    </syslink-block>

    <syslink-block *ngIf="authService.hasPermission(this.basePermissionKey + '.saleInvoice.tab.view')" [title]="'Billing'" [titleSize]="6" class="{{authService.hasPermission(this.basePermissionKey + '.worktime.tab.view')?'ms-3':''}}">
      <app-task-billings [(element)]="element" (taskBillingTypeChange)="onChangeBillingType()"></app-task-billings>
    </syslink-block>

    <syslink-block [title]="'Thirds'" [titleSize]="6" class="{{!authService.hasPermission(this.basePermissionKey + '.worktime.tab.view') && !authService.hasPermission(this.basePermissionKey + '.saleInvoice.tab.view')?'':'ms-3'}}">
      <app-task-third [(element)]="element"></app-task-third>
    </syslink-block>

    <syslink-block [title]="'Participants'" [titleSize]="6" class="ms-3">
      <app-task-participant [(element)]="element"></app-task-participant>
    </syslink-block>

    <syslink-block [title]="'Other'" [titleSize]="6" class="ms-3">
      <app-task-other [(element)]="element"></app-task-other>
    </syslink-block>
  </div>
</div>

<syslink-confirm-modal
#deleteConfirm
title="Delete"
content="DeleteConfirmMessage"
(onValidate)="onDeleteConfirmed()"
></syslink-confirm-modal>

<app-work-time-modal
#worktimeModal
*ngIf="tabsData[0].loaded"
(validate)="onValidateWorkTimeCreation($event)"
[canEditTask]="false"
[updatingPermissionKey]="this.newBasePermissionKey + '.workTimes.list.doubleClick'"
[addingPermissionKey]="this.newBasePermissionKey + '.workTimes.list.add'"
></app-work-time-modal>

<app-sale-invoice-select-modal
  #saleInvoiceSelectModal
  *ngIf="tabsData[1].loaded"
  (validate)="onConfirmInvoiceModalValidated($event)"
  (onNewInvoiceButtonClicked)="onConfirmInvoiceModalValidated($event)"
  [expand]="['CurrentStatusLink.StatusId', 'ThirdId']"
  ></app-sale-invoice-select-modal>

<app-dynamic-price-invoicing
  #dynamicPriceInvoicing
  *ngIf="tabsData[1].loaded"
  (onInvoiceCreated)="onInvoiceCreated()"
></app-dynamic-price-invoicing>

<syslink-confirm-modal
  #confirmModal
  (onValidate)="onConfirmModalValidated($event)"
></syslink-confirm-modal>


<app-work-time-type-select-modal
  #typeSelectModal
  (validate)="onConfirmModalValidated($event)"
></app-work-time-type-select-modal>
<!-- <ng-container *ngIf="element">
  <div class="mt-3 mb-5">
    <app-task-form
      #form
      [(element)]="element"
      (updated)="onFormUpdated()"
      (estimatedTimeChange)="reloadTime()"
    ></app-task-form>
  </div>
  <div
    *ngIf="element.Id"
    class="mt-4"
  >
    <syslink-tabs #tabs> -->
<!-- <syslink-tab
        key="Planification"
        [visible]="element.IsPlannable"
        label="Plannable"
      >
        <div class="row">
          <div class="col-8">
            <app-appointment-scheduler
              #scheduler
              [canRefresh]="true"
              (add)="onAddAppointmentButtonClicked()"
              [filter]="appointmentFilters"
              (validate)="onValidateAppointmentCreation($event)"
              (selectionChanged)="onSchedulerSelectionChanged($event)"
              [canEditType]="false"
              [canEditTask]="false"
            ></app-appointment-scheduler>
            <app-appointment-modal
              #appointmentModal
              [canEditType]="false"
              [canEditTask]="false"
              (validate)="onValidateAppointmentCreation($event)"
            ></app-appointment-modal>
          </div>
          <div class="col-4">
            <app-appointment-grid
              #appointmenGrid
              [permissionKey]="'schedulings.list'"
              [filter]="appointmentFilters"
              [canRefresh]="false"
              [canDelete]="false"
              (add)="onAddAppointmentButtonClicked()"
              [columns]="appointmentColumns"
              (rowDoubleClicked)="appointmentModal.open($event)"
              storageKey="task-details-appointments"
            ></app-appointment-grid>
          </div>
        </div>
      </syslink-tab> -->
<!-- <syslink-tab
        key="Performance"
        [visible]="authService.hasPermission(this.basePermissionKey + '.worktime.tab.view') && element.IsPerformable"
        label="Performable"
      >
        <app-work-time-grid
          #worktimeGrid
          [permissionKey]="'workTimes.list'"
          [filter]="['TaskId.Id eq ' + element.Id]"
          (add)="onAddWorkTimeButtonClicked()"
          (rowDoubleClicked)="worktimeModal.open($event)"
          (afterDelete)="onDeletedWorkTime($event)"
          [columns]="WorktimeColumns"
        ></app-work-time-grid>

        <app-work-time-modal
          #worktimeModal
          (validate)="onValidateWorkTimeCreation($event)"
          [canEditTask]="false"
        ></app-work-time-modal>
      </syslink-tab>
     
      <syslink-tab
        key="Other"
        label="Other"
        [visible]="authService.hasPermission(this.basePermissionKey + '.other.tab.view')"
      >
        <app-task-other [(element)]="element"></app-task-other>
      </syslink-tab>
    </syslink-tabs>
  </div>

</ng-container>
-->