import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { Account } from '../account.model';
import { ModalComponent } from 'projects/libraries/syslink-components/src/public-api';
import { AccountsService } from '../accounts.service';
import { AccountTypesService } from '../../account-types/account-types.service';

@Component({
  selector: 'app-account-modal',
  templateUrl: './account-modal.component.html',
  styleUrls: ['./account-modal.component.scss']
})
export class AccountModalComponent {
  @Input() public element: Account = new Account();
  @Output() public elementChange: EventEmitter<Account> = new EventEmitter<Account>();

  @Input() title: string = "Account";
  @Output() public validate: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild("modal") modal?: ModalComponent;

  constructor(
    public accountsService: AccountsService ,
    public accountTypesService:AccountTypesService
  ) {}

}
