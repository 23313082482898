import { Component, Input, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NotificationsService, PageComponent } from 'projects/libraries/syslink-components/src/public-api';
import { ThirdsService } from '../../../thirds/thirds/thirds.service';
import { PurchaseCreditNotesService } from './purchase-credit-notes.service';
import { PurchaseCreditNote } from './purchase-credit-note.model';
import { PaymentsComponent } from '../../../base/payments/payments.component';
import { Payment } from '../../../base/payments/payment.model';
import { ContextMenuItemAction } from 'projects/libraries/syslink-components/src/lib/context-menus/context-menu-item-action';
import { ContextMenuItemClickEvent } from 'devextreme/ui/file_manager';
import { PurchaseCreditNoteGridComponent } from '../purchase-credit-note-grid/purchase-credit-note-grid.component';
import { PurchaseContexMenuItemActionCode } from '../../purchase-documents/purchase-documents/purchase-documents.service';
import { PurchaseCreditNoteStatusSelectModalComponent } from '../purchase-credit-note-status/purchase-credit-note-status-select-modal/purchase-credit-note-status-select-modal.component';
import { PurchaseCreditNoteHeaderStatus } from '../purchase-credit-note-header-statuses/purchase-credit-note-header-status.model';
import { PurchaseCreditNoteHeaderHeaderStatusesService } from '../purchase-credit-note-header-header-statuses/purchase-credit-note-header-header-statuses.service';
import { DocumentCreateModalComponent } from '../../../base/documents/document-create-modal/document-create-modal.component';

@Component({
  selector: 'app-purchase-credit-notes',
  templateUrl: './purchase-credit-notes.component.html',
  styleUrls: ['./purchase-credit-notes.component.scss']
})
export class PurchaseCreditNotesComponent extends PageComponent {
  @Input() public filter: string | string[] = "";
  @Input() public canRefresh: boolean = false;


  @Input() public items?: PurchaseCreditNote[];

  // @ViewChild('initModal') initModal?: ModalComponent;
  @ViewChild("paymentModal") paymentModal?: PaymentsComponent;
  @ViewChild('grid') grid?: PurchaseCreditNoteGridComponent;
  @ViewChild('createModal') createModal?: DocumentCreateModalComponent;
  @ViewChild("statusModal") statusModal?: PurchaseCreditNoteStatusSelectModalComponent;

  public purchaseCreditNoteHeader: PurchaseCreditNote = new PurchaseCreditNote();

  public supplierFilter: string | string[] = ["Roles/any(r:r/Code eq 'supplier')"];

  constructor(
    public purchaseCreditNotesService: PurchaseCreditNotesService,
    public override activatedRoute: ActivatedRoute,
    public thirdsService: ThirdsService,
    public override translateService: TranslateService,
    private purchaseCreditNoteHeaderHeaderStatusesService: PurchaseCreditNoteHeaderHeaderStatusesService
  ) { super(); }


  // ContextMenuItemAction
  // ---------------------
  public contextMenuItems: ContextMenuItemAction[] = [
    { code: PurchaseContexMenuItemActionCode.Status, text: "Change status", icon: "edit", onItemClick: (e: any) => this.onMenuItemClicked(e), visible: this.authService.hasPermission('purchases.credit-notes.list.contextMenu.changeStatus') },
    { code: PurchaseContexMenuItemActionCode.payment, text: "Add payment", icon: "money", onItemClick: (e: any) => this.onMenuItemClicked(e), visible: this.authService.hasPermission('purchases.credit-notes.list.contextMenu.addPayment') }
  ];

  // Payment
  // -------
  public paymentElement: Payment[] = [];
  public paymentDocument?: PurchaseCreditNote;


  public onAddButtonClicked() {
    this.createModal?.open();
  };

  @Input() public canDelete: boolean = false;
  @Input() public delete: Function = (e: any) => {
    console.log("DeleteButtonClicked: " + e);
  };

  public onCreateValidated(element: any) {
    if (!element.ThirdId.Id) {
      NotificationsService.sendErrorMessage("Please select third");
      return;
    }
    if (element.ThirdId.Blocked == true) {
      NotificationsService.sendErrorMessage("Third is blocked");
      return;
    }

    this.router.navigateByUrl('/purchases/credit-notes/new?ThirdId=' + element.ThirdId.Id + (element.Subject != undefined ? '&Subject=' + element.Subject : ''));
  }
  // ContextMenu
  // -----------
  public async onMenuItemClicked(e: ContextMenuItemClickEvent) {

    if (!this.grid) return;

    switch (e.itemData.code) {
      case PurchaseContexMenuItemActionCode.Status:
        this.showStatusModal("Would you like change status selected rows?", e.itemData);
        break;
      case PurchaseContexMenuItemActionCode.payment:
        await this.showPaymentModal("Would you like add payment", e.itemData);
        break;
    }
  }

  private showStatusModal(text: string, data: ContextMenuItemAction) {
    if (!this.statusModal) return;

    this.statusModal.title = data.text ? data.text : "Confirm";
    this.statusModal.content = text;
    this.statusModal.open({ rows: this.grid?.getSelectedRows(data.rowData), action: data.code });
  }
  private async showPaymentModal(text: string, data: ContextMenuItemAction) {
    if (this.grid?.getSelectedRows(data.rowData) && this.grid?.getSelectedRows(data.rowData)?.length > 1) {
      NotificationsService.sendErrorMessage("Please select only one line");
      return;
    }
    this.paymentDocument = await this.purchaseCreditNotesService.findByID(this.grid?.getSelectedRows(data.rowData)[0].Id, { expand: this.purchaseCreditNotesService.defaultOptions.expand })
    // TODO add better method
    // Wait component is loaded
    setTimeout(async () => {
      if (!this.paymentModal || !this.paymentDocument) return;
      this.paymentElement = this.paymentDocument.Payments;
      // await this.paymentModal.onAddPaymentClicked();
    }, 100);
  }

  public async onConfirmModalValidated(modalData: { rows: PurchaseCreditNote[], action: string } | any) {
    switch (modalData.action) {
      //    case PurchaseContexMenuItemActionCode.PrintToPDF:
      //   await this.purchaseCreditNotesService.printRows(modalData.rows, "pdf", "PurchaseCreditNote");
      //   break;
      // case PurchaseContexMenuItemActionCode.PrintToZIP:
      //   await this.purchaseCreditNotesService.printRows(modalData.rows, "zip", "PurchaseCreditNote");
      //   break;
      case PurchaseContexMenuItemActionCode.Status:
        await this.updateStatus(modalData.rows, modalData.CreditNoteStatus);
        break;
    }

    this.grid?.refresh();
    this.statusModal?.close();
  }
  // ------------------------------------------------------------------------------------------------

  // Actions
  // -------
  private async updateStatus(selectedRows: PurchaseCreditNote[], status: PurchaseCreditNoteHeaderStatus) {
    if (!this.purchaseCreditNotesService.canUpdateStatus(selectedRows, status)) return;
    selectedRows.sort((a, b) => { return a.Date.getTime() - b.Date.getTime(); });

    for (var element of selectedRows) {
      if (element.Id) {
        const link: any = {
          HeaderId: { Id: element.Id },
          StatusId: { Id: status.Id },
          Date: new Date()
        };
        await this.updateDocumentStatus(link);
      }
    }
    NotificationsService.sendSuccess('Status updated');
  }
  private async updateDocumentStatus(link: any) {
    await this.purchaseCreditNoteHeaderHeaderStatusesService.insert(this.purchaseCreditNoteHeaderHeaderStatusesService.format(link));
  }
}