import { Component, OnInit, ViewChild } from '@angular/core';
import { WorkTimeActionCode, WorkTimesService } from './work-times.service';
import { WorkTime } from './work-time';
import { WorkTimeModalComponent } from './work-time-modal/work-time-modal.component';
import { ConfirmModalComponent, NotificationsService, PageComponent, SchedulerSelectionChangedData, SyslinkToolbarAction, SyslinkToolbarActionButton } from 'projects/libraries/syslink-components/src/public-api';
import { WorkTimeGridComponent } from './work-time-grid/work-time-grid.component';
import { FilterDescriptor } from 'devextreme/data';
import { WorkTimeSchedulerComponent } from './work-time-scheduler/work-time-scheduler.component';
import { ContextMenuItemAction } from 'projects/libraries/syslink-components/src/lib/context-menus/context-menu-item-action';
import { SaleInvoiceSelectModalComponent } from '../../../sales/sale-invoices/sale-invoice-select-modal/sale-invoice-select-modal.component';
import { ContextMenuItemClickEvent } from 'devextreme/ui/file_manager';
import { SaleInvoice } from '../../../sales/sale-invoices/sale-invoices/sale-invoice.model';
import { TaskSelectModalComponent } from '../../tasks/tasks/task-select-modal/task-select-modal.component';
import { Task } from '../../tasks/tasks/task.model';
import { jsonToOdataFormat } from 'projects/libraries/syslink-components/src/lib/helpers/tools';
import { WorkTimeTypeSelectModalComponent } from '../work-time-type-select-modal/work-time-type-select-modal.component';
import { WorkType } from '../../works/work-types/work-type.model';
import { PrivateConfigurationsService } from '../../../base/private-configurations/private-configurations.service';

@Component({
  selector: 'app-work-times',
  templateUrl: './work-times.component.html',
  styleUrls: ['./work-times.component.scss']
})
export class WorkTimesComponent extends PageComponent implements OnInit {
  WorkTimeViewTypeCode = WorkTimeViewTypeCode;

  // View Type
  // ---------
  private defaultViewType: WorkTimeViewType = WorkTimeViewTypeCode.Grid;
  public viewType: WorkTimeViewType = this.defaultViewType;
  // public viewTypeStorageKey: string = "worktimes-viewType";

  // Create/Update Modal
  // --------------------
  @ViewChild('modal') modal?: WorkTimeModalComponent;

  // Task
  // ----
  @ViewChild('taskSelectModal') taskSelectModal?: TaskSelectModalComponent;
  @ViewChild('typeSelectModal') typeSelectModal?: WorkTimeTypeSelectModalComponent;


  // Invoicing
  // --------- 
  @ViewChild('saleInvoiceSelectModal') saleInvoiceSelectModal?: SaleInvoiceSelectModalComponent;
  public saleInvoiceSelectModalFilter: string | string[] = ["CurrentStatusLink.StatusId.Sequence lt 3"];

  // Delete Modal
  @ViewChild("confirmModal") confirmModal?: ConfirmModalComponent;

  // Grid
  // ----
  @ViewChild('grid') grid?: WorkTimeGridComponent;
  @ViewChild('scheduler') scheduler?: WorkTimeSchedulerComponent;
  public globalResult?: { Duration: number, DurationNotBilled: number, BillableDuration: number };

  // Toolbar
  // -------
  public CommonToolbarActions: SyslinkToolbarAction[] = [
    new SyslinkToolbarActionButton({
      code: 'filter',
      icon: 'filter',
      location: 'after'
    })
  ];

  @ViewChild('deleteConfirm') deleteConfirm?: ConfirmModalComponent;

  // Action
  // ------

  public gridToolbarActions: SyslinkToolbarAction[] = [
    new SyslinkToolbarActionButton({
      icon: 'event',
      onClick: () => this.changeViewType(WorkTimeViewTypeCode.Scheduler),
      location: 'after'
    })
  ];

  public contextMenuItems: ContextMenuItemAction[] = [
    {
      text: "Task", icon: "event", items: [
        { code: WorkTimeActionCode.Task, text: "Update task", onItemClick: (e: any) => this.onMenuItemClicked(e) },
      ]
    },
    {
      text: "Invoicing", icon: "money", items: [
        { code: WorkTimeActionCode.Invoice, text: "Invoice it", onItemClick: (e: any) => this.onMenuItemClicked(e), visible: this.authService.hasPermission('time-management.work-times.contextMenu.invoicing') },
        { code: WorkTimeActionCode.MarkAsNotBillable, text: "Mark as not billable", onItemClick: (e: any) => this.onMenuItemClicked(e), visible: this.authService.hasPermission('time-management.work-times.contextMenu.updateInvoicingStatus') },
        { code: WorkTimeActionCode.MarkAsBillable, text: "Mark as billable", onItemClick: (e: any) => this.onMenuItemClicked(e), visible: this.authService.hasPermission('time-management.work-times.contextMenu.updateInvoicingStatus') },
      ], visible: this.authService.hasPermission('time-management.work-times.contextMenu.invoicing') ?? this.authService.hasPermission('time-management.work-times.contextMenu.updateInvoicingStatus')
    },
    { code: WorkTimeActionCode.Type, text: "Update type", icon: "edit", onItemClick: (e: any) => this.onMenuItemClicked(e), visible: this.authService.hasPermission('time-management.work-times.contextMenu.editType') },
    { code: WorkTimeActionCode.Delete, text: "Delete", icon: "trash", onItemClick: (e: any) => this.onMenuItemClicked(e), visible: this.authService.hasPermission('time-management.work-times.contextMenu.delete') }
  ];

  public SchedulerToolbarActions: SyslinkToolbarAction[] = [
    new SyslinkToolbarActionButton({
      icon: 'detailslayout',
      onClick: () => this.changeViewType(WorkTimeViewTypeCode.Grid),
      location: 'after'
    })
  ];

  // public workTimeFilters: FilterDescriptor | Array<FilterDescriptor>;

  constructor(
    public workTimesService: WorkTimesService,
    // public thirdsService: ThirdsService,
    // public tasksService: TasksService,
    // public workTypesService: WorkTypesService,
    // public userGroupsService: UserGroupsService
  ) {
    super();
    // this.gridToolbarActions.push(...this.CommonToolbarActions);
    this.SchedulerToolbarActions.push(...this.CommonToolbarActions);
  }

  override ngOnInit(): void {
    this.inithViewType();
  }

  // Grid
  // ----
  public async onAddButtonClicked() {
    const instance = await this.workTimesService.getInstance();
    this.modal?.open(instance);
  }

  public onRowDoubleClicked(e: WorkTime) {
    this.modal?.open(e);
  }

  // Modal
  // ------
  public async onCreateValidateButtonClicked(e: any) {
    let index = this.grid?.grid?.grid?.instance.getDataSource()?.items().findIndex(item => item.Id === e.Id) ?? 0;
    const columns = this.grid?.grid?.grid?.instance.getVisibleColumns() ?? [];
    columns.forEach(column => {
      const fieldName: string = column.dataField ?? "";
      if (fieldName != "" && e.hasOwnProperty(fieldName)) {
        this.grid?.grid?.grid?.instance.cellValue(index, fieldName, e[fieldName]);
      }
    });
    if (index < 0) {
      this.grid?.refresh();
    }
    this.grid?.grid?.grid?.instance.repaintRows([index]);
    if(this.grid?.grid?.grid?.instance.getCombinedFilter()){
      this.onFilterValueChange(this.grid?.grid?.grid?.instance.getCombinedFilter())
    }

    this.scheduler?.scheduler?.refresh();
  }

  // View Type
  // ---------
  private inithViewType() {
    // const storedViewType = localStorage.getItem(this.viewTypeStorageKey) ?? this.defaultViewType;
    const storedViewType = this.defaultViewType;
    this.viewType = this.isViewTypeAllowed(storedViewType) ? <WorkTimeViewType>storedViewType : this.defaultViewType;
  }

  private changeViewType(viewType: WorkTimeViewType) {
    this.viewType = viewType;
    // localStorage.setItem(this.viewTypeStorageKey, viewType);
  }

  private isViewTypeAllowed(viewType: string) {
    return (<string[]>Object.values(WorkTimeViewTypeCode)).includes(viewType)
  }

  public async onSchedulerSelectionChanged(schedulerSelectDates: SchedulerSelectionChangedData[]) {
    this.modal?.open();
    let startDate;
    let endDate;
    if (schedulerSelectDates?.length > 0) {
      startDate = new Date(Math.min(...schedulerSelectDates.map(e => e.startDate.getTime())));
      endDate = new Date(Math.max(...schedulerSelectDates.map(e => e.endDate.getTime())));
      if (this.modal?.modal?.data) {
        this.modal.modal.data = await this.workTimesService.getInstance({
          StartDate: startDate,
          EndDate: endDate
        });
      }
    }

  }

  public onWorkItemsFiltersPopoverValueChanged(filters: FilterDescriptor | Array<FilterDescriptor>) {
    // this.workTimeFilters = filters;
    // this.grid?.grid?.grid?.instance.refresh();
    // this.scheduler?.scheduler?.refresh();
  }

  // ContextMenu
  // -----------
  public async onMenuItemClicked(e: ContextMenuItemClickEvent) {

    if (!this.grid && !this.scheduler) return;

    switch (e.itemData.code) {
      // Invoicing
      // ---------
      case WorkTimeActionCode.Invoice:
        this.showInvoiceModal("Would you like to invoice selected rows?", e.itemData);
        break;
      case WorkTimeActionCode.MarkAsNotBillable:
        this.showConfirmModal("Would you like to make all selected rows not billable?", e.itemData)
        break;
      case WorkTimeActionCode.MarkAsBillable:
        this.showConfirmModal("Would you like to make all selected rows billable?", e.itemData)
        break;

      // Others
      // ------
      case WorkTimeActionCode.Task:
        this.showTaskSelectModal("Would you like to update task for selected rows?", e.itemData);
        break;
      case WorkTimeActionCode.Type:
        this.showTypeSelectModal("Would you like to update type for selected rows?", e.itemData);
        break;
      case WorkTimeActionCode.Delete:
        this.showConfirmModal("Would you like to delete all selected rows?", e.itemData)
        break;
    }
  }
  private getRows(data: ContextMenuItemAction) {
    if (this.grid) {
      return this.grid?.getSelectedRows(data.rowData)
    }
    else if (this.scheduler) {
      return [data.rowData]
    }
    return [];
  }

  private showConfirmModal(text: string, data: ContextMenuItemAction) {
    if (!this.confirmModal) return;

    this.confirmModal.title = data.text ? data.text : "Confirm";
    this.confirmModal.content = text;
    this.confirmModal.open({ rows: this.getRows(data), action: data.code });
  }
  private showInvoiceModal(text: string, data: ContextMenuItemAction) {
    if (!this.saleInvoiceSelectModal) return;

    this.saleInvoiceSelectModal.title = data.text ? data.text : "Confirm";
    this.saleInvoiceSelectModal.content = text;

    this.saleInvoiceSelectModal.open({ rows: this.getRows(data), action: data.code, thirdId: data.rowData.ThirdId.Id, OpenInNewTab:PrivateConfigurationsService.getPreviewInvoiceInNewTabAfterInvoiceWorkTime() });
    this.saleInvoiceSelectModal.updateFilter();
  }
  private showTaskSelectModal(text: string, data: ContextMenuItemAction) {
    if (!this.taskSelectModal) return;

    this.taskSelectModal.title = data.text ? data.text : "Confirm";
    this.taskSelectModal.content = text;
    this.taskSelectModal.open({ rows: this.getRows(data), action: data.code });
  }
  private showTypeSelectModal(text: string, data: ContextMenuItemAction) {
    if (!this.typeSelectModal) return;

    this.typeSelectModal.title = data.text ? data.text : "Confirm";
    this.typeSelectModal.content = text;
    this.typeSelectModal.open({ rows: this.getRows(data), action: data.code });
  }


  public async onConfirmModalValidated(modalData: { rows: WorkTime[], action: string } | any) {
    switch (modalData.action) {
      // Invoicing
      // ---------
      case WorkTimeActionCode.Invoice:
        await this.invoiceWorkTimes(modalData.rows, modalData.Invoice , modalData.OpenInNewTab);
        // // TODO Revoir la récupération des informations pour faire le repaints
        // this.grid?.refresh()
        break;
      case WorkTimeActionCode.MarkAsBillable:
        modalData.rows = await this.workTimesService.tryUpdateMultiples(modalData.rows, [{ field: 'IsBillable', value: true }]);
        break;
      case WorkTimeActionCode.MarkAsNotBillable:
        modalData.rows = await this.workTimesService.tryUpdateMultiples(modalData.rows, [{ field: 'IsBillable', value: false }]);
        break;

      // Others
      // ------
      case WorkTimeActionCode.Task:
        await this.addTaskToWorkTimes(modalData.rows, modalData.Task);
        break;
      case WorkTimeActionCode.Type:
        await this.updateWorkTimeTypes(modalData.rows, modalData.Type);
        break;
      case WorkTimeActionCode.Delete:
        await this.deleteWorkTimes(modalData.rows);
        break;
    }

    await this.repaintGrid(modalData.rows);


    // this.grid?.refresh();
    this.scheduler?.scheduler?.refresh();
    this.saleInvoiceSelectModal?.close();
    this.taskSelectModal?.close();
    this.confirmModal?.close();
  }
  // ------------------------------------------------------------------------------------------------

  // Repaint
  // -------
  async repaintGrid(rows: WorkTime[]) {
    for (let i = 0; i < rows.length; i++) {
      if (!rows[i].Id) continue;
      const element = await this.workTimesService.findByID(rows[i].Id ?? 0, { expand: ["SaleInvoiceLineId.HeaderId"] });
      let index: any = this.grid?.grid?.grid?.instance.getDataSource()?.items().findIndex(item => item.Id === rows[i].Id) ?? 0;
      if (this.grid && this.grid.grid && this.grid.grid.grid && this.grid.grid.grid.dataSource != undefined) {
        (<any>this.grid.grid.grid.dataSource)._items[index] = element;
        this.grid?.grid?.grid?.instance.cellValue(index, "SaleInvoiceLineId", element.SaleInvoiceLineId);
        this.grid?.grid?.grid?.instance.cellValue(index, "SaleInvoiceLineId.HeaderId", element.SaleInvoiceLineId?.HeaderId);
        this.grid?.grid?.grid?.instance.cellValue(index, "IsBillable", element.IsBillable);
        this.grid?.grid?.grid?.instance.cellValue(index, "IsBilled", element.IsBilled);
        this.grid?.grid?.grid?.instance.repaintRows(index);
      }
    }
    // this.grid?.grid?.grid?.instance.repaint();
  }

  // ------------------------------------------------------------------------------------------------
  // Invoice
  // -------
  private async invoiceWorkTimes(selectedRows: WorkTime[], invoice: SaleInvoice | undefined, OpenInNewTab:boolean) {
    if (!this.workTimesService.can(WorkTimeActionCode.Invoice, selectedRows)) return;

   var result:any =  await this.workTimesService.invoice({
      workTimeIds: selectedRows.map(e => e.Id!),
      invoiceId: invoice?.Id
    });
    NotificationsService.sendSuccess('Invoice created');
    if(OpenInNewTab){
      window.open("/sales/invoices/" + result.saleInvoiceHeaderId, '_blank');
    }
    else{
     this.goToUrl("/sales/invoices/" + result.saleInvoiceHeaderId);
    }
  }

  // Task
  // ----
  private async addTaskToWorkTimes(selectedRows: WorkTime[], task: Task | undefined) {
    if (!this.workTimesService.canTask(selectedRows, task)) return;

    for (const work of selectedRows) {
      if (!work.Id) return;
      work.TaskId = task;
      await this.workTimesService.update(work.Id, this.workTimesService.format(work));
    };
    NotificationsService.sendSuccess('Worktime updated');
  }

  // Type
  private async updateWorkTimeTypes(selectedRows: WorkTime[], type: WorkType | undefined) {
    if (type == undefined) {
      NotificationsService.sendErrorMessage('Type is empty');
      return;
    }

    if (selectedRows?.some(row => row.IsBilled == true)) {
      NotificationsService.sendErrorMessage("One or more selected line(s) are already billed");
      return;
    }

    for (const work of selectedRows) {
      if (!work.Id) return;
      work.TypeId = type;
      await this.workTimesService.update(work.Id, this.workTimesService.format(work));
    };
    NotificationsService.sendSuccess('Worktimes updated');
    this.typeSelectModal?.close();
  }

  public async deleteWorkTimes(selectedRows: WorkTime[]) {
    for (const row of selectedRows) {
      if (row.Id)
        await this.workTimesService.remove(row.Id)
    }
  }

  // Grid
  public async onFilterValueChange(e: any[]) {
    var request = await this.workTimesService.getStatistics(e);
    if (!request) {
      this.globalResult = undefined;
      return;
    }
    this.globalResult = jsonToOdataFormat(request);
  }

  public convertMinuteToTime(minute: number): string {
    return Math.floor((minute / 60)) + ":" + Math.round((minute % 60)).toString().padEnd(2, '0');
  }
}


export enum WorkTimeViewTypeCode {
  Scheduler = 'calendar',
  Grid = 'grid'
}

export type WorkTimeViewType = WorkTimeViewTypeCode.Grid | WorkTimeViewTypeCode.Scheduler;