
<syslink-block [title]="'Mail template'">
    <app-mail-template-grid
    #grid
    (add)="onMailTemplateCreate()"
    (doubleClick)="onMailTemplateEdit($event)"
></app-mail-template-grid>
</syslink-block>

<syslink-modal
    #editModal
    title="Mail templates"
    width="600px"
    (onValidate)="onMailTemplateValidate()"
    validateText="Save"
    cancelText="Cancel"
>
    <syslink-select
        label="Mail template type"
        [store]="mailTemplateTypesService.store"
        [(model)]="editedMailTemplate.MailTemplateTypeId"
        (modelChange)="loadMentions(editedMailTemplate.MailTemplateTypeId?.Code)"
    ></syslink-select>
    <syslink-input-text
        label="Code"
        [(model)]="editedMailTemplate.Code"
    ></syslink-input-text>
    <syslink-input-text
        label="Name"
        [(model)]="editedMailTemplate.Name"
    ></syslink-input-text>
    <div class="row">
        <div class="col-6">
            <syslink-switch
                label="AttachFile"
                [(model)]="editedMailTemplate.AttachFile"
            ></syslink-switch>
        </div>
        <div class="col-11">
            <syslink-wysiwyg
                #wysiwygSubject
                label="Subject"
                [(model)]="editedMailTemplate.Subject"
                height="50px"
                [(mentions)]="mentions"
                [toolbar]="false"
                [maxLength]="10"
            ></syslink-wysiwyg>
        </div>
        <div class="col-1">
            <i
                class="fa-solid fa-circle-info"
                [title]="informationLabel"
            ></i>
        </div>
        <div class="col-11">
            <syslink-wysiwyg
                #wysiwygContent
                label="Content"
                [(model)]="editedMailTemplate.Content"
                height="300px"
                [(mentions)]="mentions"
            ></syslink-wysiwyg>
        </div>
        <div class="col-1">
            <i
                class="fa-solid fa-circle-info"
                [title]="informationLabel"
            ></i>
        </div>
    </div>
</syslink-modal>