<syslink-toolbar
  [actions]="toolbarActions"
  class="me-3"
  [withLine]="false"
></syslink-toolbar>

<div class="row">
  <div class="col-sm-9 col-xs-12">
    <!-- Toolbar -->

    <syslink-block
      [title]="formattedTitle"
      [translateTitle]="false"
    >
      <!-- Third -->
      <div class="col-sm-5 col-xs-12">
        <app-third-form
          [(element)]="element"
          (thirdRolesChange)="onThirdRoleChanged($event)"
          (isProfessionalChange)="onIsProfessionalChanged($event)"
        ></app-third-form>
      </div>
      <div class="mt-4">
        <syslink-tabs
          #infoTabs
          (selectedIdChange)="onChangeSelectedId($event)"
        >
          <syslink-tab
            [tabData]="tabsData[0]"
            [visible]="
              authService.hasPermission(
                this.newBasePermissionKey + '.private.tab.view'
              ) && !element.IsProfessional
            "
          >
            <app-third-private-form
              *ngIf="element && tabsData[0].loaded"
              [(element)]="element"
              [disabled]="isAutocompleteLoading"
            ></app-third-private-form>
          </syslink-tab>

          <syslink-tab
            [tabData]="tabsData[1]"
            [visible]="
              authService.hasPermission(
                this.newBasePermissionKey + '.professionnal.tab.view'
              ) && (element.IsProfessional ?? false)
            "
          >
            <app-third-professional-form
              *ngIf="tabsData[1].loaded"
              [(element)]="element"
              [disabled]="isAutocompleteLoading"
              [isAutocompleteLoading]="isAutocompleteLoading"
              (onVatChange)="getThirdDataSummaryFromVat()"
              [vatDisabled]="isAutocompleteLoading"
            ></app-third-professional-form>
          </syslink-tab>
          <syslink-tab
            [tabData]="tabsData[2]"
            [visible]="
              authService.hasPermission(
                this.newBasePermissionKey + '.customer.tab.view'
              ) &&
              element.Roles != null &&
              hasCustomerRole()
            "
          >
            <app-customer-form
              *ngIf="element.CustomerId && tabsData[2].loaded"
              [(element)]="element.CustomerId"
            ></app-customer-form>
          </syslink-tab>
          <syslink-tab
            [tabData]="tabsData[3]"
            [visible]="
              authService.hasPermission(
                this.newBasePermissionKey + '.supplier.tab.view'
              ) &&
              element.Roles != null &&
              hasSupplierRole()
            "
          >
            <app-supplier-form
              *ngIf="element.SupplierId && tabsData[3].loaded"
              [(element)]="element.SupplierId"
            ></app-supplier-form>
          </syslink-tab>
          <syslink-tab [tabData]="tabsData[4]" [visible]="authService.hasPermission(
            this.newBasePermissionKey + '.finance.tab.view'
          )">
            <app-third-finance-form
              *ngIf="tabsData[4].loaded"
              [(element)]="element"
            ></app-third-finance-form>
          </syslink-tab>
          <syslink-tab
            [tabData]="tabsData[5]"
            [visible]="
              authService.hasPermission(
                this.newBasePermissionKey + '.user.tab.view'
              ) &&
              element.Roles != null &&
              hasUserRole()
            "
          >
            <ng-container *ngIf="tabsData[5].loaded">
              <syslink-block
                [title]="'User informations'"
                [titleBgColor]="'gray'"
                [titleTextColor]="'white'"
                [bordered]="true"
                [titlePadding]="2"
              >
                <div class="row">
                  <div class="col-1"></div>
                  <div class="col-11">
                    <syslink-select
                      [store]="usersService.store"
                      displayKey="Name"
                      [(model)]="element.UserId"
                      [allowClearing]="true"
                      [expand]="['LanguageId']"
                    ></syslink-select>
                  </div>
                </div>
                <app-user-form
                  *ngIf="element.UserId"
                  [(user)]="element.UserId"
                ></app-user-form>
              </syslink-block>
            </ng-container>
          </syslink-tab>
          <syslink-tab
            [tabData]="tabsData[6]"
            [visible]="
            authService.hasPermission(
              this.newBasePermissionKey + '.statistics.tab.view'
            ) &&
            element.Roles != null &&
            hasUserRole()
          "
          >
            <ng-container *ngIf="tabsData[6].loaded">
              <app-third-statistics [element]="element"></app-third-statistics>
            </ng-container>
          </syslink-tab>
        </syslink-tabs>
      </div>
    </syslink-block>
  </div>

  <div class="col-sm-3 col-xs-12">
    <syslink-block
      *ngIf="element.HasCustomerRole"
      [title]="'Totals'"
      [titleSize]="6"
    >
      <app-third-total [(element)]="element"></app-third-total>
    </syslink-block>

    <syslink-block
      [title]="'Contacts'"
      class="{{element.HasCustomerRole?'ms-3':''}}"
      [titleSize]="6"
    >
      <app-third-contacts [(element)]="element"></app-third-contacts>
    </syslink-block>

    <syslink-block
      [title]="'Relations'"
      class="ms-3"
    >
      <app-third-relations [(element)]="element"></app-third-relations>
    </syslink-block>

    <syslink-block
      [title]="'References'"
      [titleSize]="6"
      class="ms-3"
    >
      <app-third-reference-form [(element)]="element"></app-third-reference-form>
    </syslink-block>
    <syslink-block
      [title]="'Documents'"
      *ngIf="element.Id"
      [titleSize]="6"
      class="ms-3"
    >
      <app-third-document-relations [element]="element"></app-third-document-relations>
    </syslink-block>
  </div>
</div>

<!-- Modal -->
<syslink-confirm-modal
  #deleteConfirm
  title="Delete"
  content="DeleteConfirmMessage"
  cancelText="Cancel"
  validateText="Confirm"
  (onValidate)="delete($event)"
></syslink-confirm-modal>

<syslink-third-modal
  #thirdModaltransfert
  content="Transfer document(s) to another third"
  [store]="thirdsService.store"
  (onValidate)="thirdModaltransfertValidate($event)"
  [filter]="thirdModaltransfertFilter"
  [extraFields]="thirdModaltransfertFields"
></syslink-third-modal>

<!-- Modification modal -->
<syslink-modal
  #updateThirdAutocomplete
  title="Select the data"
  width="1200px"
  height="700px"
  (onValidate)="onModalModificationReplace()"
  validateText="Validate"
>
  <app-third-vies-form
    *ngIf="thirdAutocompleted && thirdAutocompleted.Name"
    [(currentElement)]="element"
    [(newElement)]="thirdAutocompleted"
    [(usedCurrentCountry)]="usedCurrentCountry"
    [(usedCurrentDenomination)]="usedCurrentDenomination"
    [(usedCurrentLegalForm)]="usedCurrentLegalForm"
  ></app-third-vies-form>
</syslink-modal>

<!-- CreditSafe -->
<app-creditsafe-modal
  #creditsafeModal
  [element]="creditSafeElement"
  [vatNumber]="element.VatNumber"
></app-creditsafe-modal>

<syslink-confirm-modal
  #confirmSaveModal
  title="Save"
  content="This firstname and lastname already exist. Please confirm save?"
  (onValidate)="save(false)"
  validateText="Confirm"
  cancelText="Cancel"
></syslink-confirm-modal>
