import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SyslinkColumn } from 'projects/libraries/syslink-components/src/lib/helpers/SyslinkColumn';
import { TaxRatesService } from '../../../base/tax-rates/tax-rates.service';
import { CustomersService } from '../../../thirds/customers/customers/customers.service';
import { PriceListsService } from '../../../thirds/customers/price-lists/price-lists.service';
import { ProductCategoriesService } from '../../product-categories/product-category.service';
import { CustomerCatalog } from '.././customer-catalog.model';
import { BaseGridComponent } from 'projects/libraries/syslink-components/src/lib/grid/base-grid/base-grid.component';

@Component({
  selector: 'app-customer-catalog-grid',
  templateUrl: './customer-catalog-grid.component.html',
  styleUrls: ['./customer-catalog-grid.component.scss']
})
export class CustomerCatalogGridComponent  extends BaseGridComponent<CustomerCatalog>  {
  override storageKey?: string | undefined = "grid-products-sale-customer-catalog";
  @Input() public customerCatalogs: Array<CustomerCatalog> = [];
  @Output() public customerCatalogsChange: EventEmitter<Array<CustomerCatalog>> = new EventEmitter<Array<CustomerCatalog>>();


  public columns: SyslinkColumn[] = [
    new SyslinkColumn({ field: "ProductId", label: "", type: "boolean", cellTemplate: "icon-cell", editCellTemplate: "icon-cell", data: { icon: "fa-link", activeClass: "cl-accent", inactiveClass: "cl-gray" }, width: 60 }),
    new SyslinkColumn({ field: "CustomerId", label: "Customer", cellTemplate: 'select-value', editCellTemplate: "select-cell", data: { service: this.customersService, expand: "ThirdId", displayKey: 'ThirdId.Fullname' } }),
    new SyslinkColumn({ field: "PriceListId", label: "Price list", cellTemplate: 'select-value', editCellTemplate: "select-cell", data: { service: this.priceListsService, displayKey: 'Name' } }),
    new SyslinkColumn({ field: "ProductCategoryId", label: "ProductCategory", cellTemplate: 'select-value', editCellTemplate: "select-cell", editable: false, data: { service: this.productCategoriesService, displayKey: 'Name' } }),
    new SyslinkColumn({ field: "MarginId", label: "Discount margin", cellTemplate: "object-discount-cell", editCellTemplate: 'edit-object-discount-cell', data: { discountTypeField: "IsDiscountFixed" }, width: 160 }),
    new SyslinkColumn({ field: "DiscountId", label: "Discount", cellTemplate: "object-discount-cell", editCellTemplate: 'edit-object-discount-cell', data: { discountTypeField: "IsDiscountFixed" }, width: 160 }),
    // new SyslinkColumn({ field: "PenaltyId", label: "Penalty", cellTemplate: "object-discount-cell", editCellTemplate: 'edit-object-discount-cell', data: { discountTypeField: "IsDiscountFixed" }, width: 160 }),
    new SyslinkColumn({ field: "ExTaxNetPrice", label: "Net price excl. VAT", type: "number", editable: false, data: { key: 'TotalPrecision' }, cellTemplate: 'currency-cell', width: 80 }),
  ];

  constructor(
    public taxRatesService: TaxRatesService,
    public customersService: CustomersService,
    public priceListsService: PriceListsService,
    public productCategoriesService: ProductCategoriesService,
    public translateService: TranslateService
  ) {
    super();
  }
}
