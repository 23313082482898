import { Injectable } from '@angular/core';
import { LoadOptions } from 'devextreme/data';
import { SaleDocumentHeaderStatusesService } from '../../sale-documents/sale-document-header-status/sale-document-header-statuses.service';
import { SaleOrderHeaderHeaderStatus } from './sale-order-header-header-status.model';

@Injectable({
  providedIn: 'root'
})
export class SaleOrderHeaderHeaderStatusesService extends SaleDocumentHeaderStatusesService<SaleOrderHeaderHeaderStatus> {
  public override url: string = 'SaleOrderHeaderHeaderStatus';
  public override defaultOptions: LoadOptions = {
    expand: ["StatusId"]
  };
  public override format(element: Partial<SaleOrderHeaderHeaderStatus>): SaleOrderHeaderHeaderStatus {
    var result: any = {
      ...element,
      HeaderId:element.HeaderId?.Id,
      StatusId:element.StatusId?.Id
    };
    return result;
  }

  public override async getInstance(params?: Partial<SaleOrderHeaderHeaderStatus>): Promise<SaleOrderHeaderHeaderStatus> {
    var element = new SaleOrderHeaderHeaderStatus({
      Date: new Date(),
      ...params
    });
    return element;
  }
}
