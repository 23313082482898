import { SaleDocumentHeaderStatus } from "../../sale-documents/sale-document-header-status/sale-document-header-status.model";
import { SaleQuoteHeaderStatus } from "../sale-quote-header-statuses/sale-quote-header-status.model";
import { SaleQuote } from "../sale-quotes/sale-quote.model";

export class SaleQuoteHeaderHeaderStatus extends SaleDocumentHeaderStatus {
    public override HeaderId?: SaleQuote;
    public override StatusId?: SaleQuoteHeaderStatus = new SaleQuoteHeaderStatus();

    constructor(entity?: Partial<SaleQuoteHeaderHeaderStatus>) {
        super();
        Object.assign(this, entity);
    }

}