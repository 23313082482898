import { Module } from "../../../base/modules/modules/module.model";
import { SubModule } from "../../../base/modules/sub-modules/sub-module.model";
import { BasicEntity } from "../../models/basic-entity";
import { UserGroup } from "../user-groups/user-group.model";
import { UserPermission } from "../user-permissions/user-permission.model";
import { User } from "../users/user.model";

export class ActionGroup extends BasicEntity {

    public ModuleId?:Module;
    public SubModuleId?:SubModule;
    
    public Enabled?:boolean;
    
    public Users?:User[];
    public UserGroups?:UserGroup[];
    public Permissions:UserPermission[]=[];

    constructor(element?: Partial<ActionGroup>) {
        super();
        Object.assign(this, element);
    }
}
