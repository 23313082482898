import { Component, Input } from '@angular/core';
import { Third } from '../third.model';
import { BaseGridComponent } from 'projects/libraries/syslink-components/src/lib/grid/base-grid/base-grid.component';
import { SyslinkColumn } from 'projects/libraries/syslink-components/src/lib/helpers/SyslinkColumn';
import { ThirdsService } from '../thirds.service';
import { BooleanCellData } from 'projects/libraries/syslink-components/src/lib/grid/cells/boolean-cell/boolean-cell.component';

@Component({
  selector: 'app-third-grid',
  templateUrl: './third-grid.component.html',
  styleUrls: ['./third-grid.component.scss']
})
export class ThirdGridComponent extends BaseGridComponent<Third> {
  @Input() storageKey: string = 'grid-thirds';

  @Input() public columns: SyslinkColumn[] = [
    new SyslinkColumn({ field: 'No', label: 'Id', width: 80, sortIndex: 1, sortDirection: 'desc' }),
    new SyslinkColumn({ field: 'Fullname', label: 'fullname' }),
    new SyslinkColumn({ field: 'DisplayAddress', label: 'Adresse' }),
    new SyslinkColumn({ field: 'DisplayPhoneNumber', label: 'Phone number', width: 200 }),
    new SyslinkColumn({ field: 'DisplayEmail', label: 'Professional email', width: 200 }),
    new SyslinkColumn({ field: 'VatNumber', label: 'Vat number', width: 120 }),
    new SyslinkColumn({ field: 'Alias', label: 'Alias' }),
    new SyslinkColumn({ field: 'Blocked', label: 'Blocked', type: 'boolean', cellTemplate: 'boolean-cell', data: new BooleanCellData({ type: 'box', trueColor: 'danger', falseColor: "success" }), width: 100 }),
    new SyslinkColumn({ field: 'AccountingBOBReference', label: 'BOB reference', visible:false }),
    new SyslinkColumn({ field: 'Id', technical: true }),
  ];

  constructor(
    public thirdsService: ThirdsService,
  ) {
    super();
  }
}
