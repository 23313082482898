import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { DxTreeListComponent } from 'devextreme-angular';
import ODataStore from 'devextreme/data/odata/store';
import { EditingStartEvent, FocusedCellChangingEvent, Properties, RowDblClickEvent, RowInsertedEvent, RowInsertingEvent, RowPreparedEvent, RowRemovedEvent, RowRemovingEvent, RowUpdatedEvent, RowUpdatingEvent, SelectionChangedEvent, ToolbarItem } from 'devextreme/ui/tree_list';
import { TreelistService } from './treelist.service';
import { ToolbarService } from '../toolbar/toolbar.service';
import ArrayStore from 'devextreme/data/array_store';
import { AppInjectorService } from '../services/app-injector.service';
import { TranslationsModalComponent } from '../grid/edit-cells/translations-modal/translations-modal.component';
import { ActivatedRoute, Router } from '@angular/router';
import { SyslinkColumn } from '../helpers/SyslinkColumn';
import { SyslinkDataSource } from '../helpers/SyslinkDataSource';
import { fromDotNotation } from '../helpers/tools';
import { CrudComponent } from '../helpers/crud-component/crud-component.component';
import { SyslinkToolbarAction } from '../toolbar/toolbar.component';

@Component({
  selector: 'syslink-treelist',
  templateUrl: './treelist.component.html',
  styleUrls: ['./treelist.component.scss']
})
export class TreelistComponent extends CrudComponent implements OnInit {
  // DataSource
  // ----------
  @Input() public items?: any[];
  @Output() public itemsChange: EventEmitter<any[]> = new EventEmitter<any[]>();
  @Input() public store?: ODataStore | ArrayStore;
  @Input() public parentIdExpr: string = 'ParentId';
  @Input() public hasItemsExpr: string = 'HasChildren';
  @Input() public remoteOperations: string | { filtering?: boolean | undefined; grouping?: boolean | undefined; sorting?: boolean | undefined; } = 'auto';
  public dataSource: SyslinkDataSource = new SyslinkDataSource({});

  // Toolbar
  // -------
  // @Input() public toolbarActions: SyslinkToolbarAction[] = [];
  private _toolbarActions: SyslinkToolbarAction[] = [];
  @Input() set toolbarActions(value: SyslinkToolbarAction[]) {
    this._toolbarActions = value;
    this.initToolbar();
  }
  get toolbarActions(): SyslinkToolbarAction[] { return this._toolbarActions; };
  private toolbarItems: Array<ToolbarItem> = [];

  // Columns
  // -------
  @Input() public columns: SyslinkColumn[] = [];
  public getColumn(field: string): SyslinkColumn {
    return this.columns.find(column => column.field == field)!;
  }

  // Options
  // -------
  @Input() public options: Properties = {};
  @Input() public exportFileName: string = '';
  @Input() public key: string = 'Id';

  // Drag and drop
  // -------------
  @Input() public allowDropInsideItem: boolean = true;
  @Input() public allowReordering: boolean = true;
  @Input() public showDragIcons: boolean = true;
  @Input() public allowColumnReordering: boolean = true;
  @Output() public onReorder: EventEmitter<any> = new EventEmitter<any>();

  // State Storing
  // -------------
  @Input() public stateStoringEnabled: boolean = true;

  // Actions
  // -------
  @Input() public canEdit: boolean = true;
  @Input() public detailsUrl: string | 'self' = 'self';
  @Input() public canCustomize: boolean = true;

  // Events
  // ------
  @Output() onRowPrepared: EventEmitter<RowPreparedEvent> = new EventEmitter<RowPreparedEvent>();
  @Output() onBeforeInsert: EventEmitter<RowInsertingEvent> = new EventEmitter<RowInsertingEvent>();
  @Output() onAfterInsert: EventEmitter<RowInsertedEvent> = new EventEmitter<RowInsertedEvent>();
  @Output() onBeforeUpdate: EventEmitter<RowUpdatingEvent> = new EventEmitter<RowUpdatingEvent>();
  @Output() onAfterUpdate: EventEmitter<RowUpdatedEvent> = new EventEmitter<RowUpdatedEvent>();
  @Output() onBeforeDelete: EventEmitter<RowRemovingEvent> = new EventEmitter<RowRemovingEvent>();
  @Output() onAfterDelete: EventEmitter<RowRemovedEvent> = new EventEmitter<RowRemovedEvent>();
  @Output() onEditingStart: EventEmitter<EditingStartEvent> = new EventEmitter<EditingStartEvent>();
  @Output() onFocusedCellChanging: EventEmitter<FocusedCellChangingEvent> = new EventEmitter<FocusedCellChangingEvent>();

  @Output() doubleClick: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild('treelist') public treelist?: DxTreeListComponent;

  // Selection
  // ---------
  @Input() selectedKeys: number[] = [];
  @Input() selectedItems: any[] = [];
  @Output() selectedItemsChange: EventEmitter<any[]> = new EventEmitter<any[]>();
  @Output() selectedKeysChange: EventEmitter<number[]> = new EventEmitter<number[]>();
  @Output() selectionChanged: EventEmitter<SelectionChangedEvent> = new EventEmitter<SelectionChangedEvent>();

  @Output() refresh: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private treelistService: TreelistService,
    private toolbarService: ToolbarService,
    private activatedRoute: ActivatedRoute
  ) {
    super();
    this.stateStoringEnabled = AppInjectorService.config.useStateStoring;
    this.onReorderEvent = this.onReorderEvent.bind(this);
  }

  override ngOnInit(): void {
    this.columns.sort((a, b) => a.order - b.order);
    this.initDatasource();
    this.initOptions();
  }

  override ngAfterViewInit(): void {
    this.updateOptions(this.options);
  }

  private initDatasource() {
    this.store = this.items ? new ArrayStore({
      key: this.key,
      data: this.items ?? []
    }) : this.store;

    this.dataSource = new SyslinkDataSource({
      store: this.store,
      expand: this.expand,
      filter: this.filter,
      select: this.select,
    });
  }

  private initOptions() {
    this.options = this.treelistService.getConfiguration(this.options);
    this.initToolbar();
  }


  public initToolbar() {
    if (!this.options.toolbar) return;

    this.toolbarItems = [];

    // Custom toolbarActions of before location
    // ----------------------------------------
    this.toolbarActions
      .filter((e) => e.location == 'before' && e.visible)
      .forEach((toolbarAction) => {
        this.toolbarItems.push(ToolbarService.generateToolbarItem(toolbarAction));
      });

    // Custom toolbarActions of center location
    // ----------------------------------------
    this.toolbarActions
      .filter((e) => e.location == 'center' && e.visible)
      .forEach((toolbarAction) => {
        this.toolbarItems.push(ToolbarService.generateToolbarItem(toolbarAction));
      });

    // AFTER LOCATION
    // -------------
    this.toolbarItems.push({
      name: 'columnChooserButton',
      location: 'after',
      options: { elementAttr: { id: 'columnChooser' } },
    });

    // Custom toolbarActions of after location
    // ---------------------------------------
    this.toolbarActions
      .filter((e) => e.location == 'after' && e.visible)
      .forEach((toolbarAction) => {
        this.toolbarItems.push(ToolbarService.generateToolbarItem(toolbarAction));
      });

    // Custom toolbarActions in menu location
    // --------------------------------------
    this.toolbarActions
      .filter((e) => e.inMenu == 'always' && e.visible)
      .forEach((toolbarAction) => {
        this.toolbarItems.push(ToolbarService.generateToolbarItem(toolbarAction));
      });

    this.options.toolbar.items = this.toolbarItems;

    this.treelist?.instance.option('toolbar.items', this.toolbarItems);
  }

  public onRowDblClick(event: RowDblClickEvent) {
    if (!this.canDblClck) return;
    if (this.doubleClick.observed) {
      this.doubleClick.emit(event.data);
    }
    else {
      if (!this.detailsUrl) return;

      if (this.detailsUrl === 'self') {
        this.router.navigate([event.data.Id], { relativeTo: this.activatedRoute });
      } else {
        this.router.navigateByUrl(this.detailsUrl + '/' + event.data.Id);
      }
    }
  }

  // Translations
  // ------------
  @Input() public languages: any[] = [];
  @ViewChild('translationsCellModal') public translationsCellModal?: TranslationsModalComponent;

  public onTranslationsBtnClicked(translations: any, cell: any): void {
    if (this.translationsCellModal) {
      this.translationsCellModal.open(translations, cell);
    }
  }

  public onTranslationEdit(value: string, cell: any): void {
    // if (!cell.row.isNewRow) {
    //   const translation = cell.data.NameTranslationId.Translations.find((translation: any) => translation.LanguageId.IsoCode === 'fr');
    //   this.grid?.instance.option('editing.changes', [{ data: { NameTranslationId: { Translations: [{ Id: translation.Id, Value: value }] } }, key: cell.key, type: 'update' }]);
    // } else {
    //   cell.setValue(value);
    // }
  }

  public onTranslationsModalValidated(translations: any): void {
    this.treelist?.instance.option('editing.changes', [{ data: { NameTranslationId: { Translations: translations } }, key: this.translationsCellModal?.modal.data.cell.key, type: 'update' }])
    this.treelist?.instance.saveEditData();
    this.treelist?.instance.refresh();
  }

  private updateOptions(options: Properties) {
    this.treelist?.instance.option(options);
  }

  public getSelectValue(data: any, value: any) {
    if (value)
      return value[data.valueKey];
    return null;
  }
  public onReorderEvent(e: any) {
    e.promise = this.onReorderAsync(e);
  }

  public async onReorderAsync(e: any) {
    this.onReorder.emit(e);
  }

  // FromDotNotation
  // ---------------
  public fromDotNotation(cell: any, key: string) {
    if (cell.value) {
      return fromDotNotation(cell.value, key);
    }
    return null;
  }

  // Object Discount Cell
  // --------------------
  public isNewObjectDiscountCell(cell: any): boolean {
    return cell.value && this.getColumn(cell.column.dataField).data && this.getColumn(cell.column.dataField).data.discountTypeField
  }
  
  public getPrecisionWithKey(key:string){
    return AppInjectorService.config.getPrecision( key);
  }

  public onObjectDiscountCellUpdated(newValue: number, cell: any, idFixed: boolean) {
    cell.setValue({ ...cell.value, Value: idFixed ? newValue : Number(newValue / 100).toFixed(2) });
  }

  public onObjectDiscountTypeChange(type: number, cell: any, field: string) {
    cell.setValue({ ...cell.value, [field]: type ? true : false, Value: 0 });
  }

  public onSelectionChanged(e: SelectionChangedEvent) {
    this.selectedItems = e.selectedRowsData;
    if (this.selectedItemsChange.observed) this.selectedItemsChange.emit(this.selectedItems);
    if (this.selectedKeysChange.observed) this.selectedKeysChange.emit(e.selectedRowKeys);
    if (this.selectionChanged.observed) this.selectionChanged.emit(e);

  }

  // State storing
  // -------------
  public saveState(state: any) {
    AppInjectorService.config.updateStateStoring(this.storageKey, state);
  }
  public loadState(): Promise<any> {
    const state = localStorage.getItem(this.storageKey ?? "");
    return Promise.resolve(state ? JSON.parse(state) : {});
  }
}
