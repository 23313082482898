<syslink-block [title]="'Sale contracts'">
  <ng-container *ngIf="globalResult">
    <div class="row gap-2">
      <div class="offset-5 col-1 rounded text-center border border-dark">
        <div class="h-100 d-flex flex-column justify-content-between">
          <p class="h6 fw-bold my-2"> {{'Monthly amount' | translate}}</p>
          <div>
            <p class="my-1">{{globalResult.MonthlyExTaxTotal | syslinkCurrency:"TotalPrecision"}} H.T.V.A</p>
            <p class="my-1">{{globalResult.MonthlyInTaxTotal | syslinkCurrency:"TotalPrecision"}} T.T.C</p>
          </div>
        </div>
      </div>
      <div class="col-1 rounded text-center border border-dark">
        <div class="h-100 d-flex flex-column justify-content-between">
          <p class="h6 fw-bold my-2">{{'Annual amount' | translate}}</p>
          <div>
            <p class="my-1">{{globalResult.AnnualExTaxTotal | syslinkCurrency:"TotalPrecision"}} H.T.V.A</p>
            <p class="my-1">{{globalResult.AnnualInTaxTotal | syslinkCurrency:"TotalPrecision"}} T.T.C</p>
          </div>
        </div>
      </div>
    </div>
  </ng-container>

  <app-sale-contract-grid
    #grid
    [permissionKey]="'list'"
    [canDelete]="false"
    [items]="items"
    [filter]="filter"
    (add)="onAddButtonClicked()"
    (onFilterValueChange)="onFilterValueChange($event)"
  ></app-sale-contract-grid>
</syslink-block>

<app-document-create-modal
  #initModal
  title="Create Contract"
  [thirdFilter]="customerFilter"
  detailsUrl="/thirds/customers/"
  (validate)="onCreateValidated($event)"
></app-document-create-modal>
