import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ViewComponent } from 'projects/libraries/syslink-components/src/lib/helpers/view/view.component';
import { Product } from '../product.model';

@Component({
  selector: 'app-product-summary',
  templateUrl: './product-summary.component.html',
  styleUrls: ['./product-summary.component.scss']
})
export class ProductSummaryComponent  extends ViewComponent {
  @Input() public element: Product = new Product();
  @Output() public elementChange: EventEmitter<Product> = new EventEmitter<Product>();

  // Get purchase price status
  // -------------------------
  public getPurchasePriceStatus() : string {
    if (this.element.SupplierCatalogs?.length == 1)
    return "middle";
    if (this.element.SupplierCatalogs && this.element.DefaultSupplierCatalogId && this.element.DefaultSupplierCatalogId.ExTaxNetPrice) {
      const basePrice = this.element.DefaultSupplierCatalogId.ExTaxNetPrice;
      let catalogs = this.element.SupplierCatalogs.filter((e: any) => e.ExTaxNetPrice > basePrice)
      if (catalogs.length == 0) {
        return "worst";
      }
      catalogs = this.element.SupplierCatalogs.filter((e: any) => e.ExTaxNetPrice <= basePrice)
      if (catalogs.length == 1)
      return "best";
    }
    return "middle";
  }
}
