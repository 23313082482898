import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ModificationService {
   static modificationDetected: boolean = false;

   get hasModifications(): boolean {
     return ModificationService.modificationDetected;
   }

   setModifications(status: boolean): void {
     ModificationService.modificationDetected = status;
     console.log(ModificationService.modificationDetected);
   }

   static setModificationGuard(status: boolean): boolean {
     ModificationService.modificationDetected = status;
     return status;
   }
}
