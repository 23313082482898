import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { ModalComponent } from 'projects/libraries/syslink-components/src/public-api';
import { ProductCategory } from '../../product-categories/product-category.model';
import { ProductCategoriesService } from '../../product-categories/product-category.service';
import { Product } from '../product.model';
import { ProductsService } from '../product.service';
import { ViewComponent } from 'projects/libraries/syslink-components/src/lib/helpers/view/view.component';

@Component({
  selector: 'app-product-form',
  templateUrl: './product-form.component.html',
  styleUrls: ['./product-form.component.scss']
})
export class ProductFormComponent  extends ViewComponent {
  @Input() public element: Product = new Product();
  @Output() public elementChange: EventEmitter<Product> = new EventEmitter<Product>();
  @Output() public productPurchasableChanged: EventEmitter<number> = new EventEmitter<number>();

  public categories: ProductCategory[] = [];
  @ViewChild('changeAccountConfirm') changeAccountConfirm: ModalComponent = new ModalComponent();
  constructor(
    public productCategoriesService: ProductCategoriesService,
    public productsService: ProductsService
  ) {
    super();
  }

  public onChangeCategory(productCategoryId: ProductCategory) {
    if (!productCategoryId || productCategoryId == this.element.ProductCategoryId) return;
    
    this.element.ProductCategoryId = productCategoryId;
    this.changeAccountConfirm.open();
  }

  public async onChangeAccount() {
    this.changeAccountConfirm.close();
    if (!this.element.ProductCategoryId.Id) return;
    let productCategory: ProductCategory = await this.productCategoriesService.findByID(this.element.ProductCategoryId.Id);
    this.element.SaleAccountId = productCategory.SaleAccountId;
    this.element.PurchaseAccountId = productCategory.PurchaseAccountId;
  }
}
