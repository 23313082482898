import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { Task } from '../task.model';
import { ViewComponent } from 'projects/libraries/syslink-components/src/lib/helpers/view/view.component';
import { TaskParticipant } from '../../task-participants/task-participant.model';
import { ConfirmModalComponent, ModalComponent, NotificationsService } from 'projects/libraries/syslink-components/src/public-api';
import { UsersService } from 'projects/erp-app/src/app/core/auth/users/users.service';
import { UserGroupsService } from 'projects/erp-app/src/app/core/auth/user-groups/user-groups.service';
import { User } from 'projects/erp-app/src/app/core/auth/users/user.model';
import { UserGroup } from 'projects/erp-app/src/app/core/auth/user-groups/user-group.model';
import { TasksService } from '../tasks.service';
import { TaskParticipantModalComponent } from './task-participant-modal/task-participant-modal.component';
import { AppInjectorService } from 'projects/libraries/syslink-components/src/lib/services/app-injector.service';

@Component({
  selector: 'app-task-participant',
  templateUrl: './task-participant.component.html',
  styleUrls: ['./task-participant.component.scss']
})
export class TaskParticipantComponent extends ViewComponent {
  @Input() public element: Task = new Task();
  @Output() public elementChange: EventEmitter<Task> = new EventEmitter<Task>();

  @Input() disabled: boolean = false;

  @ViewChild('deleteConfirm') public deleteConfirm: ConfirmModalComponent = new ConfirmModalComponent();
  @ViewChild('editParticipantModal') public editParticipantModal: ModalComponent = new ModalComponent();
  @ViewChild('taskParticipantModal') public taskParticipantModal: TaskParticipantModalComponent = new TaskParticipantModalComponent();

  public userIsLoaded: boolean = false;
  public userAndUserGroupItems?: any;// [{ userName: string | undefined, userId: any, isUser: boolean }];

  public participant: TaskParticipant = new TaskParticipant();

  constructor(
    public usersService: UsersService,
    public userGroupsService: UserGroupsService,
    private tasksService: TasksService
  ) {
    super();
  }

  // Load user and User group list
  public async loadUsers() {
    this.userAndUserGroupItems = [];
    var users = await this.usersService.load({ filter: ["IsActive eq true"] });
    var userGroups = await this.userGroupsService.load({ filter: ["IsActive eq true"], expand: ['NameTranslationId.Translations.LanguageId'] });

    users.forEach((u: User) => {
      this.userAndUserGroupItems?.push({ Id: this.userAndUserGroupItems.length + 1, 'FormattedName': u.Name, user: u, isUser: true });
    })
    userGroups.forEach((u: UserGroup) => {
      this.userAndUserGroupItems?.push({ Id: this.userAndUserGroupItems.length + 1, 'FormattedName': u.Name, user: u, isUser: false });
    })
    this.userIsLoaded = true;
  }

  public async initModalDatas(participant?: TaskParticipant) {
    this.participant = new TaskParticipant({ ...participant, TaskId:this.element }) ?? await this.tasksService.getInstance(this.participant);
  }

  // Adding
  public async onAddNewParticipantBtnClicked() {
    if (!this.userIsLoaded) {
      await this.loadUsers();
    }
    await this.initModalDatas();
    this.editParticipantModal.open({ newItem: true, selectedUser: undefined });
  }
  public onCreate(itemId: number) {
    var item = this.userAndUserGroupItems.find((i:any)=>i.Id == itemId);
    var e:any = new TaskParticipant({
      IsUser: item.isUser,
      FormattedName: item.FormattedName,
      BackgroundColor: item.user.BackgroundColor,
      ForegroundColor: item.user.ForegroundColor,
      UserId: item.isUser ? item.user : undefined,
      UserGroupId: !item.isUser ? item.user : undefined,
      TaskId:this.element
    });
    
    if (!this.canCreateOrUpdate(e)) return;
    // this.createOrUpdateVerification(e);
    this.element.TaskParticipants.push(e);
    this.editParticipantModal.close();
    // this.documentDataCollectionChange.emit(this.documentDataCollection);
  }

  // Updating
  public async onUpdateParticipantBtnClicked(e: TaskParticipant, index: number) {
    if (!this.userIsLoaded) {
      await this.loadUsers();
    }
    await this.initModalDatas(e);
    var participant = this.userAndUserGroupItems.find((p: any) => {
      if (e.IsUser) {
        // Si c'est un utilisateur, comparer les identifiants Oid
        return p.user.Oid.toString() === e.UserId?.Oid?.toString();
      } else {
        // Si c'est un groupe, comparer les identifiants Id
        return p.user.Id === e.UserGroupId?.Id;
      }
    });
    this.editParticipantModal.open({ newItem: false, index: index, selectedUser: { Id: participant.Id, FormattedName: this.participant.FormattedName, user: (this.participant.IsUser ? this.participant.UserId : this.participant.UserGroupId), isUser: this.participant.IsUser } });

    // TODO check best method
    setTimeout(() => {
      if (this.taskParticipantModal.UserSelect) {
        this.taskParticipantModal.UserSelect.model = this.taskParticipantModal.UserSelect.model.Id;
      }
    }, 10);

  }
  public onUpdate(itemId: number, index: number) {
    var item = this.userAndUserGroupItems.find((i:any)=>i.Id == itemId);
    var d:any = new TaskParticipant({
      IsUser: item.isUser,
      FormattedName: item.FormattedName,
      BackgroundColor: item.user.BackgroundColor,
      ForegroundColor: item.user.ForegroundColor,
      UserId: item.isUser ? item.user : undefined,
      UserGroupId: !item.isUser ? item.user : undefined,
      TaskId:this.element
    });
    if (!this.canCreateOrUpdate(d)) return;
    // this.createOrUpdateVerification(d, index);
    this.element.TaskParticipants[index] = d;
    this.editParticipantModal.close();
    // this.documentDataCollectionChange.emit(this.documentDataCollection);
  }

  // deleting
  public onDeleteParticipantBtnClicked(d: TaskParticipant) {
    if (this.element.TaskParticipants.length <= 1) {
      NotificationsService.sendErrorMessage("Participants cannot by empty");
      return;
    }
    if (!d.Id) {
      NotificationsService.sendErrorMessage("Please contact Axel DUEZ");
      return;
    }
    this.deleteConfirm.open({ participantId: d.Id });
  }

  public async onDelete() {
    this.element.TaskParticipants = this.element.TaskParticipants.filter((e: TaskParticipant) => { return e.Id !== this.deleteConfirm.modal.data.participantId });
    this.deleteConfirm.close();
    AppInjectorService.config.setModificationGuard(true);
  }
  // --------------------------------------------

  private canCreateOrUpdate(e: TaskParticipant): boolean {
    if (!e.UserId && !e.UserGroupId) {
      NotificationsService.sendErrorMessage("User cannot be empty");
      return false;
    }

    if (this.element.TaskParticipants.some((t: TaskParticipant) => t.UserId?.Oid?.toString() == e.UserId?.Oid?.toString() && t.UserGroupId?.Id == e.UserGroupId?.Id)) {
      NotificationsService.sendErrorMessage("User already exists");
      return false;
    }

    return true;
  }

}
