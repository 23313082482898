<syslink-block [title]="'Products'">
    <app-product-grid
        #productGrid
        [expand]="expand"
        [permissionKey]="'list'"
        [canRefresh]="canRefresh"
        (add)="addNewProduct()"
        [showContextMenu]="true"
        (rowDoubleClicked)="onDoubleClicked($event)"
    ></app-product-grid>
</syslink-block>