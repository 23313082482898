import { Injectable } from '@angular/core';
import { DocumentRelation } from './document-relation.model';
import { ODataService } from '../../../core/services/oData.service';
import { LoadOptions } from 'devextreme/data';

@Injectable({
  providedIn: 'root'
})
export class DocumentRelationsService extends ODataService<DocumentRelation> {
  public url = 'DocumentRelation';
  public override defaultOptions: LoadOptions = {
    expand: []
  };
  
  public override async getInstance(params?: Partial<DocumentRelation>): Promise<DocumentRelation> {
    var element = new DocumentRelation({
      ...params
    });
    return element;
  }
}
