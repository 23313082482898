<div class="row">
  <syslink-block
    *ngIf="authService.hasPermission(
      this.newBasePermissionKey + '.finance.purchase'
    ) && element.Purchasable"
    class="col-sm-4 col-xs-12"
    [title]="'Purchase'"
    [titleBgColor]="'gray'"
    [titleTextColor]="'white'"
    [bordered]="true"
    [titlePadding]="2"
  >
    <syslink-select
      label="Tax type"
      [store]="taxTypesService.store"
      [(model)]="element.PurchaseTaxTypeId"
      [displayKey]="'FormattedName'"
    ></syslink-select>
    <syslink-select
      label="Account"
      [store]="accountsService.store"
      [(model)]="element.PurchaseAccountId"
      [displayKey]="'FormattedName'"
    ></syslink-select>
  </syslink-block>

  <syslink-block
    *ngIf="authService.hasPermission(
      this.newBasePermissionKey + '.finance.sale'
    )  &&element.Sellable "
    [title]="'Sales'"
    class="col-sm-4 col-xs-12 mt-3 mt-sm-0"
    [titleBgColor]="'gray'"
    [titleTextColor]="'white'"
    [bordered]="true"
    [titlePadding]="2"
  >
    <syslink-select
      label="Sale tax"
      [store]="taxRatesService.store"
      [filter]="['IsVisible eq true']"
      [(model)]="element.SaleTaxRateId"
    ></syslink-select>
    <syslink-select
      label="Tax type"
      [store]="taxTypesService.store"
      [(model)]="element.SaleTaxTypeId"
      [displayKey]="'FormattedName'"
    ></syslink-select>
    <syslink-select
      label="Account"
      [store]="accountsService.store"
      [(model)]="element.SaleAccountId"
      [displayKey]="'FormattedName'"
    ></syslink-select>
  </syslink-block>
</div>
