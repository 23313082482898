import { Component, Input } from '@angular/core';
import { CreditsafeData } from '../creditsafe.model';

@Component({
  selector: 'app-creditsafe-current',
  templateUrl: './creditsafe-current.component.html',
  styleUrls: ['./creditsafe-current.component.scss']
})
export class CreditsafeCurrentComponent {
  @Input() element: CreditsafeData = new CreditsafeData();

  public backgroundColors: any[] = [
    { 'background-color': '#4a996e' },
    { 'background-color': '#75c69b' },
    { 'background-color': '#f49730' },
    { 'background-color': '#e93b23' },
    { 'background-color': '#e93b23' }
  ]
  private formatter = new Intl.NumberFormat('de-DE');

  public getCreditLimit() {
    return this.formatter.format(this.element.Report?.CompanySummary?.CreditRating?.CreditLimit?.Value ?? 0) + " " + this.element.Report?.CompanySummary?.CreditRating?.CreditLimit?.Currency;
  }

  public getDefaultProbability() {
    return ((this.element.Report?.CompanySummary?.CreditRating?.Pod ?? 0) * 100).toFixed(4) + " %";
  }

  public getCommonValueColor(value: string = ""): any  {
    switch (value) {
      case 'A':
        return this.backgroundColors[0];
      case 'B':
        return this.backgroundColors[1];
      case 'C':
        return this.backgroundColors[2];
      case 'D':
        return this.backgroundColors[3];
      case 'E':
        return this.backgroundColors[4];
      default:
        return "";
    }
  }

  public getCommonDescriptionColor(value: string = ""): any  {
    switch (value) {
      case 'Risque Très Faible':
        return this.backgroundColors[0];
      case 'Risque Faible':
        return this.backgroundColors[1];
      case 'Risque Moyen':
        return this.backgroundColors[2];
      case 'Risque Elevé':
        return this.backgroundColors[3];
      case 'Pas De Note':
        return this.backgroundColors[4];
      default:
        return "";
    }
  }

  public getProviderValueColor(value?: number) : any {
    if(!value) return this.backgroundColors[4];
    else if(value<=29) return this.backgroundColors[3];
    else if(value<=50) return this.backgroundColors[2];
    else if(value<=70) return this.backgroundColors[1];
    else if(value<=100) return this.backgroundColors[0];
    else return "";
  }
}
