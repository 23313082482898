import { Injectable } from '@angular/core';
import { ODataService } from '../../core/services/oData.service';
import { LoadOptions } from 'devextreme/data';
import { BankReconciliationLine } from './bank-reconciliation-line.model';
import { Payment } from '../../base/payments/payment.model';

@Injectable({
  providedIn: 'root'
})
export class BankReconciliationLinesService extends ODataService<BankReconciliationLine> {
  public url = 'BankReconciliationLine';
  public override defaultOptions: LoadOptions = {
    expand: [
      'BankReconciliationId',
      'ThirdId',
      'SaleDocumentHeaderId',
      'StatusId',
      'PaymentId',
    ]
  };

  public override format(element: Partial<BankReconciliationLine>): BankReconciliationLine {
    var result: any = {
      ...element,
      BankReconciliationId: element.BankReconciliationId?.Id,
      ThirdId: element.ThirdId?.Id,
      SaleDocumentHeaderId: element.SaleDocumentHeaderId?.Id,
      PaymentId : element.PaymentId?.Id,
    };

    if (result.Date) {
      let date: Date = new Date(result.Date);
      if (!date || date.getFullYear().toString() == "1" || date.getFullYear().toString() == "0") {
        delete result.Date;
      }
    }

    return result;
  }

}