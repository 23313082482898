import { Component, Input, ViewChild } from '@angular/core';
import { BaseModalComponent } from 'projects/libraries/syslink-components/src/lib/modal/base-modal/base-modal.component';
import { WorkType } from '../../works/work-types/work-type.model';
import { FilterDescriptor } from 'devextreme/data';
import { SelectComponent } from 'projects/libraries/syslink-components/src/public-api';
import { WorkTypesService } from '../../works/work-types/work-types.service';

@Component({
  selector: 'app-work-time-type-select-modal',
  templateUrl: './work-time-type-select-modal.component.html',
  styleUrls: ['./work-time-type-select-modal.component.scss']
})
export class WorkTimeTypeSelectModalComponent extends BaseModalComponent<WorkType>{
  @Input() public selectFilters: FilterDescriptor | Array<FilterDescriptor>;
  @Input() public expand: FilterDescriptor | Array<FilterDescriptor>;
  @Input() public title: string = "Select type";
  @Input() public content?: string;
  @Input() public data: any;
  @ViewChild('select') select?: SelectComponent;

  constructor(public workTypesService: WorkTypesService) {
    super();
  }
}