import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Third } from '../third.model';

@Component({
  selector: 'app-third-total',
  templateUrl: './third-total.component.html',
  styleUrls: ['./third-total.component.scss']
})
export class ThirdTotalComponent {
  @Input() public element: Third = new Third();
  @Output() elementChange: EventEmitter<Third> = new EventEmitter<Third>();
}
