import { Injectable } from '@angular/core';
import { ODataService } from '../../core/services/oData.service';
import { AccountMove } from './account-move.model';
import { LoadOptions } from 'devextreme/data';
import { AccountMoveStatusService } from './account-move-status/account-move-status.service';
import { AccountMoveHeaderStatus } from './account-move-header-status/account-move-header-status.model';
import { NotificationsService } from 'projects/libraries/syslink-components/src/public-api';
import { AccountMoveLine } from '../account-move-lines/account-move-line.model';
import { DxoLineComponent } from 'devextreme-angular/ui/nested';
import { CreditsafeCommentariesComponent } from '../../connectors/creditsafe/creditsafe-commentaries/creditsafe-commentaries.component';

@Injectable({
  providedIn: 'root'
})
export class AccountMovesService extends ODataService<AccountMove> {
  public url = 'AccountMove';
  public override defaultOptions: LoadOptions = {
    expand: [
      'AccountJournalId',
      'AccountPeriodId',
      'ThirdId',
      'DocumentId',
      'AccountMoveLines($orderby=LineNo;$expand=AccountId($expand=NameTranslationId),ThirdId,SaleDocumentId,TaxId)',
      'CurrentStatusLink.StatusId',
      'Statuses.StatusId'
    ]
  };

  constructor(
    private accountMoveStatusService: AccountMoveStatusService
  ) {
    super();
  }

  public override format(element: Partial<AccountMove>): AccountMove {
    var result: any = {
      ...element,
      AccountJournalId: element.AccountJournalId?.Id,
      AccountPeriodId: element.AccountPeriodId?.Id,
      ThirdId: element.ThirdId?.Id,
      DocumentId: element.DocumentId?.Id
    };

    // Update Date
    delete result.CurrentStatusLink;
    delete result.Statuses;
    delete result.AccountMoveLines;

    if (result.Date) {
      let date: Date = new Date(result.Date);
      if (!date || date.getFullYear().toString() == "1" || date.getFullYear().toString() == "0") {
        delete result.Date;
      }
    }

    return result;
  }

  public override async getInstance(params?: Partial<AccountMove>): Promise<AccountMove> {
    var statuses = await this.accountMoveStatusService.load();

    var defaultStatus = new AccountMoveHeaderStatus({
      StatusId: statuses.find((s: any) => s.Code == 'Move.Encoded'),
      Date: new Date()
    });

    var element = new AccountMove({
      CurrentStatusLink: defaultStatus,
      Statuses: [defaultStatus],
      Date: new Date(),
      ...params
    });

    return element;
  }

  public canSave(move: AccountMove): boolean {
    if (!move.Date) {
      NotificationsService.sendErrorMessage('Date cannot be empty')
      return false;
    }
    if (!move.AccountJournalId) {
      NotificationsService.sendErrorMessage('Journal cannot be empty')
      return false;
    }
    if (!move.AccountPeriodId) {
      NotificationsService.sendErrorMessage('Period cannot be empty')
      return false;
    }

    var resultLine = 0;
    move.AccountMoveLines.forEach((line: AccountMoveLine) => {
      if (line.Debit == 0 && line.Credit == 0) {
        resultLine++;
      }
      if (line.AccountId == null || line.AccountId == undefined) {
        resultLine++;
      }

      if(line.AccountId?.Code != "400000" && line.AccountId?.Code != "451000" && line.TaxId == null){
        resultLine++;
      }
    });
    if (resultLine != 0) {
      NotificationsService.sendErrorMessage('Error in the lines')
      return false;
    }

    var debit = move.AccountMoveLines.reduce((sum, item) => sum + (item.Debit ?? 0), 0);
    var credit = move.AccountMoveLines.reduce((sum, item) => sum + (item.Credit ?? 0), 0);
    var total = debit - credit;
    if (total != 0) {
      NotificationsService.sendErrorMessage('Error in the lines')
      return false;
    }
    return true;
  }



}