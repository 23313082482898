<div class="row">
  <div class="col-sm-3 col-xs-12 ">
    <div>
      <i
        *ngIf="!element.IsProfessional"
        class="text-primary text-center w-100 h5 py-1 m-0 fa-solid fa-user bg-light"
        style="font-size: 5rem"
      ></i>
      <i
        *ngIf="element.IsProfessional"
        class="text-primary text-center w-100 h5 py-1 m-0 fa-solid fa-building bg-light"
        style="font-size: 5rem"
      ></i>
    </div>
    <div>
      <syslink-switch
        [(model)]="!element.Blocked"
        (modelChange)="element.Blocked = !$event"
        type="box"
        [falseText]="'Blocked' | translate"
        [trueText]="'Active' | translate"
      ></syslink-switch>
    </div>
  </div>
  <div class="col-sm-6">
    <syslink-input-text
      label="Alias"
      [(model)]="element.Alias"
    ></syslink-input-text>
    <syslink-tagbox
      label="Roles"
      [store]="thirdRolesService.store"
      [(model)]="element.Roles"
      (modelChange)="thirdRolesChange.emit($event)"
    ></syslink-tagbox>
    <div>
      <syslink-switch
        label="Professional"
        [model]="element.IsProfessional"
        (modelChange)="onIsProfessionalChanged($event)"
      ></syslink-switch>
    </div>
  </div>
</div>