<div class="h-100">
  <syslink-drawer
    #drawer
    *ngIf="groupPageService.drawerItems"
    [drawerItems]="groupPageService.drawerItems"
  >
    <div>
      <div class="sticky-top bg-secondary d-flex justify-content-between py-2 ps-2 pe-3">
        <syslink-button
          class="drawer-toggle"
          icon="menu"
          mode="text"
          [hoverStateEnabled]="false"
          (click)="drawer.opened = !drawer.opened"
        ></syslink-button>
        <syslink-user-menu
          *ngIf="authService.user && authService.user.UserName"
          id="user-menu"
          name="userMenu"
          [userMenuItems]="userMenuItems"
          [userName]="authService.user.UserName"
        ></syslink-user-menu>
      </div>
      <div class="container-fluid p-3 pb-4">
        <syslink-breadcrumbs #breadcrumb></syslink-breadcrumbs>
        <div id="admin-outlet">
          <router-outlet *ngIf="permissionsIsLoaded"></router-outlet>
          <span class="version">{{versionText}}</span>
        </div>
      </div>
    </div>
  </syslink-drawer>
</div>

<syslink-modal
  #profileModal
  title="Profile"
  width="300px"
  (onValidate)="onProfileModalValidate()"
  validateText="Save"
  cancelText="Cancel"
>
  <div class="col-12 col-lg-12 p-1">
    <app-user-form
      *ngIf="currentUser"
      [user]="currentUser"
    ></app-user-form>
  </div>
</syslink-modal>
<syslink-modal
  #aboutModal
  [noFooter]="true"
  title="About"
  width="475px"
>
  <div class="p-3">
    <app-about></app-about>
  </div>
</syslink-modal>

<syslink-confirm-modal
  #logoutConfirm
  title="Logout"
  content="LogoutConfirmMessage"
  (onValidate)="authService.logout()"
  validateText="Confirm"
  cancelText="Cancel"
></syslink-confirm-modal>
<syslink-confirm-password
  #passwordConfirm
  (onValidate)="saveProfile($event)"
></syslink-confirm-password>