<syslink-switch
    label="Performable"
    [(model)]="element.IsPerformable"
></syslink-switch>

<div *ngIf="element.IsPerformable">
<syslink-input-duration
    label="Estimated time"
    [textColor]="element.EstimatedTimeState.Color"
    [(model)]="element.EstimatedTime"
></syslink-input-duration>

<syslink-progress-bar
    #workedTimeProgressBar
    name="Not billable worked time"
    [max]="element.EstimatedTime || element.WorkedTime"
    [value]="element.NotBillableWorkedTime"
    [fillColor]="'#f0ad4e'"
    type="duration"
></syslink-progress-bar>

<syslink-progress-bar
    #workedTimeProgressBar
    name="Billable worked time"
    [max]="element.EstimatedTime || element.WorkedTime"
    [value]="element.BillableWorkedTime"
    [fillColor]="'#03a9f4'"
    type="duration"
></syslink-progress-bar>

<syslink-progress-bar   
    #workedTimeProgressBar
    name="Worked time"
    [max]="element.EstimatedTime || element.WorkedTime"
    [value]="element.WorkedTime"
    [fillColor]="element.WorkedTimeState.Color"
    type="duration"
></syslink-progress-bar>
</div>