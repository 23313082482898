import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { SchedulerComponent, SyslinkToolbarAction } from 'projects/libraries/syslink-components/src/public-api';
import { WorkTimesService } from '../work-times.service';
import { CrudComponent } from 'projects/libraries/syslink-components/src/lib/helpers/crud-component/crud-component.component';
import { ContextMenuItemAction } from 'projects/libraries/syslink-components/src/lib/context-menus/context-menu-item-action';
import { WorkTimeModalComponent } from '../work-time-modal/work-time-modal.component';
import { UsersService } from 'projects/erp-app/src/app/core/auth/users/users.service';
import { User } from 'projects/erp-app/src/app/core/auth/users/user.model';
import { TasksService } from '../../../tasks/tasks/tasks.service';
import { ThirdsService } from 'projects/erp-app/src/app/thirds/thirds/thirds.service';
import { Third } from 'projects/erp-app/src/app/thirds/thirds/third.model';
import { Task } from '../../../tasks/tasks/task.model';
import { WorkTime } from '../work-time';
import { NgxUiLoaderService } from 'ngx-ui-loader';
@Component({
  selector: 'app-work-time-scheduler',
  templateUrl: './work-time-scheduler.component.html',
  styleUrls: ['./work-time-scheduler.component.scss']
})
export class WorkTimeSchedulerComponent extends CrudComponent{

  @Input() public toolbarActions: SyslinkToolbarAction[] = [];
  @Input() public contextMenuItems: ContextMenuItemAction[] = [];
  @Input() public appointmentContextMenuItems: ContextMenuItemAction[] = [];
  @Input() public override storageKey: string = 'worktime-scheduler';

  @Output() validate: EventEmitter<any> = new EventEmitter<any>();
  @Output() selectionChanged: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild("scheduler") scheduler?: SchedulerComponent;
  @ViewChild("editModal") editModal?: WorkTimeModalComponent;

  public startDate: any = new Date();
  public endDate: any = new Date();

  public thirds: Third[] = [];
  public tasks: Task[] = [];
  public users: User[] = [];

  public items: WorkTime[] = [];

  constructor(
    public workTimesService: WorkTimesService,
    public usersService: UsersService,
    public tasksService: TasksService,
    public thirdsService: ThirdsService,
    public ngxUiLoaderService : NgxUiLoaderService
  ) {
    super();
  }

  private async loadItem(){
    // this.ngxUiLoaderService.start();
    this.items = await this.workTimesService.load({filter:this.loadFilter(),
      select:['Id','Description','EndDate','IsBillable','IsBilled','StartDate','ThirdId.Id', 'Duration', 'ThirdId.Fullname','TypeId.id','TypeId.Name','UserId.Oid', 'UserId.Name','TaskId.Id','TaskId.FormattedName'],
      expand:[]
    });
//     AllDay
// SaleInvoiceLineId

    this.scheduler?.scheduler?.instance.option("currentDate",this.startDate );
    this.scheduler?.scheduler?.instance.option("startDayHour",8 );
    this.scheduler?.scheduler?.instance.option("dataSource", this.items);
    this.scheduler?.scheduler?.instance.repaint();
    // this.ngxUiLoaderService.stop();
  }

  public onCurrentDateChange(e:any){
   setTimeout(async ()=>{
     await this.loadItem();
   },200); 
  }
  public updateFilterDate() {
    this.startDate = this.scheduler?.scheduler?.instance.getStartViewDate();
    this.endDate = this.scheduler?.scheduler?.instance.getEndViewDate();
  }

  public async userChange(elements: User[]) {
    this.users = elements;
   await this.loadItem();
  }
  public async thirdChange(elements: Third[]) {
    this.thirds = elements;
   await this.loadItem();
  }
  public async taskChange(elements: Task[]) {
    this.tasks = elements;
   await this.loadItem();
  }

  private formatDate(date: Date = new Date()) {
    let year = date.getFullYear();
    let month = (date.getMonth() + 1).toString().padStart(2, '0'); // Les mois sont de 0 à 11
    let day = date.getDate().toString().padStart(2, '0');

    return `${year}-${month}-${day}`;
  }

  private loadFilter() {
    this.updateFilterDate();
    return ["StartDate ge " + this.formatDate(this.startDate) + " and EndDate le " + this.formatDate(this.endDate)
      + (this.users.length > 0 ? " and UserId.Oid in (" + this.users.map(item => item.Oid).join(',') + ")" : "")
      + (this.thirds.length > 0 ? " and ThirdId.Id in (" + this.thirds.map(item => item.Id).join(',') + ")" : "")
      + (this.tasks.length > 0 ? " and TaskId.Id in (" + this.tasks.map(item => item.Id).join(',') + ")" : "")
    ]
  }

  // private reloadScheduler() {
  //   this.loadFilter();
  //   setTimeout(() => {
  //     if (!this.scheduler || !this.scheduler?.scheduler) return;
  //     this.scheduler.filter = this.filter;
  //     this.scheduler?.refresh();
  //     this.scheduler.scheduler.instance.option("currentDate", this.startDate)
  //   }, 100);
  // }

}
