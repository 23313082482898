import { DocumentLineRelation } from "../../../base/documents/document-line-relations/document-relation.model";
import { DocumentRelation } from "../../../base/documents/document-relations/document-relation.model";
import { Document } from "../../../base/documents/documents/document.model";
import { IndexingType } from "../../../base/indexing/indexing-type.model";
import { Periodicity } from "../../../base/periodicities/periodicities/periodicity.model";
import { SaleDocumentFinance } from "../sale-document-finances/sale-document-finance.model";
import { SaleDocumentHeaderStatus } from "../sale-document-header-status/sale-document-header-status.model";
import { SaleDocumentLine } from "../sale-document-lines/sale-document-line.model";

export class SaleDocument extends Document {
    public override DocumentFinances?: SaleDocumentFinance[];
    public override Lines?: SaleDocumentLine[];
    public FormattedDocumentPayment?: string;
    public FormattedStructuredCommunication?: string;
    public override Statuses?: SaleDocumentHeaderStatus[];
    public override CurrentStatusLink?: SaleDocumentHeaderStatus;
    
    public DocumentLineRelations?: DocumentLineRelation[];;

    // Sale Contract
    // -------------
    // public AutomaticallySend: boolean = false;
    // public AutomaticallyValidated: boolean = false;
    // public StartPeriodDate?: Date;
    // public StartPeriodDateOffset?: number;
    // public IsStartPeriodDateOffsetPositive: boolean = false;
    // public PeriodicityId?: Periodicity;
    // public CurrentPeriodStartDate?: Date;
    // public CurrentPeriodEndDate?: Date;
    // public GenerateDocumentNextDate?: Date;
    // public DateFrom?:Date;
    // public DateTo?:Date;
    // public ContractId?:SaleDocument;
    // public RRule?: string;
    // public NextIndexingDate?: Date;
    // public LastIndexingDate?: Date;
    // public Renewal?: number
    // public RenewalDate?: Date;
    // public IndexingTypeId?:IndexingType;
    // public NotificationDelay?:number;
    // public IndexCoefficient?:number;
    // //public IndexFormula?:string;
    // public ExtensionDate?:Date;
    // public StopDate?:Date;
    // public Indexing?:number;
    // public LastIndexingValue?:number|undefined;
    // public RenewalEndDate?:Date;

    // // TODO find a way to place these fields in SaleContract
    // public ExTaxTotalInvoiced : number = 0;
    // public InTaxTotalInvoiced : number = 0;

    // TODO Replace those fields in invoice when forms code are refactored
    public DaysOverdue : number = 0;
    public FixedLateFeeAmount : number = 0;
    public LateFeeInterestPercentage : number = 0;
    public LateFeeInterestAmount : number = 0;
    public TotalWithLateFee : number = 0;

    public DocumentRelations : DocumentRelation[] = [];
    public NameFormattedWithThirdName : string = '';

    public Margin?:number;
    public MarginPercent?:number;
    
    //-------------------------------------------------------
    public Type?:string;

    constructor(entity?: Partial<SaleDocument>) {
        super();
        Object.assign(this, entity);
    }
}
