<div class="row">
    <div class="col-4">
        <syslink-switch
            label="Public"
            [(model)]="element.IsPublic"
            [disabled]="readOnly"
        ></syslink-switch>
    </div>
    <div class="col-4">
        <syslink-switch
            label="Favorite"
            [(model)]="element.IsFavorite"
            [disabled]="readOnly"
        ></syslink-switch>
    </div>
    <div class="col-4"></div>
    <div class="col-4">
        <syslink-select
            label="Type"
            [store]="thirdContactTypesService.store"
            [(model)]="element.ContactTypeId"
            [disabled]="readOnly"
            (modelChange)="onChangeContactType($event)"
        ></syslink-select>
    </div>
    <div class="col-4">
        <syslink-select
            label="Qualifier"
            [store]="thirdContactQualifiersService.store"
            [(model)]="element.ContactQualifierId"
            [disabled]="readOnly"
        ></syslink-select>
    </div>
</div>
<div class="row">
    <app-address-form
        #addressForm
        *ngIf="element.ContactTypeId && element.ContactTypeId.Code == 'Address' && element.AddressId"
        [(address)]="element.AddressId"
    ></app-address-form>
</div>
<div class="row">
    <div *ngIf="element.ContactTypeId && element.ContactTypeId.Code != 'Address'">
        <div class="col-6">
            <syslink-input-text
                [name]="'Value' | translate"
                [(model)]="element.Value"  
                (focusIn)="focusInValue($event)"              
            ></syslink-input-text>
        </div>
    </div>
</div>
<div class="row">
    <div class="col-4">
        <syslink-switch
            label="Used for quotes"
            [(model)]="element.UsedForQuote"
            [disabled]="readOnly"
        ></syslink-switch>
    </div>
    <div class="col-4">
        <syslink-switch
            label="Used for deliveries"
            [(model)]="element.UsedForDelivery"
            [disabled]="readOnly"
        ></syslink-switch>
    </div>
    <div class="col-4">
        <syslink-switch
            label="Used for invoices"
            [(model)]="element.UsedForInvoice"
            [disabled]="readOnly"
        ></syslink-switch>
    </div>
</div>