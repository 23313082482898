import { Injectable } from '@angular/core';
import { ODataService } from '../../services/oData.service';
import { UserStorage } from './user-storage.model';
import { AuthService } from '../auth.service';
import { User } from '../users/user.model';

@Injectable({
  providedIn: 'root'
})
export class UserStoragesService extends ODataService<UserStorage> {
  public url = 'UserStorage';

  static async updateStateStoring(storageKey: string, state: any): Promise<void> {
    if (!storageKey) return;
    const instance = new UserStoragesService();

    var item = localStorage.getItem(storageKey);
    var str = JSON.stringify(state);
    if (!item) {
      await instance.addOrCreateUserStorage('/insert', storageKey, JSON.stringify(state));
      localStorage.setItem(storageKey, JSON.stringify(state));
    }
    else if (item && str.toString() != item.toString() && state.pageSize > 0) {
      await instance.addOrCreateUserStorage('/update', storageKey, JSON.stringify(state));
      localStorage.setItem(storageKey, JSON.stringify(state));
    }
  }

  private async addOrCreateUserStorage(type: '/insert' | '/update', key: string, value: string) {
    return await this.apiService.sendRequest(
      '/api/' + this.url + type,
      'POST',
      {
        Key: key,
        Value: value
      }
    );
  }
}
