import { Component, Input, ViewChild } from '@angular/core';
import { BaseGridComponent } from 'projects/libraries/syslink-components/src/lib/grid/base-grid/base-grid.component';
import { Sequence } from '../sequence.model';
import { GridComponent } from 'projects/libraries/syslink-components/src/public-api';
import { SyslinkColumn } from 'projects/libraries/syslink-components/src/lib/helpers/SyslinkColumn';
import { SequencesService } from '../sequences.service';
import { SequenceConfigurationsService } from '../sequence-configurations.service';

@Component({
  selector: 'app-sequence-grid',
  templateUrl: './sequence-grid.component.html',
  styleUrls: ['./sequence-grid.component.scss']
})
export class SequenceGridComponent extends BaseGridComponent<Sequence> {
  @Input() storageKey: string = 'grid-sequences';

  @ViewChild('gridComponent') public gridComponent?: GridComponent;
  // @ViewChild('deleteConfirmModal') public deleteConfirmModal: ConfirmModalComponent = new ConfirmModalComponent();

  public columns: SyslinkColumn[] = [
    new SyslinkColumn({ field: 'Id', type: 'number', editable:false }),
    new SyslinkColumn({ field: 'NextValue', type: 'number' }),
    new SyslinkColumn({ field: 'Increment', type: 'number' }),
    new SyslinkColumn({ field: 'Active', type: 'boolean' }),
    new SyslinkColumn({ field: "ConfigId", label: "Sequence configuration", cellTemplate: 'select-value', editCellTemplate: "select-cell", data: { service: this.sequenceConfigurationsService, displayKey: 'FormattedSequenceConfig' } }),
    new SyslinkColumn({ field: 'Year', type: 'number' }),
  ];

  constructor(
    public sequencesService: SequencesService,
    public sequenceConfigurationsService:SequenceConfigurationsService
  ) {
    super();
  }

}
