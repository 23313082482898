import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { DocumentData } from '../document-data.model';
import { ThirdsService } from 'projects/erp-app/src/app/thirds/thirds/thirds.service';
import { Third } from 'projects/erp-app/src/app/thirds/thirds/third.model';
import { SelectComponent } from 'projects/libraries/syslink-components/src/public-api';
import { Address } from '../../../addresses/addresses/address.model';

@Component({
  selector: 'app-third-document-data-modal',
  templateUrl: './third-document-data-modal.component.html',
  styleUrls: ['./third-document-data-modal.component.scss']
})
export class ThirdDocumentDataModalComponent implements OnInit {
  @Input() element: DocumentData = new DocumentData;
  @Output() elementChange: EventEmitter<DocumentData> = new EventEmitter<DocumentData>();

  @Input() public thirdFilter: string | Array<string> = "";
  @Input() public detailsUrl: string = "";
  @Input() public showRelatedThird: boolean = true;
  @Input() public relatedThirdFilter: string | Array<string> = "";
  @Input() public showDeliveryAddress: boolean = false;

  @ViewChild('ThirdSelect') public ThirdSelect?: SelectComponent;
  public selectFilter: string | Array<string> = "";

  constructor(
    public thirdsService: ThirdsService
  ) { }
  ngOnInit(): void {
    if (this.element.ThirdId != undefined) {
      this.loadAddress(this.element.ThirdId);
    }
  }

  public onChangeAddress(e: Address) {
    if (!e || e.Id == this.element.AddressId?.Id) return;
    this.element.AddressId = e;
    this.element.Street = e.Street ?? "";
    this.element.Number = e.Number ?? "";
    this.element.Box = e.Box ?? "";
    this.element.LocalityId = e.LocalityId;
    this.element.PostalCodeId = e.PostalCodeId;
  }

  public onChangeDeliveryAddress(e: Address) {
    if (!e || e.Id == this.element.DeliveryAddressId?.Id) return;
    this.element.DeliveryAddressId = e;
    this.element.DeliveryStreet = e.Street ?? "";
    this.element.DeliveryNumber = e.Number ?? "";
    this.element.DeliveryBox = e.Box ?? "";
    this.element.DeliveryLocalityId = e.LocalityId;
    this.element.DeliveryPostalCodeId = e.PostalCodeId;
  }

  public ChangeThirdFilter() {
    if (this.showRelatedThird) {
      this.selectFilter = this.relatedThirdFilter;
    } else {
      this.selectFilter = this.thirdFilter;
    }
    this.ThirdSelect?.forceReload();
  }

  public async onThirdChanged(e: Third) {
    if (!this.element.ThirdId || e.Id != this.element.ThirdId?.Id) {
      this.element.AddressId = undefined;
    }
    
    await this.loadAddress(e);
  }

  private async loadAddress(e: Third) {
    if(!e.Id) return;
    let contactInformations = (await this.thirdsService.findByID(e.Id,{select:['Id'], expand:['ContactInformations.AddressId.LocalityId','ContactInformations.AddressId.PostalCodeId']})).ContactInformations;
    const contacts: any[] = contactInformations.filter(((a: any) => a.AddressId != undefined));

    this.element.ThirdId.Addresses = [];

    if (contacts.length == 1) {
      this.element.AddressId = contacts[0].AddressId;
    }

    contacts.forEach(element => {
      this.element.ThirdId.Addresses.push(element.AddressId);
    });
  }

  public updatePrincipal() {
    if (this.element.HasPrincipal == true) {
      this.element.HasPrincipal = false;
      return;
    }
    if (this.element.HasPrincipal == false && this.element.HasSecondary == true) {
      this.element.HasSecondary = false;
    }
    this.element.HasPrincipal = true;
  }

  public updateSecondary() {
    if (this.element.HasSecondary == true) {
      this.element.HasSecondary = false;
      return;
    }
    if (this.element.HasSecondary == false && this.element.HasPrincipal == true) {
      this.element.HasPrincipal = false;
    }
    this.element.HasSecondary = true;
  }
}
