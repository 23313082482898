import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { CrudComponent } from '../../helpers/crud-component/crud-component.component';
import { SyslinkColumn } from '../../helpers/SyslinkColumn';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { AppInjectorService } from '../../services/app-injector.service';
import { BeforeDeleteEvent } from '../../helpers/crud-component/events/BeforeDeleteEvent';
import { goToUrl } from '../../helpers/tools';
import { GridComponent } from '../grid.component';
import { SyslinkToolbarAction } from '../../toolbar/toolbar.component';
import { ContextMenuItemClickEvent } from 'devextreme/ui/file_manager';

@Component({
  template: ''
})
export abstract class BaseGridComponent<T> extends CrudComponent {
  // Overrides
  // ---------
  public override canUpdate: boolean = false;
  public override canRefresh: boolean = true;
  public override canFilter: boolean = true;
  public override canView: boolean = true;
  public override canSearch: boolean = true;
  public override canAdd: boolean = true;
  public override canSort: boolean = true;
  public override canExport: boolean = true;
  public override canDelete: boolean = true;
  public override canDblClck: boolean = true;
  public override permissionKey?: string | undefined = 'list';

  // Inputs
  // ------
  @Input() public abstract columns: SyslinkColumn[];
  @Input() public detailsUrl?: string;
  abstract override storageKey?: string | undefined;
  @Input() newTab: boolean = true;
  @Input() key: string = "Id";
  @Input() public toolbarActions: SyslinkToolbarAction[] = [];

  // Outputs
  // -------
  @Output() rowDoubleClicked: EventEmitter<T> = new EventEmitter<T>();
  @Output() refreshClicked: EventEmitter<any> = new EventEmitter<any>();

  // ViewChildren
  // ------------
  @ViewChild('grid') public grid?: GridComponent;

  // Custom properties
  // -----------------
  protected location: Location;
  protected activatedRoute: ActivatedRoute;

  constructor() {
    super();
    this.location = AppInjectorService.injector.get(Location);
    this.activatedRoute = AppInjectorService.injector.get(ActivatedRoute);
  }

  override ngOnInit(): void {
    super.ngOnInit();
    var url = this.basePermissionKey?.replace(/\?.*/, ".details").replace('.copy', ".details");

    this.canUpdate = this.authService.hasPermission(url + '.' + this.permissionKey + '.' + 'update');
    this.canRefresh = this.authService.hasPermission(url + '.' + this.permissionKey + '.' + 'refresh');
    this.canFilter = this.authService.hasPermission(url + '.' + this.permissionKey + '.' + 'filter');
    this.canView = this.authService.hasPermission(url + '.' + this.permissionKey + '.' + 'view');
    this.canSearch = this.authService.hasPermission(url + '.' + this.permissionKey + '.' + 'search');
    this.canAdd = this.authService.hasPermission(url + '.' + this.permissionKey + '.' + 'add');
    this.canSort = this.authService.hasPermission(url + '.' + this.permissionKey + '.' + 'sort');
    this.canExport = this.authService.hasPermission(url + '.' + this.permissionKey + '.' + 'export');
    this.canDelete = this.authService.hasPermission(url + '.' + this.permissionKey + '.' + 'delete');
    this.canDblClck = this.authService.hasPermission(url + '.' + this.permissionKey + '.' + 'doubleClick');
    //  console.log(url + '.' + this.permissionKey + '.');

  }

  // HELPERS
  // -------
  public refresh() {
    if (this.refreshClicked.observed) {
      this.refreshClicked.emit();
    }
    else {
      this.grid?.grid?.instance.refresh();
    }
  }

  // EVENTS
  // ------
  public onAddButtonClicked() {    
    if (this.add.observed) {
      this.add.emit();
    }
    else {
      goToUrl(this.router, this.activatedRoute, this.location, this.detailsUrl + '/new', false, true, this.newTab);
    }
  };

  public onDeleteClicked(e: BeforeDeleteEvent) {
    if (this.delete.observed) {
      e.cancel = true;
      this.delete.emit(e.data);
    }
  }

  public onDoubleClicked(e: any) {
    if (this.rowDoubleClicked.observed) {
      this.rowDoubleClicked.emit(e);
    }
    else {
      goToUrl(this.router, this.activatedRoute, this.location, this.detailsUrl + e[this.key], false, true, this.newTab);
    }
  }

  public getSelectedRows(selectedContextMenuItem?: any): any[] {
    var selectedRows: any[] = this.grid?.grid?.instance.getSelectedRowsData() || [];

    if (selectedRows.length == 0 && selectedContextMenuItem)
      selectedRows.push(selectedContextMenuItem)

    return selectedRows;
  }

  public onContextMenuPreparing(e: ContextMenuItemClickEvent) {

  }
}
