import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { ArrowStepperItem } from 'projects/libraries/syslink-components/src/lib/arrow-stepper-item/arrow-stepper-item.model';
import { ConfirmModalComponent } from 'projects/libraries/syslink-components/src/public-api';
import { DocumentHeaderStatus } from '../document-header-statuses/document-header-status.model';
import { DocumentStatus } from './document-status.model';

export class DocumentStatusChangedEvent {
  public status: DocumentStatus = new DocumentStatus();
  public documentStatus: DocumentHeaderStatus = new DocumentHeaderStatus();
}

@Component({
  selector: 'app-document-statuses',
  templateUrl: './document-statuses.component.html',
  styleUrls: ['./document-statuses.component.scss']
})
export class DocumentStatusesComponent {

  @Input() statuses: DocumentStatus[] = [];
  @Input() documentStatuses?: DocumentHeaderStatus[] = [];
  @Input() currentStatus?: DocumentHeaderStatus;
  
  @Input() showConfirmMessageSaveDocument: boolean = false;

  private selectedItem?: ArrowStepperItem;
  @Output() statusChanged: EventEmitter<DocumentStatusChangedEvent> = new EventEmitter<DocumentStatusChangedEvent>();
  @ViewChild('changeStatusConfirmModal') changeStatusConfirmModal!: ConfirmModalComponent;

  // Internal only
  // -------------
  public items: ArrowStepperItem[] = [];

  refreshItems() {
    this.items = [];
    // Fetching currentDocumentStatusIndex
    // -----------------------------------
    const currentStatus = this.statuses.find((s: DocumentStatus) => s.Id);
    const currentStatusIndex = currentStatus ? this.statuses.indexOf(currentStatus) : -1;

    // Splitting statuses array between previous and next statuses
    // -----------------------------------------------------------
    let previousStatuses = JSON.parse(JSON.stringify(this.statuses));
    let nextStatuses = [];
    if (currentStatusIndex == -1) {
      previousStatuses = [];
      nextStatuses = this.statuses;
    }
    else {
      nextStatuses = previousStatuses.splice(currentStatusIndex);
    }

    for (const index in this.statuses) {
      
      const status = this.statuses[index];

      // Checking if documentStatuses contains current status
      // ----------------------------------------------------
      const documentStatus = this.documentStatuses?.find((ds: DocumentHeaderStatus) => ds.StatusId?.Id == status.Id);

      // Generate ArrowstepperItem 
      // -------------------------
      this.items.push(new ArrowStepperItem({
        bgColor: status.Color,
        canHover: nextStatuses.map((s: DocumentStatus) => s.Id).includes(status.Id) && (this.documentStatuses?.length || 0) > 0,
        active: documentStatus != undefined,
        isFirst: Number.parseInt(index) == 0,
        isLast: Number.parseInt(index) == this.statuses.length,
        data: {
          status: status,
          documentStatus: documentStatus
        }
      }));
    }
  }

  onItemClicked(item: ArrowStepperItem) {
    if (!item.canHover) return;

    this.selectedItem = item;
    this.changeStatusConfirmModal.modal.open();
  }
  onChangeStatusConfirmed() {
    if (!this.selectedItem) return;

    this.statusChanged.emit({
      status: this.selectedItem.data.status,
      documentStatus: this.selectedItem.data.documentStatus
    });

    this.changeStatusConfirmModal.modal.close();
  }

}
