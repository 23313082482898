import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { tap, catchError, mergeMap } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { NotificationsService } from '../../../../libraries/syslink-components/src/public-api';
import { ApiDomainError } from './api-domain-error';

@Injectable()
export class AppHttpInterceptor implements HttpInterceptor {
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    return next
      .handle(request)
      .pipe(
        catchError((response: HttpErrorResponse) => {
          let error = this.getErrorFromResponse(response);

          return this.extractError(error)
            .pipe(
              tap(apiError => NotificationsService.sendErrorMessage(apiError.message)),
              mergeMap(apiError => {
                return throwError(() => apiError);
              })
            )
        })
      );
  }

  private getErrorFromResponse(response: HttpErrorResponse): string | object | Blob | null {
    if (response.status >= 400 && response.status < 500) {
      return response.error;
    } else if (response.status >= 500) {
      return response.message;
    }
    return null;
  }

  private extractError(error: string | object | Blob | null): Observable<ApiDomainError> {
    if (typeof (error) === 'string') {
      return of(new ApiDomainError("Unknown", error, {}));
    } else if (error instanceof Blob) {
      let observable = new Observable<ApiDomainError>(observer => {
        let reader = new FileReader();
        reader.onloadend = (_) => {
          let result = this.extractErrorFromLoadedBlob(reader.result as string);
          observer.next(result);
        }
        reader.readAsText(error);
      });
      return observable;
    } else if (typeof (error) === 'object' && error !== null) {
      return of(ApiDomainError.fromObject(error));
    }
    return of(ApiDomainError.default());
  }

  private extractErrorFromLoadedBlob(blobContent: string): ApiDomainError {
    try {
      let parsedResult = JSON.parse(blobContent);
      if (typeof (parsedResult?.error) === 'object') {
        return ApiDomainError.fromObject(parsedResult.error);
      } else {
        return ApiDomainError.default();
      }

    }
    catch (e) {
      return ApiDomainError.default();

    }
  }
}
