<syslink-block [title]="'Work Times'">
  <ng-container *ngIf="viewType === WorkTimeViewTypeCode.Grid && globalResult">
    <div class="row gap-2">
      <div class="offset-4 col-1 rounded bg-success text-center text-white border border-dark">
        <div class="h-100 d-flex flex-column justify-content-between">
          <p class="h6 fw-bold my-2"> {{'Duration' | translate}}</p>
          <div>
            <p>{{convertMinuteToTime(globalResult.Duration)}}</p>
          </div>
        </div>
      </div>
    <div class="col-1 rounded text-center border border-secondary">
        <div class="h-100 d-flex flex-column justify-content-between">
          <p class="h6 fw-bold my-2">{{'Duration not billed' | translate}}</p>
          <div>
            <p>{{convertMinuteToTime(globalResult.DurationNotBilled)}}</p>
          </div>
        </div>
      </div>
      <div class="col-1 rounded text-center border border-secondary">
        <div class="h-100 d-flex flex-column justify-content-between">
          <p class="h6 fw-bold my-2">{{'Billable duration' | translate}}</p>
          <div>
            <p>{{convertMinuteToTime(globalResult.BillableDuration)}}</p>
          </div>
        </div>
      </div>
    </div> 
  </ng-container>

  <app-work-time-grid
    #grid
    *ngIf="viewType === WorkTimeViewTypeCode.Grid"
    [permissionKey]="'list'"
    (add)="onAddButtonClicked()"
    (rowDoubleClicked)="onRowDoubleClicked($event)"
    [toolbarActions]="gridToolbarActions"
    [contextMenuItems]="contextMenuItems"      
    (onFilterValueChange)="onFilterValueChange($event)"
  ></app-work-time-grid>

  <app-work-time-scheduler
    #scheduler
    *ngIf="viewType === WorkTimeViewTypeCode.Scheduler"
    [toolbarActions]="SchedulerToolbarActions"
    [canRefresh]="true"
    (add)="onAddButtonClicked()"
    (selectionChanged)="onSchedulerSelectionChanged($event)"
    [appointmentContextMenuItems]="contextMenuItems"
    (validate)="onCreateValidateButtonClicked($event)"
    >
  </app-work-time-scheduler>
</syslink-block>

<app-work-time-modal
  #modal
  (validate)="onCreateValidateButtonClicked($event)"
></app-work-time-modal>
<!-- <app-work-time-filters-popover (valueChanged)="onWorkItemsFiltersPopoverValueChanged($event)"></app-work-time-filters-popover> -->

<syslink-confirm-modal
  #confirmModal
  (onValidate)="onConfirmModalValidated($event)"
></syslink-confirm-modal>

<app-sale-invoice-select-modal
  #saleInvoiceSelectModal
  (validate)="onConfirmModalValidated($event)"
  (onNewInvoiceButtonClicked)="onConfirmModalValidated($event)"
  [expand]="'CurrentStatusLink.StatusId'"
  >
</app-sale-invoice-select-modal>

<app-task-select-modal
  #taskSelectModal
  (validate)="onConfirmModalValidated($event)"
  [expand]="'ThirdId'"
></app-task-select-modal> 

<app-work-time-type-select-modal
  #typeSelectModal
  (validate)="onConfirmModalValidated($event)"
></app-work-time-type-select-modal>