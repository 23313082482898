<ng-container *ngFor="let payment of element">
    <div class="h-100 d-flex mb-1 pb-1 justify-content-center align-items-center gap-2">

        <!-- Payment status -->
        <span class="badge bg-{{payment.PaymentTypeId && payment.PaymentTypeId.Code == 'Refund'? 'blue':
        payment.PaymentStateId && payment.PaymentStateId.Code == 'paid'?'success':
        payment.PaymentStateId && (
            payment.PaymentStateId.Code == 'canceled' ||
            payment.PaymentStateId.Code == 'expired' ||
            payment.PaymentStateId.Code == 'failed')?'danger':      
        'warning'}} rounded-pill text-extra-small">&nbsp;</span>
        
        <!-- Payment label -->
        <div class=" flex-grow-1 align-items-center" [title]="payment.PaymentStateId?.Name">
            {{getPaymentLabel(payment)}}
        </div>

        <!-- Payment amount -->
        <div class="d-flex align-items-right gap-1" [title]="payment.PaymentStateId?.Name">
            {{getPaymentAmount(payment)}}
        </div>

        <!-- Payment editing -->
        <div class="d-flex align-items-center gap-1 mx-1">
            <i
                *ngIf="authService.hasPermission(this.newBasePermissionKey + '.payments.update') && !payment.LinkedDocumentType && payment.PaymentMethodPrimary?.Code!='Invoice' && payment.PaymentMethodPrimary?.Code!='CreditNote' && payment.PaymentMethodPrimary?.Code!='Mollie-Terminal'"
                class="fa-solid fa-pencil text-center text-gray"
                role="button"
                (click)="onUpdateBtnClicked(payment)"
                [title]="'Update' | translate"
            ></i>
            <i
                *ngIf="authService.hasPermission(this.newBasePermissionKey + '.payments.delete') && payment.PaymentMethodPrimary?.Code!='Mollie-Terminal'"
                class="fa-solid fa-trash text-center text-danger"
                role="button"
                (click)="onDeleteBtnClicked(payment)"
                [title]="'Delete' | translate"
            ></i>
        </div>
    </div>
</ng-container>

<div
    *ngIf="authService.hasPermission(this.newBasePermissionKey + '.payments.add')"
    class="full-width-container"
>
    <syslink-button
        class="full-width-button"
        icon="plus"
        (click)="onCreateBtnClicked()"
    ></syslink-button>
</div>

<div class="row mt-2 mx-1">
    <ng-container *ngIf="document && document?.Id">
        <div class="col-9">{{'Amount received' | translate}} :</div>
        <div class="col-3 fw-bolder text-end text-{{document.AmountReceived != 0 ? 'success' : 'secondary'}}">{{(document.AmountReceived??0) | syslinkCurrency:"TotalPrecision"}}</div>

        <div class="col-12 mt-2"></div>

        <div class="col-9">{{'Remaining amount' | translate}} :</div>
        <div class="col-3 fw-bolder text-end text-{{getAmountRemainingColor()}}">{{ (document.AmountRemaining ?? 0) | syslinkCurrency:"TotalPrecision"}}</div>

        <div class="col-12 mt-2"></div>

        <div *ngIf="documentType!='SaleCreditNote'" class="col-12 text-center">+++{{document.StructuredCommunication}}+++</div>
    </ng-container>
</div>


<syslink-confirm-modal
    #deleteConfirm
    title="Delete"
    content="DeleteConfirmMessage"
    validateText="Confirm"
    cancelText="Cancel"
    (onValidate)="onDelete()"
></syslink-confirm-modal>

<syslink-modal
    *ngIf="payment"
    #editModal
    title="payment"
    (onValidate)="editModal.data.newItem==false? onUpdate(payment) : onCreate(payment)"
    [validateText]="(payment.Id ? 'Save' : 'Add')"
    cancelText="Cancel"
>
    <app-payment-details
        #paymentModal
        *ngIf="payment"
        [(payment)]="payment"
        [documentType]="documentType"
        [third]="document?.ThirdId"
        [amountRemaining]="document?.AmountRemaining"
        [filterPaymentType]="filterPaymentType"
        [refundablePayments]="refundablePayments"
        [documentNameFormatted]="document?.NameFormatted"
    ></app-payment-details>
</syslink-modal>