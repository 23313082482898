import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { DocumentData } from '../document-data.model';
import { ConfirmModalComponent, ModalComponent, NotificationsService } from 'projects/libraries/syslink-components/src/public-api';
import { DocumentDatasService } from '../document-datas.service';
import { ViewComponent } from 'projects/libraries/syslink-components/src/lib/helpers/view/view.component';
import { AppInjectorService } from 'projects/libraries/syslink-components/src/lib/services/app-injector.service';
import { ThirdsService } from 'projects/erp-app/src/app/thirds/thirds/thirds.service';
import { ThirdRelation } from 'projects/erp-app/src/app/thirds/thirds/third-relations/third-relation.model';
import { ThirdRelationsService } from 'projects/erp-app/src/app/thirds/thirds/third-relations/third-relations.service';
import { ThirdDocumentDataModalComponent } from '../third-document-data-modal/third-document-data-modal.component';
import { Third } from 'projects/erp-app/src/app/thirds/thirds/third.model';

@Component({
  selector: 'app-third-document-data-list',
  templateUrl: './third-document-data-list.component.html',
  styleUrls: ['./third-document-data-list.component.scss']
})
export class ThirdDocumentDataListComponent extends ViewComponent {
  @Input() public documentDataCollection: DocumentData[] = [];
  @Output() public documentDataCollectionChange: EventEmitter<DocumentData[]> = new EventEmitter<DocumentData[]>();
  
  @Input() public showDeliveryAddress: boolean = false;
  @Input() public thirdFilter: string | Array<string> = "";
  @Input() public detailsUrl: string = "";
  @Input() disabled: boolean = false;
  @Output() public principalThirdChange: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild('editDocumentDataModal') public editDocumentDataModal: ModalComponent = new ModalComponent();
  @ViewChild('deleteConfirm') public deleteConfirm: ConfirmModalComponent = new ConfirmModalComponent();
  @ViewChild('thirdDocumentDataModal') public thirdDocumentDataModal?: ThirdDocumentDataModalComponent;

  public documentData: DocumentData = new DocumentData();
  public relatedThirdFilter: string | Array<string> = "";

  constructor(
    private thirdRelationsService: ThirdRelationsService,
    private documentDatasService: DocumentDatasService
  ) {
    super();
  }

  public async initModalDatas(documentData?: DocumentData) {
    this.documentData = new DocumentData({ ...documentData }) ?? await this.documentDatasService.getInstance(this.documentData);
    if (this.relatedThirdFilter == "") {
      await this.loadRelatedThird();
    }
  }

  public async loadRelatedThird() {
    var primaryThird = (this.documentDataCollection.find((d: DocumentData) => d.HasPrincipal == true))?.ThirdId;
    if (!primaryThird || !primaryThird.Id) return;
    var relations = await this.thirdRelationsService.load({ filter: ['FromThirdId.Id eq ' + primaryThird.Id + 'or ToThirdId.Id eq ' + primaryThird.Id], select: ['FromThirdId.Id', 'ToThirdId.Id'] })
    var ids: number[] = []
    relations.forEach((relation: ThirdRelation) => {
      ids.push(relation.FromThirdId && relation.FromThirdId.Id == primaryThird?.Id ? (relation.ToThirdId?.Id) ?? 0 : (relation.FromThirdId?.Id) ?? 0)
    })
    this.relatedThirdFilter = ['Id in (' + (ids).join(',') + ')'];
  }

  // Create
  // ------
  public async onAddNewDocumentDataBtnClicked() {
    await this.initModalDatas();
    this.editDocumentDataModal.open({ newItem: true });
    setTimeout(() => {
      this.thirdDocumentDataModal?.ChangeThirdFilter();
    }, 50);
  }

  public onCreate(e: DocumentData) {
    if (!this.canCreateOrUpdate(e)) return;
    this.createOrUpdateVerification(e);
    this.updateDeliveryAddress();
    this.documentDataCollection.push(e);
    this.editDocumentDataModal.close();
    this.documentDataCollectionChange.emit(this.documentDataCollection);
    this.principalThirdChange.emit();
  }

  // --------------------------------------------

  // Update
  // ------
  public async updatePrincipal(e: DocumentData, index?: number) {
    if (e.HasPrincipal == true && !this.documentDataCollection.some((e: DocumentData) => e.HasSecondary)) {
      NotificationsService.sendErrorMessage("Select the primary third");
      return;
    }
    if (e.HasSecondary == true) {
      NotificationsService.sendErrorMessage("A secondary third cannot be primary");
      return;
    }
    if (e.HasPrincipal == true && this.documentDataCollection.some((e: DocumentData) => e.HasSecondary)) {
      var secondaryIndex = this.documentDataCollection.findIndex((e: DocumentData) => e.HasSecondary == true)
      this.documentDataCollection[secondaryIndex].HasPrincipal = true;
      this.documentDataCollection[secondaryIndex].HasSecondary = false;
      e.HasPrincipal = false;
      this.principalThirdChange.emit();
      return;
    }
    var oldPrincipalIndex = this.documentDataCollection.findIndex((dd: DocumentData) => dd.HasPrincipal == true);
    if (oldPrincipalIndex >= 0) {
      this.documentDataCollection[oldPrincipalIndex].HasPrincipal = false;
    }
    if (index != undefined) {
      this.documentDataCollection[index].HasPrincipal = true;
    }
    await this.loadRelatedThird();
    this.principalThirdChange.emit();
  }

  public updateSecondary(e: DocumentData, index?: number) {
    if (e.HasPrincipal == true) {
      NotificationsService.sendErrorMessage("A primary third cannot be secondary");
      return;
    }
    if (e.ThirdId.IsProfessional == true) {
      NotificationsService.sendErrorMessage("A professionnal third cannot be secondary");
      return;
    }
    var oldPrincipalIndex = this.documentDataCollection.findIndex((dd: DocumentData) => dd.HasSecondary == true);
    if (oldPrincipalIndex >= 0 && oldPrincipalIndex != index) {
      this.documentDataCollection[oldPrincipalIndex].HasSecondary = false;
    }
    if (index != undefined) {
      this.documentDataCollection[index].HasSecondary = !(this.documentDataCollection[index].HasSecondary);
    }
  }

  public changeDeliveryAddress(e: DocumentData, index: number) {
    this.documentDataCollection[index].UsedDeliveryAddress = !this.documentDataCollection[index].UsedDeliveryAddress;
    this.updateDeliveryAddress(index);
   
  }

  public async onUpdateDocumentDataBtnClicked(e: DocumentData, index: number) {
    await this.initModalDatas(e);
    this.editDocumentDataModal.open({ newItem: false, index: index });
    setTimeout(() => {
      this.thirdDocumentDataModal?.ChangeThirdFilter();
      this.thirdDocumentDataModal?.onThirdChanged(this.documentData.ThirdId);
    }, 50);
  }

  public onUpdate(d: DocumentData, index: number) {
    if (!this.canCreateOrUpdate(d)) return;
    this.createOrUpdateVerification(d, index);
    this.updateDeliveryAddress(index);
    this.documentDataCollection[index] = d;
    this.editDocumentDataModal.close();
    this.documentDataCollectionChange.emit(this.documentDataCollection);
    this.principalThirdChange.emit();
  }
  // --------------------------------------------

  // delete
  // ------
  public onDeleteDocumentDataBtnClicked(d: DocumentData) {
    if (d.HasPrincipal) {
      NotificationsService.sendErrorMessage("A principal cannot be deleted");
      return;
    }
    if (!d.Id) {
      var index = this.documentDataCollection.findIndex((e: DocumentData) =>
        e.ThirdId?.Id == d.ThirdId?.Id &&
        e.AddressId?.Id == d.AddressId?.Id &&
        e.HasPrincipal == d.HasPrincipal &&
        e.HasSecondary == d.HasSecondary &&
        e.HasCC == d.HasCC &&
        e.HasCCI == d.HasCCI
      );
      this.documentDataCollection.splice(index, 1);
      AppInjectorService.config.setModificationGuard(true);
      return;
    }
    this.deleteConfirm.open({ documentDataId: d.Id });
  }

  public async onDelete() {
    this.documentDataCollection = this.documentDataCollection.filter((e: DocumentData) => { return e.Id !== this.deleteConfirm.modal.data.documentDataId });
    this.deleteConfirm.close();
    AppInjectorService.config.setModificationGuard(true);
    this.documentDataCollectionChange.emit(this.documentDataCollection);
    this.principalThirdChange.emit();
  }


  // --------------------------------------------

  private updateDeliveryAddress(index: number = -1){
    for (let i = 0; i < this.documentDataCollection.length; i++) {
      if (i != index) {
        this.documentDataCollection[i].UsedDeliveryAddress = false;
      }
    }
  }
  private canCreateOrUpdate(e: DocumentData): boolean {
    if (!e.ThirdId || !e.ThirdId.Id) {
      NotificationsService.sendErrorMessage("Third cannot be empty");
      return false;
    }

    if (e.HasPrincipal === false && this.documentDataCollection.filter((dd:DocumentData)=> dd.ThirdId.Id != e.ThirdId.Id && dd.HasPrincipal == true).length == 0) {
      NotificationsService.sendErrorMessage("The principal cannot be empty");
      return false;
    }

    if (e.HasSecondary === true && e.ThirdId.IsProfessional == true) {
      NotificationsService.sendErrorMessage("A professionnal third cannot be secondary");
      return false;
    }
    return true;
  }

  private createOrUpdateVerification(e: DocumentData, index?: number) {
    if (e.HasPrincipal == true) {
      var oldPrincipalIndex = this.documentDataCollection.findIndex((dd: DocumentData) => dd.HasPrincipal == true);
      if ((oldPrincipalIndex >= 0 && index == undefined) || oldPrincipalIndex != index) {
        this.documentDataCollection[oldPrincipalIndex].HasPrincipal = false;
        this.documentDataCollection[oldPrincipalIndex].HasSecondary = this.documentDataCollection[oldPrincipalIndex].ThirdId.IsProfessional ? false : true;
      }
    }

    if (e.HasSecondary == true) {
      var oldPrincipalIndex = this.documentDataCollection.findIndex((dd: DocumentData) => dd.HasSecondary == true);
      if ((oldPrincipalIndex >= 0) && (index == undefined || oldPrincipalIndex != index)) {
        this.documentDataCollection[oldPrincipalIndex].HasSecondary = false;
      }
    }
  }
}