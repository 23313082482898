
<syslink-block [title]="'Accounts'">
  <div class="accountTreeList">
    <app-account-treelist
    #treelist
    [permissionKey]="'list'"
    (rowDoubleClicked)="onUpdate($event)"
    (add)="onAdd()"
    ></app-account-treelist>
  </div>
</syslink-block>

<app-account-modal 
#accountModal
(validate)="onValidateModal($event)"
></app-account-modal>
