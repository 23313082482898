<syslink-treelist
    #treelistComponent
    [toolbarActions]="toolbarActions"
    [canView]="canView"
    [canSearch]="canSearch"
    [canRefresh]="canRefresh"
    [canSort]="canSort"
    [canUpdate]="false"
    [canDelete]="canDelete"
    [canFilter]="canFilter"
    [canExport]="canExport"
    [canDblClck]="canDblClck"
    [canAdd]="canAdd"
    [columns]="columns"
    [select]="['Id','Name','AccountTypeId.Id','AccountTypeId.Name','Code','ParentAccountId.Id','Taxable', 'Letterable','NameTranslationId.Id', 'NameTranslationId.TranslatedValue', 'NameTranslationId.Code']"
    [store]="accountsService.store"
    parentIdExpr="ParentAccountId.Id"
    [options]="{editing:{allowAdding:false, allowUpdating:false, allowDeleting:true},sorting:{mode:'none'},selection:{mode:'none'},pager:{visible:false}}"
    [storageKey]="storageKey"
    [showDragIcons]="false"
    [allowReordering]="false"
    [remoteOperations]="{filtering:true, grouping:true, sorting:true}"
    [stateStoringEnabled]="false"
    (doubleClick)="rowDoubleClicked.emit($event)"
></syslink-treelist>