import { Component } from '@angular/core';
import { AppInjectorService } from 'projects/libraries/syslink-components/src/lib/services/app-injector.service';
import { StockMoveDocumentDetailsComponent } from '../../../stock-move-documents/stock-move-documents/stock-move-document-details/stock-move-document-details.component';
import { SyslinkColumn } from 'projects/libraries/syslink-components/src/lib/helpers/SyslinkColumn';
import { StockMoveInternal } from '../stock-move-internal.model';
import { StockMoveInternalsService } from '../stock-move-internals.service';
import { StockMoveInternalLine } from '../../stock-move-internal-lines/stock-move-internal-line.model';
import { StockMoveInternalLinesService } from '../../stock-move-internal-lines/stock-move-internal-lines.service';
import { StockMoveInternalHeaderStatus } from '../../stock-move-internal-header-statuses/stock-move-internal-header-status.model';
import { StockMoveInternalHeaderStatusesService } from '../../stock-move-internal-header-statuses/stock-move-internal-header-statuses.service';
import { StockMoveInternalHeaderHeaderStatus } from '../../stock-move-internal-header-header-statuses/stock-move-internal-header-header-status.model';
import { StockMoveInternalHeaderHeaderStatusesService } from '../../stock-move-internal-header-header-statuses/stock-move-internal-header-header-statuses.service';
import { ActivatedRoute } from '@angular/router';
import { ReportsService } from 'projects/erp-app/src/app/connectors/reports/reports.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ConfigurationsService } from 'projects/erp-app/src/app/base/modules/configurations/configurations.service';
import { DocumentDatasService } from 'projects/erp-app/src/app/base/documents/document-datas/document-datas.service';
import { ThirdsService } from 'projects/erp-app/src/app/thirds/thirds/thirds.service';
import { MailTemplateTypesService } from 'projects/erp-app/src/app/mails/mail-template-types/mail-template-types.service';
import { StockLocationService } from '../../../stock-location/stock-location.service';
import { DocumentsService } from 'projects/erp-app/src/app/base/documents/documents/documents.service';

@Component({
  selector: 'app-stock-move-internal-details',
  templateUrl: '../../../stock-move-documents/stock-move-documents/stock-move-document-details/stock-move-document-details.component.html',
  styleUrls: ['./stock-move-internal-details.component.scss']
})
export class StockMoveInternalDetailsComponent extends StockMoveDocumentDetailsComponent<
  StockMoveInternal,
  StockMoveInternalsService,
  StockMoveInternalLine,
  StockMoveInternalLinesService,
  StockMoveInternalHeaderStatus,
  StockMoveInternalHeaderStatusesService,
  StockMoveInternalHeaderHeaderStatus,
  StockMoveInternalHeaderHeaderStatusesService
>{

  // constructor( 
  //   public override ngxUiLoaderService: NgxUiLoaderService,
  //   public override activatedRoute: ActivatedRoute,
  //   public override reportsService: ReportsService,
  //   public override configurationsService: ConfigurationsService,
  //   public override documentDatasService: DocumentDatasService,
  //   public override thirdsService: ThirdsService,
  //   public override mailTemplateTypesService: MailTemplateTypesService,
  //   public stockLocationService: StockLocationService,
  //   public override documentService: DocumentsService
  //   ){
  //   super(ngxUiLoaderService,activatedRoute,reportsService,configurationsService,documentDatasService,thirdsService,mailTemplateTypesService,documentService);
  // }
  // protected override loadDependencies() {
  //   this.StockMoveDocumentService = AppInjectorService.injector.get(StockMoveInternalsService);
  //   this.StockMoveDocumentLinesService = AppInjectorService.injector.get(StockMoveInternalLinesService);
  //   this.StockMoveDocumentStatusesService = AppInjectorService.injector.get(StockMoveInternalHeaderStatusesService);
  //   this.StockMoveDocumentHeaderStatusesService = AppInjectorService.injector.get(StockMoveInternalHeaderHeaderStatusesService);

  //   this.documentType = "StockMoveInternal";
  //   this.type = "";
  //   this.subModuleCode = "Stocks.Internal";
  //   this.defaultStatusCode = "Stocks.Draft";
  //   this.documentLinesColumns = [...this.StockMoveDocumentService.getDefaultDocumentLineColumns(this.subModuleCode,this.documentType), 
  //     new SyslinkColumn({ order: 6, field: 'FromStockLocationId', label: 'FromStockLocation', cellTemplate: 'select-value', editCellTemplate: 'select-cell', data: { service: this.stockLocationService, displayKey: 'Name' }, width: 250 })
  //   ];
  // }

  // public override canEditDocument(): boolean {
  //   return !this.hasActiveStatus("Stocks.Achieved");
  // }

}
