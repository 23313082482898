import { Injectable } from '@angular/core';
import { MailModel } from './mail.model';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ApiService } from '../../core/api.service';

@Injectable({
  providedIn: 'root'
})
export class MailService {
  public url: string = '/api/Mail';

  constructor(
    private api: ApiService,   
    private ngxUiLoaderService: NgxUiLoaderService
    ) { }

  public async sendMail(mailModel : MailModel){
    this.ngxUiLoaderService.start();
    var result = await this.api.sendRequest(this.url+'/SendMail', 'POST', mailModel);
    this.ngxUiLoaderService.stop();
    return result
  }
}
