import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { TaskParticipant } from '../../../task-participants/task-participant.model';
import { SelectComponent } from 'projects/libraries/syslink-components/src/public-api';

@Component({
  selector: 'app-task-participant-modal',
  templateUrl: './task-participant-modal.component.html',
  styleUrls: ['./task-participant-modal.component.scss']
})
export class TaskParticipantModalComponent {
  @Input() element: TaskParticipant = new TaskParticipant;
  @Output() elementChange: EventEmitter<TaskParticipant> = new EventEmitter<TaskParticipant>();
  
  @Input() public items?: [{ Id:number, FormattedName: string | undefined, user: any, isUser: boolean }];
  @Input() public selectedUser?: { Id:number, FormattedName: string | undefined, user: any, isUser: boolean };
  @Output() selectedUserChange: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild('UserSelect') public UserSelect: SelectComponent = new SelectComponent();

}
