import { User } from "../../../core/auth/users/user.model";
import { SaleDocumentLine } from "../../../sales/sale-documents/sale-document-lines/sale-document-line.model";
import { Task } from "../../tasks/tasks/task.model";
import { Work } from "../../works/works/work.model";

export class WorkTime extends Work {
    public IsBillable: boolean = false;
    public IsBilled: boolean = false;
    
    public EndDate: Date = new Date();
    public UserId?: User;
    public TaskId?: Task;
    public Duration?: number;
    public SaleInvoiceLineId?: SaleDocumentLine;

    constructor(entity?: Partial<WorkTime>) {
        super();
        Object.assign(this, entity);
    }
}
