import { Component, ViewChild } from '@angular/core';
import { ModalComponent, PageComponent } from 'projects/libraries/syslink-components/src/public-api';
import { environment } from 'projects/erp-app/src/environments/environment';
import { GroupPageService } from '../../../base/group-pages/group-page.service';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent extends PageComponent {
  public urlSyslink = environment.base_url;
  public urlDevExpress = 'www.devexpress.com';

  @ViewChild('versionDetail') public versionDetail: ModalComponent = new ModalComponent();

  constructor(
    public groupPageService: GroupPageService
  ) {
    super();
  }

  public onDetailClick() {
    this.versionDetail.open();
  }
}
