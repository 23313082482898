<div class="py-3">
    <syslink-google-places
        *ngIf="showMap"
        #googlePlaceInput
        (onPlace)="onGooglePlace($event)"
        [label]="'Search for an address' | translate"
    ></syslink-google-places>
    <div
        [hidden]="!address.FormattedAddress"
        class="row mt-2"
        #addressContainer
    >
        <div *ngIf="showMap" class="col-12 my-2">
            <syslink-google-map [query]="address.FormattedAddress"></syslink-google-map>
        </div>
        <div class="col-8">
            <syslink-input-text
                label="Street"
                [(model)]="address.Street"
                [disabled]="disabled"
                (focusOut)="onChangeAddress()"
            ></syslink-input-text>
        </div>
        <div class="col-2">
            <syslink-input-text
                label="Number"
                [(model)]="address.Number"
                [disabled]="disabled"
                (focusOut)="onChangeAddress()"
            ></syslink-input-text>
        </div>
        <div class="col-2">
            <syslink-input-text
                label="Box"
                [(model)]="address.Box"
                [disabled]="disabled"
                (focusOut)="onChangeAddress()"
            ></syslink-input-text>
        </div>
        <div class="col-6">
            <syslink-select
                label="Locality"
                [store]="localityService.store"
                [(model)]="address.LocalityId"
                displayKey="LongName"
                [disabled]="disabled"
                (onCustomValue)="onLocalityCustomValue($event)"
                (modelChange)="onChangeAddress()"
            ></syslink-select>
        </div>
        <div class="col-6">
            <syslink-select
                label="Postal code"
                [store]="postalCodeService.store"
                displayKey="Value"
                [(model)]="address.PostalCodeId"              
                (modelChange)="onChangeAddress()"
                [disabled]="disabled"
                (onCustomValue)="onPostalCodeCustomValue($event)"
            ></syslink-select>
        </div>
        <!-- <div class="col-8">
            <syslink-select
                label="Address type"
                [store]="addressTypesService.store"
                [(model)]="address.AddressTypeId"
                [disabled]="disabled"
            ></syslink-select>
        </div> -->
        <!-- <div class="col-4">
            <syslink-switch
                label="Public"
                [(model)]="address.IsPublic"
                [disabled]="disabled"
            ></syslink-switch>
        </div> -->
    </div>
</div>