<div class="row">
    <div class="col-3">
        <syslink-input-date
            name="Date"
            label="Date"
            [(model)]="element.Date"
            [disabled]="disabled"
            type="date"
            format="date"
        ></syslink-input-date>
    </div>
    <div class="col-3">
        <syslink-select
            label="Document"
            [store]="allSaleDocumentsService.store"
            [disabled]="disabled"
            [(model)]="element.DocumentId"
            [select]="['Id', 'NameFormatted']"
            displayKey="NameFormatted"
        ></syslink-select>
    </div>
</div>
<div class="row">
    <div class="col-3">
        <syslink-select
            label="Journal"
            [store]="accountJournalsService.store"
            [(model)]="element.AccountJournalId"
            [disabled]="disabled"
        ></syslink-select>
    </div>
    <div class="col-3"> 
        <syslink-select
            label="Period"
            [store]="accountPeriodService.store"
            [(model)]="element.AccountPeriodId"
            displayKey="Code"
            [disabled]="disabled"
        ></syslink-select>
    </div>
</div>
<div class="row">
    <div class="col-3"> 
        <syslink-select
            label="Third"
            [store]="thirdsService.store"
            [(model)]="element.ThirdId"
            [select]="['Id', 'Fullname', 'AccountingBOBReference']"
            [disabled]="disabled"
            displayKey="Fullname"
            [canView]="true"
            detailsUrl="/basics/thirds/thirds/"
        ></syslink-select>
    </div>
    <div class="col-3"> 
        <div class="row">
            <div class="col-10">
                <syslink-input-text
                *ngIf="element.ThirdId"
                name="BOB reference"
                [(model)]="element.ThirdId.AccountingBOBReference"
                [disabled]="true"
                icon="fa-solid fa-paragraph"
                [maxLength]="10"
                ></syslink-input-text>
            </div>
            <div class="col-2">
                <syslink-button
                icon="fa-solid fa-refresh"
                (onClick)="refreshBOBReference()"
                ></syslink-button>
            </div>
        </div>
    </div>
</div>
   