<div class="row pt-3">
  <div class="col-sm-4 col-xs-12 mt-3 mt-sm-0">
    <syslink-block
      [title]="'Products'"
      [titleBgColor]="'gray'"
      [titleTextColor]="'white'"
      [bordered]="true"
      [titlePadding]="2"
    >
      <syslink-select
        label="Deliverytime"
        [store]="deliveryTimesService.store"
        [(model)]="element.DeliveryTimeId"
      ></syslink-select>
    </syslink-block>
  </div>

  <div class="col-sm-4 col-xs-12">
    <syslink-block
      [title]="'Purchase invoices'"
      [titleBgColor]="'gray'"
      [titleTextColor]="'white'"
      [bordered]="true"
      [titlePadding]="2"
    >
      <syslink-select
        label="Purchase invoices DocumentDelay"
        [store]="documentDelaysService.store"
        [(model)]="element.PurchaseInvoiceDocumentDelayId"
      ></syslink-select>
    </syslink-block>
  </div>
  <div class="col-sm-4 col-xs-12">
    <syslink-block
      [title]="'Purchase credit notes'"
      [titleBgColor]="'gray'"
      [titleTextColor]="'white'"
      [bordered]="true"
      [titlePadding]="2"
    >
      <syslink-select
        label="Purchase credit notes DocumentDelay"
        [store]="documentDelaysService.store"
        [(model)]="element.PurchaseCreditNoteDocumentDelayId"
      ></syslink-select>
    </syslink-block>
  </div>
</div>
