import { Injectable } from '@angular/core';
import { LoadOptions } from 'devextreme/data';
import { ODataService } from 'projects/erp-app/src/app/core/services/oData.service';
import { ThirdContactInformation } from './third-contact-information.model';
import { TranslateService } from '@ngx-translate/core';
import { ApiService } from 'projects/erp-app/src/app/core/api.service';

@Injectable({
  providedIn: 'root'
})
export class ThirdContactInformationsService extends ODataService<ThirdContactInformation> {
  public url = 'ContactInformation';
  public override defaultOptions: LoadOptions = {
    expand: ['ContactTypeId', 'ThirdId', 'ContactQualifierId',
      'AddressId.PostalCodeId', 'AddressId.LocalityId',
      'AddressId.AddressTypeId',
      'AddressId.LocalityId.CountryId'],
    sort: ['IsFavorite desc']
  };
  constructor(private api: ApiService,
    private translateService: TranslateService) {
    super();
  }

  public override format(element: ThirdContactInformation): any {
    var result: any = {
      ...element,
      ThirdId: element.ThirdId?.Id,
      AddressId: element.AddressId?.Id,
      ContactQualifierId: element.ContactQualifierId?.Id,
      ContactTypeId: element.ContactTypeId?.Id,
    };
    delete result.IsChecked
    return result;
  }

  public override async getInstance(params?: Partial<ThirdContactInformation>): Promise<ThirdContactInformation> {
    const third = new ThirdContactInformation({
      Value: this.translateService.instant("New value"),
      IsPublic: true,
      IsFavorite: false,
      UsedForQuote: true,
      UsedForDelivery: true,
      UsedForInvoice: true,
      UsedForNewsletter: true,
      ...params
    })
    return third;
  }
  public async removeThirdontactQualifier(elementId: number) {
    return await this.api.sendRequest('/api/odata/ContactInformation/' + elementId + '/ContactQualifierId/$ref', 'DELETE');
  }

  public isSimilar(obj1: any, obj2: any): boolean {
    if (obj1.Id != obj2.Id) {
      return false;
    }
    else if (obj1.ThirdId.Id != obj2.ThirdId.Id) {
      return false;
    }
    else if (obj1.AddressId?.Id != obj2.AddressId?.Id) {
      return false;
    }
    else if (obj1.ContactQualifierId?.Id != obj2.ContactQualifierId?.Id) {
      return false;
    }
    else if (obj1.ContactTypeId?.Id != obj2.ContactTypeId?.Id) {
      return false;
    }
    else if (obj1.IsPublic != obj2.IsPublic || obj1.Value != obj2.Value || obj1.UsedForQuote != obj2.UsedForQuote || obj1.UsedForDelivery != obj2.UsedForDelivery || obj1.UsedForInvoice != obj2.UsedForInvoice || obj1.IsFavorite != obj2.IsFavorite) {
      return false;
    }

    return true;
  }
}