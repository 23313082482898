import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { ModalComponent, NotificationsService } from 'projects/libraries/syslink-components/src/public-api';
import { TaxRatesService } from '../../../../base/tax-rates/tax-rates.service';
import { SupplierCatalog } from '../../../supplier-catalogs/supplier-catalog.model';
import { SupplierCatalogsService } from '../../../supplier-catalogs/supplier-catalog.service';
import { Product } from '../../product.model';
import { ProductsService } from '../../product.service';
import { AuthService } from '../../../../core/auth/auth.service';
import { SupplierCatalogDiscountOperationService } from '../../../supplier-catalogs/supplier-catalog-discount-operations/supplier-catalog-discount-operation.service';
import { SupplierCatalogGridComponent } from '../../../supplier-catalogs/supplier-catalog-grid/supplier-catalog-grid.component';

@Component({
  selector: 'app-product-supplier-catalogs',
  templateUrl: './product-supplier-catalogs.component.html',
  styleUrls: ['./product-supplier-catalogs.component.scss']
})
export class ProductSupplierCatalogsComponent {
  @Input() public element: Product = new Product();
  @Output() public elementChange: EventEmitter<any> = new EventEmitter<any>();

  public supplierCatalog: SupplierCatalog = new SupplierCatalog();

  @ViewChild('editModal') public editModal: ModalComponent = new ModalComponent();
  @ViewChild('supplierCatalogGrid') public supplierCatalogGrid?: SupplierCatalogGridComponent ;
  
  constructor(
    public supplierCatalogsService: SupplierCatalogsService,
    public supplierCatalogDiscountOperationService: SupplierCatalogDiscountOperationService,
    public productsService: ProductsService,
    public taxRatesService: TaxRatesService,
    public authService:AuthService
  ) { }

  public async initModalDatas(item?: SupplierCatalog) {
    this.supplierCatalog = item ?? await this.supplierCatalogsService.getInstanceWithProduct(this.element,item);
  }

  // Create
  // ------
  public async onCreateBtnClicked() {
    await this.initModalDatas();
    this.editModal.open({ newItem: true });
  }

  public async onCreate(item: SupplierCatalog) {
    if (this.canCreateOrUpdate(item) == false) return;
      if(item.IsFavorite == true){
        this.element.SupplierCatalogs.map((s:SupplierCatalog)=>s.IsFavorite = false);
      }
    item.Id=-(this.element.SupplierCatalogs.length);
    item = this.computeExTaxNetPrice(item);
    this.element.SupplierCatalogs.push(item);
    if(!this.supplierCatalogGrid) return;
    this.supplierCatalogGrid.supplierCatalogs=this.element.SupplierCatalogs;
    this.supplierCatalogGrid.grid?.grid?.instance.refresh();
    this.element = this.productsService.reloadPrice(this.element);
    this.editModal.close();
  }
  // ----------------------------------------------

  // Update
  // ------
  public async onBeforeUpdate(event: any) {
  if (event.key== undefined || this.element.SupplierCatalogs == undefined) return;
  let index: number = this.element.SupplierCatalogs?.findIndex((l: SupplierCatalog) => l.Id == event.key)
  const newData = event.newData;
  const oldData = event.oldData;

  if (index < 0) return;

  for (const key in newData) {
    if (newData.hasOwnProperty(key) && oldData.hasOwnProperty(key)) {
      if (newData[key] !== oldData[key]) {
        switch (key) {
          case 'ExTaxGrossPrice':
          case 'DiscountId':
            (this.element.SupplierCatalogs[index] as any)[key] = newData[key] ?? 0;
            this.element.SupplierCatalogs[index] = this.computeExTaxNetPrice(this.element.SupplierCatalogs[index]);
            this.element = this.productsService.reloadPrice(this.element);
            break;
            case 'IsFavorite':
              if(newData[key]==true){
                this.element.SupplierCatalogs.map((s:SupplierCatalog)=>s.IsFavorite = false);
              }
              (this.element.SupplierCatalogs[index] as any)[key] = newData[key] ?? false;

              this.element = this.productsService.reloadPrice(this.element);
              break;
            case 'SupplierId':
              if(newData[key] == null)              {
                NotificationsService.sendErrorMessage('A supplier catalog must be linked to a supplier');
                return;
              }
              (this.element.SupplierCatalogs[index] as any)[key] = newData[key] ?? 0;
              break;
          default:
            (this.element.SupplierCatalogs[index] as any)[key] = newData[key];
        }
      }
    }
  }
    }
    
  // ----------------------------------------------

  // Delete
  // ------
  public onDeleteBtnClicked(e:any) {
      this.element.SupplierCatalogs = this.element.SupplierCatalogs.filter((l: SupplierCatalog) => l.Id !== e.key);
      this.element = this.productsService.reloadPrice(this.element);
  }

  // ----------------------------------------------

  private computeExTaxNetPrice(item: SupplierCatalog):SupplierCatalog{
    if(!item.ExTaxGrossPrice)
    {
      item.ExTaxGrossPrice =0;
    }
    if(!item.DiscountId || item.DiscountId.Value==undefined ) return item;
    if(item.ExTaxGrossPrice<0 || (item.DiscountId.IsDiscountFixed && item.ExTaxGrossPrice < item.DiscountId.Value) || (!item.DiscountId.IsDiscountFixed && item.ExTaxGrossPrice < item.ExTaxGrossPrice * (1 - item.DiscountId.Value))){
      item.ExTaxNetPrice = item.ExTaxGrossPrice;
    }
    else{
      if(item.DiscountId.IsDiscountFixed){
        item.ExTaxNetPrice = item.ExTaxGrossPrice -  item.DiscountId.Value
      }else{
        item.ExTaxNetPrice = item.ExTaxGrossPrice * (1 -  item.DiscountId.Value)
      }
  }      
  return item;
  }

  private canCreateOrUpdate(item: SupplierCatalog): boolean {
    if (!item.SupplierId || !item.SupplierId.Id) {
      NotificationsService.sendErrorMessage('A supplier catalog must be linked to a supplier');
      return false;
    }

    if (!item.ExTaxGrossPrice) {
      NotificationsService.sendErrorMessage('Price cannot be empty');
      return false;
    }

    return true;
  }
}