<syslink-treelist
  #treelist
  id="document-line-treelist"
  [toolbarActions]="toolbarActions"
  [canView]="canView"
  [canAdd]="false"
  [canDblClck]="canDblClck"
  [canUpdate]="canUpdate"
  [canRefresh]="canRefresh"
  [canFilter]="canFilter"
  [canSort]="canSort"
  [canExport]="canExport"
  [canDelete]="canDelete"
  [canSearch]="canSearch"
  [columns]="columns"
  [items]="documentLines"
  [expand]="expand"
  (onRowPrepared)="onRowPrepared($event)"
  (onBeforeDelete)="deleteLine.emit($event)"
  (onEditingStart)="onChangeEditCell($event)"
  (onFocusedCellChanging)="onFocusedCellChanging($event)"
  [selectedKeys]="selectedKey"
  (selectionChanged)="selectionChanged.emit($event)"
  (onBeforeUpdate)="updateLine.emit($event)"
  (onReorder)="onReorder.emit($event)"
  [parentIdExpr]="'ParentId.Id'"
  [options]="{pager:{visible:false}}"  
  [storageKey]="storageKey"
></syslink-treelist>
