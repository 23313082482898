import { Component, OnInit, ViewChild } from '@angular/core';
import { MailModalComponent } from 'projects/erp-app/src/app/mails/mail-modal/mail-modal.component';
import { SyslinkColumn } from 'projects/libraries/syslink-components/src/lib/helpers/SyslinkColumn';
import { ConfirmModalComponent, ModalComponent, NotificationsService, PageComponent, SelectComponent, SyslinkToolbarActionButton, SyslinkToolbarActionDropDownButton, SyslinkToolbarActionMenu, SyslinkToolbarFileButton } from 'projects/libraries/syslink-components/src/public-api';
import { DocumentStatusesComponent } from '../../document-statuses/document-statuses.component';
import { MailModel } from 'projects/erp-app/src/app/connectors/mail/mail.model';
import { MailTemplateType } from 'projects/erp-app/src/app/mails/mail-template-types/mail-template-type.model';
import { DocumentHeaderStatus } from '../../document-header-statuses/document-header-status.model';
import { ReportsService } from 'projects/erp-app/src/app/connectors/reports/reports.service';
import { DocumentDatasService } from '../../document-datas/document-datas.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { DocumentsService } from '../documents.service';
import { Third } from 'projects/erp-app/src/app/thirds/thirds/third.model';
import { DocumentLine } from '../../document-lines/document-line.model';
import { DocumentCopyModalComponent } from '../../document-copy-modal/document-copy-modal.component';
import { PaymentsService } from '../../../payments/payments.service';
import { DocumentRelationsService } from '../../document-relations/document-relation.service';
import { Entity } from 'projects/erp-app/src/app/core/models/entity';
import { ODataService } from 'projects/erp-app/src/app/core/services/oData.service';
import { DocumentData } from '../../document-datas/document-data.model';
import { Payment } from '../../../payments/payment.model';
import { ModificationService } from 'projects/erp-app/src/app/core/services/modification.service';
import { AppInjectorService } from 'projects/libraries/syslink-components/src/lib/services/app-injector.service';
import { DocumentRelation } from '../../document-relations/document-relation.model';
import { DocumentLineRelation } from '../../document-line-relations/document-relation.model';
import { DocumentLineRelationsService } from '../../document-line-relations/document-relation.service';
import { Observable, switchMap } from 'rxjs';
import { FileData } from 'projects/erp-app/src/app/connectors/filesystem/file-data';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-document-details',
  templateUrl: './document-details.component.html',
  styleUrls: ['./document-details.component.scss']
})
export class DocumentDetailsComponent extends PageComponent implements OnInit {

  public documentUrlController: string = "";

  public element: any;
  public documentType: 'SaleQuote' | 'SaleOrder' | 'SaleInvoice' | 'SaleCreditNote' | 'SaleContract' | 'PurchaseOrder' | 'PurchaseInvoice' | 'PurchaseCreditNote' | 'StockMoveInternal' | 'StockMoveCustomerIncoming' | 'StockMoveCustomerOutgoing' | 'StockMoveSupplierIncoming' | 'StockMoveSupplierOutgoing' = 'SaleQuote';

  // Formatted title
  // ---------------
  public formattedTitle: string = '';

  public loadFormattedTitle(value: string = ''): void {
    this.formattedTitle = this.translateService.instant(this.documentType) + ' ' + (value != '' ? this.translateService.instant(value) : '');
  }
  // -----------------------------------------------------

  protected subModuleCode: string = '';
  protected defaultStatusCode: string = '';

  public selectedKey: number[] = [];
  public toDocument: string = "";
  public documentLinesColumns: SyslinkColumn[] = [];

  // // ViewChild
  @ViewChild('deleteConfirm') deleteConfirm: ConfirmModalComponent = new ConfirmModalComponent();
  @ViewChild('emailModal') public emailModal?: MailModalComponent;
  @ViewChild('documentStatuses') documentStatuses: DocumentStatusesComponent = new DocumentStatusesComponent();
  @ViewChild('copyModal') public copyModal?: DocumentCopyModalComponent;
  @ViewChild('copyModalThirdSelect') public copyModalThirdSelect?: SelectComponent;             // getIfExist
  @ViewChild('saveConfirmModel') public saveConfirmModel?: ConfirmModalComponent;
  @ViewChild('saleDocumentContent') public saleDocumentContent?: any;
  @ViewChild('previewModal') previewModal!: ModalComponent;

  public mailModel?: MailModel;
  public supplierFilter: string | string[] = ["Roles/any(r:r/Code eq 'supplier')"];
  public mailTemplateType: MailTemplateType = new MailTemplateType();
  public customerFilter: string | string[] = ["Roles/any(r:r/Code eq 'customer')"];
  public copyModalFilter: string | string[] = "";

  // Contract
  // --------
  public createFromContractFromDate: Date = new Date();
  public createFromContractToDate: Date = new Date();

  // Preview
  protected previewData: SafeResourceUrl;
  protected previewDocumentType: string = "Report";
  protected previewId: string = "";

  constructor(
    public ngxUiLoaderService: NgxUiLoaderService,
    public reportsService: ReportsService,
    public documentDatasService: DocumentDatasService,
    public documentService: DocumentsService,
    public paymentsService: PaymentsService,
    public documentRelationsService: DocumentRelationsService,
    public documentLineRelationsService: DocumentLineRelationsService,
    public modificationService: ModificationService
    // public mailTemplateTypesService: MailTemplateTypesService
  ) {
    super();
    this.previewData = AppInjectorService.injector.get(DomSanitizer).bypassSecurityTrustResourceUrl("");
  }

  protected loadDependencies(): void { }

  public canEditDocument(): boolean {
    return true;
  }

  public hasActiveStatus(statusCode: string): boolean {
    if (!this.element || !this.element.Statuses) return false;
    return this.element.Statuses?.find((status: DocumentHeaderStatus) => status.StatusId?.Code === statusCode) != undefined;
  }

  public async defaultInitFromParams(element: any) {
    if (this.activatedRoute.snapshot.queryParamMap.has('ThirdId')) {
      element = {
        ...element,
        ThirdId: { Id: this.activatedRoute.snapshot.queryParamMap.get('ThirdId') }
      };
    }
    if (this.activatedRoute.snapshot.queryParamMap.has('Subject')) {
      element = {
        ...element,
        Subject: this.activatedRoute.snapshot.queryParamMap.get('Subject')
      };
    }
    if (this.activatedRoute.snapshot.queryParamMap.has('ExternalReference')) {
      element = {
        ...element,
        ExternalReference: this.activatedRoute.snapshot.queryParamMap.get('ExternalReference')
      };
    }
    return element;
  }

  override async ngOnInit(): Promise<void> {
    // super.ngOnInit();
    await this.refresh();
  }

  // KeyDown
  // -------
  public override onKeyDownSave() { this.update() }
  // -------------------------------------------------

  public async refresh() {
    //   this.updateBreadCrumb(this.element.No);
    this.updateToolbar();
    this.initStatusBar();
    //   await this.updateDocumentData(this.element);
    //   this.ngxUiLoaderService.stop();
  }

  public updateToolbar() {
    this.toolbarActions = [];
    this.toolbarActions.push(new SyslinkToolbarActionButton({ code: 'save', icon: 'save', text: 'Save', onClick: async () => { await this.update() }, hotkey: 'control.s', visible: this.authService.hasPermission(this.newBasePermissionKey + '.update') }));
    if (this.documentType != "SaleContract" && this.documentType != "PurchaseInvoice" && this.documentType != "PurchaseCreditNote") {
      this.toolbarActions.push(new SyslinkToolbarActionButton({ code: 'preview', icon: 'print', text: this.translateService.instant('Preview'), onClick: () => { this.onPreviewModalButtonClicked() }, visible: this.authService.hasPermission(this.newBasePermissionKey + '.preview') }));
      // this.toolbarActions.push(new SyslinkToolbarActionButton({ code: 'preview', icon: 'print', text: 'Preview', onClick: () => { this.onPreviewButtonClicked() }, hotkey: 'control.p', visible: this.authService.hasPermission(this.newBasePermissionKey + '.preview') }));
    }

    if (this.element.Id) {
      this.toolbarActions.push(new SyslinkToolbarFileButton({ entityType: `${this.documentType}Header`, entityId: this.element.Id }));
    }

    if (this.documentType != "SaleContract" && this.documentType != "PurchaseInvoice" && this.documentType != "PurchaseCreditNote" && !this.documentType.includes("Stock")) {
      this.toolbarActions.push(new SyslinkToolbarActionButton({ code: 'email', icon: 'email', text: 'Send mail', onClick: () => { this.onSendMailButtonClicked() }, visible: this.authService.hasPermission(this.basePermissionKey + '.sendMail') }));
    }

    this.toolbarActions.push(new SyslinkToolbarActionButton({ code: 'delete', icon: 'trash', text: 'Delete', onClick: () => { this.onDeleteBtnClicked() }, visible: this.authService.hasPermission(this.basePermissionKey + '.delete') }));

    if (this.element.Id) {

      this.toolbarActions.push(new SyslinkToolbarActionMenu({
        items: [
          {
            icon: 'fa-solid fa-copy', items: [
              {
                // Sales
                // -----
                icon: 'fa-solid fa-euro-sign', text: 'Sale', items: [
                  { text: 'Transform to Quote', onClick: () => { this.transform('Sale', 'SaleQuote'); }, visible: this.documentType !== 'SaleQuote' && this.authService.hasPermission(this.basePermissionKey + '.transformToSaleQuote') },
                  { text: 'Transform to Order', onClick: () => { this.transform('Sale', 'SaleOrder'); }, visible: this.documentType !== 'SaleOrder' && this.authService.hasPermission(this.basePermissionKey + '.transformToSaleOrder') },
                  { text: 'Transform to Invoice', onClick: () => { this.transform('Sale', 'SaleInvoice'); }, visible: this.documentType !== 'SaleInvoice' && this.authService.hasPermission(this.basePermissionKey + '.transformToSaleInvoice') },
                  { text: 'Transform to Credit note', onClick: () => { this.transform('Sale', 'SaleCreditNote'); }, visible: this.documentType !== 'SaleCreditNote' && this.authService.hasPermission(this.basePermissionKey + '.transformToSaleCreditNote') },
                  { text: 'Transform to Contract', onClick: () => { this.transform('Sale', 'SaleContract'); }, visible: this.documentType !== 'SaleContract' && this.authService.hasPermission(this.basePermissionKey + '.transformToSaleContract') }
                ]
              },
              //           {
              //             // Purchases
              //             // ---------
              //             icon: 'fa-solid fa-cart-shopping', text: 'Purchase', items: [
              //               { text: 'Transform to Order', onClick: () => { this.transform('Purchase', 'PurchaseOrder'); }, visible: this.documentType !== 'PurchaseOrder' && this.authService.hasPermission(this.basePermissionKey + '.transformToPurchaseOrder') },
              //               { text: 'Transform to Invoice', onClick: () => { this.transform('Purchase', 'PurchaseInvoice'); }, visible: this.documentType !== 'PurchaseInvoice' && this.authService.hasPermission(this.basePermissionKey + '.transformToPurchaseInvoice') },
              //               { text: 'Transform to Credit note', onClick: () => { this.transform('Purchase', 'PurchaseCreditNote'); }, visible: this.documentType !== 'PurchaseCreditNote' && this.authService.hasPermission(this.basePermissionKey + '.transformToPurchaseCreditNote') },
              //             ]
              //           },
              //           {
              //             // Stocks
              //             // -----
              //             icon: 'fa-solid fa-warehouse', text: 'Stock', items: [
              //               { text: 'Transform to Customerincomings', onClick: () => { this.transform('StockMove', 'StockMoveCustomerIncoming'); }, visible: this.documentType !== 'StockMoveCustomerIncoming' && this.authService.hasPermission(this.basePermissionKey + '.transformToStockMoveCustomerIncoming') },
              //               { text: 'Transform to Customeroutgoings', onClick: () => { this.transform('StockMove', 'StockMoveCustomerOutgoing'); }, visible: this.documentType !== 'StockMoveCustomerOutgoing' && this.authService.hasPermission(this.basePermissionKey + '.transformToStockMoveCustomerOutgoing') },
              //               { text: 'Transform to Supplieroutgoings', onClick: () => { this.transform('StockMove', 'StockMoveSupplierOutgoing'); }, visible: this.documentType !== 'StockMoveSupplierOutgoing' && this.authService.hasPermission(this.basePermissionKey + '.transformToStockMoveSupplierOutgoing') },
              //               { text: 'Transform to Supplierincomings', onClick: () => { this.transform('StockMove', 'StockMoveSupplierIncoming'); }, visible: this.documentType !== 'StockMoveSupplierIncoming' && this.authService.hasPermission(this.basePermissionKey + '.transformToStockMoveSupplierIncoming') },
              //               { text: 'Transform to StockMoveInternal', onClick: () => { this.transform('StockMove', 'StockMoveInternal'); }, visible: this.documentType !== 'StockMoveInternal' && this.authService.hasPermission(this.basePermissionKey + '.transformToStockMoveInternal') },
              //             ]
              //           },
              {
                // Copy
                // ----
                beginGroup: true, text: 'Copy', visible: this.authService.hasPermission(this.basePermissionKey + '.copy'), onClick: () => {
                  this.documentType.includes("Sale") || this.documentType.includes("Customer") ? this.updatecopyModalFilter("customer") : this.updatecopyModalFilter("supplier");
                  if (!this.copyModal) return;
                  this.copyModal.open({
                    subject: this.element.Subject,
                    showThird: this.documentType.includes("StockMoveInternal") ? false : true,
                    showSubject: this.documentType.includes("Stock") ? false : true,
                    showReference: true,
                    reference: this.element.ExternalReference,
                    title: "Copy",
                    showSavingMessage: this.modificationService.hasModifications ? 'The document will be saved' : undefined,
                    thirdFilter: this.copyModalFilter,
                    selectedKey: this.selectedKey
                  });
                }
              },
              {
                // New version
                // -----------
                text: 'New version', visible: this.authService.hasPermission(this.basePermissionKey + '.transformTo' + this.documentType), onClick: () => {
                  let mainReportType;
                  let subReportType;

                  if (this.documentType.includes('Sale')) { mainReportType = 'Sale'; }
                  else if (this.documentType.includes('Purchase')) { mainReportType = 'Purchase'; }
                  else if (this.documentType.includes('StockMove')) { mainReportType = 'StockMove'; }
                  else { return; }
                  subReportType = this.documentType.replace(mainReportType, '');

                  this.transform(mainReportType, mainReportType + subReportType);
                }
              }
            ]
          }
        ]
      }));
      this.afterUpdateToolbar();
    }
  }

  protected afterUpdateToolbar() {
  }


  protected onRegeneratePreviewButtonClicked(event?: Event) {
    this.element.Report = null;
    this.onPreviewModalButtonClicked();
  }

  public async updateDocumentData(e: any) { }

  public onChangeDocumentDataCollection() {
    var principal = (this.element?.DocumentDataCollection?.find((d: DocumentData) => d.HasPrincipal == true))?.ThirdId;

    if (principal != undefined) {
      this.element.ThirdId = principal;
    }
  }

  public initStatusBar() { }


  public async update(showMessage: boolean = true) { }

  // Save
  // ----
  public async saveStatus() {
  }

  public async saveDocumentData() {
    if (!this.element.DocumentDataCollection) return;
    var filter: any = [];
    if (this.documentType.includes('Sale')) {
      filter = ["SaleDocumentHeaderId.Id eq " + this.element.Id];
      this.element.DocumentDataCollection.map((d: DocumentData) => d.SaleDocumentHeaderId = this.element);
    }
    var existingElement = await this.documentDatasService.load({ filter: filter, expand: ['AddressId', 'ThirdId', 'DeliveryAddressId', 'PostalCodeId', 'LocalityId', 'DeliveryPostalCodeId', 'DeliveryLocalityId'] });
    await this.createOrUpdateArray(this.documentDatasService, this.element.DocumentDataCollection, existingElement, ['HasPrincipal', 'HasSecondary', 'HasCC', 'HasCCI', 'AddressId', 'ThirdId', 'UsedDeliveryAddress', 'DeliveryAddressId', 'PostalCodeId', 'LocalityId', 'DeliveryPostalCodeId', 'DeliveryLocalityId']);
    this.element.DocumentDataCollection = await this.documentDatasService.load({ filter: filter, expand: ['ThirdId.ContactInformations.ContactTypeId', 'ThirdId.Addresses.LocalityId', 'ThirdId.Addresses.PostalCodeId', 'AddressId.LocalityId', 'AddressId.PostalCodeId', 'ThirdId.CustomerId.SaleInvoiceDocumentDelayId', 'DeliveryAddressId', 'PostalCodeId', 'LocalityId', 'DeliveryPostalCodeId', 'DeliveryLocalityId'] });
  }

  public async saveLines() { }

  public async savePayments() {
    if (!this.element.Payments) return;
    var filter: any = [];
    if (this.documentType.includes('SaleInvoice')) {
      filter = ["DocumentType eq 'SaleInvoiceHeader' and DocumentId eq " + this.element.Id + ' or ' + "LinkedDocumentType eq 'SaleInvoiceHeader' and LinkedDocumentId eq " + this.element.Id];
      this.element.Payments.map((d: Payment) => { d.DocumentType = "SaleInvoiceHeader"; d.DocumentId = this.element.Id });
    }
    else if (this.documentType.includes('SaleCreditNote')) {
      filter = ["DocumentType eq 'SaleCreditNoteHeader' and DocumentId eq " + this.element.Id + ' or ' + "LinkedDocumentType eq 'SaleCreditNoteHeader' and LinkedDocumentId eq " + this.element.Id];
      this.element.Payments.map((d: Payment) => { d.DocumentType = "SaleCreditNoteHeader"; d.DocumentId = this.element.Id });
    }
    var existingElement = await this.paymentsService.load({ filter: filter, expand: ['PaymentStateId', 'PaymentTypeId', 'PaymentMethodPrimary'] });
    existingElement = existingElement.filter((p: Payment) => p.PaymentMethodPrimary.Code != "Mollie-Terminal");
    await this.createOrUpdateArray(this.paymentsService, this.element.Payments.filter((p: Payment) => p.PaymentMethodPrimary.Code != "Mollie-Terminal"), existingElement, ['Amount', 'RoundedAmount', 'PaymentMethodPrimary', 'Date', 'Note', 'Reference', 'PaymentStateId', 'PaymentTypeId']);
    this.element.Payments = await this.paymentsService.load({ filter: filter, expand: ['PaymentStateId', 'PaymentTypeId', 'PaymentMethodPrimary'] });
  }

  public async saveDocumentRelations() {
    if (!this.element.DocumentRelations) return;
    this.element.DocumentRelations.map((dr: DocumentRelation) => {
      if (dr.ToId == undefined || dr.ToId == null || dr.ToId == 0) {
        dr.ToId = this.element.Id;
      }
    })
    var filter: any = [];
    if (this.documentType.includes('SaleInvoice')) {
      filter = ["(FromType eq 'SaleInvoiceHeader' and FromId eq " + this.element.Id + ") or (ToType eq 'SaleInvoiceHeader' and ToId eq " + this.element.Id + ")"];
    }
    else if (this.documentType.includes('SaleQuote')) {
      filter = ["(FromType eq 'SaleQuoteHeader' and FromId eq " + this.element.Id + ") or (ToType eq 'SaleQuoteHeader' and ToId eq " + this.element.Id + ")"];
    }
    else if (this.documentType.includes('SaleOrder')) {
      filter = ["(FromType eq 'SaleOrderHeader' and FromId eq " + this.element.Id + ") or (ToType eq 'SaleOrderHeader' and ToId eq " + this.element.Id + ")"];
    }
    else if (this.documentType.includes('SaleCreditNote')) {
      filter = ["(FromType eq 'SaleCreditNoteHeader' and FromId eq " + this.element.Id + ") or (ToType eq 'SaleCreditNoteHeader' and ToId eq " + this.element.Id + ")"];
    }
    else if (this.documentType.includes('SaleContract')) {
      filter = ["(FromType eq 'SaleContractHeader' and FromId eq " + this.element.Id + ") or (ToType eq 'SaleContractHeader' and ToId eq " + this.element.Id + ")"];
    }
    var existingElement = await this.documentRelationsService.load({ filter: filter, expand: [] });
    await this.createOrUpdateArray(this.documentRelationsService, this.element.DocumentRelations, existingElement, ['FromId', 'ToId', 'FromType', 'ToType']);
    this.element.DocumentRelations = await this.documentRelationsService.load({ filter: filter, expand: [] });
  }

  public async saveDocumentLineRelations() {
    if (!this.element.DocumentLineRelations) return;
    for (let index = 0; index < this.element.DocumentLineRelations.length; index++) {
      if (this.element.DocumentLineRelations[index].ToId <= 0) continue;
      await this.documentLineRelationsService.insert(this.documentLineRelationsService.format(this.element.DocumentLineRelations[index]))
    }
  }



  public async createOrUpdateArray<T extends Entity>(service: ODataService<T>, listElements: any[], existingElement: any[], keyToCompare: string[] = [], discountService?: any): Promise<void> {

    // Get element to Add
    // ------------------
    var elementToAdd = listElements.filter((s: any) => { return s.Id == undefined || (s.Id != undefined && s.Id <= 0) });
    for (const s of elementToAdd) {
      let oldElementId = s.Id;
      s.Id = undefined;
      var temp = await service.insert(service.format(s));

      if (s.Discount && discountService) {
        if (this.element.DocumentLineRelations) {
          this.UpdateDocumentLineRelation(oldElementId, temp.Id);
        }

        s.Discount.LineId = temp;
        s.Discount.DiscountOperationId = new Object({ "Id": 1 });
        await discountService.insert(discountService.format(s.Discount));
      }
      if (s.Margin && discountService) {
        s.Margin.LineId = temp;
        s.Margin.DiscountOperationId = new Object({ "Id": 2 });
        await discountService.insert(discountService.format(s.Margin));
      }
      s.Id = temp.Id;
    };

    // Get element to Update
    // ---------------------
    var elementToUpdate = listElements.filter((obj1: any) => {
      const obj2: any = existingElement.find((obj2: any) => obj2.Id === obj1.Id);
      return keyToCompare.some((cle: any) => {
        if (obj2 == undefined) return true;
        else if ((obj1[cle] !== undefined && obj2[cle] === undefined) || (obj1[cle] === undefined && obj2[cle] !== undefined)) return true;
        else if (typeof obj1[cle] == 'object' && typeof obj2[cle] == 'object' && ((obj1[cle] === undefined && obj2[cle] === undefined) || (obj1[cle] === null && obj2[cle] === null))) return false;
        else if ((typeof obj1[cle] != 'object' && typeof obj2[cle] == 'object') || (typeof obj1[cle] == 'object' && typeof obj2[cle] != 'object')) return true;
        else if (typeof obj1[cle] == 'object' && typeof obj2[cle] == 'object' && (cle == "Discount" || cle == "Margin") && (obj1[cle].Value != obj2[cle].Value || obj1[cle].IsDiscountFixed != obj2[cle].IsDiscountFixed)) return true;
        else if (typeof obj1[cle] == 'object' && typeof obj2[cle] == 'object' && ((obj1[cle] == undefined && obj2[cle] != undefined) || (obj2[cle] == undefined && obj1[cle] != undefined) || obj1[cle].Id !== obj2[cle].Id)) return true;
        else if (typeof obj1[cle] == 'object' && typeof obj2[cle] == 'object' && obj1[cle].Id === obj2[cle].Id) return false;
        else if (obj1[cle] !== obj2[cle]) return true;
        else return false;
      })
    });
    for (const s of elementToUpdate) {
      if (s.Id) {
        await service.update(s.Id, service.format(s))
      }
      if (s.Discount && s.Discount.Id && discountService) {
        await discountService.update(s.Discount.Id, s.Discount);
      }
      if (s.Margin && s.Margin.Id && discountService) {
        await discountService.update(s.Margin.Id, s.Margin);
      }
    };

    // Get element to Delete
    // ---------------------
    var elementToDelete = existingElement.filter((obj1: any) =>
      !listElements.some((obj2: any) => obj2.Id === obj1.Id)
    );
    for (const s of elementToDelete) {
      if (s.Id) {
        await service.remove(s.Id);
      }
    };
  }

  // DocumentLineRelation
  public UpdateDocumentLineRelation(FromLineElementId: number, ToElementLineId?: number) {
    if (!this.element.DocumentLineRelations || !ToElementLineId) return;
    var index = this.element.DocumentLineRelations.findIndex((l: DocumentLineRelation) => l.ToId == FromLineElementId);
    if (index <= -1) return;
    this.element.DocumentLineRelations[index].ToId = ToElementLineId;
  }

  // delete
  // --------------
  public onDeleteBtnClicked() {
    this.deleteConfirm.open();
  }

  // preview
  // -------
  public async onPreviewButtonClicked() {
    if (this.modificationService.hasModifications) {
      if (!this.saveConfirmModel) return;
      this.saveConfirmModel.content = 'Please confirm saving before preview';
      this.saveConfirmModel.open({ event: 'Preview' });
    }
    else {
      this.reportsService.preview(this.element.Id, this.documentType.includes("StockMove") && this.documentType != "StockMoveInternal" ? "StockMove" : this.documentType, this.element.No + ".pdf", this.documentType);
    }
  }

  public async onPreviewModalButtonClicked() {
    if (!this.documentUrlController || !this.documentType || !this.element) return;
    if (this.modificationService.hasModifications) {
      if (!this.saveConfirmModel) return;
      this.saveConfirmModel.content = 'Please confirm saving before preview';
      this.saveConfirmModel.open({ event: 'Preview' });
    }
    else {
      this.previewDocumentType = "Report";
      this.ngxUiLoaderService.start();
      this.documentService.getReport(this.documentUrlController, this.documentType, this.element)?.pipe(switchMap((file) => this.switchMapToFileData(file))
      )
        .subscribe({
          next: fileData => {
            this.fileDataLoadSuccess(fileData);
          },
          error: _ => this.ngxUiLoaderService.stop()
        })
    }
  }

  public fileDataLoadSuccess(fileData: FileData) { }

  public switchMapToFileData(file: File): Observable<FileData> {
    let contentObservable = new Observable<FileData>(
      observer => {
        let reader = new FileReader();
        reader.onload = () => observer.next({
          fileContent: reader.result as string,
          fileName: file.name
        });
        reader.onerror = reader.onabort = observer.error;
        reader.readAsDataURL(file);
        return {
          unsubscribe: reader.abort
        }
      }
    );
    return contentObservable;

  }
  // Preview Work time
  // -----------------
  public onPreviewWorkTimeButtonClicked() {
    if (this.modificationService.hasModifications) {
      if (!this.saveConfirmModel) return;
      this.saveConfirmModel.content = 'Please confirm saving before preview';
      this.saveConfirmModel.open({ event: 'PreviewWorkTime' });
    }
    else {
      this.previewDocumentType = "WorkTime";
      this.ngxUiLoaderService.start();
      this.documentService.getWorkTimeReportByInvoiceId(this.element)?.pipe(switchMap((file) => this.switchMapToFileData(file))
      )
        .subscribe({
          next: fileData => {
            this.fileDataLoadSuccess(fileData);
          },
          error: _ => this.ngxUiLoaderService.stop()
        })
    }
  }

  public async onConfirmeSavingBeforeEvent(e: { event: 'Preview' | 'PreviewWorkTime' | 'Transform' | 'SendMail', type: string, totype: string }) {
    await this.update();
    this.saveConfirmModel?.close();
    setTimeout(async () => {
      AppInjectorService.config.setModificationGuard(false);
      switch (e.event) {
        case "Preview":
          this.onPreviewModalButtonClicked();
          break;
        case "PreviewWorkTime":
          this.onPreviewWorkTimeButtonClicked();
          break;
        case "Transform":
          this.transform(e.type, e.totype);
          break;
        case "SendMail":
          if (!this.element.Report){
            AppInjectorService.config.setModificationGuard(false);
            await this.onRegeneratePreviewButtonClicked();
          }
          await this.onSendMailButtonClicked();
          this.saveConfirmModel?.close();
          break;
      }
    }, 100);
  }



  // Mail
  // ----
  public async onSendMailButtonClicked() {
  }
  //-------------------------------------------------------------------------

  // Transform
  // ---------
  public async transform(type: string, totype: string) {
    if (this.modificationService.hasModifications) {
      if (!this.saveConfirmModel) return;
      this.saveConfirmModel.content = 'Please confirm saving before transform';
      this.saveConfirmModel.open({ event: 'Transform', type: type, totype: totype });
      return;
    }

    if (!this.copyModal) return;

    // Transform
    // ---------
    if ((this.documentType.includes("Sale") && type == "Sale") || (this.documentType.includes("Purchase") && type == "Purchase")) {
      await this.documentTransform(totype);
      return;
    }
    // if ((this.documentType.includes("StockMove") && type == "StockMove" && totype.includes("Customer") && this.element.ThirdId.HasCustomerRole) || (this.documentType.includes("StockMove") && type == "StockMove" && totype.includes("Supplier") && this.element.ThirdId.HasSupplierRole)) {
    //   this.documentTransform(totype);
    //   return;
    // }
    // else if ((this.documentType.includes("StockMove") && type == "StockMove" && !this.element.ThirdId.HasCustomerRole)) {
    //   this.updatecopyModalFilter("customer");
    //   if (this.element.Lines.filter((l: DocumentLine) => l.LineType == "product" && l.ProductId && l.ProductId.Sellable == false).length > 0) {
    //     NotificationsService.sendInfo("Some lines may not be sellable");
    //   }
    //   this.documentTransform(totype, true);
    //   return;
    // }
    // else if ((this.documentType.includes("StockMove") && type == "StockMove" && !this.element.ThirdId.HasSupplierRole)) {
    //   this.updatecopyModalFilter("supplier");
    //   if (this.element.Lines.filter((l: DocumentLine) => l.LineType == "product" && l.ProductId && l.ProductId.Purchasable == false).length > 0) {
    //     NotificationsService.sendInfo("Some lines may not be sellable");
    //   }
    //   this.documentTransform(totype, true);
    //   return;
    // }
    // else if (this.documentType.includes("StockMove") && totype == "StockMoveInternal") {
    //   this.documentTransform(totype, false);
    //   return;
    // }

    // Convert
    // -------
    if ((this.documentType.includes("Sale") && type == "Purchase")) {
      this.updatecopyModalFilter("supplier");
      if (this.element.Lines.filter((l: DocumentLine) => l.LineType == "product" && l.ProductId && l.ProductId.Purchasable == false).length > 0) {
        NotificationsService.sendInfo("Some lines may not be purchasable");
      }
    }
    // else if ((this.documentType.includes("Purchase") && type == "Sale")) {
    //   this.updatecopyModalFilter("customer");
    //   if (this.element.Lines.filter((l: DocumentLine) => l.LineType == "product" && l.ProductId && l.ProductId.Sellable == false).length > 0) {
    //     NotificationsService.sendInfo("Some lines may not be sellable");
    //   }
    // }
    // else if ((this.documentType.includes("StockMove") && type == "Purchase")) {
    //   this.updatecopyModalFilter("supplier");
    //   if (this.element.Lines.filter((l: DocumentLine) => l.LineType == "product" && l.ProductId && l.ProductId.Purchasable == false).length > 0) {
    //     NotificationsService.sendInfo("Some lines may not be purchasable");
    //   }
    // }
    // else if ((this.documentType.includes("StockMove") && type == "Sale")) {
    //   this.updatecopyModalFilter("customer");
    //   if (this.element.Lines.filter((l: DocumentLine) => l.LineType == "product" && l.ProductId && l.ProductId.Sellable == false).length > 0) {
    //     NotificationsService.sendInfo("Some lines may not be sellable");
    //   }
    // }
    else if ((this.documentType.includes("Sale") && totype.includes("Supplier"))) {
      this.updatecopyModalFilter("supplier");
    }
    else if ((this.documentType.includes("Purchase") && totype.includes("Customer"))) {
      this.updatecopyModalFilter("customer");
    }

    if ((totype.includes("StockMove"))) {
      if (this.element.Lines.filter((l: DocumentLine) => l.LineType == "product" && l.ProductId && l.ProductId.Stockable == false).length > 0) {
        NotificationsService.sendInfo("Some lines may not be stockable");
      }
    }

    if (!this.copyModal) return;
    this.copyModal.open({
      subject: this.element.Subject,
      type: type,
      toType: totype,
      showThird: totype.includes("StockMoveInternal") || ((totype.includes("Customer") && this.documentType.includes("Sale")) || (totype.includes("Supplier") && this.documentType.includes("Purchase"))) ? false : true,
      showSubject: totype.includes("StockMove") ? false : true,
      title: "Copy",
      thirdFilter: this.copyModalFilter,
      selectedKey: this.selectedKey
    })
  }

  public updatecopyModalFilter(role: string = "customer" || "supplier") {
    if (role == "customer") {
      this.copyModalFilter = this.customerFilter;
    }
    if (role == "supplier") {
      this.copyModalFilter = this.supplierFilter;
    }
    this.copyModalThirdSelect?.forceReload();
  }

  public async documentTransform(toType: string, showThird: boolean = false) {
    if (this.selectedKey.length != 0 || showThird) {
      this.copyModal?.open({
        toDocument: toType,
        showThird: showThird,
        title: "Transform",
        thirdFilter: this.copyModalFilter,
        selectedKey: this.selectedKey
      });
      return;
    }
    await this.swithToTransformModel(toType, undefined);
  }

  public async swithToTransformModel(toType: string, selectedKey: number[] | undefined, third: Third | undefined = undefined) {
    this.ngxUiLoaderService.start();

    var currentElement = JSON.parse(JSON.stringify(this.element));
    currentElement.Id = null;

    currentElement.DocumentRelations = [];
    currentElement.dateFrom = undefined;
    currentElement.Payments = [];
    currentElement.No = undefined;
    currentElement.DocumentDataCollection = currentElement.DocumentDataCollection.map((d: DocumentData) => { d.Id = undefined; return d; })
    currentElement.StructuredCommunication = undefined;
    delete currentElement['CreatedAt'];
    delete currentElement.CurrentStatusLink['UpdatedAt'];
    currentElement = await this.copyLoadDate(currentElement, currentElement.ThirdId);
    currentElement = await this.copyLoadLine(currentElement, toType);
    if (toType == this.documentType) {
      currentElement = await this.copyLoadStatus(currentElement);

    }
    if (toType != this.documentType) {
      delete currentElement['@odata.context'];
      currentElement.Lines.forEach((line: any) => {
        delete line['@odata.type'];
      });
      currentElement = await this.getDefaultToTypeStatusElement(currentElement, toType);
    }
    if (this.documentType == "SaleOrder") {
      delete currentElement['DocumentDelayId'];
    }

    // Adding relation
    currentElement.DocumentRelations.push(new DocumentRelation({ FromId: this.element.Id, FromType: this.documentType + "Header", ToType: toType + "Header" }));

    localStorage.setItem("SaleHeader", JSON.stringify(currentElement));
    this.goToDocumentTypeUrlCopy(toType);
  }

  public async onTransformTo(toType: string, toUrl: string, newTab: boolean = false, type: string = "", idList?: number[], third: Third | undefined = undefined, fromDate: Date = new Date(), toDate: Date = new Date()) {
    if (!this.element.Id) return;
    this.ngxUiLoaderService.start();
    let id = await this.documentService?.TransformToType(this.documentUrlController, toType, this.element.Id, type, idList, third?.Id, fromDate, toDate);
    this.goToUrl(toUrl + id, undefined, undefined, newTab);
    this.ngxUiLoaderService.stop();
  }
  public async getDefaultToTypeStatusElement(currentElement: any, toType: string) {

  }
  //-------------------------------------------------------------------------
  // Copy
  // ----
  public async onCopyButtonClicked(e: any) {
    if (e.data.showSavingMessage) {
      await this.update();
      // TODO Check better method
      setTimeout(() => {
        AppInjectorService.config.setModificationGuard(false);
      }, 100);
    }
    if (!this.element.Id) return;
    if (e.data.toDocument != null && e.data.toDocument != "") {
      if (((e.data.toDocument.includes('Supplier') && this.documentType.includes('Customer')) || (e.data.toDocument.includes('Customer') && this.documentType.includes('Supplier'))) && (!e.data.ThirdId || !e.data.ThirdId.Id)) {
        NotificationsService.sendErrorMessage("Third cannot be empty");
        return;
      }
      await this.swithToTransformModel(e.data.toDocument, e.data.selectedKey, e.data.ThirdId);
      return;
    }
    if ((e.data.toType && !e.data.toType.includes("StockMove")) && (!e.data.ThirdId || !e.data.ThirdId.Id)) {
      NotificationsService.sendErrorMessage("Third cannot be empty");
      return;
    }
    this.ngxUiLoaderService.start();

    if (e.data.type != null && e.data.type != "") {
      await this.convert(e.data, e.data.selectedKey);
    }
    else {
      await this.copy(e.data, e.data.selectedKey);
    }
    this.copyModal?.close();
    this.ngxUiLoaderService.stop();
  }

  public async copy(e: { subject: string, ThirdId: Third, reference: string }, selectedKey?: number[]) {
    if (this.documentType != "StockMoveInternal" && (!e.ThirdId || !e.ThirdId.Id)) {
      NotificationsService.sendErrorMessage("Third cannot be empty");
      return;
    }

    this.ngxUiLoaderService.start();

    var currentElement = JSON.parse(JSON.stringify(this.element));
    currentElement.Id = null;

    currentElement.DocumentRelations = [];
    currentElement.dateFrom = undefined;
    currentElement.No = undefined;
    currentElement.Subject = e.subject;
    currentElement.ExternalReference = e.reference;
    currentElement.StructuredCommunication = undefined;
    currentElement.Payments = [];
    currentElement = await this.copyLoadDate(currentElement, e.ThirdId);
    currentElement = await this.copyLoadLine(currentElement);
    currentElement = await this.copyLoadDocumentData(currentElement, e.ThirdId);
    currentElement = await this.copyLoadStatus(currentElement);

    if (this.documentType == "SaleOrder") {
      delete currentElement['DocumentDelayId'];
    }
    localStorage.setItem("SaleHeader", JSON.stringify(currentElement));
    this.goToDocumentTypeUrlCopy(this.documentType);

  }

  public async copyLoadDocumentData(currentElement: any, selectedThird: Third) {
    currentElement.DocumentDataCollection = [];
    currentElement.ThirdId = selectedThird;
    currentElement.DocumentDataCollection.push(await this.documentDatasService.getInstance(undefined, this.documentType, undefined, selectedThird));
    return currentElement;
  }

  public async copyLoadDate(currentElement: any, selectedThird: Third) {
    currentElement.Date = new Date();
    currentElement.DocumentDelayId = selectedThird.CustomerId?.SaleInvoiceDocumentDelayId;
    currentElement.Deadline = await this.documentService.computeDeadline(currentElement.Date, currentElement.DocumentDelayId?.Id ?? 3);;
    return currentElement;
  }

  public async copyLoadLine(currentElement: any, toType?: string) {
    currentElement.DocumentLineRelations = [];
    for (let index = 0; index < currentElement.Lines.length; index++) {
      let originalDocumentLine = JSON.parse(JSON.stringify(currentElement.Lines[index]));
      currentElement.Lines[index].Id = -(index + 1);
      currentElement.Lines[index].HeaderId = undefined;
      if (currentElement.Lines[index].Margin) { currentElement.Lines[index].Margin.Id = undefined; }
      if (currentElement.Lines[index].Discount) { currentElement.Lines[index].Discount.Id = undefined; }

      if (toType && toType != this.documentType) {
        currentElement.DocumentLineRelations.push(new DocumentLineRelation({
          FromId: originalDocumentLine.Id,
          ToId: -(index + 1),
          FromType: this.documentType + "Line",
          ToType: toType + "Line"
        }))
      }
    }
    for (let index = 0; index < currentElement.Lines.length; index++) {
      if (currentElement.Lines[index].ParentId && currentElement.Lines[index].ParentId.Id) {
        currentElement.Lines[index].ParentId = currentElement.Lines.find((l: any) => l.LineNo == currentElement.Lines[index].ParentLineNo);
      }
    }
    return currentElement;
  }

  private goToDocumentTypeUrlCopy(goToDocumentType: string) {
    switch (goToDocumentType) {
      case "SaleQuote":
        this.goToUrl("/sales/quotes/copy");
        break;
      case "SaleOrder":
        this.goToUrl("/sales/orders/copy");
        break;
      case "SaleInvoice":
        this.goToUrl("/sales/invoices/copy");
        break;
      case "SaleCreditNote":
        this.goToUrl("/sales/credit-notes/copy");
        break;
      case "SaleContract":
        this.goToUrl("/sales/contracts/copy");
        break;
    }
  }

  public async copyLoadStatus(currentElement: any) {
    currentElement.CurrentStatusLink = currentElement.Statuses.find((s: any) => { return s.StatusId.Sequence == 1 });
    currentElement.CurrentStatusLink.Id = undefined;
    currentElement.CurrentStatusLink.Date = new Date();
    currentElement.Statuses = [];
    currentElement.Statuses.push(currentElement.CurrentStatusLink);
    return currentElement;
  }

  public async onCopyTo(toType: string, toUrl: string, newTab: boolean = false, subject: string, third: number, idList?: number[], fromDate: Date = new Date(), toDate: Date = new Date()) {
    if (!this.element.Id) return;
    this.ngxUiLoaderService.start();
    let id = await this.documentService?.CopyToType(this.documentUrlController, toType, this.element.Id, subject, third, idList, fromDate, toDate);
    this.goToUrl(toUrl + id, undefined, undefined, newTab);
    this.ngxUiLoaderService.stop();
  }
  //-------------------------------------------------------------------------

  public async convert(e: { subject: string, ThirdId: Third, type: string, toType: string }, selectedKey?: number[]) {

    // if (e.toType == "SaleQuote" && e.type == 'Sale') {
    //   await this.onConvertTo('SaleQuote', '/sales/quotes/', true, e.subject, e.ThirdId.Id, selectedKey);
    // }
    // else if (e.toType == "SaleOrder" && e.type == 'Sale') {
    //   await this.onConvertTo('SaleOrder', '/sales/orders/', true, e.subject, e.ThirdId.Id, selectedKey);
    // }
    // else
    if (e.toType == "SaleQuote" && e.type == 'Sale') {
      await this.onConvertTo('SaleQuote', '/sales/orders/', true, e.subject, e.ThirdId.Id, selectedKey);
    }
    else if (e.toType == "SaleOrder" && e.type == 'Sale') {
      await this.onConvertTo('SaleOrder', '/sales/orders/', true, e.subject, e.ThirdId.Id, selectedKey);
    }
    else if (e.toType == "SaleInvoice" && e.type == 'Sale') {
      await this.onConvertTo('SaleInvoice', '/sales/invoices/', true, e.subject, e.ThirdId.Id, selectedKey);
    }
    else if (e.toType == "SaleCreditNote" && e.type == 'Sale') {
      await this.onConvertTo('SaleCreditNote', '/sales/credit-notes/', true, e.subject, e.ThirdId.Id, selectedKey);
    }
    else if (e.toType == "SaleContract" && e.type == 'Sale') {
      await this.onConvertTo('SaleContract', '/sales/contracts/', true, e.subject, e.ThirdId.Id, selectedKey);
    }
    // else if (e.toType == "PurchaseOrder" && e.type == 'Purchase') {
    //   await this.onConvertTo('PurchaseOrder', '/purchases/orders/', true, e.subject, e.ThirdId.Id, selectedKey);
    // }
    // else if (e.toType == "PurchaseInvoice" && e.type == 'Purchase') {
    //   await this.onConvertTo('PurchaseInvoice', '/purchases/invoices/', true, e.subject, e.ThirdId.Id, selectedKey);
    // }
    // else if (e.toType == "PurchaseCreditNote" && e.type == 'Purchase') {
    //   await this.onConvertTo('PurchaseCreditNote', '/purchases/credit-notes/', true, e.subject, e.ThirdId.Id, selectedKey);
    // }
    // else if (e.toType == "StockMoveCustomerOutgoing" && e.type == 'StockMove') {
    //   await this.onConvertTo('StockMoveCustomerOutgoing', '/stockmoves/customeroutgoings/', true, e.subject, e.ThirdId?.Id, selectedKey);
    // }
    // else if (e.toType == "StockMoveCustomerIncoming" && e.type == 'StockMove') {
    //   await this.onConvertTo('StockMoveCustomerIncoming', '/stockmoves/customerincomings/', true, e.subject, e.ThirdId?.Id, selectedKey);
    // }
    // else if (e.toType == "StockMoveSupplierIncoming" && e.type == 'StockMove') {
    //   await this.onConvertTo('StockMoveSupplierIncoming', '/stockmoves/supplierincomings/', true, e.subject, e.ThirdId?.Id, selectedKey);
    // }
    // else if (e.toType == "StockMoveSupplierOutgoing" && e.type == 'StockMove') {
    //   await this.onConvertTo('StockMoveSupplierOutgoing', '/stockmoves/supplieroutgoings/', true, e.subject, e.ThirdId?.Id, selectedKey);
    // }
    // else if (e.toType == "StockMoveInternal" && e.type == 'StockMove') {
    //   await this.onConvertTo('StockMoveInternal', '/stockmoves/internalstockmoves/', true, e.subject, e.ThirdId?.Id, selectedKey);
    // }
  }

  public async onConvertTo(toType: string, toUrl: string, newTab: boolean = false, subject: string, third: number = 0, idList?: number[], fromDate: Date = new Date(), toDate: Date = new Date()) {
    if (!this.element.Id) return;
    this.ngxUiLoaderService.start();
    let id = await this.documentService?.ConvertToType(this.documentUrlController, toType, this.element.Id, subject, third, idList, fromDate, toDate);
    this.goToUrl(toUrl + id, undefined, undefined, newTab);
    this.ngxUiLoaderService.stop();
  }
}