import { CurrencyPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { AppInjectorService } from '../services/app-injector.service';

@Pipe({ name: 'syslinkCurrency' })
export class SyslinkCurrencyPipe implements PipeTransform {
    constructor(
        private currencyPipe: CurrencyPipe
    ) { }

    transform(value?: number, key: string = '', display: string = 'symbol'): string {
        if (value === undefined) return '';
        const precision = AppInjectorService.config.getPrecision( key);
        return this.currencyPipe.transform(value, undefined, display, `1.${precision}-${precision}`) || value.toPrecision(precision || 0);
    }
}