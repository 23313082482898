import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Task } from '../task.model';
import { ThirdsService } from 'projects/erp-app/src/app/thirds/thirds/thirds.service';

@Component({
  selector: 'app-task-third',
  templateUrl: './task-third.component.html',
  styleUrls: ['./task-third.component.scss']
})
export class TaskThirdComponent {
  @Input() public element: Task = new Task();
  @Output() public elementChange: EventEmitter<Task> = new EventEmitter<Task>();

  constructor(
    public thirdsService:ThirdsService
  ){}
}
