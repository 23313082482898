import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import DataSource from 'devextreme/data/data_source';
import ODataStore from 'devextreme/data/odata/store';
import { InputComponent } from '../input/input.component';
import { DxTagBoxComponent } from 'devextreme-angular';

@Component({
  selector: 'syslink-tagbox',
  templateUrl: './tagbox.component.html',
  styleUrls: ['./tagbox.component.scss']
})
export class TagboxComponent extends InputComponent implements OnChanges {

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['model']) {
      this.formatModel();
    }

    if (changes['filter']) {
      this.forceReload();
    }
  }

  public override class: string = 'py-2';

  public formatedModel: Array<number> = [];

  // Local items
  // -----------
  @Input() items?: any[];
  @Output() itemsChange: EventEmitter<any[]> = new EventEmitter<any[]>();

  @ViewChild("tagbox") tagbox?: DxTagBoxComponent;

  // Remote items
  // ------------
  public dataSource: DataSource = new DataSource({});
  @Input() public store?: ODataStore;
  @Input() public expand?: string | string[];
  @Input() public select?: string | string[];
  @Input() public filter?: string | string[];

  @Input() public displayKey: string = 'Name';
  @Input() public valueKey: string = 'Id';
  @Input() public colorKey: string = 'Color';

  @Input() public showSelectionControls: boolean = false;
  @Input() public allowSearch: boolean = true;
  @Input() public acceptCustomValue: boolean = false;

  // @Input() public detailsUrl?: string;
  // @Input() public canView: boolean = false;
  // @Input() public canAdd: boolean = false;
  @Input() public emailLabel?: string;

  // @Output() public onShowAdvancedSearch: EventEmitter<string> = new EventEmitter<string>();
  @Output() public onCustomValueChange: EventEmitter<any> = new EventEmitter<any>();

  ngOnInit(): void {
    this.formatModel();

    if (!this.store) return;

    this.items = undefined;

    this.dataSource = new DataSource({
      store: this.store,
      sort: [this.displayKey],
      expand: this.expand,
      select: this.select,
      filter: this.filter
    });
  }

  public formatModel() {
    if (JSON.stringify(this.formatedModel) === JSON.stringify(this.model)) return;

    this.formatedModel = this.model?.map((item: any) => item[this.valueKey]) ?? [];
  }

  public getDisplayValue(item: any): string {
    if (!item) return '';

    let value: any = item;
    this.displayKey.split('.').forEach((key: string) => {
      //TODO Check if adding '?' after value doesn't break anything.
      value = value?.hasOwnProperty(key) ? value[key] : value
    });

    return typeof value === 'string' ? value : '';
  }

  public getEmail(third: any): string {
    if (!third.CommunicationMethods) return this.translateService?.instant('No billing email');
    const cm = third.CommunicationMethods.find((cm: any) => cm.CommunicationLabelId?.Code === this.emailLabel && cm.CommunicationTypeId?.Code === 'email');
    if (!cm) return this.translateService?.instant('No billing email');
    return cm.Value;
  }

  public async onValueChanged(event: any) {
    this.updateModel(event.value);
  }

  public removeTag(key: any) {
    const value = this.formatedModel.filter(item => item.toString() !== key.toString());
    this.updateModel(value);
  }

  private updateModel(value: Array<any>): void {
    if (JSON.stringify(this.formatedModel) === JSON.stringify(value)) return;

    this.formatedModel = value;

    const items = (this.store ? this.dataSource.items() : this.items) || [];
    this.model = this.formatedModel.map((key: any) => [...items, ...this.model].find(item => item[this.valueKey].toString() === key.toString()));
    this.onModelChange(this.model);
  }

  private reloadDatasource() {
    this.dataSource = new DataSource({
      store: this.store,
      sort: [this.displayKey],
      filter: this.filter,
      expand: this.expand,
      select: this.select
    })
  }

  public forceReload(): void {
    this.reloadDatasource();
    this.tagbox?.instance.getDataSource().reload();
  }
}
