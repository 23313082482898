<syslink-modal width="40vw" #modal [title]="title" (onValidate)="onValidate($event)" (onCancel)="close()">
  <div class="row">
    <div class="col-9">
      <syslink-input-text label="Subject" [(model)]="modal.data.Subject"></syslink-input-text></div>
    <div class="col-3">
      <syslink-select
      label="Status"
      [store]="taskStatusesService.store"
      [(model)]="modal.data.StatusId"
    ></syslink-select>
    </div>
  </div>
  <div class="row">
    <div class="col-6">
      <syslink-select label="Third" displayKey="Fullname" [store]="thirdsService.store" [(model)]="modal.data.ThirdId"
        [filter]="[['IsSupplier eq true'], 'or', ['IsCustomer eq true']]"
        [canAdd]="authService.hasPermission('settings-thirds-thirds') && authService.hasPermission('basics.thirds.thirds.list.add')"
        [canView]="authService.hasPermission('settings-thirds-thirds')" detailsUrl="/basics/thirds/thirds/"
        [canRefresh]="true"></syslink-select>
    </div>
    <div class="col-3 py-2">
      <syslink-select label="IsBillable" [store]="taskBillingTypesService.store"
        [(model)]="modal.data.TaskBillingTypeId" [disabled]="
          (modal.data.BilledTime != 0 || modal.data.AmountInvoiced != 0) &&
          modal.data.IsBillable
        "></syslink-select>


      <syslink-input-number *ngIf="modal.data.TaskBillingTypeId?.Code == 'FixedPrice'" label="Fixed price"
        [(model)]="modal.data.FixedPrice" [min]="0" [disabled]="
          (modal.data.BilledTime != 0 || modal.data.AmountInvoiced != 0) &&
          modal.data.TaskBillingTypeId?.Code != 'NotBillable'
        "></syslink-input-number>
    </div>
    <div class="col-3">
      <syslink-input-date label="Deadline" format="date" type="date" [(model)]="modal.data.EndDate"
        [min]="modal.data.StartDate"></syslink-input-date>
    </div>
  </div>

  <div class="row">
    <syslink-wysiwyg name="Description" label="Description" [(model)]="modal.data.Description" [showTable]="false"
      height="auto" class="mt-1"></syslink-wysiwyg>
  </div>

  <syslink-tagbox #usersTagbox label="Users" valueKey="Id" [items]="userAndUserGroupItems" displayKey="FormattedName"
    [(model)]="modal.data.tagBoxTaskParticipants"></syslink-tagbox>
   
  <syslink-button *ngIf="modal.data.Id" [text]="'Add workTime'"
    (onClick)="onAddWorkTimeButtonClicked()"></syslink-button>

  <ng-container *ngIf="modal.data.Id">
    <hr />

    <div class="my-3 text-center">
      <syslink-link text="View more" (onClick)="onViewMoreClicked()"></syslink-link>
    </div>
  </ng-container>
</syslink-modal>

<app-work-time-modal #worktimeModal (validate)="onValidateWorkTimeCreation($event)"
  [canEditTask]="false"   [updatingPermissionKey]="this.newBasePermissionKey + '.details.workTimes.list.doubleClick'"
  [addingPermissionKey]="this.newBasePermissionKey + '.details.workTimes.list.add'" ></app-work-time-modal>