<div>
    <syslink-select
        #syslink_select
        [label]="label"
        [store]="store"
        [filter]="filter"
        [displayKey]="displayKey"
        (modelChange)="onChanged($event)"
        [expand]="expand"
        (onCustomValue)="onCustomItem($event)"
        [canRefresh]="canRefresh"
        [canAdd]="canAdd"
        [canView]="canView"
        [allowAddCustomItem]="allowAddCustomItem"
        [usedModificationDetect]="usedModificationDetect"
    >
    </syslink-select>
</div>
<div>
    <div
        class="dx-tag"
        *ngFor="let element of model"
    >
        <div
            class="dx-tag-content text-white"
            [style.background-color]="'#1a2e38'"
        >
            <div class="d-flex flex-column">
                <div>
                    <span>{{element.ThirdId?.Fullname}}</span>
                    <div
                        id="{{element.Id}}-remove-item"
                        (click)="onRemoveElement(element)"
                        class="dx-tag-remove-button"
                    ></div>
                </div>
                <div class="mt-1"><span>{{element.Value}}</span></div>
            </div>
        </div>
    </div>
</div>