<div class="row">
  <div class="col-3">
      <syslink-tagbox
        label="Users"
        [store]="usersService.store"
        [(model)]="users"
        [select]="['Oid', 'Name']"
        displayKey="Name"
        valueKey="Oid"
        (modelChange)="userChange($event)"
      ></syslink-tagbox>
  </div>
  <div class="col-3">
    <syslink-tagbox
      label="Third"
      [store]="thirdsService.store"
      [select]="['Id', 'Fullname']"
      [(model)]="thirds"
      displayKey="Fullname"
      (modelChange)="thirdChange($event)"
    ></syslink-tagbox>
  </div>
  <div class="col-6">
    <syslink-tagbox
      label="Task"
      [store]="tasksService.store"
      [select]="['Id', 'FormattedName']"
      [(model)]="tasks"
      displayKey="FormattedName"
      (modelChange)="taskChange($event)"
    ></syslink-tagbox>
  </div>
</div>

<syslink-scheduler
  #scheduler
  [toolbarActions]="toolbarActions"
  [editModal]="editModal?.modal"
  [canRefresh]="canRefresh"
  textAppointmentField="ThirdId.Fullname"
  [items]="items"
  (add)="add.emit($event)"
  (selectionChanged)="selectionChanged.emit($event)"
  [appointmentContextMenuItems]="appointmentContextMenuItems"
  [storageKey]="storageKey"
  (onCurrentDateChange)="onCurrentDateChange($event)"
  [autoRefreshAfterUpdateItem]="false"
  ></syslink-scheduler>
<app-work-time-modal
  #editModal
  (validate)="validate.emit($event)"
></app-work-time-modal>
