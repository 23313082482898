<div class="row mx-3">
    <!-- ExTaxTotal (Excluding discount) -->
    <div class="col-9 fw-bolder">{{'ExTaxTotal (excluding discount)' | translate}}</div>
    <div class="col-3 fw-bolder text-end">{{(element.ExTaxTotalWithoutGlobalDiscount??element.ExTaxTotal??0) + getDiscountLines() | syslinkCurrency:"TotalPrecision"}}</div>

    <!-- ExTaxTotal (Excluding discount) -->
    <ng-container *ngIf="this.element.GlobalDiscountValue != undefined && this.element.GlobalDiscountValue != null && this.element.GlobalDiscountValue != 0">
        <div class="col-9 fw-bolder">{{'ExTaxTotal (with global discount)' | translate}}</div>
        <div class="col-3 fw-bolder text-end">{{(element.ExTaxTotal??0) + getDiscountLines() | syslinkCurrency:"TotalPrecision"}}</div>
    </ng-container>

    <!-- Discount lines -->
    <div class="col-9">{{'Discount lines' | translate}}</div>
    <div class="col-3 text-end">{{getDiscountLines() | syslinkCurrency:"TotalPrecision"}}</div>

    <div class="col-12 mt-2"></div>

    <!-- ExTaxTotal -->
    <div class="col-9 fw-bolder">{{'exTaxTotal' | translate}}</div>
    <div class="col-3 fw-bolder text-end">{{(element.ExTaxTotal??0) | syslinkCurrency:"TotalPrecision"}}</div>

    <!-- DocumentFinance -->
    <ng-container *ngFor="let DocumentFinance of element.DocumentFinances">
        <div class="col-9">{{'TVA ' + DocumentFinance.TaxRateName}}</div>
        <div class="col-3 text-end">{{DocumentFinance.Vat | syslinkCurrency:"TotalPrecision"}}</div>
    </ng-container>

    <div class="col-12 mt-2"></div>
    <div class="col-12 border-top border-2"></div>
    <div class="col-12 mt-2"></div>

    <!-- InTaxTotal -->
    <div class="col-9 fw-bolder">{{'InTaxTotalPrice' | translate}}</div>
    <div class="col-3 fw-bolder text-end">{{(element.InTaxTotal??0) | syslinkCurrency:"TotalPrecision"}}</div>

    <!-- Amount with late fees -->
    <ng-container *ngIf="element.DaysOverdue >= 0 && (element.LateFeeInterestAmount > 0 || element.FixedLateFeeAmount > 0)">

      <div class="col-12 mt-2"></div>
      <div class="col-12 border-top border-2"></div>
      <div class="col-12 mt-2"></div>

      <div class="col-9">{{'Remaining amount' | translate}}</div>
      <div class="col-3 text-end">{{(element.AmountRemaining??0) | syslinkCurrency:"TotalPrecision"}}</div>

      <div class="col-9">{{'Late fee interest amount' | translate}}</div>
      <div class="col-3 text-end">{{(element.LateFeeInterestAmount??0) | syslinkCurrency:"TotalPrecision"}}</div>

      <div class="col-9">{{'Fixed late fee' | translate}}</div>
      <div class="col-3 text-end">{{(element.FixedLateFeeAmount ??0) | syslinkCurrency:"TotalPrecision"}}</div>

      <div class="col-9 fw-bolder">{{'Total with late fee' | translate}}</div>
      <div class="col-3 fw-bolder text-end">{{(element.TotalWithLateFee??0) | syslinkCurrency:"TotalPrecision"}}</div>

    </ng-container>

    <div class="col-12 mt-3"></div>

    <!-- Margin -->
    <h6
        *ngIf="documentType!='SaleCreditNote'"
        class="col-12 text-end"
    >{{'TotalMargin' | translate }} : {{ (element.Margin ?? 0) | syslinkCurrency:"TotalPrecision" }}</h6>
    <h6
        *ngIf="documentType!='SaleCreditNote'"
        class="col-12 text-end"
    >{{'Margin (%)' | translate }} : {{ getMarginPercent(element.MarginPercent ?? 0)}}</h6>
</div>
