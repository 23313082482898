import { Component, Input, ViewChild } from '@angular/core';
import { Account } from '../account.model';
import { BaseGridComponent } from 'projects/libraries/syslink-components/src/lib/grid/base-grid/base-grid.component';
import { SyslinkToolbarActionButton, TreelistComponent } from 'projects/libraries/syslink-components/src/public-api';
import { SyslinkColumn } from 'projects/libraries/syslink-components/src/lib/helpers/SyslinkColumn';
import { AccountTypesService } from '../../account-types/account-types.service';
import { AccountsService } from '../accounts.service';

@Component({
  selector: 'app-account-treelist',
  templateUrl: './account-treelist.component.html',
  styleUrls: ['./account-treelist.component.scss']
})
export class AccountTreelistComponent extends BaseGridComponent<Account> {

  @Input() storageKey: string = 'accounts-treelist';

  @ViewChild('treelistComponent') public treelistComponent?: TreelistComponent;
  public override toolbarActions = [
    new SyslinkToolbarActionButton({ code: 'Add', icon: 'plus', onClick: async () => { this.add.emit() }, visible: this.canAdd })
  ];

  public columns: SyslinkColumn[] = [
    new SyslinkColumn({ field: 'Code', label: "Code" }),
    new SyslinkColumn({ field: 'Name', label: "Name" }),
    new SyslinkColumn({ field: "Taxable", label: "Taxable", type: "boolean", editable: false }),
    new SyslinkColumn({ field: "Letterable", label: "Letterable", type: "boolean", editable: false }),
    new SyslinkColumn({ field: "AccountTypeId", label: "Account type", cellTemplate: 'select-value', data: { service: this.accountTypesService, displayKey: 'Name' } }),
  ];

  constructor(
    public accountTypesService: AccountTypesService,
    public accountsService: AccountsService
  ) {
    super();
  }

}
