<!-- <syslink-modal
    #modal
    *ngIf="payment"
    [title]="'Register payment'"
    [validateText]="'Confirm'"
    [cancelText]="'Cancel'"
    (onValidate)="onValidatePayment()"
    width="500px"
> -->
<syslink-select
    #paymentTypeSelect
    label="Payment Type"
    [store]="paymentTypesService.store"
    [(model)]="payment.PaymentTypeId"
    [filter]="filterPaymentType"
></syslink-select>

<syslink-select
    #paymentMethodSelect
    label="Payment method"
    [store]="paymentMethodsService.store"
    [(model)]="payment.PaymentMethodPrimary"
    [filter]="filterPaymentMethod"
    (modelChange)="onChangePaymentMethod($event)"
    [disabled]="payment.Id != undefined ? true : false"
></syslink-select>

<syslink-select
    *ngIf="payment.PaymentMethodPrimary && payment.PaymentMethodPrimary.Code == 'Mollie-Terminal'"
    #mollieTerminal
    label="Terminal"
    [items]="mollieTerminals"
    displayKey="Description"
    [(model)]="payment.SelectedMollieTerminal"
></syslink-select>

<div
    *ngIf="payment.PaymentTypeId?.Code=='Refund' && payment.PaymentMethodPrimary &&  payment.PaymentMethodPrimary.Code == 'Mollie-Terminal'"
    class="mb-3"
>
    <syslink-grid
        #gridRefundablePayment
        [items]="refundablePayments"
        [columns]="columnsMollie"
        (selectedKeysChange)="onChangeGridRefundablePaymentSelectedKeys($event)"
        [canAdd]="false"
        [canUpdate]="false"
        [canDelete]="false"
        [options]="{ editing: {
            allowDeleting: false,
            allowAdding: false,
            allowUpdating: false
            },
            export: {
                enabled: false
            },
            searchPanel: {
                visible: false
            },
            columnChooser: {
                enabled: false
            },
            pager: { visible: true }}"
    ></syslink-grid>
</div>

<syslink-select
    *ngIf="payment.PaymentMethodPrimary && payment.PaymentMethodPrimary.Code == 'Invoice' && documentType.includes('Sale')"
    #documentInvoice
    label="Invoice"
    displayKey="FormattedDocumentPayment"
    [store]="saleInvoicesService.store"
    [(model)]="payment.LinkedDocument"
    [filter]="filterLinked"
    (modelChange)="onChangeDocumentLinked('Invoice')"
></syslink-select>

<syslink-select
    *ngIf="payment.PaymentMethodPrimary && payment.PaymentMethodPrimary.Code == 'CreditNote' && documentType.includes('Sale')"
    #documentCreditNote
    label="Credit note"
    displayKey="FormattedDocumentPayment"
    [store]="saleCreditNotesService.store"
    [(model)]="payment.LinkedDocument"
    [filter]="filterLinked"
    (modelChange)="onChangeDocumentLinked('CreditNote')"
></syslink-select>

<syslink-select
    *ngIf="payment.PaymentMethodPrimary && payment.PaymentMethodPrimary.Code == 'Invoice' && documentType.includes('Purchase')"
    #documentInvoice
    label="Invoice"
    displayKey="FormattedDocumentPayment"
    [store]="purchaseInvoicesService.store"
    [(model)]="payment.LinkedDocument"
    [filter]="filterLinked"
    (modelChange)="onChangeDocumentLinked('Invoice')"
></syslink-select>

<syslink-select
    *ngIf="payment.PaymentMethodPrimary && payment.PaymentMethodPrimary.Code == 'CreditNote' && documentType.includes('Purchase')"
    #documentCreditNote
    label="Credit note"
    displayKey="FormattedDocumentPayment"
    [store]="purchaseCreditNotesService.store"
    [(model)]="payment.LinkedDocument"
    [filter]="filterLinked"
    (modelChange)="onChangeDocumentLinked('CreditNote')"
></syslink-select>

<syslink-input-number
    *ngIf="payment.PaymentMethodPrimary && (
payment.PaymentMethodPrimary.Code == 'Invoice' ||
payment.PaymentMethodPrimary.Code == 'CreditNote')
"
    label="Amount available"
    format="€ #0.00"
    [(model)]="AmountAvailable"
    [disabled]="true"
></syslink-input-number>

<syslink-input-number
    *ngIf="!(payment.PaymentTypeId?.Code == 'Refund' && payment.PaymentMethodPrimary &&  payment.PaymentMethodPrimary.Code == 'Mollie-Terminal')"
    label="Amount"
    [(model)]="payment.Amount"
    [min]="0"
    format="#0.00"
    (modelChange)="onAmountChange($event)"
></syslink-input-number>

<syslink-input-number
    *ngIf="payment.PaymentMethodPrimary && payment.PaymentMethodPrimary.ApplyRound"
    label="Rounded amount"
    [model]="payment.RoundedAmount"
    [min]="0"
    format="€ #0.00"
    [disabled]="true"
></syslink-input-number>

<syslink-input-number
    *ngIf="payment.PaymentMethodPrimary && payment.PaymentMethodPrimary.ApplyReturn"
    label="Amount to return"
    [model]="payment.AmountToReturn"
    format="€ #0.00"
    [disabled]="true"
></syslink-input-number>

<syslink-input-number
    *ngIf="payment.PaymentTypeId?.Code != 'Refund'"
    label="Remaining amount"
    [model]="RemainingAmount"
    format="€ #0.00"
    [disabled]="true"
></syslink-input-number>

<syslink-input-date
    label="Date"
    [(model)]="payment.Date"
    format="date"
></syslink-input-date>

<syslink-select
    *ngIf="payment.PaymentTypeId?.Code!='Refund' && payment.PaymentMethodPrimary && payment.PaymentMethodPrimary.Code != 'Mollie-Terminal' && payment.PaymentMethodPrimary.Code != 'Invoice' && payment.PaymentMethodPrimary.Code != 'CreditNote'"
    #paymentState
    label="Payment state"
    [store]="paymentStatesService.store"
    [(model)]="payment.PaymentStateId"
></syslink-select>

<syslink-wysiwyg
    label="Comments"
    [multilineToolbar]="false"
    [(model)]="payment.Note"
    height="250"
></syslink-wysiwyg>
<!-- </syslink-modal> -->