import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { BaseModalComponent } from 'projects/libraries/syslink-components/src/lib/modal/base-modal/base-modal.component';
import { SaleInvoice } from '../sale-invoices/sale-invoice.model';
import { FilterDescriptor } from 'devextreme/data';
import { SelectComponent } from 'projects/libraries/syslink-components/src/public-api';
import { SaleInvoicesService } from '../sale-invoices/sale-invoices.service';

@Component({
  selector: 'app-sale-invoice-select-modal',
  templateUrl: './sale-invoice-select-modal.component.html',
  styleUrls: ['./sale-invoice-select-modal.component.scss']
})
export class SaleInvoiceSelectModalComponent extends BaseModalComponent<SaleInvoice> implements OnInit {
  @Input() public expand: FilterDescriptor | Array<FilterDescriptor>;
  @Input() public title: string = "Select invoice";
  @Input() public content?: string;
  @Input() public data: any;
  @ViewChild('select') select?: SelectComponent;

  public FilteredByThird: boolean = true;
  public selectFilters: FilterDescriptor | Array<FilterDescriptor>;

  constructor(public saleInvoicesService: SaleInvoicesService) {
    super();
  }

  ngOnInit(): void {
    this.updateFilter();
  }

  public updateFilter() {
    if (this.FilteredByThird == true && this.modal?.data?.thirdId) {
      this.selectFilters = [`CurrentStatusLink.StatusId.Sequence lt 3  and ThirdId.Id eq ${this.modal.data.thirdId}`];
    }
    else {
      this.selectFilters = ["CurrentStatusLink.StatusId.Sequence lt 3"];
    }
    if(this.select)
    this.select.filter =  this.selectFilters;
  }

  public onNewInvoiceButtonClicked() {
    if (!this.modal) return;
    this.modal.data.Invoice = undefined;
    this.onValidateButtonClicked(this.modal?.data);
  }
}