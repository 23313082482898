<syslink-toolbar
  [actions]="toolbarActions"
  class="me-3"
  [withLine]="false"
></syslink-toolbar>

<div class="row">
  <div class="col-sm-9 col-xs-12">
    <syslink-block
      [title]="formattedTitle"
      [translateTitle]="false"
    >
      <app-document-statuses
        *ngIf="element?.Statuses"
        #documentStatuses
        [statuses]="availableStatuses"
        [documentStatuses]="element.Statuses"
        (statusChanged)="onDocumentStatusChanged($event)"
        [showConfirmMessageSaveDocument]="true"
      ></app-document-statuses>

      <!-- *ngIf="element?.Date" -->
      <app-sale-contract-form
        [(element)]="element"
        [disabled]="!canEditDocument()"
        [documentType]="documentType"
      ></app-sale-contract-form>

    </syslink-block>

    <syslink-block
    
    [title]="'Schedule'"
    class="ms-3"
    >
    <app-sale-contract-schedule
    [(element)]="element"
    (updateRRule)="update(false)"
    ></app-sale-contract-schedule>
    </syslink-block>

    <syslink-block
      [title]="'Document'"
      class="ms-3"
    >
      <app-sale-document-content
        #saleDocumentContent
        *ngIf="authService.hasPermission(this.newBasePermissionKey + '.content.list.view')"
        [(element)]="element"
        [documentType]="documentType"
        [subModuleCode]="subModuleCode"
        [saleDocumentService]="saleDocumentService"
        [saleDocumentLinesService]="saleDocumentLinesService"
        [columns]="documentLinesColumns"
        [disabled]="!canEditDocument()"
      ></app-sale-document-content>
    </syslink-block>
  </div>

   <div class="col-sm-3 col-xs-12">
    <syslink-block
      *ngIf="authService.hasPermission(this.newBasePermissionKey + '.totals.view')"
      [title]="'Totals'"
      [titleSize]="6"
    >
      <app-document-totals
        *ngIf="element"
        [documentType]="documentType"
        [element]="element"
      ></app-document-totals>
    </syslink-block>

    <syslink-block
      *ngIf="authService.hasPermission(this.newBasePermissionKey + '.third.view')"
      [title]="'Thirds'"
      [titleSize]="6"
      class="ms-3"
    >
      <app-third-document-data-list
        *ngIf="element?.DocumentDataCollection"
        [detailsUrl]="'/thirds/customers/'"
        [thirdFilter]="thirdDocumentDataFilters"
        [(documentDataCollection)]="element.DocumentDataCollection"
        [disabled]="!canEditDocument()"
        [showDeliveryAddress]="documentType == 'SaleOrder' ? true:false"
      ></app-third-document-data-list>
    </syslink-block>



    <syslink-block
      *ngIf="authService.hasPermission(this.newBasePermissionKey + '.documents.view') && element && element.Id"
      [title]="'Documents'"
      [titleSize]="6"
      class="ms-3"
    >
      <app-document-relations
        *ngIf="element && element.DocumentRelations"
        [(elements)]="element.DocumentRelations"
        [documentId]="element.Id"
        [documentType]="documentType"
        [disabled]="!canEditDocument()"
      ></app-document-relations>
    </syslink-block>

    <syslink-block
      *ngIf="authService.hasPermission(this.newBasePermissionKey + '.other.view')"
      [title]="'Other'"
      [titleSize]="6"
      class="ms-3"
    >
      <app-sale-document-other
        *ngIf="element"
        [(element)]="element"
        [disabled]="!canEditDocument()"
      ></app-sale-document-other>
    </syslink-block>
  </div>
</div>

<syslink-confirm-modal
  #deleteConfirm
  title="Delete"
  content="DeleteConfirmMessage"
  (onValidate)="onDeleteConfirmed()"
  validateText="Confirm"
  cancelText="Cancel"
></syslink-confirm-modal>

<app-document-copy-modal
  #copyModal
  (validate)="onCopyButtonClicked($event)"
></app-document-copy-modal>

<syslink-confirm-modal
  #saveConfirmModel
  title="{{'Confirm saving'|translate}}"
  (onValidate)="onConfirmeSavingBeforeEvent($event)"
  validateText="Confirm"
  cancelText="Cancel"
  >
</syslink-confirm-modal>
<!--
<syslink-confirm-modal
  #deleteConfirm
  title="Delete"
  content="DeleteConfirmMessage"
  (onValidate)="onDeleteConfirmed()"
  validateText="Confirm"
  cancelText="Cancel"
></syslink-confirm-modal>

<app-mail-modal
  *ngIf="mailModel"
  #emailModal
  [(mailModel)]="mailModel"
  [mailTemplateType]="mailTemplateType"
  [element]="element"
  [documentType]="documentType"
></app-mail-modal>

<app-document-copy-modal
  #copyModal
  (validate)="onCopyButtonClicked($event)"
></app-document-copy-modal>

<syslink-confirm-modal
  #saveConfirmModel
  title="{{'Confirm saving'|translate}}"
  (onValidate)="onConfirmeSavingBeforeEvent($event)"
  validateText="Confirm"
  cancelText="Cancel"
  >
</syslink-confirm-modal> -->
<!-- <ng-container *ngIf="element">
  content="{{saveConfirmModel.data?.content}}"
  <syslink-title *ngIf="element.No" [text]="element.No"></syslink-title>

  <syslink-toolbar [actions]="toolbarActions"></syslink-toolbar>


  <div class="mt-3 mb-5">
  
  </div>

  <div class="mt-4">
    <syslink-tabs>
      <syslink-tab
        label="Content"
        key="Content"
        [visible]="
          authService.hasPermission(
            this.basePermissionKey + '.content.tab.view'
          )
        "
      >
        <app-sale-document-content
          [(element)]="element"
          [documentType]="documentType"
          [subModuleCode]="subModuleCode"
          (selectedKeyChange)="onSelectedKeyChange($event)"
          (elementLineChange)="onElementLineChange($event)"
          [saleDocumentService]="saleDocumentService"
          [saleDocumentLinesService]="saleDocumentLinesService"
          [columns]="documentLinesColumns"
          [disabled]="!canEditDocument()"
        ></app-sale-document-content>
      </syslink-tab>

      <syslink-tab
        label="Third"
        key="Third"
        [visible]="
          authService.hasPermission(this.basePermissionKey + '.third.tab.view')
        "
      >
        <app-sale-document-third
          #saleDocumentThird
          [(element)]="element"
          [documentType]="documentType"
          (documentData)="update(false)"
          [disabled]="!canEditDocument()"
        ></app-sale-document-third>
      </syslink-tab>

      <syslink-tab
        [label]="'Finance' | translate"
        key="Finance"
        [visible]="
          authService.hasPermission(
            this.basePermissionKey + '.finance.tab.view'
          )
        "
      >
        <app-sale-document-finance
          [(element)]="element"
          [documentType]="documentType"
          [subModuleCode]="subModuleCode"
          (paymentsChange)="onPaymentChange($event)"
        ></app-sale-document-finance>
      </syslink-tab>
      <syslink-tab
        label="Other"
        key="Other"
        [visible]="
          authService.hasPermission(this.basePermissionKey + '.other.tab.view')
        "
      >
        <app-sale-document-other
          #saleDocumentOther
          [(element)]="element"
          [documentType]="documentType"
          (documentDelayChanged)="onDocumentDelayChanged($event)"
          (globalDiscountChanged)="update()"
          [disabled]="!canEditDocument()"
          (onCreateContract)="
            onTransformTo('Contract', '../../contracts/', true, '', selectedKey)
          "
        ></app-sale-document-other>
      </syslink-tab>
    </syslink-tabs>
  </div>
  <div class="mt-4" *ngIf="element.Id">
    <app-document-relations
      #saleDocumentRelation
      [documentType]="documentType"
      [documentId]="element.Id"
      [service]="saleDocumentService"
      [documentRelations]="element.DocumentRelations"
      (onRefreshClicked)="reloadDocumentRelation()"
    ></app-document-relations>
  </div>


  <syslink-modal
    #createFromContractModal
    title="Create"
    width="500"
    (onValidate)="
      onTransformTo(
        'Invoice',
        '../../invoices/',
        false,
        '',
        selectedKey,
        undefined,
        createFromContractFromDate,
        createFromContractToDate
      )
    "
    [validateText]="'Create'"
    [cancelText]="'Cancel'"
  >
    <div class="d-flex justify-content-between w-30">
      <syslink-input-date
        name="Start date"
        [label]="'Start date'"
        [(model)]="createFromContractFromDate"
        type="date"
      ></syslink-input-date>
      <syslink-input-date
        name="End date"
        [label]="'End date'"
        [(model)]="createFromContractToDate"
        type="date"
      ></syslink-input-date>
    </div>
  </syslink-modal>

  <syslink-modal
    #addInvoiceFromContractModal
    title="Create"
    width="500"
    (onValidate)="addContractToInvoice()"
    [validateText]="'Create'"
    [cancelText]="'Cancel'"
  >
    <syslink-select
      [label]="'Invoice'"
      [store]="saleInvoicesService.store"
      [filter]="filterLinkContract"
      [(model)]="InvoiceFromContract"
      displayKey="No"
    ></syslink-select>
    <div class="d-flex justify-content-between w-30">
      <syslink-input-date
        name="Start date"
        [label]="'Start date'"
        [(model)]="createFromContractFromDate"
      ></syslink-input-date>
      <syslink-input-date
        name="End date"
        [label]="'End date'"
        [(model)]="createFromContractToDate"
      ></syslink-input-date>
    </div>
  </syslink-modal>
    <div class="mt-4">
        <syslink-tabs>
            <syslink-tab
                label="Content"
                key="Content"
                [visible]="authService.hasPermission(this.basePermissionKey + '.content.tab.view')"
            >
                <app-sale-document-content
                    [(element)]="element"
                    [documentType]="documentType"
                    [subModuleCode]="subModuleCode"
                    (selectedKeyChange)="onSelectedKeyChange($event)"
                    (elementLineChange)="onElementLineChange($event)"
                    [saleDocumentService]="saleDocumentService"
                    [saleDocumentLinesService]="saleDocumentLinesService"
                    [columns]="documentLinesColumns"
                    [disabled]="!canEditDocument()"
                ></app-sale-document-content>
            </syslink-tab>

            <syslink-tab
                label="Third"
                key="Third"
                [visible]="authService.hasPermission(this.basePermissionKey + '.third.tab.view')"
            >
                <app-sale-document-third
                    #saleDocumentThird
                    [(element)]="element"
                    [documentType]="documentType"
                    (documentData)="update(false)"
                    [disabled]="!canEditDocument()"
                ></app-sale-document-third>
            </syslink-tab>

            <syslink-tab
                *ngIf="documentType != 'SaleContract'"
                [label]="'Finance' | translate"
                key="Finance"
                [visible]="authService.hasPermission(this.basePermissionKey + '.finance.tab.view')"
            >
                <app-sale-document-finance
                    [(element)]="element"
                    [documentType]="documentType"
                    [subModuleCode]="subModuleCode"
                    (paymentsChange)="onPaymentChange($event)"
                ></app-sale-document-finance>
            </syslink-tab>

            <syslink-tab
                *ngIf="documentType == 'SaleContract'"
                [label]="'Schedule'"
                key="Schedule"
                [visible]="authService.hasPermission(this.basePermissionKey + '.scheduler.tab.view')"
            >
                <app-sale-contract-schedule
                    [(element)]="element"
                    (updateRRule)="update(false)"
                ></app-sale-contract-schedule>
            </syslink-tab>
            <syslink-tab
                *ngIf="documentType == 'SaleContract'"
                label="Renewal"
                key="Renewal"
                [visible]="authService.hasPermission(this.basePermissionKey + '.renewal.tab.view')"
            >
                <app-sale-contract-renewal
                    [(element)]="element"
                    (renewalChange)="renewalChange()"
                ></app-sale-contract-renewal>
            </syslink-tab>

            <syslink-tab
                label="Other"
                key="Other"
                [visible]="authService.hasPermission(this.basePermissionKey + '.other.tab.view')"
            >
                <app-sale-document-other
                    #saleDocumentOther
                    [(element)]="element"
                    [documentType]="documentType"
                    (documentDelayChanged)="onDocumentDelayChanged($event)"
                    (globalDiscountChanged)="update()"
                    [disabled]="!canEditDocument()"
                    (onCreateContract)="onTransformTo('Contract','../../contracts/',true,'',selectedKey)"
                ></app-sale-document-other>
            </syslink-tab>
        </syslink-tabs>
    </div>
    <div
        class="mt-4"
        *ngIf="element.Id && documentType!='SaleContract'"
    >
        <app-document-relations
            #saleDocumentRelation
            [documentType]="documentType"
            [documentId]="element.Id"
            [service]="saleDocumentService"
            [documentRelations]="element.DocumentRelations"
            (onRefreshClicked)="reloadDocumentRelation()"
        ></app-document-relations>
    </div>
    <div
        class="mt-4"
        *ngIf="element.Id && documentType=='SaleContract'"
    >
        <app-document-relations
            #saleDocumentRelation
            [documentType]="documentType"
            [documentId]="element.Id"
            [service]="saleDocumentService"
            [documentRelations]="element.DocumentRelations"
            [addSaleInvoice]="addSaleInvoiceFromContract"
            (onRefreshClicked)="reloadDocumentRelation()"
        ></app-document-relations>
    </div>
    <app-mail-modal
        *ngIf="mailModel"
        #emailModal
        [(mailModel)]="mailModel"
        [mailTemplateType]="mailTemplateType"
        [element]="element"
        [documentType]="documentType"
    ></app-mail-modal>

  <app-document-copy-modal
    #copyModal
    (validate)="onCopyButtonClicked($event)"
  ></app-document-copy-modal>
</ng-container> -->


























<!-- <ng-container *ngIf="element">
  <syslink-title *ngIf="element.No" [text]="element.No"></syslink-title>

  <syslink-toolbar [actions]="toolbarActions"></syslink-toolbar>

  <app-document-statuses
    #documentStatuses
    [statuses]="availableStatuses"
    [documentStatuses]="element.Statuses"
    (statusChanged)="onDocumentStatusChanged($event)"
  ></app-document-statuses>

  <div class="mt-3 mb-5">
    <app-sale-contract-form
      [(element)]="element"
      [documentType]="documentType"
      [subModuleCode]="subModuleCode"
      (dateChange)="onDateChanged()"
      [disabled]="!canEditDocument()"
      [showExtensionDate]="showExtensionDate"
    ></app-sale-contract-form>
  </div>

  <div class="mt-4">
    <syslink-tabs>
      <syslink-tab
        label="Content"
        key="Content"
        [visible]="
          authService.hasPermission(
            this.basePermissionKey + '.content.tab.view'
          )
        "
      >
        <app-sale-contract-content
          [(element)]="element"
          [documentType]="documentType"
          [subModuleCode]="subModuleCode"
          (selectedKeyChange)="onSelectedKeyChange($event)"
          (elementLineChange)="onElementLineChange($event)"
          [saleDocumentService]="saleDocumentService"
          [saleDocumentLinesService]="saleDocumentLinesService"
          [columns]="documentLinesColumns"
          [disabled]="!canEditDocument()"
        ></app-sale-contract-content>
      </syslink-tab>

      <syslink-tab
        label="Third"
        key="Third"
        [visible]="
          authService.hasPermission(this.basePermissionKey + '.third.tab.view')
        "
      >
        <app-sale-document-third
          #saleDocumentThird
          [(element)]="element"
          [documentType]="documentType"
          (documentData)="update(false)"
          [disabled]="!canEditDocument()"
        ></app-sale-document-third>
      </syslink-tab>

      <syslink-tab
        [label]="'Schedule'"
        key="Schedule"
        [visible]="
          authService.hasPermission(
            this.basePermissionKey + '.scheduler.tab.view'
          )
        "
      >
        <app-sale-contract-schedule
          [(element)]="element"
        ></app-sale-contract-schedule>
      </syslink-tab>
      <syslink-tab
        label="Renewal"
        key="Renewal"
        [visible]="
          authService.hasPermission(
            this.basePermissionKey + '.renewal.tab.view'
          )
        "
      >
        <app-sale-contract-renewal
          [(element)]="element"
          (renewalChange)="renewalChange()"
        ></app-sale-contract-renewal>
      </syslink-tab>

      <syslink-tab
        label="Other"
        key="Other"
        [visible]="
          authService.hasPermission(this.basePermissionKey + '.other.tab.view')
        "
      >
        <app-sale-contract-other
          #saleDocumentOther
          [(element)]="element"
          [documentType]="documentType"
          (documentDelayChanged)="onDocumentDelayChanged($event)"
          (globalDiscountChanged)="update()"
          [disabled]="!canEditDocument()"
          (onCreateContract)="
            onTransformTo('Contract', '../../contracts/', true, '', selectedKey)
          "
        ></app-sale-contract-other>
      </syslink-tab>
    </syslink-tabs>
  </div>

  <div class="mt-4" *ngIf="element.Id">
    <app-document-relations
      #saleDocumentRelation
      [documentType]="documentType"
      [documentId]="element.Id"
      [service]="saleDocumentService"
      [documentRelations]="element.DocumentRelations"
      [addSaleInvoice]="addSaleInvoiceFromContract"
      (onRefreshClicked)="reloadDocumentRelation()"
    ></app-document-relations>
  </div>
  <app-mail-modal
    #emailModal
    *ngIf="mailModel"
    [(mailModel)]="mailModel"
    [mailTemplateType]="mailTemplateType"
    [element]="element"
    [documentType]="documentType"
  ></app-mail-modal>

  <syslink-confirm-modal
    #deleteConfirm
    title="Delete"
    content="DeleteConfirmMessage"
    (onValidate)="onDeleteConfirmed()"
    validateText="Confirm"
    cancelText="Cancel"
  ></syslink-confirm-modal>
  <syslink-modal
    #createFromContractModal
    title="Create"
    width="500"
    (onValidate)="
      onTransformTo(
        'Invoice',
        '../../invoices/',
        false,
        '',
        selectedKey,
        undefined,
        createFromContractFromDate,
        createFromContractToDate
      )
    "
    [validateText]="'Create'"
    [cancelText]="'Cancel'"
  >
    <div class="d-flex justify-content-between w-30">
      <syslink-input-date
        name="Start date"
        [label]="'Start date'"
        [(model)]="createFromContractFromDate"
        type="date"
      ></syslink-input-date>
      <syslink-input-date
        name="End date"
        [label]="'End date'"
        [(model)]="createFromContractToDate"
        type="date"
      ></syslink-input-date>
    </div>
  </syslink-modal>

  <syslink-modal
    #addInvoiceFromContractModal
    title="Create"
    width="500"
    (onValidate)="addContractToInvoice()"
    [validateText]="'Create'"
    [cancelText]="'Cancel'"
  >
    <syslink-select
      [label]="'Invoice'"
      [store]="saleInvoicesService.store"
      [filter]="filterLinkContract"
      [(model)]="InvoiceFromContract"
      displayKey="No"
    ></syslink-select>
    <div class="d-flex justify-content-between w-30">
      <syslink-input-date
        name="Start date"
        [label]="'Start date'"
        [(model)]="createFromContractFromDate"
      ></syslink-input-date>
      <syslink-input-date
        name="End date"
        [label]="'End date'"
        [(model)]="createFromContractToDate"
      ></syslink-input-date>
    </div>
  </syslink-modal>

  <app-document-copy-modal
    #copyModal
    (validate)="onCopyButtonClicked($event)"
  ></app-document-copy-modal>
</ng-container> -->
