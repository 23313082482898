import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LoadOptions } from 'devextreme/data';
import { ApiService } from '../../api.service';
import { ODataService } from '../../services/oData.service';
import { UserGroup } from './user-group.model';
import { User } from '../users/user.model';
import { ConfigurationsService } from '../../../base/modules/configurations/configurations.service';

@Injectable({
  providedIn: 'root'
})
export class UserGroupsService extends ODataService<UserGroup> {
  public url = 'UserGroup';
  public override defaultOptions: LoadOptions = {
    expand: [
      'Roles.NameTranslationId',
      'Users',
      'ActionGroups',
      'Permissions',
      'NameTranslationId.Translations.LanguageId'
    ]
  };

  constructor(
    private api: ApiService,
    private configurationsService: ConfigurationsService,
    private translateService: TranslateService
  ) {
    super();
  }
  public override format(element: Partial<UserGroup>): UserGroup {
    var result: any = {
      ...element,
    };
    // delete result.Users;
    // delete result.Roles;
    // delete result.Permissions;

    return result;
  }

  public override async getInstance(params?: Partial<UserGroup>): Promise<UserGroup> {
    var group = new UserGroup({
      IsActive: true,
      ForegroundColor: await this.configurationsService.getConfigurationAsString("userGroups.ForegroundColor", "Base.Security", "Base"),
      BackgroundColor: await this.configurationsService.getConfigurationAsString("userGroups.BackgroundColor", "Base.Security", "Base"),
      Name: this.translateService.instant('New User group'),
      ...params
    });
    return group;
  }

  public async removeUser(userGroupId: number, userId: number) {
    return await this.api.sendRequest('/api/odata/UserGroup/' + userGroupId + '/Users/' + userId + '/$ref', 'DELETE');
  }
  public async removeUserPermission(userGroupId: number, permissionId: number) {
    return await this.api.sendRequest('/api/odata/UserGroup/' + userGroupId + '/Permissions/' + permissionId + '/$ref', 'DELETE');
  }
  public async removeUserRole(userGroupId: number, roleId: number) {
    return await this.api.sendRequest('/api/odata/UserGroup/' + userGroupId + '/Roles/' + roleId + '/$ref', 'DELETE');
  }
  public async removeActionGroup(userId: number | string, actionGroupId: number) {
    return await this.api.sendRequest('/api/odata/UserGroup/' + userId + '/ActionGroups/' + actionGroupId + '/$ref', 'DELETE');
  }
}
