import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { BaseGridComponent } from 'projects/libraries/syslink-components/src/lib/grid/base-grid/base-grid.component';
import { BankReconciliationLine } from '../../bank-reconciliation-line.model';
import { GridComponent, NotificationsService } from 'projects/libraries/syslink-components/src/public-api';
import { SyslinkColumn } from 'projects/libraries/syslink-components/src/lib/helpers/SyslinkColumn';
import { ThirdsService } from 'projects/erp-app/src/app/thirds/thirds/thirds.service';
import { PaymentsService } from 'projects/erp-app/src/app/base/payments/payments.service';
import { BankReconciliationLineStatusesService } from '../../bank-reconciliation-line-status/bank-reconciliation-line-status.service';
import { ContextMenuItemAction } from 'projects/libraries/syslink-components/src/lib/context-menus/context-menu-item-action';
import { BankReconciliationLineStatus } from '../../bank-reconciliation-line-status/bank-reconciliation-line-status.model';
import { PaymentMethodsService } from 'projects/erp-app/src/app/base/payments/payment-methods/payment-methods.service';
import { PaymentTypesService } from 'projects/erp-app/src/app/base/payments/payment-types/payment-types.service';
import { SaleInvoicesService } from 'projects/erp-app/src/app/sales/sale-invoices/sale-invoices/sale-invoices.service';
import { DXCell } from 'projects/libraries/syslink-components/src/lib/grid/cells/dxcell';
import { SaleCreditNotesService } from 'projects/erp-app/src/app/sales/sale-credit-notes/sale-credit-notes/sale-credit-notes.service';

@Component({
  selector: 'app-bank-reconciliation-line-grid-items',
  templateUrl: './bank-reconciliation-line-grid-items.component.html',
  styleUrls: ['./bank-reconciliation-line-grid-items.component.scss']
})
export class BankReconciliationLineGridItemsComponent extends BaseGridComponent<BankReconciliationLine> implements OnInit {

  @Input() storageKey: string = 'grid-bank-reconciliation-line-items';
  @Input() public override expand?: string | string[] = ['BankReconciliationId', 'AccountId', 'SaleDocumentId', 'TaxId'];

  @Input() public elements: BankReconciliationLine[] = [];
  @Output() public elementsChange: EventEmitter<any> = new EventEmitter<any>();

  private _disabled: boolean = false;
  @Input() set disabled(value: boolean) {
    this._disabled = value;
    this.updateListOptions();
  }
  get disabled(): boolean { return this._disabled; };

  public selectedItems: any[] = [];
  public contextMenuItems: ContextMenuItemAction[] = [
    { code: "Duplicate", icon: "copy", text: "Duplicate", onItemClick: (e: any) => this.onMenuItemClicked("Duplicate"), visible: true },
    { code: "AddPayment", icon: "money", text: "Add payment", onItemClick: (e: any) => this.onMenuItemClicked("AddPayment"), visible: !this.disabled },
    { code: "SetCanceled", icon: "trash", text: "Cancel", onItemClick: (e: any) => this.onMenuItemClicked("SetCanceled"), visible: !this.disabled },
  ];

  public saleDocumentData:any[] = [];

  @ViewChild('gridComponent') public gridComponent?: GridComponent;

  public columns: SyslinkColumn[] = [
    new SyslinkColumn({ field: 'LineNo', label: 'GRID.CAPTION.NO', editable: false, sortable: true, sortIndex: 0, sortDirection: 'desc' }),
    new SyslinkColumn({ field: 'Date', label: 'Date', type: 'date', visible: false, editable: false }),
    new SyslinkColumn({ field: 'Amount', type: 'number', cellTemplate: 'currency-cell', editable: false, visible: true, data: { key: 'TotalPrecision', shouldHideData() { return false } } }),
    new SyslinkColumn({ field: 'Debit', type: 'number', cellTemplate: 'currency-cell', editable: true, visible: false, data: { key: 'TotalPrecision', shouldHideData() { return false } } }),
    new SyslinkColumn({ field: 'Credit', type: 'number', cellTemplate: 'currency-cell', editable: true, visible: false, data: { key: 'TotalPrecision', shouldHideData() { return false } } }),
    new SyslinkColumn({ field: 'Communication', label: 'Communication', editable: false }),
    new SyslinkColumn({ field: 'ThirdName', label: 'Fullname', editable: false }),
    new SyslinkColumn({ field: "ThirdId", label: "Third", cellTemplate: 'select-value', editCellTemplate: "select-cell", data: { service: this.thirdsService, select:['Id','Fullname'], displayKey: 'Fullname' } }),
    // new SyslinkColumn({ field: "SaleDocumentHeaderId", label: "Document", cellTemplate: 'select-value', editCellTemplate: "select-cell", data: { service: this.saleInvoicesService, displayKey: 'FormattedDocumentPaymentWithThirdName' } }),
    new SyslinkColumn({ field: "SaleDocumentHeaderId", label: "Document", cellTemplate: "select-value",editCellTemplate: "select-enum-cell", data: { enum: this.saleDocumentData, displayKey: 'FormattedDocumentPaymentWithThirdName' } }),
    new SyslinkColumn({ field: "StatusId.Name", label: "Status", editable: false, cellTemplate: 'block-cell', data: { getBlockLabel: this.getStatusCellLabel, getBlockClass: this.getStatusCellClass, getBlockColor: this.getStatusCellColor }, width: 120 }),
    new SyslinkColumn({ field: 'Reference', label: 'Reference', editable: false, visible: false }),
    new SyslinkColumn({ field: 'PaymentId.Id', label: 'Payment id', editable: false, visible: true }),
  ];

  public selectedKeys: number[] = [];
  public bankReconcilistaionLineStatuses: BankReconciliationLineStatus[] = [];
  public draftStatus?: BankReconciliationLineStatus;
  public ReconciledStatus?: BankReconciliationLineStatus;
  public cancelStatus?: BankReconciliationLineStatus;

  constructor(
    public thirdsService: ThirdsService,
    private saleInvoicesService: SaleInvoicesService,
    private saleCreditNotesService:SaleCreditNotesService,
    public paymentsService: PaymentsService,
    public bankReconciliationLineStatusesService: BankReconciliationLineStatusesService,
    private paymentMethodsService: PaymentMethodsService,
    private paymentTypesService: PaymentTypesService
  ) {
    super();
    this.saleInvoicesService.load({select:['Id','FormattedDocumentPaymentWithThirdName','AmountRemaining','Type'],expand:[], sort:['No']}).then((result) =>  {
      result.forEach((e:any)=>{
        if(e.AmountRemaining && e.AmountRemaining > 0) {
          e.Type = "SaleInvoiceHeader";
          this.saleDocumentData.push(e);
        }
      })
    });
    this.saleCreditNotesService.load({select:['Id','FormattedDocumentPaymentWithThirdName','AmountRemaining','Type'],expand:[], sort:['No']}).then((result) =>  {
      result.forEach((e:any)=>{
        if(e.AmountRemaining && e.AmountRemaining > 0) {
          e.Type = "SaleCreditNoteHeader";
          this.saleDocumentData.push(e);
        }
      })
    });
  }

  public override async ngOnInit(): Promise<void> {
    this.updateListOptions();
    this.bankReconcilistaionLineStatuses = await this.bankReconciliationLineStatusesService.load();
    this.draftStatus = this.bankReconcilistaionLineStatuses.find((s: BankReconciliationLineStatus) => s.Code == "Draft");
    this.ReconciledStatus = this.bankReconcilistaionLineStatuses.find((s: BankReconciliationLineStatus) => s.Code == "Reconciled");
    this.cancelStatus = this.bankReconcilistaionLineStatuses.find((s: BankReconciliationLineStatus) => s.Code == "Canceled");
  }
  public updateListOptions(): void {
    if (this.disabled) {
      this.canDelete = false;
      this.canAdd = false;
      this.canUpdate = false;
    }
    else {
      this.canDelete = true;
      this.canAdd = true;
      this.canUpdate = true;
    }
    this.gridComponent?.ngOnInit();
  }

  public async onMenuItemClicked(code: "Duplicate" | "AddPayment" | "SetCanceled") {
    if (this.selectedItems.length == 0) {
      NotificationsService.sendErrorMessage("Please first select line(s)")
    }
    switch (code) {
      case "Duplicate":
        await this.duplicate();
        break;
      case "AddPayment":
        await this.addPayment();
        break;
      case "SetCanceled":
        await this.setCancelStatus();
        break;
    }
  }

  public async duplicate() {
    if (this.selectedItems.some((line: BankReconciliationLine) => line.StatusId?.Code == "Reconciled")) {
      NotificationsService.sendErrorMessage('Some line are reconciled');
      return;
    }
    if (this.selectedItems.some((line: BankReconciliationLine) => line.StatusId?.Code == "Canceled")) {
      NotificationsService.sendErrorMessage('Some line are canceled');
      return;
    }
    for (let index = this.selectedItems.length; index > 0; index--) {
      let newLine = JSON.parse(JSON.stringify(this.selectedItems[index - 1]));
      newLine.LineNo = this.getLineNo(newLine.LineNo);
      newLine.Id = -(this.elements.length + 1);
      this.elements.push(newLine);

      this.elements.sort((a: any, b: any) => {
        return a.LineNo.localeCompare(b.LineNo);
      });

      this.gridComponent?.grid?.instance.refresh();

    }
  }

  private getLineNo(lineNo: string): string {
    let lines = this.elements.filter((l: BankReconciliationLine) => l.LineNo?.includes(lineNo) && l.LineNo?.includes("_")) ?? [];
    return lineNo + "_" + (lines.length + 1);
  }

  public async addPayment() {
    if (this.selectedItems.some((line: BankReconciliationLine) => line.StatusId?.Code == "Reconciled")) {
      NotificationsService.sendErrorMessage('Some line are reconciled');
      return;
    }
    if (this.selectedItems.some((line: BankReconciliationLine) => line.StatusId?.Code == "Canceled")) {
      NotificationsService.sendErrorMessage('Some line are canceled');
      return;
    }
    if (this.selectedItems.some((line: BankReconciliationLine) => line.SaleDocumentHeaderId == null)) {
      NotificationsService.sendErrorMessage('Some line are not linked to a document');
      return;
    }

    let defaultPaymentMethodPrimary = (await this.paymentMethodsService.load({ filter: ["Code eq 'ByTransfert'"] }))[0];
    let defaultPaymentTypeId = (await this.paymentTypesService.load({ filter: ["Code eq 'Payment'"] }))[0];

    this.selectedItems.forEach(async (line: BankReconciliationLine) => {
      
      let invoice:any = line?.SaleDocumentHeaderId; 
      console.log(invoice);
      let payment = await this.paymentsService.getInstance({
        DocumentId: line?.SaleDocumentHeaderId?.Id,
        DocumentType: invoice?.Type,
        Amount: (line.Credit ?? 0) - (line.Debit ?? 0),
        RoundedAmount: (line.Credit ?? 0) + (line.Debit ?? 0),
        Reference: line.Reference,
        PaymentMethodPrimary: defaultPaymentMethodPrimary,
        PaymentTypeId: defaultPaymentTypeId,
        Date:line.Date
      });
      line.StatusId = this.ReconciledStatus;
      line.PaymentId = payment;
    });
  }

  public async setCancelStatus() {
    if (this.selectedItems.some((line: BankReconciliationLine) => line.StatusId?.Code == "Reconciled")) {
      NotificationsService.sendErrorMessage('Some line are reconciled');
      return;
    }

    this.selectedItems.forEach((line: BankReconciliationLine) => {
      line.StatusId = this.cancelStatus;
    });
  }

  // Edition line
  public onChangeEditCell(e: any) {
    e.cancel = !this.canEditCell(e);
  }
  private canEditCell(e: any): boolean {
    if (e.data.StatusId.Code != "Draft") {
      return false;
    }
    return true;
  }

  // Status cell
  // -----------
  getStatusCellLabel(cell: DXCell) {
    return cell.data.StatusId.Name;
  }

  getStatusCellClass(cell: DXCell) {
    return 'text-white text-center';
  }

  getStatusCellColor(cell: DXCell) {
    return cell.data.StatusId.Color;
  }
}