<ng-container *ngFor="let documentData of documentDataCollection; let i = index; let last = last">
    <div class="h-100 d-flex mb-1 pb-1 justify-content-center align-items-center gap-2" [class.border-bottom]="!last">
        <i *ngIf="documentData.ThirdId.IsProfessional" class="fa-solid fa-building bg-light text-center text-gray mx-2"
            [title]="'Company' | translate"></i>
        <i *ngIf="!documentData.ThirdId.IsProfessional" class="fa-solid fa-user bg-light text-center text-gray mx-2"
            [title]="'Person' | translate"></i>

        <div class="flex-grow-1 align-items-center">
            <a class="text-gray fst-italic fw-light" role="button"
                routerLink="/basics/thirds/thirds/{{documentData.ThirdId.Id}}" target="_blank">
                {{documentData.ThirdId.Fullname}}
            </a>
            <br>
            <div class="d-flex align-items-center gap-2 mt-2">
                <i role="button"
                    class="card text-center text-white bg-{{documentData.HasPrincipal? 'primary':'gray'}} p-1 flex-grow-1"
                    (click)="disabled? null : updatePrincipal(documentData, i)" [title]="'Primary' | translate">PRI</i>
                <i role="button"
                    class="card text-center text-white bg-{{documentData.HasSecondary? 'primary':'gray'}} p-1 flex-grow-1"
                    (click)="disabled? null : updateSecondary(documentData, i )"
                    [title]="'Secondary' | translate">SEC</i>
                <i class="card text-center text-white bg-{{documentData.HasCC? 'primary':'gray'}} p-1 flex-grow-1" role="button"
                    (click)="disabled? null : documentData.HasCC = !documentData.HasCC"
                    [title]="'Carbon copy' | translate">CC</i>
                <i class="card text-center text-white bg-{{documentData.HasCCI? 'primary':'gray'}} p-1 flex-grow-1" role="button"
                    (click)="disabled? null : documentData.HasCCI = !documentData.HasCCI"
                    [title]="'Blind carbon copy' | translate">CCI</i>
                <i *ngIf="showDeliveryAddress"
                    class="card text-center text-white bg-{{documentData.UsedDeliveryAddress? 'primary':'gray'}} p-1 flex-grow-1"
                    role="button" (click)="disabled? null : changeDeliveryAddress(documentData, i )"
                    [title]="'Delivery address' | translate">POST</i>
            </div>
        </div>

        <div *ngIf="authService.hasPermission(this.newBasePermissionKey + '.third.update')&& !disabled"
            class="d-flex align-items-center gap-2 mx-2">

            <i class="fa-solid fa-pencil text-center text-gray" role="button"
                (click)="onUpdateDocumentDataBtnClicked(documentData,i)" [title]="'Edit' | translate"></i>
            <i class="fa-solid fa-trash text-center text-{{documentData.HasPrincipal==false?'danger':'gray'}}"
                role="button"
                (click)="documentData.HasPrincipal==false ? onDeleteDocumentDataBtnClicked(documentData):undefined"
                [title]="'Delete' | translate"></i>
        </div>
    </div>
</ng-container>

<div *ngIf="authService.hasPermission(this.newBasePermissionKey + '.third.update') && !disabled"
    class="full-width-container">
    <syslink-button class="full-width-button" icon="plus" (click)="onAddNewDocumentDataBtnClicked()"
        [hint]="'Add' | translate"></syslink-button>
</div>

<syslink-modal #editDocumentDataModal title="Third" width="350px"
    (onValidate)="editDocumentDataModal.data.newItem==false ? onUpdate(documentData,editDocumentDataModal.data.index) : onCreate(documentData)"
    [validateText]="(documentData.Id ? 'Save' : 'Create')" cancelText="Cancel">
    <app-third-document-data-modal #thirdDocumentDataModal [(element)]="documentData" [thirdFilter]="thirdFilter"
        [detailsUrl]="detailsUrl" [relatedThirdFilter]="relatedThirdFilter"
        [showDeliveryAddress]="showDeliveryAddress"></app-third-document-data-modal>
</syslink-modal>

<syslink-confirm-modal #deleteConfirm title="Delete" content="DeleteConfirmMessage" validateText="Confirm"
    cancelText="Cancel" (onValidate)="onDelete()"></syslink-confirm-modal>