<div class="d-flex align-items-center">
  <div class="me-2">
    <syslink-button
      [text]="('Reload' | translate) + ' ('+creditLimit+')'"
      icon="fa-solid fa-refresh"
      (onClick)="loadCreditSafeData()"
    ></syslink-button>
  </div>
  <ng-container *ngIf="element; else noElement">
    {{'Last refresh' | translate}} : {{element.DateOfOrder | date:'short'}}
  </ng-container>
  <ng-template #noElement>
    {{'No Creditsafe calls have been made for this third' | translate}}
  </ng-template>
</div>

<ng-container *ngIf="element">
  <div class="d-flex align-items-stretch my-2" >
    <syslink-flag
      class="box alignSelf"
      style="margin-right: 10px;"
      [flag]="element.Report?.CompanySummary?.Country?.toLowerCase()"
      [size]="175"
    ></syslink-flag>
    <div style="writing-mode: vertical-lr; transform: rotate(180deg); margin-bottom:0px; text-align: center;" class="box vertical-text bg-{{element.Report?.CompanySummary?.CompanyStatus?.ProviderStatus == 'Actif'?'success':'danger'}} fw-bold h5 text-white " >{{getStatusLabel(element.Report?.CompanySummary?.CompanyStatus?.ProviderStatus)}}</div>
    <div class="box fw-bold h3 text-white bg-secondary" style="margin-bottom:0px; text-align: center; line-height:normal; align-content:center">{{element.Report?.CompanySummary?.BusinessName}}</div>
  </div>

  <div class="mb-2">
    <app-creditsafe-current [element]="element"></app-creditsafe-current>
  </div>

  <syslink-tabs>
    <syslink-tab label="Payment">
      <app-creditsafe-payment-data [element]="element"></app-creditsafe-payment-data>
    </syslink-tab>

    <syslink-tab label="CompanySignificantEvents">
      <app-creditsafe-company-significant-events [element]="element"></app-creditsafe-company-significant-events>
    </syslink-tab>

    <syslink-tab label="Commentaries">
      <app-creditsafe-commentaries [element]="element"></app-creditsafe-commentaries>
    </syslink-tab>

    <syslink-tab
      label="FinancialStatements"
      *ngIf="element.Report?.FinancialStatements"
    >
      <app-creditsafe-financial-statements [element]="element"></app-creditsafe-financial-statements>
    </syslink-tab>

    <syslink-tab
      label="SocialBalanceSheets"
      *ngIf="element.Report?.AdditionalInformation?.SocialBalanceSheets"
    >
      <app-creditsafe-social-balance-sheets [element]="element"></app-creditsafe-social-balance-sheets>
    </syslink-tab>

    <syslink-tab label="RatingHistory">
      <app-creditsafe-rating-history [element]="element"></app-creditsafe-rating-history>
    </syslink-tab>

    <syslink-tab label="CreditLimitHistory">
      <app-creditsafe-credit-limit-history [element]="element"></app-creditsafe-credit-limit-history>
    </syslink-tab>
  </syslink-tabs>
</ng-container>