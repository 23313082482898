<syslink-block [title]="'Taxes'">
<syslink-grid
    #gridComponent
    [permissionKey]="'list'"
    [gridName]="storageKey"
    [canView]="canView"
    [canSearch]="canSearch"
    [canRefresh]="canRefresh"
    [canAdd]="canAdd"
    [canSort]="canSort"
    [canFilter]="canFilter"
    [canExport]="canExport"
    [canDblClck]="canDblClck"
    [canDelete]="canDelete"
    [canUpdate]="canUpdate"
    [service]="taxesService"
    [expand]="expand"
    [columns]="columns"
    [storageKey]="storageKey"
    [filter]="filter"
></syslink-grid>
</syslink-block>