import { Component } from '@angular/core';
import { AppInjectorService } from 'projects/libraries/syslink-components/src/lib/services/app-injector.service';
import { SaleOrderHeaderHeaderStatusesService } from '../sale-order-header-header-statuses/sale-order-header-header-statuses.service';
import { SaleOrderHeaderHeaderStatus } from '../sale-order-header-header-statuses/sale-order-header-header-status.model';
import { SaleOrderHeaderStatusesService } from '../sale-order-header-statuses/sale-order-header-statuses.service';
import { SaleOrderHeaderStatus } from '../sale-order-header-statuses/sale-order-header-status.model';
import { SaleOrderLinesService } from '../sale-order-lines/sale-order-lines.service';
import { SaleOrderLine } from '../sale-order-lines/sale-order-line.model';
import { SaleOrdersService } from '../sale-orders/sale-orders.service';
import { SaleOrder } from '../sale-orders/sale-order.model';
import { SaleDocumentDetailsComponent } from '../../sale-documents/sale-documents/sale-document-details/sale-document-details.component';
import { SaleInvoiceHeaderStatusesService } from '../../sale-invoices/sale-invoice-header-statuses/sale-invoice-header-statuses.service';
import { SaleCreditNoteHeaderStatusesService } from '../../sale-credit-notes/sale-credit-note-header-statuses/sale-credit-note-header-statuses.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ActivatedRoute } from '@angular/router';
import { ReportsService } from '../../../connectors/reports/reports.service';
import { MailTemplateTypesService } from '../../../mails/mail-template-types/mail-template-types.service';
import { DocumentsService } from '../../../base/documents/documents/documents.service';
import { ThirdsService } from '../../../thirds/thirds/thirds.service';
import { DocumentDatasService } from '../../../base/documents/document-datas/document-datas.service';
import { PaymentsService } from '../../../base/payments/payments.service';
import { DocumentRelationsService } from '../../../base/documents/document-relations/document-relation.service';
import { SaleDocumentLineDiscountOperationsService } from '../../sale-documents/sale-document-line-discount-operations/sale-document-line-discount-operations.service';
import { ModificationService } from '../../../core/services/modification.service';
import { DocumentData } from '../../../base/documents/document-datas/document-data.model';
import { ThirdContactInformation } from '../../../thirds/thirds/third-contacts/third-contact-informations/third-contact-information.model';
import { SaleQuoteHeaderStatusesService } from '../../sale-quotes/sale-quote-header-statuses/sale-quote-header-statuses.service';
import { SaleContractHeaderStatusesService } from '../../sale-contracts/sale-contract-header-statuses/sale-contract-header-statuses.service';
import { MailModel } from '../../../connectors/mail/mail.model';
import { DocumentLineRelationsService } from '../../../base/documents/document-line-relations/document-relation.service';
import { SyslinkColumn } from 'projects/libraries/syslink-components/src/lib/helpers/SyslinkColumn';
import { DXCell } from 'projects/libraries/syslink-components/src/lib/grid/cells/dxcell';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-sale-order-details',
  templateUrl: '../../sale-documents/sale-documents/sale-document-details/sale-document-details.component.html',
  styleUrls: ['./sale-order-details.component.scss']
})
export class SaleOrderDetailsComponent extends SaleDocumentDetailsComponent<
  SaleOrder,
  SaleOrdersService,
  SaleOrderLine,
  SaleOrderLinesService,
  SaleOrderHeaderStatus,
  SaleOrderHeaderStatusesService,
  SaleOrderHeaderHeaderStatus,
  SaleOrderHeaderHeaderStatusesService
> {

  constructor(
    public override saleQuoteHeaderStatusesService: SaleQuoteHeaderStatusesService,
    public override saleOrderHeaderStatusesService: SaleOrderHeaderStatusesService,
    public override saleInvoiceHeaderStatusesService: SaleInvoiceHeaderStatusesService,
    public override saleCreditNoteHeaderStatusesService: SaleCreditNoteHeaderStatusesService,
    public override saleContractHeaderStatusesService: SaleContractHeaderStatusesService,
    public override ngxUiLoaderService: NgxUiLoaderService,
    public override activatedRoute: ActivatedRoute,
    public override reportsService: ReportsService,
    private mailTemplateTypesService: MailTemplateTypesService,
    public override documentService: DocumentsService,
    public override thirdsService: ThirdsService,
    public override documentDatasService: DocumentDatasService,
    public override paymentsService: PaymentsService,
    public override documentRelationsService: DocumentRelationsService,
    public override saleDocumentLineDiscountOperationsService: SaleDocumentLineDiscountOperationsService,
    public override documentLineRelationsService: DocumentLineRelationsService,
    public override modificationService: ModificationService,
    public override sanitizer: DomSanitizer
  ) {
    super(saleQuoteHeaderStatusesService, saleOrderHeaderStatusesService, saleInvoiceHeaderStatusesService, saleCreditNoteHeaderStatusesService, saleContractHeaderStatusesService, ngxUiLoaderService, activatedRoute, reportsService, documentDatasService, thirdsService, paymentsService, documentRelationsService, saleDocumentLineDiscountOperationsService, documentService, documentLineRelationsService, modificationService, sanitizer);
  }

  protected override loadDependencies() {
    this.saleDocumentService = AppInjectorService.injector.get(SaleOrdersService);
    this.saleDocumentLinesService = AppInjectorService.injector.get(SaleOrderLinesService);
    this.saleDocumentStatusesService = AppInjectorService.injector.get(SaleOrderHeaderStatusesService);
    this.saleDocumentHeaderStatusesService = AppInjectorService.injector.get(SaleOrderHeaderHeaderStatusesService);

    this.documentType = "SaleOrder";
    this.subModuleCode = "Sales.Order";
    this.defaultStatusCode = "Order.Draft";
    this.documentLinesColumns = [...this.saleDocumentService.getDefaultDocumentLineColumns(this.subModuleCode, this.documentType),
    new SyslinkColumn({ order: 17, field: 'InvoicedAmount', visible: false, label: 'Invoiced amount', data: { getBlockLabel: this.getInvoicedAmountCellLabel, getBlockClass: this.getInvoicedAmountCellClass.bind(this), getBlockColor: this.getInvoicedAmountCellColor.bind(this), getBlockText: this.getInvoicedAmountCellText.bind(this) }, cellTemplate: 'block-cell', editable: false }),
    ];
  }

  public override canEditDocument(): boolean {
    return !this.hasActiveStatus("Order.Closed");
  }
  public override async onSendMailButtonClicked() {
    if (this.modificationService.hasModifications || !this.element.Report) {
      if (!this.saveConfirmModel) return;
      this.saveConfirmModel.content = 'Please confirm saving and generate report before send mail';
      this.saveConfirmModel.open({ event: 'SendMail' });
    }
    else {
      this.mailModel = undefined;
      setTimeout(async () => {
        this.mailModel = new MailModel();

        this.mailTemplateType = (await this.mailTemplateTypesService.load({ filter: ["Code eq 'SaleOrder'"] }))[0];

        this.element.DocumentDataCollection.forEach((d: DocumentData) => {
          if ((d.HasPrincipal || d.HasSecondary) && this.mailModel) {
            this.mailModel.ToThirdContactInformationId.push(...d.ThirdId.ContactInformations.filter((c: ThirdContactInformation) => c.ContactTypeId?.Code == 'email' && c.UsedForDelivery == true));
          }
          if (d.HasCC && this.mailModel) {
            this.mailModel.CCThirdContactInformationId.push(...d.ThirdId.ContactInformations.filter((c: ThirdContactInformation) => c.ContactTypeId?.Code == 'email' && c.UsedForDelivery == true));
          }
          if (d.HasCCI && this.mailModel) {
            this.mailModel.CCIThirdContactInformationId.push(...d.ThirdId.ContactInformations.filter((c: ThirdContactInformation) => c.ContactTypeId?.Code == 'email' && c.UsedForDelivery == true));
          }
        })

        this.mailModel.ReportType = this.documentType;
        this.mailModel.HeaderId = this.element.Id?.toString() ?? "0";
        this.mailModel.FileName = this.reportsService.updateFileName(this.documentType, this.element.No + ".pdf");
        if (!this.emailModal) return;
        this.emailModal.mailTemplateType = this.mailTemplateType;
        await this.emailModal.loadMailTemplateData();

        await this.emailModal?.open();
      }, 50);
    }
  }
  //-------------------------------------------------------------------------

  // Grid invoiced amount
  getInvoicedAmountCellLabel(cell: DXCell) {
    if (!cell.text) return "";
    if (parseFloat(cell.text) < 0) return "0 €";
    return cell.text + " €";
  }

  getInvoicedAmountCellText(cell: DXCell) {
    return undefined;
  }
  getInvoicedAmountCellClass(cell: DXCell) {
    return 'text-center text-black';
  }

  getInvoicedAmountCellColor(cell: DXCell) {
    if (!cell.data || !cell.data.ExTaxTotalPrice) return "";
    if (parseFloat(cell.data.InvoicedAmount.toFixed(AppInjectorService.config.getPrecision("TotalPrecision"))) >= parseFloat(cell.data.ExTaxTotalPrice.toFixed(AppInjectorService.config.getPrecision("TotalPrecision")))) return "#5cb85c";
    if (parseFloat(cell.data.InvoicedAmount.toFixed(AppInjectorService.config.getPrecision("TotalPrecision"))) < parseFloat(cell.data.ExTaxTotalPrice.toFixed(AppInjectorService.config.getPrecision("TotalPrecision"))) && cell.data.InvoicedAmount > 0) return "#f0ad4e";
    return "";
  }
}