import { SaleDocumentHeaderStatus } from "../../sale-documents/sale-document-header-status/sale-document-header-status.model";
import { SaleCreditNoteHeaderStatus } from "../sale-credit-note-header-statuses/sale-credit-note-header-status.model";
import { SaleCreditNote } from "../sale-credit-notes/sale-credit-note.model";

export class SaleCreditNoteHeaderHeaderStatus extends SaleDocumentHeaderStatus {
    public override HeaderId?: SaleCreditNote;
    public override StatusId?: SaleCreditNoteHeaderStatus = new SaleCreditNoteHeaderStatus();

    constructor(entity?: Partial<SaleCreditNoteHeaderHeaderStatus>) {
        super();
        Object.assign(this, entity);
    }

}