<syslink-grid
    [canView]="canView"
    [canSearch]="canSearch"
    [canRefresh]="canRefresh"
    [canAdd]="canAdd"
    [canSort]="canSort"
    [canUpdate]="canUpdate"
    [canDelete]="canDelete"
    [canFilter]="canFilter"
    [canExport]="canExport"
    [canDblClck]="canDblClck"
    [(items)]="customerCatalogs"
    [columns]="columns"
    storageKey="storageKey"
    exportFileName="Products customers catalogs"
    (add)="add.emit($event)"
    (afterUpdate)="afterUpdate.emit($event)"
    (beforeDelete)="beforeDelete.emit($event)"
></syslink-grid>