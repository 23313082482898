import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AccountMove } from '../account-move.model';

@Component({
  selector: 'app-account-move-total',
  templateUrl: './account-move-total.component.html',
  styleUrls: ['./account-move-total.component.scss']
})
export class AccountMoveTotalComponent {
  @Input() public element: AccountMove = new AccountMove();
  @Output() public elementChange: EventEmitter<AccountMove> = new EventEmitter<AccountMove>();
 
  public getDebit():number{
    if(!this.element || !this.element.AccountMoveLines) return 0;
    return this.element.AccountMoveLines.reduce((sum, item) => sum + (item.Debit ??0), 0);
  }
 
  public getCredit():number{
    if(!this.element || !this.element.AccountMoveLines) return 0;
    return this.element.AccountMoveLines.reduce((sum, item) => sum + (item.Credit ??0), 0);
  }
  
  public getTotal():number{
    return this.getDebit()-this.getCredit();
  }

  public getTotalStateColor():string{
    if((this.getDebit()-this.getCredit() != 0)) return "danger";
    return 'primary';
  }
}
