import { AfterViewInit, Component, Input, OnInit, ViewChildren } from '@angular/core';
import { ComponentContainerService } from '../../component-container/component-container.service';
import { ComponentContainerDirective } from '../../component-container/component-container.directive';
import { AppInjectorService } from '../../services/app-injector.service';
import { Router } from '@angular/router';
import { AuthService } from 'projects/erp-app/src/app/core/auth/auth.service';

@Component({
  selector: 'syslink-view',
  templateUrl: './view.component.html',
  styleUrls: ['./view.component.scss']
})
export class ViewComponent implements OnInit, AfterViewInit {

  @Input() basePermissionKey?: string;
  @Input() newBasePermissionKey?: string;
  @Input() permissionKey?: string;

  protected componentContainerService: ComponentContainerService;
  @ViewChildren(ComponentContainerDirective) componentContainers?: ComponentContainerDirective[];

  protected router: Router;
  protected authService:AuthService
  constructor() {
    this.componentContainerService = AppInjectorService.injector.get(ComponentContainerService);
    this.router = AppInjectorService.injector.get(Router);
    this.authService = AppInjectorService.injector.get(AuthService);
    this.initPermissionKey();
  }
  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.componentContainers?.forEach((container: ComponentContainerDirective) => {
      container.viewContainerRef.clear();
      this.componentContainerService.createComponent(container);
    });
  }

  protected initPermissionKey(): void {
    const url = this.router.url;
    const parts: string[] = url.split("/");
    let desiredPart: string = parts.slice(1, parts.length - 1).join("/");

    // Check if the last part is a number (integer ID) or a GUID
    const lastPart: string = parts[parts.length - 1];
    const guidPattern: RegExp = /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/;
    if (isNaN(Number(lastPart)) && !guidPattern.test(lastPart)) {
      desiredPart += "/" + lastPart;
    }

    this.basePermissionKey = desiredPart.replaceAll("/", ".").replaceAll(".new", "");
    this.newBasePermissionKey = this.basePermissionKey?.replace(/\?.*/, ".details").replace('.copy', ".details");

    const lastUrlPart = this.router.url.split('/').pop();
    if (lastUrlPart && (!isNaN(+lastUrlPart) || lastUrlPart == "new" || guidPattern.test(lastUrlPart))) {
      this.basePermissionKey += '.details';
      this.newBasePermissionKey = this.basePermissionKey?.replace(/\?.*/, ".details").replace('.copy', ".details");
    }
  }
}
