import { Injectable } from '@angular/core';
import { SequenceConfiguration } from './sequence-configuration.model';
import { ODataService } from '../services/oData.service';

@Injectable({
  providedIn: 'root'
})
export class SequenceConfigurationsService extends ODataService<SequenceConfiguration> {
  public url = 'SequenceConfig';
}
