<!-- Toolbar -->
<syslink-toolbar
  [actions]="toolbarActions"
  class="me-3"
  [withLine]="false"
></syslink-toolbar>
<div class="row">
  <div class="col-sm-9 col-xs-12">
    <!-- Toolbar -->

    <syslink-block
      [title]="getFormattedTitle()"
      [translateTitle]="false"
    >
    <div class="row">
      
      <div class="col-sm-6 col-xs-12">
        <app-product-form [(element)]="element"></app-product-form>
      </div>
      <div class="col-sm-6 col-xs-12">
        <syslink-wysiwyg
          id="wysiwig_description"
          [(model)]="element.Description"
          [showTable]="false"
          [toolbar]="true"
          height="140"
          minHeight="140"
          label="Description"
        >
        </syslink-wysiwyg>
      </div>
    </div>

      <div class="mt-4">
        <syslink-tabs
          #productTabs
          (selectedIdChange)="onChangeSelectedId($event)"
        >
          <syslink-tab
            [tabData]="tabsData[0]"
            [visible]="
              authService.hasPermission(
                this.newBasePermissionKey + '.purchase.tab.view'
                ) && element.Purchasable
                "
          >
            <app-product-purchase-form
              *ngIf="tabsData[0].loaded"
              [(element)]="element"
            ></app-product-purchase-form>
          </syslink-tab>
          <syslink-tab
            [tabData]="tabsData[1]"
            [visible]="
              authService.hasPermission(
                this.newBasePermissionKey + '.sale.tab.view'
              ) &&
              element.Sellable &&
              element.FilteredCustomerCatalogs != null
            "
          >
            <app-product-sale-form
              *ngIf="tabsData[1].loaded"
              [(element)]="element"
            ></app-product-sale-form>
          </syslink-tab>
          <syslink-tab
            [tabData]="tabsData[2]"
            [visible]="
              authService.hasPermission(
                this.newBasePermissionKey + '.stock.tab.view'
              ) && element.Stockable
            "
          >
            <app-product-stock-form
              *ngIf="tabsData[2].loaded"
              [(element)]="element"
            ></app-product-stock-form>
          </syslink-tab>
          <syslink-tab
            [tabData]="tabsData[3]"
            [visible]="
          authService.hasPermission(
            this.newBasePermissionKey + '.finance.tab.view'
          )
        "
          >
            <app-product-finance-form
              *ngIf="tabsData[3].loaded"
              [(element)]="element"
            ></app-product-finance-form>
          </syslink-tab>
          <syslink-tab
            [tabData]="tabsData[4]"
            [visible]="authService.hasPermission(this.newBasePermissionKey + '.statistics.tab.view') && (element.Sellable || element.Purchasable)"
            *ngIf="element.Id"
          >
            <app-product-statistics
              *ngIf="tabsData[4].loaded"
              [element]="element"
              [productId]="element.Id"
            ></app-product-statistics>
          </syslink-tab>
        </syslink-tabs>
      </div>
    </syslink-block>
  </div>

  <div class="col-sm-3 col-xs-12 mt-3 mt-sm-0">
    <syslink-block
      [title]="'Summary'"
      [titleSize]="6"
    >
      <app-product-summary [(element)]="element"></app-product-summary>
    </syslink-block>

    <syslink-block
      [title]="'Note'"
      class="ms-3"
    >
      <syslink-wysiwyg
        id="wysiwig_internal_note"
        [(model)]="element.InternalNote"
        [showTable]="false"
        [toolbar]="false"
        height="140"
        minHeight="140"
        [labelMode]="'hidden'"
      >
      </syslink-wysiwyg>
    </syslink-block>
  </div>
</div>

<syslink-confirm-modal
  #deleteConfirm
  title="Delete"
  content="DeleteConfirmMessage"
  (onValidate)="productDelete()"
  validateText="Confirm"
  cancelText="Cancel"
></syslink-confirm-modal>