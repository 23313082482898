<div
    class="{{class}}"
    [hidden]="hidden"
>
    <div class="d-flex align-items-center gap-3">
        <dx-select-box
            #select
            id="select-{{name}}"
            class="flex-grow-1"
            [dataSource]="items ?? dataSource"
            [valueExpr]="valueKey"
            [displayExpr]="displayKey"
            [(value)]="model"
            (onValueChanged)="onModelChange($event.value)"
            [stylingMode]="mode"
            [label]="getLabel()"
            [labelMode]="labelMode"
            [searchEnabled]="allowSearch"
            [readOnly]="disabled"
            [acceptCustomValue]="onCustomValue.observed || onShowAdvancedSearch.observed"
            customItemCreateEvent="focusout"
            (onCustomItemCreating)="onCustomItemCreating($event)"
            [showClearButton]="allowClearing"
            itemTemplate="itemTemplate"
            (onFocusIn)="onFocusIn($event)"
            (onFocusOut)="onFocusOut($event)"
        >
            <div *dxTemplate="let item of 'itemTemplate'">
                <div id="select-{{name}}-{{valueKey}}">
                    {{getDisplayValue(item)}}
                </div>
            </div>
        </dx-select-box>

        <syslink-link
            *ngIf="canRefresh"
            icon="fas fa-rotate"
            (onClick)="forceReload()"
        ></syslink-link>
        <syslink-link
            *ngIf="canView && detailsUrl && model?.Id"
            [newTab]="true"
            [url]="detailsUrl + model.Id"
            icon="fas fa-eye"
        ></syslink-link>
        <syslink-link
            *ngIf="canAdd && detailsUrl && !onAdd.observed"
            [newTab]="true"
            [url]="detailsUrl + 'new'"
            icon="fas fa-plus"
        ></syslink-link>
        <syslink-link
            *ngIf="canAdd && detailsUrl && onAdd.observed"
            icon="fas fa-plus"
            (onClick)="onAdd.emit()"
        ></syslink-link>
        <i
            *ngIf="onShowAdvancedSearch.observed"
            class="fas fa-magnifying-glass text-primary"
            (click)="onShowAdvancedSearch.emit(select.text)"
            role="button"
        ></i>
    </div>
</div>
