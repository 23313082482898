import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { ModalComponent, ConfirmModalComponent, NotificationsService } from 'projects/libraries/syslink-components/src/public-api';
import { Third } from '../third.model';
import { ThirdRelation } from './third-relation.model';
import { ThirdRelationsService } from './third-relations.service';
import { ViewComponent } from 'projects/libraries/syslink-components/src/lib/helpers/view/view.component';
import { AppInjectorService } from 'projects/libraries/syslink-components/src/lib/services/app-injector.service';

@Component({
  selector: 'app-third-relations',
  templateUrl: './third-relations.component.html',
  styleUrls: ['./third-relations.component.scss']
})
export class ThirdRelationsComponent extends ViewComponent {
  @Input() public element: Third = new Third();
  @Output() elementChange: EventEmitter<Third> = new EventEmitter<Third>();

  @ViewChild('editModal') public editModal: ModalComponent = new ModalComponent();
  @ViewChild('deleteConfirm') public deleteConfirm: ConfirmModalComponent = new ConfirmModalComponent();

  public relation: ThirdRelation = new ThirdRelation();

  constructor(
    private thirdRelationsService: ThirdRelationsService
  ) {
    super();
  }

  public onThirdClick(thirdId?: number) {
    window.open(this.router.serializeUrl(this.router.createUrlTree(["/basics/thirds/thirds/" + thirdId])), '_blank');
  }

  public async initModalDatas(relation?: ThirdRelation) {
    this.relation = relation ?? await this.thirdRelationsService.getInstance(this.element);
  }

  // Create
  // ------
  public async onCreateBtnClicked() {
    await this.initModalDatas();
    this.editModal.open({ newItem: true });
  }

  public async onCreate(relation: ThirdRelation) {
    if (this.canCreateOrUpdate(relation) == false) return;
    this.element.AllRelationThirds.push(relation);

    this.editModal.close();
  }
  // ----------------------------------------------

  // Update
  // ------
  public async onUpdateBtnClicked(relation: ThirdRelation) {
    if (!this.authService.hasPermission(this.newBasePermissionKey + '.relations.update')) {
      NotificationsService.sendErrorMessage("You do not have the required permission!");
      return;
    }
    await this.initModalDatas(relation);
    this.editModal.open({ newItem: false });
  }

  public async onUpdate(relation: ThirdRelation) {
    if (this.canCreateOrUpdate(relation) == false) return;
    this.element.AllRelationThirds.map((e: ThirdRelation) => {
      if (e.Id !== relation.Id) return;
      e = relation;
    });
    this.editModal.close();
  }
  // ----------------------------------------------

  // Delete
  // ------
  public onDeleteBtnClicked(relation: ThirdRelation) {
    if (!relation.Id) {
      var index = this.element.AllRelationThirds.findIndex((e: ThirdRelation) =>
        e.FromThirdId?.Id == relation.FromThirdId?.Id &&
        e.ToThirdId?.Id == relation.ToThirdId?.Id &&
        e.ThirdRelationTypeId?.Id == relation.ThirdRelationTypeId?.Id &&
        e.IsPublic == relation.IsPublic
      );
      this.element.AllRelationThirds.splice(index, 1);
      return;
    }
    if (!this.authService.hasPermission(this.newBasePermissionKey + '.relations.delete')) {
      NotificationsService.sendErrorMessage("You do not have the required permission!");
      return;
    }
    this.deleteConfirm.open({ relationId: relation.Id });
  }

  public async onDelete() {
    this.element.AllRelationThirds = this.element.AllRelationThirds.filter((e: ThirdRelation) => e.Id !== this.deleteConfirm.modal.data.relationId);
    AppInjectorService.config.setModificationGuard(true);
    this.deleteConfirm.close();
  }
  // ----------------------------------------------

  private canCreateOrUpdate(element: ThirdRelation): boolean {
    if (!element.ToThirdId || !element.ToThirdId.Id) {
      NotificationsService.sendErrorMessage("Third cannot be empty")
      return false;
    }

    if (!element.ThirdRelationTypeId || !element.ThirdRelationTypeId.Id) {
      NotificationsService.sendErrorMessage("Relation type cannot be empty")
      return false;
    }
    return true;
  }
}
