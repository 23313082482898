import { Injectable } from '@angular/core';
import { LoadOptions } from 'devextreme/data';
import { PurchaseDocumentsService } from '../../purchase-documents/purchase-documents/purchase-documents.service';
import { PurchaseOrder } from './purchase-order.model';
import { PurchaseOrderHeaderStatus } from '../purchase-order-header-statuses/purchase-order-header-status.model';

@Injectable({
  providedIn: 'root'
})
export class PurchaseOrdersService extends PurchaseDocumentsService<PurchaseOrder, PurchaseOrderHeaderStatus> {
  public override url: string = 'PurchaseOrderHeader';
  
  public override defaultOptions: LoadOptions = {
    expand: [
      'ThirdId.CustomerId.PaymentMethodId',
      'CurrentStatusLink.StatusId',
      'ResponsibleUserId',
      'Lines($orderby=LineNo;$expand=TaxRateId($expand=NameTranslationId),UnitId,Discount,Children,PurchaseDocumentLineDiscountOperations,ParentId, ProductId)',
      'Statuses.StatusId',
      'DeliveryTimeId',
      'DocumentDataCollection.ThirdId.ThirdContacts',
      'DocumentDataCollection.ThirdId.Addresses',
      'DocumentDataCollection.AddressId',
      'DocumentDataCollection.DeliveryAddressId',
      'Payments.PaymentMethodPrimary',
      'Payments.PaymentStateId',          
      'Payments.PaymentTypeId',   
      'ThirdId.ContactInformations.ThirdId',
      'ThirdId.ContactInformations.ContactTypeId',
      'DocumentRelations'
    ]
  };

  public override async getInstance(order?: Partial<PurchaseOrder>): Promise<any> {

    let element = {
      Date: new Date(),
      ...order,
    };
    element = await this.refreshResponsibleUser(element);
    element = await this.refreshThirdDocumentDelayFromSupplier(element);
    element = await this.refreshThirdDeliveryTimeFromSupplier(element);
    return element;
  }
}
