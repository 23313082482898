import { Injectable } from '@angular/core';
import { ODataService } from '../../../core/services/oData.service';
import { AccountMoveStatus } from './account-move-status.model';

@Injectable({
  providedIn: 'root'
})
export class AccountMoveStatusService extends ODataService<AccountMoveStatus> {
  public url = 'AccountMoveStatus';

}
