<syslink-toolbar
  [actions]="toolbarActions"
  class="me-3"
  [withLine]="false"
></syslink-toolbar>

<div class="row">
  <div class="col-sm-9 col-xs-12">
    <syslink-block
      [title]="element.Name??''"
      [translateTitle]="false"
    >
      <div class="col-sm-5 col-xs-12">
        <app-user-group-form [(element)]="element"></app-user-group-form>
      </div>

      <div
        class="row mt-3"
        *ngIf="element.Id"
      >
        <syslink-tabs #userPermissions>
          <syslink-tab
            label="Users"
            [visible]="authService.hasPermission(this.basePermissionKey + '.user.tab.view')"
          >
            <app-user-grid
              #userGrid
              [permissionKey]="'users.list'"
              [canUpdate]="false"
              (add)="onAddUserButtonClicked()"
              (delete)="onDeleteButtonClicked($event)"
              [filter]="usersFilters"
            ></app-user-grid>
            <app-user-select-modal
              #userSelectModal
              (validate)="onValidateUserAddButtonClicked($event)"
            ></app-user-select-modal>
          </syslink-tab>

          <syslink-tab
          label="Action groups"
          [visible]="authService.hasPermission(this.basePermissionKey + '.actionGroup.tab.view')"
        >
          <app-action-group-grid
            #actionGroupsGrid
            [canUpdate]="false"
            [permissionKey]="'actionGroups.list'"
            storageKey="userGroup-details-grid-action-groups"
            [filter]="actionGroupsFilters"
            (add)="addActionGroupButtonClicked()"
            (delete)="onDeleteActionGroup($event)"
            [select]="['Id', 'Code','Name']"
            [expand]="['NameTranslationId.Translations.LanguageId']"
          >
          </app-action-group-grid>

          <app-action-group-select-modal
            #actionGroupSelectModal
            (validate)="onValidateActionGroupAddButtonClicked($event)"
          ></app-action-group-select-modal>
        </syslink-tab>
        </syslink-tabs>
      </div>

    </syslink-block>

  </div>

  <div class="col-sm-3 col-xs-12">
    <syslink-block
      [title]="'Other'"
      [titleSize]="6"
    > <syslink-color-picker
        name="backgroundColor"
        label="BackgroundColor"
        [(model)]="element.BackgroundColor"
      ></syslink-color-picker>

      <syslink-color-picker
        name="foregroundColor"
        label="ForegroundColor"
        [(model)]="element.ForegroundColor"
      ></syslink-color-picker>
    </syslink-block>
  </div>
</div>

<syslink-confirm-modal
  #deleteConfirm
  title="Delete"
  content="DeleteConfirmMessage"
  (onValidate)="groupDelete()"
  [validateText]="'Confirm'"
  [cancelText]="'Cancel'"
></syslink-confirm-modal>