import { Component, Input, ViewChild } from '@angular/core';
import { AccountMove } from '../account-move.model';
import { BaseGridComponent } from 'projects/libraries/syslink-components/src/lib/grid/base-grid/base-grid.component';
import { GridComponent } from 'projects/libraries/syslink-components/src/public-api';
import { SyslinkColumn } from 'projects/libraries/syslink-components/src/lib/helpers/SyslinkColumn';
import { AccountMovesService } from '../account-move.service';
import { DXCell } from 'projects/libraries/syslink-components/src/lib/grid/cells/dxcell';

@Component({
  selector: 'app-account-move-grid',
  templateUrl: './account-move-grid.component.html',
  styleUrls: ['./account-move-grid.component.scss']
})
export class AccountMoveGridComponent extends BaseGridComponent<AccountMove> {

  @Input() storageKey: string = 'grid-account-moves';
  @Input() public override expand?: string | string[] = ['AccountJournalId', 'AccountPeriodId', 'ThirdId', 'DocumentId','CurrentStatusLink.StatusId'];

  @ViewChild('gridComponent') public gridComponent?: GridComponent;
  
  public columns: SyslinkColumn[] = [
    new SyslinkColumn({ field: 'No', sortable: true, sortIndex: 0, sortDirection: 'desc' }),
    new SyslinkColumn({ field: 'AccountJournalId.Name', label: 'Journal'}),
    new SyslinkColumn({ field: 'AccountPeriodId.Code', label: 'Period' }),
    new SyslinkColumn({ field: 'ThirdId.Fullname', label: 'Third'}),
    new SyslinkColumn({ field: "CurrentStatusLink.StatusId.Name", label: "Status", cellTemplate: 'block-cell', data: { getBlockLabel: this.getStatusCellLabel, getBlockClass: this.getStatusCellClass, getBlockColor: this.getStatusCellColor }}),
    new SyslinkColumn({ field: 'Date', label: 'Date', type: 'date'}),
    new SyslinkColumn({ field: 'DocumentId.NameFormatted', label: 'Document'}),
];

  constructor(
    public accountMovesService: AccountMovesService
  ) {
    super();
  }

    // Status cell
    getStatusCellLabel(cell: DXCell) {
      return cell.data.CurrentStatusLink.StatusId.Name;
    }
  
    getStatusCellClass(cell: DXCell) {
      let statusClass = 'text-white text-center'
  
      const statusCode = (<string>cell.data.CurrentStatusLink?.StatusId?.Code).toLowerCase().split('.')[1];
      if (statusCode == undefined) return;
      statusClass += ' ' + statusCode;
      return statusClass;
    }
  
    getStatusCellColor(cell: DXCell) {
      return cell.data.CurrentStatusLink.StatusId.Color;
    }
}