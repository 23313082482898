<ng-container *ngFor="let ContactInformation of element.ContactInformations">
    <div *ngIf="authService.hasPermission(newBasePermissionKey + '.contacts.view')" class="h-100 d-flex  border-bottom mb-1 pb-1 justify-content-center align-items-center gap-2">
        <i
            class="fa-solid fa-{{ContactInformation.ContactTypeId?.Icon ?? 'diamond'}} text-center text-gray mx-2"
            [title]="ContactInformation.ContactTypeId?.Name??'Other' | translate"
        ></i>

        <div class=" flex-grow-1 align-items-center">
            <a class="text-gray fst-italic fw-light">
                {{ContactInformation.ContactTypeId?.Code === 'Address' ?ContactInformation.AddressId?.FormattedAddress:ContactInformation.Value}}
            </a>
            <ng-container *ngIf="ContactInformation.ContactQualifierId?.Id">
                <br>
                {{ContactInformation.ContactQualifierId?.Name ?? ''}}
            </ng-container>
            <br>
            <div class="d-flex align-items-center gap-2 mt-2">
                <i
                class="fa-solid {{ContactInformation.UsedForQuote ? 'cl-accent' : 'cl-gray'}} fa-file-lines text-center text-gray"
                [title]="'Used for quotes' | translate"
                ></i>
                <i
                class="fa-solid {{ContactInformation.UsedForDelivery ? 'cl-accent' : 'cl-gray'}} fas fa-shipping-fast text-center text-gray"
                [title]="'Used for deliveries' | translate"
                ></i>
                <i
                class="fa-solid {{ContactInformation.UsedForInvoice ? 'cl-accent' : 'cl-gray'}} fa-file-invoice text-center text-gray"
                [title]="'Used for invoices' | translate"
                ></i>
                <i
                class="fa-solid {{ContactInformation.IsPublic ? 'cl-accent' : 'cl-gray'}} fa-eye text-center text-gray"
                style="padding-left: 15px !important"
                [title]="'Public' | translate"
                ></i>
                <i
                class="fa-solid {{ContactInformation.IsFavorite ? 'cl-accent' : 'cl-gray'}} fas fa-star text-center text-gray"
                [title]="'Favorite' | translate"
                ></i>
            </div>
        </div>
        <div class="d-flex align-items-center gap-2 mx-2">
            <i
                *ngIf="authService.hasPermission(newBasePermissionKey + '.contacts.update') && canUpdate"
                class="fa-solid fa-pencil text-center text-gray"
                role="button"
                (click)="onUpdateBtnClicked(ContactInformation)"
                [title]="'Update' | translate"
            ></i>
            <i
                *ngIf="authService.hasPermission(newBasePermissionKey + '.contacts.delete') && canUpdate"
                class="fa-solid fa-trash text-center text-danger"
                role="button"
                (click)="onDeleteBtnClicked(ContactInformation)"
                [title]="'Delete' | translate"
            ></i>
        </div>
    </div>
</ng-container>
<div
    *ngIf="authService.hasPermission(newBasePermissionKey + '.contacts.add') && canUpdate"
    class="full-width-container"
>
    <syslink-button
        class="full-width-button"
        icon="plus"
        (click)="onCreateBtnClicked()"
    ></syslink-button>
</div>

<syslink-confirm-modal
    #deleteConfirm
    title="Delete"
    content="DeleteConfirmMessage"
    validateText="Confirm"
    cancelText="Cancel"
    (onValidate)="onDelete()"
></syslink-confirm-modal>

<syslink-modal
    #editModal
    title="Contact"
    width="600px"
    (onValidate)="editModal.data.newItem==false? onUpdate(contactInformation) : onCreate(contactInformation)"
    [validateText]="(editModal.data.newItem==false ? 'Validate' : 'Create')"
    cancelText="Cancel"
>
    <app-third-contact-form [(element)]="contactInformation"></app-third-contact-form>
</syslink-modal>