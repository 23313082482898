import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DocumentLineType } from 'projects/erp-app/src/app/base/documents/document-lines/document-line.model';
import { Product } from 'projects/erp-app/src/app/products/products/product.model';
import { ConfirmModalComponent, ModalComponent, NotificationsService, SyslinkToolbarActionButton } from 'projects/libraries/syslink-components/src/public-api';
import { SaleDocumentLine } from '../../sale-document-lines/sale-document-line.model';
import { SaleDocumentLinesService } from '../../sale-document-lines/sale-document-lines.service';
import { SaleDocument } from '../sale-document.model';
import { SaleDocumentsService } from '../sale-documents.service';
import { SyslinkColumn } from 'projects/libraries/syslink-components/src/lib/helpers/SyslinkColumn';
import { getNew } from 'projects/libraries/syslink-components/src/lib/helpers/tools';
import { ViewComponent } from 'projects/libraries/syslink-components/src/lib/helpers/view/view.component';
import { SaleDocumentStatus } from '../../sale-document-statuses/sale-document-status.model';
import { WorkTimesPickerComponent } from 'projects/erp-app/src/app/time-management/work-times/work-times/work-times-picker/work-times-picker.component';
import { DocumentLinesComponent } from 'projects/erp-app/src/app/base/documents/document-lines/document-lines.component';
import { AppInjectorService } from 'projects/libraries/syslink-components/src/lib/services/app-injector.service';
import { DocumentsService } from 'projects/erp-app/src/app/base/documents/documents/documents.service';

@Component({
  selector: 'app-sale-document-content',
  templateUrl: './sale-document-content.component.html',
  styleUrls: ['./sale-document-content.component.scss']
})
export class SaleDocumentContentComponent<
  TSaleDocument extends SaleDocument,
  TSaleDocumentService extends SaleDocumentsService<TSaleDocument, SaleDocumentStatus>,
  TSaleDocumentLine extends SaleDocumentLine,
  TSaleDocumentLineService extends SaleDocumentLinesService<SaleDocumentLine>
> extends ViewComponent {
  @Input() public element: TSaleDocument = <TSaleDocument>getNew<TSaleDocument>();
  @Output() public elementChange: EventEmitter<TSaleDocument> = new EventEmitter<TSaleDocument>();

  @Input() public saleDocumentService: TSaleDocumentService = <TSaleDocumentService>getNew<TSaleDocumentService>();
  @Input() public saleDocumentLinesService: TSaleDocumentLineService = <TSaleDocumentLineService>getNew<TSaleDocumentLineService>();

  @Input() public selectedKey: any = undefined;
  @Output() public elementLineChange: EventEmitter<TSaleDocumentLine> = new EventEmitter<TSaleDocumentLine>();

  @ViewChild('createNewProduct') createNewProduct: ConfirmModalComponent = new ConfirmModalComponent();
  @ViewChild('workTimePicker') workTimePicker?: WorkTimesPickerComponent;

  public _disabled: boolean = false;
  @Input() set disabled(value: boolean) {
    this._disabled = value;
    this.initTollbarActions();
  }
  get disabled(): boolean {
    return this._disabled;
  }

  @Input() public documentType: String = '';
  @Input() public subModuleCode: string = '';
  @Input() public columns: SyslinkColumn[] = [];

  @ViewChild('documentLines') private documentLines?: DocumentLinesComponent;
  @ViewChild('addingProductModal') private addingProductModal?: ModalComponent;

  public isAddingLine: boolean = false;
  public loadAddingProductModal: boolean = false;

  public toolbarActions?: SyslinkToolbarActionButton[];

  public insertLineId:number = 1;

  constructor(
    public activatedRoute: ActivatedRoute,
    private translateService: TranslateService,
    private documentsService:DocumentsService
  ) {
    super();
    this.initTollbarActions();
  }

  private initTollbarActions(): void {
    this.toolbarActions = [
      new SyslinkToolbarActionButton({ icon: "product", hint: this.translateService.instant("Add product"), onClick: () => this.onAddProductClicked(), visible: !this.disabled && this.authService.hasPermission(this.newBasePermissionKey + '.content.product.add') }),
      new SyslinkToolbarActionButton({ icon: "mediumiconslayout", hint: this.translateService.instant("Add various"), onClick: () => this.onAddVariousClicked(), visible: !this.disabled && this.authService.hasPermission(this.newBasePermissionKey + '.content.various.add') }),
      new SyslinkToolbarActionButton({ icon: "header", hint: this.translateService.instant("Add title"), onClick: () => this.onAddTitleClicked(), visible: !this.disabled && this.authService.hasPermission(this.newBasePermissionKey + '.content.title.add') }),
      new SyslinkToolbarActionButton({ icon: "font", hint: this.translateService.instant("Add text"), onClick: () => this.onAddTextClicked(), visible: !this.disabled && this.authService.hasPermission(this.newBasePermissionKey + '.content.text.add') }),
      new SyslinkToolbarActionButton({ icon: "file", hint: this.translateService.instant("Add page"), onClick: () => this.onAddPageClicked(), visible: !this.disabled && this.authService.hasPermission(this.newBasePermissionKey + '.content.page.add') })
    ];
  }

  private async onAddProductClicked(): Promise<void> {

    this.loadAddingProductModal = true;
    setTimeout(() => {
      this.addingProductModal?.open()
    }, 50);
  }

  private async onAddTextClicked(): Promise<void> {
    if (this.isAddingLine == true) return;
    this.isAddingLine = true;
    const reference = this.translateService.instant("Text");
    const line = await this.saleDocumentLinesService.getInstance({
      // HeaderId: new SaleDocument({ Id: this.element.Id }),
      LineType: DocumentLineType.text,
      Reference: reference
    });
    this.insertLine(line);
  }

  private async onAddTitleClicked(): Promise<void> {
    if (this.isAddingLine == true) return;
    this.isAddingLine = true;
    const reference = this.translateService.instant("Title");
    const line = await this.saleDocumentLinesService.getInstance({
      // HeaderId: new SaleDocument({ Id: this.element.Id }),
      LineType: DocumentLineType.post,
      Reference: reference
    });
    this.insertLine(line);
    this.computeParentLineAmount();
  }

  private async onAddVariousClicked(): Promise<void> {
    if (this.isAddingLine == true) return;
    this.isAddingLine = true;
    if (!this.element.ThirdId.Id) return;
    var saleDocumentCreateLineDTO = await this.saleDocumentLinesService.createVariousLine(this.element.ThirdId.Id);
    var line = await this.saleDocumentLinesService.getInstance(saleDocumentCreateLineDTO.line);
    // line.HeaderId = new SaleDocument({ Id: this.element.Id }),
    line.LineType = DocumentLineType.various;
    line.Reference = this.translateService.instant("Various");
    line.Discount = saleDocumentCreateLineDTO.discount;
    line.Margin = saleDocumentCreateLineDTO.margin;
    line.Penalty = saleDocumentCreateLineDTO.penalty;
    line.SaleDocumentLineDiscountOperations = saleDocumentCreateLineDTO.saleDocumentLineDiscountOperations;
    line.TaxRateId = saleDocumentCreateLineDTO.taxRateId;
    line.UnitId = saleDocumentCreateLineDTO.unitId;
    line = await this.computeLinePrice(line);
    this.insertLine(line);
  }

  private async onAddPageClicked(): Promise<void> {
    if (this.isAddingLine == true) return;
    this.isAddingLine = true;
    const reference = this.translateService.instant("Page");
    const line = await this.saleDocumentLinesService.getInstance({
      // HeaderId: new SaleDocument({ Id: this.element.Id }),
      LineType: DocumentLineType.page,
      Reference: reference
    });
    this.insertLine(line);
  }

  // Product
  // -------
  public async onProductDoubleClick(product: Product) {
    if (this.isAddingLine == true) return;
    this.isAddingLine = true;
    if (!this.element.ThirdId.Id || !product.Id) return;
    var saleDocumentCreateLineDTO = await this.saleDocumentLinesService.createProductLine(this.element.ThirdId.Id, product.Id);

    var line = await this.saleDocumentLinesService.getInstance(saleDocumentCreateLineDTO.line);
    // line.HeaderId = new SaleDocument({ Id: this.element.Id }),
    line.LineType = DocumentLineType.product;
    line.Discount = saleDocumentCreateLineDTO.discount;
    line.Margin = saleDocumentCreateLineDTO.margin;
    line.Penalty = saleDocumentCreateLineDTO.penalty;
    line.SaleDocumentLineDiscountOperations = saleDocumentCreateLineDTO.saleDocumentLineDiscountOperations;
    line.TaxRateId = saleDocumentCreateLineDTO.taxRateId;
    line.UnitId = saleDocumentCreateLineDTO.unitId;
    line.ProductId = product;

    line = await this.computeLinePrice(line);
    this.insertLine(line);
    
    NotificationsService.sendSuccess('added line')
  }
  //-------------------------------------------------------------------------

  // Delete Line
  // -----------
  public async onDeleteLine(event: any) {
    if (!event.key) return;
    this.element.Lines = this.element.Lines?.filter((l: SaleDocumentLine) => l.Id != event.key);
    this.element.Lines = this.updateLineNo(this.element.Lines ?? []);
    this.element = this.documentsService.computeDocumentPrice(this.element);
    AppInjectorService.config.setModificationGuard(true);
    this.reloadTreelist();
  }
  //-------------------------------------------------------------------------

  // Update Line
  // -----------
  public async onUpdateLine(event: any) {
    if (!event.key || !this.element.Lines) return;
    AppInjectorService.config.setModificationGuard(true);
    let index: number = this.element.Lines?.findIndex((l: SaleDocumentLine) => l.Id == event.key)
    const newData = event.newData;
    const oldData = event.oldData;

    if (index < 0) return;

    for (const key in newData) {
      if(!this.element || !this.element.Lines) continue;
      if (newData.hasOwnProperty(key) && oldData.hasOwnProperty(key)) {
        if (newData[key] !== oldData[key]) {
          // Fix format number
          if(typeof oldData[key] === 'number' && typeof newData[key] !== 'number'){
            newData[key] = parseFloat((newData[key]).replace(',', '.'));
          }

          switch (key) {
            case 'ForcedPrice':
              if (newData[key] == null) {
                this.element.Lines[index].ForcedPrice = 0;
              }
              else {
                (this.element.Lines[index] as any)[key] = newData[key] ?? 0;
              }
              this.element.Lines[index] = await this.computeLinePrice(this.element.Lines[index]);
              this.element = this.documentsService.computeDocumentPrice(this.element);
              // Reload treelist forcedPrice afterUpdate
              // Todo check best method // this event is before update and treelist set value to undefined to after update
              if (newData[key] == null) {
                setTimeout(() => {
                  if (!this.element.Lines) return;
                  this.element.Lines[index].ForcedPrice = 0;
                }, 50);
              }
              break;
            case 'Quantity':
            case 'ExTaxGrossPrice':
            case 'ExTaxSaleGrossPrice':
            case 'ExTaxUnitPrice':
            case 'HourlyRate':
            case 'InTaxTotalPrice':
            case 'Labor':
            case 'HourlyRate':
            case 'TaxRateId':
            case 'ExTaxPurchasePrice':
              (this.element.Lines[index] as any)[key] = newData[key] ?? 0;
              this.element.Lines[index] = await this.computeLinePrice(this.element.Lines[index]);
              this.element = this.documentsService.computeDocumentPrice(this.element);
              break;
            case 'Margin':
            case 'Discount':
              (this.element.Lines[index] as any)[key] = newData[key];
              (this.element.Lines[index] as any)["SaleDocumentLineDiscountOperations"] = [(this.element.Lines[index] as any)["Margin"], (this.element.Lines[index] as any)["Discount"]]
              this.element.Lines[index] = await this.computeLinePrice(this.element.Lines[index]);
              this.element = this.documentsService.computeDocumentPrice(this.element);
              break;
            default:
              (this.element.Lines[index] as any)[key] = newData[key];
          }
        }
      }
    }
    this.computeParentLineAmount();
  }

  private async computeLinePrice(line: SaleDocumentLine): Promise<SaleDocumentLine> {
    if (line.LineType == DocumentLineType.product || line.LineType == DocumentLineType.various) {
      var result = await this.saleDocumentLinesService.ComputeLinePrice(line);
      line.ExTaxGrossPrice = result.exTaxGrossPrice;
      line.ExTaxSaleGrossPrice = result.exTaxSaleGrossPrice;
      line.ExTaxTotalPrice = result.exTaxTotalPrice;
      line.ExTaxUnitPrice = result.exTaxUnitPrice;
      line.TaxAmount = result.taxAmount;
      line.InTaxTotalPrice = result.inTaxTotal;
    }
    return line;
  }


  public onSelectedKeyChange(selectedKeys: any) {
    if (!this.element.Lines) return;
    let lines = this.sortLines([...this.element.Lines])

    var table = selectedKeys.selectedRowsData;
    var selectedIndex = -1;
    table.forEach((selectedLine: SaleDocumentLine) => {
      var index = lines?.findIndex((line: SaleDocumentLine) => line.LineNo == selectedLine.LineNo && line.ParentLineNo == selectedLine.ParentLineNo) ?? -1;

      if (index > selectedIndex) {
        selectedIndex = index;
      }
    });
    var selectedRow = lines[selectedIndex];
    if (!selectedRow) {
      this.selectedKey = undefined;
      return;
    }
    this.selectedKey = { LineNo: selectedRow.LineNo, ParentLineNo: selectedRow.ParentLineNo };
  }

  private sortLines(lines: SaleDocumentLine[]): SaleDocumentLine[] {
    var sortedList: any[] = [];
    // SortParent
    let elementsFiltres = lines.filter(element => element.ParentLineNo === 0);
    let ParentTrees = elementsFiltres.sort((a: any, b: any) => a.LineNo - b.LineNo);

    // Load result
    ParentTrees.forEach(parent => {
      sortedList.push(parent);
      if (!this.element.Lines) return;
      let childElementsFiltres = this.element.Lines.filter(element => element.ParentLineNo === parent.LineNo);
      let childTrees = childElementsFiltres.sort((a: any, b: any) => a.LineNo - b.LineNo);
      childTrees.forEach((a: any) => {
        sortedList.push(a);
      })

    });
    return sortedList;
  }

  // Drag and drop
  // -------------
  public async OnReorder(e: any) {
    if (!this.element.Lines) return;
    AppInjectorService.config.setModificationGuard(true);
    this.element.Lines = e.component.getDataSource().items().map((i: any) => i.data);

    let fromIndex = e.fromIndex;
    let toIndex = e.toIndex;
    let dropInsideItem = e.dropInsideItem;
    let targetElementIsBefore = fromIndex > toIndex;

    if (dropInsideItem == true && targetElementIsBefore == true) {
      toIndex++;
    }

    this.element.Lines = this.arraymove(this.element.Lines, e.fromIndex, toIndex);
    this.element.Lines = this.updateLineNo(this.element.Lines);
    this.computeParentLineAmount();

    this.reloadTreelist();
  }

  public arraymove(arr: any, fromIndex: any, toIndex: any): SaleDocumentLine[] {
    var element = arr[fromIndex];
    arr.splice(fromIndex, 1);
    arr.splice(toIndex, 0, element);
    return arr;
  }

  private insertLine(line: SaleDocumentLine) {
    if (!this.element.Lines) return;
    this.element.Lines = this.sortLines([...this.element.Lines])
    line.Id = -this.insertLineId;
    this.insertLineId++;
    if (this.selectedKey != undefined) {
      var lineIndex = this.element.Lines?.findIndex((l: SaleDocumentLine) => l.LineNo == this.selectedKey.LineNo && l.ParentLineNo == this.selectedKey.ParentLineNo);
      line = this.updateParentId(line, lineIndex);
      if (this.element.Lines.length != lineIndex) {
        this.element.Lines.splice(lineIndex + 1, 0, line);
      }
    }
    else {
      line = this.updateParentId(line);
      this.element.Lines.push(line);
    }
    this.element.Lines = this.updateLineNo(this.element.Lines ?? []);
    this.computeParentLineAmount();
    this.element.Lines = this.saleDocumentLinesService.formatLines(this.element.Lines ?? []);
    this.element = this.documentsService.computeDocumentPrice(this.element);
    this.isAddingLine = false;
    AppInjectorService.config.setModificationGuard(true);

    this.reloadTreelist();
  }

  private updateParentId(line: SaleDocumentLine, parentIndex?: number | undefined): SaleDocumentLine {
    if (!this.element.Lines) return line;

    if (parentIndex == undefined) {
      parentIndex = this.element.Lines?.length - 1;
    }
    if (line.LineType == "post") {
      line.ParentId = undefined;
    }
    else if (this.element.Lines[parentIndex] && this.element.Lines[parentIndex].LineType == 'post') {
      line.ParentId = this.element.Lines[parentIndex];
    }
    else if (this.element.Lines[parentIndex] && this.element.Lines[parentIndex].ParentId != null) {
      line.ParentId = this.element.Lines[parentIndex].ParentId;
    }

    return line;
  }

  private updateLineNo(lines: SaleDocumentLine[]): SaleDocumentLine[] {
    let parentId: any = null;
    let lineNoParent: number = 1;
    let lineNoChield: number = 1;

    for (let i = 0; i < lines.length; i++) {
      if (lines[i].LineType == "post") {
        lineNoChield = 1;
        lines[i].ParentLineNo = 0;
        parentId = lines[i];
        if (lines[i - 1] != undefined && lines[i - 1].ParentId == undefined && lines[i - 1].LineType != 'post' && lines[i - 1].LineNo != undefined) {
          lineNoParent = (lines[i - 1].LineNo ?? 0) + 1;
        }
        lines[i].LineNo = lineNoParent++;
      }
      else {
        lines[i].ParentId = parentId;
        lines[i].ParentLineNo = parentId?.LineNo ?? 0;
        lines[i].LineNo = lineNoChield++;
      }
    }
    return lines;
  }

  private computeParentLineAmount() {
    if (!this.element.Lines) return;

    for (let index = 0; index < this.element.Lines.length; index++) {
      if (this.element.Lines[index].LineType != "post") continue;
      var exTaxTotalPrice = 0;
      var inTaxTotalPrice = 0;
      this.element.Lines.forEach((line: any) => {
        if (line.ParentId && this.element.Lines && line.ParentId.Id == this.element.Lines[index].Id && line.LineType != "post") {
          exTaxTotalPrice += line.ExTaxTotalPrice ?? 0
          inTaxTotalPrice += line.InTaxTotalPrice ?? 0
        }
      })

      this.element.Lines[index].ExTaxTotalPrice = exTaxTotalPrice ?? 0;
      this.element.Lines[index].InTaxTotalPrice = inTaxTotalPrice ?? 0;
    }
  }

  private reloadTreelist(){
    this.documentLines?.treelist?.treelist?.instance.getDataSource().items().forEach((i: any) => {
      this.documentLines?.treelist?.treelist?.instance.expandRow(i.key);
    });
    this.documentLines?.treelist?.treelist?.instance.refresh();
  }

  public setInsertLine(insertLine:number){
    this.insertLineId = insertLine;
  }
}

