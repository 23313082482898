<div *ngIf="element" class="d-flex">
    <div class="row" style="width: 40%;">
      <div class="col-6">
        <syslink-input-date
          name="Date"
          label="Date"
          [(model)]="element.Date"
          [disabled]="disabled"
          (modelChange)="onDateChanged()"
          type="date"
          format="date"
          ></syslink-input-date>
        </div>
        <div class="col-6">
          <syslink-input-date
          name="Validity"
          [label]="(documentType + '.Validity') | translate"
          [(model)]="element.Deadline"
          (modelChange)="onDateChanged()"
          [disabled]="disabled"
          type="date"
          format="date"
        ></syslink-input-date>
      </div>
      <div class="col-6">
        <syslink-input-text
        *ngIf="element.Date && dateDiff != ''"
        name="Month"
        label="Month"
        [model]="dateDiff"
        [disabled]="true"
        [labelMode]="'hidden'"
      ></syslink-input-text>
      </div>
      <div class="col-6">
        <syslink-input-text
          label="External reference"
          [(model)]="element.ExternalReference"
          [disabled]="disabled"
        ></syslink-input-text>
      </div>
      <div class="col-12">
        <syslink-input-text
          label="Subject"
          [(model)]="element.Subject"
          [disabled]="disabled"
        ></syslink-input-text>
      </div>
    </div>
    
  <div class="d-flex justify-content-center align-items-center"  style="width: 60%;">
    <app-third-document-data-block [documentDataCollection]="element.DocumentDataCollection"  [showDeliveryAddress]="false"></app-third-document-data-block>
  </div>
</div>








<!-- <div class="row">
  <div class="col-4">
    <syslink-input-text
      name="Subject"
      label="Subject"
      [(model)]="element.Subject"
      [disabled]="disabled"
    ></syslink-input-text>
  </div>

  <div class="col-2">
    <syslink-input-date
      *ngIf="
        element.ExtensionDate?.getTime() != element.Deadline?.getTime() &&
        element.ExtensionDate &&
        element.ExtensionDate.getFullYear() > 1 &&
        showExtensionDate
      "
      name="Validity"
      label="Extension date"
      [(model)]="element.ExtensionDate"
      [disabled]="true"
      type="date"
      format="date"
    ></syslink-input-date>
  </div>

  <div class="col-3">
    <div class="row">
      <div class="col-6">
        <div class="totalPriceLabel fw-bold py-2 text-end">
          {{ "ExTaxTotalInvoiced" | translate }} :
        </div>
      </div>
      <div class="col-6">
        <div class="totalPriceValue py-2 bg-secondary text-white text-center">
          {{
            element.ExTaxTotalInvoiced
              | syslinkCurrency
                : subModuleCode
                : "Precision.SaleInvoiceHeader.ExTaxTotal"
          }}
        </div>
      </div>
    </div>
  </div>

  <div class="col-3">
    <div class="row">
      <div class="col-6">
        <div class="totalPriceLabel fw-bold py-2 text-end">
          {{ "ExTaxTotalPrice" | translate }} :
        </div>
      </div>
      <div class="col-6">
        <div class="totalPriceValue py-2 bg-secondary text-white text-center">
          {{
            element.ExTaxTotal
              | syslinkCurrency
                : subModuleCode
                : "Precision." + documentType + "Header.ExTaxTotal"
          }}
        </div>
      </div>
    </div>
  </div>
</div>

<div class="row">
  <div class="col-2">
    <syslink-input-date
      name="Date"
      [label]="documentType + '.Date' | translate"
      [(model)]="element.Date"
      [disabled]="false"
      (modelChange)="computeDateDiff(); dateChange.emit($event)"
      type="date"
    ></syslink-input-date>
  </div>

  <div class="col-2">
    <syslink-input-date
      *ngIf="element.Deadline && documentType != 'SaleOrder'"
      name="Validity"
      [label]="documentType + '.Validity' | translate"
      [(model)]="element.Deadline"
      (modelChange)="onChangeDeadLine()"
      [disabled]="false"
      type="date"
      format="date"
    ></syslink-input-date>
  </div>

  <div class="col-2">
    <syslink-input-text
      *ngIf="element.Date && element.ExtensionDate && dateDiff != ''"
      name="Month"
      label="Month"
      [model]="dateDiff"
      [disabled]="true"
      [labelMode]="'hidden'"
    ></syslink-input-text>
  </div>

  <div class="col-3">
      <div class="row">
        <div class="col-6">
          <div class="totalPriceLabel fw-bold py-2 text-end">
            {{ "InTaxTotalInvoiced" | translate }} :
          </div>
        </div>
        <div class="col-6">
          <div class="totalPriceValue py-2 bg-secondary text-white text-center">
            {{
              element.InTaxTotalInvoiced
                | syslinkCurrency
                  : subModuleCode
                  : "Precision.SaleInvoiceHeader.InTaxTotal"
            }}
          </div>
        </div>
      </div>
  </div>

  <div class="col-3">
    <div class="row">
      <div class="col-6">
        <div class="totalPriceLabel fw-bold py-2 text-end">
          {{ "InTaxTotalPrice" | translate }} :
        </div>
      </div>
      <div class="col-6">
        <div class="totalPriceValue py-2 bg-secondary text-white text-center">
          {{
            element.InTaxTotal
              | syslinkCurrency
                : subModuleCode
                : "Precision." + documentType + "Header.InTaxTotal"
          }}
        </div>
      </div>
    </div>
  </div>
</div> -->
